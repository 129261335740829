import styles from "./css-modules/wheel.module.css";
import StatRenderer from './stat_renderer';

import InfraIcon from '../resources/Cards/Icons/infrastructure.png';
import AffordIcon from '../resources/Cards/Icons/affordability.png';
import PolicyIcon from '../resources/Cards/Icons/policy.png';
import DigitalIcon from '../resources/Cards/Icons/digitalSkills.png';
import ContentIcon from '../resources/Cards/Icons/content.png';

import { useInView } from 'react-intersection-observer';
import { useEffect, useRef } from "react";

import Fade from 'react-reveal/Fade';

let contentData = [
    {
        title: 'Infrastructure & Access',
        icon: InfraIcon,
        desc: 'Scaling community connectivity providers and means of expanding device access',
        text: 'For those who find the internet unreliable, there are many reasons why',
        color: '#2C3266',
        stats: [{
            type: 'percent',
            stat: '64',
            desc: "of respondents say it’s due to poor quality of infrastructure",
        },{
            type: 'percent',
            stat: '61',
            desc: 'say they have issues with their internet service provider',
        },{
            type: 'percent',
            stat: '33',
            desc: 'cite power outages',
        }]
    },{
        title: 'Affordability',
        icon: AffordIcon,
        desc: 'Catalyzing new business & financing models to reduce the cost of connecting',
        text: 'Internet Access, Mobile Plans, Phones, Training, Computers, Software, Tech Support costs add up and are expensive to maintain',
        color: '#8796FF',
        stats: [{
            type: 'percent',
            stat: '19',
            desc: 'of CSOs find all these things expensive ',
        },{
            type: 'percent',
            stat: '49',
            desc: 'of people served find all these things expensive',
        }]
    },{
        title: 'Digital Skills',
        icon: DigitalIcon, 
        desc: 'Developing the workforce that can build the internet and supporting digital literacy initiatives at scale',
        text: 'Digital Skills is a major challenge for CSOs and the people they serve',
        color: '#EC5C43',
        stats: [{
            type: 'percent',
            stat: '60',
            desc: 'of organizations do not provide digital literacy training',
        },{
            type: 'percent',
            stat: '37',
            desc: 'do not believe their employees are well trained for the devices and software they use',
        },{
            type: 'percent',
            stat: '55',
            desc: 'of people served do not have access to digital skills training',
        }]
    },{
        title: 'Content',
        icon: ContentIcon, 
        desc: 'Increasing incentives to use the internet by expanding locally relevant content',
        text: "Key resources aren't always available in local languages",
        color: '#0346AD',
        stats: [{
            type: 'percent',
            stat: '85',
            desc: 'of global respondents agree that Social Media can be found in their language',
        },{
            type: 'percent',
            stat: '80',
            desc: 'agree that News is available in their language',
        },{
            type: 'percent',
            stat: '57',
            desc: 'say Government Services are available in local languages',
        },{
            type: 'percent',
            stat: '46',
            desc: 'agree that Civic Engagement (census, voting, jury duty, etc.) is found in the local language',
        }]
    },{
        title: 'Policy',
        icon: PolicyIcon, 
        desc: 'Advocating for changes to accelerate the rate at which people are connecting',
        text: "People are unlikely to use the internet if they don’t feel safe or if policies don’t support their connectivity",
        color: '#BFB2FC',
        stats: [{
            type: 'percent',
            stat: '91',
            desc: 'of respondents believe internet access is a basic right',
        },{
            type: 'percent',
            stat: '29',
            desc: 'of CSOs don’t feel safe online',
        }]
    }
];


function Wheel({ onRouteChange }) {

    const donutRef = useRef();

  return (
    <div className={styles.container}>
        <div className={styles.image_container} style={{height: window.innerHeight}}>
            <img ref={donutRef} src='https://connecthumanity.fund/wp-content/uploads/2021/12/donaut-2.png' className={styles.donut}/>
        </div>
        <div className={styles.content}>
            {contentData.length > 0 && contentData.map((content, index) => {
                return <WheelContent {...{content, index, donutRef, onRouteChange}}/>
            })}
        </div>
    </div>
  );
}

function WheelContent({ content, index, donutRef, onRouteChange }) {

    const { ref, inView, entry } = useInView({
        threshold: 0.8,
    });

    useEffect(() => {
        if (inView) {
            const index = entry.target.getAttribute('index') * 70 * -1;
            donutRef.current.style.transform = `rotate(${index}deg)`;
        }
    }, [inView]);



    return (
        <div ref={ref} index={index} className={styles.wheel_content}>
            <div className={styles.absolute_color} style={{backgroundColor: content.color}}></div>
            <Fade top distance='1em'>
                <div className={styles.flexed}>
                    <img src={content.icon} className={styles.icon}/>
                    <h3 className={styles.title}>{content.title}</h3>
                </div>
            </Fade>
            <Fade bottom distance='3em'>
                <p className={styles.desc}>{content.desc}</p>
                <p className={styles.text}>{content.text}</p>
                {content.stats.length > 0 && content.stats.map((stat, index) => {
                    return <StatRenderer stat={stat}/>
                })}

                <button onClick={() => onRouteChange('/data')} className={styles.abs_button} style={content.title === 'Policy' ? {backgroundColor: content.color, color: '#000'} : {backgroundColor: content.color, color: '#fff'}}>
                    <p>Explore more on the Data Dashboard</p>
                </button>

                <img src='https://connecthumanity.fund/wp-content/uploads/2021/12/donaut-2.png' className={`${styles['mobiledonut' + index]} ${styles.absDonut}`}/>

            </Fade>
        </div>
    );
  }



export default Wheel;
