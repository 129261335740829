import Navbar from '../components/navbar';
import styles from './css-modules/common.module.css';
import dashStyles from './css-modules/dash.module.css';
import { useRef, useLayoutEffect, useState, useEffect } from 'react';
import gsap, { TweenLite } from 'gsap';
import DashContent from './dashContent';
import SectionTitle from '../components/section_title';
import CustomDropDown from '../components/drop_down';
import DashboardKey from '../components/key';
import Footer from '../components/footer';
import Tooltip from '@mui/material/Tooltip';

import GeneralIcon from '../resources/Cards/Icons/general.png';
import AffordIcon from '../resources/Cards/Icons/affordability.png';
import ContentIcon from '../resources/Cards/Icons/content.png';
import DigitalIcon from '../resources/Cards/Icons/digitalSkills.png';
import InfraIcon from '../resources/Cards/Icons/infrastructure.png';
import PolicyIcon from '../resources/Cards/Icons/policy.png';

import { useInView } from 'react-intersection-observer';

import ScrollToPlugin from "gsap/ScrollToPlugin";

//Data Fetch functions below 
import { fetchTitle, fetchReasonTitle, fetchCountryResponseCount, fetchQuestionProportion, fetchQuestionPercent } from '../data/fetchDataFunctions';

//without this line, ScrollToPlugin may get dropped by your bundler...
gsap.registerPlugin(ScrollToPlugin);

function Dash({ logo, pageFade, setPageFade, onRouteChange, countries, dropDownList, comparisonList, primaryCountry, setPrimaryCountry, secondaryCountry, setSecondaryCountry, closeMobileNav, mobileNav }) {

    const navRef = useRef();
    const contentRef = useRef();

    const general = useRef();
    const access = useRef();
    const content = useRef();
    const digital = useRef();
    const afford = useRef();
    const policy = useRef();

    const gc_Ref = useRef();
    const ac_Ref = useRef();
    const cc_Ref = useRef();
    const dc_Ref = useRef();
    const afc_Ref = useRef();
    const pc_Ref = useRef();

    const [animateGraphs, setAnimateGraphs] = useState(false);

    const { gContentRef, gInView, entry } = useInView({
      threshold: 0.5,
    });

    useLayoutEffect(() => {
        if (pageFade) {
            TweenLite.fromTo(navRef.current, 0.45, {opacity: 0, y: -10}, {opacity: 1, y: 0}).delay(0);
            TweenLite.fromTo(contentRef.current, 0.8, {opacity: 0, y: 20}, {opacity: 1, y: 0}).delay(0.4).then(function() {
              setAnimateGraphs(true);
            });
            setPageFade(false);
        }
    }, [pageFade]);

    return (
      
        <div>
            <Navbar assignRef={navRef} nav={['State of Digital Inequity', 'Data Dashboard', 'Voices of Civil Society', 'About the Dashboard']} logo={logo} onRouteChange={onRouteChange} closeMobileNav={closeMobileNav} mobileNav={mobileNav}/>
            <div ref={contentRef} className={`${styles.content_container}`}>
              <SectionTitle 
                title='Data Dashboard'
                desc="This Data Dashboard was built using data from the i____internet Digital Equity Survey, conducted by Connect Humanity in 2022 in partnership with TechSoup Global Network. The survey was completed by over 7,500 participants from civil society organizations (CSOs) in 136 countries, collectively serving over 190 million people. These respondents answered questions about the barriers that they, and the communities they serve, face when it comes to digital equity. This dashboard allows comparison of responses across countries and regions."
                gray 
                removeRadius
              />
              <SectionTitle 
                gray 
                sticky
                jsx={
                  <div className={`${dashStyles.inputs_container} ${dashStyles.dropdown_input_cont}`}>
                    <div className={`${dashStyles.flexed_inputs} ${dashStyles.dropdowns}`}>
                      {dropDownList.length > 0  && <CustomDropDown 
                        title='Select a Country' 
                        stateVar={primaryCountry} 
                        stateFunction={setPrimaryCountry} 
                        options={dropDownList} 
                        optionLabel='name'
                        groupBy='type'
                      />}

                      {comparisonList.length > 0 && <CustomDropDown 
                        title='Compare with' 
                        stateVar={secondaryCountry} 
                        stateFunction={setSecondaryCountry} 
                        options={comparisonList} 
                        optionLabel='name'
                        groupBy='type'
                      />}
                    </div>
                    <div className={`${dashStyles.flexed_inputs} ${dashStyles.justified_inputs} ${dashStyles.legend}`}>
                      <div className={`${dashStyles.flexed_inputs} ${dashStyles.flexed_keys}`}>
                        <DashboardKey color={'#0346AD'} country={primaryCountry.name} count={fetchCountryResponseCount(countries, primaryCountry)}/>
                        <DashboardKey color={'#A1ACFD'} country={secondaryCountry.name} count={fetchCountryResponseCount(countries, secondaryCountry)}/>
                      </div>
                      <div className={`${dashStyles.flexed_inputs} ${dashStyles.section_icons}`}>
                        <RenderSkipIcon image={GeneralIcon} tool='Navigate to General' refV={general} refC={gc_Ref} cons='general'/>
                        <RenderSkipIcon image={InfraIcon} tool='Navigate to Infrastructure' refV={access} refC={ac_Ref}/>
                        <RenderSkipIcon image={AffordIcon} tool='Navigate to Affordability' refV={afford} refC={afc_Ref}/>
                        <RenderSkipIcon image={PolicyIcon} tool='Navigate to Policy' refV={policy} refC={pc_Ref}/>
                        <RenderSkipIcon image={DigitalIcon} tool='Navigate to Digital Skills' refV={digital} refC={dc_Ref}/>
                        <RenderSkipIcon image={ContentIcon} tool='Navigate to Content' refV={content} refC={cc_Ref}/>
                      </div>
                    </div>
                  </div>
                }
              />
              <DashContent {...{animateGraphs, dashStyles, countries, fetchTitle, fetchReasonTitle, fetchCountryResponseCount, primaryCountry, secondaryCountry, fetchQuestionProportion, fetchQuestionPercent, general, access, policy, content, digital, afford, gc_Ref, ac_Ref, afc_Ref, dc_Ref, cc_Ref, pc_Ref }}/>

              <Footer {...{logo}}/>
            </div>
        </div>
    );
}

function RenderSkipIcon({ image, tool, refV, refC, cons }) {

  const ref = useRef();

  const navigateSec = (el) => {
    let stickyHeight = document.getElementById('sticky').offsetHeight * 0.5;
    let yVal = el.offsetTop - stickyHeight
    TweenLite.to(window, 1.25, { scrollTo: {y: yVal} });
  }

  useEffect(() => {
    let observer = new IntersectionObserver((entry)=>{
      if (cons) {
        console.log(cons + ' - ' + entry[0].intersectionRatio);
      }
        if (entry[0].intersectionRatio > 0.26) {
          ref.current.style.filter = 'opacity(0.5)';
        }
        else {
          ref.current.style.filter = 'opacity(1)';
        }
      },{
        threshold : 0.26
      });

      observer.observe(refC.current);
  }, []);

  return (
    <Tooltip title={tool}>
      <img ref={ref} src={image} className={dashStyles.secIcon} onClick={() => navigateSec(refV.current)}/>
    </Tooltip>
  );
}

export default Dash;


