import * as d3 from "d3";

function drawDial(bGCol, needleCol, percVal, svgRef) {
  // function that helps us draw the arc
  const arc = d3.arc().innerRadius(200).outerRadius(250).cornerRadius(25);

  // scale to compute the extent to which we rotate the dial needle
  const angScale = d3.scaleLinear().domain([0, 100]).range([0, 180]);

  d3.select(svgRef.current)
    .selectAll("*")
    .remove();

  // appending a vis G
  const svgG = d3
    .select(svgRef.current)
    .append("g")
    .attr("transform", "translate(20, 10) translate(250, 250)");

  // an object with some margin params
  const margin = {
    left: 20,
    right: 20,
    top: 20,
    bottom: 20,
  };

  // bG dial path (generated by arc function)
  svgG
    .append("path")
    .attr(
      "d",
      arc({
        innerRadius: 0,
        outerRadius: 100,
        startAngle: -Math.PI / 1.9,
        endAngle: Math.PI / 1.9,
      })
    )
    .style("fill", bGCol);

  // a line for the needle of the dial
  const needle = svgG
    .append("line")
    .attr("x1", -250)
    .attr("y1", 0)
    .attr("x2", 0)
    .attr("y2", 0)
    .style("stroke", needleCol)
    .style("stroke-width", "12px")
    .style("stroke-linecap", "round")
    .attr("transform", "rotate(0)");

  // circle for needle axis
  const axis = svgG
    .append("circle")
    .attr("cx", 0)
    .attr("cy", 0)
    .attr("r", 15)
    .style("fill", bGCol);

  needle
    .transition()
    .duration(1500)
    .ease(d3.easeBounceOut)
    .attr("transform", `rotate(${angScale(percVal)})`);
}

export { drawDial };
