import { BoxProportion } from "../../components/non_d3/boxProportion";
import { Grid } from "../../components/non_d3/grid";
import { CountryInfo, BoxPair, ChartAndCaption } from "../../components/BoxPair";
import { ChartFlexRow } from "../../components/ChartFlexRow";
import { DonutWrappedInDiv } from "../../components/d3/donut";
import { DialWrappedInDiv } from "../../components/d3/dial";
import { BarPair } from "../../components/barPair";
import { BarWrappedInDiv } from "../../components/non_d3/boxProportion";
import { ComparisonBar } from "../../components/non_d3/comparison_bar";
import { SimpleBarAndLabelPair } from "../../components/non_d3/barsAndLabels";
import { MdSignalCellularConnectedNoInternet1Bar } from "react-icons/md";
import { GrTechnology } from "react-icons/gr";
import { ImPowerCord } from "react-icons/im";

function DashGeneral({ animateGraphs, dashStyles, fetchTitle, fetchReasonTitle, primaryCountry, secondaryCountry, fetchQuestionProportion, fetchQuestionPercent, gc_Ref  }) {
  return (
    <div ref={gc_Ref}>
      <ChartFlexRow>
        <ChartAndCaption caption={fetchTitle("11.1", "Story")}>
          <BoxPair>
            <BoxProportion
              borderWidth="2px"
              proportion={fetchQuestionProportion(primaryCountry, "11.1")}
              boxFill="#0346AD"
              borderRadius="15px"
              type="box"
              transitionDuration={0.4}
              transitionDelay={0.125}
            />
            <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "11.1")} />
            <BoxProportion
              borderWidth="2px"
              proportion={fetchQuestionProportion(secondaryCountry, "11.1")}
              boxFill="#A1ACFD"
              borderRadius="15px"
              type="box"
              transitionDuration={0.4}
              transitionDelay={0.25}
                          />
            <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "11.1")} />
          </BoxPair>
        </ChartAndCaption>
        <ChartAndCaption caption={fetchTitle("11.2", "Story")}>
          <BoxPair>
            <Grid
              percentInt={fetchQuestionPercent(primaryCountry, "11.2")}
              fillCol="#0346AD"
              emptyCol="#fff"
              cellMargin="1px"
              borderRadius="50%"
            />
            <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "11.2")} />
            <Grid
              percentInt={fetchQuestionPercent(secondaryCountry, "11.2")}
              fillCol="#A1ACFD"
              emptyCol="#fff"
              cellMargin="1px"
              borderRadius="50%"
            />
            <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "11.2")} />
          </BoxPair>
        </ChartAndCaption>
        <ChartAndCaption caption={fetchTitle("11.3", "Story")}>
          <BarPair>
            <BarWrappedInDiv   proportion={fetchQuestionProportion(primaryCountry, "11.3")} boxFill="#0346AD" {...{animateGraphs}}/>
            <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "11.3")}/>
            <BarWrappedInDiv   proportion={fetchQuestionProportion(secondaryCountry, "11.3")} boxFill="#A1ACFD" {...{animateGraphs}}/>
            <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "11.3")}/>
          </BarPair>
        </ChartAndCaption>
        <ChartAndCaption caption={fetchTitle("11.4", "Story")}>
          <BoxPair>
            <DonutWrappedInDiv percent={fetchQuestionPercent(primaryCountry, "11.4")} arcFill="#0346AD" {...{animateGraphs}}/>
            <CountryInfo countryName={primaryCountry.name} center />
            <DonutWrappedInDiv percent={fetchQuestionPercent(secondaryCountry, "11.4")} arcFill="#A1ACFD" {...{animateGraphs}}/>
            <CountryInfo countryName={secondaryCountry.name} center />
          </BoxPair>
        </ChartAndCaption>
        <ChartAndCaption caption={fetchTitle("11.5", "Story")}>
            <ComparisonBar
                primary_data={{name: primaryCountry.name, value: fetchQuestionPercent(primaryCountry, "11.5"), color: "#0346AD"}}
                secondary_data={{name: secondaryCountry.name, value: fetchQuestionPercent(secondaryCountry, "11.5"), color: "#A1ACFD"}}
            />
        </ChartAndCaption>
   
        <ChartAndCaption caption={fetchTitle("13", "Story")}>
            <BarPair>
                <BarWrappedInDiv boxFill="#0346AD" needleCol="grey" proportion={fetchQuestionProportion(primaryCountry, "13")} {...{animateGraphs}}/>
                <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "13")} />
                <BarWrappedInDiv boxFill="#A1ACFD" needleCol="grey" proportion={fetchQuestionProportion(secondaryCountry, "13")} {...{animateGraphs}}/>
                <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "13")} />
            </BarPair>
        </ChartAndCaption>
      </ChartFlexRow>

      <p className='dashSection_desc'>{fetchTitle("14.1", "Group")}</p>

      <ChartFlexRow>
        <ChartAndCaption caption={fetchTitle("14.1", "Story")}>
          <SimpleBarAndLabelPair
            proportion1={fetchQuestionProportion(primaryCountry, "14.1")}
            barCol1="#0346AD"
            proportion2={fetchQuestionProportion(secondaryCountry, "14.1")}
            barCol2="#A1ACFD"
            icon={ImPowerCord}
            caption={fetchReasonTitle("14.1")}
          />
          <SimpleBarAndLabelPair
            proportion1={fetchQuestionProportion(primaryCountry, "14.2")}
            barCol1="#0346AD"
            proportion2={fetchQuestionProportion(secondaryCountry, "14.2")}
            barCol2="#A1ACFD"
            icon={GrTechnology}
            caption={fetchReasonTitle("14.2")}
          />
          <SimpleBarAndLabelPair
            proportion1={fetchQuestionProportion(primaryCountry, "14.3")}
            barCol1="#0346AD"
            proportion2={fetchQuestionProportion(secondaryCountry, "14.3")}
            barCol2="#A1ACFD"
            icon={MdSignalCellularConnectedNoInternet1Bar}
            caption={fetchReasonTitle("14.3")}
          />
        </ChartAndCaption>
        <ChartAndCaption caption="Barriers for people served in using the internet">
          <SimpleBarAndLabelPair
            proportion1={fetchQuestionProportion(primaryCountry, "15.1")}
            barCol1="#0346AD"
            proportion2={fetchQuestionProportion(secondaryCountry, "15.1")}
            barCol2="#A1ACFD"
            icon={ImPowerCord}
            caption={fetchReasonTitle("15.1")}
          />
          <SimpleBarAndLabelPair
            proportion1={fetchQuestionProportion(primaryCountry, "15.2")}
            barCol1="#0346AD"
            proportion2={fetchQuestionProportion(secondaryCountry, "15.2")}
            barCol2="#A1ACFD"
            icon={GrTechnology}
            caption={fetchReasonTitle("15.2")}
          />
          <SimpleBarAndLabelPair
            proportion1={fetchQuestionProportion(primaryCountry, "15.3")}
            barCol1="#0346AD"
            proportion2={fetchQuestionProportion(secondaryCountry, "15.3")}
            barCol2="#A1ACFD"
            icon={MdSignalCellularConnectedNoInternet1Bar}
            caption={fetchReasonTitle("15.3")}
          />
        </ChartAndCaption>
      </ChartFlexRow>
    </div>
  );
}

export default DashGeneral;
