import { dashboardData } from "./dashboard";
import { countryInfoObj } from "./countryInfo";
import { stats } from "./stats";
import { comments } from './voices';

function fetchTitle(ID, Type) {
  let item = dashboardData.filter((obj) => obj["Question no"] === ID);
  return item[0][Type];
}

function fetchReasonTitle(ID) {
  let item = dashboardData.filter((obj) => obj["Question no"] === ID)[0];
  let key = Object.keys(item["Data"]);
  return key;
}

function fetchCountryResponseCount(list, id) {
  let returnVal = 0;

  if (id.type === "Globals") {
    let items;
    if (id.name === "Global Average") {
      items = list;
    } else if (id.name === "Global North Average") {
      items = list.filter((obj) => obj["GlobalRegion"] === "North");
    } else {
      items = list.filter((obj) => obj["GlobalRegion"] === "South");
    }
    returnVal = items.reduce(function (a, b) {
      return a + parseInt(b["Count of Vrid"]);
    }, 0);
  } else if (id.type === "Regions") {
    let items = list.filter((obj) => obj["CH Region"] === id.name);

    returnVal = items.reduce(function (a, b) {
      return a + parseInt(b["Count of Vrid"]);
    }, 0);
  } else {
    let item = list.filter((obj) => obj["CH Country"] === id.name);

    if (item.length > 0) {
      returnVal = item[0]["Count of Vrid"];
    }
  }

  return returnVal;
}

function fetchQuestionProportion(country, id) {
  let data = filterDataByType(country.type, id);
  let countries = Object.values(countryInfoObj);

  let countryID = "";

  if (country.type === "Countries") {
    countryID = countries.filter(
      (obj) => obj["CH Country"] === country.name
    )[0]["Country Code"];
  } else if (country.type === "Regions") {
    countryID = country.name;
  } else {
    if (country.name === "Global Average") {
      countryID = "Total";
    } else if (country.name === "Global North Average") {
      countryID = "North";
    } else {
      countryID = "South";
    }
  }

  if (id.includes("18") && country.type === "Countries") {
    if (data[countryID]['Count of Vrid'].length > 0) {
      let returnVal = data[countryID].percent;

      if (returnVal > 1) {
        returnVal = returnVal / 100;
      }
      return returnVal;
    }
    else {
      let returnVal = false; 
      return returnVal;
    }
  }
  else {
    let returnVal = data[countryID] ? data[countryID].percent : 0;

    if (returnVal > 1) {
      returnVal = returnVal / 100;
    }
    return returnVal;
  }
}

function fetchQuestionPercent(country, id) {
  let returnVal = Math.round(fetchQuestionProportion(country, id) * 100);
  return returnVal;
}

function filterDataByType(type, id) {
  let returnVal;
  if (type === "Globals") {
    returnVal = stats[id]["ns"];
  } else if (type === "Regions") {
    returnVal = stats[id]["r"];
  } else if (type === "Countries") {
    returnVal = stats[id]["std"];
  }
  return returnVal;
}

function fetchRegionCountryList(region) {
  let countries = Object.values(countryInfoObj);
  let returnVal = countries.filter((obj) => obj["CH Region"] === region.name);
  return returnVal;
}

function fetchCountryComments(region) {
  let countries = fetchRegionCountryList(region);

  let filteredData = [];

  countries.forEach((country) => {
    let arr = comments.filter((comm) => comm["HQ Country"] === country["CH Country"]);
    if (arr.length === 0) {
      countries = countries.filter((c) => c['CH Country'] !== country['CH Country'])
    }
    filteredData = filteredData.concat(arr);
  });

  return {
    filterOptions: countries,
    filteredData: filteredData
  }
}



export {
  fetchTitle,
  fetchReasonTitle,
  fetchCountryResponseCount,
  fetchQuestionProportion,
  fetchQuestionPercent,
  fetchRegionCountryList,
  fetchCountryComments
};
