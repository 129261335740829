import styled from "styled-components";
import  TweenLite from 'gsap';
import { useEffect, useState } from 'react';
import { useInView } from "react-intersection-observer";

const GridCell = styled.div`
  flex: 1;
  scale: 0;
  background-color: ${(props) => {
    const { percentInt, cellNumber, fillCol, emptyCol } = props;
    return cellNumber <= percentInt ? fillCol : emptyCol;
  }};
  margin: ${(props) => props.cellMargin};
  border-radius: ${(props) => props.borderRadius};
`;

const GridCellWrapper = ({
  cellNumber,
  percentInt,
  fillCol,
  emptyCol,
  cellMargin,
  borderRadius,
}) => {

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const [init, setInit] = useState(true);

  useEffect(() => {
    if (inView && init) {
      TweenLite.fromTo(entry.target, 0.2,{scale: 0}, {scale: 1}).delay(cellNumber*0.0125);
      setInit(false);
    }
  }, [inView, init]);

  useEffect(() => {
    if (entry) {
      setInit(true);
    }
  }, [percentInt]);

  return (
    <GridCell
      ref={ref}
      className="gridCell"
      key={`cell${cellNumber}`}
      {...{
        cellNumber,
        percentInt,
        fillCol,
        emptyCol,
        cellMargin,
        borderRadius,
      }}
    />
  );
}

const GridRow = ({
  rowNumber,
  percentInt,
  fillCol,
  emptyCol,
  cellMargin,
  borderRadius,
}) => {
  return (
    <div
      className="gridRow"
      style={{ display: "flex", width: "100%", height: "10%" }}
    >
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((d, i) => {
        let cellNumber = rowNumber * 10 + d;
        return (
          <GridCellWrapper
            className="gridCell"
            key={`cell${cellNumber}`}
            {...{
              cellNumber,
              percentInt,
              fillCol,
              emptyCol,
              cellMargin,
              borderRadius,
            }}
          />
        );
      })}
    </div>
  );
};

export const Grid = ({
  percentInt,
  fillCol,
  emptyCol,
  cellMargin,
  borderRadius,
}) => {
  return (
    <div className="gridContain" style={{ width: "100%", paddingTop: "100%", position: 'relative' }}>
      <div style={{position: 'absolute', left: '0px', top: '0px', width: '100%', height: '100%'}}>
      {[9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((d) => {
        return (
          <GridRow
            key={`row${d}`}
            {...{
              rowNumber: d,
              percentInt,
              fillCol,
              emptyCol,
              cellMargin,
              borderRadius,
            }}
          />
        );
      })}
      </div>
    </div>
  );
};
