import * as d3Base from "d3";
import * as d3Multi from "d3-selection-multi";

const d3 = Object.assign(d3Base, d3Multi);

function drawDonutArc(
  percent,
  innerRad,
  outerRad,
  arcFill,
  svgRef,
  backGrndStyle = 2,
) {
  const width = 400;
  const height = 400;

  d3.select(svgRef.current).selectAll("*").remove();

  // svg selection
  const svgG = d3
    .select(svgRef.current)
    .append("g")
    .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

  // arc function to draw the arc
  const arc = d3.arc().innerRadius(innerRad).outerRadius(outerRad);

  const backGrndArc = d3
    .arc()
    .innerRadius(0)
    .outerRadius((outerRad + innerRad) / 2);

  let datum = [
    {
      data: percent,
      index: 0,
      value: percent,
      startAngle: 0,
      endAngle: computeEndAngle(percent),
      padAngle: 0,
    },
  ];

  function createDatum(endAngle) {
    return {
      data: percent,
      index: 0,
      value: percent,
      startAngle: 0,
      endAngle: endAngle,
      padAngle: 0,
    };
  }

  //datum = [createDatum(computeEndAngle(percent))];

  if (backGrndStyle === 1) {
    svgG
      .append("path")
      .classed("backGrndArc", true)
      .attr("d", arc(createDatum(Math.PI * 2)))
      .style("fill", "none")
      .style("stroke", "grey");
  }

  if (backGrndStyle === 2) {
    svgG
      .append("path")
      .classed("backGrndArc", true)
      .attr("d", backGrndArc(createDatum(Math.PI * 2)))
      .style("fill", "none")
      .style("stroke", "grey")
      .style("stroke-dasharray", 15);
  }
  
  svgG
    .selectAll("path.arc")
    .data(datum)
    .enter()
    .append("path")
    .classed("arc", true)
    .style('fill', arcFill)
    //.styles(arcStyle)
    .transition()
    .delay(100)
    .duration(1000)
    .attrTween("d", (d) => {
      return function (t) {
        const endAngleInterp = d3.interpolate(createDatum(0), d);
        return arc(endAngleInterp(t));
      };
    });

  function computeEndAngle(percent) {
    return 2 * Math.PI * (percent / 100);
  }
}

export default drawDonutArc;
