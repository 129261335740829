import styles from "./css-modules/statRenderer.module.css";

function StatRenderer({ stat }) {
  return (
    <div>
        {stat.type === 'percent' ? 
            <div className={styles.flexed}>
                <div className={styles.percentCont}>
                    <p>{stat.stat}</p>
                    <p className={styles.percent}>%</p>
                </div>
                <div className={styles.desc_contain}>
                    <p>{stat.desc}</p>
                </div>
            </div>
        :
            <div className={styles.flexed}>
                <div className={styles.proportion}>
                    <p className={styles.sup}>{stat.sup}</p>
                    <p>{stat.stat}</p>
                </div>
                <div className={styles.desc_contain}>
                    <p>{stat.desc}</p>
                </div>
            </div>
        }
    </div>
  );
}

export default StatRenderer;
