import DashSectionTitle from '../../components/dashSectionTitle';
import { BoxProportion } from "../../components/non_d3/boxProportion";
import { Grid } from "../../components/non_d3/grid";
import { CountryInfo, BoxPair, ChartAndCaption } from "../../components/BoxPair";
import { ChartFlexRow } from "../../components/ChartFlexRow";
import { DonutWrappedInDiv } from "../../components/d3/donut";
import { DialWrappedInDiv } from "../../components/d3/dial";
import { BarPair } from "../../components/barPair";
import { BarWrappedInDiv } from "../../components/non_d3/boxProportion";
import { ComparisonBar } from "../../components/non_d3/comparison_bar";
import { SimpleBarAndLabelPair } from "../../components/non_d3/barsAndLabels";
import { MdSignalCellularConnectedNoInternet1Bar } from "react-icons/md";
import { GrTechnology } from "react-icons/gr";
import { ImPowerCord } from "react-icons/im";

function Digital({ animateGraphs, fetchTitle, fetchReasonTitle, primaryCountry, secondaryCountry, fetchQuestionProportion, fetchQuestionPercent, dc_Ref  }) {
  return (
    <div ref={dc_Ref}>
      <ChartFlexRow>
        <ChartAndCaption caption={fetchTitle("28.1", "Story")}>
          <BoxPair>
            <BoxProportion
              borderWidth="2px"
              proportion={fetchQuestionProportion(primaryCountry, "28.1")}
              boxFill="#0346AD"
              borderRadius="15px"
              type="box"
              transitionDuration={0.4}
              transitionDelay={0.125}
            />
            <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "28.1")} />
            <BoxProportion
              borderWidth="2px"
              proportion={fetchQuestionProportion(secondaryCountry, "28.1")}
              boxFill="#A1ACFD"
              borderRadius="15px"
              type="box"
              transitionDuration={0.4}
              transitionDelay={0.25}
            />
            <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "28.1")} />
          </BoxPair>
        </ChartAndCaption>
        <ChartAndCaption caption={fetchTitle("28.2", "Story")}>
          <BoxPair>
            <Grid
              percentInt={fetchQuestionPercent(primaryCountry, "28.2")}
              fillCol="#0346AD"
              emptyCol="#fff"
              cellMargin="1px"
              borderRadius="50%"
            />
            <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "28.2")} />
            <Grid
              percentInt={fetchQuestionPercent(secondaryCountry, "28.2")}
              fillCol="#A1ACFD"
              emptyCol="#fff"
              cellMargin="1px"
              borderRadius="50%"
            />
            <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "28.2")} />
          </BoxPair>
        </ChartAndCaption>
        <ChartAndCaption caption={fetchTitle("29.1", "Story")}>
          <BarPair>
            <BarWrappedInDiv   proportion={fetchQuestionProportion(primaryCountry, "29.1")} boxFill="#0346AD" {...{animateGraphs}}/>
            <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "29.1")}/>
            <BarWrappedInDiv   proportion={fetchQuestionProportion(secondaryCountry, "29.1")} boxFill="#A1ACFD" {...{animateGraphs}}/>
            <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "29.1")}/>
          </BarPair>
        </ChartAndCaption>
      </ChartFlexRow>

      <p className='dashSection_desc'>{fetchTitle("28.3", "Group")}</p>

      <ChartFlexRow>
        <ChartAndCaption caption={fetchTitle("28.3", "Story")}>
          <BoxPair>
            <DonutWrappedInDiv percent={fetchQuestionPercent(primaryCountry, "28.3")} arcFill="#0346AD" {...{animateGraphs}}/>
            <CountryInfo countryName={primaryCountry.name} center />
            <DonutWrappedInDiv percent={fetchQuestionPercent(secondaryCountry, "28.3")} arcFill="#A1ACFD" {...{animateGraphs}}/>
            <CountryInfo countryName={secondaryCountry.name} center />
          </BoxPair>
        </ChartAndCaption>
        <ChartAndCaption caption={fetchTitle("30.1", "Story")}>
            <ComparisonBar
                primary_data={{name: primaryCountry.name, value: fetchQuestionPercent(primaryCountry, "30.1"), color: "#0346AD"}}
                secondary_data={{name: secondaryCountry.name, value: fetchQuestionPercent(secondaryCountry, "30.1"), color: "#A1ACFD"}}
            />
        </ChartAndCaption>
      </ChartFlexRow>

    </div>
  );
}

export default Digital;
