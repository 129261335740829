import styles from './css_modules/comparison_bar.module.css';
import { useEffect, useRef, useState } from 'react';
import { useInView } from "react-intersection-observer";
import  TweenLite from 'gsap';

function ComparisonBar({ primary_data, secondary_data }) {
  const [reset, setReset] = useState(false); 

  const [ref, inView] = useInView({
        /* Optional options */
        threshold: 0.9, 
    });

  useEffect(() => {
    if (inView && barRef.current) {
        let primeX = barRef.current.clientWidth * primary_data.value/100 - (primary_data.value > 50 ? primeInfoRef.current.clientWidth - 10 : 0);
        primeX = primeX/primeInfoRef.current.clientWidth * 100;
        TweenLite.to(primeInfoRef.current, 0.7, {translateX: primeX + "%"}).delay(0);
        let secondX = barRef.current.clientWidth * secondary_data.value/100 - (secondary_data.value > 50 ? secondInfoRef.current.clientWidth - 10 : 0);
        secondX = secondX/secondInfoRef.current.clientWidth * 100;
        TweenLite.to(secondInfoRef.current, 0.7, {translateX: secondX + "%"}).delay(0.1);
        setReset(false);
    }
  }, [inView, reset]);

  useEffect(() => {
    if (!reset) {
        setReset(true);
    }
  }, [primary_data, secondary_data]);




 


  const barRef = useRef();
  const primeInfoRef = useRef();
  const secondInfoRef = useRef();

  return (
    <div ref={ref} className={styles.bar_container}>
        <div ref={primeInfoRef} className={styles.info_cont} style={{flexDirection: `${primary_data.value > 50 ? 'row-reverse' : 'row'}`}}>
            <div className={styles.marker} style={{background: primary_data.color}}></div>
            <div className={styles.country_desc_cont} style={{justifyContent: `${primary_data.value > 50 ? 'flex-end' : 'flex-start'}`}}>
                <p className={styles.data_name}>{primary_data.name}</p>
                <p className={styles.data_value}>{primary_data.value}%</p>
            </div>
        </div>

        <div ref={barRef} className={styles.bar}>
            <p>0%</p>
            <p>100%</p>
        </div>

        <div ref={secondInfoRef} className={styles.info_cont} style={{flexDirection: `${secondary_data.value > 50 ? 'row-reverse' : 'row'}`}}>
            <div className={styles.marker} style={{background: secondary_data.color}}></div>
            <div className={styles.country_desc_cont} style={{justifyContent: `${secondary_data.value > 50 ? 'flex-end' : 'flex-start'}`}}>
                <p className={styles.data_name}>{secondary_data.name}</p>
                <p className={styles.data_value}>{secondary_data.value}%</p>
            </div>        
        </div>    
    </div>
  );
}

export { ComparisonBar };
