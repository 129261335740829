import styled from 'styled-components';
import styles from './css-modules/key.module.css';

const KeyContain = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.75em;
    font-weight: 500;
    margin-top: 10px;
    margin-right: 30px;

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        align-items: flex-start;
    }
`;

const KeyCircle = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 5px;

  @media screen and (max-width: 600px) {
    width: 100%;
    height: 7px;
    margin-bottom: 10px;
    border-radius: 50px;
}
`;

function SectionTitle({ color, country, count }) {
    return (
        <KeyContain>
            <KeyCircle style={{backgroundColor: color}}></KeyCircle>
            <div className={styles.key_flex}><div><p className={styles.country_name}>{country}:</p> <p className={styles.respondant}>Respondant Count</p></div> <p>{count}</p></div>
        </KeyContain>
    );
}

export default SectionTitle;
