const dashboardData = [
  {
    Section: "General",
    Group: "Civil Society Organizations (CSOs) rely on the internet to work effectively and help the people they aim to serve.",
    Story: "CSOs say internet access is vital to their work",
    Data: 0.95,
    "Question no": "11.1",
  },
  {
    Section: "General",
    Group: "Civil Society Organizations (CSOs) rely on the internet to work effectively and help the people they aim to serve.",
    Story: "CSOs need the internet to reach the people they serve",
    Data: 0.91,
    "Question no": "11.2",
  },
  {
    Section: "General",
    Group: "Civil Society Organizations (CSOs) rely on the internet to work effectively and help the people they aim to serve.",
    Story:
      "CSOs believe that the internet is their most powerful communications tool",
    Data: 0.9,
    "Question no": "11.3",
  },
  {
    Section: "General",
    Group: "Civil Society Organizations (CSOs) rely on the internet to work effectively and help the people they aim to serve.",
    Story: "CSOs need the internet to fundraise",
    Data: 0.92,
    "Question no": "11.4",
  },
  {
    Section: "General",
    Group: "Civil Society Organizations (CSOs) rely on the internet to work effectively and help the people they aim to serve.",
    Story:
      "CSOs say that the internet is critical to their ability to support the people they serve",
    Data: 0.9,
    "Question no": "11.5",
  },
  {
    Section: "General",
    Group: "Civil Society Organizations (CSOs) rely on the internet to work effectively and help the people they aim to serve.",
    Story:
      "A lack of internet access, tools, or skills among staff or people served has limited the ability of CSOs to work",
    Data: 0.78,
    "Question no": "13",
  },
  {
    Section: "General",
    Group:
      "There are multiple barriers that CSOs and the people they serve face when trying to access the internet.\n",
    Story: "Top 3 barriers preventing CSOs from using the internet",
    Data: { "Lack of digital skills": 0.39 },
    "Question no": "14.1",
  },
  {
    Section: "General",
    Group:
      "There are multiple barriers that CSOs and the people they serve face when trying to access the internet.\n",
    Story: "Top 3 barriers preventing CSOs from using the internet",
    Data: { "Speed of internet": 0.39 },
    "Question no": "14.2",
  },
  {
    Section: "General",
    Group:
      "There are multiple barriers that CSOs and the people they serve face when trying to access the internet.\n",
    Story: "Top 3 barriers preventing CSOs from using the internet",
    Data: { "Reliability of internet": 0.32 },
    "Question no": "14.3",
  },
  {
    Section: "General",
    Group:
      "There are multiple barriers that CSOs and the people they serve face when trying to access the internet.\n",
    Story: "Top 3 barriers preventing the people CSOs serve from using the internet",
    Data: { "Lack of digital skills": 0.5 },
    "Question no": "15.1",
  },
  {
    Section: "General",
    Group:
      "There are multiple barriers that CSOs and the people they serve face when trying to access the internet.\n",
    Story: "Top 3 barriers preventing the people CSOs serve from using the internet",
    Data: { "Affordability of internet": 0.41 },
    "Question no": "15.2",
  },
  {
    Section: "General",
    Group:
      "There are multiple barriers that CSOs and the people they serve face when trying to access the internet.\n",
    Story: "Top 3 barriers preventing the people CSOs serve from using the internet",
    Data: { "Availability of internet": 0.35 },
    "Question no": "15.3",
  },
  {
    Section: "Access & Infrastructure",
    Group:
      "Even when connected, CSOs and the people they serve face many barriers to reliable internet access.",
    Story: "Barriers to reliable internet access",
    Data: { "Poor quality of infrastructure": 0.64 },
    "Question no": "18.1",
  },
  {
    Section: "Access & Infrastructure",
    Group:
      "Even when connected, CSOs and the people they serve face many barriers to reliable internet access.",
    Story: "",
    Data: { "Issues with their internet service provider": 0.61 },
    "Question no": "18.2",
  },
  {
    Section: "Access & Infrastructure",
    Group:
      "Even when connected, CSOs and the people they serve face many barriers to reliable internet access.",
    Story: "",
    Data: { "Power outages": 0.33 },
    "Question no": "18.3",
  },
  {
    Section: "Access & Infrastructure",
    Group:
      "Generally, CSOs have better access to the internet than the people they serve.",
    Story: "CSO participants have access to the internet at work",
    Data: 0.67,
    "Question no": "16.1",
  },
  {
    Section: "Access & Infrastructure",
    Group:
      "Generally, CSOs have better access to the internet than the people they serve.",
    Story: "People served have access to the internet",
    Data: 0.12,
    "Question no": "17.1",
  },
  {
    Section: "Affordability",
    Group:
      "Costs are a barrier to many CSOs. Internet access, devices, and digital tools and services are often not available at prices they can afford.",
    Story: "Percent of CSOs that say the following are expensive or unaffordable",
    Data: { "Tech Support": 0.65 },
    "Question no": "23.1",
  },
  {
    Section: "Affordability",
    Group:
      "Costs are a barrier to many CSOs. Internet access, devices, and digital tools and services are often not available at prices they can afford.",
    Story: "Percent of CSOs that say the following are expensive or unaffordable",
    Data: { Software: 0.64 },
    "Question no": "23.2",
  },
  {
    Section: "Affordability",
    Group:
      "Costs are a barrier to many CSOs. Internet access, devices, and digital tools and services are often not available at prices they can afford.",
    Story: "Percent of CSOs that say the following are expensive or unaffordable",
    Data: { Computers: 0.64 },
    "Question no": "23.3",
  },
  {
    Section: "Affordability",
    Group:
      "Costs are a barrier to many CSOs. Internet access, devices, and digital tools and services are often not available at prices they can afford.",
    Story: "Percent of CSOs that find all of the following expensive or unaffordable (internet access, mobile data, mobile phones, training, computers, software, tech support)",
    Data: 0.19,
    "Question no": "23.4",
  },
  {
    Section: "Affordability",
    Group:
      "Affordability is an even larger barrier for the people CSOs serve. \n",
    Story: "Affordability challenges for people CSOs serve",
    Data: { "Tech Support": 0.79 },
    "Question no": "24.1",
  },
  {
    Section: "Affordability",
    Group:
      "Affordability is an even larger barrier for the people CSOs serve. \n",
    Story: "Affordability challenges for people CSOs serve",
    Data: { Software: 0.81 },
    "Question no": "24.2",
  },
  {
    Section: "Affordability",
    Group:
      "Affordability is an even larger barrier for the people CSOs serve. \n",
    Story: "Affordability challenges for people CSOs serve",
    Data: { Computers: 0.81 },
    "Question no": "24.3",
  },
  {
    Section: "Affordability",
    Group:
      "Affordability is an even larger barrier for the people CSOs serve. \n",
    Story: "Percent of people that find all of the following expensive or unaffordable (internet access, mobile data, mobile phones, training, computers, software, tech support)",
    Data: 0.49,
    "Question no": "24.4",
  },
  {
    Section: "Policy",
    Group:
      'Effective rules and regulations can help more people access the internet and fully participate online. While the UN has declared that access to information and the internet are human rights, few countries protect access to the internet as a right.',
    Story: "CSOs that believe internet access is a basic right",
    Data: 0.91,
    "Question no": "35.1",
  },
  {
    Section: "Policy",
    Group:
      'Effective rules and regulations can help more people access the internet and fully participate online. While the UN has declared that access to information and the internet are human rights, few countries protect access to the internet as a right.',
    Story:
      "CSOs aware of their government supporting internet access as a basic right",
    Data: 0.59,
    "Question no": "35.2",
  },
  {
    Section: "Policy",
    Group:
      "Even when connected to the internet, if people don't feel safe online, they are less likely to connect and use the internet meaningfully.",
    Story: "CSO participants do not feel safe online",
    Data: 0.29,
    "Question no": "31.1",
  },
  {
    Section: "Policy",
    Group:
      "Even when connected to the internet, if people don't feel safe online, they are less likely to connect and use the internet meaningfully.",
    Story: "People CSOs serve do not feel safe online",
    Data: 0.41,
    "Question no": "32.1",
  },
  {
    Section: "Digital Skills",
    Group:
      "To more effectively deliver on their mission, CSOs must cultivate digital skills and knowledge within their workforce and among the people they serve.",
    Story: "CSOs with a digital strategy",
    Data: 0.15,
    "Question no": "28.1",
  },
  {
    Section: "Digital Skills",
    Group:
      "To more effectively deliver on their mission, CSOs must cultivate digital skills and knowledge within their workforce and among the people they serve.",
    Story: "CSO participants that say they are well-trained on digital tools",
    Data: 0.12,
    "Question no": "28.2",
  },
  {
    Section: "Digital Skills",
    Group:
      "To more effectively deliver on their mission, CSOs must cultivate digital skills and knowledge within their workforce and among the people they serve.",
    Story: "CSOs that provide digital training",
    Data: 0.4,
    "Question no": "29.1",
  },
  {
    Section: "Digital Skills",
    Group:
      "Many people CSOs served lack the skills needed to participate online and are without access to digital skills training.",
    Story: "People served have access to digital skills training",
    Data: 0.07,
    "Question no": "28.3",
  },
  {
    Section: "Digital Skills",
    Group:
      "Many people CSOs served lack the skills needed to participate online and are without access to digital skills training.",
    Story:
      "Percent of people relying on CSOs for digital skills training",
    Data: { "Nonprofit / CSO provides training in person and/or online": 0.57 },
    "Question no": "30.1",
  },
  {
    Section: "Content",
    Group:
      "Finally, there must be relevant resources, media, and tools available in local languages to encourage people to use the internet. Gaps in local language content limit access to critical information and services.",
    Story:
      "CSOs that agree that civic engagement content (including census, voter registration/voting, and jury duty) is available online in the local language.",
    Data: 0.46,
    "Question no": "38.1",
  },
  {
    Section: "Content",
    Group:
      "Finally, there must be relevant resources, media, and tools available in local languages to encourage people to use the internet. Gaps in local language content limit access to critical information and services.",
    Story: "CSOs that agree government services are available online in the local language",
    Data: 0.57,
    "Question no": "38.2",
  },
  {
    Section: "Content",
    Group:
      "Finally, there must be relevant resources, media, and tools available in local languages to encourage people to use the internet. Gaps in local language content limit access to critical information and services.",
    Story: "CSOs that agree online banking and other financial services are available online in the local language",
    Data: 0.57,
    "Question no": "38.3",
  },
  {
    Section: "Content",
    Group:
      "Finally, there must be relevant resources, media, and tools available in local languages to encourage people to use the internet. Gaps in local language content limit access to critical information and services.",
    Story: "CSOs that agree online health-related content like telehealth services are available in their local language",
    Data: 0.58,
    "Question no": "38.4",
  },
];

export { dashboardData };