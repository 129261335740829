import styles from "./css-modules/desc.module.css";
import Fade from 'react-reveal/Fade';
import Mesh from '../resources/mesh.png';

function Desc({ title, render }) {
  return (
    <div className={styles.container}>
      <img src={Mesh} className={styles.mesh}/>
      <Fade top distance='1em'>
        <h2 className={`${styles.title}`}>{title}</h2>
      </Fade>
      <Fade bottom distance='3em'>
        <div className={styles.render}>
            {render}
        </div>    
       </Fade>
    </div>
  );
}

export default Desc;
