import Navbar from "../components/navbar";
import styles from "./css-modules/common.module.css";
import { useRef, useLayoutEffect, useEffect, useState } from "react";
import TweenLite from "gsap";
import Footer from "../components/footer";
import SectionTitle from "../components/section_title";
import dashStyles from "./css-modules/dash.module.css";
import CustomDropDown from "../components/drop_down";
import CommentRenderer from '../components/comment_renderer';
import VoicesP from '../resources/Cards/Compressed/Voices.webp';

import { fetchCountryComments } from "../data/fetchDataFunctions";

function Voices({
  logo,
  pageFade,
  setPageFade,
  onRouteChange,
  regions,
  voicesRegion,
  setVoicesRegion,
  closeMobileNav,
  mobileNav
}) {
  const navRef = useRef();
  const contentRef = useRef();

  const [regionCountryList, setRegionCountryList] = useState([]);
  const [regionComments, setRegionComments] = useState([]);
  const [currentComments, setCurrentComments] = useState([]);
  const [activeFilters, setActiveFilters] = useState(
    {'CH Country' : "United States of America",
     'CH Region' : "North America",
     'Count of Vrid' :  "1574",
     'Country Code' : "US",
     'GlobalRegion' : "North"
    });

  useLayoutEffect(() => {
    if (pageFade) {
      TweenLite.fromTo(
        navRef.current,
        0.45,
        { opacity: 0, y: -10 },
        { opacity: 1, y: 0 }
      ).delay(0.15);
      TweenLite.fromTo(
        contentRef.current,
        0.8,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0 }
      ).delay(0.5);
      setPageFade(false);
    }

    setCurrentComments(fetchCountryComments(voicesRegion)['filteredData']);

  }, [pageFade]);

  useEffect(() => {
    setRegionCountryList(fetchCountryComments(voicesRegion)['filterOptions']);
    setActiveFilters(fetchCountryComments(voicesRegion)['filterOptions'][0]);
    setRegionComments(fetchCountryComments(voicesRegion)['filteredData']);
    let arr = regionComments.filter((comm) => comm["HQ Country"] === activeFilters["CH Country"]);
    setCurrentComments(fetchCountryComments(arr));
  }, [voicesRegion]);

  useEffect(() => {
      let arr = regionComments.filter((comm) => comm["HQ Country"] === activeFilters["CH Country"]);
      setCurrentComments(arr);
  }, [activeFilters]);

  let updateActiveFilters = (filter) => {
    setActiveFilters(filter);
  }


  return (
    <div>
      <Navbar
        curved
        assignRef={navRef}
        nav={['State of Digital Inequity', 'Data Dashboard', 'Voices of Civil Society', 'About the Dashboard']}        
        logo={logo}
        onRouteChange={onRouteChange}
        closeMobileNav={closeMobileNav}
        mobileNav={mobileNav}
      />
      <div ref={contentRef} className={`${styles.content_container}`}>
        <SectionTitle
          title={"Voices of civil Society"}
          desc="The quotes on this page are sourced from the i____internet Digital Equity Survey and represent a range of civil society organizations’ experience with digital equity. Respondents were invited to leave optional comments at the end of the survey and we have removed identifiable information before publishing. Quotes are shared here to demonstrate a diversity of perspectives and to be used to fit your purpose: anecdotally, in your research, on grant proposals to add qualitative, lived perspectives to the conversation around digital equity. "
          removeRadius
        />
        <SectionTitle
          sticky
          jsx={
            <div className={`${dashStyles.inputs_container}`}>
              <div className={dashStyles.flexed_inputs}>
                {regions.length > 0 && (
                  <CustomDropDown
                    title="Select a Region"
                    stateVar={voicesRegion}
                    stateFunction={setVoicesRegion}
                    options={regions.filter((el) => el.name !== 'Middle East')}
                    optionLabel="name"
                    invert
                  />
                )}
                {regionCountryList.length > 0 && (
                  <CustomDropDown
                    title="Country"
                    stateVar={activeFilters}
                    stateFunction={updateActiveFilters}
                    options={regionCountryList}
                    optionLabel="CH Country"
                    invert
                  />
                )}
              </div>
            </div>
          }
        />
        <div className={styles.flexed_content}>
          <div className={styles.voices_container}>
            {currentComments.length > 0 && currentComments.map((comment, index) => {
              return <div key={index}><CommentRenderer {...{comment}}/></div>
            })}
          </div>
        </div>

        <Footer {...{ logo }} />
      </div>
    </div>
  );
}

export default Voices;
