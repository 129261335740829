import { BoxProportion } from "../../components/non_d3/boxProportion";
import { Grid } from "../../components/non_d3/grid";
import boxStyles from '../../components/css-modules/boxPair.module.css';
import { CountryInfo, BoxPair, ChartAndCaption } from "../../components/BoxPair";
import { ChartFlexRow } from "../../components/ChartFlexRow";
import { DonutWrappedInDiv } from "../../components/d3/donut";
import { DialWrappedInDiv } from "../../components/d3/dial";
import { BarPair } from "../../components/barPair";
import { BarWrappedInDiv } from "../../components/non_d3/boxProportion";
import { ComparisonBar } from "../../components/non_d3/comparison_bar";
import { SimpleBarAndLabelPair } from "../../components/non_d3/barsAndLabels";
import { MdSignalCellularConnectedNoInternet1Bar } from "react-icons/md";
import { GrTechnology } from "react-icons/gr";
import { ImPowerCord } from "react-icons/im";

function AccessInfra({ animateGraphs, fetchTitle, fetchReasonTitle, primaryCountry, secondaryCountry, fetchQuestionProportion, fetchQuestionPercent, ac_Ref }) {
  return (
    <div ref={ac_Ref}>
      <p className='paddedCaption'><span className={boxStyles.caption}>{fetchTitle("18.1", "Story")}</span></p>
      <ChartFlexRow>
        <ChartAndCaption
          caption={fetchReasonTitle("18.1")}
          iconPresent
          captionCentered
          icon={GrTechnology}
        >
          <BoxPair>
            {fetchQuestionPercent(primaryCountry, "18.1") ? <DonutWrappedInDiv percent={fetchQuestionPercent(primaryCountry, "18.1")} arcFill="#0346AD" {...{animateGraphs}}/> : <></>}
            {fetchQuestionPercent(primaryCountry, "18.1") ? <CountryInfo countryName={primaryCountry.name} center /> : <p style={{fontSize: '0.935em'}}>No responses from {primaryCountry.name} to this question</p>}
            {fetchQuestionPercent(secondaryCountry, "18.1") ? <DonutWrappedInDiv percent={fetchQuestionPercent(secondaryCountry, "18.1")} arcFill="#A1ACFD" {...{animateGraphs}}/>  : <></>}
            {fetchQuestionPercent(secondaryCountry, "18.1") ? <CountryInfo countryName={secondaryCountry.name} center /> : <p style={{fontSize: '0.935em'}}>No responses from {secondaryCountry.name} to this question</p>}
          </BoxPair>
        </ChartAndCaption>
        <ChartAndCaption
          caption={fetchReasonTitle("18.2")}
          iconPresent
          captionCentered
          icon={MdSignalCellularConnectedNoInternet1Bar}
        >
          <BoxPair>
            {fetchQuestionPercent(primaryCountry, "18.2") ? <DialWrappedInDiv bGCol="#0346AD" needleCol="grey" percVal={fetchQuestionPercent(primaryCountry, "18.2")} {...{animateGraphs}}/> : <></>}
            {fetchQuestionPercent(primaryCountry, "18.2") ? <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "18.2")} center /> : <p style={{fontSize: '0.935em'}}>No responses from {primaryCountry.name} to this question</p>}
            {fetchQuestionPercent(secondaryCountry, "18.2") ? <DialWrappedInDiv bGCol="#A1ACFD" needleCol="grey" percVal={fetchQuestionPercent(secondaryCountry, "18.2")} {...{animateGraphs}}/>  : <></>}
            {fetchQuestionPercent(secondaryCountry, "18.2") ? <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "18.2")} center />  : <p style={{fontSize: '0.935em'}}>No responses from {secondaryCountry.name} to this question</p>}
          </BoxPair>
        </ChartAndCaption>
        <ChartAndCaption
          caption={fetchReasonTitle("18.3")}
          iconPresent
          captionCentered
          icon={ImPowerCord}
        >
          <BoxPair>
            {fetchQuestionPercent(primaryCountry, "18.3") ? <DonutWrappedInDiv percent={fetchQuestionPercent(primaryCountry, "18.3")} arcFill="#0346AD" {...{animateGraphs}}/>  : <></>}
            {fetchQuestionPercent(primaryCountry, "18.3") ? <CountryInfo countryName={primaryCountry.name} center /> : <p style={{fontSize: '0.935em'}}>No responses from {primaryCountry.name} to this question</p>}
            {fetchQuestionPercent(secondaryCountry, "18.3") ? <DonutWrappedInDiv percent={fetchQuestionPercent(secondaryCountry, "18.3")} arcFill="#A1ACFD" {...{animateGraphs}}/> : <></>}
            {fetchQuestionPercent(secondaryCountry, "18.3") ? <CountryInfo countryName={secondaryCountry.name} center /> : <p style={{fontSize: '0.935em'}}>No responses from {secondaryCountry.name} to this question</p>}
          </BoxPair>
        </ChartAndCaption>
      </ChartFlexRow>

      <p className='dashSection_desc'>{fetchTitle("16.1", "Group")}</p>

      <ChartFlexRow>
        <ChartAndCaption caption={fetchTitle("16.1", "Story")}>
            <BarPair>
                <BarWrappedInDiv proportion={fetchQuestionProportion(primaryCountry, "16.1")} boxFill="#0346AD" {...{animateGraphs}}/>
                <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "16.1")}/>
                <BarWrappedInDiv proportion={fetchQuestionProportion(secondaryCountry, "16.1")} boxFill="#A1ACFD" {...{animateGraphs}}/>
                <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "16.1")}/>
          </BarPair>
        </ChartAndCaption>
        <ChartAndCaption caption={fetchTitle("17.1", "Story")}>
          <BarPair>
                <BarWrappedInDiv proportion={fetchQuestionProportion(primaryCountry, "17.1")} boxFill="#0346AD" {...{animateGraphs}}/>
                <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "17.1")}/>
                <BarWrappedInDiv proportion={fetchQuestionProportion(secondaryCountry, "17.1")} boxFill="#A1ACFD" {...{animateGraphs}}/>
                <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "17.1")}/>
          </BarPair>
        </ChartAndCaption>
      </ChartFlexRow>
    </div>
  );
}

export default AccessInfra;
