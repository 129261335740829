import styles from './css-modules/landing.module.css';

import InfraIcon from '../resources/Cards/Icons/infrastructure.png';
import AffordIcon from '../resources/Cards/Icons/affordability.png';
import PolicyIcon from '../resources/Cards/Icons/policy.png';
import DigitalIcon from '../resources/Cards/Icons/digitalSkills.png';
import ContentIcon from '../resources/Cards/Icons/content.png';
import donutImage from '../resources/donut.png';

import { FaChevronDown } from "react-icons/fa";

import Fade from 'react-reveal/Fade';

function Home({ heading }) {


    let animationData = [{
        icon: InfraIcon,
        title: 'Access & Infrastructure',
        type: 'square',
        color: '#2C3266',
        textC: '#fff',
    },{
        icon: AffordIcon,
        title: 'Affordability',
        type: 'circle',
        color: '#8796FF',
        textC: '#fff',
    },{
        icon: DigitalIcon,
        title: 'Digital Skills',
        type: 'square',
        color: '#EC5C43',
        textC: '#fff',
    },{
        icon: ContentIcon,
        title: 'Content',
        type: 'circle',
        color: '#0346AD',
        textC: '#fff',
    },{
        icon: PolicyIcon,
        title: 'Policy',
        type: 'square',
        color: '#BFB2FC',
        textC: '#fff',
    }];

    return (
        <div className={styles.container}>
            <div className={styles.content_container}>
                <h1 className={styles.heading}>{heading}</h1>
            </div>

            <Fade left distance='4em'>
                <div className={styles.decor_img}>
                    <img src='https://connecthumanity.fund/wp-content/uploads/2023/01/shorthand/5298/cdnDAhmNzK/assets/wQx1vEqzGc/pexels-fauxels-3183197-1-2560x1440.webp'/>
                </div>
            </Fade>

            <div className={styles.animation_container}>
                <img src={donutImage} className={styles.donut}/>
                {animationData.length > 0 && animationData.map((item, index) => {
                    return <div className={`${styles.section_cont} ${styles[item.type]} ${styles['anim' + index]}`} style={{backgroundColor: item.color, color: item.textC}}>
                        <div className={`${styles.anim_content} ${styles['animT' + index]}`}>
                            <img src={item.icon} className={styles.icon}/>
                            <p className={styles.anim_title}>{item.title}</p>
                        </div>
                    </div>
                })}
            </div>

            <div className={styles.scroll_cont}>
                <p>Scroll Down</p>
                <div className={styles.circle}>
                    <FaChevronDown className={styles.chev}/>
                </div>                
            </div>
        </div>
    );
}

export default Home;
