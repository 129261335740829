import styles from "./css-modules/report.module.css";
import ReportT from '../resources/cover.webp';

function ReportDownload({ onRouteChange }) {
  return (
    <div className={styles.container}>
        <div className={styles.report_dwn_cont}>
            <img src={ReportT} className={styles.report_title}/>
            <h3>State of Digital Inequity: Civil Society Perspectives on Barriers to Progress in our Digitizing World</h3>
            <div className={styles.flexed_buttons}>
                <div 
                    onClick={() => window.open('https://connecthumanity.fund/wp-content/uploads/2023/01/Connect-Humanity-Digital-Equity-01-24-23-FINAL2.pdf')}
                    className={styles.button}>
                        <p>Download Report</p>
                </div>
                <div 
                    onClick={() => window.open('https://connecthumanity.fund/story/state-of-digital-inequity-exec-summary/')}
                    className={styles.button}>
                        <p>Executive Summary</p>
                </div>
            </div>
        </div>
        <div className={styles.dataDash}>
            <img src='https://connecthumanity.fund/wp-content/uploads/2023/01/shorthand/5298/cdnDAhmNzK/assets/DqnzKGkUyo/pexels-zch-12498767-1-2560x1440.jpg' className={styles.image_cont}/>
            <div className={styles.dash_link}>
                <img src={ReportT} className={styles.report_title} style={{opacity: 0}}/>
                <h3>Data Dashboard</h3>
                <div 
                    onClick={() => onRouteChange('/data')}
                    className={`${styles.button_inv} ${styles.button}`}>
                        <p>Explore Data</p>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ReportDownload;
