const stats = {
    "13": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctTrue": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctTrue": "0.67",
          "percent": 0.67
        },
        "AF": {
          "Survey CC": "AF",
          "PctTrue": "1",
          "percent": 1
        },
        "AL": {
          "Survey CC": "AL",
          "PctTrue": "0.67",
          "percent": 0.67
        },
        "AO": {
          "Survey CC": "AO",
          "PctTrue": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctTrue": "0.82",
          "percent": 0.82
        },
        "AT": {
          "Survey CC": "AT",
          "PctTrue": "0.67",
          "percent": 0.67
        },
        "AU": {
          "Survey CC": "AU",
          "PctTrue": "0.72",
          "percent": 0.72
        },
        "BD": {
          "Survey CC": "BD",
          "PctTrue": "0.6",
          "percent": 0.6
        },
        "BE": {
          "Survey CC": "BE",
          "PctTrue": "0.72",
          "percent": 0.72
        },
        "BG": {
          "Survey CC": "BG",
          "PctTrue": "0.79",
          "percent": 0.79
        },
        "BH": {
          "Survey CC": "BH",
          "PctTrue": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctTrue": "1",
          "percent": 1
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctTrue": "1",
          "percent": 1
        },
        "BO": {
          "Survey CC": "BO",
          "PctTrue": "1",
          "percent": 1
        },
        "BR": {
          "Survey CC": "BR",
          "PctTrue": "0.87",
          "percent": 0.87
        },
        "BS": {
          "Survey CC": "BS",
          "PctTrue": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctTrue": "1",
          "percent": 1
        },
        "CA": {
          "Survey CC": "CA",
          "PctTrue": "0.72",
          "percent": 0.72
        },
        "CD": {
          "Survey CC": "CD",
          "PctTrue": "0.88",
          "percent": 0.88
        },
        "CF": {
          "Survey CC": "CF",
          "PctTrue": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctTrue": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctTrue": "0.6",
          "percent": 0.6
        },
        "CI": {
          "Survey CC": "CI",
          "PctTrue": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctTrue": "0.8",
          "percent": 0.8
        },
        "CM": {
          "Survey CC": "CM",
          "PctTrue": "1",
          "percent": 1
        },
        "CN": {
          "Survey CC": "CN",
          "PctTrue": "0.75",
          "percent": 0.75
        },
        "CO": {
          "Survey CC": "CO",
          "PctTrue": "0.9",
          "percent": 0.9
        },
        "CR": {
          "Survey CC": "CR",
          "PctTrue": "0.75",
          "percent": 0.75
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctTrue": "0.85",
          "percent": 0.85
        },
        "DE": {
          "Survey CC": "DE",
          "PctTrue": "0.7",
          "percent": 0.7
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctTrue": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctTrue": "0.59",
          "percent": 0.59
        },
        "DO": {
          "Survey CC": "DO",
          "PctTrue": "1",
          "percent": 1
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctTrue": "0.33",
          "percent": 0.33
        },
        "EC": {
          "Survey CC": "EC",
          "PctTrue": "0.7",
          "percent": 0.7
        },
        "EE": {
          "Survey CC": "EE",
          "PctTrue": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctTrue": "0.83",
          "percent": 0.83
        },
        "ES": {
          "Survey CC": "ES",
          "PctTrue": "0.67",
          "percent": 0.67
        },
        "ET": {
          "Survey CC": "ET",
          "PctTrue": "1",
          "percent": 1
        },
        "FI": {
          "Survey CC": "FI",
          "PctTrue": "0.45",
          "percent": 0.45
        },
        "FR": {
          "Survey CC": "FR",
          "PctTrue": "0.92",
          "percent": 0.92
        },
        "GA": {
          "Survey CC": "GA",
          "PctTrue": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctTrue": "0.71",
          "percent": 0.71
        },
        "GE": {
          "Survey CC": "GE",
          "PctTrue": "1",
          "percent": 1
        },
        "GH": {
          "Survey CC": "GH",
          "PctTrue": "0.89",
          "percent": 0.89
        },
        "GN": {
          "Survey CC": "GN",
          "PctTrue": "0.75",
          "percent": 0.75
        },
        "GR": {
          "Survey CC": "GR",
          "PctTrue": "0.83",
          "percent": 0.83
        },
        "GT": {
          "Survey CC": "GT",
          "PctTrue": "0.7",
          "percent": 0.7
        },
        "HK": {
          "Survey CC": "HK",
          "PctTrue": "0.53",
          "percent": 0.53
        },
        "HN": {
          "Survey CC": "HN",
          "PctTrue": "0.86",
          "percent": 0.86
        },
        "HR": {
          "Survey CC": "HR",
          "PctTrue": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctTrue": "1",
          "percent": 1
        },
        "HU": {
          "Survey CC": "HU",
          "PctTrue": "0.75",
          "percent": 0.75
        },
        "ID": {
          "Survey CC": "ID",
          "PctTrue": "0.92",
          "percent": 0.92
        },
        "IE": {
          "Survey CC": "IE",
          "PctTrue": "0.83",
          "percent": 0.83
        },
        "IN": {
          "Survey CC": "IN",
          "PctTrue": "0.87",
          "percent": 0.87
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctTrue": "0.75",
          "percent": 0.75
        },
        "IT": {
          "Survey CC": "IT",
          "PctTrue": "0.8",
          "percent": 0.8
        },
        "JO": {
          "Survey CC": "JO",
          "PctTrue": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctTrue": "0.73",
          "percent": 0.73
        },
        "KE": {
          "Survey CC": "KE",
          "PctTrue": "0.91",
          "percent": 0.91
        },
        "KG": {
          "Survey CC": "KG",
          "PctTrue": "1",
          "percent": 1
        },
        "KH": {
          "Survey CC": "KH",
          "PctTrue": "1",
          "percent": 1
        },
        "KR": {
          "Survey CC": "KR",
          "PctTrue": "0.39",
          "percent": 0.39
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctTrue": "1",
          "percent": 1
        },
        "LB": {
          "Survey CC": "LB",
          "PctTrue": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctTrue": "0.88",
          "percent": 0.88
        },
        "LR": {
          "Survey CC": "LR",
          "PctTrue": "1",
          "percent": 1
        },
        "LT": {
          "Survey CC": "LT",
          "PctTrue": "0.8",
          "percent": 0.8
        },
        "LU": {
          "Survey CC": "LU",
          "PctTrue": "0.5",
          "percent": 0.5
        },
        "LV": {
          "Survey CC": "LV",
          "PctTrue": "0.5",
          "percent": 0.5
        },
        "LY": {
          "Survey CC": "LY",
          "PctTrue": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctTrue": "0.87",
          "percent": 0.87
        },
        "ML": {
          "Survey CC": "ML",
          "PctTrue": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctTrue": "1",
          "percent": 1
        },
        "MN": {
          "Survey CC": "MN",
          "PctTrue": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctTrue": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctTrue": "0",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctTrue": "1",
          "percent": 1
        },
        "MW": {
          "Survey CC": "MW",
          "PctTrue": "1",
          "percent": 1
        },
        "MX": {
          "Survey CC": "MX",
          "PctTrue": "0.76",
          "percent": 0.76
        },
        "MY": {
          "Survey CC": "MY",
          "PctTrue": "0.94",
          "percent": 0.94
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctTrue": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctTrue": "1",
          "percent": 1
        },
        "NE": {
          "Survey CC": "NE",
          "PctTrue": "1",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctTrue": "0.98",
          "percent": 0.98
        },
        "NI": {
          "Survey CC": "NI",
          "PctTrue": "0.67",
          "percent": 0.67
        },
        "NL": {
          "Survey CC": "NL",
          "PctTrue": "0.69",
          "percent": 0.69
        },
        "NP": {
          "Survey CC": "NP",
          "PctTrue": "0.8",
          "percent": 0.8
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctTrue": "0.62",
          "percent": 0.62
        },
        "PA": {
          "Survey CC": "PA",
          "PctTrue": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctTrue": "0.88",
          "percent": 0.88
        },
        "PH": {
          "Survey CC": "PH",
          "PctTrue": "0.84",
          "percent": 0.84
        },
        "PK": {
          "Survey CC": "PK",
          "PctTrue": "0.75",
          "percent": 0.75
        },
        "PL": {
          "Survey CC": "PL",
          "PctTrue": "0.84",
          "percent": 0.84
        },
        "PR": {
          "Survey CC": "PR",
          "PctTrue": "1",
          "percent": 1
        },
        "PS": {
          "Survey CC": "PS",
          "PctTrue": "0",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctTrue": "0.82",
          "percent": 0.82
        },
        "PY": {
          "Survey CC": "PY",
          "PctTrue": "1",
          "percent": 1
        },
        "RO": {
          "Survey CC": "RO",
          "PctTrue": "0.88",
          "percent": 0.88
        },
        "RS": {
          "Survey CC": "RS",
          "PctTrue": "1",
          "percent": 1
        },
        "RW": {
          "Survey CC": "RW",
          "PctTrue": "0.75",
          "percent": 0.75
        },
        "SA": {
          "Survey CC": "SA",
          "PctTrue": "0.79",
          "percent": 0.79
        },
        "SD": {
          "Survey CC": "SD",
          "PctTrue": "1",
          "percent": 1
        },
        "SE": {
          "Survey CC": "SE",
          "PctTrue": "0.79",
          "percent": 0.79
        },
        "SG": {
          "Survey CC": "SG",
          "PctTrue": "0.77",
          "percent": 0.77
        },
        "SI": {
          "Survey CC": "SI",
          "PctTrue": "0.79",
          "percent": 0.79
        },
        "SK": {
          "Survey CC": "SK",
          "PctTrue": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctTrue": "1",
          "percent": 1
        },
        "SN": {
          "Survey CC": "SN",
          "PctTrue": "0.67",
          "percent": 0.67
        },
        "SO": {
          "Survey CC": "SO",
          "PctTrue": "1",
          "percent": 1
        },
        "SR": {
          "Survey CC": "SR",
          "PctTrue": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctTrue": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctTrue": "0.5",
          "percent": 0.5
        },
        "SY": {
          "Survey CC": "SY",
          "PctTrue": "1",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctTrue": "1",
          "percent": 1
        },
        "TD": {
          "Survey CC": "TD",
          "PctTrue": "0.5",
          "percent": 0.5
        },
        "TG": {
          "Survey CC": "TG",
          "PctTrue": "1",
          "percent": 1
        },
        "TH": {
          "Survey CC": "TH",
          "PctTrue": "0.89",
          "percent": 0.89
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctTrue": "1",
          "percent": 1
        },
        "TR": {
          "Survey CC": "TR",
          "PctTrue": "0.93",
          "percent": 0.93
        },
        "TT": {
          "Survey CC": "TT",
          "PctTrue": "1",
          "percent": 1
        },
        "TW": {
          "Survey CC": "TW",
          "PctTrue": "0.79",
          "percent": 0.79
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctTrue": "0.92",
          "percent": 0.92
        },
        "UA": {
          "Survey CC": "UA",
          "PctTrue": "0.92",
          "percent": 0.92
        },
        "UG": {
          "Survey CC": "UG",
          "PctTrue": "0.93",
          "percent": 0.93
        },
        "US": {
          "Survey CC": "US",
          "PctTrue": "0.7",
          "percent": 0.7
        },
        "UY": {
          "Survey CC": "UY",
          "PctTrue": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctTrue": "0.83",
          "percent": 0.83
        },
        "VI": {
          "Survey CC": "VI",
          "PctTrue": "1",
          "percent": 1
        },
        "VN": {
          "Survey CC": "VN",
          "PctTrue": "1",
          "percent": 1
        },
        "YE": {
          "Survey CC": "YE",
          "PctTrue": "1",
          "percent": 1
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctTrue": "0.92",
          "percent": 0.92
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctTrue": "1",
          "percent": 1
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctTrue": "0.83",
          "percent": 0.83
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctTrue": "0.73",
          "percent": 0.73
        },
        "South": {
          "GlobalRegion": "South",
          "PctTrue": "0.89",
          "percent": 0.89
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctTrue": "0.78",
          "percent": 0.78
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctTrue": "0.93",
          "percent": 0.93
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctTrue": "0.79",
          "percent": 0.79
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctTrue": "1",
          "percent": 1
        },
        "Europe": {
          "CH Region": "Europe",
          "PctTrue": "0.8",
          "percent": 0.8
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctTrue": "0.83",
          "percent": 0.83
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctTrue": "0.79",
          "percent": 0.79
        },
        "North America": {
          "CH Region": "North America",
          "PctTrue": "0.7",
          "percent": 0.7
        },
        "Total": {
          "CH Region": "Total",
          "PctTrue": "0.78",
          "percent": 0.78
        }
      }
    },
    "11.1": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "AF": {
          "Survey CC": "AF",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "AL": {
          "Survey CC": "AL",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AO": {
          "Survey CC": "AO",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "AT": {
          "Survey CC": "AT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AU": {
          "Survey CC": "AU",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "BD": {
          "Survey CC": "BD",
          "PctAgreeAndStronglyAgree": "0.8",
          "percent": 0.8
        },
        "BE": {
          "Survey CC": "BE",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "BG": {
          "Survey CC": "BG",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "BH": {
          "Survey CC": "BH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BO": {
          "Survey CC": "BO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BR": {
          "Survey CC": "BR",
          "PctAgreeAndStronglyAgree": "0.98",
          "percent": 0.98
        },
        "BS": {
          "Survey CC": "BS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CA": {
          "Survey CC": "CA",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "CD": {
          "Survey CC": "CD",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "CF": {
          "Survey CC": "CF",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "CI": {
          "Survey CC": "CI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctAgreeAndStronglyAgree": "0.78",
          "percent": 0.78
        },
        "CM": {
          "Survey CC": "CM",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "CN": {
          "Survey CC": "CN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CO": {
          "Survey CC": "CO",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "CR": {
          "Survey CC": "CR",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "DE": {
          "Survey CC": "DE",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "DO": {
          "Survey CC": "DO",
          "PctAgreeAndStronglyAgree": "0.6",
          "percent": 0.6
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctAgreeAndStronglyAgree": "0.33",
          "percent": 0.33
        },
        "EC": {
          "Survey CC": "EC",
          "PctAgreeAndStronglyAgree": "0.8",
          "percent": 0.8
        },
        "EE": {
          "Survey CC": "EE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "ES": {
          "Survey CC": "ES",
          "PctAgreeAndStronglyAgree": "0.98",
          "percent": 0.98
        },
        "ET": {
          "Survey CC": "ET",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "FI": {
          "Survey CC": "FI",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "FR": {
          "Survey CC": "FR",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "GA": {
          "Survey CC": "GA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "GE": {
          "Survey CC": "GE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GH": {
          "Survey CC": "GH",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "GN": {
          "Survey CC": "GN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GR": {
          "Survey CC": "GR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GT": {
          "Survey CC": "GT",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "HK": {
          "Survey CC": "HK",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "HN": {
          "Survey CC": "HN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HR": {
          "Survey CC": "HR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HU": {
          "Survey CC": "HU",
          "PctAgreeAndStronglyAgree": "0.98",
          "percent": 0.98
        },
        "ID": {
          "Survey CC": "ID",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "IE": {
          "Survey CC": "IE",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "IN": {
          "Survey CC": "IN",
          "PctAgreeAndStronglyAgree": "0.84",
          "percent": 0.84
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "IT": {
          "Survey CC": "IT",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "JO": {
          "Survey CC": "JO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "JP": {
          "Survey CC": "JP",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KE": {
          "Survey CC": "KE",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "KG": {
          "Survey CC": "KG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KH": {
          "Survey CC": "KH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KR": {
          "Survey CC": "KR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LB": {
          "Survey CC": "LB",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LR": {
          "Survey CC": "LR",
          "PctAgreeAndStronglyAgree": "0.78",
          "percent": 0.78
        },
        "LT": {
          "Survey CC": "LT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LU": {
          "Survey CC": "LU",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LV": {
          "Survey CC": "LV",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LY": {
          "Survey CC": "LY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "ML": {
          "Survey CC": "ML",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MN": {
          "Survey CC": "MN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MW": {
          "Survey CC": "MW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MX": {
          "Survey CC": "MX",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "MY": {
          "Survey CC": "MY",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "NE": {
          "Survey CC": "NE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "NI": {
          "Survey CC": "NI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NL": {
          "Survey CC": "NL",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "NP": {
          "Survey CC": "NP",
          "PctAgreeAndStronglyAgree": "0.8",
          "percent": 0.8
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "PA": {
          "Survey CC": "PA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PH": {
          "Survey CC": "PH",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "PK": {
          "Survey CC": "PK",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "PL": {
          "Survey CC": "PL",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "PR": {
          "Survey CC": "PR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PS": {
          "Survey CC": "PS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "PY": {
          "Survey CC": "PY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "RO": {
          "Survey CC": "RO",
          "PctAgreeAndStronglyAgree": "0.99",
          "percent": 0.99
        },
        "RS": {
          "Survey CC": "RS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "RW": {
          "Survey CC": "RW",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "SA": {
          "Survey CC": "SA",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "SD": {
          "Survey CC": "SD",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "SG": {
          "Survey CC": "SG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SI": {
          "Survey CC": "SI",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "SK": {
          "Survey CC": "SK",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "SN": {
          "Survey CC": "SN",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "SO": {
          "Survey CC": "SO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SR": {
          "Survey CC": "SR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "SY": {
          "Survey CC": "SY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TD": {
          "Survey CC": "TD",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TG": {
          "Survey CC": "TG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TH": {
          "Survey CC": "TH",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TR": {
          "Survey CC": "TR",
          "PctAgreeAndStronglyAgree": "0.99",
          "percent": 0.99
        },
        "TT": {
          "Survey CC": "TT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TW": {
          "Survey CC": "TW",
          "PctAgreeAndStronglyAgree": "0.98",
          "percent": 0.98
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "UA": {
          "Survey CC": "UA",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "UG": {
          "Survey CC": "UG",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "US": {
          "Survey CC": "US",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "UY": {
          "Survey CC": "UY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "VI": {
          "Survey CC": "VI",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "VN": {
          "Survey CC": "VN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "YE": {
          "Survey CC": "YE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "South": {
          "GlobalRegion": "South",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "Europe": {
          "CH Region": "Europe",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "North America": {
          "CH Region": "North America",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "Total": {
          "CH Region": "Total",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        }
      }
    },
    "11.2": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "AF": {
          "Survey CC": "AF",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "AL": {
          "Survey CC": "AL",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AO": {
          "Survey CC": "AO",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "AT": {
          "Survey CC": "AT",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "AU": {
          "Survey CC": "AU",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "BD": {
          "Survey CC": "BD",
          "PctAgreeAndStronglyAgree": "0.8",
          "percent": 0.8
        },
        "BE": {
          "Survey CC": "BE",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "BG": {
          "Survey CC": "BG",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "BH": {
          "Survey CC": "BH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BO": {
          "Survey CC": "BO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BR": {
          "Survey CC": "BR",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "BS": {
          "Survey CC": "BS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CA": {
          "Survey CC": "CA",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "CD": {
          "Survey CC": "CD",
          "PctAgreeAndStronglyAgree": "0.71",
          "percent": 0.71
        },
        "CF": {
          "Survey CC": "CF",
          "PctAgreeAndStronglyAgree": "0.33",
          "percent": 0.33
        },
        "CG": {
          "Survey CC": "CG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctAgreeAndStronglyAgree": "0.6",
          "percent": 0.6
        },
        "CI": {
          "Survey CC": "CI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "CM": {
          "Survey CC": "CM",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "CN": {
          "Survey CC": "CN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CO": {
          "Survey CC": "CO",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "CR": {
          "Survey CC": "CR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctAgreeAndStronglyAgree": "0.84",
          "percent": 0.84
        },
        "DE": {
          "Survey CC": "DE",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "DO": {
          "Survey CC": "DO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctAgreeAndStronglyAgree": "0.33",
          "percent": 0.33
        },
        "EC": {
          "Survey CC": "EC",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "EE": {
          "Survey CC": "EE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "ES": {
          "Survey CC": "ES",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "ET": {
          "Survey CC": "ET",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "FI": {
          "Survey CC": "FI",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "FR": {
          "Survey CC": "FR",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "GA": {
          "Survey CC": "GA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "GE": {
          "Survey CC": "GE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GH": {
          "Survey CC": "GH",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "GN": {
          "Survey CC": "GN",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "GR": {
          "Survey CC": "GR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GT": {
          "Survey CC": "GT",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "HK": {
          "Survey CC": "HK",
          "PctAgreeAndStronglyAgree": "0.84",
          "percent": 0.84
        },
        "HN": {
          "Survey CC": "HN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HR": {
          "Survey CC": "HR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HU": {
          "Survey CC": "HU",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "ID": {
          "Survey CC": "ID",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "IE": {
          "Survey CC": "IE",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "IN": {
          "Survey CC": "IN",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "IT": {
          "Survey CC": "IT",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "JO": {
          "Survey CC": "JO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "JP": {
          "Survey CC": "JP",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "KE": {
          "Survey CC": "KE",
          "PctAgreeAndStronglyAgree": "0.84",
          "percent": 0.84
        },
        "KG": {
          "Survey CC": "KG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KH": {
          "Survey CC": "KH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KR": {
          "Survey CC": "KR",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "LB": {
          "Survey CC": "LB",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "LR": {
          "Survey CC": "LR",
          "PctAgreeAndStronglyAgree": "0.78",
          "percent": 0.78
        },
        "LT": {
          "Survey CC": "LT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LU": {
          "Survey CC": "LU",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "LV": {
          "Survey CC": "LV",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LY": {
          "Survey CC": "LY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "ML": {
          "Survey CC": "ML",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MN": {
          "Survey CC": "MN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MW": {
          "Survey CC": "MW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MX": {
          "Survey CC": "MX",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "MY": {
          "Survey CC": "MY",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NE": {
          "Survey CC": "NE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "NI": {
          "Survey CC": "NI",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "NL": {
          "Survey CC": "NL",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "NP": {
          "Survey CC": "NP",
          "PctAgreeAndStronglyAgree": "0.6",
          "percent": 0.6
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "PA": {
          "Survey CC": "PA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PH": {
          "Survey CC": "PH",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "PK": {
          "Survey CC": "PK",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "PL": {
          "Survey CC": "PL",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "PR": {
          "Survey CC": "PR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PS": {
          "Survey CC": "PS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "PY": {
          "Survey CC": "PY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "RO": {
          "Survey CC": "RO",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "RS": {
          "Survey CC": "RS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "RW": {
          "Survey CC": "RW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SA": {
          "Survey CC": "SA",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "SD": {
          "Survey CC": "SD",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "SG": {
          "Survey CC": "SG",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "SI": {
          "Survey CC": "SI",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "SK": {
          "Survey CC": "SK",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "SN": {
          "Survey CC": "SN",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "SO": {
          "Survey CC": "SO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SR": {
          "Survey CC": "SR",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "ST": {
          "Survey CC": "ST",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SY": {
          "Survey CC": "SY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TD": {
          "Survey CC": "TD",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TG": {
          "Survey CC": "TG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TH": {
          "Survey CC": "TH",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TR": {
          "Survey CC": "TR",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "TT": {
          "Survey CC": "TT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TW": {
          "Survey CC": "TW",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "UA": {
          "Survey CC": "UA",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "UG": {
          "Survey CC": "UG",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "US": {
          "Survey CC": "US",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "UY": {
          "Survey CC": "UY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "VI": {
          "Survey CC": "VI",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "VN": {
          "Survey CC": "VN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "YE": {
          "Survey CC": "YE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctAgreeAndStronglyAgree": "0.87",
          "percent": 0.87
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "South": {
          "GlobalRegion": "South",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "Europe": {
          "CH Region": "Europe",
          "PctAgreeAndStronglyAgree": "0.87",
          "percent": 0.87
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "North America": {
          "CH Region": "North America",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "Total": {
          "CH Region": "Total",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        }
      }
    },
    "11.3": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "AF": {
          "Survey CC": "AF",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "AL": {
          "Survey CC": "AL",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AO": {
          "Survey CC": "AO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AR": {
          "Survey CC": "AR",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "AT": {
          "Survey CC": "AT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AU": {
          "Survey CC": "AU",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "BD": {
          "Survey CC": "BD",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BE": {
          "Survey CC": "BE",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "BG": {
          "Survey CC": "BG",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "BH": {
          "Survey CC": "BH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BO": {
          "Survey CC": "BO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BR": {
          "Survey CC": "BR",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "BS": {
          "Survey CC": "BS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CA": {
          "Survey CC": "CA",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "CD": {
          "Survey CC": "CD",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "CF": {
          "Survey CC": "CF",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "CI": {
          "Survey CC": "CI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctAgreeAndStronglyAgree": "0.98",
          "percent": 0.98
        },
        "CM": {
          "Survey CC": "CM",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "CN": {
          "Survey CC": "CN",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "CO": {
          "Survey CC": "CO",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "CR": {
          "Survey CC": "CR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctAgreeAndStronglyAgree": "0.84",
          "percent": 0.84
        },
        "DE": {
          "Survey CC": "DE",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctAgreeAndStronglyAgree": "0.82",
          "percent": 0.82
        },
        "DO": {
          "Survey CC": "DO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "EC": {
          "Survey CC": "EC",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "EE": {
          "Survey CC": "EE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "ES": {
          "Survey CC": "ES",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "ET": {
          "Survey CC": "ET",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "FI": {
          "Survey CC": "FI",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "FR": {
          "Survey CC": "FR",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "GA": {
          "Survey CC": "GA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "GE": {
          "Survey CC": "GE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GH": {
          "Survey CC": "GH",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "GN": {
          "Survey CC": "GN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GR": {
          "Survey CC": "GR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GT": {
          "Survey CC": "GT",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "HK": {
          "Survey CC": "HK",
          "PctAgreeAndStronglyAgree": "0.84",
          "percent": 0.84
        },
        "HN": {
          "Survey CC": "HN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HR": {
          "Survey CC": "HR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HU": {
          "Survey CC": "HU",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "ID": {
          "Survey CC": "ID",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "IE": {
          "Survey CC": "IE",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "IN": {
          "Survey CC": "IN",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "IT": {
          "Survey CC": "IT",
          "PctAgreeAndStronglyAgree": "0.87",
          "percent": 0.87
        },
        "JO": {
          "Survey CC": "JO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "JP": {
          "Survey CC": "JP",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "KE": {
          "Survey CC": "KE",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "KG": {
          "Survey CC": "KG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KH": {
          "Survey CC": "KH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KR": {
          "Survey CC": "KR",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LB": {
          "Survey CC": "LB",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LR": {
          "Survey CC": "LR",
          "PctAgreeAndStronglyAgree": "0.78",
          "percent": 0.78
        },
        "LT": {
          "Survey CC": "LT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LU": {
          "Survey CC": "LU",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LV": {
          "Survey CC": "LV",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LY": {
          "Survey CC": "LY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "ML": {
          "Survey CC": "ML",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MN": {
          "Survey CC": "MN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MW": {
          "Survey CC": "MW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MX": {
          "Survey CC": "MX",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "MY": {
          "Survey CC": "MY",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NE": {
          "Survey CC": "NE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "NI": {
          "Survey CC": "NI",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "NL": {
          "Survey CC": "NL",
          "PctAgreeAndStronglyAgree": "0.73",
          "percent": 0.73
        },
        "NP": {
          "Survey CC": "NP",
          "PctAgreeAndStronglyAgree": "0.8",
          "percent": 0.8
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctAgreeAndStronglyAgree": "0.82",
          "percent": 0.82
        },
        "PA": {
          "Survey CC": "PA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PH": {
          "Survey CC": "PH",
          "PctAgreeAndStronglyAgree": "0.84",
          "percent": 0.84
        },
        "PK": {
          "Survey CC": "PK",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "PL": {
          "Survey CC": "PL",
          "PctAgreeAndStronglyAgree": "0.81",
          "percent": 0.81
        },
        "PR": {
          "Survey CC": "PR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PS": {
          "Survey CC": "PS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctAgreeAndStronglyAgree": "0.87",
          "percent": 0.87
        },
        "PY": {
          "Survey CC": "PY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "RO": {
          "Survey CC": "RO",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "RS": {
          "Survey CC": "RS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "RW": {
          "Survey CC": "RW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SA": {
          "Survey CC": "SA",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "SD": {
          "Survey CC": "SD",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SE": {
          "Survey CC": "SE",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "SG": {
          "Survey CC": "SG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SI": {
          "Survey CC": "SI",
          "PctAgreeAndStronglyAgree": "0.79",
          "percent": 0.79
        },
        "SK": {
          "Survey CC": "SK",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "SN": {
          "Survey CC": "SN",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "SO": {
          "Survey CC": "SO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SR": {
          "Survey CC": "SR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SY": {
          "Survey CC": "SY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TD": {
          "Survey CC": "TD",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TG": {
          "Survey CC": "TG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TH": {
          "Survey CC": "TH",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TR": {
          "Survey CC": "TR",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "TT": {
          "Survey CC": "TT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TW": {
          "Survey CC": "TW",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "UA": {
          "Survey CC": "UA",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "UG": {
          "Survey CC": "UG",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "US": {
          "Survey CC": "US",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "UY": {
          "Survey CC": "UY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "VI": {
          "Survey CC": "VI",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "VN": {
          "Survey CC": "VN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "YE": {
          "Survey CC": "YE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "South": {
          "GlobalRegion": "South",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "Europe": {
          "CH Region": "Europe",
          "PctAgreeAndStronglyAgree": "0.87",
          "percent": 0.87
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "North America": {
          "CH Region": "North America",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "Total": {
          "CH Region": "Total",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        }
      }
    },
    "11.4": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctAgreeAndStronglyAgree": "0.33",
          "percent": 0.33
        },
        "AF": {
          "Survey CC": "AF",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AL": {
          "Survey CC": "AL",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AO": {
          "Survey CC": "AO",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AT": {
          "Survey CC": "AT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AU": {
          "Survey CC": "AU",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "BD": {
          "Survey CC": "BD",
          "PctAgreeAndStronglyAgree": "0.8",
          "percent": 0.8
        },
        "BE": {
          "Survey CC": "BE",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "BG": {
          "Survey CC": "BG",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "BH": {
          "Survey CC": "BH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BO": {
          "Survey CC": "BO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BR": {
          "Survey CC": "BR",
          "PctAgreeAndStronglyAgree": "0.98",
          "percent": 0.98
        },
        "BS": {
          "Survey CC": "BS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CA": {
          "Survey CC": "CA",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "CD": {
          "Survey CC": "CD",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "CF": {
          "Survey CC": "CF",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CI": {
          "Survey CC": "CI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "CM": {
          "Survey CC": "CM",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "CN": {
          "Survey CC": "CN",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "CO": {
          "Survey CC": "CO",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "CR": {
          "Survey CC": "CR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "DE": {
          "Survey CC": "DE",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctAgreeAndStronglyAgree": "0.71",
          "percent": 0.71
        },
        "DO": {
          "Survey CC": "DO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "EC": {
          "Survey CC": "EC",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "EE": {
          "Survey CC": "EE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "ES": {
          "Survey CC": "ES",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "ET": {
          "Survey CC": "ET",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "FI": {
          "Survey CC": "FI",
          "PctAgreeAndStronglyAgree": "0.73",
          "percent": 0.73
        },
        "FR": {
          "Survey CC": "FR",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "GA": {
          "Survey CC": "GA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "GE": {
          "Survey CC": "GE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GH": {
          "Survey CC": "GH",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "GN": {
          "Survey CC": "GN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GR": {
          "Survey CC": "GR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GT": {
          "Survey CC": "GT",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "HK": {
          "Survey CC": "HK",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "HN": {
          "Survey CC": "HN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HR": {
          "Survey CC": "HR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HU": {
          "Survey CC": "HU",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "ID": {
          "Survey CC": "ID",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "IE": {
          "Survey CC": "IE",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "IN": {
          "Survey CC": "IN",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "IT": {
          "Survey CC": "IT",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "JO": {
          "Survey CC": "JO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "JP": {
          "Survey CC": "JP",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "KE": {
          "Survey CC": "KE",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "KG": {
          "Survey CC": "KG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KH": {
          "Survey CC": "KH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KR": {
          "Survey CC": "KR",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LB": {
          "Survey CC": "LB",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "LR": {
          "Survey CC": "LR",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "LT": {
          "Survey CC": "LT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LU": {
          "Survey CC": "LU",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "LV": {
          "Survey CC": "LV",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LY": {
          "Survey CC": "LY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "ML": {
          "Survey CC": "ML",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MN": {
          "Survey CC": "MN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "MW": {
          "Survey CC": "MW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MX": {
          "Survey CC": "MX",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "MY": {
          "Survey CC": "MY",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NE": {
          "Survey CC": "NE",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "NG": {
          "Survey CC": "NG",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "NI": {
          "Survey CC": "NI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NL": {
          "Survey CC": "NL",
          "PctAgreeAndStronglyAgree": "0.63",
          "percent": 0.63
        },
        "NP": {
          "Survey CC": "NP",
          "PctAgreeAndStronglyAgree": "0.8",
          "percent": 0.8
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "PA": {
          "Survey CC": "PA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PH": {
          "Survey CC": "PH",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "PK": {
          "Survey CC": "PK",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "PL": {
          "Survey CC": "PL",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "PR": {
          "Survey CC": "PR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PS": {
          "Survey CC": "PS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "PY": {
          "Survey CC": "PY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "RO": {
          "Survey CC": "RO",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "RS": {
          "Survey CC": "RS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "RW": {
          "Survey CC": "RW",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "SA": {
          "Survey CC": "SA",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "SD": {
          "Survey CC": "SD",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctAgreeAndStronglyAgree": "0.82",
          "percent": 0.82
        },
        "SG": {
          "Survey CC": "SG",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "SI": {
          "Survey CC": "SI",
          "PctAgreeAndStronglyAgree": "0.73",
          "percent": 0.73
        },
        "SK": {
          "Survey CC": "SK",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "SN": {
          "Survey CC": "SN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SO": {
          "Survey CC": "SO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SR": {
          "Survey CC": "SR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SY": {
          "Survey CC": "SY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TD": {
          "Survey CC": "TD",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TG": {
          "Survey CC": "TG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TH": {
          "Survey CC": "TH",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TR": {
          "Survey CC": "TR",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "TT": {
          "Survey CC": "TT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TW": {
          "Survey CC": "TW",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "UA": {
          "Survey CC": "UA",
          "PctAgreeAndStronglyAgree": "0.87",
          "percent": 0.87
        },
        "UG": {
          "Survey CC": "UG",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "US": {
          "Survey CC": "US",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "UY": {
          "Survey CC": "UY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "VI": {
          "Survey CC": "VI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "VN": {
          "Survey CC": "VN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "YE": {
          "Survey CC": "YE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "South": {
          "GlobalRegion": "South",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "Europe": {
          "CH Region": "Europe",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctAgreeAndStronglyAgree": "0.84",
          "percent": 0.84
        },
        "North America": {
          "CH Region": "North America",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "Total": {
          "CH Region": "Total",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        }
      }
    },
    "11.5": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "AF": {
          "Survey CC": "AF",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AL": {
          "Survey CC": "AL",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AO": {
          "Survey CC": "AO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AR": {
          "Survey CC": "AR",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "AT": {
          "Survey CC": "AT",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "AU": {
          "Survey CC": "AU",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "BD": {
          "Survey CC": "BD",
          "PctAgreeAndStronglyAgree": "0.4",
          "percent": 0.4
        },
        "BE": {
          "Survey CC": "BE",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "BG": {
          "Survey CC": "BG",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "BH": {
          "Survey CC": "BH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BO": {
          "Survey CC": "BO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BR": {
          "Survey CC": "BR",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "BS": {
          "Survey CC": "BS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CA": {
          "Survey CC": "CA",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "CD": {
          "Survey CC": "CD",
          "PctAgreeAndStronglyAgree": "0.82",
          "percent": 0.82
        },
        "CF": {
          "Survey CC": "CF",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "CG": {
          "Survey CC": "CG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctAgreeAndStronglyAgree": "0.7",
          "percent": 0.7
        },
        "CI": {
          "Survey CC": "CI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "CM": {
          "Survey CC": "CM",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "CN": {
          "Survey CC": "CN",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "CO": {
          "Survey CC": "CO",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "CR": {
          "Survey CC": "CR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctAgreeAndStronglyAgree": "0.82",
          "percent": 0.82
        },
        "DE": {
          "Survey CC": "DE",
          "PctAgreeAndStronglyAgree": "0.84",
          "percent": 0.84
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctAgreeAndStronglyAgree": "0.76",
          "percent": 0.76
        },
        "DO": {
          "Survey CC": "DO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "EC": {
          "Survey CC": "EC",
          "PctAgreeAndStronglyAgree": "0.8",
          "percent": 0.8
        },
        "EE": {
          "Survey CC": "EE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "ES": {
          "Survey CC": "ES",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "ET": {
          "Survey CC": "ET",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "FI": {
          "Survey CC": "FI",
          "PctAgreeAndStronglyAgree": "0.82",
          "percent": 0.82
        },
        "FR": {
          "Survey CC": "FR",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "GA": {
          "Survey CC": "GA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "GE": {
          "Survey CC": "GE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GH": {
          "Survey CC": "GH",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "GN": {
          "Survey CC": "GN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GR": {
          "Survey CC": "GR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GT": {
          "Survey CC": "GT",
          "PctAgreeAndStronglyAgree": "0.8",
          "percent": 0.8
        },
        "HK": {
          "Survey CC": "HK",
          "PctAgreeAndStronglyAgree": "0.84",
          "percent": 0.84
        },
        "HN": {
          "Survey CC": "HN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HR": {
          "Survey CC": "HR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HU": {
          "Survey CC": "HU",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "ID": {
          "Survey CC": "ID",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "IE": {
          "Survey CC": "IE",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "IN": {
          "Survey CC": "IN",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "IT": {
          "Survey CC": "IT",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "JO": {
          "Survey CC": "JO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "JP": {
          "Survey CC": "JP",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "KE": {
          "Survey CC": "KE",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "KG": {
          "Survey CC": "KG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KH": {
          "Survey CC": "KH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KR": {
          "Survey CC": "KR",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LB": {
          "Survey CC": "LB",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LR": {
          "Survey CC": "LR",
          "PctAgreeAndStronglyAgree": "0.78",
          "percent": 0.78
        },
        "LT": {
          "Survey CC": "LT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LU": {
          "Survey CC": "LU",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "LV": {
          "Survey CC": "LV",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LY": {
          "Survey CC": "LY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "ML": {
          "Survey CC": "ML",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MN": {
          "Survey CC": "MN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MW": {
          "Survey CC": "MW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MX": {
          "Survey CC": "MX",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "MY": {
          "Survey CC": "MY",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NE": {
          "Survey CC": "NE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "NI": {
          "Survey CC": "NI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NL": {
          "Survey CC": "NL",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "NP": {
          "Survey CC": "NP",
          "PctAgreeAndStronglyAgree": "0.6",
          "percent": 0.6
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "PA": {
          "Survey CC": "PA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "PH": {
          "Survey CC": "PH",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "PK": {
          "Survey CC": "PK",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "PL": {
          "Survey CC": "PL",
          "PctAgreeAndStronglyAgree": "0.87",
          "percent": 0.87
        },
        "PR": {
          "Survey CC": "PR",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "PS": {
          "Survey CC": "PS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "PY": {
          "Survey CC": "PY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "RO": {
          "Survey CC": "RO",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "RS": {
          "Survey CC": "RS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "RW": {
          "Survey CC": "RW",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "SA": {
          "Survey CC": "SA",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "SD": {
          "Survey CC": "SD",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SE": {
          "Survey CC": "SE",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "SG": {
          "Survey CC": "SG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SI": {
          "Survey CC": "SI",
          "PctAgreeAndStronglyAgree": "0.82",
          "percent": 0.82
        },
        "SK": {
          "Survey CC": "SK",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "SN": {
          "Survey CC": "SN",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "SO": {
          "Survey CC": "SO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SR": {
          "Survey CC": "SR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SY": {
          "Survey CC": "SY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TD": {
          "Survey CC": "TD",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TG": {
          "Survey CC": "TG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TH": {
          "Survey CC": "TH",
          "PctAgreeAndStronglyAgree": "0.78",
          "percent": 0.78
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TR": {
          "Survey CC": "TR",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "TT": {
          "Survey CC": "TT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TW": {
          "Survey CC": "TW",
          "PctAgreeAndStronglyAgree": "0.95",
          "percent": 0.95
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "UA": {
          "Survey CC": "UA",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "UG": {
          "Survey CC": "UG",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "US": {
          "Survey CC": "US",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "UY": {
          "Survey CC": "UY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "VI": {
          "Survey CC": "VI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "VN": {
          "Survey CC": "VN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "YE": {
          "Survey CC": "YE",
          "PctAgreeAndStronglyAgree": "0.8",
          "percent": 0.8
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctAgreeAndStronglyAgree": "0.78",
          "percent": 0.78
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "South": {
          "GlobalRegion": "South",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "Europe": {
          "CH Region": "Europe",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctAgreeAndStronglyAgree": "0.87",
          "percent": 0.87
        },
        "North America": {
          "CH Region": "North America",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "Total": {
          "CH Region": "Total",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        }
      }
    },
    "14.1": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctChecked": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "AF": {
          "Survey CC": "AF",
          "PctChecked": "0",
          "percent": 0
        },
        "AL": {
          "Survey CC": "AL",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "AO": {
          "Survey CC": "AO",
          "PctChecked": "0",
          "percent": 0
        },
        "AR": {
          "Survey CC": "AR",
          "PctChecked": "0.36",
          "percent": 0.36
        },
        "AT": {
          "Survey CC": "AT",
          "PctChecked": "0.78",
          "percent": 0.78
        },
        "AU": {
          "Survey CC": "AU",
          "PctChecked": "0.42",
          "percent": 0.42
        },
        "BD": {
          "Survey CC": "BD",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "BE": {
          "Survey CC": "BE",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "BG": {
          "Survey CC": "BG",
          "PctChecked": "0.39",
          "percent": 0.39
        },
        "BH": {
          "Survey CC": "BH",
          "PctChecked": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctChecked": "0",
          "percent": 0
        },
        "BO": {
          "Survey CC": "BO",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "BR": {
          "Survey CC": "BR",
          "PctChecked": "0.28",
          "percent": 0.28
        },
        "BS": {
          "Survey CC": "BS",
          "PctChecked": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "CA": {
          "Survey CC": "CA",
          "PctChecked": "0.38",
          "percent": 0.38
        },
        "CD": {
          "Survey CC": "CD",
          "PctChecked": "0.59",
          "percent": 0.59
        },
        "CF": {
          "Survey CC": "CF",
          "PctChecked": "0",
          "percent": 0
        },
        "CG": {
          "Survey CC": "CG",
          "PctChecked": "0",
          "percent": 0
        },
        "CH": {
          "Survey CC": "CH",
          "PctChecked": "0.3",
          "percent": 0.3
        },
        "CI": {
          "Survey CC": "CI",
          "PctChecked": "0",
          "percent": 0
        },
        "CL": {
          "Survey CC": "CL",
          "PctChecked": "0.42",
          "percent": 0.42
        },
        "CM": {
          "Survey CC": "CM",
          "PctChecked": "0.14",
          "percent": 0.14
        },
        "CN": {
          "Survey CC": "CN",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "CO": {
          "Survey CC": "CO",
          "PctChecked": "0.28",
          "percent": 0.28
        },
        "CR": {
          "Survey CC": "CR",
          "PctChecked": "0",
          "percent": 0
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctChecked": "0.39",
          "percent": 0.39
        },
        "DE": {
          "Survey CC": "DE",
          "PctChecked": "0.52",
          "percent": 0.52
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctChecked": "0",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "DO": {
          "Survey CC": "DO",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "EC": {
          "Survey CC": "EC",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "EE": {
          "Survey CC": "EE",
          "PctChecked": "0",
          "percent": 0
        },
        "EG": {
          "Survey CC": "EG",
          "PctChecked": "0",
          "percent": 0
        },
        "ES": {
          "Survey CC": "ES",
          "PctChecked": "0.53",
          "percent": 0.53
        },
        "ET": {
          "Survey CC": "ET",
          "PctChecked": "0",
          "percent": 0
        },
        "FI": {
          "Survey CC": "FI",
          "PctChecked": "0.36",
          "percent": 0.36
        },
        "FR": {
          "Survey CC": "FR",
          "PctChecked": "0.58",
          "percent": 0.58
        },
        "GA": {
          "Survey CC": "GA",
          "PctChecked": "0",
          "percent": 0
        },
        "GB": {
          "Survey CC": "GB",
          "PctChecked": "0.52",
          "percent": 0.52
        },
        "GE": {
          "Survey CC": "GE",
          "PctChecked": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctChecked": "0.28",
          "percent": 0.28
        },
        "GN": {
          "Survey CC": "GN",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "GR": {
          "Survey CC": "GR",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "GT": {
          "Survey CC": "GT",
          "PctChecked": "0.3",
          "percent": 0.3
        },
        "HK": {
          "Survey CC": "HK",
          "PctChecked": "0.21",
          "percent": 0.21
        },
        "HN": {
          "Survey CC": "HN",
          "PctChecked": "0.57",
          "percent": 0.57
        },
        "HR": {
          "Survey CC": "HR",
          "PctChecked": "0",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctChecked": "0",
          "percent": 0
        },
        "HU": {
          "Survey CC": "HU",
          "PctChecked": "0.46",
          "percent": 0.46
        },
        "ID": {
          "Survey CC": "ID",
          "PctChecked": "0.32",
          "percent": 0.32
        },
        "IE": {
          "Survey CC": "IE",
          "PctChecked": "0.44",
          "percent": 0.44
        },
        "IN": {
          "Survey CC": "IN",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "IT": {
          "Survey CC": "IT",
          "PctChecked": "0.53",
          "percent": 0.53
        },
        "JO": {
          "Survey CC": "JO",
          "PctChecked": "0",
          "percent": 0
        },
        "JP": {
          "Survey CC": "JP",
          "PctChecked": "0.66",
          "percent": 0.66
        },
        "KE": {
          "Survey CC": "KE",
          "PctChecked": "0.34",
          "percent": 0.34
        },
        "KG": {
          "Survey CC": "KG",
          "PctChecked": "0",
          "percent": 0
        },
        "KH": {
          "Survey CC": "KH",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "KR": {
          "Survey CC": "KR",
          "PctChecked": "0.48",
          "percent": 0.48
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctChecked": "0",
          "percent": 0
        },
        "LB": {
          "Survey CC": "LB",
          "PctChecked": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "LR": {
          "Survey CC": "LR",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "LT": {
          "Survey CC": "LT",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "LU": {
          "Survey CC": "LU",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "LV": {
          "Survey CC": "LV",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "LY": {
          "Survey CC": "LY",
          "PctChecked": "0",
          "percent": 0
        },
        "MD": {
          "Survey CC": "MD",
          "PctChecked": "0.21",
          "percent": 0.21
        },
        "ML": {
          "Survey CC": "ML",
          "PctChecked": "0",
          "percent": 0
        },
        "MM": {
          "Survey CC": "MM",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "MN": {
          "Survey CC": "MN",
          "PctChecked": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctChecked": "0",
          "percent": 0
        },
        "MT": {
          "Survey CC": "MT",
          "PctChecked": "0",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "MW": {
          "Survey CC": "MW",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "MX": {
          "Survey CC": "MX",
          "PctChecked": "0.46",
          "percent": 0.46
        },
        "MY": {
          "Survey CC": "MY",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NA": {
          "Survey CC": "NA",
          "PctChecked": "1",
          "percent": 1
        },
        "NE": {
          "Survey CC": "NE",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NG": {
          "Survey CC": "NG",
          "PctChecked": "0.23",
          "percent": 0.23
        },
        "NI": {
          "Survey CC": "NI",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "NL": {
          "Survey CC": "NL",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "NP": {
          "Survey CC": "NP",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctChecked": "0.47",
          "percent": 0.47
        },
        "PA": {
          "Survey CC": "PA",
          "PctChecked": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "PH": {
          "Survey CC": "PH",
          "PctChecked": "0.24",
          "percent": 0.24
        },
        "PK": {
          "Survey CC": "PK",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "PL": {
          "Survey CC": "PL",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "PR": {
          "Survey CC": "PR",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "PS": {
          "Survey CC": "PS",
          "PctChecked": "0",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctChecked": "0.45",
          "percent": 0.45
        },
        "PY": {
          "Survey CC": "PY",
          "PctChecked": "0",
          "percent": 0
        },
        "RO": {
          "Survey CC": "RO",
          "PctChecked": "0.28",
          "percent": 0.28
        },
        "RS": {
          "Survey CC": "RS",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "RW": {
          "Survey CC": "RW",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "SA": {
          "Survey CC": "SA",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "SD": {
          "Survey CC": "SD",
          "PctChecked": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctChecked": "0.53",
          "percent": 0.53
        },
        "SG": {
          "Survey CC": "SG",
          "PctChecked": "0.52",
          "percent": 0.52
        },
        "SI": {
          "Survey CC": "SI",
          "PctChecked": "0.48",
          "percent": 0.48
        },
        "SK": {
          "Survey CC": "SK",
          "PctChecked": "0",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctChecked": "0.08",
          "percent": 0.08
        },
        "SN": {
          "Survey CC": "SN",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "SO": {
          "Survey CC": "SO",
          "PctChecked": "0",
          "percent": 0
        },
        "SR": {
          "Survey CC": "SR",
          "PctChecked": "0",
          "percent": 0
        },
        "ST": {
          "Survey CC": "ST",
          "PctChecked": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SY": {
          "Survey CC": "SY",
          "PctChecked": "0",
          "percent": 0
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "TD": {
          "Survey CC": "TD",
          "PctChecked": "0",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "TH": {
          "Survey CC": "TH",
          "PctChecked": "0.44",
          "percent": 0.44
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "TR": {
          "Survey CC": "TR",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "TT": {
          "Survey CC": "TT",
          "PctChecked": "1",
          "percent": 1
        },
        "TW": {
          "Survey CC": "TW",
          "PctChecked": "0.48",
          "percent": 0.48
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "UA": {
          "Survey CC": "UA",
          "PctChecked": "0.29",
          "percent": 0.29
        },
        "UG": {
          "Survey CC": "UG",
          "PctChecked": "0.37",
          "percent": 0.37
        },
        "US": {
          "Survey CC": "US",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "UY": {
          "Survey CC": "UY",
          "PctChecked": "0",
          "percent": 0
        },
        "VE": {
          "Survey CC": "VE",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "VI": {
          "Survey CC": "VI",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "VN": {
          "Survey CC": "VN",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "YE": {
          "Survey CC": "YE",
          "PctChecked": "0.8",
          "percent": 0.8
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctChecked": "0.36",
          "percent": 0.36
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctChecked": "0.17",
          "percent": 0.17
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "South": {
          "GlobalRegion": "South",
          "PctChecked": "0.32",
          "percent": 0.32
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctChecked": "0.39",
          "percent": 0.39
        }
      },
      "r": {
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctChecked": "0.06",
          "percent": 0.06
        },
        "Africa": {
          "CH Region": "Africa",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "North America": {
          "CH Region": "North America",
          "PctChecked": "0.32",
          "percent": 0.32
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctChecked": "0.36",
          "percent": 0.36
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctChecked": "0.36",
          "percent": 0.36
        },
        "Europe": {
          "CH Region": "Europe",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctChecked": "0.47",
          "percent": 0.47
        },
        "Total": {
          "CH Region": "Total",
          "PctChecked": "0.39",
          "percent": 0.39
        }
      }
    },
    "14.2": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctChecked": "1",
          "percent": 1
        },
        "AE": {
          "Survey CC": "AE",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "AF": {
          "Survey CC": "AF",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "AL": {
          "Survey CC": "AL",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "AO": {
          "Survey CC": "AO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctChecked": "0.36",
          "percent": 0.36
        },
        "AT": {
          "Survey CC": "AT",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "AU": {
          "Survey CC": "AU",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "BD": {
          "Survey CC": "BD",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "BE": {
          "Survey CC": "BE",
          "PctChecked": "0.22",
          "percent": 0.22
        },
        "BG": {
          "Survey CC": "BG",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "BH": {
          "Survey CC": "BH",
          "PctChecked": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctChecked": "1",
          "percent": 1
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctChecked": "1",
          "percent": 1
        },
        "BO": {
          "Survey CC": "BO",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "BR": {
          "Survey CC": "BR",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "BS": {
          "Survey CC": "BS",
          "PctChecked": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "CA": {
          "Survey CC": "CA",
          "PctChecked": "0.35",
          "percent": 0.35
        },
        "CD": {
          "Survey CC": "CD",
          "PctChecked": "0.12",
          "percent": 0.12
        },
        "CF": {
          "Survey CC": "CF",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "CG": {
          "Survey CC": "CG",
          "PctChecked": "0",
          "percent": 0
        },
        "CH": {
          "Survey CC": "CH",
          "PctChecked": "0.3",
          "percent": 0.3
        },
        "CI": {
          "Survey CC": "CI",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "CL": {
          "Survey CC": "CL",
          "PctChecked": "0.42",
          "percent": 0.42
        },
        "CM": {
          "Survey CC": "CM",
          "PctChecked": "0.71",
          "percent": 0.71
        },
        "CN": {
          "Survey CC": "CN",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "CO": {
          "Survey CC": "CO",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "CR": {
          "Survey CC": "CR",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctChecked": "0.37",
          "percent": 0.37
        },
        "DE": {
          "Survey CC": "DE",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctChecked": "0",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctChecked": "0.29",
          "percent": 0.29
        },
        "DO": {
          "Survey CC": "DO",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctChecked": "0",
          "percent": 0
        },
        "EC": {
          "Survey CC": "EC",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "EE": {
          "Survey CC": "EE",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "EG": {
          "Survey CC": "EG",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "ES": {
          "Survey CC": "ES",
          "PctChecked": "0.16",
          "percent": 0.16
        },
        "ET": {
          "Survey CC": "ET",
          "PctChecked": "1",
          "percent": 1
        },
        "FI": {
          "Survey CC": "FI",
          "PctChecked": "0.09",
          "percent": 0.09
        },
        "FR": {
          "Survey CC": "FR",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "GA": {
          "Survey CC": "GA",
          "PctChecked": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctChecked": "0.27",
          "percent": 0.27
        },
        "GE": {
          "Survey CC": "GE",
          "PctChecked": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctChecked": "0.47",
          "percent": 0.47
        },
        "GN": {
          "Survey CC": "GN",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "GR": {
          "Survey CC": "GR",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "GT": {
          "Survey CC": "GT",
          "PctChecked": "0.8",
          "percent": 0.8
        },
        "HK": {
          "Survey CC": "HK",
          "PctChecked": "0.32",
          "percent": 0.32
        },
        "HN": {
          "Survey CC": "HN",
          "PctChecked": "0.57",
          "percent": 0.57
        },
        "HR": {
          "Survey CC": "HR",
          "PctChecked": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctChecked": "0",
          "percent": 0
        },
        "HU": {
          "Survey CC": "HU",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "ID": {
          "Survey CC": "ID",
          "PctChecked": "0.66",
          "percent": 0.66
        },
        "IE": {
          "Survey CC": "IE",
          "PctChecked": "0.35",
          "percent": 0.35
        },
        "IN": {
          "Survey CC": "IN",
          "PctChecked": "0.57",
          "percent": 0.57
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "IT": {
          "Survey CC": "IT",
          "PctChecked": "0.47",
          "percent": 0.47
        },
        "JO": {
          "Survey CC": "JO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "KE": {
          "Survey CC": "KE",
          "PctChecked": "0.35",
          "percent": 0.35
        },
        "KG": {
          "Survey CC": "KG",
          "PctChecked": "0",
          "percent": 0
        },
        "KH": {
          "Survey CC": "KH",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "KR": {
          "Survey CC": "KR",
          "PctChecked": "0.14",
          "percent": 0.14
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctChecked": "1",
          "percent": 1
        },
        "LB": {
          "Survey CC": "LB",
          "PctChecked": "0",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctChecked": "0.13",
          "percent": 0.13
        },
        "LR": {
          "Survey CC": "LR",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "LT": {
          "Survey CC": "LT",
          "PctChecked": "0",
          "percent": 0
        },
        "LU": {
          "Survey CC": "LU",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "LV": {
          "Survey CC": "LV",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "LY": {
          "Survey CC": "LY",
          "PctChecked": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "ML": {
          "Survey CC": "ML",
          "PctChecked": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "MN": {
          "Survey CC": "MN",
          "PctChecked": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctChecked": "0",
          "percent": 0
        },
        "MT": {
          "Survey CC": "MT",
          "PctChecked": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "MW": {
          "Survey CC": "MW",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "MX": {
          "Survey CC": "MX",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "MY": {
          "Survey CC": "MY",
          "PctChecked": "0.48",
          "percent": 0.48
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NA": {
          "Survey CC": "NA",
          "PctChecked": "0",
          "percent": 0
        },
        "NE": {
          "Survey CC": "NE",
          "PctChecked": "1",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "NI": {
          "Survey CC": "NI",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "NL": {
          "Survey CC": "NL",
          "PctChecked": "0.1",
          "percent": 0.1
        },
        "NP": {
          "Survey CC": "NP",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "PA": {
          "Survey CC": "PA",
          "PctChecked": "0",
          "percent": 0
        },
        "PE": {
          "Survey CC": "PE",
          "PctChecked": "0.63",
          "percent": 0.63
        },
        "PH": {
          "Survey CC": "PH",
          "PctChecked": "0.65",
          "percent": 0.65
        },
        "PK": {
          "Survey CC": "PK",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "PL": {
          "Survey CC": "PL",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "PR": {
          "Survey CC": "PR",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "PS": {
          "Survey CC": "PS",
          "PctChecked": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "RO": {
          "Survey CC": "RO",
          "PctChecked": "0.22",
          "percent": 0.22
        },
        "RS": {
          "Survey CC": "RS",
          "PctChecked": "0",
          "percent": 0
        },
        "RW": {
          "Survey CC": "RW",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SA": {
          "Survey CC": "SA",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "SD": {
          "Survey CC": "SD",
          "PctChecked": "1",
          "percent": 1
        },
        "SE": {
          "Survey CC": "SE",
          "PctChecked": "0.15",
          "percent": 0.15
        },
        "SG": {
          "Survey CC": "SG",
          "PctChecked": "0.19",
          "percent": 0.19
        },
        "SI": {
          "Survey CC": "SI",
          "PctChecked": "0.24",
          "percent": 0.24
        },
        "SK": {
          "Survey CC": "SK",
          "PctChecked": "0",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctChecked": "0.62",
          "percent": 0.62
        },
        "SN": {
          "Survey CC": "SN",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "SO": {
          "Survey CC": "SO",
          "PctChecked": "0",
          "percent": 0
        },
        "SR": {
          "Survey CC": "SR",
          "PctChecked": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctChecked": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "SY": {
          "Survey CC": "SY",
          "PctChecked": "1",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "TD": {
          "Survey CC": "TD",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "TG": {
          "Survey CC": "TG",
          "PctChecked": "1",
          "percent": 1
        },
        "TH": {
          "Survey CC": "TH",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctChecked": "0.83",
          "percent": 0.83
        },
        "TR": {
          "Survey CC": "TR",
          "PctChecked": "0.47",
          "percent": 0.47
        },
        "TT": {
          "Survey CC": "TT",
          "PctChecked": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctChecked": "0.24",
          "percent": 0.24
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctChecked": "0.29",
          "percent": 0.29
        },
        "UA": {
          "Survey CC": "UA",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "UG": {
          "Survey CC": "UG",
          "PctChecked": "0.23",
          "percent": 0.23
        },
        "US": {
          "Survey CC": "US",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "UY": {
          "Survey CC": "UY",
          "PctChecked": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctChecked": "0.83",
          "percent": 0.83
        },
        "VI": {
          "Survey CC": "VI",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "VN": {
          "Survey CC": "VN",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "YE": {
          "Survey CC": "YE",
          "PctChecked": "0.8",
          "percent": 0.8
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctChecked": "0.35",
          "percent": 0.35
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctChecked": "0.22",
          "percent": 0.22
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctChecked": "0.17",
          "percent": 0.17
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "South": {
          "GlobalRegion": "South",
          "PctChecked": "0.51",
          "percent": 0.51
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctChecked": "0.39",
          "percent": 0.39
        }
      },
      "r": {
        "Europe": {
          "CH Region": "Europe",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "North America": {
          "CH Region": "North America",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "Africa": {
          "CH Region": "Africa",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctChecked": "0.49",
          "percent": 0.49
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctChecked": "0.69",
          "percent": 0.69
        },
        "Total": {
          "CH Region": "Total",
          "PctChecked": "0.39",
          "percent": 0.39
        }
      }
    },
    "14.3": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctChecked": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctChecked": "1",
          "percent": 1
        },
        "AF": {
          "Survey CC": "AF",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "AL": {
          "Survey CC": "AL",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "AO": {
          "Survey CC": "AO",
          "PctChecked": "0",
          "percent": 0
        },
        "AR": {
          "Survey CC": "AR",
          "PctChecked": "0.14",
          "percent": 0.14
        },
        "AT": {
          "Survey CC": "AT",
          "PctChecked": "0.22",
          "percent": 0.22
        },
        "AU": {
          "Survey CC": "AU",
          "PctChecked": "0.47",
          "percent": 0.47
        },
        "BD": {
          "Survey CC": "BD",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "BE": {
          "Survey CC": "BE",
          "PctChecked": "0.22",
          "percent": 0.22
        },
        "BG": {
          "Survey CC": "BG",
          "PctChecked": "0.24",
          "percent": 0.24
        },
        "BH": {
          "Survey CC": "BH",
          "PctChecked": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "BO": {
          "Survey CC": "BO",
          "PctChecked": "0",
          "percent": 0
        },
        "BR": {
          "Survey CC": "BR",
          "PctChecked": "0.22",
          "percent": 0.22
        },
        "BS": {
          "Survey CC": "BS",
          "PctChecked": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "CA": {
          "Survey CC": "CA",
          "PctChecked": "0.34",
          "percent": 0.34
        },
        "CD": {
          "Survey CC": "CD",
          "PctChecked": "0.24",
          "percent": 0.24
        },
        "CF": {
          "Survey CC": "CF",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "CG": {
          "Survey CC": "CG",
          "PctChecked": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctChecked": "0.3",
          "percent": 0.3
        },
        "CI": {
          "Survey CC": "CI",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "CL": {
          "Survey CC": "CL",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "CM": {
          "Survey CC": "CM",
          "PctChecked": "0.64",
          "percent": 0.64
        },
        "CN": {
          "Survey CC": "CN",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "CO": {
          "Survey CC": "CO",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "CR": {
          "Survey CC": "CR",
          "PctChecked": "0",
          "percent": 0
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "DE": {
          "Survey CC": "DE",
          "PctChecked": "0.3",
          "percent": 0.3
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctChecked": "0",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctChecked": "0.12",
          "percent": 0.12
        },
        "DO": {
          "Survey CC": "DO",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "EC": {
          "Survey CC": "EC",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "EE": {
          "Survey CC": "EE",
          "PctChecked": "0",
          "percent": 0
        },
        "EG": {
          "Survey CC": "EG",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "ES": {
          "Survey CC": "ES",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "ET": {
          "Survey CC": "ET",
          "PctChecked": "1",
          "percent": 1
        },
        "FI": {
          "Survey CC": "FI",
          "PctChecked": "0",
          "percent": 0
        },
        "FR": {
          "Survey CC": "FR",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "GA": {
          "Survey CC": "GA",
          "PctChecked": "0",
          "percent": 0
        },
        "GB": {
          "Survey CC": "GB",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "GE": {
          "Survey CC": "GE",
          "PctChecked": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctChecked": "0.61",
          "percent": 0.61
        },
        "GN": {
          "Survey CC": "GN",
          "PctChecked": "1",
          "percent": 1
        },
        "GR": {
          "Survey CC": "GR",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "GT": {
          "Survey CC": "GT",
          "PctChecked": "0.3",
          "percent": 0.3
        },
        "HK": {
          "Survey CC": "HK",
          "PctChecked": "0.26",
          "percent": 0.26
        },
        "HN": {
          "Survey CC": "HN",
          "PctChecked": "0",
          "percent": 0
        },
        "HR": {
          "Survey CC": "HR",
          "PctChecked": "0",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "HU": {
          "Survey CC": "HU",
          "PctChecked": "0.19",
          "percent": 0.19
        },
        "ID": {
          "Survey CC": "ID",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "IE": {
          "Survey CC": "IE",
          "PctChecked": "0.45",
          "percent": 0.45
        },
        "IN": {
          "Survey CC": "IN",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "IT": {
          "Survey CC": "IT",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "JO": {
          "Survey CC": "JO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctChecked": "0.24",
          "percent": 0.24
        },
        "KE": {
          "Survey CC": "KE",
          "PctChecked": "0.53",
          "percent": 0.53
        },
        "KG": {
          "Survey CC": "KG",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "KH": {
          "Survey CC": "KH",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "KR": {
          "Survey CC": "KR",
          "PctChecked": "0.14",
          "percent": 0.14
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "LB": {
          "Survey CC": "LB",
          "PctChecked": "0",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctChecked": "0.38",
          "percent": 0.38
        },
        "LR": {
          "Survey CC": "LR",
          "PctChecked": "0.44",
          "percent": 0.44
        },
        "LT": {
          "Survey CC": "LT",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "LU": {
          "Survey CC": "LU",
          "PctChecked": "0",
          "percent": 0
        },
        "LV": {
          "Survey CC": "LV",
          "PctChecked": "0",
          "percent": 0
        },
        "LY": {
          "Survey CC": "LY",
          "PctChecked": "0",
          "percent": 0
        },
        "MD": {
          "Survey CC": "MD",
          "PctChecked": "0.38",
          "percent": 0.38
        },
        "ML": {
          "Survey CC": "ML",
          "PctChecked": "0",
          "percent": 0
        },
        "MM": {
          "Survey CC": "MM",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "MN": {
          "Survey CC": "MN",
          "PctChecked": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctChecked": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctChecked": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctChecked": "0",
          "percent": 0
        },
        "MW": {
          "Survey CC": "MW",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "MX": {
          "Survey CC": "MX",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "MY": {
          "Survey CC": "MY",
          "PctChecked": "0.39",
          "percent": 0.39
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NA": {
          "Survey CC": "NA",
          "PctChecked": "0",
          "percent": 0
        },
        "NE": {
          "Survey CC": "NE",
          "PctChecked": "0",
          "percent": 0
        },
        "NG": {
          "Survey CC": "NG",
          "PctChecked": "0.62",
          "percent": 0.62
        },
        "NI": {
          "Survey CC": "NI",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "NL": {
          "Survey CC": "NL",
          "PctChecked": "0.23",
          "percent": 0.23
        },
        "NP": {
          "Survey CC": "NP",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctChecked": "0.27",
          "percent": 0.27
        },
        "PA": {
          "Survey CC": "PA",
          "PctChecked": "0",
          "percent": 0
        },
        "PE": {
          "Survey CC": "PE",
          "PctChecked": "0.13",
          "percent": 0.13
        },
        "PH": {
          "Survey CC": "PH",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "PK": {
          "Survey CC": "PK",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "PL": {
          "Survey CC": "PL",
          "PctChecked": "0.29",
          "percent": 0.29
        },
        "PR": {
          "Survey CC": "PR",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "PS": {
          "Survey CC": "PS",
          "PctChecked": "0",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctChecked": "0.13",
          "percent": 0.13
        },
        "PY": {
          "Survey CC": "PY",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "RO": {
          "Survey CC": "RO",
          "PctChecked": "0.24",
          "percent": 0.24
        },
        "RS": {
          "Survey CC": "RS",
          "PctChecked": "0",
          "percent": 0
        },
        "RW": {
          "Survey CC": "RW",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "SA": {
          "Survey CC": "SA",
          "PctChecked": "0.21",
          "percent": 0.21
        },
        "SD": {
          "Survey CC": "SD",
          "PctChecked": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctChecked": "0.12",
          "percent": 0.12
        },
        "SG": {
          "Survey CC": "SG",
          "PctChecked": "0.1",
          "percent": 0.1
        },
        "SI": {
          "Survey CC": "SI",
          "PctChecked": "0.18",
          "percent": 0.18
        },
        "SK": {
          "Survey CC": "SK",
          "PctChecked": "0",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctChecked": "0.54",
          "percent": 0.54
        },
        "SN": {
          "Survey CC": "SN",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "SO": {
          "Survey CC": "SO",
          "PctChecked": "0",
          "percent": 0
        },
        "SR": {
          "Survey CC": "SR",
          "PctChecked": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctChecked": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "SY": {
          "Survey CC": "SY",
          "PctChecked": "0",
          "percent": 0
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "TD": {
          "Survey CC": "TD",
          "PctChecked": "1",
          "percent": 1
        },
        "TG": {
          "Survey CC": "TG",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "TH": {
          "Survey CC": "TH",
          "PctChecked": "0.44",
          "percent": 0.44
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "TR": {
          "Survey CC": "TR",
          "PctChecked": "0.27",
          "percent": 0.27
        },
        "TT": {
          "Survey CC": "TT",
          "PctChecked": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctChecked": "0.32",
          "percent": 0.32
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctChecked": "0.42",
          "percent": 0.42
        },
        "UA": {
          "Survey CC": "UA",
          "PctChecked": "0.46",
          "percent": 0.46
        },
        "UG": {
          "Survey CC": "UG",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "US": {
          "Survey CC": "US",
          "PctChecked": "0.39",
          "percent": 0.39
        },
        "UY": {
          "Survey CC": "UY",
          "PctChecked": "0",
          "percent": 0
        },
        "VE": {
          "Survey CC": "VE",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "VI": {
          "Survey CC": "VI",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "VN": {
          "Survey CC": "VN",
          "PctChecked": "0",
          "percent": 0
        },
        "YE": {
          "Survey CC": "YE",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctChecked": "0.52",
          "percent": 0.52
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctChecked": "0.44",
          "percent": 0.44
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctChecked": "0.67",
          "percent": 0.67
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "South": {
          "GlobalRegion": "South",
          "PctChecked": "0.36",
          "percent": 0.36
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctChecked": "0.32",
          "percent": 0.32
        }
      },
      "r": {
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctChecked": "0.23",
          "percent": 0.23
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "Europe": {
          "CH Region": "Europe",
          "PctChecked": "0.29",
          "percent": 0.29
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctChecked": "0.29",
          "percent": 0.29
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctChecked": "0.38",
          "percent": 0.38
        },
        "North America": {
          "CH Region": "North America",
          "PctChecked": "0.38",
          "percent": 0.38
        },
        "Africa": {
          "CH Region": "Africa",
          "PctChecked": "0.51",
          "percent": 0.51
        },
        "Total": {
          "CH Region": "Total",
          "PctChecked": "0.32",
          "percent": 0.32
        }
      }
    },
    "15.1": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctChecked": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "AF": {
          "Survey CC": "AF",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "AL": {
          "Survey CC": "AL",
          "PctChecked": "1",
          "percent": 1
        },
        "AO": {
          "Survey CC": "AO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctChecked": "0.59",
          "percent": 0.59
        },
        "AT": {
          "Survey CC": "AT",
          "PctChecked": "0.78",
          "percent": 0.78
        },
        "AU": {
          "Survey CC": "AU",
          "PctChecked": "0.53",
          "percent": 0.53
        },
        "BD": {
          "Survey CC": "BD",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "BE": {
          "Survey CC": "BE",
          "PctChecked": "0.59",
          "percent": 0.59
        },
        "BG": {
          "Survey CC": "BG",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "BH": {
          "Survey CC": "BH",
          "PctChecked": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctChecked": "0",
          "percent": 0
        },
        "BO": {
          "Survey CC": "BO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "BR": {
          "Survey CC": "BR",
          "PctChecked": "0.37",
          "percent": 0.37
        },
        "BS": {
          "Survey CC": "BS",
          "PctChecked": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "CA": {
          "Survey CC": "CA",
          "PctChecked": "0.47",
          "percent": 0.47
        },
        "CD": {
          "Survey CC": "CD",
          "PctChecked": "0.53",
          "percent": 0.53
        },
        "CF": {
          "Survey CC": "CF",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "CG": {
          "Survey CC": "CG",
          "PctChecked": "0",
          "percent": 0
        },
        "CH": {
          "Survey CC": "CH",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "CI": {
          "Survey CC": "CI",
          "PctChecked": "0",
          "percent": 0
        },
        "CL": {
          "Survey CC": "CL",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "CM": {
          "Survey CC": "CM",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "CN": {
          "Survey CC": "CN",
          "PctChecked": "0",
          "percent": 0
        },
        "CO": {
          "Survey CC": "CO",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "CR": {
          "Survey CC": "CR",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctChecked": "0.51",
          "percent": 0.51
        },
        "DE": {
          "Survey CC": "DE",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctChecked": "0",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "DO": {
          "Survey CC": "DO",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctChecked": "0",
          "percent": 0
        },
        "EC": {
          "Survey CC": "EC",
          "PctChecked": "0.8",
          "percent": 0.8
        },
        "EE": {
          "Survey CC": "EE",
          "PctChecked": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "ES": {
          "Survey CC": "ES",
          "PctChecked": "0.71",
          "percent": 0.71
        },
        "ET": {
          "Survey CC": "ET",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "FI": {
          "Survey CC": "FI",
          "PctChecked": "0.45",
          "percent": 0.45
        },
        "FR": {
          "Survey CC": "FR",
          "PctChecked": "0.42",
          "percent": 0.42
        },
        "GA": {
          "Survey CC": "GA",
          "PctChecked": "0",
          "percent": 0
        },
        "GB": {
          "Survey CC": "GB",
          "PctChecked": "0.59",
          "percent": 0.59
        },
        "GE": {
          "Survey CC": "GE",
          "PctChecked": "1",
          "percent": 1
        },
        "GH": {
          "Survey CC": "GH",
          "PctChecked": "0.53",
          "percent": 0.53
        },
        "GN": {
          "Survey CC": "GN",
          "PctChecked": "1",
          "percent": 1
        },
        "GR": {
          "Survey CC": "GR",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "GT": {
          "Survey CC": "GT",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "HK": {
          "Survey CC": "HK",
          "PctChecked": "0.58",
          "percent": 0.58
        },
        "HN": {
          "Survey CC": "HN",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "HR": {
          "Survey CC": "HR",
          "PctChecked": "0",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "HU": {
          "Survey CC": "HU",
          "PctChecked": "0.62",
          "percent": 0.62
        },
        "ID": {
          "Survey CC": "ID",
          "PctChecked": "0.34",
          "percent": 0.34
        },
        "IE": {
          "Survey CC": "IE",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "IN": {
          "Survey CC": "IN",
          "PctChecked": "0.47",
          "percent": 0.47
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "IT": {
          "Survey CC": "IT",
          "PctChecked": "0.7",
          "percent": 0.7
        },
        "JO": {
          "Survey CC": "JO",
          "PctChecked": "0",
          "percent": 0
        },
        "JP": {
          "Survey CC": "JP",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "KE": {
          "Survey CC": "KE",
          "PctChecked": "0.59",
          "percent": 0.59
        },
        "KG": {
          "Survey CC": "KG",
          "PctChecked": "0",
          "percent": 0
        },
        "KH": {
          "Survey CC": "KH",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "KR": {
          "Survey CC": "KR",
          "PctChecked": "0.45",
          "percent": 0.45
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctChecked": "0",
          "percent": 0
        },
        "LB": {
          "Survey CC": "LB",
          "PctChecked": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctChecked": "0.63",
          "percent": 0.63
        },
        "LR": {
          "Survey CC": "LR",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "LT": {
          "Survey CC": "LT",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "LU": {
          "Survey CC": "LU",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "LV": {
          "Survey CC": "LV",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "LY": {
          "Survey CC": "LY",
          "PctChecked": "0",
          "percent": 0
        },
        "MD": {
          "Survey CC": "MD",
          "PctChecked": "0.54",
          "percent": 0.54
        },
        "ML": {
          "Survey CC": "ML",
          "PctChecked": "0",
          "percent": 0
        },
        "MM": {
          "Survey CC": "MM",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "MN": {
          "Survey CC": "MN",
          "PctChecked": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctChecked": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctChecked": "0",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctChecked": "1",
          "percent": 1
        },
        "MW": {
          "Survey CC": "MW",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "MX": {
          "Survey CC": "MX",
          "PctChecked": "0.52",
          "percent": 0.52
        },
        "MY": {
          "Survey CC": "MY",
          "PctChecked": "0.42",
          "percent": 0.42
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NA": {
          "Survey CC": "NA",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NE": {
          "Survey CC": "NE",
          "PctChecked": "0",
          "percent": 0
        },
        "NG": {
          "Survey CC": "NG",
          "PctChecked": "0.45",
          "percent": 0.45
        },
        "NI": {
          "Survey CC": "NI",
          "PctChecked": "1",
          "percent": 1
        },
        "NL": {
          "Survey CC": "NL",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "NP": {
          "Survey CC": "NP",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "PA": {
          "Survey CC": "PA",
          "PctChecked": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctChecked": "0.38",
          "percent": 0.38
        },
        "PH": {
          "Survey CC": "PH",
          "PctChecked": "0.32",
          "percent": 0.32
        },
        "PK": {
          "Survey CC": "PK",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "PL": {
          "Survey CC": "PL",
          "PctChecked": "0.54",
          "percent": 0.54
        },
        "PR": {
          "Survey CC": "PR",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "PS": {
          "Survey CC": "PS",
          "PctChecked": "0",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctChecked": "0.62",
          "percent": 0.62
        },
        "PY": {
          "Survey CC": "PY",
          "PctChecked": "0",
          "percent": 0
        },
        "RO": {
          "Survey CC": "RO",
          "PctChecked": "0.58",
          "percent": 0.58
        },
        "RS": {
          "Survey CC": "RS",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "RW": {
          "Survey CC": "RW",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SA": {
          "Survey CC": "SA",
          "PctChecked": "0.45",
          "percent": 0.45
        },
        "SD": {
          "Survey CC": "SD",
          "PctChecked": "1",
          "percent": 1
        },
        "SE": {
          "Survey CC": "SE",
          "PctChecked": "0.62",
          "percent": 0.62
        },
        "SG": {
          "Survey CC": "SG",
          "PctChecked": "0.61",
          "percent": 0.61
        },
        "SI": {
          "Survey CC": "SI",
          "PctChecked": "0.76",
          "percent": 0.76
        },
        "SK": {
          "Survey CC": "SK",
          "PctChecked": "0",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctChecked": "0.54",
          "percent": 0.54
        },
        "SN": {
          "Survey CC": "SN",
          "PctChecked": "0",
          "percent": 0
        },
        "SO": {
          "Survey CC": "SO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SR": {
          "Survey CC": "SR",
          "PctChecked": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctChecked": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SY": {
          "Survey CC": "SY",
          "PctChecked": "0",
          "percent": 0
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctChecked": "0",
          "percent": 0
        },
        "TD": {
          "Survey CC": "TD",
          "PctChecked": "0",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "TH": {
          "Survey CC": "TH",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "TR": {
          "Survey CC": "TR",
          "PctChecked": "0.42",
          "percent": 0.42
        },
        "TT": {
          "Survey CC": "TT",
          "PctChecked": "1",
          "percent": 1
        },
        "TW": {
          "Survey CC": "TW",
          "PctChecked": "0.57",
          "percent": 0.57
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctChecked": "0.54",
          "percent": 0.54
        },
        "UA": {
          "Survey CC": "UA",
          "PctChecked": "0.49",
          "percent": 0.49
        },
        "UG": {
          "Survey CC": "UG",
          "PctChecked": "0.53",
          "percent": 0.53
        },
        "US": {
          "Survey CC": "US",
          "PctChecked": "0.42",
          "percent": 0.42
        },
        "UY": {
          "Survey CC": "UY",
          "PctChecked": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "VI": {
          "Survey CC": "VI",
          "PctChecked": "1",
          "percent": 1
        },
        "VN": {
          "Survey CC": "VN",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "YE": {
          "Survey CC": "YE",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctChecked": "0.52",
          "percent": 0.52
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctChecked": "0.22",
          "percent": 0.22
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctChecked": "0.17",
          "percent": 0.17
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctChecked": "0.53",
          "percent": 0.53
        },
        "South": {
          "GlobalRegion": "South",
          "PctChecked": "0.44",
          "percent": 0.44
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctChecked": "0.5",
          "percent": 0.5
        }
      },
      "r": {
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctChecked": "0.19",
          "percent": 0.19
        },
        "North America": {
          "CH Region": "North America",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctChecked": "0.46",
          "percent": 0.46
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctChecked": "0.47",
          "percent": 0.47
        },
        "Africa": {
          "CH Region": "Africa",
          "PctChecked": "0.49",
          "percent": 0.49
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctChecked": "0.51",
          "percent": 0.51
        },
        "Europe": {
          "CH Region": "Europe",
          "PctChecked": "0.57",
          "percent": 0.57
        },
        "Total": {
          "CH Region": "Total",
          "PctChecked": "0.5",
          "percent": 0.5
        }
      }
    },
    "15.2": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctChecked": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "AF": {
          "Survey CC": "AF",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "AL": {
          "Survey CC": "AL",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "AO": {
          "Survey CC": "AO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctChecked": "0.55",
          "percent": 0.55
        },
        "AT": {
          "Survey CC": "AT",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "AU": {
          "Survey CC": "AU",
          "PctChecked": "0.37",
          "percent": 0.37
        },
        "BD": {
          "Survey CC": "BD",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "BE": {
          "Survey CC": "BE",
          "PctChecked": "0.36",
          "percent": 0.36
        },
        "BG": {
          "Survey CC": "BG",
          "PctChecked": "0.24",
          "percent": 0.24
        },
        "BH": {
          "Survey CC": "BH",
          "PctChecked": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctChecked": "1",
          "percent": 1
        },
        "BO": {
          "Survey CC": "BO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "BR": {
          "Survey CC": "BR",
          "PctChecked": "0.58",
          "percent": 0.58
        },
        "BS": {
          "Survey CC": "BS",
          "PctChecked": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "CA": {
          "Survey CC": "CA",
          "PctChecked": "0.51",
          "percent": 0.51
        },
        "CD": {
          "Survey CC": "CD",
          "PctChecked": "0.59",
          "percent": 0.59
        },
        "CF": {
          "Survey CC": "CF",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "CG": {
          "Survey CC": "CG",
          "PctChecked": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctChecked": "0.3",
          "percent": 0.3
        },
        "CI": {
          "Survey CC": "CI",
          "PctChecked": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctChecked": "0.42",
          "percent": 0.42
        },
        "CM": {
          "Survey CC": "CM",
          "PctChecked": "0.57",
          "percent": 0.57
        },
        "CN": {
          "Survey CC": "CN",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "CO": {
          "Survey CC": "CO",
          "PctChecked": "0.48",
          "percent": 0.48
        },
        "CR": {
          "Survey CC": "CR",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctChecked": "0.44",
          "percent": 0.44
        },
        "DE": {
          "Survey CC": "DE",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctChecked": "0",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctChecked": "0.29",
          "percent": 0.29
        },
        "DO": {
          "Survey CC": "DO",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "EC": {
          "Survey CC": "EC",
          "PctChecked": "0.3",
          "percent": 0.3
        },
        "EE": {
          "Survey CC": "EE",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "EG": {
          "Survey CC": "EG",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "ES": {
          "Survey CC": "ES",
          "PctChecked": "0.36",
          "percent": 0.36
        },
        "ET": {
          "Survey CC": "ET",
          "PctChecked": "1",
          "percent": 1
        },
        "FI": {
          "Survey CC": "FI",
          "PctChecked": "0.09",
          "percent": 0.09
        },
        "FR": {
          "Survey CC": "FR",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "GA": {
          "Survey CC": "GA",
          "PctChecked": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctChecked": "0.45",
          "percent": 0.45
        },
        "GE": {
          "Survey CC": "GE",
          "PctChecked": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctChecked": "0.53",
          "percent": 0.53
        },
        "GN": {
          "Survey CC": "GN",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "GR": {
          "Survey CC": "GR",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "GT": {
          "Survey CC": "GT",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "HK": {
          "Survey CC": "HK",
          "PctChecked": "0.37",
          "percent": 0.37
        },
        "HN": {
          "Survey CC": "HN",
          "PctChecked": "0.57",
          "percent": 0.57
        },
        "HR": {
          "Survey CC": "HR",
          "PctChecked": "0",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "HU": {
          "Survey CC": "HU",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "ID": {
          "Survey CC": "ID",
          "PctChecked": "0.35",
          "percent": 0.35
        },
        "IE": {
          "Survey CC": "IE",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "IN": {
          "Survey CC": "IN",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "IT": {
          "Survey CC": "IT",
          "PctChecked": "0.23",
          "percent": 0.23
        },
        "JO": {
          "Survey CC": "JO",
          "PctChecked": "0",
          "percent": 0
        },
        "JP": {
          "Survey CC": "JP",
          "PctChecked": "0.22",
          "percent": 0.22
        },
        "KE": {
          "Survey CC": "KE",
          "PctChecked": "0.68",
          "percent": 0.68
        },
        "KG": {
          "Survey CC": "KG",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "KH": {
          "Survey CC": "KH",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "KR": {
          "Survey CC": "KR",
          "PctChecked": "0.38",
          "percent": 0.38
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "LB": {
          "Survey CC": "LB",
          "PctChecked": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "LR": {
          "Survey CC": "LR",
          "PctChecked": "0.44",
          "percent": 0.44
        },
        "LT": {
          "Survey CC": "LT",
          "PctChecked": "0",
          "percent": 0
        },
        "LU": {
          "Survey CC": "LU",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "LV": {
          "Survey CC": "LV",
          "PctChecked": "0",
          "percent": 0
        },
        "LY": {
          "Survey CC": "LY",
          "PctChecked": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctChecked": "0.18",
          "percent": 0.18
        },
        "ML": {
          "Survey CC": "ML",
          "PctChecked": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "MN": {
          "Survey CC": "MN",
          "PctChecked": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctChecked": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctChecked": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctChecked": "0",
          "percent": 0
        },
        "MW": {
          "Survey CC": "MW",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "MX": {
          "Survey CC": "MX",
          "PctChecked": "0.35",
          "percent": 0.35
        },
        "MY": {
          "Survey CC": "MY",
          "PctChecked": "0.39",
          "percent": 0.39
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctChecked": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NE": {
          "Survey CC": "NE",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NG": {
          "Survey CC": "NG",
          "PctChecked": "0.62",
          "percent": 0.62
        },
        "NI": {
          "Survey CC": "NI",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "NL": {
          "Survey CC": "NL",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "NP": {
          "Survey CC": "NP",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctChecked": "0.47",
          "percent": 0.47
        },
        "PA": {
          "Survey CC": "PA",
          "PctChecked": "0",
          "percent": 0
        },
        "PE": {
          "Survey CC": "PE",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "PH": {
          "Survey CC": "PH",
          "PctChecked": "0.55",
          "percent": 0.55
        },
        "PK": {
          "Survey CC": "PK",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "PL": {
          "Survey CC": "PL",
          "PctChecked": "0.23",
          "percent": 0.23
        },
        "PR": {
          "Survey CC": "PR",
          "PctChecked": "0",
          "percent": 0
        },
        "PS": {
          "Survey CC": "PS",
          "PctChecked": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "PY": {
          "Survey CC": "PY",
          "PctChecked": "1",
          "percent": 1
        },
        "RO": {
          "Survey CC": "RO",
          "PctChecked": "0.1",
          "percent": 0.1
        },
        "RS": {
          "Survey CC": "RS",
          "PctChecked": "0",
          "percent": 0
        },
        "RW": {
          "Survey CC": "RW",
          "PctChecked": "1",
          "percent": 1
        },
        "SA": {
          "Survey CC": "SA",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SD": {
          "Survey CC": "SD",
          "PctChecked": "1",
          "percent": 1
        },
        "SE": {
          "Survey CC": "SE",
          "PctChecked": "0.15",
          "percent": 0.15
        },
        "SG": {
          "Survey CC": "SG",
          "PctChecked": "0.23",
          "percent": 0.23
        },
        "SI": {
          "Survey CC": "SI",
          "PctChecked": "0.27",
          "percent": 0.27
        },
        "SK": {
          "Survey CC": "SK",
          "PctChecked": "0",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctChecked": "0.77",
          "percent": 0.77
        },
        "SN": {
          "Survey CC": "SN",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "SO": {
          "Survey CC": "SO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SR": {
          "Survey CC": "SR",
          "PctChecked": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctChecked": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctChecked": "0",
          "percent": 0
        },
        "SY": {
          "Survey CC": "SY",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctChecked": "1",
          "percent": 1
        },
        "TD": {
          "Survey CC": "TD",
          "PctChecked": "1",
          "percent": 1
        },
        "TG": {
          "Survey CC": "TG",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "TH": {
          "Survey CC": "TH",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "TR": {
          "Survey CC": "TR",
          "PctChecked": "0.49",
          "percent": 0.49
        },
        "TT": {
          "Survey CC": "TT",
          "PctChecked": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctChecked": "0.19",
          "percent": 0.19
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "UA": {
          "Survey CC": "UA",
          "PctChecked": "0.32",
          "percent": 0.32
        },
        "UG": {
          "Survey CC": "UG",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "US": {
          "Survey CC": "US",
          "PctChecked": "0.58",
          "percent": 0.58
        },
        "UY": {
          "Survey CC": "UY",
          "PctChecked": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctChecked": "0",
          "percent": 0
        },
        "VI": {
          "Survey CC": "VI",
          "PctChecked": "1",
          "percent": 1
        },
        "VN": {
          "Survey CC": "VN",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "YE": {
          "Survey CC": "YE",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctChecked": "0.64",
          "percent": 0.64
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctChecked": "0.89",
          "percent": 0.89
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctChecked": "0.83",
          "percent": 0.83
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctChecked": "0.39",
          "percent": 0.39
        },
        "South": {
          "GlobalRegion": "South",
          "PctChecked": "0.44",
          "percent": 0.44
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctChecked": "0.41",
          "percent": 0.41
        }
      },
      "r": {
        "Europe": {
          "CH Region": "Europe",
          "PctChecked": "0.3",
          "percent": 0.3
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctChecked": "0.47",
          "percent": 0.47
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctChecked": "0.49",
          "percent": 0.49
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "North America": {
          "CH Region": "North America",
          "PctChecked": "0.57",
          "percent": 0.57
        },
        "Africa": {
          "CH Region": "Africa",
          "PctChecked": "0.65",
          "percent": 0.65
        },
        "Total": {
          "CH Region": "Total",
          "PctChecked": "0.41",
          "percent": 0.41
        }
      }
    },
    "15.3": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctChecked": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctChecked": "0",
          "percent": 0
        },
        "AF": {
          "Survey CC": "AF",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "AL": {
          "Survey CC": "AL",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "AO": {
          "Survey CC": "AO",
          "PctChecked": "0",
          "percent": 0
        },
        "AR": {
          "Survey CC": "AR",
          "PctChecked": "0.64",
          "percent": 0.64
        },
        "AT": {
          "Survey CC": "AT",
          "PctChecked": "0",
          "percent": 0
        },
        "AU": {
          "Survey CC": "AU",
          "PctChecked": "0.29",
          "percent": 0.29
        },
        "BD": {
          "Survey CC": "BD",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "BE": {
          "Survey CC": "BE",
          "PctChecked": "0.15",
          "percent": 0.15
        },
        "BG": {
          "Survey CC": "BG",
          "PctChecked": "0.23",
          "percent": 0.23
        },
        "BH": {
          "Survey CC": "BH",
          "PctChecked": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctChecked": "0",
          "percent": 0
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "BO": {
          "Survey CC": "BO",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "BR": {
          "Survey CC": "BR",
          "PctChecked": "0.49",
          "percent": 0.49
        },
        "BS": {
          "Survey CC": "BS",
          "PctChecked": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "CA": {
          "Survey CC": "CA",
          "PctChecked": "0.3",
          "percent": 0.3
        },
        "CD": {
          "Survey CC": "CD",
          "PctChecked": "0.47",
          "percent": 0.47
        },
        "CF": {
          "Survey CC": "CF",
          "PctChecked": "0",
          "percent": 0
        },
        "CG": {
          "Survey CC": "CG",
          "PctChecked": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "CI": {
          "Survey CC": "CI",
          "PctChecked": "0",
          "percent": 0
        },
        "CL": {
          "Survey CC": "CL",
          "PctChecked": "0.42",
          "percent": 0.42
        },
        "CM": {
          "Survey CC": "CM",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "CN": {
          "Survey CC": "CN",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "CO": {
          "Survey CC": "CO",
          "PctChecked": "0.59",
          "percent": 0.59
        },
        "CR": {
          "Survey CC": "CR",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "DE": {
          "Survey CC": "DE",
          "PctChecked": "0.29",
          "percent": 0.29
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctChecked": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctChecked": "0.06",
          "percent": 0.06
        },
        "DO": {
          "Survey CC": "DO",
          "PctChecked": "0.8",
          "percent": 0.8
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctChecked": "1",
          "percent": 1
        },
        "EC": {
          "Survey CC": "EC",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "EE": {
          "Survey CC": "EE",
          "PctChecked": "0",
          "percent": 0
        },
        "EG": {
          "Survey CC": "EG",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "ES": {
          "Survey CC": "ES",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "ET": {
          "Survey CC": "ET",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "FI": {
          "Survey CC": "FI",
          "PctChecked": "0.09",
          "percent": 0.09
        },
        "FR": {
          "Survey CC": "FR",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "GA": {
          "Survey CC": "GA",
          "PctChecked": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctChecked": "0.27",
          "percent": 0.27
        },
        "GE": {
          "Survey CC": "GE",
          "PctChecked": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctChecked": "0.47",
          "percent": 0.47
        },
        "GN": {
          "Survey CC": "GN",
          "PctChecked": "0",
          "percent": 0
        },
        "GR": {
          "Survey CC": "GR",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "GT": {
          "Survey CC": "GT",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "HK": {
          "Survey CC": "HK",
          "PctChecked": "0.05",
          "percent": 0.05
        },
        "HN": {
          "Survey CC": "HN",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "HR": {
          "Survey CC": "HR",
          "PctChecked": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "HU": {
          "Survey CC": "HU",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "ID": {
          "Survey CC": "ID",
          "PctChecked": "0.46",
          "percent": 0.46
        },
        "IE": {
          "Survey CC": "IE",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "IN": {
          "Survey CC": "IN",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "IT": {
          "Survey CC": "IT",
          "PctChecked": "0.26",
          "percent": 0.26
        },
        "JO": {
          "Survey CC": "JO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctChecked": "0.23",
          "percent": 0.23
        },
        "KE": {
          "Survey CC": "KE",
          "PctChecked": "0.34",
          "percent": 0.34
        },
        "KG": {
          "Survey CC": "KG",
          "PctChecked": "0",
          "percent": 0
        },
        "KH": {
          "Survey CC": "KH",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "KR": {
          "Survey CC": "KR",
          "PctChecked": "0.11",
          "percent": 0.11
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "LB": {
          "Survey CC": "LB",
          "PctChecked": "0",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctChecked": "0.38",
          "percent": 0.38
        },
        "LR": {
          "Survey CC": "LR",
          "PctChecked": "0.78",
          "percent": 0.78
        },
        "LT": {
          "Survey CC": "LT",
          "PctChecked": "0",
          "percent": 0
        },
        "LU": {
          "Survey CC": "LU",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "LV": {
          "Survey CC": "LV",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "LY": {
          "Survey CC": "LY",
          "PctChecked": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "ML": {
          "Survey CC": "ML",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "MM": {
          "Survey CC": "MM",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "MN": {
          "Survey CC": "MN",
          "PctChecked": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctChecked": "0",
          "percent": 0
        },
        "MT": {
          "Survey CC": "MT",
          "PctChecked": "0",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "MW": {
          "Survey CC": "MW",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "MX": {
          "Survey CC": "MX",
          "PctChecked": "0.51",
          "percent": 0.51
        },
        "MY": {
          "Survey CC": "MY",
          "PctChecked": "0.42",
          "percent": 0.42
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NA": {
          "Survey CC": "NA",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NE": {
          "Survey CC": "NE",
          "PctChecked": "1",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctChecked": "0.45",
          "percent": 0.45
        },
        "NI": {
          "Survey CC": "NI",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "NL": {
          "Survey CC": "NL",
          "PctChecked": "0.21",
          "percent": 0.21
        },
        "NP": {
          "Survey CC": "NP",
          "PctChecked": "0.8",
          "percent": 0.8
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctChecked": "0.28",
          "percent": 0.28
        },
        "PA": {
          "Survey CC": "PA",
          "PctChecked": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "PH": {
          "Survey CC": "PH",
          "PctChecked": "0.53",
          "percent": 0.53
        },
        "PK": {
          "Survey CC": "PK",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "PL": {
          "Survey CC": "PL",
          "PctChecked": "0.38",
          "percent": 0.38
        },
        "PR": {
          "Survey CC": "PR",
          "PctChecked": "0",
          "percent": 0
        },
        "PS": {
          "Survey CC": "PS",
          "PctChecked": "0",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctChecked": "0.29",
          "percent": 0.29
        },
        "PY": {
          "Survey CC": "PY",
          "PctChecked": "0",
          "percent": 0
        },
        "RO": {
          "Survey CC": "RO",
          "PctChecked": "0.28",
          "percent": 0.28
        },
        "RS": {
          "Survey CC": "RS",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "RW": {
          "Survey CC": "RW",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "SA": {
          "Survey CC": "SA",
          "PctChecked": "0.45",
          "percent": 0.45
        },
        "SD": {
          "Survey CC": "SD",
          "PctChecked": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctChecked": "0.24",
          "percent": 0.24
        },
        "SG": {
          "Survey CC": "SG",
          "PctChecked": "0.06",
          "percent": 0.06
        },
        "SI": {
          "Survey CC": "SI",
          "PctChecked": "0.18",
          "percent": 0.18
        },
        "SK": {
          "Survey CC": "SK",
          "PctChecked": "0",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "SN": {
          "Survey CC": "SN",
          "PctChecked": "0",
          "percent": 0
        },
        "SO": {
          "Survey CC": "SO",
          "PctChecked": "0",
          "percent": 0
        },
        "SR": {
          "Survey CC": "SR",
          "PctChecked": "0",
          "percent": 0
        },
        "ST": {
          "Survey CC": "ST",
          "PctChecked": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctChecked": "1",
          "percent": 1
        },
        "SY": {
          "Survey CC": "SY",
          "PctChecked": "1",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "TD": {
          "Survey CC": "TD",
          "PctChecked": "0",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "TH": {
          "Survey CC": "TH",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "TR": {
          "Survey CC": "TR",
          "PctChecked": "0.36",
          "percent": 0.36
        },
        "TT": {
          "Survey CC": "TT",
          "PctChecked": "1",
          "percent": 1
        },
        "TW": {
          "Survey CC": "TW",
          "PctChecked": "0.13",
          "percent": 0.13
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "UA": {
          "Survey CC": "UA",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "UG": {
          "Survey CC": "UG",
          "PctChecked": "0.3",
          "percent": 0.3
        },
        "US": {
          "Survey CC": "US",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "UY": {
          "Survey CC": "UY",
          "PctChecked": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "VI": {
          "Survey CC": "VI",
          "PctChecked": "0",
          "percent": 0
        },
        "VN": {
          "Survey CC": "VN",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "YE": {
          "Survey CC": "YE",
          "PctChecked": "0.8",
          "percent": 0.8
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctChecked": "0.47",
          "percent": 0.47
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctChecked": "0.83",
          "percent": 0.83
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctChecked": "0.3",
          "percent": 0.3
        },
        "South": {
          "GlobalRegion": "South",
          "PctChecked": "0.44",
          "percent": 0.44
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctChecked": "0.35",
          "percent": 0.35
        }
      },
      "r": {
        "Europe": {
          "CH Region": "Europe",
          "PctChecked": "0.28",
          "percent": 0.28
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctChecked": "0.38",
          "percent": 0.38
        },
        "North America": {
          "CH Region": "North America",
          "PctChecked": "0.39",
          "percent": 0.39
        },
        "Africa": {
          "CH Region": "Africa",
          "PctChecked": "0.42",
          "percent": 0.42
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctChecked": "0.48",
          "percent": 0.48
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctChecked": "0.51",
          "percent": 0.51
        },
        "Total": {
          "CH Region": "Total",
          "PctChecked": "0.35",
          "percent": 0.35
        }
      }
    },
    "16.1": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AF": {
          "Survey CC": "AF",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AO": {
          "Survey CC": "AO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BD": {
          "Survey CC": "BD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CF": {
          "Survey CC": "CF",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CI": {
          "Survey CC": "CI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "GA": {
          "Survey CC": "GA",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LB": {
          "Survey CC": "LB",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LY": {
          "Survey CC": "LY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NE": {
          "Survey CC": "NE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "PA": {
          "Survey CC": "PA",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SD": {
          "Survey CC": "SD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SY": {
          "Survey CC": "SY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "UY": {
          "Survey CC": "UY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LR": {
          "Survey CC": "LR",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "CD": {
          "Survey CC": "CD",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        },
        "BI": {
          "Survey CC": "BI",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "NP": {
          "Survey CC": "NP",
          "PctStronglyAgree": "0.2",
          "percent": 0.2
        },
        "CM": {
          "Survey CC": "CM",
          "PctStronglyAgree": "0.21",
          "percent": 0.21
        },
        "GH": {
          "Survey CC": "GH",
          "PctStronglyAgree": "0.22",
          "percent": 0.22
        },
        "CN": {
          "Survey CC": "CN",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "ET": {
          "Survey CC": "ET",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "MW": {
          "Survey CC": "MW",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "RW": {
          "Survey CC": "RW",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "TG": {
          "Survey CC": "TG",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "UG": {
          "Survey CC": "UG",
          "PctStronglyAgree": "0.27",
          "percent": 0.27
        },
        "NG": {
          "Survey CC": "NG",
          "PctStronglyAgree": "0.31",
          "percent": 0.31
        },
        "SL": {
          "Survey CC": "SL",
          "PctStronglyAgree": "0.31",
          "percent": 0.31
        },
        "KE": {
          "Survey CC": "KE",
          "PctStronglyAgree": "0.31",
          "percent": 0.31
        },
        "IN": {
          "Survey CC": "IN",
          "PctStronglyAgree": "0.32",
          "percent": 0.32
        },
        "AE": {
          "Survey CC": "AE",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "BO": {
          "Survey CC": "BO",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "BW": {
          "Survey CC": "BW",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "EG": {
          "Survey CC": "EG",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "NI": {
          "Survey CC": "NI",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "PY": {
          "Survey CC": "PY",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "VE": {
          "Survey CC": "VE",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctStronglyAgree": "0.38",
          "percent": 0.38
        },
        "EC": {
          "Survey CC": "EC",
          "PctStronglyAgree": "0.4",
          "percent": 0.4
        },
        "VN": {
          "Survey CC": "VN",
          "PctStronglyAgree": "0.4",
          "percent": 0.4
        },
        "PH": {
          "Survey CC": "PH",
          "PctStronglyAgree": "0.47",
          "percent": 0.47
        },
        "ID": {
          "Survey CC": "ID",
          "PctStronglyAgree": "0.49",
          "percent": 0.49
        },
        "EE": {
          "Survey CC": "EE",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "GN": {
          "Survey CC": "GN",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "GT": {
          "Survey CC": "GT",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "LU": {
          "Survey CC": "LU",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "LV": {
          "Survey CC": "LV",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "ML": {
          "Survey CC": "ML",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "MM": {
          "Survey CC": "MM",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "MU": {
          "Survey CC": "MU",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "NA": {
          "Survey CC": "NA",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "PE": {
          "Survey CC": "PE",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "RS": {
          "Survey CC": "RS",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "SO": {
          "Survey CC": "SO",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "TD": {
          "Survey CC": "TD",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "VI": {
          "Survey CC": "VI",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctStronglyAgree": "0.53",
          "percent": 0.53
        },
        "AR": {
          "Survey CC": "AR",
          "PctStronglyAgree": "0.55",
          "percent": 0.55
        },
        "TH": {
          "Survey CC": "TH",
          "PctStronglyAgree": "0.56",
          "percent": 0.56
        },
        "CO": {
          "Survey CC": "CO",
          "PctStronglyAgree": "0.57",
          "percent": 0.57
        },
        "HN": {
          "Survey CC": "HN",
          "PctStronglyAgree": "0.57",
          "percent": 0.57
        },
        "PL": {
          "Survey CC": "PL",
          "PctStronglyAgree": "0.57",
          "percent": 0.57
        },
        "UA": {
          "Survey CC": "UA",
          "PctStronglyAgree": "0.58",
          "percent": 0.58
        },
        "FR": {
          "Survey CC": "FR",
          "PctStronglyAgree": "0.58",
          "percent": 0.58
        },
        "PK": {
          "Survey CC": "PK",
          "PctStronglyAgree": "0.58",
          "percent": 0.58
        },
        "BR": {
          "Survey CC": "BR",
          "PctStronglyAgree": "0.59",
          "percent": 0.59
        },
        "DO": {
          "Survey CC": "DO",
          "PctStronglyAgree": "0.6",
          "percent": 0.6
        },
        "YE": {
          "Survey CC": "YE",
          "PctStronglyAgree": "0.6",
          "percent": 0.6
        },
        "MX": {
          "Survey CC": "MX",
          "PctStronglyAgree": "0.63",
          "percent": 0.63
        },
        "SA": {
          "Survey CC": "SA",
          "PctStronglyAgree": "0.64",
          "percent": 0.64
        },
        "CL": {
          "Survey CC": "CL",
          "PctStronglyAgree": "0.65",
          "percent": 0.65
        },
        "AL": {
          "Survey CC": "AL",
          "PctStronglyAgree": "0.67",
          "percent": 0.67
        },
        "GR": {
          "Survey CC": "GR",
          "PctStronglyAgree": "0.67",
          "percent": 0.67
        },
        "KH": {
          "Survey CC": "KH",
          "PctStronglyAgree": "0.67",
          "percent": 0.67
        },
        "MY": {
          "Survey CC": "MY",
          "PctStronglyAgree": "0.67",
          "percent": 0.67
        },
        "TR": {
          "Survey CC": "TR",
          "PctStronglyAgree": "0.68",
          "percent": 0.68
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctStronglyAgree": "0.68",
          "percent": 0.68
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctStronglyAgree": "0.7",
          "percent": 0.7
        },
        "US": {
          "Survey CC": "US",
          "PctStronglyAgree": "0.71",
          "percent": 0.71
        },
        "RO": {
          "Survey CC": "RO",
          "PctStronglyAgree": "0.72",
          "percent": 0.72
        },
        "JP": {
          "Survey CC": "JP",
          "PctStronglyAgree": "0.73",
          "percent": 0.73
        },
        "CR": {
          "Survey CC": "CR",
          "PctStronglyAgree": "0.75",
          "percent": 0.75
        },
        "LK": {
          "Survey CC": "LK",
          "PctStronglyAgree": "0.75",
          "percent": 0.75
        },
        "SI": {
          "Survey CC": "SI",
          "PctStronglyAgree": "0.76",
          "percent": 0.76
        },
        "IE": {
          "Survey CC": "IE",
          "PctStronglyAgree": "0.76",
          "percent": 0.76
        },
        "CA": {
          "Survey CC": "CA",
          "PctStronglyAgree": "0.76",
          "percent": 0.76
        },
        "DE": {
          "Survey CC": "DE",
          "PctStronglyAgree": "0.77",
          "percent": 0.77
        },
        "TW": {
          "Survey CC": "TW",
          "PctStronglyAgree": "0.77",
          "percent": 0.77
        },
        "AT": {
          "Survey CC": "AT",
          "PctStronglyAgree": "0.78",
          "percent": 0.78
        },
        "GB": {
          "Survey CC": "GB",
          "PctStronglyAgree": "0.79",
          "percent": 0.79
        },
        "BG": {
          "Survey CC": "BG",
          "PctStronglyAgree": "0.79",
          "percent": 0.79
        },
        "IT": {
          "Survey CC": "IT",
          "PctStronglyAgree": "0.79",
          "percent": 0.79
        },
        "HK": {
          "Survey CC": "HK",
          "PctStronglyAgree": "0.79",
          "percent": 0.79
        },
        "AU": {
          "Survey CC": "AU",
          "PctStronglyAgree": "0.79",
          "percent": 0.79
        },
        "SE": {
          "Survey CC": "SE",
          "PctStronglyAgree": "0.79",
          "percent": 0.79
        },
        "MD": {
          "Survey CC": "MD",
          "PctStronglyAgree": "0.79",
          "percent": 0.79
        },
        "NL": {
          "Survey CC": "NL",
          "PctStronglyAgree": "0.8",
          "percent": 0.8
        },
        "BE": {
          "Survey CC": "BE",
          "PctStronglyAgree": "0.81",
          "percent": 0.81
        },
        "KR": {
          "Survey CC": "KR",
          "PctStronglyAgree": "0.81",
          "percent": 0.81
        },
        "PT": {
          "Survey CC": "PT",
          "PctStronglyAgree": "0.82",
          "percent": 0.82
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctStronglyAgree": "0.83",
          "percent": 0.83
        },
        "HU": {
          "Survey CC": "HU",
          "PctStronglyAgree": "0.85",
          "percent": 0.85
        },
        "SG": {
          "Survey CC": "SG",
          "PctStronglyAgree": "0.87",
          "percent": 0.87
        },
        "ES": {
          "Survey CC": "ES",
          "PctStronglyAgree": "0.88",
          "percent": 0.88
        },
        "CH": {
          "Survey CC": "CH",
          "PctStronglyAgree": "0.9",
          "percent": 0.9
        },
        "FI": {
          "Survey CC": "FI",
          "PctStronglyAgree": "0.91",
          "percent": 0.91
        },
        "DK": {
          "Survey CC": "DK",
          "PctStronglyAgree": "0.94",
          "percent": 0.94
        },
        "BH": {
          "Survey CC": "BH",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "BS": {
          "Survey CC": "BS",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "GE": {
          "Survey CC": "GE",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "HR": {
          "Survey CC": "HR",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "JO": {
          "Survey CC": "JO",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "KG": {
          "Survey CC": "KG",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "LT": {
          "Survey CC": "LT",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "MN": {
          "Survey CC": "MN",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "PR": {
          "Survey CC": "PR",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "PS": {
          "Survey CC": "PS",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "SK": {
          "Survey CC": "SK",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "SN": {
          "Survey CC": "SN",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "SR": {
          "Survey CC": "SR",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "TT": {
          "Survey CC": "TT",
          "PctStronglyAgree": "1",
          "percent": 1
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctStronglyAgree": "0.74",
          "percent": 0.74
        },
        "South": {
          "GlobalRegion": "South",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctStronglyAgree": "0.67",
          "percent": 0.67
        }
      },
      "r": {
        "Europe": {
          "CH Region": "Europe",
          "PctStronglyAgree": "0.73",
          "percent": 0.73
        },
        "North America": {
          "CH Region": "North America",
          "PctStronglyAgree": "0.72",
          "percent": 0.72
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctStronglyAgree": "0.63",
          "percent": 0.63
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctStronglyAgree": "0.61",
          "percent": 0.61
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctStronglyAgree": "0.59",
          "percent": 0.59
        },
        "Africa": {
          "CH Region": "Africa",
          "PctStronglyAgree": "0.34",
          "percent": 0.34
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctStronglyAgree": "0.13",
          "percent": 0.13
        },
        "Total": {
          "CH Region": "Total",
          "PctStronglyAgree": "0.67",
          "percent": 0.67
        }
      }
    },
    "17.1": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "AF": {
          "Survey CC": "AF",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "AL": {
          "Survey CC": "AL",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AO": {
          "Survey CC": "AO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AR": {
          "Survey CC": "AR",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "AT": {
          "Survey CC": "AT",
          "PctStronglyAgree": "0.22",
          "percent": 0.22
        },
        "AU": {
          "Survey CC": "AU",
          "PctStronglyAgree": "0.07",
          "percent": 0.07
        },
        "BD": {
          "Survey CC": "BD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BE": {
          "Survey CC": "BE",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "BG": {
          "Survey CC": "BG",
          "PctStronglyAgree": "0.18",
          "percent": 0.18
        },
        "BH": {
          "Survey CC": "BH",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BO": {
          "Survey CC": "BO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BR": {
          "Survey CC": "BR",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "BS": {
          "Survey CC": "BS",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CA": {
          "Survey CC": "CA",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "CD": {
          "Survey CC": "CD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CF": {
          "Survey CC": "CF",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CG": {
          "Survey CC": "CG",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CH": {
          "Survey CC": "CH",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CI": {
          "Survey CC": "CI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CL": {
          "Survey CC": "CL",
          "PctStronglyAgree": "0.13",
          "percent": 0.13
        },
        "CM": {
          "Survey CC": "CM",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CN": {
          "Survey CC": "CN",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CO": {
          "Survey CC": "CO",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "CR": {
          "Survey CC": "CR",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctStronglyAgree": "0.13",
          "percent": 0.13
        },
        "DE": {
          "Survey CC": "DE",
          "PctStronglyAgree": "0.15",
          "percent": 0.15
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctStronglyAgree": "0.35",
          "percent": 0.35
        },
        "DO": {
          "Survey CC": "DO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctStronglyAgree": "0.67",
          "percent": 0.67
        },
        "EC": {
          "Survey CC": "EC",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "EE": {
          "Survey CC": "EE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "EG": {
          "Survey CC": "EG",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "ES": {
          "Survey CC": "ES",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "ET": {
          "Survey CC": "ET",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "FI": {
          "Survey CC": "FI",
          "PctStronglyAgree": "0.36",
          "percent": 0.36
        },
        "FR": {
          "Survey CC": "FR",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "GA": {
          "Survey CC": "GA",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "GB": {
          "Survey CC": "GB",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "GE": {
          "Survey CC": "GE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "GN": {
          "Survey CC": "GN",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "GR": {
          "Survey CC": "GR",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "GT": {
          "Survey CC": "GT",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "HK": {
          "Survey CC": "HK",
          "PctStronglyAgree": "0.16",
          "percent": 0.16
        },
        "HN": {
          "Survey CC": "HN",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "HR": {
          "Survey CC": "HR",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "HU": {
          "Survey CC": "HU",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "ID": {
          "Survey CC": "ID",
          "PctStronglyAgree": "0.29",
          "percent": 0.29
        },
        "IE": {
          "Survey CC": "IE",
          "PctStronglyAgree": "0.04",
          "percent": 0.04
        },
        "IN": {
          "Survey CC": "IN",
          "PctStronglyAgree": "0.07",
          "percent": 0.07
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "IT": {
          "Survey CC": "IT",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "JO": {
          "Survey CC": "JO",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctStronglyAgree": "0.14",
          "percent": 0.14
        },
        "KE": {
          "Survey CC": "KE",
          "PctStronglyAgree": "0.01",
          "percent": 0.01
        },
        "KG": {
          "Survey CC": "KG",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "KH": {
          "Survey CC": "KH",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "KR": {
          "Survey CC": "KR",
          "PctStronglyAgree": "0.3",
          "percent": 0.3
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LB": {
          "Survey CC": "LB",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LR": {
          "Survey CC": "LR",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LT": {
          "Survey CC": "LT",
          "PctStronglyAgree": "0.4",
          "percent": 0.4
        },
        "LU": {
          "Survey CC": "LU",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LV": {
          "Survey CC": "LV",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LY": {
          "Survey CC": "LY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MD": {
          "Survey CC": "MD",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "ML": {
          "Survey CC": "ML",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MM": {
          "Survey CC": "MM",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "MN": {
          "Survey CC": "MN",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MT": {
          "Survey CC": "MT",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MW": {
          "Survey CC": "MW",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MX": {
          "Survey CC": "MX",
          "PctStronglyAgree": "0.02",
          "percent": 0.02
        },
        "MY": {
          "Survey CC": "MY",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NA": {
          "Survey CC": "NA",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NE": {
          "Survey CC": "NE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NG": {
          "Survey CC": "NG",
          "PctStronglyAgree": "0.03",
          "percent": 0.03
        },
        "NI": {
          "Survey CC": "NI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NL": {
          "Survey CC": "NL",
          "PctStronglyAgree": "0.23",
          "percent": 0.23
        },
        "NP": {
          "Survey CC": "NP",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "PA": {
          "Survey CC": "PA",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "PE": {
          "Survey CC": "PE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "PH": {
          "Survey CC": "PH",
          "PctStronglyAgree": "0.03",
          "percent": 0.03
        },
        "PK": {
          "Survey CC": "PK",
          "PctStronglyAgree": "0.08",
          "percent": 0.08
        },
        "PL": {
          "Survey CC": "PL",
          "PctStronglyAgree": "0.08",
          "percent": 0.08
        },
        "PR": {
          "Survey CC": "PR",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "PS": {
          "Survey CC": "PS",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "PY": {
          "Survey CC": "PY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "RO": {
          "Survey CC": "RO",
          "PctStronglyAgree": "0.14",
          "percent": 0.14
        },
        "RS": {
          "Survey CC": "RS",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "RW": {
          "Survey CC": "RW",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SA": {
          "Survey CC": "SA",
          "PctStronglyAgree": "0.24",
          "percent": 0.24
        },
        "SD": {
          "Survey CC": "SD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctStronglyAgree": "0.21",
          "percent": 0.21
        },
        "SG": {
          "Survey CC": "SG",
          "PctStronglyAgree": "0.26",
          "percent": 0.26
        },
        "SI": {
          "Survey CC": "SI",
          "PctStronglyAgree": "0.18",
          "percent": 0.18
        },
        "SK": {
          "Survey CC": "SK",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SN": {
          "Survey CC": "SN",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SO": {
          "Survey CC": "SO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SR": {
          "Survey CC": "SR",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "ST": {
          "Survey CC": "ST",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SY": {
          "Survey CC": "SY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "TD": {
          "Survey CC": "TD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "TH": {
          "Survey CC": "TH",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "TR": {
          "Survey CC": "TR",
          "PctStronglyAgree": "0.05",
          "percent": 0.05
        },
        "TT": {
          "Survey CC": "TT",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctStronglyAgree": "0.14",
          "percent": 0.14
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctStronglyAgree": "0.13",
          "percent": 0.13
        },
        "UA": {
          "Survey CC": "UA",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        },
        "UG": {
          "Survey CC": "UG",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "US": {
          "Survey CC": "US",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "UY": {
          "Survey CC": "UY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "VE": {
          "Survey CC": "VE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "VI": {
          "Survey CC": "VI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "VN": {
          "Survey CC": "VN",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "YE": {
          "Survey CC": "YE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctStronglyAgree": "0.04",
          "percent": 0.04
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctStronglyAgree": "0",
          "percent": 0
        }
      },
      "ns": {
        "South": {
          "GlobalRegion": "South",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        },
        "North": {
          "GlobalRegion": "North",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        }
      },
      "r": {
        "Middle East": {
          "CH Region": "Middle East",
          "PctStronglyAgree": "0.23",
          "percent": 0.23
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "Europe": {
          "CH Region": "Europe",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        },
        "North America": {
          "CH Region": "North America",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "Africa": {
          "CH Region": "Africa",
          "PctStronglyAgree": "0.03",
          "percent": 0.03
        },
        "Total": {
          "CH Region": "Total",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        }
      }
    },
    "18.1": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "...4": "Did not select this option",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 0.5
        },
        "AF": {
          "Survey CC": "AF",
          "PctChecked": "0.67",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 0.67
        },
        "AL": {
          "Survey CC": "AL",
          "PctChecked": "0.67",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 0.67
        },
        "AO": {
          "Survey CC": "AO",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctChecked": "0.63",
          "Count of Vrid": "16",
          "...4": "",
          "percent": 0.63
        },
        "AT": {
          "Survey CC": "AT",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 0.33
        },
        "AU": {
          "Survey CC": "AU",
          "PctChecked": "0.8",
          "Count of Vrid": "81",
          "...4": "",
          "percent": 0.8
        },
        "BD": {
          "Survey CC": "BD",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 0.33
        },
        "BE": {
          "Survey CC": "BE",
          "PctChecked": "0.67",
          "Count of Vrid": "54",
          "...4": "",
          "percent": 0.67
        },
        "BG": {
          "Survey CC": "BG",
          "PctChecked": "0.53",
          "Count of Vrid": "34",
          "...4": "",
          "percent": 0.53
        },
        "BH": {
          "Survey CC": "BH",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctChecked": "0.5",
          "Count of Vrid": "6",
          "...4": "",
          "percent": 0.5
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctChecked": "0.67",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 0.67
        },
        "BO": {
          "Survey CC": "BO",
          "PctChecked": "1",
          "Count of Vrid": "5",
          "...4": "",
          "percent": 1
        },
        "BR": {
          "Survey CC": "BR",
          "PctChecked": "0.69",
          "Count of Vrid": "115",
          "...4": "",
          "percent": 0.69
        },
        "BS": {
          "Survey CC": "BS",
          "PctChecked": "0",
          "Count of Vrid": "",
          "...4": "Did not respond to this question",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctChecked": "0",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 0
        },
        "CA": {
          "Survey CC": "CA",
          "PctChecked": "0.68",
          "Count of Vrid": "208",
          "...4": "",
          "percent": 0.68
        },
        "CD": {
          "Survey CC": "CD",
          "PctChecked": "0.59",
          "Count of Vrid": "17",
          "...4": "",
          "percent": 0.59
        },
        "CF": {
          "Survey CC": "CF",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctChecked": "1",
          "Count of Vrid": "4",
          "...4": "",
          "percent": 1
        },
        "CI": {
          "Survey CC": "CI",
          "PctChecked": "1",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctChecked": "0.48",
          "Count of Vrid": "40",
          "...4": "",
          "percent": 0.48
        },
        "CM": {
          "Survey CC": "CM",
          "PctChecked": "0.57",
          "Count of Vrid": "14",
          "...4": "",
          "percent": 0.57
        },
        "CN": {
          "Survey CC": "CN",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 0
        },
        "CO": {
          "Survey CC": "CO",
          "PctChecked": "0.63",
          "Count of Vrid": "48",
          "...4": "",
          "percent": 0.63
        },
        "CR": {
          "Survey CC": "CR",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 0.5
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctChecked": "0.58",
          "Count of Vrid": "73",
          "...4": "",
          "percent": 0.58
        },
        "DE": {
          "Survey CC": "DE",
          "PctChecked": "0.8",
          "Count of Vrid": "117",
          "...4": "",
          "percent": 0.8
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 0.33
        },
        "DO": {
          "Survey CC": "DO",
          "PctChecked": "0.4",
          "Count of Vrid": "5",
          "...4": "",
          "percent": 0.4
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 0.5
        },
        "EC": {
          "Survey CC": "EC",
          "PctChecked": "0.75",
          "Count of Vrid": "8",
          "...4": "",
          "percent": 0.75
        },
        "EE": {
          "Survey CC": "EE",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 0
        },
        "EG": {
          "Survey CC": "EG",
          "PctChecked": "0.67",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 0.67
        },
        "ES": {
          "Survey CC": "ES",
          "PctChecked": "0.48",
          "Count of Vrid": "81",
          "...4": "",
          "percent": 0.48
        },
        "ET": {
          "Survey CC": "ET",
          "PctChecked": "1",
          "Count of Vrid": "4",
          "...4": "",
          "percent": 1
        },
        "FI": {
          "Survey CC": "FI",
          "PctChecked": "1",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 1
        },
        "FR": {
          "Survey CC": "FR",
          "PctChecked": "0.78",
          "Count of Vrid": "9",
          "...4": "",
          "percent": 0.78
        },
        "GA": {
          "Survey CC": "GA",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctChecked": "0.53",
          "Count of Vrid": "59",
          "...4": "",
          "percent": 0.53
        },
        "GE": {
          "Survey CC": "GE",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctChecked": "0.72",
          "Count of Vrid": "32",
          "...4": "",
          "percent": 0.72
        },
        "GN": {
          "Survey CC": "GN",
          "PctChecked": "0.75",
          "Count of Vrid": "4",
          "...4": "",
          "percent": 0.75
        },
        "GR": {
          "Survey CC": "GR",
          "PctChecked": "1",
          "Count of Vrid": "4",
          "...4": "",
          "percent": 1
        },
        "GT": {
          "Survey CC": "GT",
          "PctChecked": "0.75",
          "Count of Vrid": "8",
          "...4": "",
          "percent": 0.75
        },
        "HK": {
          "Survey CC": "HK",
          "PctChecked": "0.75",
          "Count of Vrid": "8",
          "...4": "",
          "percent": 0.75
        },
        "HN": {
          "Survey CC": "HN",
          "PctChecked": "0.71",
          "Count of Vrid": "7",
          "...4": "",
          "percent": 0.71
        },
        "HR": {
          "Survey CC": "HR",
          "PctChecked": "0",
          "Count of Vrid": "",
          "...4": "",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctChecked": "0.67",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 0.67
        },
        "HU": {
          "Survey CC": "HU",
          "PctChecked": "0.64",
          "Count of Vrid": "50",
          "...4": "",
          "percent": 0.64
        },
        "ID": {
          "Survey CC": "ID",
          "PctChecked": "0.62",
          "Count of Vrid": "180",
          "...4": "",
          "percent": 0.62
        },
        "IE": {
          "Survey CC": "IE",
          "PctChecked": "0.7",
          "Count of Vrid": "40",
          "...4": "",
          "percent": 0.7
        },
        "IN": {
          "Survey CC": "IN",
          "PctChecked": "0.7",
          "Count of Vrid": "93",
          "...4": "",
          "percent": 0.7
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctChecked": "1",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 1
        },
        "IT": {
          "Survey CC": "IT",
          "PctChecked": "0.77",
          "Count of Vrid": "47",
          "...4": "",
          "percent": 0.77
        },
        "JO": {
          "Survey CC": "JO",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 1
        },
        "JP": {
          "Survey CC": "JP",
          "PctChecked": "0.54",
          "Count of Vrid": "212",
          "...4": "",
          "percent": 0.54
        },
        "KE": {
          "Survey CC": "KE",
          "PctChecked": "0.75",
          "Count of Vrid": "64",
          "...4": "",
          "percent": 0.75
        },
        "KG": {
          "Survey CC": "KG",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 1
        },
        "KH": {
          "Survey CC": "KH",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 1
        },
        "KR": {
          "Survey CC": "KR",
          "PctChecked": "0.73",
          "Count of Vrid": "15",
          "...4": "",
          "percent": 0.73
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctChecked": "0",
          "Count of Vrid": "4",
          "...4": "",
          "percent": 0
        },
        "LB": {
          "Survey CC": "LB",
          "PctChecked": "0",
          "Count of Vrid": "",
          "...4": "",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctChecked": "0.71",
          "Count of Vrid": "7",
          "...4": "",
          "percent": 0.71
        },
        "LR": {
          "Survey CC": "LR",
          "PctChecked": "0.75",
          "Count of Vrid": "8",
          "...4": "",
          "percent": 0.75
        },
        "LT": {
          "Survey CC": "LT",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 0.5
        },
        "LU": {
          "Survey CC": "LU",
          "PctChecked": "1",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 1
        },
        "LV": {
          "Survey CC": "LV",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 0
        },
        "LY": {
          "Survey CC": "LY",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctChecked": "0.24",
          "Count of Vrid": "25",
          "...4": "",
          "percent": 0.24
        },
        "ML": {
          "Survey CC": "ML",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctChecked": "0.5",
          "Count of Vrid": "4",
          "...4": "",
          "percent": 0.5
        },
        "MN": {
          "Survey CC": "MN",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 0
        },
        "MT": {
          "Survey CC": "MT",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctChecked": "1",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 1
        },
        "MW": {
          "Survey CC": "MW",
          "PctChecked": "1",
          "Count of Vrid": "4",
          "...4": "",
          "percent": 1
        },
        "MX": {
          "Survey CC": "MX",
          "PctChecked": "0.5",
          "Count of Vrid": "125",
          "...4": "",
          "percent": 0.5
        },
        "MY": {
          "Survey CC": "MY",
          "PctChecked": "0.74",
          "Count of Vrid": "19",
          "...4": "",
          "percent": 0.74
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctChecked": "1",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctChecked": "1",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 1
        },
        "NE": {
          "Survey CC": "NE",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 0.5
        },
        "NG": {
          "Survey CC": "NG",
          "PctChecked": "0.76",
          "Count of Vrid": "54",
          "...4": "",
          "percent": 0.76
        },
        "NI": {
          "Survey CC": "NI",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 0.33
        },
        "NL": {
          "Survey CC": "NL",
          "PctChecked": "0.68",
          "Count of Vrid": "19",
          "...4": "",
          "percent": 0.68
        },
        "NP": {
          "Survey CC": "NP",
          "PctChecked": "0.5",
          "Count of Vrid": "4",
          "...4": "",
          "percent": 0.5
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctChecked": "0.6",
          "Count of Vrid": "40",
          "...4": "",
          "percent": 0.6
        },
        "PA": {
          "Survey CC": "PA",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctChecked": "0.63",
          "Count of Vrid": "8",
          "...4": "",
          "percent": 0.63
        },
        "PH": {
          "Survey CC": "PH",
          "PctChecked": "0.82",
          "Count of Vrid": "45",
          "...4": "",
          "percent": 0.82
        },
        "PK": {
          "Survey CC": "PK",
          "PctChecked": "0.8",
          "Count of Vrid": "5",
          "...4": "",
          "percent": 0.8
        },
        "PL": {
          "Survey CC": "PL",
          "PctChecked": "0.7",
          "Count of Vrid": "81",
          "...4": "",
          "percent": 0.7
        },
        "PR": {
          "Survey CC": "PR",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 1
        },
        "PS": {
          "Survey CC": "PS",
          "PctChecked": "0",
          "Count of Vrid": "",
          "...4": "",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctChecked": "0.48",
          "Count of Vrid": "124",
          "...4": "",
          "percent": 0.48
        },
        "PY": {
          "Survey CC": "PY",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 1
        },
        "RO": {
          "Survey CC": "RO",
          "PctChecked": "0.53",
          "Count of Vrid": "64",
          "...4": "",
          "percent": 0.53
        },
        "RS": {
          "Survey CC": "RS",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 0
        },
        "RW": {
          "Survey CC": "RW",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 0.33
        },
        "SA": {
          "Survey CC": "SA",
          "PctChecked": "0.47",
          "Count of Vrid": "17",
          "...4": "",
          "percent": 0.47
        },
        "SD": {
          "Survey CC": "SD",
          "PctChecked": "0",
          "Count of Vrid": "",
          "...4": "",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctChecked": "0.63",
          "Count of Vrid": "8",
          "...4": "",
          "percent": 0.63
        },
        "SG": {
          "Survey CC": "SG",
          "PctChecked": "0.67",
          "Count of Vrid": "6",
          "...4": "",
          "percent": 0.67
        },
        "SI": {
          "Survey CC": "SI",
          "PctChecked": "0.5",
          "Count of Vrid": "12",
          "...4": "",
          "percent": 0.5
        },
        "SK": {
          "Survey CC": "SK",
          "PctChecked": "0",
          "Count of Vrid": "",
          "...4": "",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctChecked": "0.92",
          "Count of Vrid": "13",
          "...4": "",
          "percent": 0.92
        },
        "SN": {
          "Survey CC": "SN",
          "PctChecked": "0.67",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 0.67
        },
        "SO": {
          "Survey CC": "SO",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 0.5
        },
        "SR": {
          "Survey CC": "SR",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctChecked": "0.67",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 0.67
        },
        "SY": {
          "Survey CC": "SY",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 0.5
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctChecked": "0.67",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 0.67
        },
        "TD": {
          "Survey CC": "TD",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctChecked": "1",
          "Count of Vrid": "4",
          "...4": "",
          "percent": 1
        },
        "TH": {
          "Survey CC": "TH",
          "PctChecked": "0.58",
          "Count of Vrid": "12",
          "...4": "",
          "percent": 0.58
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctChecked": "0.83",
          "Count of Vrid": "6",
          "...4": "",
          "percent": 0.83
        },
        "TR": {
          "Survey CC": "TR",
          "PctChecked": "0.77",
          "Count of Vrid": "43",
          "...4": "",
          "percent": 0.77
        },
        "TT": {
          "Survey CC": "TT",
          "PctChecked": "0",
          "Count of Vrid": "",
          "...4": "",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctChecked": "0.6",
          "Count of Vrid": "94",
          "...4": "",
          "percent": 0.6
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctChecked": "0.57",
          "Count of Vrid": "21",
          "...4": "",
          "percent": 0.57
        },
        "UA": {
          "Survey CC": "UA",
          "PctChecked": "0.51",
          "Count of Vrid": "258",
          "...4": "",
          "percent": 0.51
        },
        "UG": {
          "Survey CC": "UG",
          "PctChecked": "0.74",
          "Count of Vrid": "27",
          "...4": "",
          "percent": 0.74
        },
        "US": {
          "Survey CC": "US",
          "PctChecked": "0.68",
          "Count of Vrid": "957",
          "...4": "",
          "percent": 0.68
        },
        "UY": {
          "Survey CC": "UY",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctChecked": "0.67",
          "Count of Vrid": "6",
          "...4": "",
          "percent": 0.67
        },
        "VI": {
          "Survey CC": "VI",
          "PctChecked": "0",
          "Count of Vrid": "2",
          "...4": "",
          "percent": 0
        },
        "VN": {
          "Survey CC": "VN",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "...4": "",
          "percent": 1
        },
        "YE": {
          "Survey CC": "YE",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "...4": "",
          "percent": 1
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctChecked": "0.67",
          "Count of Vrid": "92",
          "...4": "",
          "percent": 0.67
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctChecked": "0.63",
          "Count of Vrid": "8",
          "...4": "",
          "percent": 0.63
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctChecked": "0.6",
          "Count of Vrid": "5",
          "...4": "",
          "percent": 0.6
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctChecked": "0.64",
          "percent": 0.64
        },
        "South": {
          "GlobalRegion": "South",
          "PctChecked": "0.63",
          "percent": 0.63
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctChecked": "0.64",
          "percent": 0.64
        }
      },
      "r": {
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctChecked": "0.57",
          "percent": 0.57
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctChecked": "0.57",
          "percent": 0.57
        },
        "Europe": {
          "CH Region": "Europe",
          "PctChecked": "0.59",
          "percent": 0.59
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctChecked": "0.64",
          "percent": 0.64
        },
        "North America": {
          "CH Region": "North America",
          "PctChecked": "0.68",
          "percent": 0.68
        },
        "Africa": {
          "CH Region": "Africa",
          "PctChecked": "0.7",
          "percent": 0.7
        },
        "Total": {
          "CH Region": "Total",
          "PctChecked": "0.64",
          "percent": 0.64
        }
      }
    },
    "18.2": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "AF": {
          "Survey CC": "AF",
          "PctChecked": "0.67",
          "Count of Vrid": "3",
          "percent": 0.67
        },
        "AL": {
          "Survey CC": "AL",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "percent": 1
        },
        "AO": {
          "Survey CC": "AO",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctChecked": "0.75",
          "Count of Vrid": "16",
          "percent": 0.75
        },
        "AT": {
          "Survey CC": "AT",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "percent": 0.33
        },
        "AU": {
          "Survey CC": "AU",
          "PctChecked": "0.46",
          "Count of Vrid": "81",
          "percent": 0.46
        },
        "BD": {
          "Survey CC": "BD",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "percent": 1
        },
        "BE": {
          "Survey CC": "BE",
          "PctChecked": "0.5",
          "Count of Vrid": "54",
          "percent": 0.5
        },
        "BG": {
          "Survey CC": "BG",
          "PctChecked": "0.65",
          "Count of Vrid": "34",
          "percent": 0.65
        },
        "BH": {
          "Survey CC": "BH",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctChecked": "0.67",
          "Count of Vrid": "6",
          "percent": 0.67
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctChecked": "0.67",
          "Count of Vrid": "3",
          "percent": 0.67
        },
        "BO": {
          "Survey CC": "BO",
          "PctChecked": "0.6",
          "Count of Vrid": "5",
          "percent": 0.6
        },
        "BR": {
          "Survey CC": "BR",
          "PctChecked": "0.67",
          "Count of Vrid": "115",
          "percent": 0.67
        },
        "BS": {
          "Survey CC": "BS",
          "PctChecked": "0",
          "Count of Vrid": "",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "percent": 1
        },
        "CA": {
          "Survey CC": "CA",
          "PctChecked": "0.61",
          "Count of Vrid": "208",
          "percent": 0.61
        },
        "CD": {
          "Survey CC": "CD",
          "PctChecked": "0.76",
          "Count of Vrid": "17",
          "percent": 0.76
        },
        "CF": {
          "Survey CC": "CF",
          "PctChecked": "0",
          "Count of Vrid": "3",
          "percent": 0
        },
        "CG": {
          "Survey CC": "CG",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctChecked": "0.25",
          "Count of Vrid": "4",
          "percent": 0.25
        },
        "CI": {
          "Survey CC": "CI",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "CL": {
          "Survey CC": "CL",
          "PctChecked": "0.88",
          "Count of Vrid": "40",
          "percent": 0.88
        },
        "CM": {
          "Survey CC": "CM",
          "PctChecked": "0.79",
          "Count of Vrid": "14",
          "percent": 0.79
        },
        "CN": {
          "Survey CC": "CN",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "CO": {
          "Survey CC": "CO",
          "PctChecked": "0.77",
          "Count of Vrid": "48",
          "percent": 0.77
        },
        "CR": {
          "Survey CC": "CR",
          "PctChecked": "0",
          "Count of Vrid": "2",
          "percent": 0
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctChecked": "0.44",
          "Count of Vrid": "73",
          "percent": 0.44
        },
        "DE": {
          "Survey CC": "DE",
          "PctChecked": "0.43",
          "Count of Vrid": "117",
          "percent": 0.43
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "percent": 0.33
        },
        "DO": {
          "Survey CC": "DO",
          "PctChecked": "0.6",
          "Count of Vrid": "5",
          "percent": 0.6
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctChecked": "1",
          "Count of Vrid": "2",
          "percent": 1
        },
        "EC": {
          "Survey CC": "EC",
          "PctChecked": "1",
          "Count of Vrid": "8",
          "percent": 1
        },
        "EE": {
          "Survey CC": "EE",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "EG": {
          "Survey CC": "EG",
          "PctChecked": "0.67",
          "Count of Vrid": "3",
          "percent": 0.67
        },
        "ES": {
          "Survey CC": "ES",
          "PctChecked": "0.49",
          "Count of Vrid": "81",
          "percent": 0.49
        },
        "ET": {
          "Survey CC": "ET",
          "PctChecked": "0.75",
          "Count of Vrid": "4",
          "percent": 0.75
        },
        "FI": {
          "Survey CC": "FI",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "FR": {
          "Survey CC": "FR",
          "PctChecked": "0.78",
          "Count of Vrid": "9",
          "percent": 0.78
        },
        "GA": {
          "Survey CC": "GA",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctChecked": "0.53",
          "Count of Vrid": "59",
          "percent": 0.53
        },
        "GE": {
          "Survey CC": "GE",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctChecked": "0.72",
          "Count of Vrid": "32",
          "percent": 0.72
        },
        "GN": {
          "Survey CC": "GN",
          "PctChecked": "0.5",
          "Count of Vrid": "4",
          "percent": 0.5
        },
        "GR": {
          "Survey CC": "GR",
          "PctChecked": "0.75",
          "Count of Vrid": "4",
          "percent": 0.75
        },
        "GT": {
          "Survey CC": "GT",
          "PctChecked": "0.75",
          "Count of Vrid": "8",
          "percent": 0.75
        },
        "HK": {
          "Survey CC": "HK",
          "PctChecked": "0.5",
          "Count of Vrid": "8",
          "percent": 0.5
        },
        "HN": {
          "Survey CC": "HN",
          "PctChecked": "0.71",
          "Count of Vrid": "7",
          "percent": 0.71
        },
        "HR": {
          "Survey CC": "HR",
          "PctChecked": "0",
          "Count of Vrid": "",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "percent": 1
        },
        "HU": {
          "Survey CC": "HU",
          "PctChecked": "0.58",
          "Count of Vrid": "50",
          "percent": 0.58
        },
        "ID": {
          "Survey CC": "ID",
          "PctChecked": "0.64",
          "Count of Vrid": "180",
          "percent": 0.64
        },
        "IE": {
          "Survey CC": "IE",
          "PctChecked": "0.43",
          "Count of Vrid": "40",
          "percent": 0.43
        },
        "IN": {
          "Survey CC": "IN",
          "PctChecked": "0.72",
          "Count of Vrid": "93",
          "percent": 0.72
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctChecked": "1",
          "Count of Vrid": "2",
          "percent": 1
        },
        "IT": {
          "Survey CC": "IT",
          "PctChecked": "0.55",
          "Count of Vrid": "47",
          "percent": 0.55
        },
        "JO": {
          "Survey CC": "JO",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "JP": {
          "Survey CC": "JP",
          "PctChecked": "0.5",
          "Count of Vrid": "212",
          "percent": 0.5
        },
        "KE": {
          "Survey CC": "KE",
          "PctChecked": "0.77",
          "Count of Vrid": "64",
          "percent": 0.77
        },
        "KG": {
          "Survey CC": "KG",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "KH": {
          "Survey CC": "KH",
          "PctChecked": "0.67",
          "Count of Vrid": "3",
          "percent": 0.67
        },
        "KR": {
          "Survey CC": "KR",
          "PctChecked": "0.13",
          "Count of Vrid": "15",
          "percent": 0.13
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctChecked": "0.75",
          "Count of Vrid": "4",
          "percent": 0.75
        },
        "LB": {
          "Survey CC": "LB",
          "PctChecked": "0",
          "Count of Vrid": "",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctChecked": "0.57",
          "Count of Vrid": "7",
          "percent": 0.57
        },
        "LR": {
          "Survey CC": "LR",
          "PctChecked": "0.63",
          "Count of Vrid": "8",
          "percent": 0.63
        },
        "LT": {
          "Survey CC": "LT",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "LU": {
          "Survey CC": "LU",
          "PctChecked": "0",
          "Count of Vrid": "2",
          "percent": 0
        },
        "LV": {
          "Survey CC": "LV",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "LY": {
          "Survey CC": "LY",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctChecked": "0.64",
          "Count of Vrid": "25",
          "percent": 0.64
        },
        "ML": {
          "Survey CC": "ML",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctChecked": "0.5",
          "Count of Vrid": "4",
          "percent": 0.5
        },
        "MN": {
          "Survey CC": "MN",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctChecked": "1",
          "Count of Vrid": "2",
          "percent": 1
        },
        "MW": {
          "Survey CC": "MW",
          "PctChecked": "0.75",
          "Count of Vrid": "4",
          "percent": 0.75
        },
        "MX": {
          "Survey CC": "MX",
          "PctChecked": "0.73",
          "Count of Vrid": "125",
          "percent": 0.73
        },
        "MY": {
          "Survey CC": "MY",
          "PctChecked": "0.89",
          "Count of Vrid": "19",
          "percent": 0.89
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "NA": {
          "Survey CC": "NA",
          "PctChecked": "0",
          "Count of Vrid": "2",
          "percent": 0
        },
        "NE": {
          "Survey CC": "NE",
          "PctChecked": "1",
          "Count of Vrid": "2",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctChecked": "0.89",
          "Count of Vrid": "54",
          "percent": 0.89
        },
        "NI": {
          "Survey CC": "NI",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "percent": 1
        },
        "NL": {
          "Survey CC": "NL",
          "PctChecked": "0.32",
          "Count of Vrid": "19",
          "percent": 0.32
        },
        "NP": {
          "Survey CC": "NP",
          "PctChecked": "0.5",
          "Count of Vrid": "4",
          "percent": 0.5
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctChecked": "0.43",
          "Count of Vrid": "40",
          "percent": 0.43
        },
        "PA": {
          "Survey CC": "PA",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctChecked": "0.88",
          "Count of Vrid": "8",
          "percent": 0.88
        },
        "PH": {
          "Survey CC": "PH",
          "PctChecked": "0.8",
          "Count of Vrid": "45",
          "percent": 0.8
        },
        "PK": {
          "Survey CC": "PK",
          "PctChecked": "0.8",
          "Count of Vrid": "5",
          "percent": 0.8
        },
        "PL": {
          "Survey CC": "PL",
          "PctChecked": "0.53",
          "Count of Vrid": "81",
          "percent": 0.53
        },
        "PR": {
          "Survey CC": "PR",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "PS": {
          "Survey CC": "PS",
          "PctChecked": "0",
          "Count of Vrid": "",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctChecked": "0.31",
          "Count of Vrid": "124",
          "percent": 0.31
        },
        "PY": {
          "Survey CC": "PY",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "percent": 0.33
        },
        "RO": {
          "Survey CC": "RO",
          "PctChecked": "0.52",
          "Count of Vrid": "64",
          "percent": 0.52
        },
        "RS": {
          "Survey CC": "RS",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "RW": {
          "Survey CC": "RW",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "percent": 1
        },
        "SA": {
          "Survey CC": "SA",
          "PctChecked": "0.82",
          "Count of Vrid": "17",
          "percent": 0.82
        },
        "SD": {
          "Survey CC": "SD",
          "PctChecked": "0",
          "Count of Vrid": "",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctChecked": "0.13",
          "Count of Vrid": "8",
          "percent": 0.13
        },
        "SG": {
          "Survey CC": "SG",
          "PctChecked": "0.17",
          "Count of Vrid": "6",
          "percent": 0.17
        },
        "SI": {
          "Survey CC": "SI",
          "PctChecked": "0.5",
          "Count of Vrid": "12",
          "percent": 0.5
        },
        "SK": {
          "Survey CC": "SK",
          "PctChecked": "0",
          "Count of Vrid": "",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctChecked": "0.85",
          "Count of Vrid": "13",
          "percent": 0.85
        },
        "SN": {
          "Survey CC": "SN",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "percent": 0.33
        },
        "SO": {
          "Survey CC": "SO",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "SR": {
          "Survey CC": "SR",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "ST": {
          "Survey CC": "ST",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "percent": 1
        },
        "SY": {
          "Survey CC": "SY",
          "PctChecked": "1",
          "Count of Vrid": "2",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "percent": 1
        },
        "TD": {
          "Survey CC": "TD",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctChecked": "0.5",
          "Count of Vrid": "4",
          "percent": 0.5
        },
        "TH": {
          "Survey CC": "TH",
          "PctChecked": "0.42",
          "Count of Vrid": "12",
          "percent": 0.42
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctChecked": "0.67",
          "Count of Vrid": "6",
          "percent": 0.67
        },
        "TR": {
          "Survey CC": "TR",
          "PctChecked": "0.72",
          "Count of Vrid": "43",
          "percent": 0.72
        },
        "TT": {
          "Survey CC": "TT",
          "PctChecked": "0",
          "Count of Vrid": "",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctChecked": "0.5",
          "Count of Vrid": "94",
          "percent": 0.5
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctChecked": "0.81",
          "Count of Vrid": "21",
          "percent": 0.81
        },
        "UA": {
          "Survey CC": "UA",
          "PctChecked": "0.55",
          "Count of Vrid": "258",
          "percent": 0.55
        },
        "UG": {
          "Survey CC": "UG",
          "PctChecked": "0.7",
          "Count of Vrid": "27",
          "percent": 0.7
        },
        "US": {
          "Survey CC": "US",
          "PctChecked": "0.66",
          "Count of Vrid": "957",
          "percent": 0.66
        },
        "UY": {
          "Survey CC": "UY",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctChecked": "0.67",
          "Count of Vrid": "6",
          "percent": 0.67
        },
        "VI": {
          "Survey CC": "VI",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "VN": {
          "Survey CC": "VN",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "YE": {
          "Survey CC": "YE",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "percent": 1
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctChecked": "0.63",
          "Count of Vrid": "92",
          "percent": 0.63
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctChecked": "1",
          "Count of Vrid": "8",
          "percent": 1
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctChecked": "1",
          "Count of Vrid": "5",
          "percent": 1
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "South": {
          "GlobalRegion": "South",
          "PctChecked": "0.69",
          "percent": 0.69
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctChecked": "0.61",
          "percent": 0.61
        }
      },
      "r": {
        "Europe": {
          "CH Region": "Europe",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "North America": {
          "CH Region": "North America",
          "PctChecked": "0.65",
          "percent": 0.65
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctChecked": "0.71",
          "percent": 0.71
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctChecked": "0.73",
          "percent": 0.73
        },
        "Africa": {
          "CH Region": "Africa",
          "PctChecked": "0.74",
          "percent": 0.74
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctChecked": "0.82",
          "percent": 0.82
        },
        "Total": {
          "CH Region": "Total",
          "PctChecked": "0.61",
          "percent": 0.61
        }
      }
    },
    "18.3": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "AE": {
          "Survey CC": "AE",
          "PctChecked": "0",
          "Count of Vrid": "2",
          "percent": 0
        },
        "AF": {
          "Survey CC": "AF",
          "PctChecked": "0",
          "Count of Vrid": "3",
          "percent": 0
        },
        "AL": {
          "Survey CC": "AL",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "percent": 0.33
        },
        "AO": {
          "Survey CC": "AO",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctChecked": "0.56",
          "Count of Vrid": "16",
          "percent": 0.56
        },
        "AT": {
          "Survey CC": "AT",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "percent": 0.33
        },
        "AU": {
          "Survey CC": "AU",
          "PctChecked": "0.36",
          "Count of Vrid": "81",
          "percent": 0.36
        },
        "BD": {
          "Survey CC": "BD",
          "PctChecked": "0.67",
          "Count of Vrid": "3",
          "percent": 0.67
        },
        "BE": {
          "Survey CC": "BE",
          "PctChecked": "0.13",
          "Count of Vrid": "54",
          "percent": 0.13
        },
        "BG": {
          "Survey CC": "BG",
          "PctChecked": "0.26",
          "Count of Vrid": "34",
          "percent": 0.26
        },
        "BH": {
          "Survey CC": "BH",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctChecked": "0.33",
          "Count of Vrid": "6",
          "percent": 0.33
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctChecked": "0",
          "Count of Vrid": "3",
          "percent": 0
        },
        "BO": {
          "Survey CC": "BO",
          "PctChecked": "0.4",
          "Count of Vrid": "5",
          "percent": 0.4
        },
        "BR": {
          "Survey CC": "BR",
          "PctChecked": "0.47",
          "Count of Vrid": "115",
          "percent": 0.47
        },
        "BS": {
          "Survey CC": "BS",
          "PctChecked": "0",
          "Count of Vrid": "",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "percent": 0.33
        },
        "CA": {
          "Survey CC": "CA",
          "PctChecked": "0.3",
          "Count of Vrid": "208",
          "percent": 0.3
        },
        "CD": {
          "Survey CC": "CD",
          "PctChecked": "0.82",
          "Count of Vrid": "17",
          "percent": 0.82
        },
        "CF": {
          "Survey CC": "CF",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctChecked": "1",
          "Count of Vrid": "4",
          "percent": 1
        },
        "CI": {
          "Survey CC": "CI",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "CL": {
          "Survey CC": "CL",
          "PctChecked": "0.15",
          "Count of Vrid": "40",
          "percent": 0.15
        },
        "CM": {
          "Survey CC": "CM",
          "PctChecked": "0.93",
          "Count of Vrid": "14",
          "percent": 0.93
        },
        "CN": {
          "Survey CC": "CN",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "CO": {
          "Survey CC": "CO",
          "PctChecked": "0.29",
          "Count of Vrid": "48",
          "percent": 0.29
        },
        "CR": {
          "Survey CC": "CR",
          "PctChecked": "0",
          "Count of Vrid": "2",
          "percent": 0
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctChecked": "0.12",
          "Count of Vrid": "73",
          "percent": 0.12
        },
        "DE": {
          "Survey CC": "DE",
          "PctChecked": "0.15",
          "Count of Vrid": "117",
          "percent": 0.15
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctChecked": "0",
          "Count of Vrid": "3",
          "percent": 0
        },
        "DO": {
          "Survey CC": "DO",
          "PctChecked": "0.8",
          "Count of Vrid": "5",
          "percent": 0.8
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "EC": {
          "Survey CC": "EC",
          "PctChecked": "0.38",
          "Count of Vrid": "8",
          "percent": 0.38
        },
        "EE": {
          "Survey CC": "EE",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctChecked": "0",
          "Count of Vrid": "3",
          "percent": 0
        },
        "ES": {
          "Survey CC": "ES",
          "PctChecked": "0.21",
          "Count of Vrid": "81",
          "percent": 0.21
        },
        "ET": {
          "Survey CC": "ET",
          "PctChecked": "0.5",
          "Count of Vrid": "4",
          "percent": 0.5
        },
        "FI": {
          "Survey CC": "FI",
          "PctChecked": "0",
          "Count of Vrid": "2",
          "percent": 0
        },
        "FR": {
          "Survey CC": "FR",
          "PctChecked": "0.33",
          "Count of Vrid": "9",
          "percent": 0.33
        },
        "GA": {
          "Survey CC": "GA",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctChecked": "0.25",
          "Count of Vrid": "59",
          "percent": 0.25
        },
        "GE": {
          "Survey CC": "GE",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctChecked": "0.5",
          "Count of Vrid": "32",
          "percent": 0.5
        },
        "GN": {
          "Survey CC": "GN",
          "PctChecked": "0.25",
          "Count of Vrid": "4",
          "percent": 0.25
        },
        "GR": {
          "Survey CC": "GR",
          "PctChecked": "0",
          "Count of Vrid": "4",
          "percent": 0
        },
        "GT": {
          "Survey CC": "GT",
          "PctChecked": "0.63",
          "Count of Vrid": "8",
          "percent": 0.63
        },
        "HK": {
          "Survey CC": "HK",
          "PctChecked": "0.13",
          "Count of Vrid": "8",
          "percent": 0.13
        },
        "HN": {
          "Survey CC": "HN",
          "PctChecked": "0.71",
          "Count of Vrid": "7",
          "percent": 0.71
        },
        "HR": {
          "Survey CC": "HR",
          "PctChecked": "0",
          "Count of Vrid": "",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "percent": 1
        },
        "HU": {
          "Survey CC": "HU",
          "PctChecked": "0.2",
          "Count of Vrid": "50",
          "percent": 0.2
        },
        "ID": {
          "Survey CC": "ID",
          "PctChecked": "0.39",
          "Count of Vrid": "180",
          "percent": 0.39
        },
        "IE": {
          "Survey CC": "IE",
          "PctChecked": "0.08",
          "Count of Vrid": "40",
          "percent": 0.08
        },
        "IN": {
          "Survey CC": "IN",
          "PctChecked": "0.57",
          "Count of Vrid": "93",
          "percent": 0.57
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "IT": {
          "Survey CC": "IT",
          "PctChecked": "0.21",
          "Count of Vrid": "47",
          "percent": 0.21
        },
        "JO": {
          "Survey CC": "JO",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "JP": {
          "Survey CC": "JP",
          "PctChecked": "0.09",
          "Count of Vrid": "212",
          "percent": 0.09
        },
        "KE": {
          "Survey CC": "KE",
          "PctChecked": "0.58",
          "Count of Vrid": "64",
          "percent": 0.58
        },
        "KG": {
          "Survey CC": "KG",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "KH": {
          "Survey CC": "KH",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "percent": 0.33
        },
        "KR": {
          "Survey CC": "KR",
          "PctChecked": "0",
          "Count of Vrid": "15",
          "percent": 0
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctChecked": "0.5",
          "Count of Vrid": "4",
          "percent": 0.5
        },
        "LB": {
          "Survey CC": "LB",
          "PctChecked": "0",
          "Count of Vrid": "",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctChecked": "0.57",
          "Count of Vrid": "7",
          "percent": 0.57
        },
        "LR": {
          "Survey CC": "LR",
          "PctChecked": "0.5",
          "Count of Vrid": "8",
          "percent": 0.5
        },
        "LT": {
          "Survey CC": "LT",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "LU": {
          "Survey CC": "LU",
          "PctChecked": "0",
          "Count of Vrid": "2",
          "percent": 0
        },
        "LV": {
          "Survey CC": "LV",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "LY": {
          "Survey CC": "LY",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctChecked": "0.2",
          "Count of Vrid": "25",
          "percent": 0.2
        },
        "ML": {
          "Survey CC": "ML",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctChecked": "0.75",
          "Count of Vrid": "4",
          "percent": 0.75
        },
        "MN": {
          "Survey CC": "MN",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "MT": {
          "Survey CC": "MT",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "MW": {
          "Survey CC": "MW",
          "PctChecked": "1",
          "Count of Vrid": "4",
          "percent": 1
        },
        "MX": {
          "Survey CC": "MX",
          "PctChecked": "0.38",
          "Count of Vrid": "125",
          "percent": 0.38
        },
        "MY": {
          "Survey CC": "MY",
          "PctChecked": "0.32",
          "Count of Vrid": "19",
          "percent": 0.32
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "NA": {
          "Survey CC": "NA",
          "PctChecked": "0",
          "Count of Vrid": "2",
          "percent": 0
        },
        "NE": {
          "Survey CC": "NE",
          "PctChecked": "1",
          "Count of Vrid": "2",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctChecked": "0.89",
          "Count of Vrid": "54",
          "percent": 0.89
        },
        "NI": {
          "Survey CC": "NI",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "percent": 0.33
        },
        "NL": {
          "Survey CC": "NL",
          "PctChecked": "0.26",
          "Count of Vrid": "19",
          "percent": 0.26
        },
        "NP": {
          "Survey CC": "NP",
          "PctChecked": "0.75",
          "Count of Vrid": "4",
          "percent": 0.75
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctChecked": "0.28",
          "Count of Vrid": "40",
          "percent": 0.28
        },
        "PA": {
          "Survey CC": "PA",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctChecked": "0.38",
          "Count of Vrid": "8",
          "percent": 0.38
        },
        "PH": {
          "Survey CC": "PH",
          "PctChecked": "0.49",
          "Count of Vrid": "45",
          "percent": 0.49
        },
        "PK": {
          "Survey CC": "PK",
          "PctChecked": "0.6",
          "Count of Vrid": "5",
          "percent": 0.6
        },
        "PL": {
          "Survey CC": "PL",
          "PctChecked": "0.22",
          "Count of Vrid": "81",
          "percent": 0.22
        },
        "PR": {
          "Survey CC": "PR",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "PS": {
          "Survey CC": "PS",
          "PctChecked": "0",
          "Count of Vrid": "",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctChecked": "0.26",
          "Count of Vrid": "124",
          "percent": 0.26
        },
        "PY": {
          "Survey CC": "PY",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "percent": 0.33
        },
        "RO": {
          "Survey CC": "RO",
          "PctChecked": "0.31",
          "Count of Vrid": "64",
          "percent": 0.31
        },
        "RS": {
          "Survey CC": "RS",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "RW": {
          "Survey CC": "RW",
          "PctChecked": "0",
          "Count of Vrid": "3",
          "percent": 0
        },
        "SA": {
          "Survey CC": "SA",
          "PctChecked": "0.35",
          "Count of Vrid": "17",
          "percent": 0.35
        },
        "SD": {
          "Survey CC": "SD",
          "PctChecked": "0",
          "Count of Vrid": "",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctChecked": "0.13",
          "Count of Vrid": "8",
          "percent": 0.13
        },
        "SG": {
          "Survey CC": "SG",
          "PctChecked": "0.33",
          "Count of Vrid": "6",
          "percent": 0.33
        },
        "SI": {
          "Survey CC": "SI",
          "PctChecked": "0",
          "Count of Vrid": "12",
          "percent": 0
        },
        "SK": {
          "Survey CC": "SK",
          "PctChecked": "0",
          "Count of Vrid": "",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctChecked": "0.77",
          "Count of Vrid": "13",
          "percent": 0.77
        },
        "SN": {
          "Survey CC": "SN",
          "PctChecked": "0.33",
          "Count of Vrid": "3",
          "percent": 0.33
        },
        "SO": {
          "Survey CC": "SO",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "SR": {
          "Survey CC": "SR",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "ST": {
          "Survey CC": "ST",
          "PctChecked": "0",
          "Count of Vrid": "1",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "percent": 1
        },
        "SY": {
          "Survey CC": "SY",
          "PctChecked": "1",
          "Count of Vrid": "2",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctChecked": "0.67",
          "Count of Vrid": "3",
          "percent": 0.67
        },
        "TD": {
          "Survey CC": "TD",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "TG": {
          "Survey CC": "TG",
          "PctChecked": "0.5",
          "Count of Vrid": "4",
          "percent": 0.5
        },
        "TH": {
          "Survey CC": "TH",
          "PctChecked": "0.75",
          "Count of Vrid": "12",
          "percent": 0.75
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctChecked": "0.17",
          "Count of Vrid": "6",
          "percent": 0.17
        },
        "TR": {
          "Survey CC": "TR",
          "PctChecked": "0.21",
          "Count of Vrid": "43",
          "percent": 0.21
        },
        "TT": {
          "Survey CC": "TT",
          "PctChecked": "0",
          "Count of Vrid": "",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctChecked": "0.22",
          "Count of Vrid": "94",
          "percent": 0.22
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctChecked": "0.86",
          "Count of Vrid": "21",
          "percent": 0.86
        },
        "UA": {
          "Survey CC": "UA",
          "PctChecked": "0.4",
          "Count of Vrid": "258",
          "percent": 0.4
        },
        "UG": {
          "Survey CC": "UG",
          "PctChecked": "0.59",
          "Count of Vrid": "27",
          "percent": 0.59
        },
        "US": {
          "Survey CC": "US",
          "PctChecked": "0.27",
          "Count of Vrid": "957",
          "percent": 0.27
        },
        "UY": {
          "Survey CC": "UY",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctChecked": "0.83",
          "Count of Vrid": "6",
          "percent": 0.83
        },
        "VI": {
          "Survey CC": "VI",
          "PctChecked": "0.5",
          "Count of Vrid": "2",
          "percent": 0.5
        },
        "VN": {
          "Survey CC": "VN",
          "PctChecked": "1",
          "Count of Vrid": "1",
          "percent": 1
        },
        "YE": {
          "Survey CC": "YE",
          "PctChecked": "1",
          "Count of Vrid": "3",
          "percent": 1
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctChecked": "0.85",
          "Count of Vrid": "92",
          "percent": 0.85
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctChecked": "0.5",
          "Count of Vrid": "8",
          "percent": 0.5
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctChecked": "1",
          "Count of Vrid": "5",
          "percent": 1
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctChecked": "0.23",
          "percent": 0.23
        },
        "South": {
          "GlobalRegion": "South",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctChecked": "0.33",
          "percent": 0.33
        }
      },
      "r": {
        "Europe": {
          "CH Region": "Europe",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "North America": {
          "CH Region": "North America",
          "PctChecked": "0.28",
          "percent": 0.28
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctChecked": "0.29",
          "percent": 0.29
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctChecked": "0.46",
          "percent": 0.46
        },
        "Africa": {
          "CH Region": "Africa",
          "PctChecked": "0.7",
          "percent": 0.7
        },
        "Total": {
          "CH Region": "Total",
          "PctChecked": "0.33",
          "percent": 0.33
        }
      }
    },
    "23.1": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctExpensiveAndCantAfford": "0.33",
          "percent": 0.33
        },
        "AF": {
          "Survey CC": "AF",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AL": {
          "Survey CC": "AL",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AO": {
          "Survey CC": "AO",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "AT": {
          "Survey CC": "AT",
          "PctExpensiveAndCantAfford": "0.78",
          "percent": 0.78
        },
        "AU": {
          "Survey CC": "AU",
          "PctExpensiveAndCantAfford": "0.66",
          "percent": 0.66
        },
        "BD": {
          "Survey CC": "BD",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "BE": {
          "Survey CC": "BE",
          "PctExpensiveAndCantAfford": "0.78",
          "percent": 0.78
        },
        "BG": {
          "Survey CC": "BG",
          "PctExpensiveAndCantAfford": "0.64",
          "percent": 0.64
        },
        "BH": {
          "Survey CC": "BH",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "BO": {
          "Survey CC": "BO",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "BR": {
          "Survey CC": "BR",
          "PctExpensiveAndCantAfford": "0.77",
          "percent": 0.77
        },
        "BS": {
          "Survey CC": "BS",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "CA": {
          "Survey CC": "CA",
          "PctExpensiveAndCantAfford": "0.66",
          "percent": 0.66
        },
        "CD": {
          "Survey CC": "CD",
          "PctExpensiveAndCantAfford": "0.88",
          "percent": 0.88
        },
        "CF": {
          "Survey CC": "CF",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctExpensiveAndCantAfford": "0.7",
          "percent": 0.7
        },
        "CI": {
          "Survey CC": "CI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctExpensiveAndCantAfford": "0.56",
          "percent": 0.56
        },
        "CM": {
          "Survey CC": "CM",
          "PctExpensiveAndCantAfford": "0.79",
          "percent": 0.79
        },
        "CN": {
          "Survey CC": "CN",
          "PctExpensiveAndCantAfford": "0.25",
          "percent": 0.25
        },
        "CO": {
          "Survey CC": "CO",
          "PctExpensiveAndCantAfford": "0.66",
          "percent": 0.66
        },
        "CR": {
          "Survey CC": "CR",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctExpensiveAndCantAfford": "0.58",
          "percent": 0.58
        },
        "DE": {
          "Survey CC": "DE",
          "PctExpensiveAndCantAfford": "0.74",
          "percent": 0.74
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctExpensiveAndCantAfford": "0.59",
          "percent": 0.59
        },
        "DO": {
          "Survey CC": "DO",
          "PctExpensiveAndCantAfford": "0.4",
          "percent": 0.4
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctExpensiveAndCantAfford": "0.33",
          "percent": 0.33
        },
        "EC": {
          "Survey CC": "EC",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "EE": {
          "Survey CC": "EE",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "ES": {
          "Survey CC": "ES",
          "PctExpensiveAndCantAfford": "0.74",
          "percent": 0.74
        },
        "ET": {
          "Survey CC": "ET",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "FI": {
          "Survey CC": "FI",
          "PctExpensiveAndCantAfford": "0.64",
          "percent": 0.64
        },
        "FR": {
          "Survey CC": "FR",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "GA": {
          "Survey CC": "GA",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "GB": {
          "Survey CC": "GB",
          "PctExpensiveAndCantAfford": "0.68",
          "percent": 0.68
        },
        "GE": {
          "Survey CC": "GE",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctExpensiveAndCantAfford": "0.78",
          "percent": 0.78
        },
        "GN": {
          "Survey CC": "GN",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "GR": {
          "Survey CC": "GR",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "GT": {
          "Survey CC": "GT",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "HK": {
          "Survey CC": "HK",
          "PctExpensiveAndCantAfford": "0.63",
          "percent": 0.63
        },
        "HN": {
          "Survey CC": "HN",
          "PctExpensiveAndCantAfford": "0.71",
          "percent": 0.71
        },
        "HR": {
          "Survey CC": "HR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "HU": {
          "Survey CC": "HU",
          "PctExpensiveAndCantAfford": "0.7",
          "percent": 0.7
        },
        "ID": {
          "Survey CC": "ID",
          "PctExpensiveAndCantAfford": "0.49",
          "percent": 0.49
        },
        "IE": {
          "Survey CC": "IE",
          "PctExpensiveAndCantAfford": "0.73",
          "percent": 0.73
        },
        "IN": {
          "Survey CC": "IN",
          "PctExpensiveAndCantAfford": "0.7",
          "percent": 0.7
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "IT": {
          "Survey CC": "IT",
          "PctExpensiveAndCantAfford": "0.56",
          "percent": 0.56
        },
        "JO": {
          "Survey CC": "JO",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "JP": {
          "Survey CC": "JP",
          "PctExpensiveAndCantAfford": "0.71",
          "percent": 0.71
        },
        "KE": {
          "Survey CC": "KE",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "KG": {
          "Survey CC": "KG",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "KH": {
          "Survey CC": "KH",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "KR": {
          "Survey CC": "KR",
          "PctExpensiveAndCantAfford": "0.72",
          "percent": 0.72
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "LB": {
          "Survey CC": "LB",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctExpensiveAndCantAfford": "0.88",
          "percent": 0.88
        },
        "LR": {
          "Survey CC": "LR",
          "PctExpensiveAndCantAfford": "0.78",
          "percent": 0.78
        },
        "LT": {
          "Survey CC": "LT",
          "PctExpensiveAndCantAfford": "0.4",
          "percent": 0.4
        },
        "LU": {
          "Survey CC": "LU",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "LV": {
          "Survey CC": "LV",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "LY": {
          "Survey CC": "LY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "ML": {
          "Survey CC": "ML",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "MM": {
          "Survey CC": "MM",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "MN": {
          "Survey CC": "MN",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "MW": {
          "Survey CC": "MW",
          "PctExpensiveAndCantAfford": "0.25",
          "percent": 0.25
        },
        "MX": {
          "Survey CC": "MX",
          "PctExpensiveAndCantAfford": "0.65",
          "percent": 0.65
        },
        "MY": {
          "Survey CC": "MY",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NE": {
          "Survey CC": "NE",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctExpensiveAndCantAfford": "0.69",
          "percent": 0.69
        },
        "NI": {
          "Survey CC": "NI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NL": {
          "Survey CC": "NL",
          "PctExpensiveAndCantAfford": "0.55",
          "percent": 0.55
        },
        "NP": {
          "Survey CC": "NP",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "PA": {
          "Survey CC": "PA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctExpensiveAndCantAfford": "0.63",
          "percent": 0.63
        },
        "PH": {
          "Survey CC": "PH",
          "PctExpensiveAndCantAfford": "0.74",
          "percent": 0.74
        },
        "PK": {
          "Survey CC": "PK",
          "PctExpensiveAndCantAfford": "0.58",
          "percent": 0.58
        },
        "PL": {
          "Survey CC": "PL",
          "PctExpensiveAndCantAfford": "0.76",
          "percent": 0.76
        },
        "PR": {
          "Survey CC": "PR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PS": {
          "Survey CC": "PS",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctExpensiveAndCantAfford": "0.69",
          "percent": 0.69
        },
        "PY": {
          "Survey CC": "PY",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "RO": {
          "Survey CC": "RO",
          "PctExpensiveAndCantAfford": "0.68",
          "percent": 0.68
        },
        "RS": {
          "Survey CC": "RS",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "RW": {
          "Survey CC": "RW",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SA": {
          "Survey CC": "SA",
          "PctExpensiveAndCantAfford": "0.48",
          "percent": 0.48
        },
        "SD": {
          "Survey CC": "SD",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SE": {
          "Survey CC": "SE",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "SG": {
          "Survey CC": "SG",
          "PctExpensiveAndCantAfford": "0.71",
          "percent": 0.71
        },
        "SI": {
          "Survey CC": "SI",
          "PctExpensiveAndCantAfford": "0.58",
          "percent": 0.58
        },
        "SK": {
          "Survey CC": "SK",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SN": {
          "Survey CC": "SN",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "SO": {
          "Survey CC": "SO",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "SR": {
          "Survey CC": "SR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "SY": {
          "Survey CC": "SY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctExpensiveAndCantAfford": "0.33",
          "percent": 0.33
        },
        "TD": {
          "Survey CC": "TD",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "TG": {
          "Survey CC": "TG",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "TH": {
          "Survey CC": "TH",
          "PctExpensiveAndCantAfford": "0.56",
          "percent": 0.56
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "TR": {
          "Survey CC": "TR",
          "PctExpensiveAndCantAfford": "0.76",
          "percent": 0.76
        },
        "TT": {
          "Survey CC": "TT",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctExpensiveAndCantAfford": "0.76",
          "percent": 0.76
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctExpensiveAndCantAfford": "0.71",
          "percent": 0.71
        },
        "UA": {
          "Survey CC": "UA",
          "PctExpensiveAndCantAfford": "0.52",
          "percent": 0.52
        },
        "UG": {
          "Survey CC": "UG",
          "PctExpensiveAndCantAfford": "0.7",
          "percent": 0.7
        },
        "US": {
          "Survey CC": "US",
          "PctExpensiveAndCantAfford": "0.62",
          "percent": 0.62
        },
        "UY": {
          "Survey CC": "UY",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "VE": {
          "Survey CC": "VE",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "VI": {
          "Survey CC": "VI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "VN": {
          "Survey CC": "VN",
          "PctExpensiveAndCantAfford": "0.4",
          "percent": 0.4
        },
        "YE": {
          "Survey CC": "YE",
          "PctExpensiveAndCantAfford": "0.4",
          "percent": 0.4
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctExpensiveAndCantAfford": "0.89",
          "percent": 0.89
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctExpensiveAndCantAfford": "0.33",
          "percent": 0.33
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "South": {
          "GlobalRegion": "South",
          "PctExpensiveAndCantAfford": "0.61",
          "percent": 0.61
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctExpensiveAndCantAfford": "0.65",
          "percent": 0.65
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctExpensiveAndCantAfford": "0.74",
          "percent": 0.74
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctExpensiveAndCantAfford": "0.64",
          "percent": 0.64
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctExpensiveAndCantAfford": "0.69",
          "percent": 0.69
        },
        "Europe": {
          "CH Region": "Europe",
          "PctExpensiveAndCantAfford": "0.65",
          "percent": 0.65
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctExpensiveAndCantAfford": "0.49",
          "percent": 0.49
        },
        "North America": {
          "CH Region": "North America",
          "PctExpensiveAndCantAfford": "0.63",
          "percent": 0.63
        },
        "Total": {
          "CH Region": "Total",
          "PctExpensiveAndCantAfford": "0.65",
          "percent": 0.65
        }
      }
    },
    "23.2": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AE": {
          "Survey CC": "AE",
          "PctExpensiveAndCantAfford": "0.33",
          "percent": 0.33
        },
        "AF": {
          "Survey CC": "AF",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "AL": {
          "Survey CC": "AL",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AO": {
          "Survey CC": "AO",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctExpensiveAndCantAfford": "0.77",
          "percent": 0.77
        },
        "AT": {
          "Survey CC": "AT",
          "PctExpensiveAndCantAfford": "0.78",
          "percent": 0.78
        },
        "AU": {
          "Survey CC": "AU",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "BD": {
          "Survey CC": "BD",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "BE": {
          "Survey CC": "BE",
          "PctExpensiveAndCantAfford": "0.64",
          "percent": 0.64
        },
        "BG": {
          "Survey CC": "BG",
          "PctExpensiveAndCantAfford": "0.73",
          "percent": 0.73
        },
        "BH": {
          "Survey CC": "BH",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "BO": {
          "Survey CC": "BO",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "BR": {
          "Survey CC": "BR",
          "PctExpensiveAndCantAfford": "0.78",
          "percent": 0.78
        },
        "BS": {
          "Survey CC": "BS",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CA": {
          "Survey CC": "CA",
          "PctExpensiveAndCantAfford": "0.58",
          "percent": 0.58
        },
        "CD": {
          "Survey CC": "CD",
          "PctExpensiveAndCantAfford": "0.71",
          "percent": 0.71
        },
        "CF": {
          "Survey CC": "CF",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctExpensiveAndCantAfford": "0.7",
          "percent": 0.7
        },
        "CI": {
          "Survey CC": "CI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctExpensiveAndCantAfford": "0.65",
          "percent": 0.65
        },
        "CM": {
          "Survey CC": "CM",
          "PctExpensiveAndCantAfford": "0.79",
          "percent": 0.79
        },
        "CN": {
          "Survey CC": "CN",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "CO": {
          "Survey CC": "CO",
          "PctExpensiveAndCantAfford": "0.74",
          "percent": 0.74
        },
        "CR": {
          "Survey CC": "CR",
          "PctExpensiveAndCantAfford": "0.25",
          "percent": 0.25
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctExpensiveAndCantAfford": "0.56",
          "percent": 0.56
        },
        "DE": {
          "Survey CC": "DE",
          "PctExpensiveAndCantAfford": "0.63",
          "percent": 0.63
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctExpensiveAndCantAfford": "0.53",
          "percent": 0.53
        },
        "DO": {
          "Survey CC": "DO",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "EC": {
          "Survey CC": "EC",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "EE": {
          "Survey CC": "EE",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "EG": {
          "Survey CC": "EG",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "ES": {
          "Survey CC": "ES",
          "PctExpensiveAndCantAfford": "0.69",
          "percent": 0.69
        },
        "ET": {
          "Survey CC": "ET",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "FI": {
          "Survey CC": "FI",
          "PctExpensiveAndCantAfford": "0.45",
          "percent": 0.45
        },
        "FR": {
          "Survey CC": "FR",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "GA": {
          "Survey CC": "GA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "GE": {
          "Survey CC": "GE",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        },
        "GN": {
          "Survey CC": "GN",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "GR": {
          "Survey CC": "GR",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "GT": {
          "Survey CC": "GT",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "HK": {
          "Survey CC": "HK",
          "PctExpensiveAndCantAfford": "0.53",
          "percent": 0.53
        },
        "HN": {
          "Survey CC": "HN",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "HR": {
          "Survey CC": "HR",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "HU": {
          "Survey CC": "HU",
          "PctExpensiveAndCantAfford": "0.73",
          "percent": 0.73
        },
        "ID": {
          "Survey CC": "ID",
          "PctExpensiveAndCantAfford": "0.57",
          "percent": 0.57
        },
        "IE": {
          "Survey CC": "IE",
          "PctExpensiveAndCantAfford": "0.61",
          "percent": 0.61
        },
        "IN": {
          "Survey CC": "IN",
          "PctExpensiveAndCantAfford": "0.71",
          "percent": 0.71
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "IT": {
          "Survey CC": "IT",
          "PctExpensiveAndCantAfford": "0.37",
          "percent": 0.37
        },
        "JO": {
          "Survey CC": "JO",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "KE": {
          "Survey CC": "KE",
          "PctExpensiveAndCantAfford": "0.91",
          "percent": 0.91
        },
        "KG": {
          "Survey CC": "KG",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "KH": {
          "Survey CC": "KH",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "KR": {
          "Survey CC": "KR",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "LB": {
          "Survey CC": "LB",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctExpensiveAndCantAfford": "0.63",
          "percent": 0.63
        },
        "LR": {
          "Survey CC": "LR",
          "PctExpensiveAndCantAfford": "0.89",
          "percent": 0.89
        },
        "LT": {
          "Survey CC": "LT",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "LU": {
          "Survey CC": "LU",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "LV": {
          "Survey CC": "LV",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "LY": {
          "Survey CC": "LY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctExpensiveAndCantAfford": "0.77",
          "percent": 0.77
        },
        "ML": {
          "Survey CC": "ML",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "MN": {
          "Survey CC": "MN",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "MW": {
          "Survey CC": "MW",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "MX": {
          "Survey CC": "MX",
          "PctExpensiveAndCantAfford": "0.73",
          "percent": 0.73
        },
        "MY": {
          "Survey CC": "MY",
          "PctExpensiveAndCantAfford": "0.79",
          "percent": 0.79
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "NA": {
          "Survey CC": "NA",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "NE": {
          "Survey CC": "NE",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "NG": {
          "Survey CC": "NG",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "NI": {
          "Survey CC": "NI",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "NL": {
          "Survey CC": "NL",
          "PctExpensiveAndCantAfford": "0.51",
          "percent": 0.51
        },
        "NP": {
          "Survey CC": "NP",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctExpensiveAndCantAfford": "0.55",
          "percent": 0.55
        },
        "PA": {
          "Survey CC": "PA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctExpensiveAndCantAfford": "0.63",
          "percent": 0.63
        },
        "PH": {
          "Survey CC": "PH",
          "PctExpensiveAndCantAfford": "0.76",
          "percent": 0.76
        },
        "PK": {
          "Survey CC": "PK",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "PL": {
          "Survey CC": "PL",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "PR": {
          "Survey CC": "PR",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "PS": {
          "Survey CC": "PS",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctExpensiveAndCantAfford": "0.71",
          "percent": 0.71
        },
        "PY": {
          "Survey CC": "PY",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "RO": {
          "Survey CC": "RO",
          "PctExpensiveAndCantAfford": "0.78",
          "percent": 0.78
        },
        "RS": {
          "Survey CC": "RS",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "RW": {
          "Survey CC": "RW",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "SA": {
          "Survey CC": "SA",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "SD": {
          "Survey CC": "SD",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "SG": {
          "Survey CC": "SG",
          "PctExpensiveAndCantAfford": "0.68",
          "percent": 0.68
        },
        "SI": {
          "Survey CC": "SI",
          "PctExpensiveAndCantAfford": "0.7",
          "percent": 0.7
        },
        "SK": {
          "Survey CC": "SK",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctExpensiveAndCantAfford": "0.85",
          "percent": 0.85
        },
        "SN": {
          "Survey CC": "SN",
          "PctExpensiveAndCantAfford": "0.33",
          "percent": 0.33
        },
        "SO": {
          "Survey CC": "SO",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "SR": {
          "Survey CC": "SR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "SY": {
          "Survey CC": "SY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "TD": {
          "Survey CC": "TD",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "TG": {
          "Survey CC": "TG",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "TH": {
          "Survey CC": "TH",
          "PctExpensiveAndCantAfford": "0.61",
          "percent": 0.61
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "TR": {
          "Survey CC": "TR",
          "PctExpensiveAndCantAfford": "0.85",
          "percent": 0.85
        },
        "TT": {
          "Survey CC": "TT",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctExpensiveAndCantAfford": "0.66",
          "percent": 0.66
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "UA": {
          "Survey CC": "UA",
          "PctExpensiveAndCantAfford": "0.73",
          "percent": 0.73
        },
        "UG": {
          "Survey CC": "UG",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "US": {
          "Survey CC": "US",
          "PctExpensiveAndCantAfford": "0.55",
          "percent": 0.55
        },
        "UY": {
          "Survey CC": "UY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "VI": {
          "Survey CC": "VI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "VN": {
          "Survey CC": "VN",
          "PctExpensiveAndCantAfford": "0.4",
          "percent": 0.4
        },
        "YE": {
          "Survey CC": "YE",
          "PctExpensiveAndCantAfford": "0.4",
          "percent": 0.4
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctExpensiveAndCantAfford": "0.71",
          "percent": 0.71
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctExpensiveAndCantAfford": "0.62",
          "percent": 0.62
        },
        "South": {
          "GlobalRegion": "South",
          "PctExpensiveAndCantAfford": "0.69",
          "percent": 0.69
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctExpensiveAndCantAfford": "0.64",
          "percent": 0.64
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctExpensiveAndCantAfford": "0.78",
          "percent": 0.78
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctExpensiveAndCantAfford": "0.64",
          "percent": 0.64
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctExpensiveAndCantAfford": "0.63",
          "percent": 0.63
        },
        "Europe": {
          "CH Region": "Europe",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctExpensiveAndCantAfford": "0.73",
          "percent": 0.73
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctExpensiveAndCantAfford": "0.51",
          "percent": 0.51
        },
        "North America": {
          "CH Region": "North America",
          "PctExpensiveAndCantAfford": "0.55",
          "percent": 0.55
        },
        "Total": {
          "CH Region": "Total",
          "PctExpensiveAndCantAfford": "0.64",
          "percent": 0.64
        }
      }
    },
    "23.3": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctExpensiveAndCantAfford": "0.33",
          "percent": 0.33
        },
        "AF": {
          "Survey CC": "AF",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "AL": {
          "Survey CC": "AL",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "AO": {
          "Survey CC": "AO",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AR": {
          "Survey CC": "AR",
          "PctExpensiveAndCantAfford": "0.86",
          "percent": 0.86
        },
        "AT": {
          "Survey CC": "AT",
          "PctExpensiveAndCantAfford": "0.78",
          "percent": 0.78
        },
        "AU": {
          "Survey CC": "AU",
          "PctExpensiveAndCantAfford": "0.52",
          "percent": 0.52
        },
        "BD": {
          "Survey CC": "BD",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "BE": {
          "Survey CC": "BE",
          "PctExpensiveAndCantAfford": "0.72",
          "percent": 0.72
        },
        "BG": {
          "Survey CC": "BG",
          "PctExpensiveAndCantAfford": "0.63",
          "percent": 0.63
        },
        "BH": {
          "Survey CC": "BH",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "BO": {
          "Survey CC": "BO",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "BR": {
          "Survey CC": "BR",
          "PctExpensiveAndCantAfford": "0.9",
          "percent": 0.9
        },
        "BS": {
          "Survey CC": "BS",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CA": {
          "Survey CC": "CA",
          "PctExpensiveAndCantAfford": "0.62",
          "percent": 0.62
        },
        "CD": {
          "Survey CC": "CD",
          "PctExpensiveAndCantAfford": "0.71",
          "percent": 0.71
        },
        "CF": {
          "Survey CC": "CF",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "CI": {
          "Survey CC": "CI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctExpensiveAndCantAfford": "0.78",
          "percent": 0.78
        },
        "CM": {
          "Survey CC": "CM",
          "PctExpensiveAndCantAfford": "0.79",
          "percent": 0.79
        },
        "CN": {
          "Survey CC": "CN",
          "PctExpensiveAndCantAfford": "0.25",
          "percent": 0.25
        },
        "CO": {
          "Survey CC": "CO",
          "PctExpensiveAndCantAfford": "0.78",
          "percent": 0.78
        },
        "CR": {
          "Survey CC": "CR",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "DE": {
          "Survey CC": "DE",
          "PctExpensiveAndCantAfford": "0.47",
          "percent": 0.47
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctExpensiveAndCantAfford": "0.47",
          "percent": 0.47
        },
        "DO": {
          "Survey CC": "DO",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctExpensiveAndCantAfford": "0.33",
          "percent": 0.33
        },
        "EC": {
          "Survey CC": "EC",
          "PctExpensiveAndCantAfford": "0.7",
          "percent": 0.7
        },
        "EE": {
          "Survey CC": "EE",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "ES": {
          "Survey CC": "ES",
          "PctExpensiveAndCantAfford": "0.66",
          "percent": 0.66
        },
        "ET": {
          "Survey CC": "ET",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "FI": {
          "Survey CC": "FI",
          "PctExpensiveAndCantAfford": "0.64",
          "percent": 0.64
        },
        "FR": {
          "Survey CC": "FR",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "GA": {
          "Survey CC": "GA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctExpensiveAndCantAfford": "0.57",
          "percent": 0.57
        },
        "GE": {
          "Survey CC": "GE",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "GH": {
          "Survey CC": "GH",
          "PctExpensiveAndCantAfford": "0.89",
          "percent": 0.89
        },
        "GN": {
          "Survey CC": "GN",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "GR": {
          "Survey CC": "GR",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "GT": {
          "Survey CC": "GT",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "HK": {
          "Survey CC": "HK",
          "PctExpensiveAndCantAfford": "0.53",
          "percent": 0.53
        },
        "HN": {
          "Survey CC": "HN",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "HR": {
          "Survey CC": "HR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "HU": {
          "Survey CC": "HU",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        },
        "ID": {
          "Survey CC": "ID",
          "PctExpensiveAndCantAfford": "0.56",
          "percent": 0.56
        },
        "IE": {
          "Survey CC": "IE",
          "PctExpensiveAndCantAfford": "0.62",
          "percent": 0.62
        },
        "IN": {
          "Survey CC": "IN",
          "PctExpensiveAndCantAfford": "0.62",
          "percent": 0.62
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "IT": {
          "Survey CC": "IT",
          "PctExpensiveAndCantAfford": "0.42",
          "percent": 0.42
        },
        "JO": {
          "Survey CC": "JO",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "JP": {
          "Survey CC": "JP",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "KE": {
          "Survey CC": "KE",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "KG": {
          "Survey CC": "KG",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "KH": {
          "Survey CC": "KH",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "KR": {
          "Survey CC": "KR",
          "PctExpensiveAndCantAfford": "0.45",
          "percent": 0.45
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "LB": {
          "Survey CC": "LB",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "LR": {
          "Survey CC": "LR",
          "PctExpensiveAndCantAfford": "0.89",
          "percent": 0.89
        },
        "LT": {
          "Survey CC": "LT",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "LU": {
          "Survey CC": "LU",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "LV": {
          "Survey CC": "LV",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "LY": {
          "Survey CC": "LY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctExpensiveAndCantAfford": "0.51",
          "percent": 0.51
        },
        "ML": {
          "Survey CC": "ML",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MN": {
          "Survey CC": "MN",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MW": {
          "Survey CC": "MW",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MX": {
          "Survey CC": "MX",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        },
        "MY": {
          "Survey CC": "MY",
          "PctExpensiveAndCantAfford": "0.61",
          "percent": 0.61
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NE": {
          "Survey CC": "NE",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctExpensiveAndCantAfford": "0.94",
          "percent": 0.94
        },
        "NI": {
          "Survey CC": "NI",
          "PctExpensiveAndCantAfford": "0.33",
          "percent": 0.33
        },
        "NL": {
          "Survey CC": "NL",
          "PctExpensiveAndCantAfford": "0.43",
          "percent": 0.43
        },
        "NP": {
          "Survey CC": "NP",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "PA": {
          "Survey CC": "PA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "PH": {
          "Survey CC": "PH",
          "PctExpensiveAndCantAfford": "0.76",
          "percent": 0.76
        },
        "PK": {
          "Survey CC": "PK",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "PL": {
          "Survey CC": "PL",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "PR": {
          "Survey CC": "PR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PS": {
          "Survey CC": "PS",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctExpensiveAndCantAfford": "0.68",
          "percent": 0.68
        },
        "PY": {
          "Survey CC": "PY",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "RO": {
          "Survey CC": "RO",
          "PctExpensiveAndCantAfford": "0.68",
          "percent": 0.68
        },
        "RS": {
          "Survey CC": "RS",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "RW": {
          "Survey CC": "RW",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SA": {
          "Survey CC": "SA",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "SD": {
          "Survey CC": "SD",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctExpensiveAndCantAfford": "0.38",
          "percent": 0.38
        },
        "SG": {
          "Survey CC": "SG",
          "PctExpensiveAndCantAfford": "0.52",
          "percent": 0.52
        },
        "SI": {
          "Survey CC": "SI",
          "PctExpensiveAndCantAfford": "0.7",
          "percent": 0.7
        },
        "SK": {
          "Survey CC": "SK",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctExpensiveAndCantAfford": "0.92",
          "percent": 0.92
        },
        "SN": {
          "Survey CC": "SN",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "SO": {
          "Survey CC": "SO",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "SR": {
          "Survey CC": "SR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "SY": {
          "Survey CC": "SY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "TD": {
          "Survey CC": "TD",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "TG": {
          "Survey CC": "TG",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "TH": {
          "Survey CC": "TH",
          "PctExpensiveAndCantAfford": "0.61",
          "percent": 0.61
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "TR": {
          "Survey CC": "TR",
          "PctExpensiveAndCantAfford": "0.91",
          "percent": 0.91
        },
        "TT": {
          "Survey CC": "TT",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "TW": {
          "Survey CC": "TW",
          "PctExpensiveAndCantAfford": "0.54",
          "percent": 0.54
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctExpensiveAndCantAfford": "0.92",
          "percent": 0.92
        },
        "UA": {
          "Survey CC": "UA",
          "PctExpensiveAndCantAfford": "0.68",
          "percent": 0.68
        },
        "UG": {
          "Survey CC": "UG",
          "PctExpensiveAndCantAfford": "0.93",
          "percent": 0.93
        },
        "US": {
          "Survey CC": "US",
          "PctExpensiveAndCantAfford": "0.57",
          "percent": 0.57
        },
        "UY": {
          "Survey CC": "UY",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "VE": {
          "Survey CC": "VE",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "VI": {
          "Survey CC": "VI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "VN": {
          "Survey CC": "VN",
          "PctExpensiveAndCantAfford": "0.2",
          "percent": 0.2
        },
        "YE": {
          "Survey CC": "YE",
          "PctExpensiveAndCantAfford": "0.4",
          "percent": 0.4
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctExpensiveAndCantAfford": "0.86",
          "percent": 0.86
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctExpensiveAndCantAfford": "0.89",
          "percent": 0.89
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "South": {
          "GlobalRegion": "South",
          "PctExpensiveAndCantAfford": "0.71",
          "percent": 0.71
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctExpensiveAndCantAfford": "0.64",
          "percent": 0.64
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctExpensiveAndCantAfford": "0.87",
          "percent": 0.87
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctExpensiveAndCantAfford": "0.58",
          "percent": 0.58
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctExpensiveAndCantAfford": "0.69",
          "percent": 0.69
        },
        "Europe": {
          "CH Region": "Europe",
          "PctExpensiveAndCantAfford": "0.64",
          "percent": 0.64
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctExpensiveAndCantAfford": "0.64",
          "percent": 0.64
        },
        "North America": {
          "CH Region": "North America",
          "PctExpensiveAndCantAfford": "0.58",
          "percent": 0.58
        },
        "Total": {
          "CH Region": "Total",
          "PctExpensiveAndCantAfford": "0.64",
          "percent": 0.64
        }
      }
    },
    "23.4": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "AF": {
          "Survey CC": "AF",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "AL": {
          "Survey CC": "AL",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "AO": {
          "Survey CC": "AO",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "AR": {
          "Survey CC": "AR",
          "PctExpAndCantAffordAny": "0.36",
          "percent": 0.36
        },
        "AT": {
          "Survey CC": "AT",
          "PctExpAndCantAffordAny": "0.11",
          "percent": 0.11
        },
        "AU": {
          "Survey CC": "AU",
          "PctExpAndCantAffordAny": "0.11",
          "percent": 0.11
        },
        "BD": {
          "Survey CC": "BD",
          "PctExpAndCantAffordAny": "0.2",
          "percent": 0.2
        },
        "BE": {
          "Survey CC": "BE",
          "PctExpAndCantAffordAny": "0.24",
          "percent": 0.24
        },
        "BG": {
          "Survey CC": "BG",
          "PctExpAndCantAffordAny": "0.12",
          "percent": 0.12
        },
        "BH": {
          "Survey CC": "BH",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "BO": {
          "Survey CC": "BO",
          "PctExpAndCantAffordAny": "0.33",
          "percent": 0.33
        },
        "BR": {
          "Survey CC": "BR",
          "PctExpAndCantAffordAny": "0.36",
          "percent": 0.36
        },
        "BS": {
          "Survey CC": "BS",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctExpAndCantAffordAny": "0.33",
          "percent": 0.33
        },
        "CA": {
          "Survey CC": "CA",
          "PctExpAndCantAffordAny": "0.25",
          "percent": 0.25
        },
        "CD": {
          "Survey CC": "CD",
          "PctExpAndCantAffordAny": "0.29",
          "percent": 0.29
        },
        "CF": {
          "Survey CC": "CF",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctExpAndCantAffordAny": "0.2",
          "percent": 0.2
        },
        "CI": {
          "Survey CC": "CI",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "CL": {
          "Survey CC": "CL",
          "PctExpAndCantAffordAny": "0.2",
          "percent": 0.2
        },
        "CM": {
          "Survey CC": "CM",
          "PctExpAndCantAffordAny": "0.29",
          "percent": 0.29
        },
        "CN": {
          "Survey CC": "CN",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "CO": {
          "Survey CC": "CO",
          "PctExpAndCantAffordAny": "0.36",
          "percent": 0.36
        },
        "CR": {
          "Survey CC": "CR",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctExpAndCantAffordAny": "0.09",
          "percent": 0.09
        },
        "DE": {
          "Survey CC": "DE",
          "PctExpAndCantAffordAny": "0.14",
          "percent": 0.14
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "DO": {
          "Survey CC": "DO",
          "PctExpAndCantAffordAny": "0.2",
          "percent": 0.2
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "EC": {
          "Survey CC": "EC",
          "PctExpAndCantAffordAny": "0.2",
          "percent": 0.2
        },
        "EE": {
          "Survey CC": "EE",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "EG": {
          "Survey CC": "EG",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "ES": {
          "Survey CC": "ES",
          "PctExpAndCantAffordAny": "0.13",
          "percent": 0.13
        },
        "ET": {
          "Survey CC": "ET",
          "PctExpAndCantAffordAny": "0.25",
          "percent": 0.25
        },
        "FI": {
          "Survey CC": "FI",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "FR": {
          "Survey CC": "FR",
          "PctExpAndCantAffordAny": "0.25",
          "percent": 0.25
        },
        "GA": {
          "Survey CC": "GA",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "GB": {
          "Survey CC": "GB",
          "PctExpAndCantAffordAny": "0.14",
          "percent": 0.14
        },
        "GE": {
          "Survey CC": "GE",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctExpAndCantAffordAny": "0.42",
          "percent": 0.42
        },
        "GN": {
          "Survey CC": "GN",
          "PctExpAndCantAffordAny": "0.25",
          "percent": 0.25
        },
        "GR": {
          "Survey CC": "GR",
          "PctExpAndCantAffordAny": "0.17",
          "percent": 0.17
        },
        "GT": {
          "Survey CC": "GT",
          "PctExpAndCantAffordAny": "0.1",
          "percent": 0.1
        },
        "HK": {
          "Survey CC": "HK",
          "PctExpAndCantAffordAny": "0.16",
          "percent": 0.16
        },
        "HN": {
          "Survey CC": "HN",
          "PctExpAndCantAffordAny": "0.43",
          "percent": 0.43
        },
        "HR": {
          "Survey CC": "HR",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "HU": {
          "Survey CC": "HU",
          "PctExpAndCantAffordAny": "0.18",
          "percent": 0.18
        },
        "ID": {
          "Survey CC": "ID",
          "PctExpAndCantAffordAny": "0.11",
          "percent": 0.11
        },
        "IE": {
          "Survey CC": "IE",
          "PctExpAndCantAffordAny": "0.14",
          "percent": 0.14
        },
        "IN": {
          "Survey CC": "IN",
          "PctExpAndCantAffordAny": "0.2",
          "percent": 0.2
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "IT": {
          "Survey CC": "IT",
          "PctExpAndCantAffordAny": "0.06",
          "percent": 0.06
        },
        "JO": {
          "Survey CC": "JO",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctExpAndCantAffordAny": "0.2",
          "percent": 0.2
        },
        "KE": {
          "Survey CC": "KE",
          "PctExpAndCantAffordAny": "0.38",
          "percent": 0.38
        },
        "KG": {
          "Survey CC": "KG",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "KH": {
          "Survey CC": "KH",
          "PctExpAndCantAffordAny": "0.17",
          "percent": 0.17
        },
        "KR": {
          "Survey CC": "KR",
          "PctExpAndCantAffordAny": "0.05",
          "percent": 0.05
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "LB": {
          "Survey CC": "LB",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctExpAndCantAffordAny": "0.38",
          "percent": 0.38
        },
        "LR": {
          "Survey CC": "LR",
          "PctExpAndCantAffordAny": "0.22",
          "percent": 0.22
        },
        "LT": {
          "Survey CC": "LT",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "LU": {
          "Survey CC": "LU",
          "PctExpAndCantAffordAny": "0.33",
          "percent": 0.33
        },
        "LV": {
          "Survey CC": "LV",
          "PctExpAndCantAffordAny": "0.25",
          "percent": 0.25
        },
        "LY": {
          "Survey CC": "LY",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "MD": {
          "Survey CC": "MD",
          "PctExpAndCantAffordAny": "0.1",
          "percent": 0.1
        },
        "ML": {
          "Survey CC": "ML",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "MM": {
          "Survey CC": "MM",
          "PctExpAndCantAffordAny": "0.33",
          "percent": 0.33
        },
        "MN": {
          "Survey CC": "MN",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "MW": {
          "Survey CC": "MW",
          "PctExpAndCantAffordAny": "0.25",
          "percent": 0.25
        },
        "MX": {
          "Survey CC": "MX",
          "PctExpAndCantAffordAny": "0.21",
          "percent": 0.21
        },
        "MY": {
          "Survey CC": "MY",
          "PctExpAndCantAffordAny": "0.15",
          "percent": 0.15
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "NA": {
          "Survey CC": "NA",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "NE": {
          "Survey CC": "NE",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "NG": {
          "Survey CC": "NG",
          "PctExpAndCantAffordAny": "0.46",
          "percent": 0.46
        },
        "NI": {
          "Survey CC": "NI",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "NL": {
          "Survey CC": "NL",
          "PctExpAndCantAffordAny": "0.08",
          "percent": 0.08
        },
        "NP": {
          "Survey CC": "NP",
          "PctExpAndCantAffordAny": "0.6",
          "percent": 0.6
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctExpAndCantAffordAny": "0.18",
          "percent": 0.18
        },
        "PA": {
          "Survey CC": "PA",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "PE": {
          "Survey CC": "PE",
          "PctExpAndCantAffordAny": "0.13",
          "percent": 0.13
        },
        "PH": {
          "Survey CC": "PH",
          "PctExpAndCantAffordAny": "0.32",
          "percent": 0.32
        },
        "PK": {
          "Survey CC": "PK",
          "PctExpAndCantAffordAny": "0.33",
          "percent": 0.33
        },
        "PL": {
          "Survey CC": "PL",
          "PctExpAndCantAffordAny": "0.3",
          "percent": 0.3
        },
        "PR": {
          "Survey CC": "PR",
          "PctExpAndCantAffordAny": "0.33",
          "percent": 0.33
        },
        "PS": {
          "Survey CC": "PS",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctExpAndCantAffordAny": "0.18",
          "percent": 0.18
        },
        "PY": {
          "Survey CC": "PY",
          "PctExpAndCantAffordAny": "0.67",
          "percent": 0.67
        },
        "RO": {
          "Survey CC": "RO",
          "PctExpAndCantAffordAny": "0.13",
          "percent": 0.13
        },
        "RS": {
          "Survey CC": "RS",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "RW": {
          "Survey CC": "RW",
          "PctExpAndCantAffordAny": "0.25",
          "percent": 0.25
        },
        "SA": {
          "Survey CC": "SA",
          "PctExpAndCantAffordAny": "0.14",
          "percent": 0.14
        },
        "SD": {
          "Survey CC": "SD",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctExpAndCantAffordAny": "0.03",
          "percent": 0.03
        },
        "SG": {
          "Survey CC": "SG",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "SI": {
          "Survey CC": "SI",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "SK": {
          "Survey CC": "SK",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctExpAndCantAffordAny": "0.46",
          "percent": 0.46
        },
        "SN": {
          "Survey CC": "SN",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "SO": {
          "Survey CC": "SO",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "SR": {
          "Survey CC": "SR",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "SY": {
          "Survey CC": "SY",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctExpAndCantAffordAny": "0.33",
          "percent": 0.33
        },
        "TD": {
          "Survey CC": "TD",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "TG": {
          "Survey CC": "TG",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "TH": {
          "Survey CC": "TH",
          "PctExpAndCantAffordAny": "0.17",
          "percent": 0.17
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "TR": {
          "Survey CC": "TR",
          "PctExpAndCantAffordAny": "0.46",
          "percent": 0.46
        },
        "TT": {
          "Survey CC": "TT",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctExpAndCantAffordAny": "0.16",
          "percent": 0.16
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctExpAndCantAffordAny": "0.38",
          "percent": 0.38
        },
        "UA": {
          "Survey CC": "UA",
          "PctExpAndCantAffordAny": "0.08",
          "percent": 0.08
        },
        "UG": {
          "Survey CC": "UG",
          "PctExpAndCantAffordAny": "0.4",
          "percent": 0.4
        },
        "US": {
          "Survey CC": "US",
          "PctExpAndCantAffordAny": "0.2",
          "percent": 0.2
        },
        "UY": {
          "Survey CC": "UY",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "VE": {
          "Survey CC": "VE",
          "PctExpAndCantAffordAny": "0.33",
          "percent": 0.33
        },
        "VI": {
          "Survey CC": "VI",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "VN": {
          "Survey CC": "VN",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "YE": {
          "Survey CC": "YE",
          "PctExpAndCantAffordAny": "0.2",
          "percent": 0.2
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctExpAndCantAffordAny": "0.34",
          "percent": 0.34
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctExpAndCantAffordAny": "0.44",
          "percent": 0.44
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctExpAndCantAffordAny": "0.18",
          "percent": 0.18
        },
        "South": {
          "GlobalRegion": "South",
          "PctExpAndCantAffordAny": "0.21",
          "percent": 0.21
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctExpAndCantAffordAny": "0.19",
          "percent": 0.19
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctExpAndCantAffordAny": "0.37",
          "percent": 0.37
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctExpAndCantAffordAny": "0.16",
          "percent": 0.16
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctExpAndCantAffordAny": "0.13",
          "percent": 0.13
        },
        "Europe": {
          "CH Region": "Europe",
          "PctExpAndCantAffordAny": "0.14",
          "percent": 0.14
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctExpAndCantAffordAny": "0.28",
          "percent": 0.28
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctExpAndCantAffordAny": "0.13",
          "percent": 0.13
        },
        "North America": {
          "CH Region": "North America",
          "PctExpAndCantAffordAny": "0.21",
          "percent": 0.21
        },
        "Total": {
          "CH Region": "Total",
          "PctExpAndCantAffordAny": "0.19",
          "percent": 0.19
        }
      }
    },
    "24.1": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AE": {
          "Survey CC": "AE",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "AF": {
          "Survey CC": "AF",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AL": {
          "Survey CC": "AL",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AO": {
          "Survey CC": "AO",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AR": {
          "Survey CC": "AR",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "AT": {
          "Survey CC": "AT",
          "PctExpensiveAndCantAfford": "0.89",
          "percent": 0.89
        },
        "AU": {
          "Survey CC": "AU",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "BD": {
          "Survey CC": "BD",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "BE": {
          "Survey CC": "BE",
          "PctExpensiveAndCantAfford": "0.85",
          "percent": 0.85
        },
        "BG": {
          "Survey CC": "BG",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        },
        "BH": {
          "Survey CC": "BH",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "BO": {
          "Survey CC": "BO",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "BR": {
          "Survey CC": "BR",
          "PctExpensiveAndCantAfford": "0.91",
          "percent": 0.91
        },
        "BS": {
          "Survey CC": "BS",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CA": {
          "Survey CC": "CA",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        },
        "CD": {
          "Survey CC": "CD",
          "PctExpensiveAndCantAfford": "0.76",
          "percent": 0.76
        },
        "CF": {
          "Survey CC": "CF",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctExpensiveAndCantAfford": "0.9",
          "percent": 0.9
        },
        "CI": {
          "Survey CC": "CI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctExpensiveAndCantAfford": "0.85",
          "percent": 0.85
        },
        "CM": {
          "Survey CC": "CM",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CN": {
          "Survey CC": "CN",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "CO": {
          "Survey CC": "CO",
          "PctExpensiveAndCantAfford": "0.86",
          "percent": 0.86
        },
        "CR": {
          "Survey CC": "CR",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "DE": {
          "Survey CC": "DE",
          "PctExpensiveAndCantAfford": "0.85",
          "percent": 0.85
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctExpensiveAndCantAfford": "0.59",
          "percent": 0.59
        },
        "DO": {
          "Survey CC": "DO",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctExpensiveAndCantAfford": "0.33",
          "percent": 0.33
        },
        "EC": {
          "Survey CC": "EC",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "EE": {
          "Survey CC": "EE",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "ES": {
          "Survey CC": "ES",
          "PctExpensiveAndCantAfford": "0.93",
          "percent": 0.93
        },
        "ET": {
          "Survey CC": "ET",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "FI": {
          "Survey CC": "FI",
          "PctExpensiveAndCantAfford": "0.55",
          "percent": 0.55
        },
        "FR": {
          "Survey CC": "FR",
          "PctExpensiveAndCantAfford": "0.92",
          "percent": 0.92
        },
        "GA": {
          "Survey CC": "GA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctExpensiveAndCantAfford": "0.91",
          "percent": 0.91
        },
        "GE": {
          "Survey CC": "GE",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctExpensiveAndCantAfford": "0.86",
          "percent": 0.86
        },
        "GN": {
          "Survey CC": "GN",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "GR": {
          "Survey CC": "GR",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "GT": {
          "Survey CC": "GT",
          "PctExpensiveAndCantAfford": "0.7",
          "percent": 0.7
        },
        "HK": {
          "Survey CC": "HK",
          "PctExpensiveAndCantAfford": "0.74",
          "percent": 0.74
        },
        "HN": {
          "Survey CC": "HN",
          "PctExpensiveAndCantAfford": "0.86",
          "percent": 0.86
        },
        "HR": {
          "Survey CC": "HR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "HU": {
          "Survey CC": "HU",
          "PctExpensiveAndCantAfford": "0.85",
          "percent": 0.85
        },
        "ID": {
          "Survey CC": "ID",
          "PctExpensiveAndCantAfford": "0.55",
          "percent": 0.55
        },
        "IE": {
          "Survey CC": "IE",
          "PctExpensiveAndCantAfford": "0.9",
          "percent": 0.9
        },
        "IN": {
          "Survey CC": "IN",
          "PctExpensiveAndCantAfford": "0.77",
          "percent": 0.77
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "IT": {
          "Survey CC": "IT",
          "PctExpensiveAndCantAfford": "0.69",
          "percent": 0.69
        },
        "JO": {
          "Survey CC": "JO",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctExpensiveAndCantAfford": "0.78",
          "percent": 0.78
        },
        "KE": {
          "Survey CC": "KE",
          "PctExpensiveAndCantAfford": "0.91",
          "percent": 0.91
        },
        "KG": {
          "Survey CC": "KG",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "KH": {
          "Survey CC": "KH",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "KR": {
          "Survey CC": "KR",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "LB": {
          "Survey CC": "LB",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "LR": {
          "Survey CC": "LR",
          "PctExpensiveAndCantAfford": "0.89",
          "percent": 0.89
        },
        "LT": {
          "Survey CC": "LT",
          "PctExpensiveAndCantAfford": "0.2",
          "percent": 0.2
        },
        "LU": {
          "Survey CC": "LU",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "LV": {
          "Survey CC": "LV",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "LY": {
          "Survey CC": "LY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctExpensiveAndCantAfford": "0.85",
          "percent": 0.85
        },
        "ML": {
          "Survey CC": "ML",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "MM": {
          "Survey CC": "MM",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "MN": {
          "Survey CC": "MN",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "MW": {
          "Survey CC": "MW",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "MX": {
          "Survey CC": "MX",
          "PctExpensiveAndCantAfford": "0.93",
          "percent": 0.93
        },
        "MY": {
          "Survey CC": "MY",
          "PctExpensiveAndCantAfford": "0.85",
          "percent": 0.85
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NE": {
          "Survey CC": "NE",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctExpensiveAndCantAfford": "0.89",
          "percent": 0.89
        },
        "NI": {
          "Survey CC": "NI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NL": {
          "Survey CC": "NL",
          "PctExpensiveAndCantAfford": "0.71",
          "percent": 0.71
        },
        "NP": {
          "Survey CC": "NP",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctExpensiveAndCantAfford": "0.77",
          "percent": 0.77
        },
        "PA": {
          "Survey CC": "PA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "PH": {
          "Survey CC": "PH",
          "PctExpensiveAndCantAfford": "0.84",
          "percent": 0.84
        },
        "PK": {
          "Survey CC": "PK",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "PL": {
          "Survey CC": "PL",
          "PctExpensiveAndCantAfford": "0.86",
          "percent": 0.86
        },
        "PR": {
          "Survey CC": "PR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PS": {
          "Survey CC": "PS",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctExpensiveAndCantAfford": "0.91",
          "percent": 0.91
        },
        "PY": {
          "Survey CC": "PY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "RO": {
          "Survey CC": "RO",
          "PctExpensiveAndCantAfford": "0.79",
          "percent": 0.79
        },
        "RS": {
          "Survey CC": "RS",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "RW": {
          "Survey CC": "RW",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "SA": {
          "Survey CC": "SA",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "SD": {
          "Survey CC": "SD",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SE": {
          "Survey CC": "SE",
          "PctExpensiveAndCantAfford": "0.56",
          "percent": 0.56
        },
        "SG": {
          "Survey CC": "SG",
          "PctExpensiveAndCantAfford": "0.68",
          "percent": 0.68
        },
        "SI": {
          "Survey CC": "SI",
          "PctExpensiveAndCantAfford": "0.7",
          "percent": 0.7
        },
        "SK": {
          "Survey CC": "SK",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctExpensiveAndCantAfford": "0.92",
          "percent": 0.92
        },
        "SN": {
          "Survey CC": "SN",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SO": {
          "Survey CC": "SO",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SR": {
          "Survey CC": "SR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "SY": {
          "Survey CC": "SY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "TD": {
          "Survey CC": "TD",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "TG": {
          "Survey CC": "TG",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "TH": {
          "Survey CC": "TH",
          "PctExpensiveAndCantAfford": "0.72",
          "percent": 0.72
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "TR": {
          "Survey CC": "TR",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "TT": {
          "Survey CC": "TT",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "UA": {
          "Survey CC": "UA",
          "PctExpensiveAndCantAfford": "0.66",
          "percent": 0.66
        },
        "UG": {
          "Survey CC": "UG",
          "PctExpensiveAndCantAfford": "0.87",
          "percent": 0.87
        },
        "US": {
          "Survey CC": "US",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "UY": {
          "Survey CC": "UY",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "VE": {
          "Survey CC": "VE",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "VI": {
          "Survey CC": "VI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "VN": {
          "Survey CC": "VN",
          "PctExpensiveAndCantAfford": "0.4",
          "percent": 0.4
        },
        "YE": {
          "Survey CC": "YE",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctExpensiveAndCantAfford": "0.88",
          "percent": 0.88
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctExpAndCantAffordAny": "0.82",
          "percent": 0.82
        },
        "South": {
          "GlobalRegion": "South",
          "PctExpAndCantAffordAny": "0.74",
          "percent": 0.74
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctExpAndCantAffordAny": "0.79",
          "percent": 0.79
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctExpensiveAndCantAfford": "0.88",
          "percent": 0.88
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctExpensiveAndCantAfford": "0.72",
          "percent": 0.72
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctExpensiveAndCantAfford": "0.69",
          "percent": 0.69
        },
        "Europe": {
          "CH Region": "Europe",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctExpensiveAndCantAfford": "0.88",
          "percent": 0.88
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctExpensiveAndCantAfford": "0.62",
          "percent": 0.62
        },
        "North America": {
          "CH Region": "North America",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "Total": {
          "CH Region": "Total",
          "PctExpensiveAndCantAfford": "0.79",
          "percent": 0.79
        }
      }
    },
    "24.2": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AE": {
          "Survey CC": "AE",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "AF": {
          "Survey CC": "AF",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AL": {
          "Survey CC": "AL",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AO": {
          "Survey CC": "AO",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AR": {
          "Survey CC": "AR",
          "PctExpensiveAndCantAfford": "0.86",
          "percent": 0.86
        },
        "AT": {
          "Survey CC": "AT",
          "PctExpensiveAndCantAfford": "0.89",
          "percent": 0.89
        },
        "AU": {
          "Survey CC": "AU",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "BD": {
          "Survey CC": "BD",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "BE": {
          "Survey CC": "BE",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "BG": {
          "Survey CC": "BG",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        },
        "BH": {
          "Survey CC": "BH",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "BO": {
          "Survey CC": "BO",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "BR": {
          "Survey CC": "BR",
          "PctExpensiveAndCantAfford": "0.95",
          "percent": 0.95
        },
        "BS": {
          "Survey CC": "BS",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CA": {
          "Survey CC": "CA",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "CD": {
          "Survey CC": "CD",
          "PctExpensiveAndCantAfford": "0.71",
          "percent": 0.71
        },
        "CF": {
          "Survey CC": "CF",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctExpensiveAndCantAfford": "0.9",
          "percent": 0.9
        },
        "CI": {
          "Survey CC": "CI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctExpensiveAndCantAfford": "0.85",
          "percent": 0.85
        },
        "CM": {
          "Survey CC": "CM",
          "PctExpensiveAndCantAfford": "0.93",
          "percent": 0.93
        },
        "CN": {
          "Survey CC": "CN",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "CO": {
          "Survey CC": "CO",
          "PctExpensiveAndCantAfford": "0.86",
          "percent": 0.86
        },
        "CR": {
          "Survey CC": "CR",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "DE": {
          "Survey CC": "DE",
          "PctExpensiveAndCantAfford": "0.77",
          "percent": 0.77
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctExpensiveAndCantAfford": "0.65",
          "percent": 0.65
        },
        "DO": {
          "Survey CC": "DO",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctExpensiveAndCantAfford": "0.33",
          "percent": 0.33
        },
        "EC": {
          "Survey CC": "EC",
          "PctExpensiveAndCantAfford": "0.7",
          "percent": 0.7
        },
        "EE": {
          "Survey CC": "EE",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "ES": {
          "Survey CC": "ES",
          "PctExpensiveAndCantAfford": "0.88",
          "percent": 0.88
        },
        "ET": {
          "Survey CC": "ET",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "FI": {
          "Survey CC": "FI",
          "PctExpensiveAndCantAfford": "0.55",
          "percent": 0.55
        },
        "FR": {
          "Survey CC": "FR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "GA": {
          "Survey CC": "GA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctExpensiveAndCantAfford": "0.87",
          "percent": 0.87
        },
        "GE": {
          "Survey CC": "GE",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        },
        "GN": {
          "Survey CC": "GN",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "GR": {
          "Survey CC": "GR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "GT": {
          "Survey CC": "GT",
          "PctExpensiveAndCantAfford": "0.9",
          "percent": 0.9
        },
        "HK": {
          "Survey CC": "HK",
          "PctExpensiveAndCantAfford": "0.74",
          "percent": 0.74
        },
        "HN": {
          "Survey CC": "HN",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "HR": {
          "Survey CC": "HR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "HU": {
          "Survey CC": "HU",
          "PctExpensiveAndCantAfford": "0.92",
          "percent": 0.92
        },
        "ID": {
          "Survey CC": "ID",
          "PctExpensiveAndCantAfford": "0.65",
          "percent": 0.65
        },
        "IE": {
          "Survey CC": "IE",
          "PctExpensiveAndCantAfford": "0.87",
          "percent": 0.87
        },
        "IN": {
          "Survey CC": "IN",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "IT": {
          "Survey CC": "IT",
          "PctExpensiveAndCantAfford": "0.65",
          "percent": 0.65
        },
        "JO": {
          "Survey CC": "JO",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctExpensiveAndCantAfford": "0.77",
          "percent": 0.77
        },
        "KE": {
          "Survey CC": "KE",
          "PctExpensiveAndCantAfford": "0.97",
          "percent": 0.97
        },
        "KG": {
          "Survey CC": "KG",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "KH": {
          "Survey CC": "KH",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "KR": {
          "Survey CC": "KR",
          "PctExpensiveAndCantAfford": "0.84",
          "percent": 0.84
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "LB": {
          "Survey CC": "LB",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "LR": {
          "Survey CC": "LR",
          "PctExpensiveAndCantAfford": "0.78",
          "percent": 0.78
        },
        "LT": {
          "Survey CC": "LT",
          "PctExpensiveAndCantAfford": "0.6",
          "percent": 0.6
        },
        "LU": {
          "Survey CC": "LU",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "LV": {
          "Survey CC": "LV",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "LY": {
          "Survey CC": "LY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctExpensiveAndCantAfford": "0.92",
          "percent": 0.92
        },
        "ML": {
          "Survey CC": "ML",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "MN": {
          "Survey CC": "MN",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MW": {
          "Survey CC": "MW",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MX": {
          "Survey CC": "MX",
          "PctExpensiveAndCantAfford": "0.91",
          "percent": 0.91
        },
        "MY": {
          "Survey CC": "MY",
          "PctExpensiveAndCantAfford": "0.91",
          "percent": 0.91
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NE": {
          "Survey CC": "NE",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "NG": {
          "Survey CC": "NG",
          "PctExpensiveAndCantAfford": "0.91",
          "percent": 0.91
        },
        "NI": {
          "Survey CC": "NI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NL": {
          "Survey CC": "NL",
          "PctExpensiveAndCantAfford": "0.71",
          "percent": 0.71
        },
        "NP": {
          "Survey CC": "NP",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "PA": {
          "Survey CC": "PA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PH": {
          "Survey CC": "PH",
          "PctExpensiveAndCantAfford": "0.85",
          "percent": 0.85
        },
        "PK": {
          "Survey CC": "PK",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "PL": {
          "Survey CC": "PL",
          "PctExpensiveAndCantAfford": "0.88",
          "percent": 0.88
        },
        "PR": {
          "Survey CC": "PR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PS": {
          "Survey CC": "PS",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctExpensiveAndCantAfford": "0.89",
          "percent": 0.89
        },
        "PY": {
          "Survey CC": "PY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "RO": {
          "Survey CC": "RO",
          "PctExpensiveAndCantAfford": "0.87",
          "percent": 0.87
        },
        "RS": {
          "Survey CC": "RS",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "RW": {
          "Survey CC": "RW",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "SA": {
          "Survey CC": "SA",
          "PctExpensiveAndCantAfford": "0.64",
          "percent": 0.64
        },
        "SD": {
          "Survey CC": "SD",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctExpensiveAndCantAfford": "0.56",
          "percent": 0.56
        },
        "SG": {
          "Survey CC": "SG",
          "PctExpensiveAndCantAfford": "0.87",
          "percent": 0.87
        },
        "SI": {
          "Survey CC": "SI",
          "PctExpensiveAndCantAfford": "0.85",
          "percent": 0.85
        },
        "SK": {
          "Survey CC": "SK",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctExpensiveAndCantAfford": "0.85",
          "percent": 0.85
        },
        "SN": {
          "Survey CC": "SN",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SO": {
          "Survey CC": "SO",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SR": {
          "Survey CC": "SR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SY": {
          "Survey CC": "SY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "TD": {
          "Survey CC": "TD",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "TH": {
          "Survey CC": "TH",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "TR": {
          "Survey CC": "TR",
          "PctExpensiveAndCantAfford": "0.92",
          "percent": 0.92
        },
        "TT": {
          "Survey CC": "TT",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctExpensiveAndCantAfford": "0.96",
          "percent": 0.96
        },
        "UA": {
          "Survey CC": "UA",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        },
        "UG": {
          "Survey CC": "UG",
          "PctExpensiveAndCantAfford": "0.97",
          "percent": 0.97
        },
        "US": {
          "Survey CC": "US",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "UY": {
          "Survey CC": "UY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "VI": {
          "Survey CC": "VI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "VN": {
          "Survey CC": "VN",
          "PctExpensiveAndCantAfford": "0.4",
          "percent": 0.4
        },
        "YE": {
          "Survey CC": "YE",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctExpensiveAndCantAfford": "0.94",
          "percent": 0.94
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        },
        "South": {
          "GlobalRegion": "South",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctExpensiveAndCantAfford": "0.91",
          "percent": 0.91
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctExpensiveAndCantAfford": "0.76",
          "percent": 0.76
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctExpensiveAndCantAfford": "0.88",
          "percent": 0.88
        },
        "Europe": {
          "CH Region": "Europe",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctExpensiveAndCantAfford": "0.9",
          "percent": 0.9
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctExpensiveAndCantAfford": "0.66",
          "percent": 0.66
        },
        "North America": {
          "CH Region": "North America",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        },
        "Total": {
          "CH Region": "Total",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        }
      }
    },
    "24.3": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AE": {
          "Survey CC": "AE",
          "PctExpensiveAndCantAfford": "0.33",
          "percent": 0.33
        },
        "AF": {
          "Survey CC": "AF",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AL": {
          "Survey CC": "AL",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AO": {
          "Survey CC": "AO",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "AR": {
          "Survey CC": "AR",
          "PctExpensiveAndCantAfford": "0.91",
          "percent": 0.91
        },
        "AT": {
          "Survey CC": "AT",
          "PctExpensiveAndCantAfford": "0.78",
          "percent": 0.78
        },
        "AU": {
          "Survey CC": "AU",
          "PctExpensiveAndCantAfford": "0.79",
          "percent": 0.79
        },
        "BD": {
          "Survey CC": "BD",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "BE": {
          "Survey CC": "BE",
          "PctExpensiveAndCantAfford": "0.86",
          "percent": 0.86
        },
        "BG": {
          "Survey CC": "BG",
          "PctExpensiveAndCantAfford": "0.78",
          "percent": 0.78
        },
        "BH": {
          "Survey CC": "BH",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "BO": {
          "Survey CC": "BO",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "BR": {
          "Survey CC": "BR",
          "PctExpensiveAndCantAfford": "0.95",
          "percent": 0.95
        },
        "BS": {
          "Survey CC": "BS",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CA": {
          "Survey CC": "CA",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        },
        "CD": {
          "Survey CC": "CD",
          "PctExpensiveAndCantAfford": "0.76",
          "percent": 0.76
        },
        "CF": {
          "Survey CC": "CF",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "CI": {
          "Survey CC": "CI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctExpensiveAndCantAfford": "0.91",
          "percent": 0.91
        },
        "CM": {
          "Survey CC": "CM",
          "PctExpensiveAndCantAfford": "0.93",
          "percent": 0.93
        },
        "CN": {
          "Survey CC": "CN",
          "PctExpensiveAndCantAfford": "0.25",
          "percent": 0.25
        },
        "CO": {
          "Survey CC": "CO",
          "PctExpensiveAndCantAfford": "0.88",
          "percent": 0.88
        },
        "CR": {
          "Survey CC": "CR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "DE": {
          "Survey CC": "DE",
          "PctExpensiveAndCantAfford": "0.73",
          "percent": 0.73
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctExpensiveAndCantAfford": "0.65",
          "percent": 0.65
        },
        "DO": {
          "Survey CC": "DO",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "EC": {
          "Survey CC": "EC",
          "PctExpensiveAndCantAfford": "0.9",
          "percent": 0.9
        },
        "EE": {
          "Survey CC": "EE",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "ES": {
          "Survey CC": "ES",
          "PctExpensiveAndCantAfford": "0.88",
          "percent": 0.88
        },
        "ET": {
          "Survey CC": "ET",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "FI": {
          "Survey CC": "FI",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "FR": {
          "Survey CC": "FR",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "GA": {
          "Survey CC": "GA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctExpensiveAndCantAfford": "0.9",
          "percent": 0.9
        },
        "GE": {
          "Survey CC": "GE",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctExpensiveAndCantAfford": "0.94",
          "percent": 0.94
        },
        "GN": {
          "Survey CC": "GN",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "GR": {
          "Survey CC": "GR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "GT": {
          "Survey CC": "GT",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "HK": {
          "Survey CC": "HK",
          "PctExpensiveAndCantAfford": "0.79",
          "percent": 0.79
        },
        "HN": {
          "Survey CC": "HN",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "HR": {
          "Survey CC": "HR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "HU": {
          "Survey CC": "HU",
          "PctExpensiveAndCantAfford": "0.95",
          "percent": 0.95
        },
        "ID": {
          "Survey CC": "ID",
          "PctExpensiveAndCantAfford": "0.65",
          "percent": 0.65
        },
        "IE": {
          "Survey CC": "IE",
          "PctExpensiveAndCantAfford": "0.9",
          "percent": 0.9
        },
        "IN": {
          "Survey CC": "IN",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "IT": {
          "Survey CC": "IT",
          "PctExpensiveAndCantAfford": "0.69",
          "percent": 0.69
        },
        "JO": {
          "Survey CC": "JO",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctExpensiveAndCantAfford": "0.77",
          "percent": 0.77
        },
        "KE": {
          "Survey CC": "KE",
          "PctExpensiveAndCantAfford": "0.97",
          "percent": 0.97
        },
        "KG": {
          "Survey CC": "KG",
          "PctExpensiveAndCantAfford": "0.5",
          "percent": 0.5
        },
        "KH": {
          "Survey CC": "KH",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "KR": {
          "Survey CC": "KR",
          "PctExpensiveAndCantAfford": "0.58",
          "percent": 0.58
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "LB": {
          "Survey CC": "LB",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "LR": {
          "Survey CC": "LR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "LT": {
          "Survey CC": "LT",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "LU": {
          "Survey CC": "LU",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "LV": {
          "Survey CC": "LV",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "LY": {
          "Survey CC": "LY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctExpensiveAndCantAfford": "0.85",
          "percent": 0.85
        },
        "ML": {
          "Survey CC": "ML",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MN": {
          "Survey CC": "MN",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MW": {
          "Survey CC": "MW",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "MX": {
          "Survey CC": "MX",
          "PctExpensiveAndCantAfford": "0.97",
          "percent": 0.97
        },
        "MY": {
          "Survey CC": "MY",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NE": {
          "Survey CC": "NE",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctExpensiveAndCantAfford": "0.98",
          "percent": 0.98
        },
        "NI": {
          "Survey CC": "NI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NL": {
          "Survey CC": "NL",
          "PctExpensiveAndCantAfford": "0.65",
          "percent": 0.65
        },
        "NP": {
          "Survey CC": "NP",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "PA": {
          "Survey CC": "PA",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PH": {
          "Survey CC": "PH",
          "PctExpensiveAndCantAfford": "0.9",
          "percent": 0.9
        },
        "PK": {
          "Survey CC": "PK",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "PL": {
          "Survey CC": "PL",
          "PctExpensiveAndCantAfford": "0.88",
          "percent": 0.88
        },
        "PR": {
          "Survey CC": "PR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PS": {
          "Survey CC": "PS",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctExpensiveAndCantAfford": "0.9",
          "percent": 0.9
        },
        "PY": {
          "Survey CC": "PY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "RO": {
          "Survey CC": "RO",
          "PctExpensiveAndCantAfford": "0.76",
          "percent": 0.76
        },
        "RS": {
          "Survey CC": "RS",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "RW": {
          "Survey CC": "RW",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "SA": {
          "Survey CC": "SA",
          "PctExpensiveAndCantAfford": "0.86",
          "percent": 0.86
        },
        "SD": {
          "Survey CC": "SD",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SE": {
          "Survey CC": "SE",
          "PctExpensiveAndCantAfford": "0.62",
          "percent": 0.62
        },
        "SG": {
          "Survey CC": "SG",
          "PctExpensiveAndCantAfford": "0.71",
          "percent": 0.71
        },
        "SI": {
          "Survey CC": "SI",
          "PctExpensiveAndCantAfford": "0.79",
          "percent": 0.79
        },
        "SK": {
          "Survey CC": "SK",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctExpensiveAndCantAfford": "0.92",
          "percent": 0.92
        },
        "SN": {
          "Survey CC": "SN",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SO": {
          "Survey CC": "SO",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SR": {
          "Survey CC": "SR",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SY": {
          "Survey CC": "SY",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "TD": {
          "Survey CC": "TD",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "TG": {
          "Survey CC": "TG",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "TH": {
          "Survey CC": "TH",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "TR": {
          "Survey CC": "TR",
          "PctExpensiveAndCantAfford": "0.96",
          "percent": 0.96
        },
        "TT": {
          "Survey CC": "TT",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "TW": {
          "Survey CC": "TW",
          "PctExpensiveAndCantAfford": "0.75",
          "percent": 0.75
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctExpensiveAndCantAfford": "0.92",
          "percent": 0.92
        },
        "UA": {
          "Survey CC": "UA",
          "PctExpensiveAndCantAfford": "0.77",
          "percent": 0.77
        },
        "UG": {
          "Survey CC": "UG",
          "PctExpensiveAndCantAfford": "0.93",
          "percent": 0.93
        },
        "US": {
          "Survey CC": "US",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "UY": {
          "Survey CC": "UY",
          "PctExpensiveAndCantAfford": "0",
          "percent": 0
        },
        "VE": {
          "Survey CC": "VE",
          "PctExpensiveAndCantAfford": "0.67",
          "percent": 0.67
        },
        "VI": {
          "Survey CC": "VI",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "VN": {
          "Survey CC": "VN",
          "PctExpensiveAndCantAfford": "0.4",
          "percent": 0.4
        },
        "YE": {
          "Survey CC": "YE",
          "PctExpensiveAndCantAfford": "0.8",
          "percent": 0.8
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctExpensiveAndCantAfford": "0.96",
          "percent": 0.96
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctExpensiveAndCantAfford": "1",
          "percent": 1
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctExpensiveAndCantAfford": "0.83",
          "percent": 0.83
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        },
        "South": {
          "GlobalRegion": "South",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctExpensiveAndCantAfford": "0.95",
          "percent": 0.95
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctExpensiveAndCantAfford": "0.74",
          "percent": 0.74
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctExpensiveAndCantAfford": "0.94",
          "percent": 0.94
        },
        "Europe": {
          "CH Region": "Europe",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctExpensiveAndCantAfford": "0.93",
          "percent": 0.93
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctExpensiveAndCantAfford": "0.84",
          "percent": 0.84
        },
        "North America": {
          "CH Region": "North America",
          "PctExpensiveAndCantAfford": "0.82",
          "percent": 0.82
        },
        "Total": {
          "CH Region": "Total",
          "PctExpensiveAndCantAfford": "0.81",
          "percent": 0.81
        }
      }
    },
    "24.4": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "AE": {
          "Survey CC": "AE",
          "PctExpAndCantAffordAny": "0.33",
          "percent": 0.33
        },
        "AF": {
          "Survey CC": "AF",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "AL": {
          "Survey CC": "AL",
          "PctExpAndCantAffordAny": "0.67",
          "percent": 0.67
        },
        "AO": {
          "Survey CC": "AO",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "AR": {
          "Survey CC": "AR",
          "PctExpAndCantAffordAny": "0.68",
          "percent": 0.68
        },
        "AT": {
          "Survey CC": "AT",
          "PctExpAndCantAffordAny": "0.44",
          "percent": 0.44
        },
        "AU": {
          "Survey CC": "AU",
          "PctExpAndCantAffordAny": "0.48",
          "percent": 0.48
        },
        "BD": {
          "Survey CC": "BD",
          "PctExpAndCantAffordAny": "0.6",
          "percent": 0.6
        },
        "BE": {
          "Survey CC": "BE",
          "PctExpAndCantAffordAny": "0.55",
          "percent": 0.55
        },
        "BG": {
          "Survey CC": "BG",
          "PctExpAndCantAffordAny": "0.33",
          "percent": 0.33
        },
        "BH": {
          "Survey CC": "BH",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctExpAndCantAffordAny": "0.83",
          "percent": 0.83
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctExpAndCantAffordAny": "0.33",
          "percent": 0.33
        },
        "BO": {
          "Survey CC": "BO",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "BR": {
          "Survey CC": "BR",
          "PctExpAndCantAffordAny": "0.72",
          "percent": 0.72
        },
        "BS": {
          "Survey CC": "BS",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "CA": {
          "Survey CC": "CA",
          "PctExpAndCantAffordAny": "0.58",
          "percent": 0.58
        },
        "CD": {
          "Survey CC": "CD",
          "PctExpAndCantAffordAny": "0.35",
          "percent": 0.35
        },
        "CF": {
          "Survey CC": "CF",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "CI": {
          "Survey CC": "CI",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "CL": {
          "Survey CC": "CL",
          "PctExpAndCantAffordAny": "0.44",
          "percent": 0.44
        },
        "CM": {
          "Survey CC": "CM",
          "PctExpAndCantAffordAny": "0.93",
          "percent": 0.93
        },
        "CN": {
          "Survey CC": "CN",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "CO": {
          "Survey CC": "CO",
          "PctExpAndCantAffordAny": "0.69",
          "percent": 0.69
        },
        "CR": {
          "Survey CC": "CR",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctExpAndCantAffordAny": "0.44",
          "percent": 0.44
        },
        "DE": {
          "Survey CC": "DE",
          "PctExpAndCantAffordAny": "0.43",
          "percent": 0.43
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctExpAndCantAffordAny": "0.12",
          "percent": 0.12
        },
        "DO": {
          "Survey CC": "DO",
          "PctExpAndCantAffordAny": "0.6",
          "percent": 0.6
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "EC": {
          "Survey CC": "EC",
          "PctExpAndCantAffordAny": "0.4",
          "percent": 0.4
        },
        "EE": {
          "Survey CC": "EE",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "EG": {
          "Survey CC": "EG",
          "PctExpAndCantAffordAny": "0.67",
          "percent": 0.67
        },
        "ES": {
          "Survey CC": "ES",
          "PctExpAndCantAffordAny": "0.49",
          "percent": 0.49
        },
        "ET": {
          "Survey CC": "ET",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "FI": {
          "Survey CC": "FI",
          "PctExpAndCantAffordAny": "0.09",
          "percent": 0.09
        },
        "FR": {
          "Survey CC": "FR",
          "PctExpAndCantAffordAny": "0.58",
          "percent": 0.58
        },
        "GA": {
          "Survey CC": "GA",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctExpAndCantAffordAny": "0.6",
          "percent": 0.6
        },
        "GE": {
          "Survey CC": "GE",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctExpAndCantAffordAny": "0.72",
          "percent": 0.72
        },
        "GN": {
          "Survey CC": "GN",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "GR": {
          "Survey CC": "GR",
          "PctExpAndCantAffordAny": "0.67",
          "percent": 0.67
        },
        "GT": {
          "Survey CC": "GT",
          "PctExpAndCantAffordAny": "0.4",
          "percent": 0.4
        },
        "HK": {
          "Survey CC": "HK",
          "PctExpAndCantAffordAny": "0.47",
          "percent": 0.47
        },
        "HN": {
          "Survey CC": "HN",
          "PctExpAndCantAffordAny": "0.71",
          "percent": 0.71
        },
        "HR": {
          "Survey CC": "HR",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctExpAndCantAffordAny": "0.67",
          "percent": 0.67
        },
        "HU": {
          "Survey CC": "HU",
          "PctExpAndCantAffordAny": "0.52",
          "percent": 0.52
        },
        "ID": {
          "Survey CC": "ID",
          "PctExpAndCantAffordAny": "0.25",
          "percent": 0.25
        },
        "IE": {
          "Survey CC": "IE",
          "PctExpAndCantAffordAny": "0.58",
          "percent": 0.58
        },
        "IN": {
          "Survey CC": "IN",
          "PctExpAndCantAffordAny": "0.51",
          "percent": 0.51
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctExpAndCantAffordAny": "0.25",
          "percent": 0.25
        },
        "IT": {
          "Survey CC": "IT",
          "PctExpAndCantAffordAny": "0.25",
          "percent": 0.25
        },
        "JO": {
          "Survey CC": "JO",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctExpAndCantAffordAny": "0.46",
          "percent": 0.46
        },
        "KE": {
          "Survey CC": "KE",
          "PctExpAndCantAffordAny": "0.76",
          "percent": 0.76
        },
        "KG": {
          "Survey CC": "KG",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "KH": {
          "Survey CC": "KH",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "KR": {
          "Survey CC": "KR",
          "PctExpAndCantAffordAny": "0.31",
          "percent": 0.31
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctExpAndCantAffordAny": "0.75",
          "percent": 0.75
        },
        "LB": {
          "Survey CC": "LB",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctExpAndCantAffordAny": "0.75",
          "percent": 0.75
        },
        "LR": {
          "Survey CC": "LR",
          "PctExpAndCantAffordAny": "0.44",
          "percent": 0.44
        },
        "LT": {
          "Survey CC": "LT",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "LU": {
          "Survey CC": "LU",
          "PctExpAndCantAffordAny": "0.83",
          "percent": 0.83
        },
        "LV": {
          "Survey CC": "LV",
          "PctExpAndCantAffordAny": "0.25",
          "percent": 0.25
        },
        "LY": {
          "Survey CC": "LY",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctExpAndCantAffordAny": "0.41",
          "percent": 0.41
        },
        "ML": {
          "Survey CC": "ML",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "MM": {
          "Survey CC": "MM",
          "PctExpAndCantAffordAny": "0.33",
          "percent": 0.33
        },
        "MN": {
          "Survey CC": "MN",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "MW": {
          "Survey CC": "MW",
          "PctExpAndCantAffordAny": "0.25",
          "percent": 0.25
        },
        "MX": {
          "Survey CC": "MX",
          "PctExpAndCantAffordAny": "0.7",
          "percent": 0.7
        },
        "MY": {
          "Survey CC": "MY",
          "PctExpAndCantAffordAny": "0.45",
          "percent": 0.45
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "NE": {
          "Survey CC": "NE",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "NG": {
          "Survey CC": "NG",
          "PctExpAndCantAffordAny": "0.74",
          "percent": 0.74
        },
        "NI": {
          "Survey CC": "NI",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "NL": {
          "Survey CC": "NL",
          "PctExpAndCantAffordAny": "0.38",
          "percent": 0.38
        },
        "NP": {
          "Survey CC": "NP",
          "PctExpAndCantAffordAny": "0.6",
          "percent": 0.6
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctExpAndCantAffordAny": "0.49",
          "percent": 0.49
        },
        "PA": {
          "Survey CC": "PA",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctExpAndCantAffordAny": "0.75",
          "percent": 0.75
        },
        "PH": {
          "Survey CC": "PH",
          "PctExpAndCantAffordAny": "0.58",
          "percent": 0.58
        },
        "PK": {
          "Survey CC": "PK",
          "PctExpAndCantAffordAny": "0.42",
          "percent": 0.42
        },
        "PL": {
          "Survey CC": "PL",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "PR": {
          "Survey CC": "PR",
          "PctExpAndCantAffordAny": "0.67",
          "percent": 0.67
        },
        "PS": {
          "Survey CC": "PS",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctExpAndCantAffordAny": "0.61",
          "percent": 0.61
        },
        "PY": {
          "Survey CC": "PY",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "RO": {
          "Survey CC": "RO",
          "PctExpAndCantAffordAny": "0.31",
          "percent": 0.31
        },
        "RS": {
          "Survey CC": "RS",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "RW": {
          "Survey CC": "RW",
          "PctExpAndCantAffordAny": "0.75",
          "percent": 0.75
        },
        "SA": {
          "Survey CC": "SA",
          "PctExpAndCantAffordAny": "0.26",
          "percent": 0.26
        },
        "SD": {
          "Survey CC": "SD",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctExpAndCantAffordAny": "0.26",
          "percent": 0.26
        },
        "SG": {
          "Survey CC": "SG",
          "PctExpAndCantAffordAny": "0.32",
          "percent": 0.32
        },
        "SI": {
          "Survey CC": "SI",
          "PctExpAndCantAffordAny": "0.27",
          "percent": 0.27
        },
        "SK": {
          "Survey CC": "SK",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctExpAndCantAffordAny": "0.46",
          "percent": 0.46
        },
        "SN": {
          "Survey CC": "SN",
          "PctExpAndCantAffordAny": "0.33",
          "percent": 0.33
        },
        "SO": {
          "Survey CC": "SO",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "SR": {
          "Survey CC": "SR",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "SY": {
          "Survey CC": "SY",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctExpAndCantAffordAny": "0.67",
          "percent": 0.67
        },
        "TD": {
          "Survey CC": "TD",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctExpAndCantAffordAny": "1",
          "percent": 1
        },
        "TH": {
          "Survey CC": "TH",
          "PctExpAndCantAffordAny": "0.61",
          "percent": 0.61
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "TR": {
          "Survey CC": "TR",
          "PctExpAndCantAffordAny": "0.69",
          "percent": 0.69
        },
        "TT": {
          "Survey CC": "TT",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctExpAndCantAffordAny": "0.37",
          "percent": 0.37
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctExpAndCantAffordAny": "0.67",
          "percent": 0.67
        },
        "UA": {
          "Survey CC": "UA",
          "PctExpAndCantAffordAny": "0.27",
          "percent": 0.27
        },
        "UG": {
          "Survey CC": "UG",
          "PctExpAndCantAffordAny": "0.67",
          "percent": 0.67
        },
        "US": {
          "Survey CC": "US",
          "PctExpAndCantAffordAny": "0.58",
          "percent": 0.58
        },
        "UY": {
          "Survey CC": "UY",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "VE": {
          "Survey CC": "VE",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "VI": {
          "Survey CC": "VI",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "VN": {
          "Survey CC": "VN",
          "PctExpAndCantAffordAny": "0",
          "percent": 0
        },
        "YE": {
          "Survey CC": "YE",
          "PctExpAndCantAffordAny": "0.4",
          "percent": 0.4
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctExpAndCantAffordAny": "0.74",
          "percent": 0.74
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctExpAndCantAffordAny": "0.78",
          "percent": 0.78
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctExpAndCantAffordAny": "0.5",
          "percent": 0.5
        },
        "South": {
          "GlobalRegion": "South",
          "PctExpAndCantAffordAny": "0.47",
          "percent": 0.47
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctExpAndCantAffordAny": "0.49",
          "percent": 0.49
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctExpAndCantAffordAny": "0.7",
          "percent": 0.7
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctExpAndCantAffordAny": "0.4",
          "percent": 0.4
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctExpAndCantAffordAny": "0.56",
          "percent": 0.56
        },
        "Europe": {
          "CH Region": "Europe",
          "PctExpAndCantAffordAny": "0.43",
          "percent": 0.43
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctExpAndCantAffordAny": "0.66",
          "percent": 0.66
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctExpAndCantAffordAny": "0.3",
          "percent": 0.3
        },
        "North America": {
          "CH Region": "North America",
          "PctExpAndCantAffordAny": "0.58",
          "percent": 0.58
        },
        "Total": {
          "CH Region": "Total",
          "PctExpAndCantAffordAny": "0.49",
          "percent": 0.49
        }
      }
    },
    "28.1": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "AF": {
          "Survey CC": "AF",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AL": {
          "Survey CC": "AL",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "AO": {
          "Survey CC": "AO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AR": {
          "Survey CC": "AR",
          "PctStronglyAgree": "0.23",
          "percent": 0.23
        },
        "AT": {
          "Survey CC": "AT",
          "PctStronglyAgree": "0.22",
          "percent": 0.22
        },
        "AU": {
          "Survey CC": "AU",
          "PctStronglyAgree": "0.18",
          "percent": 0.18
        },
        "BD": {
          "Survey CC": "BD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BE": {
          "Survey CC": "BE",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "BG": {
          "Survey CC": "BG",
          "PctStronglyAgree": "0.16",
          "percent": 0.16
        },
        "BH": {
          "Survey CC": "BH",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BO": {
          "Survey CC": "BO",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "BR": {
          "Survey CC": "BR",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "BS": {
          "Survey CC": "BS",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CA": {
          "Survey CC": "CA",
          "PctStronglyAgree": "0.14",
          "percent": 0.14
        },
        "CD": {
          "Survey CC": "CD",
          "PctStronglyAgree": "0.18",
          "percent": 0.18
        },
        "CF": {
          "Survey CC": "CF",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CG": {
          "Survey CC": "CG",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctStronglyAgree": "0.3",
          "percent": 0.3
        },
        "CI": {
          "Survey CC": "CI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CL": {
          "Survey CC": "CL",
          "PctStronglyAgree": "0.18",
          "percent": 0.18
        },
        "CM": {
          "Survey CC": "CM",
          "PctStronglyAgree": "0.14",
          "percent": 0.14
        },
        "CN": {
          "Survey CC": "CN",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "CO": {
          "Survey CC": "CO",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "CR": {
          "Survey CC": "CR",
          "PctStronglyAgree": "0.75",
          "percent": 0.75
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctStronglyAgree": "0.08",
          "percent": 0.08
        },
        "DE": {
          "Survey CC": "DE",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctStronglyAgree": "0.24",
          "percent": 0.24
        },
        "DO": {
          "Survey CC": "DO",
          "PctStronglyAgree": "0.4",
          "percent": 0.4
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "EC": {
          "Survey CC": "EC",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "EE": {
          "Survey CC": "EE",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "EG": {
          "Survey CC": "EG",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "ES": {
          "Survey CC": "ES",
          "PctStronglyAgree": "0.23",
          "percent": 0.23
        },
        "ET": {
          "Survey CC": "ET",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "FI": {
          "Survey CC": "FI",
          "PctStronglyAgree": "0.18",
          "percent": 0.18
        },
        "FR": {
          "Survey CC": "FR",
          "PctStronglyAgree": "0.08",
          "percent": 0.08
        },
        "GA": {
          "Survey CC": "GA",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "GB": {
          "Survey CC": "GB",
          "PctStronglyAgree": "0.15",
          "percent": 0.15
        },
        "GE": {
          "Survey CC": "GE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctStronglyAgree": "0.14",
          "percent": 0.14
        },
        "GN": {
          "Survey CC": "GN",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "GR": {
          "Survey CC": "GR",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "GT": {
          "Survey CC": "GT",
          "PctStronglyAgree": "0.3",
          "percent": 0.3
        },
        "HK": {
          "Survey CC": "HK",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "HN": {
          "Survey CC": "HN",
          "PctStronglyAgree": "0.14",
          "percent": 0.14
        },
        "HR": {
          "Survey CC": "HR",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "HU": {
          "Survey CC": "HU",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "ID": {
          "Survey CC": "ID",
          "PctStronglyAgree": "0.27",
          "percent": 0.27
        },
        "IE": {
          "Survey CC": "IE",
          "PctStronglyAgree": "0.14",
          "percent": 0.14
        },
        "IN": {
          "Survey CC": "IN",
          "PctStronglyAgree": "0.21",
          "percent": 0.21
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "IT": {
          "Survey CC": "IT",
          "PctStronglyAgree": "0.15",
          "percent": 0.15
        },
        "JO": {
          "Survey CC": "JO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "JP": {
          "Survey CC": "JP",
          "PctStronglyAgree": "0.07",
          "percent": 0.07
        },
        "KE": {
          "Survey CC": "KE",
          "PctStronglyAgree": "0.22",
          "percent": 0.22
        },
        "KG": {
          "Survey CC": "KG",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "KH": {
          "Survey CC": "KH",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "KR": {
          "Survey CC": "KR",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LB": {
          "Survey CC": "LB",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctStronglyAgree": "0.38",
          "percent": 0.38
        },
        "LR": {
          "Survey CC": "LR",
          "PctStronglyAgree": "0.22",
          "percent": 0.22
        },
        "LT": {
          "Survey CC": "LT",
          "PctStronglyAgree": "0.2",
          "percent": 0.2
        },
        "LU": {
          "Survey CC": "LU",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LV": {
          "Survey CC": "LV",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "LY": {
          "Survey CC": "LY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MD": {
          "Survey CC": "MD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "ML": {
          "Survey CC": "ML",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "MM": {
          "Survey CC": "MM",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "MN": {
          "Survey CC": "MN",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "MW": {
          "Survey CC": "MW",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "MX": {
          "Survey CC": "MX",
          "PctStronglyAgree": "0.15",
          "percent": 0.15
        },
        "MY": {
          "Survey CC": "MY",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NA": {
          "Survey CC": "NA",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NE": {
          "Survey CC": "NE",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "NG": {
          "Survey CC": "NG",
          "PctStronglyAgree": "0.38",
          "percent": 0.38
        },
        "NI": {
          "Survey CC": "NI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NL": {
          "Survey CC": "NL",
          "PctStronglyAgree": "0.04",
          "percent": 0.04
        },
        "NP": {
          "Survey CC": "NP",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "PA": {
          "Survey CC": "PA",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "PE": {
          "Survey CC": "PE",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "PH": {
          "Survey CC": "PH",
          "PctStronglyAgree": "0.19",
          "percent": 0.19
        },
        "PK": {
          "Survey CC": "PK",
          "PctStronglyAgree": "0.08",
          "percent": 0.08
        },
        "PL": {
          "Survey CC": "PL",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "PR": {
          "Survey CC": "PR",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "PS": {
          "Survey CC": "PS",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctStronglyAgree": "0.13",
          "percent": 0.13
        },
        "PY": {
          "Survey CC": "PY",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "RO": {
          "Survey CC": "RO",
          "PctStronglyAgree": "0.15",
          "percent": 0.15
        },
        "RS": {
          "Survey CC": "RS",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "RW": {
          "Survey CC": "RW",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "SA": {
          "Survey CC": "SA",
          "PctStronglyAgree": "0.31",
          "percent": 0.31
        },
        "SD": {
          "Survey CC": "SD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "SG": {
          "Survey CC": "SG",
          "PctStronglyAgree": "0.19",
          "percent": 0.19
        },
        "SI": {
          "Survey CC": "SI",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        },
        "SK": {
          "Survey CC": "SK",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctStronglyAgree": "0.31",
          "percent": 0.31
        },
        "SN": {
          "Survey CC": "SN",
          "PctStronglyAgree": "0.67",
          "percent": 0.67
        },
        "SO": {
          "Survey CC": "SO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SR": {
          "Survey CC": "SR",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "ST": {
          "Survey CC": "ST",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "SY": {
          "Survey CC": "SY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "TD": {
          "Survey CC": "TD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "TH": {
          "Survey CC": "TH",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "TR": {
          "Survey CC": "TR",
          "PctStronglyAgree": "0.18",
          "percent": 0.18
        },
        "TT": {
          "Survey CC": "TT",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctStronglyAgree": "0.21",
          "percent": 0.21
        },
        "UA": {
          "Survey CC": "UA",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "UG": {
          "Survey CC": "UG",
          "PctStronglyAgree": "0.2",
          "percent": 0.2
        },
        "US": {
          "Survey CC": "US",
          "PctStronglyAgree": "0.16",
          "percent": 0.16
        },
        "UY": {
          "Survey CC": "UY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "VE": {
          "Survey CC": "VE",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "VI": {
          "Survey CC": "VI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "VN": {
          "Survey CC": "VN",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "YE": {
          "Survey CC": "YE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctStronglyAgree": "0.19",
          "percent": 0.19
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctStronglyAgree": "0.22",
          "percent": 0.22
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctStronglyAgree": "0",
          "percent": 0
        }
      },
      "ns": {
        "South": {
          "GlobalRegion": "South",
          "PctStronglyAgree": "0.2",
          "percent": 0.2
        },
        "North": {
          "GlobalRegion": "North",
          "PctStronglyAgree": "0.13",
          "percent": 0.13
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctStronglyAgree": "0.15",
          "percent": 0.15
        }
      },
      "r": {
        "Middle East": {
          "CH Region": "Middle East",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "Africa": {
          "CH Region": "Africa",
          "PctStronglyAgree": "0.23",
          "percent": 0.23
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctStronglyAgree": "0.18",
          "percent": 0.18
        },
        "North America": {
          "CH Region": "North America",
          "PctStronglyAgree": "0.16",
          "percent": 0.16
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctStronglyAgree": "0.15",
          "percent": 0.15
        },
        "Europe": {
          "CH Region": "Europe",
          "PctStronglyAgree": "0.13",
          "percent": 0.13
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctStronglyAgree": "0.13",
          "percent": 0.13
        },
        "Total": {
          "CH Region": "Total",
          "PctStronglyAgree": "0.15",
          "percent": 0.15
        }
      }
    },
    "28.2": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "AF": {
          "Survey CC": "AF",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AL": {
          "Survey CC": "AL",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AO": {
          "Survey CC": "AO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AR": {
          "Survey CC": "AR",
          "PctStronglyAgree": "0.14",
          "percent": 0.14
        },
        "AT": {
          "Survey CC": "AT",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "AU": {
          "Survey CC": "AU",
          "PctStronglyAgree": "0.08",
          "percent": 0.08
        },
        "BD": {
          "Survey CC": "BD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BE": {
          "Survey CC": "BE",
          "PctStronglyAgree": "0.05",
          "percent": 0.05
        },
        "BG": {
          "Survey CC": "BG",
          "PctStronglyAgree": "0.27",
          "percent": 0.27
        },
        "BH": {
          "Survey CC": "BH",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BO": {
          "Survey CC": "BO",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "BR": {
          "Survey CC": "BR",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "BS": {
          "Survey CC": "BS",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CA": {
          "Survey CC": "CA",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "CD": {
          "Survey CC": "CD",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "CF": {
          "Survey CC": "CF",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CG": {
          "Survey CC": "CG",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "CI": {
          "Survey CC": "CI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CL": {
          "Survey CC": "CL",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "CM": {
          "Survey CC": "CM",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CN": {
          "Survey CC": "CN",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "CO": {
          "Survey CC": "CO",
          "PctStronglyAgree": "0.22",
          "percent": 0.22
        },
        "CR": {
          "Survey CC": "CR",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "DE": {
          "Survey CC": "DE",
          "PctStronglyAgree": "0.07",
          "percent": 0.07
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctStronglyAgree": "0.18",
          "percent": 0.18
        },
        "DO": {
          "Survey CC": "DO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "EC": {
          "Survey CC": "EC",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "EE": {
          "Survey CC": "EE",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "EG": {
          "Survey CC": "EG",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "ES": {
          "Survey CC": "ES",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "ET": {
          "Survey CC": "ET",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "FI": {
          "Survey CC": "FI",
          "PctStronglyAgree": "0.18",
          "percent": 0.18
        },
        "FR": {
          "Survey CC": "FR",
          "PctStronglyAgree": "0.08",
          "percent": 0.08
        },
        "GA": {
          "Survey CC": "GA",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "GB": {
          "Survey CC": "GB",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "GE": {
          "Survey CC": "GE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctStronglyAgree": "0.08",
          "percent": 0.08
        },
        "GN": {
          "Survey CC": "GN",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "GR": {
          "Survey CC": "GR",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "GT": {
          "Survey CC": "GT",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "HK": {
          "Survey CC": "HK",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "HN": {
          "Survey CC": "HN",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "HR": {
          "Survey CC": "HR",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "HU": {
          "Survey CC": "HU",
          "PctStronglyAgree": "0.15",
          "percent": 0.15
        },
        "ID": {
          "Survey CC": "ID",
          "PctStronglyAgree": "0.22",
          "percent": 0.22
        },
        "IE": {
          "Survey CC": "IE",
          "PctStronglyAgree": "0.07",
          "percent": 0.07
        },
        "IN": {
          "Survey CC": "IN",
          "PctStronglyAgree": "0.13",
          "percent": 0.13
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "IT": {
          "Survey CC": "IT",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "JO": {
          "Survey CC": "JO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "JP": {
          "Survey CC": "JP",
          "PctStronglyAgree": "0.04",
          "percent": 0.04
        },
        "KE": {
          "Survey CC": "KE",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "KG": {
          "Survey CC": "KG",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "KH": {
          "Survey CC": "KH",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "KR": {
          "Survey CC": "KR",
          "PctStronglyAgree": "0.05",
          "percent": 0.05
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "LB": {
          "Survey CC": "LB",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctStronglyAgree": "0.38",
          "percent": 0.38
        },
        "LR": {
          "Survey CC": "LR",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "LT": {
          "Survey CC": "LT",
          "PctStronglyAgree": "0.2",
          "percent": 0.2
        },
        "LU": {
          "Survey CC": "LU",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LV": {
          "Survey CC": "LV",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "LY": {
          "Survey CC": "LY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MD": {
          "Survey CC": "MD",
          "PctStronglyAgree": "0.15",
          "percent": 0.15
        },
        "ML": {
          "Survey CC": "ML",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "MN": {
          "Survey CC": "MN",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MT": {
          "Survey CC": "MT",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MW": {
          "Survey CC": "MW",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MX": {
          "Survey CC": "MX",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        },
        "MY": {
          "Survey CC": "MY",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NA": {
          "Survey CC": "NA",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NE": {
          "Survey CC": "NE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NG": {
          "Survey CC": "NG",
          "PctStronglyAgree": "0.15",
          "percent": 0.15
        },
        "NI": {
          "Survey CC": "NI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NL": {
          "Survey CC": "NL",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "NP": {
          "Survey CC": "NP",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "PA": {
          "Survey CC": "PA",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "PE": {
          "Survey CC": "PE",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "PH": {
          "Survey CC": "PH",
          "PctStronglyAgree": "0.08",
          "percent": 0.08
        },
        "PK": {
          "Survey CC": "PK",
          "PctStronglyAgree": "0.08",
          "percent": 0.08
        },
        "PL": {
          "Survey CC": "PL",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "PR": {
          "Survey CC": "PR",
          "PctStronglyAgree": "0.67",
          "percent": 0.67
        },
        "PS": {
          "Survey CC": "PS",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "PY": {
          "Survey CC": "PY",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "RO": {
          "Survey CC": "RO",
          "PctStronglyAgree": "0.22",
          "percent": 0.22
        },
        "RS": {
          "Survey CC": "RS",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "RW": {
          "Survey CC": "RW",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SA": {
          "Survey CC": "SA",
          "PctStronglyAgree": "0.29",
          "percent": 0.29
        },
        "SD": {
          "Survey CC": "SD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctStronglyAgree": "0.18",
          "percent": 0.18
        },
        "SG": {
          "Survey CC": "SG",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "SI": {
          "Survey CC": "SI",
          "PctStronglyAgree": "0.21",
          "percent": 0.21
        },
        "SK": {
          "Survey CC": "SK",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctStronglyAgree": "0.08",
          "percent": 0.08
        },
        "SN": {
          "Survey CC": "SN",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "SO": {
          "Survey CC": "SO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SR": {
          "Survey CC": "SR",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "ST": {
          "Survey CC": "ST",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "SY": {
          "Survey CC": "SY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "TD": {
          "Survey CC": "TD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "TH": {
          "Survey CC": "TH",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "TR": {
          "Survey CC": "TR",
          "PctStronglyAgree": "0.14",
          "percent": 0.14
        },
        "TT": {
          "Survey CC": "TT",
          "PctStronglyAgree": "1",
          "percent": 1
        },
        "TW": {
          "Survey CC": "TW",
          "PctStronglyAgree": "0.08",
          "percent": 0.08
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctStronglyAgree": "0.13",
          "percent": 0.13
        },
        "UA": {
          "Survey CC": "UA",
          "PctStronglyAgree": "0.15",
          "percent": 0.15
        },
        "UG": {
          "Survey CC": "UG",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "US": {
          "Survey CC": "US",
          "PctStronglyAgree": "0.13",
          "percent": 0.13
        },
        "UY": {
          "Survey CC": "UY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "VE": {
          "Survey CC": "VE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "VI": {
          "Survey CC": "VI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "VN": {
          "Survey CC": "VN",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "YE": {
          "Survey CC": "YE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctStronglyAgree": "0.05",
          "percent": 0.05
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctStronglyAgree": "0",
          "percent": 0
        }
      },
      "ns": {
        "South": {
          "GlobalRegion": "South",
          "PctStronglyAgree": "0.14",
          "percent": 0.14
        },
        "North": {
          "GlobalRegion": "North",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        }
      },
      "r": {
        "Middle East": {
          "CH Region": "Middle East",
          "PctStronglyAgree": "0.21",
          "percent": 0.21
        },
        "Europe": {
          "CH Region": "Europe",
          "PctStronglyAgree": "0.13",
          "percent": 0.13
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctStronglyAgree": "0.13",
          "percent": 0.13
        },
        "North America": {
          "CH Region": "North America",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "Africa": {
          "CH Region": "Africa",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "Total": {
          "CH Region": "Total",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        }
      }
    },
    "28.3": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "AF": {
          "Survey CC": "AF",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "AL": {
          "Survey CC": "AL",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AO": {
          "Survey CC": "AO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AR": {
          "Survey CC": "AR",
          "PctStronglyAgree": "0.05",
          "percent": 0.05
        },
        "AT": {
          "Survey CC": "AT",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "AU": {
          "Survey CC": "AU",
          "PctStronglyAgree": "0.07",
          "percent": 0.07
        },
        "BD": {
          "Survey CC": "BD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BE": {
          "Survey CC": "BE",
          "PctStronglyAgree": "0.04",
          "percent": 0.04
        },
        "BG": {
          "Survey CC": "BG",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "BH": {
          "Survey CC": "BH",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BO": {
          "Survey CC": "BO",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "BR": {
          "Survey CC": "BR",
          "PctStronglyAgree": "0.04",
          "percent": 0.04
        },
        "BS": {
          "Survey CC": "BS",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "CA": {
          "Survey CC": "CA",
          "PctStronglyAgree": "0.05",
          "percent": 0.05
        },
        "CD": {
          "Survey CC": "CD",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "CF": {
          "Survey CC": "CF",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CG": {
          "Survey CC": "CG",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CH": {
          "Survey CC": "CH",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "CI": {
          "Survey CC": "CI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CL": {
          "Survey CC": "CL",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "CM": {
          "Survey CC": "CM",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CN": {
          "Survey CC": "CN",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "CO": {
          "Survey CC": "CO",
          "PctStronglyAgree": "0.05",
          "percent": 0.05
        },
        "CR": {
          "Survey CC": "CR",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "DE": {
          "Survey CC": "DE",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "DO": {
          "Survey CC": "DO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "EC": {
          "Survey CC": "EC",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "EE": {
          "Survey CC": "EE",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "EG": {
          "Survey CC": "EG",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "ES": {
          "Survey CC": "ES",
          "PctStronglyAgree": "0.08",
          "percent": 0.08
        },
        "ET": {
          "Survey CC": "ET",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "FI": {
          "Survey CC": "FI",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "FR": {
          "Survey CC": "FR",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "GA": {
          "Survey CC": "GA",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "GB": {
          "Survey CC": "GB",
          "PctStronglyAgree": "0.04",
          "percent": 0.04
        },
        "GE": {
          "Survey CC": "GE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "GN": {
          "Survey CC": "GN",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "GR": {
          "Survey CC": "GR",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "GT": {
          "Survey CC": "GT",
          "PctStronglyAgree": "0.2",
          "percent": 0.2
        },
        "HK": {
          "Survey CC": "HK",
          "PctStronglyAgree": "0.05",
          "percent": 0.05
        },
        "HN": {
          "Survey CC": "HN",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "HR": {
          "Survey CC": "HR",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "HU": {
          "Survey CC": "HU",
          "PctStronglyAgree": "0.08",
          "percent": 0.08
        },
        "ID": {
          "Survey CC": "ID",
          "PctStronglyAgree": "0.22",
          "percent": 0.22
        },
        "IE": {
          "Survey CC": "IE",
          "PctStronglyAgree": "0.04",
          "percent": 0.04
        },
        "IN": {
          "Survey CC": "IN",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "IT": {
          "Survey CC": "IT",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "JO": {
          "Survey CC": "JO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "JP": {
          "Survey CC": "JP",
          "PctStronglyAgree": "0.03",
          "percent": 0.03
        },
        "KE": {
          "Survey CC": "KE",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "KG": {
          "Survey CC": "KG",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "KH": {
          "Survey CC": "KH",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "KR": {
          "Survey CC": "KR",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LB": {
          "Survey CC": "LB",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LR": {
          "Survey CC": "LR",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "LT": {
          "Survey CC": "LT",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LU": {
          "Survey CC": "LU",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LV": {
          "Survey CC": "LV",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "LY": {
          "Survey CC": "LY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MD": {
          "Survey CC": "MD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "ML": {
          "Survey CC": "ML",
          "PctStronglyAgree": "0.5",
          "percent": 0.5
        },
        "MM": {
          "Survey CC": "MM",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "MN": {
          "Survey CC": "MN",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MT": {
          "Survey CC": "MT",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "MW": {
          "Survey CC": "MW",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "MX": {
          "Survey CC": "MX",
          "PctStronglyAgree": "0.03",
          "percent": 0.03
        },
        "MY": {
          "Survey CC": "MY",
          "PctStronglyAgree": "0.03",
          "percent": 0.03
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NA": {
          "Survey CC": "NA",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NE": {
          "Survey CC": "NE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NG": {
          "Survey CC": "NG",
          "PctStronglyAgree": "0.03",
          "percent": 0.03
        },
        "NI": {
          "Survey CC": "NI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NL": {
          "Survey CC": "NL",
          "PctStronglyAgree": "0.01",
          "percent": 0.01
        },
        "NP": {
          "Survey CC": "NP",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "PA": {
          "Survey CC": "PA",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "PE": {
          "Survey CC": "PE",
          "PctStronglyAgree": "0.25",
          "percent": 0.25
        },
        "PH": {
          "Survey CC": "PH",
          "PctStronglyAgree": "0.05",
          "percent": 0.05
        },
        "PK": {
          "Survey CC": "PK",
          "PctStronglyAgree": "0.17",
          "percent": 0.17
        },
        "PL": {
          "Survey CC": "PL",
          "PctStronglyAgree": "0.04",
          "percent": 0.04
        },
        "PR": {
          "Survey CC": "PR",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "PS": {
          "Survey CC": "PS",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctStronglyAgree": "0.04",
          "percent": 0.04
        },
        "PY": {
          "Survey CC": "PY",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "RO": {
          "Survey CC": "RO",
          "PctStronglyAgree": "0.07",
          "percent": 0.07
        },
        "RS": {
          "Survey CC": "RS",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "RW": {
          "Survey CC": "RW",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SA": {
          "Survey CC": "SA",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        },
        "SD": {
          "Survey CC": "SD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctStronglyAgree": "0.03",
          "percent": 0.03
        },
        "SG": {
          "Survey CC": "SG",
          "PctStronglyAgree": "0.03",
          "percent": 0.03
        },
        "SI": {
          "Survey CC": "SI",
          "PctStronglyAgree": "0.12",
          "percent": 0.12
        },
        "SK": {
          "Survey CC": "SK",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SN": {
          "Survey CC": "SN",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "SO": {
          "Survey CC": "SO",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SR": {
          "Survey CC": "SR",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "ST": {
          "Survey CC": "ST",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SY": {
          "Survey CC": "SY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctStronglyAgree": "0.33",
          "percent": 0.33
        },
        "TD": {
          "Survey CC": "TD",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "TH": {
          "Survey CC": "TH",
          "PctStronglyAgree": "0.11",
          "percent": 0.11
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "TR": {
          "Survey CC": "TR",
          "PctStronglyAgree": "0.09",
          "percent": 0.09
        },
        "TT": {
          "Survey CC": "TT",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctStronglyAgree": "0.07",
          "percent": 0.07
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctStronglyAgree": "0.04",
          "percent": 0.04
        },
        "UA": {
          "Survey CC": "UA",
          "PctStronglyAgree": "0.07",
          "percent": 0.07
        },
        "UG": {
          "Survey CC": "UG",
          "PctStronglyAgree": "0.03",
          "percent": 0.03
        },
        "US": {
          "Survey CC": "US",
          "PctStronglyAgree": "0.05",
          "percent": 0.05
        },
        "UY": {
          "Survey CC": "UY",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "VE": {
          "Survey CC": "VE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "VI": {
          "Survey CC": "VI",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "VN": {
          "Survey CC": "VN",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "YE": {
          "Survey CC": "YE",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctStronglyAgree": "0.04",
          "percent": 0.04
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctStronglyAgree": "0",
          "percent": 0
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctStronglyAgree": "0",
          "percent": 0
        }
      },
      "ns": {
        "South": {
          "GlobalRegion": "South",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "North": {
          "GlobalRegion": "North",
          "PctStronglyAgree": "0.05",
          "percent": 0.05
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctStronglyAgree": "0.07",
          "percent": 0.07
        }
      },
      "r": {
        "Middle East": {
          "CH Region": "Middle East",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctStronglyAgree": "0.1",
          "percent": 0.1
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "Europe": {
          "CH Region": "Europe",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctStronglyAgree": "0.06",
          "percent": 0.06
        },
        "Africa": {
          "CH Region": "Africa",
          "PctStronglyAgree": "0.05",
          "percent": 0.05
        },
        "North America": {
          "CH Region": "North America",
          "PctStronglyAgree": "0.05",
          "percent": 0.05
        },
        "Total": {
          "CH Region": "Total",
          "PctStronglyAgree": "0.07",
          "percent": 0.07
        }
      }
    },
    "29.1": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctYes": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctYes": "0",
          "percent": 0
        },
        "AF": {
          "Survey CC": "AF",
          "PctYes": "0.5",
          "percent": 0.5
        },
        "AL": {
          "Survey CC": "AL",
          "PctYes": "1",
          "percent": 1
        },
        "AO": {
          "Survey CC": "AO",
          "PctYes": "1",
          "percent": 1
        },
        "AR": {
          "Survey CC": "AR",
          "PctYes": "0.55",
          "percent": 0.55
        },
        "AT": {
          "Survey CC": "AT",
          "PctYes": "0.56",
          "percent": 0.56
        },
        "AU": {
          "Survey CC": "AU",
          "PctYes": "0.33",
          "percent": 0.33
        },
        "BD": {
          "Survey CC": "BD",
          "PctYes": "0.6",
          "percent": 0.6
        },
        "BE": {
          "Survey CC": "BE",
          "PctYes": "0.51",
          "percent": 0.51
        },
        "BG": {
          "Survey CC": "BG",
          "PctYes": "0.21",
          "percent": 0.21
        },
        "BH": {
          "Survey CC": "BH",
          "PctYes": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctYes": "0.83",
          "percent": 0.83
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctYes": "0.33",
          "percent": 0.33
        },
        "BO": {
          "Survey CC": "BO",
          "PctYes": "0.17",
          "percent": 0.17
        },
        "BR": {
          "Survey CC": "BR",
          "PctYes": "0.35",
          "percent": 0.35
        },
        "BS": {
          "Survey CC": "BS",
          "PctYes": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctYes": "0.33",
          "percent": 0.33
        },
        "CA": {
          "Survey CC": "CA",
          "PctYes": "0.3",
          "percent": 0.3
        },
        "CD": {
          "Survey CC": "CD",
          "PctYes": "0.76",
          "percent": 0.76
        },
        "CF": {
          "Survey CC": "CF",
          "PctYes": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctYes": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctYes": "0.7",
          "percent": 0.7
        },
        "CI": {
          "Survey CC": "CI",
          "PctYes": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctYes": "0.35",
          "percent": 0.35
        },
        "CM": {
          "Survey CC": "CM",
          "PctYes": "0.79",
          "percent": 0.79
        },
        "CN": {
          "Survey CC": "CN",
          "PctYes": "0.75",
          "percent": 0.75
        },
        "CO": {
          "Survey CC": "CO",
          "PctYes": "0.38",
          "percent": 0.38
        },
        "CR": {
          "Survey CC": "CR",
          "PctYes": "0.75",
          "percent": 0.75
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctYes": "0.3",
          "percent": 0.3
        },
        "DE": {
          "Survey CC": "DE",
          "PctYes": "0.44",
          "percent": 0.44
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctYes": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctYes": "0.35",
          "percent": 0.35
        },
        "DO": {
          "Survey CC": "DO",
          "PctYes": "0.6",
          "percent": 0.6
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctYes": "0",
          "percent": 0
        },
        "EC": {
          "Survey CC": "EC",
          "PctYes": "0.3",
          "percent": 0.3
        },
        "EE": {
          "Survey CC": "EE",
          "PctYes": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctYes": "0.83",
          "percent": 0.83
        },
        "ES": {
          "Survey CC": "ES",
          "PctYes": "0.51",
          "percent": 0.51
        },
        "ET": {
          "Survey CC": "ET",
          "PctYes": "0.5",
          "percent": 0.5
        },
        "FI": {
          "Survey CC": "FI",
          "PctYes": "0.55",
          "percent": 0.55
        },
        "FR": {
          "Survey CC": "FR",
          "PctYes": "0.67",
          "percent": 0.67
        },
        "GA": {
          "Survey CC": "GA",
          "PctYes": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctYes": "0.37",
          "percent": 0.37
        },
        "GE": {
          "Survey CC": "GE",
          "PctYes": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctYes": "0.53",
          "percent": 0.53
        },
        "GN": {
          "Survey CC": "GN",
          "PctYes": "1",
          "percent": 1
        },
        "GR": {
          "Survey CC": "GR",
          "PctYes": "0.5",
          "percent": 0.5
        },
        "GT": {
          "Survey CC": "GT",
          "PctYes": "0.5",
          "percent": 0.5
        },
        "HK": {
          "Survey CC": "HK",
          "PctYes": "0.32",
          "percent": 0.32
        },
        "HN": {
          "Survey CC": "HN",
          "PctYes": "0.29",
          "percent": 0.29
        },
        "HR": {
          "Survey CC": "HR",
          "PctYes": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctYes": "0.33",
          "percent": 0.33
        },
        "HU": {
          "Survey CC": "HU",
          "PctYes": "0.27",
          "percent": 0.27
        },
        "ID": {
          "Survey CC": "ID",
          "PctYes": "0.67",
          "percent": 0.67
        },
        "IE": {
          "Survey CC": "IE",
          "PctYes": "0.41",
          "percent": 0.41
        },
        "IN": {
          "Survey CC": "IN",
          "PctYes": "0.72",
          "percent": 0.72
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctYes": "0.5",
          "percent": 0.5
        },
        "IT": {
          "Survey CC": "IT",
          "PctYes": "0.44",
          "percent": 0.44
        },
        "JO": {
          "Survey CC": "JO",
          "PctYes": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctYes": "0.37",
          "percent": 0.37
        },
        "KE": {
          "Survey CC": "KE",
          "PctYes": "0.46",
          "percent": 0.46
        },
        "KG": {
          "Survey CC": "KG",
          "PctYes": "1",
          "percent": 1
        },
        "KH": {
          "Survey CC": "KH",
          "PctYes": "0.67",
          "percent": 0.67
        },
        "KR": {
          "Survey CC": "KR",
          "PctYes": "0.52",
          "percent": 0.52
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctYes": "0.75",
          "percent": 0.75
        },
        "LB": {
          "Survey CC": "LB",
          "PctYes": "0",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctYes": "0.63",
          "percent": 0.63
        },
        "LR": {
          "Survey CC": "LR",
          "PctYes": "0.78",
          "percent": 0.78
        },
        "LT": {
          "Survey CC": "LT",
          "PctYes": "0.6",
          "percent": 0.6
        },
        "LU": {
          "Survey CC": "LU",
          "PctYes": "0.83",
          "percent": 0.83
        },
        "LV": {
          "Survey CC": "LV",
          "PctYes": "0.25",
          "percent": 0.25
        },
        "LY": {
          "Survey CC": "LY",
          "PctYes": "0",
          "percent": 0
        },
        "MD": {
          "Survey CC": "MD",
          "PctYes": "0.31",
          "percent": 0.31
        },
        "ML": {
          "Survey CC": "ML",
          "PctYes": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctYes": "0.83",
          "percent": 0.83
        },
        "MN": {
          "Survey CC": "MN",
          "PctYes": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctYes": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctYes": "0",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctYes": "1",
          "percent": 1
        },
        "MW": {
          "Survey CC": "MW",
          "PctYes": "0.75",
          "percent": 0.75
        },
        "MX": {
          "Survey CC": "MX",
          "PctYes": "0.45",
          "percent": 0.45
        },
        "MY": {
          "Survey CC": "MY",
          "PctYes": "0.48",
          "percent": 0.48
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctYes": "0",
          "percent": 0
        },
        "NA": {
          "Survey CC": "NA",
          "PctYes": "0.5",
          "percent": 0.5
        },
        "NE": {
          "Survey CC": "NE",
          "PctYes": "1",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctYes": "0.65",
          "percent": 0.65
        },
        "NI": {
          "Survey CC": "NI",
          "PctYes": "0.33",
          "percent": 0.33
        },
        "NL": {
          "Survey CC": "NL",
          "PctYes": "0.25",
          "percent": 0.25
        },
        "NP": {
          "Survey CC": "NP",
          "PctYes": "0.6",
          "percent": 0.6
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctYes": "0.22",
          "percent": 0.22
        },
        "PA": {
          "Survey CC": "PA",
          "PctYes": "0",
          "percent": 0
        },
        "PE": {
          "Survey CC": "PE",
          "PctYes": "0.88",
          "percent": 0.88
        },
        "PH": {
          "Survey CC": "PH",
          "PctYes": "0.47",
          "percent": 0.47
        },
        "PK": {
          "Survey CC": "PK",
          "PctYes": "0.58",
          "percent": 0.58
        },
        "PL": {
          "Survey CC": "PL",
          "PctYes": "0.44",
          "percent": 0.44
        },
        "PR": {
          "Survey CC": "PR",
          "PctYes": "0",
          "percent": 0
        },
        "PS": {
          "Survey CC": "PS",
          "PctYes": "0",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctYes": "0.37",
          "percent": 0.37
        },
        "PY": {
          "Survey CC": "PY",
          "PctYes": "0.67",
          "percent": 0.67
        },
        "RO": {
          "Survey CC": "RO",
          "PctYes": "0.43",
          "percent": 0.43
        },
        "RS": {
          "Survey CC": "RS",
          "PctYes": "1",
          "percent": 1
        },
        "RW": {
          "Survey CC": "RW",
          "PctYes": "0.75",
          "percent": 0.75
        },
        "SA": {
          "Survey CC": "SA",
          "PctYes": "0.45",
          "percent": 0.45
        },
        "SD": {
          "Survey CC": "SD",
          "PctYes": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctYes": "0.35",
          "percent": 0.35
        },
        "SG": {
          "Survey CC": "SG",
          "PctYes": "0.32",
          "percent": 0.32
        },
        "SI": {
          "Survey CC": "SI",
          "PctYes": "0.48",
          "percent": 0.48
        },
        "SK": {
          "Survey CC": "SK",
          "PctYes": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctYes": "0.69",
          "percent": 0.69
        },
        "SN": {
          "Survey CC": "SN",
          "PctYes": "0.33",
          "percent": 0.33
        },
        "SO": {
          "Survey CC": "SO",
          "PctYes": "0.5",
          "percent": 0.5
        },
        "SR": {
          "Survey CC": "SR",
          "PctYes": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctYes": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctYes": "0.25",
          "percent": 0.25
        },
        "SY": {
          "Survey CC": "SY",
          "PctYes": "0",
          "percent": 0
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctYes": "1",
          "percent": 1
        },
        "TD": {
          "Survey CC": "TD",
          "PctYes": "1",
          "percent": 1
        },
        "TG": {
          "Survey CC": "TG",
          "PctYes": "0.25",
          "percent": 0.25
        },
        "TH": {
          "Survey CC": "TH",
          "PctYes": "0.5",
          "percent": 0.5
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctYes": "0.5",
          "percent": 0.5
        },
        "TR": {
          "Survey CC": "TR",
          "PctYes": "0.42",
          "percent": 0.42
        },
        "TT": {
          "Survey CC": "TT",
          "PctYes": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctYes": "0.43",
          "percent": 0.43
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctYes": "0.54",
          "percent": 0.54
        },
        "UA": {
          "Survey CC": "UA",
          "PctYes": "0.44",
          "percent": 0.44
        },
        "UG": {
          "Survey CC": "UG",
          "PctYes": "0.6",
          "percent": 0.6
        },
        "US": {
          "Survey CC": "US",
          "PctYes": "0.25",
          "percent": 0.25
        },
        "UY": {
          "Survey CC": "UY",
          "PctYes": "0",
          "percent": 0
        },
        "VE": {
          "Survey CC": "VE",
          "PctYes": "0.33",
          "percent": 0.33
        },
        "VI": {
          "Survey CC": "VI",
          "PctYes": "0",
          "percent": 0
        },
        "VN": {
          "Survey CC": "VN",
          "PctYes": "0.8",
          "percent": 0.8
        },
        "YE": {
          "Survey CC": "YE",
          "PctYes": "0",
          "percent": 0
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctYes": "0.41",
          "percent": 0.41
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctYes": "0.11",
          "percent": 0.11
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctYes": "0.33",
          "percent": 0.33
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctYes": "0.34",
          "percent": 0.34
        },
        "South": {
          "GlobalRegion": "South",
          "PctYes": "0.53",
          "percent": 0.53
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctYes": "0.4",
          "percent": 0.4
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctYes": "0.55",
          "percent": 0.55
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctYes": "0.49",
          "percent": 0.49
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctYes": "0.63",
          "percent": 0.63
        },
        "Europe": {
          "CH Region": "Europe",
          "PctYes": "0.4",
          "percent": 0.4
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctYes": "0.4",
          "percent": 0.4
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctYes": "0.38",
          "percent": 0.38
        },
        "North America": {
          "CH Region": "North America",
          "PctYes": "0.26",
          "percent": 0.26
        },
        "Total": {
          "CH Region": "Total",
          "PctYes": "0.4",
          "percent": 0.4
        }
      }
    },
    "30.1": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "AE": {
          "Survey CC": "AE",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "AF": {
          "Survey CC": "AF",
          "PctCSOtrainingInPersonOrOnline": "0.75",
          "percent": 0.75
        },
        "AL": {
          "Survey CC": "AL",
          "PctCSOtrainingInPersonOrOnline": "0.33",
          "percent": 0.33
        },
        "AO": {
          "Survey CC": "AO",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "AR": {
          "Survey CC": "AR",
          "PctCSOtrainingInPersonOrOnline": "0.68",
          "percent": 0.68
        },
        "AT": {
          "Survey CC": "AT",
          "PctCSOtrainingInPersonOrOnline": "0.89",
          "percent": 0.89
        },
        "AU": {
          "Survey CC": "AU",
          "PctCSOtrainingInPersonOrOnline": "0.64",
          "percent": 0.64
        },
        "BD": {
          "Survey CC": "BD",
          "PctCSOtrainingInPersonOrOnline": "0.8",
          "percent": 0.8
        },
        "BE": {
          "Survey CC": "BE",
          "PctCSOtrainingInPersonOrOnline": "0.56",
          "percent": 0.56
        },
        "BG": {
          "Survey CC": "BG",
          "PctCSOtrainingInPersonOrOnline": "0.55",
          "percent": 0.55
        },
        "BH": {
          "Survey CC": "BH",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctCSOtrainingInPersonOrOnline": "0.83",
          "percent": 0.83
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctCSOtrainingInPersonOrOnline": "0.33",
          "percent": 0.33
        },
        "BO": {
          "Survey CC": "BO",
          "PctCSOtrainingInPersonOrOnline": "0.83",
          "percent": 0.83
        },
        "BR": {
          "Survey CC": "BR",
          "PctCSOtrainingInPersonOrOnline": "0.6",
          "percent": 0.6
        },
        "BS": {
          "Survey CC": "BS",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctCSOtrainingInPersonOrOnline": "0.67",
          "percent": 0.67
        },
        "CA": {
          "Survey CC": "CA",
          "PctCSOtrainingInPersonOrOnline": "0.47",
          "percent": 0.47
        },
        "CD": {
          "Survey CC": "CD",
          "PctCSOtrainingInPersonOrOnline": "0.59",
          "percent": 0.59
        },
        "CF": {
          "Survey CC": "CF",
          "PctCSOtrainingInPersonOrOnline": "0.33",
          "percent": 0.33
        },
        "CG": {
          "Survey CC": "CG",
          "PctCSOtrainingInPersonOrOnline": "0",
          "percent": 0
        },
        "CH": {
          "Survey CC": "CH",
          "PctCSOtrainingInPersonOrOnline": "0.7",
          "percent": 0.7
        },
        "CI": {
          "Survey CC": "CI",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctCSOtrainingInPersonOrOnline": "0.58",
          "percent": 0.58
        },
        "CM": {
          "Survey CC": "CM",
          "PctCSOtrainingInPersonOrOnline": "0.71",
          "percent": 0.71
        },
        "CN": {
          "Survey CC": "CN",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "CO": {
          "Survey CC": "CO",
          "PctCSOtrainingInPersonOrOnline": "0.53",
          "percent": 0.53
        },
        "CR": {
          "Survey CC": "CR",
          "PctCSOtrainingInPersonOrOnline": "0.75",
          "percent": 0.75
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctCSOtrainingInPersonOrOnline": "0.34",
          "percent": 0.34
        },
        "DE": {
          "Survey CC": "DE",
          "PctCSOtrainingInPersonOrOnline": "0.71",
          "percent": 0.71
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctCSOtrainingInPersonOrOnline": "0.71",
          "percent": 0.71
        },
        "DO": {
          "Survey CC": "DO",
          "PctCSOtrainingInPersonOrOnline": "0.6",
          "percent": 0.6
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "EC": {
          "Survey CC": "EC",
          "PctCSOtrainingInPersonOrOnline": "0.3",
          "percent": 0.3
        },
        "EE": {
          "Survey CC": "EE",
          "PctCSOtrainingInPersonOrOnline": "0.5",
          "percent": 0.5
        },
        "EG": {
          "Survey CC": "EG",
          "PctCSOtrainingInPersonOrOnline": "0.83",
          "percent": 0.83
        },
        "ES": {
          "Survey CC": "ES",
          "PctCSOtrainingInPersonOrOnline": "0.75",
          "percent": 0.75
        },
        "ET": {
          "Survey CC": "ET",
          "PctCSOtrainingInPersonOrOnline": "0.75",
          "percent": 0.75
        },
        "FI": {
          "Survey CC": "FI",
          "PctCSOtrainingInPersonOrOnline": "0.82",
          "percent": 0.82
        },
        "FR": {
          "Survey CC": "FR",
          "PctCSOtrainingInPersonOrOnline": "0.33",
          "percent": 0.33
        },
        "GA": {
          "Survey CC": "GA",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctCSOtrainingInPersonOrOnline": "0.61",
          "percent": 0.61
        },
        "GE": {
          "Survey CC": "GE",
          "PctCSOtrainingInPersonOrOnline": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctCSOtrainingInPersonOrOnline": "0.61",
          "percent": 0.61
        },
        "GN": {
          "Survey CC": "GN",
          "PctCSOtrainingInPersonOrOnline": "0.5",
          "percent": 0.5
        },
        "GR": {
          "Survey CC": "GR",
          "PctCSOtrainingInPersonOrOnline": "0.67",
          "percent": 0.67
        },
        "GT": {
          "Survey CC": "GT",
          "PctCSOtrainingInPersonOrOnline": "0.8",
          "percent": 0.8
        },
        "HK": {
          "Survey CC": "HK",
          "PctCSOtrainingInPersonOrOnline": "0.58",
          "percent": 0.58
        },
        "HN": {
          "Survey CC": "HN",
          "PctCSOtrainingInPersonOrOnline": "0.57",
          "percent": 0.57
        },
        "HR": {
          "Survey CC": "HR",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctCSOtrainingInPersonOrOnline": "0.33",
          "percent": 0.33
        },
        "HU": {
          "Survey CC": "HU",
          "PctCSOtrainingInPersonOrOnline": "0.53",
          "percent": 0.53
        },
        "ID": {
          "Survey CC": "ID",
          "PctCSOtrainingInPersonOrOnline": "0.74",
          "percent": 0.74
        },
        "IE": {
          "Survey CC": "IE",
          "PctCSOtrainingInPersonOrOnline": "0.65",
          "percent": 0.65
        },
        "IN": {
          "Survey CC": "IN",
          "PctCSOtrainingInPersonOrOnline": "0.74",
          "percent": 0.74
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctCSOtrainingInPersonOrOnline": "0.5",
          "percent": 0.5
        },
        "IT": {
          "Survey CC": "IT",
          "PctCSOtrainingInPersonOrOnline": "0.55",
          "percent": 0.55
        },
        "JO": {
          "Survey CC": "JO",
          "PctCSOtrainingInPersonOrOnline": "0",
          "percent": 0
        },
        "JP": {
          "Survey CC": "JP",
          "PctCSOtrainingInPersonOrOnline": "0.56",
          "percent": 0.56
        },
        "KE": {
          "Survey CC": "KE",
          "PctCSOtrainingInPersonOrOnline": "0.47",
          "percent": 0.47
        },
        "KG": {
          "Survey CC": "KG",
          "PctCSOtrainingInPersonOrOnline": "0.5",
          "percent": 0.5
        },
        "KH": {
          "Survey CC": "KH",
          "PctCSOtrainingInPersonOrOnline": "0.67",
          "percent": 0.67
        },
        "KR": {
          "Survey CC": "KR",
          "PctCSOtrainingInPersonOrOnline": "0.77",
          "percent": 0.77
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctCSOtrainingInPersonOrOnline": "0.5",
          "percent": 0.5
        },
        "LB": {
          "Survey CC": "LB",
          "PctCSOtrainingInPersonOrOnline": "0",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctCSOtrainingInPersonOrOnline": "0.88",
          "percent": 0.88
        },
        "LR": {
          "Survey CC": "LR",
          "PctCSOtrainingInPersonOrOnline": "0.78",
          "percent": 0.78
        },
        "LT": {
          "Survey CC": "LT",
          "PctCSOtrainingInPersonOrOnline": "0.8",
          "percent": 0.8
        },
        "LU": {
          "Survey CC": "LU",
          "PctCSOtrainingInPersonOrOnline": "0.67",
          "percent": 0.67
        },
        "LV": {
          "Survey CC": "LV",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "LY": {
          "Survey CC": "LY",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctCSOtrainingInPersonOrOnline": "0.41",
          "percent": 0.41
        },
        "ML": {
          "Survey CC": "ML",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctCSOtrainingInPersonOrOnline": "0.83",
          "percent": 0.83
        },
        "MN": {
          "Survey CC": "MN",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctCSOtrainingInPersonOrOnline": "0",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctCSOtrainingInPersonOrOnline": "0.5",
          "percent": 0.5
        },
        "MW": {
          "Survey CC": "MW",
          "PctCSOtrainingInPersonOrOnline": "0.75",
          "percent": 0.75
        },
        "MX": {
          "Survey CC": "MX",
          "PctCSOtrainingInPersonOrOnline": "0.53",
          "percent": 0.53
        },
        "MY": {
          "Survey CC": "MY",
          "PctCSOtrainingInPersonOrOnline": "0.67",
          "percent": 0.67
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctCSOtrainingInPersonOrOnline": "0",
          "percent": 0
        },
        "NA": {
          "Survey CC": "NA",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "NE": {
          "Survey CC": "NE",
          "PctCSOtrainingInPersonOrOnline": "0.5",
          "percent": 0.5
        },
        "NG": {
          "Survey CC": "NG",
          "PctCSOtrainingInPersonOrOnline": "0.82",
          "percent": 0.82
        },
        "NI": {
          "Survey CC": "NI",
          "PctCSOtrainingInPersonOrOnline": "0.33",
          "percent": 0.33
        },
        "NL": {
          "Survey CC": "NL",
          "PctCSOtrainingInPersonOrOnline": "0.56",
          "percent": 0.56
        },
        "NP": {
          "Survey CC": "NP",
          "PctCSOtrainingInPersonOrOnline": "0.4",
          "percent": 0.4
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctCSOtrainingInPersonOrOnline": "0.6",
          "percent": 0.6
        },
        "PA": {
          "Survey CC": "PA",
          "PctCSOtrainingInPersonOrOnline": "0",
          "percent": 0
        },
        "PE": {
          "Survey CC": "PE",
          "PctCSOtrainingInPersonOrOnline": "0.63",
          "percent": 0.63
        },
        "PH": {
          "Survey CC": "PH",
          "PctCSOtrainingInPersonOrOnline": "0.61",
          "percent": 0.61
        },
        "PK": {
          "Survey CC": "PK",
          "PctCSOtrainingInPersonOrOnline": "0.75",
          "percent": 0.75
        },
        "PL": {
          "Survey CC": "PL",
          "PctCSOtrainingInPersonOrOnline": "0.52",
          "percent": 0.52
        },
        "PR": {
          "Survey CC": "PR",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "PS": {
          "Survey CC": "PS",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctCSOtrainingInPersonOrOnline": "0.64",
          "percent": 0.64
        },
        "PY": {
          "Survey CC": "PY",
          "PctCSOtrainingInPersonOrOnline": "0.67",
          "percent": 0.67
        },
        "RO": {
          "Survey CC": "RO",
          "PctCSOtrainingInPersonOrOnline": "0.55",
          "percent": 0.55
        },
        "RS": {
          "Survey CC": "RS",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "RW": {
          "Survey CC": "RW",
          "PctCSOtrainingInPersonOrOnline": "0.75",
          "percent": 0.75
        },
        "SA": {
          "Survey CC": "SA",
          "PctCSOtrainingInPersonOrOnline": "0.83",
          "percent": 0.83
        },
        "SD": {
          "Survey CC": "SD",
          "PctCSOtrainingInPersonOrOnline": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctCSOtrainingInPersonOrOnline": "0.56",
          "percent": 0.56
        },
        "SG": {
          "Survey CC": "SG",
          "PctCSOtrainingInPersonOrOnline": "0.84",
          "percent": 0.84
        },
        "SI": {
          "Survey CC": "SI",
          "PctCSOtrainingInPersonOrOnline": "0.82",
          "percent": 0.82
        },
        "SK": {
          "Survey CC": "SK",
          "PctCSOtrainingInPersonOrOnline": "0",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctCSOtrainingInPersonOrOnline": "0.92",
          "percent": 0.92
        },
        "SN": {
          "Survey CC": "SN",
          "PctCSOtrainingInPersonOrOnline": "0.67",
          "percent": 0.67
        },
        "SO": {
          "Survey CC": "SO",
          "PctCSOtrainingInPersonOrOnline": "0.5",
          "percent": 0.5
        },
        "SR": {
          "Survey CC": "SR",
          "PctCSOtrainingInPersonOrOnline": "0",
          "percent": 0
        },
        "ST": {
          "Survey CC": "ST",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctCSOtrainingInPersonOrOnline": "0.25",
          "percent": 0.25
        },
        "SY": {
          "Survey CC": "SY",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "TD": {
          "Survey CC": "TD",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "TG": {
          "Survey CC": "TG",
          "PctCSOtrainingInPersonOrOnline": "0.5",
          "percent": 0.5
        },
        "TH": {
          "Survey CC": "TH",
          "PctCSOtrainingInPersonOrOnline": "0.61",
          "percent": 0.61
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctCSOtrainingInPersonOrOnline": "0.83",
          "percent": 0.83
        },
        "TR": {
          "Survey CC": "TR",
          "PctCSOtrainingInPersonOrOnline": "0.76",
          "percent": 0.76
        },
        "TT": {
          "Survey CC": "TT",
          "PctCSOtrainingInPersonOrOnline": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctCSOtrainingInPersonOrOnline": "0.65",
          "percent": 0.65
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctCSOtrainingInPersonOrOnline": "0.63",
          "percent": 0.63
        },
        "UA": {
          "Survey CC": "UA",
          "PctCSOtrainingInPersonOrOnline": "0.48",
          "percent": 0.48
        },
        "UG": {
          "Survey CC": "UG",
          "PctCSOtrainingInPersonOrOnline": "0.6",
          "percent": 0.6
        },
        "US": {
          "Survey CC": "US",
          "PctCSOtrainingInPersonOrOnline": "0.44",
          "percent": 0.44
        },
        "UY": {
          "Survey CC": "UY",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctCSOtrainingInPersonOrOnline": "0.33",
          "percent": 0.33
        },
        "VI": {
          "Survey CC": "VI",
          "PctCSOtrainingInPersonOrOnline": "0.5",
          "percent": 0.5
        },
        "VN": {
          "Survey CC": "VN",
          "PctCSOtrainingInPersonOrOnline": "1",
          "percent": 1
        },
        "YE": {
          "Survey CC": "YE",
          "PctCSOtrainingInPersonOrOnline": "0.6",
          "percent": 0.6
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctCSOtrainingInPersonOrOnline": "0.6",
          "percent": 0.6
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctCSOtrainingInPersonOrOnline": "0.33",
          "percent": 0.33
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctCSOtrainingInPersonOrOnline": "0.33",
          "percent": 0.33
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctChecked": "0.54",
          "percent": 0.54
        },
        "South": {
          "GlobalRegion": "South",
          "PctChecked": "0.63",
          "percent": 0.63
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctChecked": "0.57",
          "percent": 0.57
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctCSOtrainingInPersonOrOnline": "0.64",
          "percent": 0.64
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctCSOtrainingInPersonOrOnline": "0.66",
          "percent": 0.66
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctCSOtrainingInPersonOrOnline": "0.69",
          "percent": 0.69
        },
        "Europe": {
          "CH Region": "Europe",
          "PctCSOtrainingInPersonOrOnline": "0.58",
          "percent": 0.58
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctCSOtrainingInPersonOrOnline": "0.57",
          "percent": 0.57
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctCSOtrainingInPersonOrOnline": "0.77",
          "percent": 0.77
        },
        "North America": {
          "CH Region": "North America",
          "PctCSOtrainingInPersonOrOnline": "0.45",
          "percent": 0.45
        },
        "Total": {
          "CH Region": "Total",
          "PctCSOtrainingInPersonOrOnline": "0.57",
          "percent": 0.57
        }
      }
    },
    "31.1": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "AE": {
          "Survey CC": "AE",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "AF": {
          "Survey CC": "AF",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "AL": {
          "Survey CC": "AL",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "AO": {
          "Survey CC": "AO",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctDisagreeAndStronglyDisagree": "0.27",
          "percent": 0.27
        },
        "AT": {
          "Survey CC": "AT",
          "PctDisagreeAndStronglyDisagree": "0.11",
          "percent": 0.11
        },
        "AU": {
          "Survey CC": "AU",
          "PctDisagreeAndStronglyDisagree": "0.18",
          "percent": 0.18
        },
        "BD": {
          "Survey CC": "BD",
          "PctDisagreeAndStronglyDisagree": "0.2",
          "percent": 0.2
        },
        "BE": {
          "Survey CC": "BE",
          "PctDisagreeAndStronglyDisagree": "0.38",
          "percent": 0.38
        },
        "BG": {
          "Survey CC": "BG",
          "PctDisagreeAndStronglyDisagree": "0.47",
          "percent": 0.47
        },
        "BH": {
          "Survey CC": "BH",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "BO": {
          "Survey CC": "BO",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "BR": {
          "Survey CC": "BR",
          "PctDisagreeAndStronglyDisagree": "0.46",
          "percent": 0.46
        },
        "BS": {
          "Survey CC": "BS",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "CA": {
          "Survey CC": "CA",
          "PctDisagreeAndStronglyDisagree": "0.21",
          "percent": 0.21
        },
        "CD": {
          "Survey CC": "CD",
          "PctDisagreeAndStronglyDisagree": "0.41",
          "percent": 0.41
        },
        "CF": {
          "Survey CC": "CF",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "CH": {
          "Survey CC": "CH",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "CI": {
          "Survey CC": "CI",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctDisagreeAndStronglyDisagree": "0.36",
          "percent": 0.36
        },
        "CM": {
          "Survey CC": "CM",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "CN": {
          "Survey CC": "CN",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "CO": {
          "Survey CC": "CO",
          "PctDisagreeAndStronglyDisagree": "0.41",
          "percent": 0.41
        },
        "CR": {
          "Survey CC": "CR",
          "PctDisagreeAndStronglyDisagree": "0.25",
          "percent": 0.25
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctDisagreeAndStronglyDisagree": "0.27",
          "percent": 0.27
        },
        "DE": {
          "Survey CC": "DE",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctDisagreeAndStronglyDisagree": "0.18",
          "percent": 0.18
        },
        "DO": {
          "Survey CC": "DO",
          "PctDisagreeAndStronglyDisagree": "0.4",
          "percent": 0.4
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "EC": {
          "Survey CC": "EC",
          "PctDisagreeAndStronglyDisagree": "0.4",
          "percent": 0.4
        },
        "EE": {
          "Survey CC": "EE",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "EG": {
          "Survey CC": "EG",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "ES": {
          "Survey CC": "ES",
          "PctDisagreeAndStronglyDisagree": "0.25",
          "percent": 0.25
        },
        "ET": {
          "Survey CC": "ET",
          "PctDisagreeAndStronglyDisagree": "0.75",
          "percent": 0.75
        },
        "FI": {
          "Survey CC": "FI",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "FR": {
          "Survey CC": "FR",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "GA": {
          "Survey CC": "GA",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctDisagreeAndStronglyDisagree": "0.13",
          "percent": 0.13
        },
        "GE": {
          "Survey CC": "GE",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "GN": {
          "Survey CC": "GN",
          "PctDisagreeAndStronglyDisagree": "0.75",
          "percent": 0.75
        },
        "GR": {
          "Survey CC": "GR",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "GT": {
          "Survey CC": "GT",
          "PctDisagreeAndStronglyDisagree": "0.4",
          "percent": 0.4
        },
        "HK": {
          "Survey CC": "HK",
          "PctDisagreeAndStronglyDisagree": "0.47",
          "percent": 0.47
        },
        "HN": {
          "Survey CC": "HN",
          "PctDisagreeAndStronglyDisagree": "0.57",
          "percent": 0.57
        },
        "HR": {
          "Survey CC": "HR",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "HU": {
          "Survey CC": "HU",
          "PctDisagreeAndStronglyDisagree": "0.23",
          "percent": 0.23
        },
        "ID": {
          "Survey CC": "ID",
          "PctDisagreeAndStronglyDisagree": "0.19",
          "percent": 0.19
        },
        "IE": {
          "Survey CC": "IE",
          "PctDisagreeAndStronglyDisagree": "0.18",
          "percent": 0.18
        },
        "IN": {
          "Survey CC": "IN",
          "PctDisagreeAndStronglyDisagree": "0.34",
          "percent": 0.34
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "IT": {
          "Survey CC": "IT",
          "PctDisagreeAndStronglyDisagree": "0.37",
          "percent": 0.37
        },
        "JO": {
          "Survey CC": "JO",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctDisagreeAndStronglyDisagree": "0.2",
          "percent": 0.2
        },
        "KE": {
          "Survey CC": "KE",
          "PctDisagreeAndStronglyDisagree": "0.47",
          "percent": 0.47
        },
        "KG": {
          "Survey CC": "KG",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "KH": {
          "Survey CC": "KH",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "KR": {
          "Survey CC": "KR",
          "PctDisagreeAndStronglyDisagree": "0.52",
          "percent": 0.52
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctDisagreeAndStronglyDisagree": "0.75",
          "percent": 0.75
        },
        "LB": {
          "Survey CC": "LB",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "LR": {
          "Survey CC": "LR",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "LT": {
          "Survey CC": "LT",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "LU": {
          "Survey CC": "LU",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "LV": {
          "Survey CC": "LV",
          "PctDisagreeAndStronglyDisagree": "0.25",
          "percent": 0.25
        },
        "LY": {
          "Survey CC": "LY",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctDisagreeAndStronglyDisagree": "0.46",
          "percent": 0.46
        },
        "ML": {
          "Survey CC": "ML",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "MM": {
          "Survey CC": "MM",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "MN": {
          "Survey CC": "MN",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "MW": {
          "Survey CC": "MW",
          "PctDisagreeAndStronglyDisagree": "0.25",
          "percent": 0.25
        },
        "MX": {
          "Survey CC": "MX",
          "PctDisagreeAndStronglyDisagree": "0.42",
          "percent": 0.42
        },
        "MY": {
          "Survey CC": "MY",
          "PctDisagreeAndStronglyDisagree": "0.45",
          "percent": 0.45
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "NE": {
          "Survey CC": "NE",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "NG": {
          "Survey CC": "NG",
          "PctDisagreeAndStronglyDisagree": "0.34",
          "percent": 0.34
        },
        "NI": {
          "Survey CC": "NI",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "NL": {
          "Survey CC": "NL",
          "PctDisagreeAndStronglyDisagree": "0.19",
          "percent": 0.19
        },
        "NP": {
          "Survey CC": "NP",
          "PctDisagreeAndStronglyDisagree": "0.6",
          "percent": 0.6
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctDisagreeAndStronglyDisagree": "0.09",
          "percent": 0.09
        },
        "PA": {
          "Survey CC": "PA",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "PE": {
          "Survey CC": "PE",
          "PctDisagreeAndStronglyDisagree": "0.75",
          "percent": 0.75
        },
        "PH": {
          "Survey CC": "PH",
          "PctDisagreeAndStronglyDisagree": "0.48",
          "percent": 0.48
        },
        "PK": {
          "Survey CC": "PK",
          "PctDisagreeAndStronglyDisagree": "0.42",
          "percent": 0.42
        },
        "PL": {
          "Survey CC": "PL",
          "PctDisagreeAndStronglyDisagree": "0.45",
          "percent": 0.45
        },
        "PR": {
          "Survey CC": "PR",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "PS": {
          "Survey CC": "PS",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctDisagreeAndStronglyDisagree": "0.2",
          "percent": 0.2
        },
        "PY": {
          "Survey CC": "PY",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "RO": {
          "Survey CC": "RO",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "RS": {
          "Survey CC": "RS",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "RW": {
          "Survey CC": "RW",
          "PctDisagreeAndStronglyDisagree": "0.25",
          "percent": 0.25
        },
        "SA": {
          "Survey CC": "SA",
          "PctDisagreeAndStronglyDisagree": "0.38",
          "percent": 0.38
        },
        "SD": {
          "Survey CC": "SD",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctDisagreeAndStronglyDisagree": "0.12",
          "percent": 0.12
        },
        "SG": {
          "Survey CC": "SG",
          "PctDisagreeAndStronglyDisagree": "0.32",
          "percent": 0.32
        },
        "SI": {
          "Survey CC": "SI",
          "PctDisagreeAndStronglyDisagree": "0.15",
          "percent": 0.15
        },
        "SK": {
          "Survey CC": "SK",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctDisagreeAndStronglyDisagree": "0.54",
          "percent": 0.54
        },
        "SN": {
          "Survey CC": "SN",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "SO": {
          "Survey CC": "SO",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "SR": {
          "Survey CC": "SR",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "SY": {
          "Survey CC": "SY",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "TD": {
          "Survey CC": "TD",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctDisagreeAndStronglyDisagree": "0.75",
          "percent": 0.75
        },
        "TH": {
          "Survey CC": "TH",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "TR": {
          "Survey CC": "TR",
          "PctDisagreeAndStronglyDisagree": "0.51",
          "percent": 0.51
        },
        "TT": {
          "Survey CC": "TT",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "UA": {
          "Survey CC": "UA",
          "PctDisagreeAndStronglyDisagree": "0.36",
          "percent": 0.36
        },
        "UG": {
          "Survey CC": "UG",
          "PctDisagreeAndStronglyDisagree": "0.6",
          "percent": 0.6
        },
        "US": {
          "Survey CC": "US",
          "PctDisagreeAndStronglyDisagree": "0.2",
          "percent": 0.2
        },
        "UY": {
          "Survey CC": "UY",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "VI": {
          "Survey CC": "VI",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "VN": {
          "Survey CC": "VN",
          "PctDisagreeAndStronglyDisagree": "0.2",
          "percent": 0.2
        },
        "YE": {
          "Survey CC": "YE",
          "PctDisagreeAndStronglyDisagree": "0.4",
          "percent": 0.4
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctDisagreeAndStronglyDisagree": "0.27",
          "percent": 0.27
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctDisagreeAndStronglyDisagree": "0.22",
          "percent": 0.22
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctDisagreeAndStronglyDisagree": "0.26",
          "percent": 0.26
        },
        "South": {
          "GlobalRegion": "South",
          "PctDisagreeAndStronglyDisagree": "0.35",
          "percent": 0.35
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctDisagreeAndStronglyDisagree": "0.29",
          "percent": 0.29
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctDisagreeAndStronglyDisagree": "0.42",
          "percent": 0.42
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctDisagreeAndStronglyDisagree": "0.27",
          "percent": 0.27
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctDisagreeAndStronglyDisagree": "0.75",
          "percent": 0.75
        },
        "Europe": {
          "CH Region": "Europe",
          "PctDisagreeAndStronglyDisagree": "0.31",
          "percent": 0.31
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctDisagreeAndStronglyDisagree": "0.43",
          "percent": 0.43
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctDisagreeAndStronglyDisagree": "0.39",
          "percent": 0.39
        },
        "North America": {
          "CH Region": "North America",
          "PctDisagreeAndStronglyDisagree": "0.21",
          "percent": 0.21
        },
        "Total": {
          "CH Region": "Total",
          "PctDisagreeAndStronglyDisagree": "0.29",
          "percent": 0.29
        }
      }
    },
    "32.1": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "AE": {
          "Survey CC": "AE",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "AF": {
          "Survey CC": "AF",
          "PctDisagreeAndStronglyDisagree": "0.75",
          "percent": 0.75
        },
        "AL": {
          "Survey CC": "AL",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "AO": {
          "Survey CC": "AO",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctDisagreeAndStronglyDisagree": "0.41",
          "percent": 0.41
        },
        "AT": {
          "Survey CC": "AT",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "AU": {
          "Survey CC": "AU",
          "PctDisagreeAndStronglyDisagree": "0.46",
          "percent": 0.46
        },
        "BD": {
          "Survey CC": "BD",
          "PctDisagreeAndStronglyDisagree": "0.2",
          "percent": 0.2
        },
        "BE": {
          "Survey CC": "BE",
          "PctDisagreeAndStronglyDisagree": "0.55",
          "percent": 0.55
        },
        "BG": {
          "Survey CC": "BG",
          "PctDisagreeAndStronglyDisagree": "0.52",
          "percent": 0.52
        },
        "BH": {
          "Survey CC": "BH",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctDisagreeAndStronglyDisagree": "0.83",
          "percent": 0.83
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "BO": {
          "Survey CC": "BO",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "BR": {
          "Survey CC": "BR",
          "PctDisagreeAndStronglyDisagree": "0.59",
          "percent": 0.59
        },
        "BS": {
          "Survey CC": "BS",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "CA": {
          "Survey CC": "CA",
          "PctDisagreeAndStronglyDisagree": "0.42",
          "percent": 0.42
        },
        "CD": {
          "Survey CC": "CD",
          "PctDisagreeAndStronglyDisagree": "0.59",
          "percent": 0.59
        },
        "CF": {
          "Survey CC": "CF",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "CG": {
          "Survey CC": "CG",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "CH": {
          "Survey CC": "CH",
          "PctDisagreeAndStronglyDisagree": "0.2",
          "percent": 0.2
        },
        "CI": {
          "Survey CC": "CI",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctDisagreeAndStronglyDisagree": "0.4",
          "percent": 0.4
        },
        "CM": {
          "Survey CC": "CM",
          "PctDisagreeAndStronglyDisagree": "0.64",
          "percent": 0.64
        },
        "CN": {
          "Survey CC": "CN",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "CO": {
          "Survey CC": "CO",
          "PctDisagreeAndStronglyDisagree": "0.55",
          "percent": 0.55
        },
        "CR": {
          "Survey CC": "CR",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctDisagreeAndStronglyDisagree": "0.4",
          "percent": 0.4
        },
        "DE": {
          "Survey CC": "DE",
          "PctDisagreeAndStronglyDisagree": "0.45",
          "percent": 0.45
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctDisagreeAndStronglyDisagree": "0.18",
          "percent": 0.18
        },
        "DO": {
          "Survey CC": "DO",
          "PctDisagreeAndStronglyDisagree": "0.2",
          "percent": 0.2
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "EC": {
          "Survey CC": "EC",
          "PctDisagreeAndStronglyDisagree": "0.4",
          "percent": 0.4
        },
        "EE": {
          "Survey CC": "EE",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "EG": {
          "Survey CC": "EG",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "ES": {
          "Survey CC": "ES",
          "PctDisagreeAndStronglyDisagree": "0.49",
          "percent": 0.49
        },
        "ET": {
          "Survey CC": "ET",
          "PctDisagreeAndStronglyDisagree": "0.75",
          "percent": 0.75
        },
        "FI": {
          "Survey CC": "FI",
          "PctDisagreeAndStronglyDisagree": "0.18",
          "percent": 0.18
        },
        "FR": {
          "Survey CC": "FR",
          "PctDisagreeAndStronglyDisagree": "0.75",
          "percent": 0.75
        },
        "GA": {
          "Survey CC": "GA",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctDisagreeAndStronglyDisagree": "0.44",
          "percent": 0.44
        },
        "GE": {
          "Survey CC": "GE",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "GN": {
          "Survey CC": "GN",
          "PctDisagreeAndStronglyDisagree": "0.75",
          "percent": 0.75
        },
        "GR": {
          "Survey CC": "GR",
          "PctDisagreeAndStronglyDisagree": "0.17",
          "percent": 0.17
        },
        "GT": {
          "Survey CC": "GT",
          "PctDisagreeAndStronglyDisagree": "0.3",
          "percent": 0.3
        },
        "HK": {
          "Survey CC": "HK",
          "PctDisagreeAndStronglyDisagree": "0.37",
          "percent": 0.37
        },
        "HN": {
          "Survey CC": "HN",
          "PctDisagreeAndStronglyDisagree": "0.86",
          "percent": 0.86
        },
        "HR": {
          "Survey CC": "HR",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "HU": {
          "Survey CC": "HU",
          "PctDisagreeAndStronglyDisagree": "0.43",
          "percent": 0.43
        },
        "ID": {
          "Survey CC": "ID",
          "PctDisagreeAndStronglyDisagree": "0.18",
          "percent": 0.18
        },
        "IE": {
          "Survey CC": "IE",
          "PctDisagreeAndStronglyDisagree": "0.46",
          "percent": 0.46
        },
        "IN": {
          "Survey CC": "IN",
          "PctDisagreeAndStronglyDisagree": "0.53",
          "percent": 0.53
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "IT": {
          "Survey CC": "IT",
          "PctDisagreeAndStronglyDisagree": "0.45",
          "percent": 0.45
        },
        "JO": {
          "Survey CC": "JO",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctDisagreeAndStronglyDisagree": "0.26",
          "percent": 0.26
        },
        "KE": {
          "Survey CC": "KE",
          "PctDisagreeAndStronglyDisagree": "0.65",
          "percent": 0.65
        },
        "KG": {
          "Survey CC": "KG",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "KH": {
          "Survey CC": "KH",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "KR": {
          "Survey CC": "KR",
          "PctDisagreeAndStronglyDisagree": "0.59",
          "percent": 0.59
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctDisagreeAndStronglyDisagree": "0.75",
          "percent": 0.75
        },
        "LB": {
          "Survey CC": "LB",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctDisagreeAndStronglyDisagree": "0.25",
          "percent": 0.25
        },
        "LR": {
          "Survey CC": "LR",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "LT": {
          "Survey CC": "LT",
          "PctDisagreeAndStronglyDisagree": "0.2",
          "percent": 0.2
        },
        "LU": {
          "Survey CC": "LU",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "LV": {
          "Survey CC": "LV",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "LY": {
          "Survey CC": "LY",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctDisagreeAndStronglyDisagree": "0.49",
          "percent": 0.49
        },
        "ML": {
          "Survey CC": "ML",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "MM": {
          "Survey CC": "MM",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "MN": {
          "Survey CC": "MN",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "MW": {
          "Survey CC": "MW",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "MX": {
          "Survey CC": "MX",
          "PctDisagreeAndStronglyDisagree": "0.49",
          "percent": 0.49
        },
        "MY": {
          "Survey CC": "MY",
          "PctDisagreeAndStronglyDisagree": "0.55",
          "percent": 0.55
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "NE": {
          "Survey CC": "NE",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctDisagreeAndStronglyDisagree": "0.43",
          "percent": 0.43
        },
        "NI": {
          "Survey CC": "NI",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "NL": {
          "Survey CC": "NL",
          "PctDisagreeAndStronglyDisagree": "0.41",
          "percent": 0.41
        },
        "NP": {
          "Survey CC": "NP",
          "PctDisagreeAndStronglyDisagree": "0.6",
          "percent": 0.6
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctDisagreeAndStronglyDisagree": "0.3",
          "percent": 0.3
        },
        "PA": {
          "Survey CC": "PA",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "PE": {
          "Survey CC": "PE",
          "PctDisagreeAndStronglyDisagree": "0.75",
          "percent": 0.75
        },
        "PH": {
          "Survey CC": "PH",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "PK": {
          "Survey CC": "PK",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "PL": {
          "Survey CC": "PL",
          "PctDisagreeAndStronglyDisagree": "0.58",
          "percent": 0.58
        },
        "PR": {
          "Survey CC": "PR",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "PS": {
          "Survey CC": "PS",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctDisagreeAndStronglyDisagree": "0.32",
          "percent": 0.32
        },
        "PY": {
          "Survey CC": "PY",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "RO": {
          "Survey CC": "RO",
          "PctDisagreeAndStronglyDisagree": "0.41",
          "percent": 0.41
        },
        "RS": {
          "Survey CC": "RS",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "RW": {
          "Survey CC": "RW",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "SA": {
          "Survey CC": "SA",
          "PctDisagreeAndStronglyDisagree": "0.4",
          "percent": 0.4
        },
        "SD": {
          "Survey CC": "SD",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctDisagreeAndStronglyDisagree": "0.35",
          "percent": 0.35
        },
        "SG": {
          "Survey CC": "SG",
          "PctDisagreeAndStronglyDisagree": "0.35",
          "percent": 0.35
        },
        "SI": {
          "Survey CC": "SI",
          "PctDisagreeAndStronglyDisagree": "0.24",
          "percent": 0.24
        },
        "SK": {
          "Survey CC": "SK",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctDisagreeAndStronglyDisagree": "0.62",
          "percent": 0.62
        },
        "SN": {
          "Survey CC": "SN",
          "PctDisagreeAndStronglyDisagree": "0.67",
          "percent": 0.67
        },
        "SO": {
          "Survey CC": "SO",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "SR": {
          "Survey CC": "SR",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "SY": {
          "Survey CC": "SY",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "TD": {
          "Survey CC": "TD",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctDisagreeAndStronglyDisagree": "0.75",
          "percent": 0.75
        },
        "TH": {
          "Survey CC": "TH",
          "PctDisagreeAndStronglyDisagree": "0.56",
          "percent": 0.56
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "TR": {
          "Survey CC": "TR",
          "PctDisagreeAndStronglyDisagree": "0.68",
          "percent": 0.68
        },
        "TT": {
          "Survey CC": "TT",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctDisagreeAndStronglyDisagree": "0.55",
          "percent": 0.55
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctDisagreeAndStronglyDisagree": "0.54",
          "percent": 0.54
        },
        "UA": {
          "Survey CC": "UA",
          "PctDisagreeAndStronglyDisagree": "0.47",
          "percent": 0.47
        },
        "UG": {
          "Survey CC": "UG",
          "PctDisagreeAndStronglyDisagree": "0.7",
          "percent": 0.7
        },
        "US": {
          "Survey CC": "US",
          "PctDisagreeAndStronglyDisagree": "0.39",
          "percent": 0.39
        },
        "UY": {
          "Survey CC": "UY",
          "PctDisagreeAndStronglyDisagree": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "VI": {
          "Survey CC": "VI",
          "PctDisagreeAndStronglyDisagree": "0",
          "percent": 0
        },
        "VN": {
          "Survey CC": "VN",
          "PctDisagreeAndStronglyDisagree": "0.2",
          "percent": 0.2
        },
        "YE": {
          "Survey CC": "YE",
          "PctDisagreeAndStronglyDisagree": "0.4",
          "percent": 0.4
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctDisagreeAndStronglyDisagree": "0.48",
          "percent": 0.48
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctDisagreeAndStronglyDisagree": "0.5",
          "percent": 0.5
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctDisagreeAndStronglyDisagree": "0.41",
          "percent": 0.41
        },
        "South": {
          "GlobalRegion": "South",
          "PctDisagreeAndStronglyDisagree": "0.43",
          "percent": 0.43
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctDisagreeAndStronglyDisagree": "0.41",
          "percent": 0.41
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctDisagreeAndStronglyDisagree": "0.56",
          "percent": 0.56
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctDisagreeAndStronglyDisagree": "0.33",
          "percent": 0.33
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctDisagreeAndStronglyDisagree": "0.81",
          "percent": 0.81
        },
        "Europe": {
          "CH Region": "Europe",
          "PctDisagreeAndStronglyDisagree": "0.45",
          "percent": 0.45
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctDisagreeAndStronglyDisagree": "0.52",
          "percent": 0.52
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctDisagreeAndStronglyDisagree": "0.43",
          "percent": 0.43
        },
        "North America": {
          "CH Region": "North America",
          "PctDisagreeAndStronglyDisagree": "0.4",
          "percent": 0.4
        },
        "Total": {
          "CH Region": "Total",
          "PctDisagreeAndStronglyDisagree": "0.41",
          "percent": 0.41
        }
      }
    },
    "35.1": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "AF": {
          "Survey CC": "AF",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AL": {
          "Survey CC": "AL",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AO": {
          "Survey CC": "AO",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AT": {
          "Survey CC": "AT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "AU": {
          "Survey CC": "AU",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "BD": {
          "Survey CC": "BD",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BE": {
          "Survey CC": "BE",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "BG": {
          "Survey CC": "BG",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "BH": {
          "Survey CC": "BH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BO": {
          "Survey CC": "BO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BR": {
          "Survey CC": "BR",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "BS": {
          "Survey CC": "BS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CA": {
          "Survey CC": "CA",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "CD": {
          "Survey CC": "CD",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CF": {
          "Survey CC": "CF",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "CG": {
          "Survey CC": "CG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CH": {
          "Survey CC": "CH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CI": {
          "Survey CC": "CI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CL": {
          "Survey CC": "CL",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "CM": {
          "Survey CC": "CM",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CN": {
          "Survey CC": "CN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CO": {
          "Survey CC": "CO",
          "PctAgreeAndStronglyAgree": "0.98",
          "percent": 0.98
        },
        "CR": {
          "Survey CC": "CR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctAgreeAndStronglyAgree": "0.8",
          "percent": 0.8
        },
        "DE": {
          "Survey CC": "DE",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "DO": {
          "Survey CC": "DO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "EC": {
          "Survey CC": "EC",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "EE": {
          "Survey CC": "EE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "ES": {
          "Survey CC": "ES",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "ET": {
          "Survey CC": "ET",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "FI": {
          "Survey CC": "FI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "FR": {
          "Survey CC": "FR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GA": {
          "Survey CC": "GA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "GE": {
          "Survey CC": "GE",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "GH": {
          "Survey CC": "GH",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "GN": {
          "Survey CC": "GN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GR": {
          "Survey CC": "GR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GT": {
          "Survey CC": "GT",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "HK": {
          "Survey CC": "HK",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HN": {
          "Survey CC": "HN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HR": {
          "Survey CC": "HR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HU": {
          "Survey CC": "HU",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "ID": {
          "Survey CC": "ID",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "IE": {
          "Survey CC": "IE",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "IN": {
          "Survey CC": "IN",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "IT": {
          "Survey CC": "IT",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "JO": {
          "Survey CC": "JO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "JP": {
          "Survey CC": "JP",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "KE": {
          "Survey CC": "KE",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "KG": {
          "Survey CC": "KG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KH": {
          "Survey CC": "KH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KR": {
          "Survey CC": "KR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LB": {
          "Survey CC": "LB",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LK": {
          "Survey CC": "LK",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LR": {
          "Survey CC": "LR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LT": {
          "Survey CC": "LT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LU": {
          "Survey CC": "LU",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "LV": {
          "Survey CC": "LV",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "LY": {
          "Survey CC": "LY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctAgreeAndStronglyAgree": "0.85",
          "percent": 0.85
        },
        "ML": {
          "Survey CC": "ML",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MM": {
          "Survey CC": "MM",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MN": {
          "Survey CC": "MN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MW": {
          "Survey CC": "MW",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MX": {
          "Survey CC": "MX",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "MY": {
          "Survey CC": "MY",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NA": {
          "Survey CC": "NA",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "NE": {
          "Survey CC": "NE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "NI": {
          "Survey CC": "NI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NL": {
          "Survey CC": "NL",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "NP": {
          "Survey CC": "NP",
          "PctAgreeAndStronglyAgree": "0.8",
          "percent": 0.8
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctAgreeAndStronglyAgree": "0.86",
          "percent": 0.86
        },
        "PA": {
          "Survey CC": "PA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "PH": {
          "Survey CC": "PH",
          "PctAgreeAndStronglyAgree": "0.81",
          "percent": 0.81
        },
        "PK": {
          "Survey CC": "PK",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PL": {
          "Survey CC": "PL",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "PR": {
          "Survey CC": "PR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PS": {
          "Survey CC": "PS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "PY": {
          "Survey CC": "PY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "RO": {
          "Survey CC": "RO",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "RS": {
          "Survey CC": "RS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "RW": {
          "Survey CC": "RW",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "SA": {
          "Survey CC": "SA",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "SD": {
          "Survey CC": "SD",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SE": {
          "Survey CC": "SE",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "SG": {
          "Survey CC": "SG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SI": {
          "Survey CC": "SI",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "SK": {
          "Survey CC": "SK",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "SN": {
          "Survey CC": "SN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SO": {
          "Survey CC": "SO",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SR": {
          "Survey CC": "SR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SV": {
          "Survey CC": "SV",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SY": {
          "Survey CC": "SY",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TD": {
          "Survey CC": "TD",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TG": {
          "Survey CC": "TG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TH": {
          "Survey CC": "TH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TR": {
          "Survey CC": "TR",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "TT": {
          "Survey CC": "TT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TW": {
          "Survey CC": "TW",
          "PctAgreeAndStronglyAgree": "0.96",
          "percent": 0.96
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "UA": {
          "Survey CC": "UA",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "UG": {
          "Survey CC": "UG",
          "PctAgreeAndStronglyAgree": "0.87",
          "percent": 0.87
        },
        "US": {
          "Survey CC": "US",
          "PctAgreeAndStronglyAgree": "0.82",
          "percent": 0.82
        },
        "UY": {
          "Survey CC": "UY",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "VI": {
          "Survey CC": "VI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "VN": {
          "Survey CC": "VN",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "YE": {
          "Survey CC": "YE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "South": {
          "GlobalRegion": "South",
          "PctAgreeAndStronglyAgree": "0.94",
          "percent": 0.94
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "Europe": {
          "CH Region": "Europe",
          "PctAgreeAndStronglyAgree": "0.92",
          "percent": 0.92
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctAgreeAndStronglyAgree": "0.97",
          "percent": 0.97
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctAgreeAndStronglyAgree": "0.9",
          "percent": 0.9
        },
        "North America": {
          "CH Region": "North America",
          "PctAgreeAndStronglyAgree": "0.84",
          "percent": 0.84
        },
        "Total": {
          "CH Region": "Total",
          "PctAgreeAndStronglyAgree": "0.91",
          "percent": 0.91
        }
      }
    },
    "35.2": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctAgreeAndStronglyAgree": "0.33",
          "percent": 0.33
        },
        "AF": {
          "Survey CC": "AF",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "AL": {
          "Survey CC": "AL",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "AO": {
          "Survey CC": "AO",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctAgreeAndStronglyAgree": "0.59",
          "percent": 0.59
        },
        "AT": {
          "Survey CC": "AT",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "AU": {
          "Survey CC": "AU",
          "PctAgreeAndStronglyAgree": "0.66",
          "percent": 0.66
        },
        "BD": {
          "Survey CC": "BD",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "BE": {
          "Survey CC": "BE",
          "PctAgreeAndStronglyAgree": "0.64",
          "percent": 0.64
        },
        "BG": {
          "Survey CC": "BG",
          "PctAgreeAndStronglyAgree": "0.56",
          "percent": 0.56
        },
        "BH": {
          "Survey CC": "BH",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BI": {
          "Survey CC": "BI",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BO": {
          "Survey CC": "BO",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "BR": {
          "Survey CC": "BR",
          "PctAgreeAndStronglyAgree": "0.52",
          "percent": 0.52
        },
        "BS": {
          "Survey CC": "BS",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "CA": {
          "Survey CC": "CA",
          "PctAgreeAndStronglyAgree": "0.56",
          "percent": 0.56
        },
        "CD": {
          "Survey CC": "CD",
          "PctAgreeAndStronglyAgree": "0.82",
          "percent": 0.82
        },
        "CF": {
          "Survey CC": "CF",
          "PctAgreeAndStronglyAgree": "0.33",
          "percent": 0.33
        },
        "CG": {
          "Survey CC": "CG",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "CH": {
          "Survey CC": "CH",
          "PctAgreeAndStronglyAgree": "0.7",
          "percent": 0.7
        },
        "CI": {
          "Survey CC": "CI",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "CL": {
          "Survey CC": "CL",
          "PctAgreeAndStronglyAgree": "0.45",
          "percent": 0.45
        },
        "CM": {
          "Survey CC": "CM",
          "PctAgreeAndStronglyAgree": "0.57",
          "percent": 0.57
        },
        "CN": {
          "Survey CC": "CN",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "CO": {
          "Survey CC": "CO",
          "PctAgreeAndStronglyAgree": "0.59",
          "percent": 0.59
        },
        "CR": {
          "Survey CC": "CR",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctAgreeAndStronglyAgree": "0.57",
          "percent": 0.57
        },
        "DE": {
          "Survey CC": "DE",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "DO": {
          "Survey CC": "DO",
          "PctAgreeAndStronglyAgree": "0.6",
          "percent": 0.6
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "EC": {
          "Survey CC": "EC",
          "PctAgreeAndStronglyAgree": "0.2",
          "percent": 0.2
        },
        "EE": {
          "Survey CC": "EE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "ES": {
          "Survey CC": "ES",
          "PctAgreeAndStronglyAgree": "0.59",
          "percent": 0.59
        },
        "ET": {
          "Survey CC": "ET",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "FI": {
          "Survey CC": "FI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "FR": {
          "Survey CC": "FR",
          "PctAgreeAndStronglyAgree": "0.58",
          "percent": 0.58
        },
        "GA": {
          "Survey CC": "GA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctAgreeAndStronglyAgree": "0.68",
          "percent": 0.68
        },
        "GE": {
          "Survey CC": "GE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "GH": {
          "Survey CC": "GH",
          "PctAgreeAndStronglyAgree": "0.53",
          "percent": 0.53
        },
        "GN": {
          "Survey CC": "GN",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "GR": {
          "Survey CC": "GR",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "GT": {
          "Survey CC": "GT",
          "PctAgreeAndStronglyAgree": "0.3",
          "percent": 0.3
        },
        "HK": {
          "Survey CC": "HK",
          "PctAgreeAndStronglyAgree": "0.58",
          "percent": 0.58
        },
        "HN": {
          "Survey CC": "HN",
          "PctAgreeAndStronglyAgree": "0.43",
          "percent": 0.43
        },
        "HR": {
          "Survey CC": "HR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "HU": {
          "Survey CC": "HU",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "ID": {
          "Survey CC": "ID",
          "PctAgreeAndStronglyAgree": "0.84",
          "percent": 0.84
        },
        "IE": {
          "Survey CC": "IE",
          "PctAgreeAndStronglyAgree": "0.59",
          "percent": 0.59
        },
        "IN": {
          "Survey CC": "IN",
          "PctAgreeAndStronglyAgree": "0.71",
          "percent": 0.71
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctAgreeAndStronglyAgree": "0.25",
          "percent": 0.25
        },
        "IT": {
          "Survey CC": "IT",
          "PctAgreeAndStronglyAgree": "0.62",
          "percent": 0.62
        },
        "JO": {
          "Survey CC": "JO",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctAgreeAndStronglyAgree": "0.47",
          "percent": 0.47
        },
        "KE": {
          "Survey CC": "KE",
          "PctAgreeAndStronglyAgree": "0.6",
          "percent": 0.6
        },
        "KG": {
          "Survey CC": "KG",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "KH": {
          "Survey CC": "KH",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "KR": {
          "Survey CC": "KR",
          "PctAgreeAndStronglyAgree": "0.56",
          "percent": 0.56
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "LB": {
          "Survey CC": "LB",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "LR": {
          "Survey CC": "LR",
          "PctAgreeAndStronglyAgree": "0.89",
          "percent": 0.89
        },
        "LT": {
          "Survey CC": "LT",
          "PctAgreeAndStronglyAgree": "0.4",
          "percent": 0.4
        },
        "LU": {
          "Survey CC": "LU",
          "PctAgreeAndStronglyAgree": "0.83",
          "percent": 0.83
        },
        "LV": {
          "Survey CC": "LV",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "LY": {
          "Survey CC": "LY",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "MD": {
          "Survey CC": "MD",
          "PctAgreeAndStronglyAgree": "0.38",
          "percent": 0.38
        },
        "ML": {
          "Survey CC": "ML",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "MM": {
          "Survey CC": "MM",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "MN": {
          "Survey CC": "MN",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MT": {
          "Survey CC": "MT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "MW": {
          "Survey CC": "MW",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "MX": {
          "Survey CC": "MX",
          "PctAgreeAndStronglyAgree": "0.47",
          "percent": 0.47
        },
        "MY": {
          "Survey CC": "MY",
          "PctAgreeAndStronglyAgree": "0.55",
          "percent": 0.55
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "NA": {
          "Survey CC": "NA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NE": {
          "Survey CC": "NE",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "NG": {
          "Survey CC": "NG",
          "PctAgreeAndStronglyAgree": "0.42",
          "percent": 0.42
        },
        "NI": {
          "Survey CC": "NI",
          "PctAgreeAndStronglyAgree": "0.33",
          "percent": 0.33
        },
        "NL": {
          "Survey CC": "NL",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "NP": {
          "Survey CC": "NP",
          "PctAgreeAndStronglyAgree": "0.8",
          "percent": 0.8
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "PA": {
          "Survey CC": "PA",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "PH": {
          "Survey CC": "PH",
          "PctAgreeAndStronglyAgree": "0.58",
          "percent": 0.58
        },
        "PK": {
          "Survey CC": "PK",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "PL": {
          "Survey CC": "PL",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "PR": {
          "Survey CC": "PR",
          "PctAgreeAndStronglyAgree": "0.33",
          "percent": 0.33
        },
        "PS": {
          "Survey CC": "PS",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "PT": {
          "Survey CC": "PT",
          "PctAgreeAndStronglyAgree": "0.72",
          "percent": 0.72
        },
        "PY": {
          "Survey CC": "PY",
          "PctAgreeAndStronglyAgree": "0.33",
          "percent": 0.33
        },
        "RO": {
          "Survey CC": "RO",
          "PctAgreeAndStronglyAgree": "0.45",
          "percent": 0.45
        },
        "RS": {
          "Survey CC": "RS",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "RW": {
          "Survey CC": "RW",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "SA": {
          "Survey CC": "SA",
          "PctAgreeAndStronglyAgree": "0.93",
          "percent": 0.93
        },
        "SD": {
          "Survey CC": "SD",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "SE": {
          "Survey CC": "SE",
          "PctAgreeAndStronglyAgree": "0.88",
          "percent": 0.88
        },
        "SG": {
          "Survey CC": "SG",
          "PctAgreeAndStronglyAgree": "0.68",
          "percent": 0.68
        },
        "SI": {
          "Survey CC": "SI",
          "PctAgreeAndStronglyAgree": "0.79",
          "percent": 0.79
        },
        "SK": {
          "Survey CC": "SK",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctAgreeAndStronglyAgree": "0.69",
          "percent": 0.69
        },
        "SN": {
          "Survey CC": "SN",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "SO": {
          "Survey CC": "SO",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "SR": {
          "Survey CC": "SR",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "SY": {
          "Survey CC": "SY",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctAgreeAndStronglyAgree": "0.33",
          "percent": 0.33
        },
        "TD": {
          "Survey CC": "TD",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "TG": {
          "Survey CC": "TG",
          "PctAgreeAndStronglyAgree": "0.25",
          "percent": 0.25
        },
        "TH": {
          "Survey CC": "TH",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "TR": {
          "Survey CC": "TR",
          "PctAgreeAndStronglyAgree": "0.36",
          "percent": 0.36
        },
        "TT": {
          "Survey CC": "TT",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "TW": {
          "Survey CC": "TW",
          "PctAgreeAndStronglyAgree": "0.67",
          "percent": 0.67
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctAgreeAndStronglyAgree": "0.33",
          "percent": 0.33
        },
        "UA": {
          "Survey CC": "UA",
          "PctAgreeAndStronglyAgree": "0.68",
          "percent": 0.68
        },
        "UG": {
          "Survey CC": "UG",
          "PctAgreeAndStronglyAgree": "0.57",
          "percent": 0.57
        },
        "US": {
          "Survey CC": "US",
          "PctAgreeAndStronglyAgree": "0.48",
          "percent": 0.48
        },
        "UY": {
          "Survey CC": "UY",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        },
        "VE": {
          "Survey CC": "VE",
          "PctAgreeAndStronglyAgree": "0.33",
          "percent": 0.33
        },
        "VI": {
          "Survey CC": "VI",
          "PctAgreeAndStronglyAgree": "1",
          "percent": 1
        },
        "VN": {
          "Survey CC": "VN",
          "PctAgreeAndStronglyAgree": "0.8",
          "percent": 0.8
        },
        "YE": {
          "Survey CC": "YE",
          "PctAgreeAndStronglyAgree": "0.4",
          "percent": 0.4
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctAgreeAndStronglyAgree": "0.57",
          "percent": 0.57
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctAgreeAndStronglyAgree": "0.56",
          "percent": 0.56
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctAgreeAndStronglyAgree": "0",
          "percent": 0
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctAgreeAndStronglyAgree": "0.56",
          "percent": 0.56
        },
        "South": {
          "GlobalRegion": "South",
          "PctAgreeAndStronglyAgree": "0.65",
          "percent": 0.65
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctAgreeAndStronglyAgree": "0.59",
          "percent": 0.59
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctAgreeAndStronglyAgree": "0.54",
          "percent": 0.54
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctAgreeAndStronglyAgree": "0.65",
          "percent": 0.65
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "Europe": {
          "CH Region": "Europe",
          "PctAgreeAndStronglyAgree": "0.64",
          "percent": 0.64
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctAgreeAndStronglyAgree": "0.75",
          "percent": 0.75
        },
        "North America": {
          "CH Region": "North America",
          "PctAgreeAndStronglyAgree": "0.5",
          "percent": 0.5
        },
        "Total": {
          "CH Region": "Total",
          "PctAgreeAndStronglyAgree": "0.59",
          "percent": 0.59
        }
      }
    },
    "38.1": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctChecked": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctChecked": "0",
          "percent": 0
        },
        "AF": {
          "Survey CC": "AF",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "AL": {
          "Survey CC": "AL",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "AO": {
          "Survey CC": "AO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctChecked": "0.68",
          "percent": 0.68
        },
        "AT": {
          "Survey CC": "AT",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "AU": {
          "Survey CC": "AU",
          "PctChecked": "0.64",
          "percent": 0.64
        },
        "BD": {
          "Survey CC": "BD",
          "PctChecked": "0",
          "percent": 0
        },
        "BE": {
          "Survey CC": "BE",
          "PctChecked": "0.35",
          "percent": 0.35
        },
        "BG": {
          "Survey CC": "BG",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "BH": {
          "Survey CC": "BH",
          "PctChecked": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "BO": {
          "Survey CC": "BO",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "BR": {
          "Survey CC": "BR",
          "PctChecked": "0.49",
          "percent": 0.49
        },
        "BS": {
          "Survey CC": "BS",
          "PctChecked": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctChecked": "0",
          "percent": 0
        },
        "CA": {
          "Survey CC": "CA",
          "PctChecked": "0.62",
          "percent": 0.62
        },
        "CD": {
          "Survey CC": "CD",
          "PctChecked": "0.53",
          "percent": 0.53
        },
        "CF": {
          "Survey CC": "CF",
          "PctChecked": "0",
          "percent": 0
        },
        "CG": {
          "Survey CC": "CG",
          "PctChecked": "0",
          "percent": 0
        },
        "CH": {
          "Survey CC": "CH",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "CI": {
          "Survey CC": "CI",
          "PctChecked": "0",
          "percent": 0
        },
        "CL": {
          "Survey CC": "CL",
          "PctChecked": "0.55",
          "percent": 0.55
        },
        "CM": {
          "Survey CC": "CM",
          "PctChecked": "0.29",
          "percent": 0.29
        },
        "CN": {
          "Survey CC": "CN",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "CO": {
          "Survey CC": "CO",
          "PctChecked": "0.52",
          "percent": 0.52
        },
        "CR": {
          "Survey CC": "CR",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "DE": {
          "Survey CC": "DE",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctChecked": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctChecked": "0.59",
          "percent": 0.59
        },
        "DO": {
          "Survey CC": "DO",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "EC": {
          "Survey CC": "EC",
          "PctChecked": "0.1",
          "percent": 0.1
        },
        "EE": {
          "Survey CC": "EE",
          "PctChecked": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctChecked": "0",
          "percent": 0
        },
        "ES": {
          "Survey CC": "ES",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "ET": {
          "Survey CC": "ET",
          "PctChecked": "0",
          "percent": 0
        },
        "FI": {
          "Survey CC": "FI",
          "PctChecked": "0.55",
          "percent": 0.55
        },
        "FR": {
          "Survey CC": "FR",
          "PctChecked": "0.58",
          "percent": 0.58
        },
        "GA": {
          "Survey CC": "GA",
          "PctChecked": "1",
          "percent": 1
        },
        "GB": {
          "Survey CC": "GB",
          "PctChecked": "0.69",
          "percent": 0.69
        },
        "GE": {
          "Survey CC": "GE",
          "PctChecked": "1",
          "percent": 1
        },
        "GH": {
          "Survey CC": "GH",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "GN": {
          "Survey CC": "GN",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "GR": {
          "Survey CC": "GR",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "GT": {
          "Survey CC": "GT",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "HK": {
          "Survey CC": "HK",
          "PctChecked": "0.37",
          "percent": 0.37
        },
        "HN": {
          "Survey CC": "HN",
          "PctChecked": "0.57",
          "percent": 0.57
        },
        "HR": {
          "Survey CC": "HR",
          "PctChecked": "0",
          "percent": 0
        },
        "HT": {
          "Survey CC": "HT",
          "PctChecked": "0",
          "percent": 0
        },
        "HU": {
          "Survey CC": "HU",
          "PctChecked": "0.77",
          "percent": 0.77
        },
        "ID": {
          "Survey CC": "ID",
          "PctChecked": "0.27",
          "percent": 0.27
        },
        "IE": {
          "Survey CC": "IE",
          "PctChecked": "0.54",
          "percent": 0.54
        },
        "IN": {
          "Survey CC": "IN",
          "PctChecked": "0.23",
          "percent": 0.23
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "IT": {
          "Survey CC": "IT",
          "PctChecked": "0.39",
          "percent": 0.39
        },
        "JO": {
          "Survey CC": "JO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "JP": {
          "Survey CC": "JP",
          "PctChecked": "0.11",
          "percent": 0.11
        },
        "KE": {
          "Survey CC": "KE",
          "PctChecked": "0.34",
          "percent": 0.34
        },
        "KG": {
          "Survey CC": "KG",
          "PctChecked": "0",
          "percent": 0
        },
        "KH": {
          "Survey CC": "KH",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "KR": {
          "Survey CC": "KR",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "LB": {
          "Survey CC": "LB",
          "PctChecked": "0",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctChecked": "0",
          "percent": 0
        },
        "LR": {
          "Survey CC": "LR",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "LT": {
          "Survey CC": "LT",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "LU": {
          "Survey CC": "LU",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "LV": {
          "Survey CC": "LV",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "LY": {
          "Survey CC": "LY",
          "PctChecked": "0",
          "percent": 0
        },
        "MD": {
          "Survey CC": "MD",
          "PctChecked": "0.36",
          "percent": 0.36
        },
        "ML": {
          "Survey CC": "ML",
          "PctChecked": "0",
          "percent": 0
        },
        "MM": {
          "Survey CC": "MM",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "MN": {
          "Survey CC": "MN",
          "PctChecked": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctChecked": "0",
          "percent": 0
        },
        "MT": {
          "Survey CC": "MT",
          "PctChecked": "0",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctChecked": "0",
          "percent": 0
        },
        "MW": {
          "Survey CC": "MW",
          "PctChecked": "0",
          "percent": 0
        },
        "MX": {
          "Survey CC": "MX",
          "PctChecked": "0.48",
          "percent": 0.48
        },
        "MY": {
          "Survey CC": "MY",
          "PctChecked": "0.27",
          "percent": 0.27
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NA": {
          "Survey CC": "NA",
          "PctChecked": "0",
          "percent": 0
        },
        "NE": {
          "Survey CC": "NE",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NG": {
          "Survey CC": "NG",
          "PctChecked": "0.38",
          "percent": 0.38
        },
        "NI": {
          "Survey CC": "NI",
          "PctChecked": "0",
          "percent": 0
        },
        "NL": {
          "Survey CC": "NL",
          "PctChecked": "0.45",
          "percent": 0.45
        },
        "NP": {
          "Survey CC": "NP",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctChecked": "0.68",
          "percent": 0.68
        },
        "PA": {
          "Survey CC": "PA",
          "PctChecked": "0",
          "percent": 0
        },
        "PE": {
          "Survey CC": "PE",
          "PctChecked": "0",
          "percent": 0
        },
        "PH": {
          "Survey CC": "PH",
          "PctChecked": "0.32",
          "percent": 0.32
        },
        "PK": {
          "Survey CC": "PK",
          "PctChecked": "0.08",
          "percent": 0.08
        },
        "PL": {
          "Survey CC": "PL",
          "PctChecked": "0.35",
          "percent": 0.35
        },
        "PR": {
          "Survey CC": "PR",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "PS": {
          "Survey CC": "PS",
          "PctChecked": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctChecked": "0.52",
          "percent": 0.52
        },
        "PY": {
          "Survey CC": "PY",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "RO": {
          "Survey CC": "RO",
          "PctChecked": "0.42",
          "percent": 0.42
        },
        "RS": {
          "Survey CC": "RS",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "RW": {
          "Survey CC": "RW",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SA": {
          "Survey CC": "SA",
          "PctChecked": "0.29",
          "percent": 0.29
        },
        "SD": {
          "Survey CC": "SD",
          "PctChecked": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SG": {
          "Survey CC": "SG",
          "PctChecked": "0.48",
          "percent": 0.48
        },
        "SI": {
          "Survey CC": "SI",
          "PctChecked": "0.45",
          "percent": 0.45
        },
        "SK": {
          "Survey CC": "SK",
          "PctChecked": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctChecked": "0.46",
          "percent": 0.46
        },
        "SN": {
          "Survey CC": "SN",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "SO": {
          "Survey CC": "SO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SR": {
          "Survey CC": "SR",
          "PctChecked": "0",
          "percent": 0
        },
        "ST": {
          "Survey CC": "ST",
          "PctChecked": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "SY": {
          "Survey CC": "SY",
          "PctChecked": "0",
          "percent": 0
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "TD": {
          "Survey CC": "TD",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "TG": {
          "Survey CC": "TG",
          "PctChecked": "0",
          "percent": 0
        },
        "TH": {
          "Survey CC": "TH",
          "PctChecked": "0.11",
          "percent": 0.11
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "TR": {
          "Survey CC": "TR",
          "PctChecked": "0.58",
          "percent": 0.58
        },
        "TT": {
          "Survey CC": "TT",
          "PctChecked": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctChecked": "0.35",
          "percent": 0.35
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "UA": {
          "Survey CC": "UA",
          "PctChecked": "0.51",
          "percent": 0.51
        },
        "UG": {
          "Survey CC": "UG",
          "PctChecked": "0.27",
          "percent": 0.27
        },
        "US": {
          "Survey CC": "US",
          "PctChecked": "0.66",
          "percent": 0.66
        },
        "UY": {
          "Survey CC": "UY",
          "PctChecked": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "VI": {
          "Survey CC": "VI",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "VN": {
          "Survey CC": "VN",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "YE": {
          "Survey CC": "YE",
          "PctChecked": "0",
          "percent": 0
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctChecked": "0.32",
          "percent": 0.32
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctChecked": "0.44",
          "percent": 0.44
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctChecked": "0.33",
          "percent": 0.33
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctChecked": "0.51",
          "percent": 0.51
        },
        "South": {
          "GlobalRegion": "South",
          "PctChecked": "0.36",
          "percent": 0.36
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctChecked": "0.46",
          "percent": 0.46
        }
      },
      "r": {
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctChecked": "0.26",
          "percent": 0.26
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctChecked": "0.28",
          "percent": 0.28
        },
        "Africa": {
          "CH Region": "Africa",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctChecked": "0.47",
          "percent": 0.47
        },
        "Europe": {
          "CH Region": "Europe",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "North America": {
          "CH Region": "North America",
          "PctChecked": "0.65",
          "percent": 0.65
        },
        "Total": {
          "CH Region": "Total",
          "PctChecked": "0.46",
          "percent": 0.46
        }
      }
    },
    "38.2": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctChecked": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctChecked": "0",
          "percent": 0
        },
        "AF": {
          "Survey CC": "AF",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "AL": {
          "Survey CC": "AL",
          "PctChecked": "1",
          "percent": 1
        },
        "AO": {
          "Survey CC": "AO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctChecked": "0.68",
          "percent": 0.68
        },
        "AT": {
          "Survey CC": "AT",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "AU": {
          "Survey CC": "AU",
          "PctChecked": "0.79",
          "percent": 0.79
        },
        "BD": {
          "Survey CC": "BD",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "BE": {
          "Survey CC": "BE",
          "PctChecked": "0.55",
          "percent": 0.55
        },
        "BG": {
          "Survey CC": "BG",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "BH": {
          "Survey CC": "BH",
          "PctChecked": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctChecked": "0",
          "percent": 0
        },
        "BO": {
          "Survey CC": "BO",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "BR": {
          "Survey CC": "BR",
          "PctChecked": "0.63",
          "percent": 0.63
        },
        "BS": {
          "Survey CC": "BS",
          "PctChecked": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "CA": {
          "Survey CC": "CA",
          "PctChecked": "0.73",
          "percent": 0.73
        },
        "CD": {
          "Survey CC": "CD",
          "PctChecked": "0.24",
          "percent": 0.24
        },
        "CF": {
          "Survey CC": "CF",
          "PctChecked": "0",
          "percent": 0
        },
        "CG": {
          "Survey CC": "CG",
          "PctChecked": "0",
          "percent": 0
        },
        "CH": {
          "Survey CC": "CH",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "CI": {
          "Survey CC": "CI",
          "PctChecked": "0",
          "percent": 0
        },
        "CL": {
          "Survey CC": "CL",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "CM": {
          "Survey CC": "CM",
          "PctChecked": "0.14",
          "percent": 0.14
        },
        "CN": {
          "Survey CC": "CN",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "CO": {
          "Survey CC": "CO",
          "PctChecked": "0.55",
          "percent": 0.55
        },
        "CR": {
          "Survey CC": "CR",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctChecked": "0.69",
          "percent": 0.69
        },
        "DE": {
          "Survey CC": "DE",
          "PctChecked": "0.55",
          "percent": 0.55
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctChecked": "1",
          "percent": 1
        },
        "DK": {
          "Survey CC": "DK",
          "PctChecked": "0.94",
          "percent": 0.94
        },
        "DO": {
          "Survey CC": "DO",
          "PctChecked": "0.8",
          "percent": 0.8
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctChecked": "0",
          "percent": 0
        },
        "EC": {
          "Survey CC": "EC",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "EE": {
          "Survey CC": "EE",
          "PctChecked": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "ES": {
          "Survey CC": "ES",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "ET": {
          "Survey CC": "ET",
          "PctChecked": "0",
          "percent": 0
        },
        "FI": {
          "Survey CC": "FI",
          "PctChecked": "0.91",
          "percent": 0.91
        },
        "FR": {
          "Survey CC": "FR",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "GA": {
          "Survey CC": "GA",
          "PctChecked": "0",
          "percent": 0
        },
        "GB": {
          "Survey CC": "GB",
          "PctChecked": "0.76",
          "percent": 0.76
        },
        "GE": {
          "Survey CC": "GE",
          "PctChecked": "1",
          "percent": 1
        },
        "GH": {
          "Survey CC": "GH",
          "PctChecked": "0.19",
          "percent": 0.19
        },
        "GN": {
          "Survey CC": "GN",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "GR": {
          "Survey CC": "GR",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "GT": {
          "Survey CC": "GT",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "HK": {
          "Survey CC": "HK",
          "PctChecked": "0.53",
          "percent": 0.53
        },
        "HN": {
          "Survey CC": "HN",
          "PctChecked": "0.86",
          "percent": 0.86
        },
        "HR": {
          "Survey CC": "HR",
          "PctChecked": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctChecked": "0",
          "percent": 0
        },
        "HU": {
          "Survey CC": "HU",
          "PctChecked": "0.87",
          "percent": 0.87
        },
        "ID": {
          "Survey CC": "ID",
          "PctChecked": "0.36",
          "percent": 0.36
        },
        "IE": {
          "Survey CC": "IE",
          "PctChecked": "0.72",
          "percent": 0.72
        },
        "IN": {
          "Survey CC": "IN",
          "PctChecked": "0.38",
          "percent": 0.38
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctChecked": "0",
          "percent": 0
        },
        "IT": {
          "Survey CC": "IT",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "JO": {
          "Survey CC": "JO",
          "PctChecked": "1",
          "percent": 1
        },
        "JP": {
          "Survey CC": "JP",
          "PctChecked": "0.24",
          "percent": 0.24
        },
        "KE": {
          "Survey CC": "KE",
          "PctChecked": "0.37",
          "percent": 0.37
        },
        "KG": {
          "Survey CC": "KG",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "KH": {
          "Survey CC": "KH",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "KR": {
          "Survey CC": "KR",
          "PctChecked": "0.34",
          "percent": 0.34
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "LB": {
          "Survey CC": "LB",
          "PctChecked": "0",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "LR": {
          "Survey CC": "LR",
          "PctChecked": "0.22",
          "percent": 0.22
        },
        "LT": {
          "Survey CC": "LT",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "LU": {
          "Survey CC": "LU",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "LV": {
          "Survey CC": "LV",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "LY": {
          "Survey CC": "LY",
          "PctChecked": "0",
          "percent": 0
        },
        "MD": {
          "Survey CC": "MD",
          "PctChecked": "0.54",
          "percent": 0.54
        },
        "ML": {
          "Survey CC": "ML",
          "PctChecked": "0",
          "percent": 0
        },
        "MM": {
          "Survey CC": "MM",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "MN": {
          "Survey CC": "MN",
          "PctChecked": "1",
          "percent": 1
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctChecked": "0",
          "percent": 0
        },
        "MT": {
          "Survey CC": "MT",
          "PctChecked": "1",
          "percent": 1
        },
        "MU": {
          "Survey CC": "MU",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "MW": {
          "Survey CC": "MW",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "MX": {
          "Survey CC": "MX",
          "PctChecked": "0.61",
          "percent": 0.61
        },
        "MY": {
          "Survey CC": "MY",
          "PctChecked": "0.45",
          "percent": 0.45
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NA": {
          "Survey CC": "NA",
          "PctChecked": "0",
          "percent": 0
        },
        "NE": {
          "Survey CC": "NE",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NG": {
          "Survey CC": "NG",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "NI": {
          "Survey CC": "NI",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "NL": {
          "Survey CC": "NL",
          "PctChecked": "0.61",
          "percent": 0.61
        },
        "NP": {
          "Survey CC": "NP",
          "PctChecked": "0",
          "percent": 0
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctChecked": "0.85",
          "percent": 0.85
        },
        "PA": {
          "Survey CC": "PA",
          "PctChecked": "0",
          "percent": 0
        },
        "PE": {
          "Survey CC": "PE",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "PH": {
          "Survey CC": "PH",
          "PctChecked": "0.48",
          "percent": 0.48
        },
        "PK": {
          "Survey CC": "PK",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "PL": {
          "Survey CC": "PL",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "PR": {
          "Survey CC": "PR",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "PS": {
          "Survey CC": "PS",
          "PctChecked": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctChecked": "0.62",
          "percent": 0.62
        },
        "PY": {
          "Survey CC": "PY",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "RO": {
          "Survey CC": "RO",
          "PctChecked": "0.48",
          "percent": 0.48
        },
        "RS": {
          "Survey CC": "RS",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "RW": {
          "Survey CC": "RW",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SA": {
          "Survey CC": "SA",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SD": {
          "Survey CC": "SD",
          "PctChecked": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctChecked": "0.79",
          "percent": 0.79
        },
        "SG": {
          "Survey CC": "SG",
          "PctChecked": "0.71",
          "percent": 0.71
        },
        "SI": {
          "Survey CC": "SI",
          "PctChecked": "0.76",
          "percent": 0.76
        },
        "SK": {
          "Survey CC": "SK",
          "PctChecked": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctChecked": "0.38",
          "percent": 0.38
        },
        "SN": {
          "Survey CC": "SN",
          "PctChecked": "0",
          "percent": 0
        },
        "SO": {
          "Survey CC": "SO",
          "PctChecked": "0",
          "percent": 0
        },
        "SR": {
          "Survey CC": "SR",
          "PctChecked": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctChecked": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "SY": {
          "Survey CC": "SY",
          "PctChecked": "0",
          "percent": 0
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctChecked": "0",
          "percent": 0
        },
        "TD": {
          "Survey CC": "TD",
          "PctChecked": "0",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctChecked": "0",
          "percent": 0
        },
        "TH": {
          "Survey CC": "TH",
          "PctChecked": "0.39",
          "percent": 0.39
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "TR": {
          "Survey CC": "TR",
          "PctChecked": "0.51",
          "percent": 0.51
        },
        "TT": {
          "Survey CC": "TT",
          "PctChecked": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctChecked": "0.58",
          "percent": 0.58
        },
        "UA": {
          "Survey CC": "UA",
          "PctChecked": "0.73",
          "percent": 0.73
        },
        "UG": {
          "Survey CC": "UG",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "US": {
          "Survey CC": "US",
          "PctChecked": "0.73",
          "percent": 0.73
        },
        "UY": {
          "Survey CC": "UY",
          "PctChecked": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "VI": {
          "Survey CC": "VI",
          "PctChecked": "1",
          "percent": 1
        },
        "VN": {
          "Survey CC": "VN",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "YE": {
          "Survey CC": "YE",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctChecked": "0.22",
          "percent": 0.22
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctChecked": "0.17",
          "percent": 0.17
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctChecked": "0.61",
          "percent": 0.61
        },
        "South": {
          "GlobalRegion": "South",
          "PctChecked": "0.48",
          "percent": 0.48
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctChecked": "0.57",
          "percent": 0.57
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctChecked": "0.32",
          "percent": 0.32
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctChecked": "0.61",
          "percent": 0.61
        },
        "Europe": {
          "CH Region": "Europe",
          "PctChecked": "0.62",
          "percent": 0.62
        },
        "North America": {
          "CH Region": "North America",
          "PctChecked": "0.73",
          "percent": 0.73
        },
        "Total": {
          "CH Region": "Total",
          "PctChecked": "0.57",
          "percent": 0.57
        }
      }
    },
    "38.3": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctChecked": "0",
          "percent": 0
        },
        "AE": {
          "Survey CC": "AE",
          "PctChecked": "0",
          "percent": 0
        },
        "AF": {
          "Survey CC": "AF",
          "PctChecked": "0",
          "percent": 0
        },
        "AL": {
          "Survey CC": "AL",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "AO": {
          "Survey CC": "AO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "AR": {
          "Survey CC": "AR",
          "PctChecked": "0.73",
          "percent": 0.73
        },
        "AT": {
          "Survey CC": "AT",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "AU": {
          "Survey CC": "AU",
          "PctChecked": "0.76",
          "percent": 0.76
        },
        "BD": {
          "Survey CC": "BD",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "BE": {
          "Survey CC": "BE",
          "PctChecked": "0.64",
          "percent": 0.64
        },
        "BG": {
          "Survey CC": "BG",
          "PctChecked": "0.46",
          "percent": 0.46
        },
        "BH": {
          "Survey CC": "BH",
          "PctChecked": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctChecked": "0",
          "percent": 0
        },
        "BO": {
          "Survey CC": "BO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "BR": {
          "Survey CC": "BR",
          "PctChecked": "0.68",
          "percent": 0.68
        },
        "BS": {
          "Survey CC": "BS",
          "PctChecked": "1",
          "percent": 1
        },
        "BW": {
          "Survey CC": "BW",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "CA": {
          "Survey CC": "CA",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "CD": {
          "Survey CC": "CD",
          "PctChecked": "0.24",
          "percent": 0.24
        },
        "CF": {
          "Survey CC": "CF",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "CG": {
          "Survey CC": "CG",
          "PctChecked": "0",
          "percent": 0
        },
        "CH": {
          "Survey CC": "CH",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "CI": {
          "Survey CC": "CI",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "CL": {
          "Survey CC": "CL",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "CM": {
          "Survey CC": "CM",
          "PctChecked": "0.21",
          "percent": 0.21
        },
        "CN": {
          "Survey CC": "CN",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "CO": {
          "Survey CC": "CO",
          "PctChecked": "0.55",
          "percent": 0.55
        },
        "CR": {
          "Survey CC": "CR",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctChecked": "0.84",
          "percent": 0.84
        },
        "DE": {
          "Survey CC": "DE",
          "PctChecked": "0.63",
          "percent": 0.63
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctChecked": "0",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctChecked": "0.88",
          "percent": 0.88
        },
        "DO": {
          "Survey CC": "DO",
          "PctChecked": "0.8",
          "percent": 0.8
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctChecked": "0",
          "percent": 0
        },
        "EC": {
          "Survey CC": "EC",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "EE": {
          "Survey CC": "EE",
          "PctChecked": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "ES": {
          "Survey CC": "ES",
          "PctChecked": "0.69",
          "percent": 0.69
        },
        "ET": {
          "Survey CC": "ET",
          "PctChecked": "0",
          "percent": 0
        },
        "FI": {
          "Survey CC": "FI",
          "PctChecked": "0.91",
          "percent": 0.91
        },
        "FR": {
          "Survey CC": "FR",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "GA": {
          "Survey CC": "GA",
          "PctChecked": "0",
          "percent": 0
        },
        "GB": {
          "Survey CC": "GB",
          "PctChecked": "0.72",
          "percent": 0.72
        },
        "GE": {
          "Survey CC": "GE",
          "PctChecked": "1",
          "percent": 1
        },
        "GH": {
          "Survey CC": "GH",
          "PctChecked": "0.22",
          "percent": 0.22
        },
        "GN": {
          "Survey CC": "GN",
          "PctChecked": "0",
          "percent": 0
        },
        "GR": {
          "Survey CC": "GR",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "GT": {
          "Survey CC": "GT",
          "PctChecked": "0.7",
          "percent": 0.7
        },
        "HK": {
          "Survey CC": "HK",
          "PctChecked": "0.37",
          "percent": 0.37
        },
        "HN": {
          "Survey CC": "HN",
          "PctChecked": "0.86",
          "percent": 0.86
        },
        "HR": {
          "Survey CC": "HR",
          "PctChecked": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "HU": {
          "Survey CC": "HU",
          "PctChecked": "0.89",
          "percent": 0.89
        },
        "ID": {
          "Survey CC": "ID",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "IE": {
          "Survey CC": "IE",
          "PctChecked": "0.7",
          "percent": 0.7
        },
        "IN": {
          "Survey CC": "IN",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctChecked": "0",
          "percent": 0
        },
        "IT": {
          "Survey CC": "IT",
          "PctChecked": "0.61",
          "percent": 0.61
        },
        "JO": {
          "Survey CC": "JO",
          "PctChecked": "1",
          "percent": 1
        },
        "JP": {
          "Survey CC": "JP",
          "PctChecked": "0.22",
          "percent": 0.22
        },
        "KE": {
          "Survey CC": "KE",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "KG": {
          "Survey CC": "KG",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "KH": {
          "Survey CC": "KH",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "KR": {
          "Survey CC": "KR",
          "PctChecked": "0.34",
          "percent": 0.34
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "LB": {
          "Survey CC": "LB",
          "PctChecked": "0",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctChecked": "0.13",
          "percent": 0.13
        },
        "LR": {
          "Survey CC": "LR",
          "PctChecked": "0.11",
          "percent": 0.11
        },
        "LT": {
          "Survey CC": "LT",
          "PctChecked": "0.8",
          "percent": 0.8
        },
        "LU": {
          "Survey CC": "LU",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "LV": {
          "Survey CC": "LV",
          "PctChecked": "1",
          "percent": 1
        },
        "LY": {
          "Survey CC": "LY",
          "PctChecked": "1",
          "percent": 1
        },
        "MD": {
          "Survey CC": "MD",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "ML": {
          "Survey CC": "ML",
          "PctChecked": "0",
          "percent": 0
        },
        "MM": {
          "Survey CC": "MM",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "MN": {
          "Survey CC": "MN",
          "PctChecked": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctChecked": "0",
          "percent": 0
        },
        "MT": {
          "Survey CC": "MT",
          "PctChecked": "0",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctChecked": "0",
          "percent": 0
        },
        "MW": {
          "Survey CC": "MW",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "MX": {
          "Survey CC": "MX",
          "PctChecked": "0.61",
          "percent": 0.61
        },
        "MY": {
          "Survey CC": "MY",
          "PctChecked": "0.39",
          "percent": 0.39
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NA": {
          "Survey CC": "NA",
          "PctChecked": "0",
          "percent": 0
        },
        "NE": {
          "Survey CC": "NE",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NG": {
          "Survey CC": "NG",
          "PctChecked": "0.28",
          "percent": 0.28
        },
        "NI": {
          "Survey CC": "NI",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "NL": {
          "Survey CC": "NL",
          "PctChecked": "0.65",
          "percent": 0.65
        },
        "NP": {
          "Survey CC": "NP",
          "PctChecked": "0",
          "percent": 0
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctChecked": "0.85",
          "percent": 0.85
        },
        "PA": {
          "Survey CC": "PA",
          "PctChecked": "0",
          "percent": 0
        },
        "PE": {
          "Survey CC": "PE",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "PH": {
          "Survey CC": "PH",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "PK": {
          "Survey CC": "PK",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "PL": {
          "Survey CC": "PL",
          "PctChecked": "0.63",
          "percent": 0.63
        },
        "PR": {
          "Survey CC": "PR",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "PS": {
          "Survey CC": "PS",
          "PctChecked": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctChecked": "0.63",
          "percent": 0.63
        },
        "PY": {
          "Survey CC": "PY",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "RO": {
          "Survey CC": "RO",
          "PctChecked": "0.58",
          "percent": 0.58
        },
        "RS": {
          "Survey CC": "RS",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "RW": {
          "Survey CC": "RW",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "SA": {
          "Survey CC": "SA",
          "PctChecked": "0.48",
          "percent": 0.48
        },
        "SD": {
          "Survey CC": "SD",
          "PctChecked": "0",
          "percent": 0
        },
        "SE": {
          "Survey CC": "SE",
          "PctChecked": "0.79",
          "percent": 0.79
        },
        "SG": {
          "Survey CC": "SG",
          "PctChecked": "0.65",
          "percent": 0.65
        },
        "SI": {
          "Survey CC": "SI",
          "PctChecked": "0.79",
          "percent": 0.79
        },
        "SK": {
          "Survey CC": "SK",
          "PctChecked": "1",
          "percent": 1
        },
        "SL": {
          "Survey CC": "SL",
          "PctChecked": "0.15",
          "percent": 0.15
        },
        "SN": {
          "Survey CC": "SN",
          "PctChecked": "0",
          "percent": 0
        },
        "SO": {
          "Survey CC": "SO",
          "PctChecked": "0",
          "percent": 0
        },
        "SR": {
          "Survey CC": "SR",
          "PctChecked": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctChecked": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "SY": {
          "Survey CC": "SY",
          "PctChecked": "0",
          "percent": 0
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "TD": {
          "Survey CC": "TD",
          "PctChecked": "0",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctChecked": "0",
          "percent": 0
        },
        "TH": {
          "Survey CC": "TH",
          "PctChecked": "0.44",
          "percent": 0.44
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "TR": {
          "Survey CC": "TR",
          "PctChecked": "0.53",
          "percent": 0.53
        },
        "TT": {
          "Survey CC": "TT",
          "PctChecked": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctChecked": "0.29",
          "percent": 0.29
        },
        "UA": {
          "Survey CC": "UA",
          "PctChecked": "0.71",
          "percent": 0.71
        },
        "UG": {
          "Survey CC": "UG",
          "PctChecked": "0.2",
          "percent": 0.2
        },
        "US": {
          "Survey CC": "US",
          "PctChecked": "0.7",
          "percent": 0.7
        },
        "UY": {
          "Survey CC": "UY",
          "PctChecked": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "VI": {
          "Survey CC": "VI",
          "PctChecked": "1",
          "percent": 1
        },
        "VN": {
          "Survey CC": "VN",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "YE": {
          "Survey CC": "YE",
          "PctChecked": "0",
          "percent": 0
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctChecked": "0.42",
          "percent": 0.42
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctChecked": "0.5",
          "percent": 0.5
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctChecked": "0.62",
          "percent": 0.62
        },
        "South": {
          "GlobalRegion": "South",
          "PctChecked": "0.46",
          "percent": 0.46
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctChecked": "0.57",
          "percent": 0.57
        }
      },
      "r": {
        "Africa": {
          "CH Region": "Africa",
          "PctChecked": "0.28",
          "percent": 0.28
        },
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctChecked": "0.37",
          "percent": 0.37
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctChecked": "0.38",
          "percent": 0.38
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctChecked": "0.65",
          "percent": 0.65
        },
        "Europe": {
          "CH Region": "Europe",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "North America": {
          "CH Region": "North America",
          "PctChecked": "0.71",
          "percent": 0.71
        },
        "Total": {
          "CH Region": "Total",
          "PctChecked": "0.57",
          "percent": 0.57
        }
      }
    },
    "38.4": {
      "std": {
        "AD": {
          "Survey CC": "AD",
          "PctChecked": "1",
          "percent": 1
        },
        "AE": {
          "Survey CC": "AE",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "AF": {
          "Survey CC": "AF",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "AL": {
          "Survey CC": "AL",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "AO": {
          "Survey CC": "AO",
          "PctChecked": "1",
          "percent": 1
        },
        "AR": {
          "Survey CC": "AR",
          "PctChecked": "0.64",
          "percent": 0.64
        },
        "AT": {
          "Survey CC": "AT",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "AU": {
          "Survey CC": "AU",
          "PctChecked": "0.78",
          "percent": 0.78
        },
        "BD": {
          "Survey CC": "BD",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "BE": {
          "Survey CC": "BE",
          "PctChecked": "0.57",
          "percent": 0.57
        },
        "BG": {
          "Survey CC": "BG",
          "PctChecked": "0.36",
          "percent": 0.36
        },
        "BH": {
          "Survey CC": "BH",
          "PctChecked": "0",
          "percent": 0
        },
        "BI": {
          "Survey CC": "BI",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "BJ": {
          "Survey CC": "BJ",
          "PctChecked": "0",
          "percent": 0
        },
        "BO": {
          "Survey CC": "BO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "BR": {
          "Survey CC": "BR",
          "PctChecked": "0.63",
          "percent": 0.63
        },
        "BS": {
          "Survey CC": "BS",
          "PctChecked": "0",
          "percent": 0
        },
        "BW": {
          "Survey CC": "BW",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "CA": {
          "Survey CC": "CA",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "CD": {
          "Survey CC": "CD",
          "PctChecked": "0.29",
          "percent": 0.29
        },
        "CF": {
          "Survey CC": "CF",
          "PctChecked": "0",
          "percent": 0
        },
        "CG": {
          "Survey CC": "CG",
          "PctChecked": "0",
          "percent": 0
        },
        "CH": {
          "Survey CC": "CH",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "CI": {
          "Survey CC": "CI",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "CL": {
          "Survey CC": "CL",
          "PctChecked": "0.71",
          "percent": 0.71
        },
        "CM": {
          "Survey CC": "CM",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "CN": {
          "Survey CC": "CN",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "CO": {
          "Survey CC": "CO",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "CR": {
          "Survey CC": "CR",
          "PctChecked": "1",
          "percent": 1
        },
        "CZ": {
          "Survey CC": "CZ",
          "PctChecked": "0.58",
          "percent": 0.58
        },
        "DE": {
          "Survey CC": "DE",
          "PctChecked": "0.64",
          "percent": 0.64
        },
        "DJ": {
          "Survey CC": "DJ",
          "PctChecked": "0",
          "percent": 0
        },
        "DK": {
          "Survey CC": "DK",
          "PctChecked": "0.94",
          "percent": 0.94
        },
        "DO": {
          "Survey CC": "DO",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "DZ": {
          "Survey CC": "DZ",
          "PctChecked": "0",
          "percent": 0
        },
        "EC": {
          "Survey CC": "EC",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "EE": {
          "Survey CC": "EE",
          "PctChecked": "1",
          "percent": 1
        },
        "EG": {
          "Survey CC": "EG",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "ES": {
          "Survey CC": "ES",
          "PctChecked": "0.68",
          "percent": 0.68
        },
        "ET": {
          "Survey CC": "ET",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "FI": {
          "Survey CC": "FI",
          "PctChecked": "0.73",
          "percent": 0.73
        },
        "FR": {
          "Survey CC": "FR",
          "PctChecked": "0.58",
          "percent": 0.58
        },
        "GA": {
          "Survey CC": "GA",
          "PctChecked": "0",
          "percent": 0
        },
        "GB": {
          "Survey CC": "GB",
          "PctChecked": "0.82",
          "percent": 0.82
        },
        "GE": {
          "Survey CC": "GE",
          "PctChecked": "1",
          "percent": 1
        },
        "GH": {
          "Survey CC": "GH",
          "PctChecked": "0.42",
          "percent": 0.42
        },
        "GN": {
          "Survey CC": "GN",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "GR": {
          "Survey CC": "GR",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "GT": {
          "Survey CC": "GT",
          "PctChecked": "0.7",
          "percent": 0.7
        },
        "HK": {
          "Survey CC": "HK",
          "PctChecked": "0.63",
          "percent": 0.63
        },
        "HN": {
          "Survey CC": "HN",
          "PctChecked": "0.71",
          "percent": 0.71
        },
        "HR": {
          "Survey CC": "HR",
          "PctChecked": "1",
          "percent": 1
        },
        "HT": {
          "Survey CC": "HT",
          "PctChecked": "0",
          "percent": 0
        },
        "HU": {
          "Survey CC": "HU",
          "PctChecked": "0.84",
          "percent": 0.84
        },
        "ID": {
          "Survey CC": "ID",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "IE": {
          "Survey CC": "IE",
          "PctChecked": "0.79",
          "percent": 0.79
        },
        "IN": {
          "Survey CC": "IN",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "IQ": {
          "Survey CC": "IQ",
          "PctChecked": "0",
          "percent": 0
        },
        "IT": {
          "Survey CC": "IT",
          "PctChecked": "0.59",
          "percent": 0.59
        },
        "JO": {
          "Survey CC": "JO",
          "PctChecked": "1",
          "percent": 1
        },
        "JP": {
          "Survey CC": "JP",
          "PctChecked": "0.18",
          "percent": 0.18
        },
        "KE": {
          "Survey CC": "KE",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "KG": {
          "Survey CC": "KG",
          "PctChecked": "0",
          "percent": 0
        },
        "KH": {
          "Survey CC": "KH",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "KR": {
          "Survey CC": "KR",
          "PctChecked": "0.41",
          "percent": 0.41
        },
        "KZ": {
          "Survey CC": "KZ",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "LB": {
          "Survey CC": "LB",
          "PctChecked": "0",
          "percent": 0
        },
        "LK": {
          "Survey CC": "LK",
          "PctChecked": "0.38",
          "percent": 0.38
        },
        "LR": {
          "Survey CC": "LR",
          "PctChecked": "0.22",
          "percent": 0.22
        },
        "LT": {
          "Survey CC": "LT",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "LU": {
          "Survey CC": "LU",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "LV": {
          "Survey CC": "LV",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "LY": {
          "Survey CC": "LY",
          "PctChecked": "0",
          "percent": 0
        },
        "MD": {
          "Survey CC": "MD",
          "PctChecked": "0.46",
          "percent": 0.46
        },
        "ML": {
          "Survey CC": "ML",
          "PctChecked": "0",
          "percent": 0
        },
        "MM": {
          "Survey CC": "MM",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "MN": {
          "Survey CC": "MN",
          "PctChecked": "0",
          "percent": 0
        },
        "MQ": {
          "Survey CC": "MQ",
          "PctChecked": "0",
          "percent": 0
        },
        "MT": {
          "Survey CC": "MT",
          "PctChecked": "0",
          "percent": 0
        },
        "MU": {
          "Survey CC": "MU",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "MW": {
          "Survey CC": "MW",
          "PctChecked": "0.25",
          "percent": 0.25
        },
        "MX": {
          "Survey CC": "MX",
          "PctChecked": "0.61",
          "percent": 0.61
        },
        "MY": {
          "Survey CC": "MY",
          "PctChecked": "0.39",
          "percent": 0.39
        },
        "MZ": {
          "Survey CC": "MZ",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NA": {
          "Survey CC": "NA",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NE": {
          "Survey CC": "NE",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "NG": {
          "Survey CC": "NG",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "NI": {
          "Survey CC": "NI",
          "PctChecked": "0.67",
          "percent": 0.67
        },
        "NL": {
          "Survey CC": "NL",
          "PctChecked": "0.64",
          "percent": 0.64
        },
        "NP": {
          "Survey CC": "NP",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "NZ": {
          "Survey CC": "NZ",
          "PctChecked": "0.8",
          "percent": 0.8
        },
        "PA": {
          "Survey CC": "PA",
          "PctChecked": "1",
          "percent": 1
        },
        "PE": {
          "Survey CC": "PE",
          "PctChecked": "0.63",
          "percent": 0.63
        },
        "PH": {
          "Survey CC": "PH",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "PK": {
          "Survey CC": "PK",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "PL": {
          "Survey CC": "PL",
          "PctChecked": "0.55",
          "percent": 0.55
        },
        "PR": {
          "Survey CC": "PR",
          "PctChecked": "0.33",
          "percent": 0.33
        },
        "PS": {
          "Survey CC": "PS",
          "PctChecked": "1",
          "percent": 1
        },
        "PT": {
          "Survey CC": "PT",
          "PctChecked": "0.68",
          "percent": 0.68
        },
        "PY": {
          "Survey CC": "PY",
          "PctChecked": "1",
          "percent": 1
        },
        "RO": {
          "Survey CC": "RO",
          "PctChecked": "0.49",
          "percent": 0.49
        },
        "RS": {
          "Survey CC": "RS",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "RW": {
          "Survey CC": "RW",
          "PctChecked": "0.75",
          "percent": 0.75
        },
        "SA": {
          "Survey CC": "SA",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SD": {
          "Survey CC": "SD",
          "PctChecked": "1",
          "percent": 1
        },
        "SE": {
          "Survey CC": "SE",
          "PctChecked": "0.76",
          "percent": 0.76
        },
        "SG": {
          "Survey CC": "SG",
          "PctChecked": "0.68",
          "percent": 0.68
        },
        "SI": {
          "Survey CC": "SI",
          "PctChecked": "0.82",
          "percent": 0.82
        },
        "SK": {
          "Survey CC": "SK",
          "PctChecked": "0",
          "percent": 0
        },
        "SL": {
          "Survey CC": "SL",
          "PctChecked": "0.31",
          "percent": 0.31
        },
        "SN": {
          "Survey CC": "SN",
          "PctChecked": "0",
          "percent": 0
        },
        "SO": {
          "Survey CC": "SO",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SR": {
          "Survey CC": "SR",
          "PctChecked": "1",
          "percent": 1
        },
        "ST": {
          "Survey CC": "ST",
          "PctChecked": "0",
          "percent": 0
        },
        "SV": {
          "Survey CC": "SV",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "SY": {
          "Survey CC": "SY",
          "PctChecked": "0",
          "percent": 0
        },
        "SZ": {
          "Survey CC": "SZ",
          "PctChecked": "0",
          "percent": 0
        },
        "TD": {
          "Survey CC": "TD",
          "PctChecked": "0",
          "percent": 0
        },
        "TG": {
          "Survey CC": "TG",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "TH": {
          "Survey CC": "TH",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "TJ": {
          "Survey CC": "TJ",
          "PctChecked": "0.17",
          "percent": 0.17
        },
        "TR": {
          "Survey CC": "TR",
          "PctChecked": "0.54",
          "percent": 0.54
        },
        "TT": {
          "Survey CC": "TT",
          "PctChecked": "0",
          "percent": 0
        },
        "TW": {
          "Survey CC": "TW",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "TZ": {
          "Survey CC": "TZ",
          "PctChecked": "0.21",
          "percent": 0.21
        },
        "UA": {
          "Survey CC": "UA",
          "PctChecked": "0.56",
          "percent": 0.56
        },
        "UG": {
          "Survey CC": "UG",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "US": {
          "Survey CC": "US",
          "PctChecked": "0.74",
          "percent": 0.74
        },
        "UY": {
          "Survey CC": "UY",
          "PctChecked": "1",
          "percent": 1
        },
        "VE": {
          "Survey CC": "VE",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "VI": {
          "Survey CC": "VI",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "VN": {
          "Survey CC": "VN",
          "PctChecked": "0.6",
          "percent": 0.6
        },
        "YE": {
          "Survey CC": "YE",
          "PctChecked": "0.4",
          "percent": 0.4
        },
        "ZA": {
          "Survey CC": "ZA",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "ZM": {
          "Survey CC": "ZM",
          "PctChecked": "0.44",
          "percent": 0.44
        },
        "ZW": {
          "Survey CC": "ZW",
          "PctChecked": "0.17",
          "percent": 0.17
        }
      },
      "ns": {
        "North": {
          "GlobalRegion": "North",
          "PctChecked": "0.62",
          "percent": 0.62
        },
        "South": {
          "GlobalRegion": "South",
          "PctChecked": "0.5",
          "percent": 0.5
        },
        "Total": {
          "GlobalRegion": "Total",
          "PctChecked": "0.58",
          "percent": 0.58
        }
      },
      "r": {
        "Central Asia": {
          "CH Region": "Central Asia",
          "PctChecked": "0.19",
          "percent": 0.19
        },
        "Africa": {
          "CH Region": "Africa",
          "PctChecked": "0.39",
          "percent": 0.39
        },
        "Asia-Pacific": {
          "CH Region": "Asia-Pacific",
          "PctChecked": "0.43",
          "percent": 0.43
        },
        "Middle East": {
          "CH Region": "Middle East",
          "PctChecked": "0.44",
          "percent": 0.44
        },
        "Europe": {
          "CH Region": "Europe",
          "PctChecked": "0.62",
          "percent": 0.62
        },
        "Latin America & the Caribbean": {
          "CH Region": "Latin America & the Caribbean",
          "PctChecked": "0.62",
          "percent": 0.62
        },
        "North America": {
          "CH Region": "North America",
          "PctChecked": "0.74",
          "percent": 0.74
        },
        "Total": {
          "CH Region": "Total",
          "PctChecked": "0.58",
          "percent": 0.58
        }
      }
    }
  }

export { stats };
