import DashSectionTitle from '../../components/dashSectionTitle';
import { BoxProportion } from "../../components/non_d3/boxProportion";
import { Grid } from "../../components/non_d3/grid";
import { CountryInfo, BoxPair, ChartAndCaption } from "../../components/BoxPair";
import { ChartFlexRow } from "../../components/ChartFlexRow";
import { DonutWrappedInDiv } from "../../components/d3/donut";
import { DialWrappedInDiv } from "../../components/d3/dial";
import { BarPair } from "../../components/barPair";
import { BarWrappedInDiv } from "../../components/non_d3/boxProportion";
import { ComparisonBar } from "../../components/non_d3/comparison_bar";
import { SimpleBarAndLabelPair } from "../../components/non_d3/barsAndLabels";
import { MdSignalCellularConnectedNoInternet1Bar } from "react-icons/md";
import { GrTechnology } from "react-icons/gr";
import { ImPowerCord } from "react-icons/im";

function Policy({ animateGraphs, fetchTitle, fetchReasonTitle, primaryCountry, secondaryCountry, fetchQuestionProportion, fetchQuestionPercent, pc_Ref }) {
  return (
    <div ref={pc_Ref}>
      <ChartFlexRow>
        <ChartAndCaption caption={fetchTitle("35.1", "Story")}>
          <BoxPair>
            <Grid
              percentInt={fetchQuestionPercent(primaryCountry, "35.1")}
              fillCol="#0346AD"
              emptyCol="#fff"
              cellMargin="1px"
              borderRadius="50%"
            />
            <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "35.1")} />
            <Grid
              percentInt={fetchQuestionPercent(secondaryCountry, "35.1")}
              fillCol="#A1ACFD"
              emptyCol="#fff"
              cellMargin="1px"
              borderRadius="50%"
            />
            <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "35.1")} />
          </BoxPair>
        </ChartAndCaption>
        <ChartAndCaption caption={fetchTitle("35.2", "Story")}>
          <BarPair>
            <BarWrappedInDiv   proportion={fetchQuestionProportion(primaryCountry, "35.2")} boxFill="#0346AD" {...{animateGraphs}}/>
            <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "35.2")}/>
            <BarWrappedInDiv   proportion={fetchQuestionProportion(secondaryCountry, "35.2")} boxFill="#A1ACFD" {...{animateGraphs}}/>
            <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "35.2")}/>
          </BarPair>
        </ChartAndCaption>
      </ChartFlexRow>

      <p className='dashSection_desc'>{fetchTitle("31.1", "Group")}</p>
      
      <ChartFlexRow>
        <ChartAndCaption caption={fetchTitle("31.1", "Story")}>
            <BoxPair>
                <DonutWrappedInDiv percent={fetchQuestionPercent(primaryCountry, "31.1")} arcFill="#0346AD" {...{animateGraphs}}/>
                <CountryInfo countryName={primaryCountry.name} center />
                <DonutWrappedInDiv percent={fetchQuestionPercent(secondaryCountry, "31.1")} arcFill="#A1ACFD" {...{animateGraphs}}/>
                <CountryInfo countryName={secondaryCountry.name} center />
            </BoxPair>
        </ChartAndCaption>
        <ChartAndCaption caption={fetchTitle("32.1", "Story")}>
            <ComparisonBar
                primary_data={{name: primaryCountry.name, value: fetchQuestionPercent(primaryCountry, "32.1"), color: "#0346AD"}}
                secondary_data={{name: secondaryCountry.name, value: fetchQuestionPercent(secondaryCountry, "32.1"), color: "#A1ACFD"}}
            />
        </ChartAndCaption>
      </ChartFlexRow>
   
    </div>
  );
}

export default Policy;
