const countryInfoObj = {
  US: {
    "Country Code": "US",
    "CH Country": "United States of America",
    GlobalRegion: "North",
    "CH Region": "North America",
    "Count of Vrid": "1574",
  },
  JP: {
    "Country Code": "JP",
    "CH Country": "Japan",
    GlobalRegion: "North",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "654",
  },
  ID: {
    "Country Code": "ID",
    "CH Country": "Indonesia",
    GlobalRegion: "South",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "571",
  },
  UA: {
    "Country Code": "UA",
    "CH Country": "Ukraine",
    GlobalRegion: "South",
    "CH Region": "Europe",
    "Count of Vrid": "427",
  },
  CA: {
    "Country Code": "CA",
    "CH Country": "Canada",
    GlobalRegion: "North",
    "CH Region": "North America",
    "Count of Vrid": "402",
  },
  DE: {
    "Country Code": "DE",
    "CH Country": "Germany",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "266",
  },
  PT: {
    "Country Code": "PT",
    "CH Country": "Portugal",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "196",
  },
  TW: {
    "Country Code": "TW",
    "CH Country": "Taiwan",
    GlobalRegion: "North",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "181",
  },
  CZ: {
    "Country Code": "CZ",
    "CH Country": "Czechia (Czech Republic)",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "172",
  },
  AU: {
    "Country Code": "AU",
    "CH Country": "Australia",
    GlobalRegion: "North",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "163",
  },
  BR: {
    "Country Code": "BR",
    "CH Country": "Brazil",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "158",
  },
  MX: {
    "Country Code": "MX",
    "CH Country": "Mexico",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "150",
  },
  RO: {
    "Country Code": "RO",
    "CH Country": "Romania",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "144",
  },
  IN: {
    "Country Code": "IN",
    "CH Country": "India",
    GlobalRegion: "South",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "135",
  },
  ES: {
    "Country Code": "ES",
    "CH Country": "Spain",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "134",
  },
  PL: {
    "Country Code": "PL",
    "CH Country": "Poland",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "126",
  },
  HU: {
    "Country Code": "HU",
    "CH Country": "Hungary",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "115",
  },
  ZA: {
    "Country Code": "ZA",
    "CH Country": "South Africa",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "113",
  },
  IT: {
    "Country Code": "IT",
    "CH Country": "Italy",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "109",
  },
  BG: {
    "Country Code": "BG",
    "CH Country": "Bulgaria",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "104",
  },
  BE: {
    "Country Code": "BE",
    "CH Country": "Belgium",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "103",
  },
  NZ: {
    "Country Code": "NZ",
    "CH Country": "New Zealand",
    GlobalRegion: "North",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "103",
  },
  GB: {
    "Country Code": "GB",
    "CH Country": "United Kingdom",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "99",
  },
  NL: {
    "Country Code": "NL",
    "CH Country": "Netherlands",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "80",
  },
  TR: {
    "Country Code": "TR",
    "CH Country": "Turkey",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "74",
  },
  IE: {
    "Country Code": "IE",
    "CH Country": "Ireland",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "71",
  },
  KE: {
    "Country Code": "KE",
    "CH Country": "Kenya",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "68",
  },
  NG: {
    "Country Code": "NG",
    "CH Country": "Nigeria",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "65",
  },
  KR: {
    "Country Code": "KR",
    "CH Country": "South Korea",
    GlobalRegion: "North",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "64",
  },
  PH: {
    "Country Code": "PH",
    "CH Country": "Philippines",
    GlobalRegion: "South",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "62",
  },
  CO: {
    "Country Code": "CO",
    "CH Country": "Colombia",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "58",
  },
  CL: {
    "Country Code": "CL",
    "CH Country": "Chile",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "55",
  },
  SA: {
    "Country Code": "SA",
    "CH Country": "Saudi Arabia",
    GlobalRegion: "South",
    "CH Region": "Middle East",
    "Count of Vrid": "42",
  },
  MD: {
    "Country Code": "MD",
    "CH Country": "Moldova",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "39",
  },
  GH: {
    "Country Code": "GH",
    "CH Country": "Ghana",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "36",
  },
  SE: {
    "Country Code": "SE",
    "CH Country": "Sweden",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "34",
  },
  MY: {
    "Country Code": "MY",
    "CH Country": "Malaysia",
    GlobalRegion: "South",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "33",
  },
  SI: {
    "Country Code": "SI",
    "CH Country": "Slovenia",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "33",
  },
  SG: {
    "Country Code": "SG",
    "CH Country": "Singapore",
    GlobalRegion: "North",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "31",
  },
  UG: {
    "Country Code": "UG",
    "CH Country": "Uganda",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "30",
  },
  TZ: {
    "Country Code": "TZ",
    "CH Country": "Tanzania",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "24",
  },
  AR: {
    "Country Code": "AR",
    "CH Country": "Argentina",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "22",
  },
  HK: {
    "Country Code": "HK",
    "CH Country": "Hong Kong SAR",
    GlobalRegion: "South",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "19",
  },
  TH: {
    "Country Code": "TH",
    "CH Country": "Thailand",
    GlobalRegion: "South",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "18",
  },
  CD: {
    "Country Code": "CD",
    "CH Country": "Democratic Republic of the Congo",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "17",
  },
  DK: {
    "Country Code": "DK",
    "CH Country": "Denmark",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "17",
  },
  CM: {
    "Country Code": "CM",
    "CH Country": "Cameroon",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "14",
  },
  SL: {
    "Country Code": "SL",
    "CH Country": "Sierra Leone",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "13",
  },
  FR: {
    "Country Code": "FR",
    "CH Country": "France",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "12",
  },
  PK: {
    "Country Code": "PK",
    "CH Country": "Pakistan",
    GlobalRegion: "South",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "12",
  },
  FI: {
    "Country Code": "FI",
    "CH Country": "Finland",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "11",
  },
  CH: {
    "Country Code": "CH",
    "CH Country": "Switzerland",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "10",
  },
  EC: {
    "Country Code": "EC",
    "CH Country": "Ecuador",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "10",
  },
  GT: {
    "Country Code": "GT",
    "CH Country": "Guatemala",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "10",
  },
  AT: {
    "Country Code": "AT",
    "CH Country": "Austria",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "9",
  },
  LR: {
    "Country Code": "LR",
    "CH Country": "Liberia",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "9",
  },
  ZM: {
    "Country Code": "ZM",
    "CH Country": "Zambia",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "9",
  },
  LK: {
    "Country Code": "LK",
    "CH Country": "Sri Lanka",
    GlobalRegion: "South",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "8",
  },
  PE: {
    "Country Code": "PE",
    "CH Country": "Peru",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "8",
  },
  HN: {
    "Country Code": "HN",
    "CH Country": "Honduras",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "7",
  },
  BI: {
    "Country Code": "BI",
    "CH Country": "Burundi",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "6",
  },
  BO: {
    "Country Code": "BO",
    "CH Country": "Bolivia",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "6",
  },
  EG: {
    "Country Code": "EG",
    "CH Country": "Egypt",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "6",
  },
  GR: {
    "Country Code": "GR",
    "CH Country": "Greece",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "6",
  },
  KH: {
    "Country Code": "KH",
    "CH Country": "Cambodia",
    GlobalRegion: "South",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "6",
  },
  LU: {
    "Country Code": "LU",
    "CH Country": "Luxembourg",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "6",
  },
  MM: {
    "Country Code": "MM",
    "CH Country": "Myanmar (formerly Burma)",
    GlobalRegion: "South",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "6",
  },
  TJ: {
    "Country Code": "TJ",
    "CH Country": "Tajikistan",
    GlobalRegion: "South",
    "CH Region": "Central Asia",
    "Count of Vrid": "6",
  },
  VE: {
    "Country Code": "VE",
    "CH Country": "Venezuela",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "6",
  },
  ZW: {
    "Country Code": "ZW",
    "CH Country": "Zimbabwe",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "6",
  },
  BD: {
    "Country Code": "BD",
    "CH Country": "Bangladesh",
    GlobalRegion: "South",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "5",
  },
  DO: {
    "Country Code": "DO",
    "CH Country": "Dominican Republic",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "5",
  },
  LT: {
    "Country Code": "LT",
    "CH Country": "Lithuania",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "5",
  },
  NP: {
    "Country Code": "NP",
    "CH Country": "Nepal",
    GlobalRegion: "South",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "5",
  },
  VN: {
    "Country Code": "VN",
    "CH Country": "Vietnam",
    GlobalRegion: "South",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "5",
  },
  YE: {
    "Country Code": "YE",
    "CH Country": "Yemen",
    GlobalRegion: "South",
    "CH Region": "Middle East",
    "Count of Vrid": "5",
  },
  AF: {
    "Country Code": "AF",
    "CH Country": "Afghanistan",
    GlobalRegion: "South",
    "CH Region": "Central Asia",
    "Count of Vrid": "4",
  },
  CN: {
    "Country Code": "CN",
    "CH Country": "China",
    GlobalRegion: "South",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "4",
  },
  CR: {
    "Country Code": "CR",
    "CH Country": "Costa Rica",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "4",
  },
  ET: {
    "Country Code": "ET",
    "CH Country": "Ethiopia",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "4",
  },
  GN: {
    "Country Code": "GN",
    "CH Country": "Guinea",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "4",
  },
  IQ: {
    "Country Code": "IQ",
    "CH Country": "Iraq",
    GlobalRegion: "South",
    "CH Region": "Middle East",
    "Count of Vrid": "4",
  },
  KZ: {
    "Country Code": "KZ",
    "CH Country": "Kazakhstan",
    GlobalRegion: "South",
    "CH Region": "Central Asia",
    "Count of Vrid": "4",
  },
  LV: {
    "Country Code": "LV",
    "CH Country": "Latvia",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "4",
  },
  MW: {
    "Country Code": "MW",
    "CH Country": "Malawi",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "4",
  },
  RW: {
    "Country Code": "RW",
    "CH Country": "Rwanda",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "4",
  },
  SV: {
    "Country Code": "SV",
    "CH Country": "El Salvador",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "4",
  },
  TG: {
    "Country Code": "TG",
    "CH Country": "Togo",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "4",
  },
  AE: {
    "Country Code": "AE",
    "CH Country": "United Arab Emirates",
    GlobalRegion: "South",
    "CH Region": "Middle East",
    "Count of Vrid": "3",
  },
  AL: {
    "Country Code": "AL",
    "CH Country": "Albania",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "3",
  },
  BJ: {
    "Country Code": "BJ",
    "CH Country": "Benin",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "3",
  },
  BW: {
    "Country Code": "BW",
    "CH Country": "Botswana",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "3",
  },
  CF: {
    "Country Code": "CF",
    "CH Country": "Central African Republic",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "3",
  },
  DZ: {
    "Country Code": "DZ",
    "CH Country": "Algeria",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "3",
  },
  HT: {
    "Country Code": "HT",
    "CH Country": "Haiti",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "3",
  },
  NI: {
    "Country Code": "NI",
    "CH Country": "Nicaragua",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "3",
  },
  PR: {
    "Country Code": "PR",
    "CH Country": "Puerto Rico",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "3",
  },
  PY: {
    "Country Code": "PY",
    "CH Country": "Paraguay",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "3",
  },
  SN: {
    "Country Code": "SN",
    "CH Country": "Senegal",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "3",
  },
  SZ: {
    "Country Code": "SZ",
    "CH Country": 'Eswatini (fmr. "Swaziland")',
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "3",
  },
  AO: {
    "Country Code": "AO",
    "CH Country": "Angola",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "2",
  },
  CI: {
    "Country Code": "CI",
    "CH Country": "Côte d'Ivoire",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "2",
  },
  EE: {
    "Country Code": "EE",
    "CH Country": "Estonia",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "2",
  },
  JO: {
    "Country Code": "JO",
    "CH Country": "Jordan",
    GlobalRegion: "South",
    "CH Region": "Middle East",
    "Count of Vrid": "2",
  },
  KG: {
    "Country Code": "KG",
    "CH Country": "Kyrgyzstan",
    GlobalRegion: "South",
    "CH Region": "Central Asia",
    "Count of Vrid": "2",
  },
  ML: {
    "Country Code": "ML",
    "CH Country": "Mali",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "2",
  },
  MU: {
    "Country Code": "MU",
    "CH Country": "Mauritius",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "2",
  },
  MZ: {
    "Country Code": "MZ",
    "CH Country": "Mozambique",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "2",
  },
  NA: {
    "Country Code": "NA",
    "CH Country": "Namibia",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "2",
  },
  NE: {
    "Country Code": "NE",
    "CH Country": "Niger",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "2",
  },
  RS: {
    "Country Code": "RS",
    "CH Country": "Serbia",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "2",
  },
  SO: {
    "Country Code": "SO",
    "CH Country": "Somalia",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "2",
  },
  SY: {
    "Country Code": "SY",
    "CH Country": "Syria",
    GlobalRegion: "South",
    "CH Region": "Middle East",
    "Count of Vrid": "2",
  },
  TD: {
    "Country Code": "TD",
    "CH Country": "Chad",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "2",
  },
  VI: {
    "Country Code": "VI",
    "CH Country": "US Virgin Islands",
    GlobalRegion: "North",
    "CH Region": "North America",
    "Count of Vrid": "2",
  },
  AD: {
    "Country Code": "AD",
    "CH Country": "Andorra",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "1",
  },
  BH: {
    "Country Code": "BH",
    "CH Country": "Bahrain",
    GlobalRegion: "South",
    "CH Region": "Middle East",
    "Count of Vrid": "1",
  },
  BS: {
    "Country Code": "BS",
    "CH Country": "Bahamas",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "1",
  },
  CG: {
    "Country Code": "CG",
    "CH Country": "Congo (Congo-Brazzaville)",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "1",
  },
  DJ: {
    "Country Code": "DJ",
    "CH Country": "Djibouti",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "1",
  },
  GA: {
    "Country Code": "GA",
    "CH Country": "Gabon",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "1",
  },
  GE: {
    "Country Code": "GE",
    "CH Country": "Georgia",
    GlobalRegion: "South",
    "CH Region": "Europe",
    "Count of Vrid": "1",
  },
  HR: {
    "Country Code": "HR",
    "CH Country": "Croatia",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "1",
  },
  LB: {
    "Country Code": "LB",
    "CH Country": "Lebanon",
    GlobalRegion: "South",
    "CH Region": "Middle East",
    "Count of Vrid": "1",
  },
  LY: {
    "Country Code": "LY",
    "CH Country": "Libya",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "1",
  },
  MN: {
    "Country Code": "MN",
    "CH Country": "Mongolia",
    GlobalRegion: "South",
    "CH Region": "Asia-Pacific",
    "Count of Vrid": "1",
  },
  MQ: {
    "Country Code": "MQ",
    "CH Country": "Mauritania",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "1",
  },
  MT: {
    "Country Code": "MT",
    "CH Country": "Malta",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "1",
  },
  PA: {
    "Country Code": "PA",
    "CH Country": "Panama",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "1",
  },
  PS: {
    "Country Code": "PS",
    "CH Country": "Palestine State",
    GlobalRegion: "South",
    "CH Region": "Middle East",
    "Count of Vrid": "1",
  },
  SD: {
    "Country Code": "SD",
    "CH Country": "Sudan",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "1",
  },
  SK: {
    "Country Code": "SK",
    "CH Country": "Slovakia",
    GlobalRegion: "North",
    "CH Region": "Europe",
    "Count of Vrid": "1",
  },
  SR: {
    "Country Code": "SR",
    "CH Country": "Suriname",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "1",
  },
  ST: {
    "Country Code": "ST",
    "CH Country": "Sao Tome and Principe",
    GlobalRegion: "South",
    "CH Region": "Africa",
    "Count of Vrid": "1",
  },
  TT: {
    "Country Code": "TT",
    "CH Country": "Trinidad and Tobago",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "1",
  },
  UY: {
    "Country Code": "UY",
    "CH Country": "Uruguay",
    GlobalRegion: "South",
    "CH Region": "Latin America & the Caribbean",
    "Count of Vrid": "1",
  },
};

export { countryInfoObj };
