import styles from './css-modules/footer.module.css';
import { IoLogoTwitter, IoLogoLinkedin } from "react-icons/io5";

function Footer({ logo }) {
    return (
        <div className={`${styles.container}`}>
            <div className={styles.left}>
                <img src={logo} className={styles.logo}></img>
                <p className={styles.copyright}>© Copyright {new Date().getFullYear() }, All Rights Reserved</p>
            </div>
            <div className={styles.right}>
                <div className={styles.social_container}>
                        <IoLogoTwitter className={styles.social_icon}/>
                        <IoLogoLinkedin className={styles.social_icon}/>
                </div>
                <div className={styles.legal}>
                    <p>This dashboard is made available under a <a href='https://creativecommons.org/licenses/by/4.0/' target='_blank' rel='norefferer'>Creative Commons 4.0 International license (CC BY 4.0)</a>. For more information visit www.connecthumanity.fund or email press@connecthumanity.fund</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
