import styles from './css-modules/sectionTitle.module.css';

function SectionTitle({ title, gray, jsx, image, removeRadius, sticky, refV, desc }) {
    return (
        <div id={`${sticky && 'sticky'}`} className={`${styles.container} ${gray && styles.gray} ${!gray && !image && styles.blueBG} ${removeRadius && styles.removeRadius} ${sticky && styles.sticky}`}>
            {title && <h1 className={`${styles.title} ${!gray && styles.whiteTxt}`}>{title}</h1>}
            {desc && <p className={`${styles.desc} ${!gray && styles.whiteTxt}`}>{desc}</p>}
            {image && <div style={{background: `url(${image}`}} className={styles.image}></div>}
            {jsx && jsx}
        </div>
    );
}

export default SectionTitle;
