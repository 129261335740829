import styled from "styled-components";
import  TweenLite from 'gsap';
import { useEffect, useRef, useState } from 'react';
import { useInView } from "react-intersection-observer";

const spaceTakenByBar = 0.9;

const SimpleBarAndLabelContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
  justify-content: flex-start;
  margin: ${(props) => props.barMargin} 0px;

  & .simpleBar {
    width: ${(props) => props.proportion * spaceTakenByBar * 100}%;
    background-color: ${(props) => props.barCol};
    border-radius: ${(props) => props.borderRadius};
    transform: scale3d(0, 1, 0);
    transform-origin: left center;
  }

  & .barLabel {
    margin: auto 0px;
    margin-left: 5px;
    font-size: ${(props) => props.fontSize};
    opacity: 0; 
    transform: translateX(5px);
  }
`;

const SimpleBarAndLabel = ({
  proportion,
  barCol,
  borderRadius = "5px",
  fontSize = "12px",
  barMargin = "2px",
  transitionDuration = 0.5,
  transitionDelay = 0.1,
}) => {

  const [init, setInit] = useState(true);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const textRef = useRef(null);

  useEffect(() => {
    if (inView && init) {
      TweenLite.fromTo(entry.target, transitionDuration, {scaleX: 0}, {scaleX: 1}).delay(transitionDelay);
      TweenLite.fromTo(textRef.current, 0.35, {opacity: 0, x: 5}, {opacity: 1, x: 0}).delay(transitionDelay + transitionDuration);
      setInit(false);
    }
  }, [inView, init]);


  useEffect(() => {
    if (entry) {
      setInit(true);
    }
  }, [proportion]);

  return (
    <SimpleBarAndLabelContainer
      {...{ proportion, barCol, borderRadius, fontSize, barMargin }}
    >
      <div ref={ref} className="simpleBar" />
      <p ref={textRef} className="barLabel">{`${Math.round(proportion * 100)}%`}</p>
    </SimpleBarAndLabelContainer>
  );
};

export const SimpleBarAndLabelPair = ({
  proportion1,
  barCol1,
  proportion2,
  barCol2,
  icon: Icon,
  caption,
}) => {
  return (
    <div
      className="simpleBarAndLabelPairContain"
      style={{ padding: "20px", marginBottom: "10px", border: "1px solid #ddd", borderRadius: "10px" }}
    >
      <div className="iconContain" style={{ display: "flex" }}>
        <div className={"iconContain"}>{<Icon />}</div>
        {caption}
      </div>
      <div>
        <div
          className="barPairConatiner"
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "flex-start",
            height: "100%",
            width: "100%",
          }}
        >
          <SimpleBarAndLabel proportion={proportion1} barCol={barCol1} />
          <SimpleBarAndLabel proportion={proportion2} barCol={barCol2} />
        </div>
      </div>
    </div>
  );
};
