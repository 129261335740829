import DashSectionTitle from '../components/dashSectionTitle';
import DashGeneral from './dashSections/general';
import AccessInfra from './dashSections/accessInfra';
import Afford from './dashSections/afford';
import Digital from './dashSections/digital';
import Policy from './dashSections/policy';
import Content from './dashSections/content';
import DashboardKey from '../components/key';

import General from '../resources/Cards/Compressed/General.webp';
import AffordP from '../resources/Cards/Compressed/Afford.webp';
import ContentP from '../resources/Cards/Compressed/content.webp';
import DigitalSkills from '../resources/Cards/Compressed/DigitalSkills.webp';
import PolicyP from '../resources/Cards/Compressed/Policy.webp';
import AccessInfraP from '../resources/Cards/Compressed/AccessInfra.webp';

import GeneralIcon from '../resources/Cards/Icons/general.png';
import AffordIcon from '../resources/Cards/Icons/affordability.png';
import ContentIcon from '../resources/Cards/Icons/content.png';
import DigitalIcon from '../resources/Cards/Icons/digitalSkills.png';
import InfraIcon from '../resources/Cards/Icons/infrastructure.png';
import PolicyIcon from '../resources/Cards/Icons/policy.png';

function DashContent({ animateGraphs, dashStyles, fetchTitle, fetchReasonTitle, primaryCountry, secondaryCountry, countries, fetchCountryResponseCount, fetchQuestionProportion, fetchQuestionPercent, general, access, policy, content, digital, afford, gc_Ref, ac_Ref, afc_Ref, dc_Ref, cc_Ref, pc_Ref }) {
  return (
    <>
      <DashSectionTitle
        title={fetchTitle("11.1", "Section")} 
        desc={fetchTitle("11.1", "Group")}
        icon={GeneralIcon} 
        image={General} 
        offset
        refV={general}
      />
      <DashGeneral {...{animateGraphs, fetchTitle, fetchReasonTitle, primaryCountry, secondaryCountry, fetchQuestionProportion, fetchQuestionPercent, gc_Ref }}/>
      <DashSectionTitle
        title={fetchTitle("18.1", "Section")} 
        desc={fetchTitle("18.1", "Group")}
        icon={InfraIcon} 
        smallIcon
        image={AccessInfraP} 
        refV={access}

      />
      <AccessInfra {...{animateGraphs, fetchTitle, fetchReasonTitle, primaryCountry, secondaryCountry, fetchQuestionProportion, fetchQuestionPercent, ac_Ref }}/>

      { /*<div className={`${dashStyles.flexed_inputs} ${dashStyles.gray_container}`}>
        <DashboardKey color={'#0346AD'} country={primaryCountry.name} count={fetchCountryResponseCount(countries, primaryCountry)}/>
        <DashboardKey color={'#A1ACFD'} country={secondaryCountry.name} count={fetchCountryResponseCount(countries, secondaryCountry)}/>
       </div> */}
      <DashSectionTitle
        title={fetchTitle("23.1", "Section")} 
        desc={fetchTitle("23.1", "Group")}
        icon={AffordIcon} 
        image={AffordP} 
        refV={afford}
        smallIcon
        offset
      />
      <Afford {...{animateGraphs, fetchTitle, fetchReasonTitle, primaryCountry, secondaryCountry, fetchQuestionProportion, fetchQuestionPercent, afc_Ref }}/>

      <DashSectionTitle
        title={fetchTitle("35.1", "Section")} 
        desc={fetchTitle("35.1", "Group")}
        icon={PolicyIcon} 
        image={PolicyP} 
        refV={policy}
      />
      <Policy {...{animateGraphs, fetchTitle, fetchReasonTitle, primaryCountry, secondaryCountry, fetchQuestionProportion, fetchQuestionPercent, pc_Ref }}/>

      { /*<div className={`${dashStyles.flexed_inputs} ${dashStyles.gray_container}`}>
        <DashboardKey color={'#0346AD'} country={primaryCountry.name} count={fetchCountryResponseCount(countries, primaryCountry)}/>
        <DashboardKey color={'#A1ACFD'} country={secondaryCountry.name} count={fetchCountryResponseCount(countries, secondaryCountry)}/>
        </div> */}
      <DashSectionTitle
        title={fetchTitle("28.1", "Section")} 
        desc={fetchTitle("28.1", "Group")}
        icon={DigitalIcon} 
        image={DigitalSkills} 
        refV={digital}
        offset
      />
      <Digital {...{animateGraphs, fetchTitle, fetchReasonTitle, primaryCountry, secondaryCountry, fetchQuestionProportion, fetchQuestionPercent, dc_Ref }}/>

      <DashSectionTitle
        title={fetchTitle("38.1", "Section")}
        desc={fetchTitle("38.1", "Group")}
        icon={ContentIcon} 
        image={ContentP} 
        refV={content}
        offset
      />
      <Content {...{animateGraphs, fetchTitle, fetchReasonTitle, primaryCountry, secondaryCountry, fetchQuestionProportion, fetchQuestionPercent, cc_Ref }}/>

    </>
  );
}

export default DashContent;
