import styles from './css-modules/dash_section_title.module.css';

function DashSectionTitle({ title, desc, icon, image, offset, smallIcon, refV }) {
    return (
        <div ref={refV} className={`${styles.container} ${offset && styles.add_offset}`}>
            <div style={{background: `url(${image}`}} className={styles.image}></div>
            <div className={styles.content_container}>
                <div className={styles.title_container}>
                    <h2 className={styles.heading}>{title}</h2>
                    <p className={styles.desc}>{desc}</p>
                </div>
                <div className={styles.icon_container}>
                    <img src={icon} className={`${styles.icon} ${smallIcon && styles.small_icon}`}></img>
                </div>
            </div>
        </div>
    );
}

export default DashSectionTitle;
