import styled from "styled-components";
import styles from "./css-modules/boxPair.module.css";

const PairRow = styled.div`
  display: flex;
  justify-content: space-between;

  & div.pairElem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(48.5% - 5px);
  }

  @media screen and (max-width: 850px) {
    div.pairElem {
      margin: 0px;
      padding: 10px
    }
  }
`;

const CountryInfo = ({ countryName, perc, center = false }) => {

  console.log(perc === 0);
  return (
    <div
      className={`${styles.countryInfo} ${
        center ? styles.countryInfoCenter : ""
      }`}
    >
      <div className={styles.countryLabel}>{countryName}</div>
      {perc !== undefined && <div className={styles.countryProp}>{`${perc}%`}</div>}
    </div>
  );
};

const BoxPair = ({ children }) => {
  const [
    leftCountryChart,
    leftCountryInfo,
    rightCountryChart,
    rightCountryInfo,
  ] = children;

  return (
    <PairRow>
      <div className="pairElem">
        {leftCountryChart}
        {leftCountryInfo}
      </div>
      <div className="pairElem">
        {rightCountryChart}
        {rightCountryInfo}
      </div>
    </PairRow>
  );
};

const ChartAndCaption = ({
  caption,
  children,
  iconPresent = false,
  captionCentered = false,
  icon: Icon,
}) => {
  return (
    <div className={`${styles.boxPairAndCaptionContain} ${
      iconPresent ? styles.boxPairAndCaptionContainWithIcon : ""
    }`}>
      <div
        className={`${styles.caption} ${
          captionCentered? styles.captionCentered : ""
        }`}
      >
        <div className={styles.iconContain}>{iconPresent && <Icon />}</div>
        {caption}
      </div>
      {children}
    </div>
  );
};

export { CountryInfo, BoxPair, ChartAndCaption };
