import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { drawDial } from "./d3Dial";
import { useInView } from "react-intersection-observer";

const Dial = ({ bGCol, needleCol, percVal, draw, setDraw }) => {
  const svgRef = useRef();

  useEffect(() => {
    if (draw) {
      drawDial(bGCol, needleCol, percVal, svgRef);
      setDraw(false);
    }
  }, [draw]);

  return (
    <svg
      ref={svgRef}
      className="dialViz"
      viewBox="0 0 540 290"
      preserveAspectRatio="xMinYMin meet"
    />
  );
};

export const DialWrappedInDiv = ({ bGCol, needleCol, percVal }) => {

  const [init, setInit] = useState(true);
  const [draw, setDraw] = useState(false);

  const { ref, inView, entry } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView && init) {
      setDraw(true);
      setInit(false);
    }
  }, [inView, init]);

  useEffect(() => {
    if (entry) {
      setInit(true);
    }
  }, [percVal]);

  return (
    <div ref={ref} style={{ width: "90%", paddingTop: "90%", position: 'relative' }}>
      <div style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Dial {...{ bGCol, needleCol, percVal, draw, setDraw }} />
      </div>
    </div>
  );
};
