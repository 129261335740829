import styled from "styled-components";

const PairCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;

  & div.pairElem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
  }
`;

export const BarPair = ({ children }) => {
  const [
    topCountryChart,
    topCountryInfo,
    bottomCountryChart,
    bottomCountryInfo,
  ] = children;

  return (
    <PairCol>
      <div className="pairElem">
        {topCountryChart}
        {topCountryInfo}
      </div>
      <div className="pairElem">
        {bottomCountryChart}
        {bottomCountryInfo}
      </div>
    </PairCol>
  );
};

