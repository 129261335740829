import { useEffect, useRef, useState } from "react";
import drawDonut from "./d3Donut";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import  TweenLite from 'gsap';

// percentage
const PercentBox = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  & p {
    font-weight: 500;
    font-size: 18px;
    opacity: 0;
    transform: translateY(-10px);
  }

  @media screen and (min-width: 1750px) {
    & p {
      font-size: 1.35em;
    }
  }
`;

const Donut = ({ percent, innerRad = 190, outerRad = 166, arcFill, draw, setDraw }) => {
  const svgRef = useRef();

  useEffect(() => {
    if (draw) {
      drawDonut(percent, innerRad, outerRad, arcFill, svgRef);
      setDraw(false);
    }
  }, [draw]);

  return (
    <svg
      ref={svgRef}
      className="donutViz"
      viewBox="0 0 400 400"
      preserveAspectRatio="xMinYMin meet"
    />
  );
};

const DonutWrappedInDiv = ({ percent, arcFill }) => {

  const [init, setInit] = useState(true);
  const [draw, setDraw] = useState(false);

  const { ref, inView, entry } = useInView({
    threshold: 0.5,
  });

  const textRef = useRef(null);

  useEffect(() => {
    if (inView && init) {
      setDraw(true);
      TweenLite.fromTo(textRef.current, 0.35, {opacity: 0, y: -10}, {opacity: 1, y: 0}).delay(0.25);
      setInit(false);
    }
  }, [inView, init]);

  useEffect(() => {
    if (entry) {
      setInit(true);
    }
  }, [percent]);


  return (
    <div ref={ref} style={{ width: "90%", position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Donut {...{ percent, arcFill, draw, setDraw }} />
      <PercentBox>
        <p ref={textRef}>{percent}%</p>
      </PercentBox>
    </div>
  );
};

export { DonutWrappedInDiv };
