import DashSectionTitle from '../../components/dashSectionTitle';
import { BoxProportion } from "../../components/non_d3/boxProportion";
import boxStyles from '../../components/css-modules/boxPair.module.css';
import { Grid } from "../../components/non_d3/grid";
import { CountryInfo, BoxPair, ChartAndCaption } from "../../components/BoxPair";
import { ChartFlexRow } from "../../components/ChartFlexRow";
import { DonutWrappedInDiv } from "../../components/d3/donut";
import { DialWrappedInDiv } from "../../components/d3/dial";
import { BarPair } from "../../components/barPair";
import { BarWrappedInDiv } from "../../components/non_d3/boxProportion";
import { ComparisonBar } from "../../components/non_d3/comparison_bar";
import { SimpleBarAndLabelPair } from "../../components/non_d3/barsAndLabels";
import { MdSignalCellularConnectedNoInternet1Bar } from "react-icons/md";
import { GrTechnology } from "react-icons/gr";
import { ImPowerCord } from "react-icons/im";

function Afford({ animateGraphs, fetchTitle, fetchReasonTitle, primaryCountry, secondaryCountry, fetchQuestionProportion, fetchQuestionPercent, afc_Ref }) {
  return (
    <div ref={afc_Ref}>
      <p className='paddedCaption'><span className={boxStyles.caption}>{fetchTitle("23.1", "Story")}</span></p>
      <ChartFlexRow>
        <ChartAndCaption
          caption={fetchReasonTitle("23.1")}
          iconPresent
          captionCentered
          icon={GrTechnology}
        >
          <BoxPair>
            <Grid
                percentInt={fetchQuestionPercent(primaryCountry, "23.1")}
                fillCol="#0346AD"
                emptyCol="#fff"
                cellMargin="1px"
                borderRadius="50%"
            />
            <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "23.1")} />
            <Grid
                percentInt={fetchQuestionPercent(secondaryCountry, "23.1")}
                fillCol="#A1ACFD"
                emptyCol="#fff"
                cellMargin="1px"
                borderRadius="50%"
            />
            <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "23.1")} />
          </BoxPair>
        </ChartAndCaption>
        <ChartAndCaption
          caption={fetchReasonTitle("23.2")}
          iconPresent
          captionCentered
          icon={MdSignalCellularConnectedNoInternet1Bar}
        >
          <BoxPair>
            <Grid
                percentInt={fetchQuestionPercent(primaryCountry, "23.2")}
                fillCol="#0346AD"
                emptyCol="#fff"
                cellMargin="1px"
                borderRadius="50%"
            />
            <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "23.2")} />
            <Grid
                percentInt={fetchQuestionPercent(secondaryCountry, "23.2")}
                fillCol="#A1ACFD"
                emptyCol="#fff"
                cellMargin="1px"
                borderRadius="50%"
            />
            <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "23.2")} />
          </BoxPair>
        </ChartAndCaption>
        <ChartAndCaption
          caption={fetchReasonTitle("23.3")}
          iconPresent
          captionCentered
          icon={ImPowerCord}
        >
          <BoxPair>
            <Grid
                percentInt={fetchQuestionPercent(primaryCountry, "23.3")}
                fillCol="#0346AD"
                emptyCol="#fff"
                cellMargin="1px"
                borderRadius="50%"
            />
            <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "23.3")} />
            <Grid
                percentInt={fetchQuestionPercent(secondaryCountry, "23.3")}
                fillCol="#A1ACFD"
                emptyCol="#fff"
                cellMargin="1px"
                borderRadius="50%"
            />
            <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "23.3")} />
          </BoxPair>
        </ChartAndCaption>
      </ChartFlexRow>
      <ChartFlexRow>
        <ChartAndCaption caption={fetchTitle("23.4", "Story")}>
        <BoxPair>
            <BoxProportion
              borderWidth="2px"
              proportion={fetchQuestionProportion(primaryCountry, "23.4")}
              boxFill="#0346AD"
              borderRadius="15px"
              type="box"
              transitionDuration={0.4}
              transitionDelay={0.125}
            />
            <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "23.4")} />
            <BoxProportion
              borderWidth="2px"
              proportion={fetchQuestionProportion(secondaryCountry, "23.4")}
              boxFill="#A1ACFD"
              borderRadius="15px"
              type="box"
              transitionDuration={0.4}
              transitionDelay={0.25}
            />
            <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "23.4")} />
          </BoxPair>
        </ChartAndCaption>
      </ChartFlexRow>

      <p className='dashSection_desc'>{fetchTitle("24.1", "Group")}</p>

      <p className='paddedCaption'><span className={boxStyles.caption}>{fetchTitle("24.1", "Story")}</span></p>
      <ChartFlexRow>
        <ChartAndCaption
          caption={fetchReasonTitle("24.1")}
          iconPresent
          captionCentered
          icon={GrTechnology}
        >
          <BoxPair>
          <DonutWrappedInDiv percent={fetchQuestionPercent(primaryCountry, "24.1")} arcFill="#0346AD" {...{animateGraphs}}/>
            <CountryInfo countryName={primaryCountry.name} center />
            <DonutWrappedInDiv percent={fetchQuestionPercent(secondaryCountry, "24.1")} arcFill="#A1ACFD" {...{animateGraphs}}/>
            <CountryInfo countryName={secondaryCountry.name} center />
          </BoxPair>
        </ChartAndCaption>
        <ChartAndCaption
          caption={fetchReasonTitle("24.2")}
          iconPresent
          captionCentered
          icon={MdSignalCellularConnectedNoInternet1Bar}
        >
          <BoxPair>
          <DonutWrappedInDiv percent={fetchQuestionPercent(primaryCountry, "24.2")} arcFill="#0346AD" {...{animateGraphs}}/>
            <CountryInfo countryName={primaryCountry.name} center />
            <DonutWrappedInDiv percent={fetchQuestionPercent(secondaryCountry, "24.2")} arcFill="#A1ACFD" {...{animateGraphs}}/>
            <CountryInfo countryName={secondaryCountry.name} center />
          </BoxPair>
        </ChartAndCaption>
        <ChartAndCaption
          caption={fetchReasonTitle("24.3")}
          iconPresent
          captionCentered
          icon={ImPowerCord}
        >
          <BoxPair>
            <DonutWrappedInDiv percent={fetchQuestionPercent(primaryCountry, "24.3")} arcFill="#0346AD" {...{animateGraphs}}/>
            <CountryInfo countryName={primaryCountry.name} center />
            <DonutWrappedInDiv percent={fetchQuestionPercent(secondaryCountry, "24.3")} arcFill="#A1ACFD" {...{animateGraphs}}/>
            <CountryInfo countryName={secondaryCountry.name} center />
          </BoxPair>
        </ChartAndCaption>
      </ChartFlexRow>
      <ChartFlexRow>
        <ChartAndCaption caption={fetchTitle("24.4", "Story")}>
          <BarPair>
            <BarWrappedInDiv   proportion={fetchQuestionProportion(primaryCountry, "24.4")} boxFill="#0346AD" {...{animateGraphs}}/>
            <CountryInfo countryName={primaryCountry.name} perc={fetchQuestionPercent(primaryCountry, "24.4")}/>
            <BarWrappedInDiv   proportion={fetchQuestionProportion(secondaryCountry, "24.4")} boxFill="#A1ACFD" {...{animateGraphs}}/>
            <CountryInfo countryName={secondaryCountry.name} perc={fetchQuestionPercent(secondaryCountry, "24.4")}/>
          </BarPair>
        </ChartAndCaption>
      </ChartFlexRow>
    </div>
  );
}

export default Afford;
