import styles from './css-modules/navbar.module.css';
import { IoLogoTwitter, IoLogoLinkedin } from "react-icons/io5";
import { HiMenuAlt3 } from "react-icons/hi";
import { useRef } from 'react';
import invertedLogo from "../resources/invert.png";
import { IoCloseCircleOutline } from 'react-icons/io5';
import { TweenLite } from "gsap";

function Navbar({ nav, logo, assignRef, onRouteChange, curved, closeMobileNav, mobileNav, white }) {
    const openMobileNav = () => {
        document.body.style.overflow = 'hidden';
        TweenLite.fromTo(mobileNav.current, 0.65, {x: -100 + 'vw'}, {x: 0});
    }

  return (
    <nav ref={assignRef} className={`${styles.container} ${curved && styles.curved} ${white && styles.white}`}>
        <div className={styles.desk_nav_flexed}>
            <div to='/' onClick={() => window.open('https://connecthumanity.fund/')}>
                <img src={logo} className={styles.logo}></img>
            </div>
            <div className={styles.links_container}>
                {nav.length > 0 && nav.map((nav_item, index) => {
                    return <div onClick={() => onRouteChange('/' + nav_item.split(" ")[0].toLowerCase())} key={'deskNav' + index} className={styles.nav_item}><p>{nav_item}</p></div>
                })}
                <div className={styles.social_container}>
                    <IoLogoTwitter className={styles.social_icon}/>
                    <IoLogoLinkedin className={styles.social_icon}/>
                </div>
            </div>

            <HiMenuAlt3 onClick={() => openMobileNav()} className={styles.menu_icon}/>

            <div ref={mobileNav} className={styles.mobileNav}>
                <IoCloseCircleOutline className={styles.mobileNav_close} onClick={() => closeMobileNav()}/>

                <div className={styles.mobile_links_container}>
                    {nav.length > 0 && nav.map((nav_item, index) => {
                        return <div onClick={() => onRouteChange('/' + nav_item.split(" ")[0].toLowerCase())} key={'deskNav' + index} className={styles.nav_item}><p>{nav_item}</p></div>
                    })}
                </div>
                <div className={styles.social_container}>
                    <IoLogoTwitter className={styles.social_icon}/>
                    <IoLogoLinkedin className={styles.social_icon}/>
                </div>

                <img src={invertedLogo} className={styles.mobile_nav_logo}></img>
            </div>
        </div>
    </nav>
  );
}

export default Navbar;
