// import { useEffect } from 'react';
// import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import  TweenLite from 'gsap';
import { useEffect, useRef, useState } from 'react';
import { useInView } from "react-intersection-observer";

// props
// borderWidth, borderColor, borderStyle, borderRadius
const VizContain = styled.div`
  width: 100%;
  height: ${(props) => (props.type === "box" ? "unset" : "100%")};
  padding-top: ${(props) => (props.type === "box" ? "100%" : "unset")};
  position: relative;

  &::${(props) => (props.borderAbove ? "after" : "before")} {
    content: "";
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    border-width: ${(props) => props.borderWidth};
    border-color: ${(props) => props.borderColor};
    border-style: ${(props) => props.borderStyle};
    border-radius: ${(props) => props.borderRadius};
  }
`;

// props
// proportion, boxFill
const PropBox = styled.div`
  width: ${(props) =>
    props.type === "box"
      ? Math.sqrt(props.proportion) * 100
      : props.proportion * 100}%;
  height: ${(props) =>
    props.type === "box" ? Math.sqrt(props.proportion) * 100 : 100}%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: ${(props) => props.boxFill};
  border-radius: ${(props) => props.borderRadius};
  transform: scale3d(0, 0, 0);
`;

const PropContain = styled.div`
  height: 100%;
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.proporFontSize};
  font-weight: 500;
  opacity: 0;
  transform: translateY(-10px);

  & div.textDecor {
    width: 40%;
    height: 40%;
    border-radius: 50%;
    background-color: #ebebeb00;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const BoxProportion = ({
  borderWidth,
  borderColor = "#EBEBEB",
  borderStyle = "solid",
  proportion,
  proporFontSize = "22px",
  boxFill,
  borderRadius,
  type,
  borderAbove = false,
  transitionDuration,
  transitionDelay
}) => {

  const [init, setInit] = useState(true);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const textRef = useRef(null);

  useEffect(() => {
    if (inView && init) {
      if (type === 'bar') {
        entry.target.style.transform = 'scale3d(0, 1, 0)';
        TweenLite.fromTo(entry.target, transitionDuration, {scaleX: 0}, {scaleX: 1, transformOrigin: 'left center'}).delay(transitionDelay);
      }
      else {
        TweenLite.fromTo(entry.target, transitionDuration, {scale: 0}, {scale: 1, transformOrigin: 'left bottom'}).delay(transitionDelay);
      }
      TweenLite.fromTo(textRef.current, 0.35, {opacity: 0, y: -10}, {opacity: 1, y: 0}).delay(transitionDelay + transitionDuration);
      setInit(false);
    }
  }, [inView, init]);


  useEffect(() => {
    if (entry) {
      setInit(true);
    }
  }, [proportion]);

  return (
    <VizContain
      {...{
        borderWidth,
        borderColor,
        borderStyle,
        borderRadius,
        borderAbove,
        type,
      }}
    >
      <PropBox
        ref={ref}
        {...{
          proportion,
          boxFill,
          borderRadius,
          type,
        }}
      />
      <PropContain ref={textRef} proporFontSize={proporFontSize}>
        {type === "box" ? (
          <div className="textDecor">{`${Math.round(proportion * 100)}%`}</div>
        ) : (
          `${Math.round(proportion * 100)}%`
        )}
      </PropContain>
    </VizContain>
  );
};

const BarWrappedInDiv = ({ proportion, boxFill }) => {
  return (
    <div style={{ width: "100%", height: "30px" }}>
      <BoxProportion
          borderWidth = '2px'
          proportion = {proportion}
          proporFontSize = "17px"
          boxFill={boxFill}
          borderRadius = '5px'
          type="bar"
          transitionDuration={0.5}
          transitionDelay={0.125}
      />
    </div>
  );
};

export { BarWrappedInDiv, BoxProportion };
