import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./views/home";
import Voices from "./views/voices";
import Dash from "./views/dashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { useLayoutEffect, useState, useRef, useEffect } from "react";
import logo from "./resources/cropped.png";
import { TimelineLite, TweenLite, Power2 } from "gsap";
import { countryInfoObj } from "./data/countryInfo";
import { dashboardData } from "./data/dashboard";

function addPropertyType(arr, type) {
  let objArr = [];
  for (let i = 0; i < arr.length; i++) {
    objArr[i] = {
      name: arr[i],
      type: type,
    };
  }
  return objArr;
}

function App() {
  const decor = useRef();

  let location = useLocation();
  let navigate = useNavigate();

  let mobileNav = useRef();

  const [pageFade, setPageFade] = useState(true);
  const [currentPath, setCurrentPath] = useState(null);

  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);

  const [general, setGeneral] = useState([]);
  const [access, setAccess] = useState([]);
  const [afford, setAfford] = useState([]);
  const [policy, setPolicy] = useState([]);
  const [digital, setDigital] = useState([]);

  const [dropDownList, setDropDownList] = useState([]);
  const [comparisonList, setComparisonList] = useState([]);

  const [primaryCountry, setPrimaryCountry] = useState({
    name: "Global North Average",
    type: "Globals",
  });
  const [secondaryCountry, setSecondaryCountry] = useState({
    name: "Global Average",
    type: "Globals",
  });

  const [voicesRegion, setVoicesRegion] = useState({
    name: "North America",
    type: "Regions",
  });

  useEffect(() => {
    setCurrentPath(location.pathname);

    let countryArr = Object.values(countryInfoObj);
    setCountries(countryArr);

    let globals = [
      { name: "Global Average", type: "Globals" },
      { name: "Global North Average", type: "Globals" },
      { name: "Global South Average", type: "Globals" },
    ];

    let countryNames = [
      [...new Set(countryArr.map((item) => item["CH Country"]))],
    ][0];

    countryNames = countryNames.sort((a, b) => a.localeCompare(b));

    countryNames = addPropertyType(countryNames, "Countries");

    let regions = [[...new Set(countryArr.map((item) => item["CH Region"]))]];
    regions = addPropertyType(regions[0], "Regions");
    setRegions(regions);

    let ddList = globals.concat(regions).concat(countryNames);

    setDropDownList(ddList);
    setComparisonList(ddList);

    let generalContent = dashboardData.filter((el) => el.Section === "General");
    setGeneral(generalContent);

    let accessContent = dashboardData.filter(
      (el) => el.Section === "Access & Infrastructure"
    );
    setAccess(accessContent);

    let affordContent = dashboardData.filter(
      (el) => el.Section === "Affordability"
    );
    setAfford(affordContent);

    let policyContent = dashboardData.filter((el) => el.Section === "Policy");
    setPolicy(policyContent);

    let digitalContent = dashboardData.filter(
      (el) => el.Section === "Digital Skills"
    );
    setDigital(digitalContent);
  }, []);

  const onRouteChange = (to) => {
    if (to === '/about') {
      window.open('https://docs.google.com/document/d/1gKnII_Tz4vFIHQ2jyGX13fsM77OcQc5EDe6lB2WZ2fc');
    }
    else {
      let toLink = to === '/state' ? '/' : to;
      if (toLink !== currentPath) {
        const tl = new TimelineLite();
        tl.fromTo(
          decor.current,
          0.45,
          { ease: Power2.easeIn, y: window.innerHeight * 1.2 },
          { y: 0 }
        ).then(function () {
          window.scrollTo(0,0);
          navigate(toLink);
          if (document.body.style.overflow === 'hidden') {
            document.body.style.overflow = 'scroll';
          }
        });
      }
      else {
        closeMobileNav();
      }
    }
  };

  const closeMobileNav = () => {
    const tlC = new TimelineLite();
    tlC.fromTo(mobileNav.current, 0.65, {x: 0}, {x: 100 + 'vw'}).then(function () {
        document.body.style.overflow = 'scroll';
    });;
  } 

  useLayoutEffect(() => {
    if (!pageFade && currentPath !== location.pathname) {
      const tl = new TimelineLite();
      tl.to(decor.current, 0.4, {
        ease: Power2.easeInOut,
        y: -window.innerHeight,
      })
        .delay(0.2)
        .then(function () {
          decor.current.style.transform = "translateY(120vh)";
          setCurrentPath(location.pathname);
          setPageFade(true);
        });
    }
  }, [location]);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={<Home {...{ logo, pageFade, setPageFade, onRouteChange, closeMobileNav, mobileNav }} />}
        />
        <Route
          exact
          path="/voices"
          element={
            <Voices
              {...{
                logo,
                pageFade,
                setPageFade,
                onRouteChange,
                regions,
                voicesRegion,
                setVoicesRegion,
                closeMobileNav,
                mobileNav
              }}
            />
          }
        />
        <Route
          exact
          path="/data"
          element={
            <Dash
              {...{
                logo,
                pageFade,
                setPageFade,
                onRouteChange,
                countries,
                general,
                access,
                afford,
                policy,
                digital,
                dropDownList,
                comparisonList,
                primaryCountry,
                setPrimaryCountry,
                secondaryCountry,
                setSecondaryCountry,
                closeMobileNav,
                mobileNav
              }}
            />
          }
        />
      </Routes>

      <div ref={decor} className="route_animator"></div>
    </div>
  );
}

export default App;
