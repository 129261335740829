import Navbar from '../components/navbar';
import styles from './css-modules/common.module.css';
import { useRef, useLayoutEffect } from 'react';
import TweenLite from 'gsap';
import Landing from '../components/landing';
import Footer from '../components/footer';
import Desc from '../components/desc';
import Wheel from '../components/wheel';
import ReportDownload from '../components/report_download';

function Home({ logo, pageFade, setPageFade, onRouteChange, closeMobileNav, mobileNav }) {

    const navRef = useRef();
    const contentRef = useRef();

    useLayoutEffect(() => {
        if (pageFade) {
            TweenLite.fromTo(navRef.current, 0.45, {opacity: 0, y: -10}, {opacity: 1, y: 0}).delay(0);
            TweenLite.fromTo(contentRef.current, 0.8, {opacity: 0, y: 20}, {opacity: 1, y: 0}).delay(0.4);
            setPageFade(false);
        }
    }, [pageFade]);

    return (
        <div>
            <Navbar assignRef={navRef} nav={['State of Digital Inequity', 'Data Dashboard', 'Voices of Civil Society', 'About the Dashboard']} logo={logo} onRouteChange={onRouteChange} closeMobileNav={closeMobileNav} mobileNav={mobileNav}/>
            <div ref={contentRef} className={`${styles.content_container}`}>
                <Landing heading='State of Digital Inequity'/>
                <Desc title='What is Digital Equity? '
                    render={
                        <>
                            <p>
                                Digital Equity is a state in which all individuals have the digital access, tools, and skills they need to operate in our digital society.
                            </p>
                            <p>
                                Yet half of humanity is without adequate internet access, leaving billions of people excluded from rights and opportunities to learn and to earn, to access healthcare, online banking and government services, to build community and to make their voice heard.
                            </p>
                            <p>
                                This unconnected half are overwhelmingly our most vulnerable, most marginalized communities — people that civil society organizations seek to serve. To advance digital equity, we must better understand the digital barriers that civil society organizations and the people they serve face. 
                            </p>
                            <p>
                                That’s why, in 2023, Connect Humanity published the <a style={{color: '#A1ACFD'}} target="_blank" rel="noreferrer" href="https://connecthumanity.fund/digital-inequity-report/">State of Digital Inequity Report</a> in partnership with TechSoup. The report is based on a survey of 7,500+ civil society organizations (CSOs) from 136 countries who answered questions about the barriers that they, and the communities they serve, face when it comes to digital equity. 
                            </p>
                            <p>
                                Data was collected across the five elements of Connect Humanity's Digital Equity Framework: <ol><li>Infrastructure & Access</li> <li>Affordability</li><li>Digital Skills</li> <li>Policy</li> <li>Content.</li></ol>
                            </p>
                            <p>
                                Digital Equity is built on the five elements below.
                            </p>
                        </>
                    }
                />
                <Wheel {...{onRouteChange}}/>
                <ReportDownload {...{onRouteChange}}/>
                <Footer {...{logo}}/>
            </div>
        </div>
    );
}

export default Home;
