const comments = [
  {
    Language: "Hindi",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "Equipment need for digital skills in civil society. our demand to government for chief net recharge. digital skills for all.",
    English:
      "Equipment need for digital skills in civil society. our demand to government for chief net recharge. digital skills for all.",
    "First ResponseID": 11347,
  },
  {
    Language: "Hindi",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "मानवाधिकार और कानूनी जागरूकता, शिकायत निवारण में डिजिटल प्रबंधन को लेकर काम कर रहा हूँ. इस सन्दर्भ में यदि कोई तकनिकी, सलाह, उपकरण और आर्थिक   सहायता प्रदान कर सकते है तो अवश्य करें.",
    English:
      "I'm working on digital management for legal awareness grievance redressal and human rights. If anyone can help with technical support advice equipment or financial support in this regard please do so.",
    "First ResponseID": 4449,
  },
  {
    Language: "Hindi",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "जिनकी हम सेवा करते है उनमे से अधिकांश लोगों पढ़ें लिखे नहीं होते है इस कारन वो इन्टरनेट की कई सुविधाओं का लाभ नहीं ले पाते है l और वो स्मार्ट फ़ोन का इस्तेमाल करने में भी असहज महसूस करते है",
    English:
      "Since the majority of the people we assist lack education they are unable to use many of the internet's features. Additionally they experience discomfort when using smartphones.",
    "First ResponseID": 4418,
  },
  {
    Language: "Hindi",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "सबसे बड़ी चुनौती  डिजिटल तकनीकी पर बढ़ता खर्च है|  साथ ही इनके उपकरणों में ताजी से होता बदलाव अलग ही समस्या पैदा कर रहा है।",
    English:
      "The biggest challenge is the increasing expenditure on digital technology. Also the recent changes in their equipment are creating a different problem.",
    "First ResponseID": 9194,
  },
  {
    Language: "Hindi",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "डिजिटलीकरण से साइबर क्राइम और हैकर कि संख्या बढ़ना चुनौती है !इसको रोक पाना असंभव है !",
    English:
      "The difficulty is that digitization will lead to an increase in cybercrime and hackers. There is no way to stop it!",
    "First ResponseID": 8664,
  },
  {
    Language: "Hindi",
    "Country Code": "IN",
    "HQ Country": "India",
    Original: "We are unable to hire digitally trained manpower",
    English: "We are unable to hire digitally trained manpower",
    "First ResponseID": 11993,
  },
  {
    Language: "English",
    "Country Code": "AF",
    "HQ Country": "Afghanistan",
    Original:
      "Accessibility to the internet is the primary issue in Afghanistan. This is followed by a lack of training and digitalization.",
    English:
      "Accessibility to the internet is the primary issue in Afghanistan. This is followed by a lack of training and digitalization.",
    "First ResponseID": 8215,
  },
  {
    Language: "Romainian",
    "Country Code": "AF",
    "HQ Country": "Afghanistan",
    Original:
      "Parcurgand chestionarul mi-am dat seama ca organizatia noastra care lucreaza cu cele mai defavorizate comunitati si categorii de beneficiari, a renuntat in a face investitii in technologie, softuri si training-uri pentru angajati. Motivul se reducuce intotdeauna la resursa financiara. Am preferat sa putem ajuta efectiv oamenii in defavoarea dezvoltarii organizatiei. Acest chestionar ne da ocazia sa reflectam putin",
    English:
      "Going through the survey, I have realized that our organization - which works with the most underserved comunities and categories of beneficiaries- has given up on investing in technology, software and employee training. The reason has always been the (lack of) financial resources. We have chosen to help the people at the expense of developing our organization. This survey has given us the opportunity to reflect on it.",
    "First ResponseID": 2675,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "AR",
    "HQ Country": "Argentina",
    Original:
      "Trabajamos remotamente con comunidades que no tienen acceso a servicios básicos como agua potable, tubería y saneamiento, energía, etc. El acceso a internet es fundamental y debería ser un derecho, pero para el estado que rige a las comunidades no es visto cómo una prioridad en tanto no se cubra el acceso a otros servicios incluso más básicos.",
    English:
      "We work remotely with communities that do not have access to basic services such as drinking water, pipelines and sanitation, energy, etc. Internet access is fundamental and should be a right, but for the state governing the communities it is not seen as a priority as long as access to other even more basic services is not covered.",
    "First ResponseID": 8940,
  },
  {
    Language: "English",
    "Country Code": "AU",
    "HQ Country": "Australia",
    Original: "AI already has the potential to disrupt everything.",
    English: "AI already has the potential to disrupt everything.",
    "First ResponseID": 5162,
  },
  {
    Language: "English",
    "Country Code": "AU",
    "HQ Country": "Australia",
    Original: "Greatest challenge is working with an ageing population",
    English: "Greatest challenge is working with an ageing population",
    "First ResponseID": 7645,
  },
  {
    Language: "English",
    "Country Code": "AU",
    "HQ Country": "Australia",
    Original:
      "having people who are digitally informed and have expertise and then getting the right equipment to make it work is always a challenge for us.",
    English:
      "having people who are digitally informed and have expertise and then getting the right equipment to make it work is always a challenge for us.",
    "First ResponseID": 13942,
  },
  {
    Language: "English",
    "Country Code": "AU",
    "HQ Country": "Australia",
    Original:
      "I am totally unpaid as a volunteer for this single parent social support organisation. Approximately around 25% of our organisation's members have become older citizens age 70+. It seems these few feel that the internet technology is beyond their learning skills, so the only way I can have contact with them is via landline phone, mobile phone, post mail or in person. But I do use the internet every day to help me to supply all of our members with their social activities program either via email or via print post.",
    English:
      "I am totally unpaid as a volunteer for this single parent social support organisation. Approximately around 25% of our organisation's members have become older citizens age 70+. It seems these few feel that the internet technology is beyond their learning skills, so the only way I can have contact with them is via landline phone, mobile phone, post mail or in person. But I do use the internet every day to help me to supply all of our members with their social activities program either via email or via print post.",
    "First ResponseID": 5223,
  },
  {
    Language: "English",
    "Country Code": "AU",
    "HQ Country": "Australia",
    Original:
      "I fear digital inequality, where there is a two-stream economy-those with digital access and competency, and those without...",
    English:
      "I fear digital inequality, where there is a two-stream economy-those with digital access and competency, and those without...",
    "First ResponseID": 13889,
  },
  {
    Language: "English",
    "Country Code": "AU",
    "HQ Country": "Australia",
    Original:
      "I think the greatest challenges are the perennial ones for IT - the constant change in the sector; the ongoing threat of hackers; literacy, especially amongst older people",
    English:
      "I think the greatest challenges are the perennial ones for IT - the constant change in the sector; the ongoing threat of hackers; literacy, especially amongst older people",
    "First ResponseID": 16567,
  },
  {
    Language: "English",
    "Country Code": "AU",
    "HQ Country": "Australia",
    Original:
      "It costs a lot to be connected when you live, work and travel in remote locations in Outback Australia.  Digital literacy is very low in outback Australia.  First Nations Australian living in remote locations often can not afford computer or internet.",
    English:
      "It costs a lot to be connected when you live, work and travel in remote locations in Outback Australia.  Digital literacy is very low in outback Australia.  First Nations Australian living in remote locations often can not afford computer or internet.",
    "First ResponseID": 6599,
  },
  {
    Language: "English",
    "Country Code": "AU",
    "HQ Country": "Australia",
    Original:
      "Of course the first step is to be aware that many people don't even know what they need to know.  I don't know about new and emerging technology that might benefit us and our clients.  i don't have the resources to have an IT specific staff member.",
    English:
      "Of course the first step is to be aware that many people don't even know what they need to know.  I don't know about new and emerging technology that might benefit us and our clients.  i don't have the resources to have an IT specific staff member.",
    "First ResponseID": 13828,
  },
  {
    Language: "English",
    "Country Code": "AU",
    "HQ Country": "Australia",
    Original:
      "Our greatest challenge has been the affordability of devices and access to quality training.",
    English:
      "Our greatest challenge has been the affordability of devices and access to quality training.",
    "First ResponseID": 16727,
  },
  {
    Language: "English",
    "Country Code": "AU",
    "HQ Country": "Australia",
    Original:
      "Ours is a social organisation made up of retired people who were mainly born before 1967 so a lot have missed out on computer training until later in their working life",
    English:
      "Ours is a social organisation made up of retired people who were mainly born before 1967 so a lot have missed out on computer training until later in their working life",
    "First ResponseID": 13905,
  },
  {
    Language: "English",
    "Country Code": "AU",
    "HQ Country": "Australia",
    Original:
      "Regional venues where we conduct workshops often do not have internet access or do not invest in sufficient internet services. We are hamstrung wanting to digitise some of our feedback surveys but not having the means to do it.",
    English:
      "Regional venues where we conduct workshops often do not have internet access or do not invest in sufficient internet services. We are hamstrung wanting to digitise some of our feedback surveys but not having the means to do it.",
    "First ResponseID": 5183,
  },
  {
    Language: "English",
    "Country Code": "AU",
    "HQ Country": "Australia",
    Original:
      "The digital world is not for everyone but the whole system seems to get set up to have to have a computer or mobile even if not interested, and i think this is wrong",
    English:
      "The digital world is not for everyone but the whole system seems to get set up to have to have a computer or mobile even if not interested, and i think this is wrong",
    "First ResponseID": 6607,
  },
  {
    Language: "English",
    "Country Code": "AU",
    "HQ Country": "Australia",
    Original:
      "The previous government cut cost with deploying new internet infrastructure. we were meant to have fibre optic run to every house and business in the country but they decided to run copper for the remainder of the deployment which ended up costing more and now over half the country has terrible internet.",
    English:
      "The previous government cut cost with deploying new internet infrastructure. we were meant to have fibre optic run to every house and business in the country but they decided to run copper for the remainder of the deployment which ended up costing more and now over half the country has terrible internet.",
    "First ResponseID": 6728,
  },
  {
    Language: "English",
    "Country Code": "BS",
    "HQ Country": "Bahamas",
    Original:
      "Make more software options available to countries outside of the United States.",
    English:
      "Make more software options available to countries outside of the United States.",
    "First ResponseID": 10747,
  },
  {
    Language: "English",
    "Country Code": "BD",
    "HQ Country": "Bangladesh",
    Original: "My organisation is for deaf people.  So I need everything.",
    English: "My organisation is for deaf people.  So I need everything.",
    "First ResponseID": 3200,
  },
  {
    Language: "Dutch",
    "Country Code": "BE",
    "HQ Country": "Belgium",
    Original:
      "Voor mensen met ASS is het internet heel dubbel, ze zijn heel vaak verdiept in het spelen van online spelletjes, maar ze kennen de realiteit niet. Men zou vaker moeten waarschuwen voor de ernst van het verslaafd zijn aan digitale spellen",
    English:
      "For people with ASD, the Internet is very double, they are very often engrossed in playing online games, but they do not know the reality. One should warn more often about the seriousness of being addicted to digital games",
    "First ResponseID": 16095,
  },
  {
    Language: "Dutch",
    "Country Code": "BE",
    "HQ Country": "Belgium",
    Original:
      "Mijn droom is dat we onze kinderen en jongeren allemaal toegang kunnen geven tot digitalisering en digitale geletterdheid.",
    English:
      "My dream is that we can all give our children and youth access to digitization and digital literacy.",
    "First ResponseID": 18826,
  },
  {
    Language: "French",
    "Country Code": "BE",
    "HQ Country": "Belgium",
    Original:
      "L'accès aux logiciels et au matériel reste encore assez onéreux pour les personnes sans emploi et pour les petites associations comme la nôtre.",
    English:
      "Access to software and hardware is still quite expensive for unemployed people and for small organizations like ours.",
    "First ResponseID": 19135,
  },
  {
    Language: "French",
    "Country Code": "BE",
    "HQ Country": "Belgium",
    Original:
      "Il faut de plus en plus d'outils digitaux pour coordonner les actions.  Le problème pour les petites associations travaillant avec des bénévoles et petits budget, c'est que c'est impayables (Office 365, etc.) et cela demande des compétences poussées pour utiliser l'engin au-delà du simple espace collaboratif de stockage (qui nécessite déjà des compétences variées !). Par ailleurs, le caractère privé des données devient de plus en plus problématique.",
    English:
      "More and more digital tools are needed to coordinate actions.  The problem for small organizations working with volunteers and small budgets is that it's unaffordable (Office 365, etc.) and it requires advanced skills to use it beyond the simple collaborative storage space (which already requires various skills!). Moreover, the privacy of data is becoming more and more problematic.",
    "First ResponseID": 11206,
  },
  {
    Language: "French",
    "Country Code": "BE",
    "HQ Country": "Belgium",
    Original:
      "Problématique hyper importante et d'actualité brulante. Nous pensons qu'on jugera, à l'avenir, toute société démocratique à la manière dont elle aura \"géré\" cette matière, démocratiquement.",
    English:
      'A very important and topical issue. We think that in the future, any democratic society will be judged by the way it will have "managed" this matter, democratically.',
    "First ResponseID": 16466,
  },
  {
    Language: "French",
    "Country Code": "BE",
    "HQ Country": "Belgium",
    Original:
      "Accessibilité pour les personnes handicapées mentales : matériel et programmes",
    English:
      "Accessibility for people with learning disabilities: equipment and programs",
    "First ResponseID": 19136,
  },
  {
    Language: "French",
    "Country Code": "BE",
    "HQ Country": "Belgium",
    Original:
      "Actuellement, notre parc résidentiel se trouve dans une zone blanche avec pour certains un accès ADSL inférieur à 1MB/s. Pour d'autres, la connexion internet 4G n'est pas suffisamment fiable ni rapide (trop éloigné des bornes d'accès).  Nous souhaiterions proposer aux résidents une meilleure connexion mais le coût est trop élevé car nous sommes en partie privée, les fournisseurs ne prennent donc pas le matériel en charge.",
    English:
      "Currently, our residential park is in a white zone with for some an ADSL access lower than 1MB/s. For others, the 4G internet connection is not reliable or fast enough (too far from the access points).  We would like to propose to the residents a better connection but the cost is too high because we are in a private area, so the providers do not take care of the material.",
    "First ResponseID": 11835,
  },
  {
    Language: "French",
    "Country Code": "BE",
    "HQ Country": "Belgium",
    Original:
      "Je donne cours de compétences digitales de base. Le plus gros souci, c'est qu'une fois la formation terminée, le public n'a pas de matériel chez soi pour pouvoir continuer à découvrir l'ordinateur et les outils sur internet.  Je donne une formation de 4 semaines, en sachant qu'ils ne pourront pas continuer leur aventure et que sans pratique régulière, ils perdront leurs compétences petit à petit.",
    English:
      "I teach basic digital skills. The biggest concern is that once the training is over, the public doesn't have any equipment at home to continue to discover the computer and the tools on the internet.  I give a 4 weeks training, knowing that they will not be able to continue their adventure and that without regular practice, they will lose their skills little by little.",
    "First ResponseID": 19134,
  },
  {
    Language: "French",
    "Country Code": "BE",
    "HQ Country": "Belgium",
    Original:
      "Beaucoup à faire dans le futur avec:  la formation des ainés  les moyens pour accéder à internet (bénéficiaires en précarité)",
    English:
      "Much to do in the future with: training of the elderly means to access the internet (beneficiaries in precariousness)",
    "First ResponseID": 13494,
  },
  {
    Language: "French",
    "Country Code": "BE",
    "HQ Country": "Belgium",
    Original:
      "Obtention de don pour engager du personnel acheter un véhicule adapté....",
    English:
      "Obtaining a grant to hire staff to purchase an adapted vehicle....",
    "First ResponseID": 17097,
  },
  {
    Language: "French",
    "Country Code": "BE",
    "HQ Country": "Belgium",
    Original:
      "Logiciels terriblement coûteux et souvent très peu adaptés aux activités que nous pratiquons.",
    English:
      "Software terribly expensive and often very little adapted to the activities we practice.",
    "First ResponseID": 11351,
  },
  {
    Language: "French",
    "Country Code": "BE",
    "HQ Country": "Belgium",
    Original:
      "Le prix (matériel et logiciel) oblige l'utilisation de solutions gratuites qui malheureusement sont moins connues et maitrisées par nos membres et limite aussi le catalogue de service que nous pouvons rendre.",
    English:
      "The price (hardware and software) requires the use of free solutions which unfortunately are less known and mastered by our members and also limits the catalog of services we can provide.",
    "First ResponseID": 16964,
  },
  {
    Language: "French",
    "Country Code": "BE",
    "HQ Country": "Belgium",
    Original:
      "Nous sommes une association qui a pour but de rompre la fracture numérique auprès d'un public en situation de difficultés.  Nous devons trouver les solutions aux problèmes apportés et les expliquer d'une façon compréhensive pour notre public.",
    English:
      "We are an organization whose goal is to break the digital divide with a public in difficulty.  We have to find solutions to the problems brought and explain them in a way that is understandable for our public.",
    "First ResponseID": 18882,
  },
  {
    Language: "French",
    "Country Code": "BE",
    "HQ Country": "Belgium",
    Original:
      "Nous travaillons avec des personnes en situation de handicap et relevons que la fracture numérique est de plus en plus importante. De plus en pus de services ne sont accessibles que par internet alors que tout le monde ne sait pas s'en servir ou même tout simplement lire et écrire. Comment remédier à cela? Un retour vers de l'humain semble de moins en moins possible...",
    English:
      "We work with people with disabilities and note that the digital divide is growing. More and more services are only accessible through the internet, but not everyone knows how to use it or even how to read and write. How to remedy this? A return to the human side seems less and less possible...",
    "First ResponseID": 11841,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "BO",
    "HQ Country": "Bolivia",
    Original:
      "¡Excelente trabajo con la encuesta!.  Sin embargo, creo que está claro para todos que, el problema de la democratización de Internet se basa en las políticas estatales que pretenden proteger los intereses privados de las compañías más que la necesidad de conectividad que hay en la sociedad, aunque evidentemente esto esta teniendo un cambio en América Latina, aun falta camino por andar. Pienso que definitivamente todo lo planteado aquí, se solucionaría con políticas gubernamentales más orientadas a los usuarios. Gracias nuevamente, saludos.",
    English:
      "Great job with the survey! However, I think it is clear to everyone that the problem of the democratization of the Internet is based on state policies that seek to protect the private interests of companies more than the need for connectivity that exists in society, although this is obviously having a change in Latin America, there is still a long way to go. I think that everything raised here (in survey) would definitely be solved with government policies that are more user-oriented. Thanks again, regards.",
    "First ResponseID": 15038,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "BO",
    "HQ Country": "Bolivia",
    Original:
      "Se debe trabajar con los gobiernos para que el Internet sea accessible a toda la población, por que es una herramienta de Trabajo, comunicación, y educación e información",
    English:
      "Work must be done with governments so that the Internet is accessible to the entire population, because it is a tool for work, communication, and education and information.",
    "First ResponseID": 8381,
  },
  {
    Language: "Spanish_S",
    "Country Code": "BO",
    "HQ Country": "Bolivia",
    Original:
      "Hola, somos una fundacion muy nueva, lamentablemente no tenemos mucha experiencia y conocimiento sobre tecnologias digitales, pero estamos muy convencidos que es una herramienta muy poderosa para llegar a nuestros donantes y tambien a los que deseamos ayudar. Gracias",
    English:
      "Hello, we are a very new foundation, unfortunately we do not have much experience and knowledge about digital technologies, but we are very convinced that it is a very powerful tool to reach our donors and also those we want to help. Thank you.",
    "First ResponseID": 8318,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "O desafio da [organização] é alcançar mais pessoas nas comunidades mais carentes, infelizmente as regiões mais pobres ainda não tem acesso à internet, e quando tem, o acesso é muito precário.",
    English:
      "[Organization redacted]'s challenge is to reach more people in poorer communities; unfortunately, the poorest regions still don't have access to the Internet, and when they do, access is very precarious.",
    "First ResponseID": 1115,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Dificuldade de acesso a ongs por mitivo de multiplas exigências que em áreas ruraus e outra a ong nao pode cumorir para receber o acesso",
    English:
      "Difficulty of access due to multiple requirements that many NGOs, especially in rural areas, cannot meet to receive access.",
    "First ResponseID": 16866,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Informações mais claras e faceis sobre os Termos de Uso de imagens, dados e compartilhamento",
    English:
      "Clearer and easier information about the Terms of authorization for image use and data sharing policy",
    "First ResponseID": 8543,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Cultura: algumas comunidades no Brasil possui um cultura extremamente machista que impedem que as mulheres tenham acesso livre a internet, não por questão de limitação tecnica, mas por questões de informação.  Isso é muito visivel no interior.",
    English:
      "Culture: some communities in Brazil have an extremely sexist culture that prevents women from having free access to the internet, not because of technical limitations, but because of information reasons. This is very visible in the countryside.",
    "First ResponseID": 12325,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Atualmente, tenho extrema dificuldade para executar as ações e projetos do Instituto em comunidades tradicionais, nas áreas periféricas e na zona rural. Tenho interesse de conversar e discutir os projetos para juntos buscarmos soluções.",
    English:
      "Currently, I find it extremely difficult to carry out the Institute's actions and projects in traditional communities, in peripheral areas and in rural areas. I am interested in talking and discussing the projects so that we can look for solutions together.",
    "First ResponseID": 4309,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Durante a pandemia tivemos muitas dificuldades em trabalhar com as comunidades. Eles não têm acesso fácil à Internet, e baixo conhecimento em Informática.",
    English:
      "During the pandemic we had a lot of difficulties working with communities. They do not have easy access to the Internet and have low knowledge of IT.",
    "First ResponseID": 4362,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "FACILITAR O ACESSO À INTERNET COM MAIOR ALCANCE E PREÇOS ACESSIVEIS",
    English:
      "Facilitate Internet access with greater range and affordable prices.",
    "First ResponseID": 11458,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original: "Ter acessibilidade a equipamento para a internet",
    English: "Have accessibility to equipment with internet access.",
    "First ResponseID": 19598,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      'Agradeço a oportunidade. O que eu quero acrescentar é que a velocidade da internet nos bairros que atendemos é muito cara em valores e com uma velocidade muito baixa (pacote de internet pela operadora de celular). Em nossa organização temos uma velocidade muito baixa e para aumentar a velocidade o valor fica muito além das nossas posses. Teríamos que tirar dinheiro de outras áreas essenciais no nosso atendimento para melhorarmos a nossa velocidade. O que fica inviável para atendermos a nossa "Missão, Visão e Valores".',
    English:
      "I appreciate the opportunity. What I want to add is that the internet in the communities we serve is very expensive in terms of values. In our organization we have a very low internet speed and increasing it is very expensive. In order to do this we would have to take money from other essential areas . What is not viable.",
    "First ResponseID": 1049,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Eu cobro muito o governo de meu município, pois estão se esforçando para digitalizar o governo, mas se esquecem ou fazem de conta que esquecem que há muitos excluídos digitais, que acabam prejudicados pela digitalização em curso.",
    English:
      "I call the city hall of my city to the problem of digital inclusion because they are making an effort to digitize the government, but they forget or pretend to forget that there are many digital excluded, who end up harmed by the ongoing digitization.",
    "First ResponseID": 9178,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Tenho questionado os gestores públicos no sentido de elogiar os esforços no sentido de tornar o governo digital, mas alertando para a grande parcela de nossa população que sofre a exclusão digital, seja por um grau de analfabetismo, ou por não terem acesso à Internet e a equipamentos de qualidade para acessar os serviços tecnológicos.",
    English:
      "I have questioned public managers in order to praise the efforts to make government services digital, but alerting them to the large portion of our population that suffers from digital exclusion, either because of a degree of illiteracy, or because much part of it does not have access to the Internet and the equipment to access technological services.",
    "First ResponseID": 1130,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Acho que o grande desafio institucional é a segurança digital, principalmente neste momento de crise política no Brasil. Em relação aos grupos apoiados, é entender como podemos dar apoio e recursos (sejam financeiros ou formação) para possibilitar a sua inclusão digital já que muitos ainda não possuem acesso a internet e equipamentos.",
    English:
      "I think the great institutional challenge is digital security, especially in this moment of political crisis in Brazil. Regarding the supported groups, it is important to understand how we can provide support and resources (whether financial or training) to enable their digital inclusion since many still do not have access to the internet and equipment.",
    "First ResponseID": 3264,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "gostaria de um melhor acesso a treinamento e aplicativos digitais e uso de mídias sociais.",
    English:
      "I would like better access to training to use social media and digital applications.",
    "First ResponseID": 1133,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original: "No Brasil em geral falta mais pontos de acesso wifi publico.",
    English:
      "In Brazil in general there is a lack of public wifi access points.",
    "First ResponseID": 19416,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original: "Na área onde trabalho falta dispositivos",
    English: "In the area where I work there are no devices",
    "First ResponseID": 8537,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Saber como usar os softwares. Hoje temos acesso a MS 365 mas usamos menos de 10% do seu potencial. Precisamos de apoio para implantar esses programas.",
    English:
      "Know how to use the software. Today we have access to MS 365 but we use less than 10% of its potential. We need support to implement these programs.",
    "First ResponseID": 1078,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Desafio é de que todos os atendidos e famílias tenham acesso à Internet",
    English:
      "Our biggest challenge is that all those assisted and their families have access to the Internet",
    "First ResponseID": 4326,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Nosso maior desafio é qualidade do serviço de internet oferecido a população!!",
    English:
      "Our biggest challenge is the quality of the internet service offered to the population!!",
    "First ResponseID": 17068,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Nosso maior desafio é conseguir mais equipamentos novos para manusear a internet com qualidade e também ns totalidade da nossa necessidade.",
    English:
      "Our biggest challenge is to get more new equipment to handle the internet with quality.",
    "First ResponseID": 11378,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Nosso maior desafio é dispormos de equipamento e conexão para uma boa formação das pessoas atendidas por nossa comunidade.",
    English:
      "Our biggest challenge is to have the equipment and connection for a good training of the people served by our organization.",
    "First ResponseID": 12195,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Nossa maior dificuldade atualmente é a falta de condições para adquirir equipamentos de informática: Computadores, Impressoras, etc.",
    English:
      "Our biggest challenge today is the difficulty in acquiring computer equipments: Pc's, printers, etc.",
    "First ResponseID": 1086,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "O maior desafio enfrentado pela maioria das organizações da sociedade civil, é o acesso a internet que ainda não chegou as comunidades em situação de vulnerabilidade social.",
    English:
      "The biggest challenge faced by most civil society organizations is access to the internet, which has not yet reached communities in situations of social vulnerability.",
    "First ResponseID": 4344,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "O maior desafio para as pessoas que eu atende é ter equipamentos e internet. Realizo um cursinho Comunitário Pré-vestibular Afrosan e muitos alunos não possuem equipamentos e internet, na pandemia muitos desistiram por falta de condições tecnológicas.",
    English:
      "The biggest challenge for the people served by our organization is to have the equipment and access to the internet. We offer a community Pre-university course and many students do not have equipment and access to the internet. In the pandemic, many gave up due to lack of technological conditions.",
    "First ResponseID": 2397,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original: "Desafio,acessibilidade para as pessoas de baixa renda.",
    English: "The biggest challenge is accessibility for low-income people.",
    "First ResponseID": 3412,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original: "Maior desafio são recursos financeiros escassos",
    English: "The biggest challenge is the limited financial resources",
    "First ResponseID": 10891,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "A maior dificuldade nos territórios de atuação da organização é a dificuldade de acesso aos computadores, normalmente são utilizados celulares, as vezes um único aparelho pra família inteira.",
    English:
      "The biggest difficulty in the organization's areas of operation is the difficulty of accessing computers. Cell phones are usually used, but sometimes a single device for the whole family.",
    "First ResponseID": 19412,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Maior dificuldade é o custo dos programas e qualificação para usa-los.",
    English:
      "The biggest difficulty is the cost of the software and the knowledge needed to use it.",
    "First ResponseID": 2351,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Os altos preços do dispositivos, ainda são um grande entrave para efetivação da acessibilidade digital.",
    English:
      "The high prices of the devices are still a major obstacle to effective digital accessibility.",
    "First ResponseID": 7642,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Está pesquisa foi muito extensa e as vezes não clara Os custos para melhoria da equidade digital ainda são um entrave",
    English:
      "This research was very extensive and sometimes unclear. The costs of improving digital equity are still an obstacle.",
    "First ResponseID": 4443,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Hoje o acesso a internet é praticamente do celular, e as pessoas não pagam por planos de internet para dispositvos móveis, somente o básico oferecido pela operadora, e então sempre precisam de acesso por wifi, e esse acesso que nem sempre está disponível para todos, e quando está, muitos usam, e o sistema não funciona como deveria",
    English:
      "Today, internet access is mostly done by cell phone, but people do not pay for internet plans for mobile devices, only the basic ones offered by the operator. Therefore, they always need wifi access, and this access is not always available for everyone, when it is, many use it, and the system doesn't work as it should.",
    "First ResponseID": 19485,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Dois desafios: 1- Acesso à educação híbrida tanto por parte dos educadores quanto dos estudantes. 2- Precisamos de dispositivos móveis de tamanho médio  que nos permitam circular, armazenar uma informação em algum tipo de plataforma de dados, porém sem necessitar da internet no momento de sua utilização. Pois muitas vezes temos que fazer um registro no momento do acontecimento. Nisso, o papel (para nós) ainda é o melhor aliado.",
    English:
      "Two challenges: 1- Access to hybrid education for both educators and students. 2- We need medium-sized mobile devices that allow us to store information on some type of data platform, but without needing the internet at the time of use. Because we often have to make a report at the moment of the event. In this, paper notebooks (for us) are still the best ally.",
    "First ResponseID": 1528,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Temos um acesso razoável a Internet e queremos montar uma sala de informática para que os jovens da comunidade possam começar a ser alfabetizados digitalmente, já temos o pessoal, a estrutura física, sala, faltam mesmo infraestrutura digital, as máquinas!!!",
    English:
      "We have reasonable access to the Internet and we want to set up a computer room so that the young people in the community can start to be digitally literate, we already have the staff, the physical structure, room, what is missing is the digital infrastructure, the equipments!!!",
    "First ResponseID": 16370,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "uma empresa que tenha sensibilidade com as organizações e suas dificuldades de infraestrutura e financeiras   no acesso à internet e que possa ajudá-las a se inserir nessa nova era do mundo digital.",
    English:
      "We need a company that is sensitive to organizations and their infrastructure and financial difficulties in accessing the internet and that can help them to enter this new era of the digital world.",
    "First ResponseID": 2372,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Necessitamos de mais políticas públicas em prol ao social infanto juvenil....",
    English:
      "We need more public policies in favor of children's social welfare....",
    "First ResponseID": 1552,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Atendemos estudantes de baixa renda em diversas regiões do Brasil, com especial atenção ao Norte e Nordeste. Muitas pessoas não tem equipamentos, então através de doações atendemos essa demanda. Porém, em muitas regiões não há internet, nem mesmo para acesso no celular, e as pessoas dependem de escolas, postos de saúde e organizações públicas para o acesso, que também é de baixa qualidade (velocidade e confiabilidade). Trabalhamos com estudantes de ensino médio que na época da pandemia tiveram grande prejuízo nos estudos por não terem acesso às aulas remotas.",
    English:
      "We serve low-income students in several regions of Brazil, especially in the North and Northeast. Many people don't have equipment, so through donations we meet this demand. However, in many regions there is no internet, not even for cell phone access, and people depend on schools, health centers and public organizations for access, which is also of low quality (speed and reliability). We work with high school students who at the time of the pandemic had great damage in their studies because they did not have access to remote classes.",
    "First ResponseID": 2971,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Trabalhamos em comunidades com alta vulnerabilidade social, em região dentro do Município com mais de 100.000 habitantes, as famílias as vezes tem um único celular, equipamento não muito bom, com isso não conseguem ser introduzidos no mundo digital. A organização tem em três bairros estrutura de informática, mas é muito tímido diante da demanda existente.",
    English:
      "We work in communities with high social vulnerability, in a region within a city with more than 100,000 inhabitants. Families sometimes have a single cell phone, usually not very good equipment, so they cannot be introduced to the digital world. The organization has computer labs in three districts, but it is insufficient in view of the existing demand.",
    "First ResponseID": 19473,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Trabalhamos com pessoas em vulnerabilidade social, despertá-los e capacitá-los para o mundo digital é muito importante! Mas precisam ter acesso a equipamentos e Internet.",
    English:
      "We work with socially vulnerable people, awakening and empowering them for the digital world is very important! But they need access to equipment and the Internet.",
    "First ResponseID": 8396,
  },
  {
    Language: "Portuguese_B",
    "Country Code": "BR",
    "HQ Country": "Brazil",
    Original:
      "Atuar na periferia e ver a dificuldade que eles tem para receber essa ferramenta tão importante nos dias de hoje nos deixa muita triste. Lutar para que esse acesso chegue a todos e de suma relevância, para uma equidade digital.",
    English:
      "Working in poor areas and seeing how difficult it is to access the internet - such an important tool nowadays - makes us very sad. Fighting for it to reach everyone is of utmost relevance.",
    "First ResponseID": 10708,
  },
  {
    Language: "Bulgarian",
    "Country Code": "BG",
    "HQ Country": "Bulgaria",
    Original:
      "Цензурата в социалните мрежи често е насочена срещу общността ни. Толерира се омразното говорене срещу ЛГБТИ",
    English:
      "Cenzorship in social media is very often targeted against our community. Hate speech against LGBTI people is tolerated.",
    "First ResponseID": 2519,
  },
  {
    Language: "Bulgarian",
    "Country Code": "BG",
    "HQ Country": "Bulgaria",
    Original:
      "Незадоволителната бързина на достъп до интернет услуги и съдържание в повечето случаи се дължи на остарелите служебни и потребителски компютри.",
    English:
      "Low speed and difficult access to the Internet services in most instances is due to outdated hardware.",
    "First ResponseID": 6750,
  },
  {
    Language: "English",
    "Country Code": "CM",
    "HQ Country": "Cameroon",
    Original:
      "Our greatest challenges at [redacted school] are: + Making the people we serve to realise the universe of possibility that can come with the internet + The cost and quality of internet services",
    English:
      "Our greatest challenges at [redacted school] are: + Making the people we serve to realise the universe of possibility that can come with the internet + The cost and quality of internet services",
    "First ResponseID": 19499,
  },
  {
    Language: "English",
    "Country Code": "CM",
    "HQ Country": "Cameroon",
    Original:
      "We need trainings or capacity buildings on affordable internet, on digital equity, on internet policies and on how to safely (and freely) use digital tools without fears to be spied by cyber hackers and by untrustful persons or organisations.",
    English:
      "We need trainings or capacity buildings on affordable internet, on digital equity, on internet policies and on how to safely (and freely) use digital tools without fears to be spied by cyber hackers and by untrustful persons or organisations.",
    "First ResponseID": 3222,
  },
  {
    Language: "English",
    "Country Code": "CM",
    "HQ Country": "Cameroon",
    Original:
      "I just realized that internet blackout is typical of African governments which serves as a repressive measure which is very weak.",
    English:
      "I just realized that internet blackout is typical of African governments which serves as a repressive measure which is very weak.",
    "First ResponseID": 16031,
  },
  {
    Language: "English",
    "Country Code": "CM",
    "HQ Country": "Cameroon",
    Original:
      "Make internet available for everyone worldwide is my greatest wish. The world will be a better place.",
    English:
      "Make internet available for everyone worldwide is my greatest wish. The world will be a better place.",
    "First ResponseID": 6783,
  },
  {
    Language: "English",
    "Country Code": "CM",
    "HQ Country": "Cameroon",
    Original:
      "Our greatest challenges is the cost of internet services and the poor state of internet services. Our access is low because the services are expensive, internet policies don't really protect the users, any time they can go off and become slow to upload.",
    English:
      "Our greatest challenges is the cost of internet services and the poor state of internet services. Our access is low because the services are expensive, internet policies don't really protect the users, any time they can go off and become slow to upload.",
    "First ResponseID": 7777,
  },
  {
    Language: "English",
    "Country Code": "CM",
    "HQ Country": "Cameroon",
    Original:
      "There is increasing needs for digital tools in Cameroon and I will be glad your products and services reaches to us.",
    English:
      "There is increasing needs for digital tools in Cameroon and I will be glad your products and services reaches to us.",
    "First ResponseID": 1768,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Rural residents do not have access to reliable Internet service and that became very evident during the shut downs in the pandemic!",
    English:
      "Rural residents do not have access to reliable Internet service and that became very evident during the shut downs in the pandemic!",
    "First ResponseID": 2221,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Affordability of mobile data and phone plans in Canada is terrible.",
    English:
      "Affordability of mobile data and phone plans in Canada is terrible.",
    "First ResponseID": 17761,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Affordability, and respectful education on how to use the technology, are some of the largest barriers people face here.",
    English:
      "Affordability, and respectful education on how to use the technology, are some of the largest barriers people face here.",
    "First ResponseID": 17414,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Affordability, reliability and digital skills are key success factors for our community going forward.",
    English:
      "Affordability, reliability and digital skills are key success factors for our community going forward.",
    "First ResponseID": 1938,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "As a growing non-profit charitable organization we find the expense of purchasing software to be the most difficult to justify in terms of expenses. Admin staff work from home so they are paying for their own internet access. It's not an expense we are covering organizationally.",
    English:
      "As a growing non-profit charitable organization we find the expense of purchasing software to be the most difficult to justify in terms of expenses. Admin staff work from home so they are paying for their own internet access. It's not an expense we are covering organizationally.",
    "First ResponseID": 10338,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Being in Canada access to the Internet is VERY expensive when compared to other countries.  The people we help also have the same issue as we only do our work in Canada.  There are only two major carriers who charge far too much.",
    English:
      "Being in Canada access to the Internet is VERY expensive when compared to other countries.  The people we help also have the same issue as we only do our work in Canada.  There are only two major carriers who charge far too much.",
    "First ResponseID": 17776,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Development of website, content and ongoing support for administrative volunteer is expensive for a small charitable organization.",
    English:
      "Development of website, content and ongoing support for administrative volunteer is expensive for a small charitable organization.",
    "First ResponseID": 3338,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Funders to our programs refuse to properly recognize digital literacy as a sole educational need and push back when only digital literacy skills are being delivered. There is a refusal to give consistent and sustained funding to these programs and it forces programs like ours to spend time and energy looking for funding elsewhere-much of which is project based. Additionally the role of the employer needs to be addressed. When employers contribute to toxic workplaces that stigmatize poor digital skills and refuse to invest in long term solutions to digital gaps we  are going nowhere.",
    English:
      "Funders to our programs refuse to properly recognize digital literacy as a sole educational need and push back when only digital literacy skills are being delivered. There is a refusal to give consistent and sustained funding to these programs and it forces programs like ours to spend time and energy looking for funding elsewhere-much of which is project based. Additionally the role of the employer needs to be addressed. When employers contribute to toxic workplaces that stigmatize poor digital skills and refuse to invest in long term solutions to digital gaps we  are going nowhere.",
    "First ResponseID": 18707,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Geo-centric content allocation is the biggest challenge with the global access to content.",
    English:
      "Geo-centric content allocation is the biggest challenge with the global access to content.",
    "First ResponseID": 17425,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Greatest challenge for the communities we serve is access to devices like laptops. This is important because they cannot look for jobs, apply for jobs, work on their resumes etc if they don't have the right computer. Everyone has a mobile but they can't use it for applying for work. We work with people who are struggling financially and access to a computer and internet is one of the main factors these days for reducing poverty. As we deal with new immigrants or refugee to the country, the clients we serve are limited in gaining access to training. We understand that training is available but very difficult for women who have to find child care while they are in training. These are all barriers for those who want to improve their digital skills.",
    English:
      "Greatest challenge for the communities we serve is access to devices like laptops. This is important because they cannot look for jobs, apply for jobs, work on their resumes etc if they don't have the right computer. Everyone has a mobile but they can't use it for applying for work. We work with people who are struggling financially and access to a computer and internet is one of the main factors these days for reducing poverty. As we deal with new immigrants or refugee to the country, the clients we serve are limited in gaining access to training. We understand that training is available but very difficult for women who have to find child care while they are in training. These are all barriers for those who want to improve their digital skills.",
    "First ResponseID": 2029,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "I struggle to get some members of our team (staff, volunteers) to comply with policies because they do not fully appreciate cybersecurity risks.",
    English:
      "I struggle to get some members of our team (staff, volunteers) to comply with policies because they do not fully appreciate cybersecurity risks.",
    "First ResponseID": 17578,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "in India their internet and cell phone service is a fraction of the cost to ours. how can it be? does it relate to higher population? can we learn from them to lower our costs here?",
    English:
      "in India their internet and cell phone service is a fraction of the cost to ours. how can it be? does it relate to higher population? can we learn from them to lower our costs here?",
    "First ResponseID": 17469,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Interested in collaborating with organizations that support digital equity in resource-scarce communities and organizations",
    English:
      "Interested in collaborating with organizations that support digital equity in resource-scarce communities and organizations",
    "First ResponseID": 17592,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Many of our members acquire digital devices which they do not fully understand how to operate.  This is a constant source of frustration for everyone involved -- both our members and our ISP volunteers.",
    English:
      "Many of our members acquire digital devices which they do not fully understand how to operate.  This is a constant source of frustration for everyone involved -- both our members and our ISP volunteers.",
    "First ResponseID": 18758,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Media is at the core of our service and streaming is the tool we use to reinforce all we do.",
    English:
      "Media is at the core of our service and streaming is the tool we use to reinforce all we do.",
    "First ResponseID": 17796,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "My biggest challenge has been being unable to access any of my Microsoft suite of programs for over a month because Microsoft volume licensing and cloud based software is a complete cluster fuck. 30 days of them being unable to provide proper tech support.  Makes me seriously question the merit of using their charitable software facility. Tech Soup needs to reevaluate how it works with software suppliers and clients like me.  30 days without proper tech support.  30 days unable to access software.  30 days unable to contribute to our virtual team. 30 days unable to earn any money.  It was not worth the couple hundred dollars we saved by taking advantage of Microsoft's discounted software.",
    English:
      "My biggest challenge has been being unable to access any of my Microsoft suite of programs for over a month because Microsoft volume licensing and cloud based software is a complete cluster fuck. 30 days of them being unable to provide proper tech support.  Makes me seriously question the merit of using their charitable software facility. Tech Soup needs to reevaluate how it works with software suppliers and clients like me.  30 days without proper tech support.  30 days unable to access software.  30 days unable to contribute to our virtual team. 30 days unable to earn any money.  It was not worth the couple hundred dollars we saved by taking advantage of Microsoft's discounted software.",
    "First ResponseID": 17545,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      'My organization has been trying to support the "gig" economy by creating an online network of "professionals" working from home offices. Our biggest hurdle is still getting people to work through the hiccups and the very steep learning curve. People are still very slow to adapt and need a lot of coaxing.',
    English:
      'My organization has been trying to support the "gig" economy by creating an online network of "professionals" working from home offices. Our biggest hurdle is still getting people to work through the hiccups and the very steep learning curve. People are still very slow to adapt and need a lot of coaxing.',
    "First ResponseID": 18387,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Our member demographics are currently skewed to people over the age of 65. Many have limited computer usage exposure and have often learned about the Internet and computers through trial and error.",
    English:
      "Our member demographics are currently skewed to people over the age of 65. Many have limited computer usage exposure and have often learned about the Internet and computers through trial and error.",
    "First ResponseID": 1971,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Our organization works directly with schools and teachers in their classrooms.  Access to reliable and fast internet for Elementary schools in Canada is still lagging behind home internet.  Bandwidth at a school gets very congested when multiple classes are using it at the same time.  We often have teachers report interrupted access due to slow internet, or older devices, or outdated district policies (restricting access to YouTube or our website).",
    English:
      "Our organization works directly with schools and teachers in their classrooms.  Access to reliable and fast internet for Elementary schools in Canada is still lagging behind home internet.  Bandwidth at a school gets very congested when multiple classes are using it at the same time.  We often have teachers report interrupted access due to slow internet, or older devices, or outdated district policies (restricting access to YouTube or our website).",
    "First ResponseID": 17484,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Our programs are run entirely by volunteers and are all for people with disabilities.  The bulk of our activities are sports for people with disabilities.  Secure software for registration and payments is expensive and likely cannot be customized to suit our 'special' needs.",
    English:
      "Our programs are run entirely by volunteers and are all for people with disabilities.  The bulk of our activities are sports for people with disabilities.  Secure software for registration and payments is expensive and likely cannot be customized to suit our 'special' needs.",
    "First ResponseID": 17615,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Safety literacy is becoming of utmost importance – people need to learn how to recognize phishing scams and how to protect vital public digital infrastructure, such as hospitals.",
    English:
      "Safety literacy is becoming of utmost importance – people need to learn how to recognize phishing scams and how to protect vital public digital infrastructure, such as hospitals.",
    "First ResponseID": 2935,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Serving a rural community that is spread out over several counties, it is difficult to have participants access the internet for reasons not listed. There was not an option to chose to say that no service was available at all in certain areas or service was unreliable due to the type of infrastruture (direct line of sight to a tower).  Even cellular service is spotty for some of our service areas.",
    English:
      "Serving a rural community that is spread out over several counties, it is difficult to have participants access the internet for reasons not listed. There was not an option to chose to say that no service was available at all in certain areas or service was unreliable due to the type of infrastruture (direct line of sight to a tower).  Even cellular service is spotty for some of our service areas.",
    "First ResponseID": 3644,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Small non-profit organizations have many of the same infrastructure needs but expend lots of labour and money on maintaining them (IT costs, social media & marketing, financial management) expansion and expert advice on shared business models would increase each organization's ability to operate and allow for greater collective bargaining.",
    English:
      "Small non-profit organizations have many of the same infrastructure needs but expend lots of labour and money on maintaining them (IT costs, social media & marketing, financial management) expansion and expert advice on shared business models would increase each organization's ability to operate and allow for greater collective bargaining.",
    "First ResponseID": 17524,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "The clients we serve are low income, seniors, homeless and street people. They seem to all have mobile devices with the exception of seniors. We hold 7 free fresh food markets and they can check for updates on our Facebook site, instagram.",
    English:
      "The clients we serve are low income, seniors, homeless and street people. They seem to all have mobile devices with the exception of seniors. We hold 7 free fresh food markets and they can check for updates on our Facebook site, instagram.",
    "First ResponseID": 2276,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "The greatest challenge is understanding the obstacles in the rural communities.  Add to that the age of volunteers.  Volunteers tend to be older people who don't understand the digital world and have no interest in learning.",
    English:
      "The greatest challenge is understanding the obstacles in the rural communities.  Add to that the age of volunteers.  Volunteers tend to be older people who don't understand the digital world and have no interest in learning.",
    "First ResponseID": 17466,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "The internet provider in our area has a monopoly so prices are extremely high and service is terrible.  Outside of town the service is that and also very slow.",
    English:
      "The internet provider in our area has a monopoly so prices are extremely high and service is terrible.  Outside of town the service is that and also very slow.",
    "First ResponseID": 17822,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "The learning curve for seniors is daunting. Many seniors are choosing not to have computers or to not use social media. Maintaining the equipment and services are too expensive for those on low or fixed incomes.",
    English:
      "The learning curve for seniors is daunting. Many seniors are choosing not to have computers or to not use social media. Maintaining the equipment and services are too expensive for those on low or fixed incomes.",
    "First ResponseID": 18667,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "The organization serves the Latinx community in the GTA.  Most of the work focusses in settlement and legal services.  Language and access to digital tools is a high need which the community requires.  Immigration and level education has an impact in building technology user comfort.",
    English:
      "The organization serves the Latinx community in the GTA.  Most of the work focusses in settlement and legal services.  Language and access to digital tools is a high need which the community requires.  Immigration and level education has an impact in building technology user comfort.",
    "First ResponseID": 17556,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "We are a non-profit supporting Manitoba First Nation health and social services programs. We provide infrastructure, consulting, and support for all eHealth and other programs. Our health centers are not for profit but some have not been registered as non-profits. We have begun to run into issues with Microsoft where we cannot achieve non-profit status but they have an exception for the Aboriginal and Torres Strait Islander Health Services. Our communities suffer from similar issues and would greatly benefit from the same exception but it has been difficult to find someone to help with this.",
    English:
      "We are a non-profit supporting Manitoba First Nation health and social services programs. We provide infrastructure, consulting, and support for all eHealth and other programs. Our health centers are not for profit but some have not been registered as non-profits. We have begun to run into issues with Microsoft where we cannot achieve non-profit status but they have an exception for the Aboriginal and Torres Strait Islander Health Services. Our communities suffer from similar issues and would greatly benefit from the same exception but it has been difficult to find someone to help with this.",
    "First ResponseID": 1958,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "We do not have the money to adequately address issues of digital equity.",
    English:
      "We do not have the money to adequately address issues of digital equity.",
    "First ResponseID": 6020,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "We serve a community that is economically challenged.  More and more we see them being left behind.  Most only have access via pay per smart phones.  And, more and more organizations and government require online access for information and services.",
    English:
      "We serve a community that is economically challenged.  More and more we see them being left behind.  Most only have access via pay per smart phones.  And, more and more organizations and government require online access for information and services.",
    "First ResponseID": 4847,
  },
  {
    Language: "English",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "We support a mixed community between urban and rural locations.  This is a greater disparity than income levels for access to internet and digital materials.  Rural internet is incredibly slow, expensive, and with limited availability.",
    English:
      "We support a mixed community between urban and rural locations.  This is a greater disparity than income levels for access to internet and digital materials.  Rural internet is incredibly slow, expensive, and with limited availability.",
    "First ResponseID": 1919,
  },
  {
    Language: "French",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "La littératie numérique est un enjeu majeur actuellement. Elle limite l'accès à plusieurs personnes dans notre société. Elle affecte particulièrement les générations âgées et les personnes vulnérables de notre société.",
    English:
      "Digital literacy is a major issue today. It limits access for many people in our society. It particularly affects the older generations and the vulnerable in our society.",
    "First ResponseID": 9854,
  },
  {
    Language: "French",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Plusieurs membres de notre communauté (ainsi que certains de nos employés) vivent en milieu rural ou semi-rural et n'ont pas accès à un Internet haute-vitesse stable. Le télétravail est difficilement possible. Nous vivons dans une \"région éloignée\" des grands centres. Notre région milite pour une meilleure accessibilité à l'internet ainsi qu'à la couverture cellulaire (qui n'est toujours pas disponible partout).",
    English:
      'Many members of our community (and some of our employees) live in rural or semi-rural areas and do not have access to stable high-speed Internet. Telecommuting is not easily possible. We live in a "remote area" from major centers. Our region is advocating for better access to the internet and cell phone coverage (which is still not available everywhere).',
    "First ResponseID": 10691,
  },
  {
    Language: "French",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "L'un de nos défi financier est l'accès à internet dans le site de l'église afin d'en faire profiter les participants. Cérémonies en ligne, activités éducatives où plusieurs de nos références sont sur internet...",
    English:
      "One of our financial challenges is the access to the internet in the church site to benefit the participants. Online ceremonies, educational activities where many of our references are on the internet…",
    "First ResponseID": 10301,
  },
  {
    Language: "French",
    "Country Code": "CA",
    "HQ Country": "Canada",
    Original:
      "Très difficile de choisir le bon logiciel en fonction de nos besoins. Beaucoup d'informations cachées/limitations/etc. qu'on ne découvre qu'après s'être engagés dans une solution...",
    English:
      "Very difficult to choose the right software for our needs. A lot of hidden information/limitations/etc. that we discover only after we commit to a solution…",
    "First ResponseID": 11361,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CL",
    "HQ Country": "Chile",
    Original:
      "Aún en la actualidad hay comunidades que no pueden acceder a servicios de internet estable, lo que los excluye de casi todas la iniciativas de nivel personal y profesional, de hecho, un caso interesante es como en la misma ciudad de Santiago de Chile, por temas de sobrepoblación la comuna de Estación Central, tiene una muy mala conectividad, de hecho conozco colegas y allegados que han tenido que mudarse de comuna para poder ejecutar un teletrabajo por ejemplo, hacen mención que a veces pasan más de 6 horas sin el servicio y en este caso sería prioritario poder plantear soluciones viables, ya que es un problema de todas las arista que se vea",
    English:
      "Even today there are communities that cannot access stable internet services, which excludes them from almost all initiatives at a personal and professional level, in fact, an interesting case is like in the same city of Santiago de Chile, due to issues of overpopulation in the Central Station commune, there is very poor connectivity, in fact I know colleagues and relatives who had to move from the commune to be able to telework for example, they mention that sometimes they spend more than 6 hours without the service and in this case, it would be a priority to be able to propose different solutions, since it is a very visible problem",
    "First ResponseID": 18417,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CL",
    "HQ Country": "Chile",
    Original:
      "Financiación para poder realizar capacitaciones y entrenamientos de manera presencial. El Estado no invierte en educación o  capacitación comunitaria, salvo cuando hay interés político. Todo lo planteado es importante, pero lo primero es la educación, no sirve entregar tecnología si no se sabe usar y aprovechar",
    English:
      "Financing to be able to roll out training sessions in person. The State does not invest in community education or training, except when there is political interest. Everything raised in the survey is important, but the first thing is education, it is useless to deliver technology if you do not know how to use and take advantage of it",
    "First ResponseID": 7344,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CL",
    "HQ Country": "Chile",
    Original:
      "Conseguir estabilidad de internet a un precio accesible. Gracias",
    English: "Get internet stability at an affordable price. Thank you",
    "First ResponseID": 7989,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CL",
    "HQ Country": "Chile",
    Original:
      "Me preocupa la situación de acceso a las TIC de las Personas con Discapacidad y de los Adultos Mayores.",
    English:
      "I am concerned about the situation of access to ICT for People with Disabilities and the Elderly population.",
    "First ResponseID": 7952,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CL",
    "HQ Country": "Chile",
    Original:
      "Considero que deberían existir mayores espacio de alfabetización digital, sobre todo para las personas de menores ingresos, quienes tienen escaso o nulo conocimiento de éstas. Podría, o más bien debería, ser un plan de gobierno, considerando que hoy en día la mayoría de los servicios se ofrecen por internet, además de que como sabemos ésta es una herramienta que rompe fronteras y podría entregar muchos beneficios a quienes son más vulnerables.",
    English:
      "I believe that there should be more space for digital literacy, especially for people with lower income, who have little or none knowledge about it. It could, or rather should, be a government plan, considering that today most services are offered online, in addition to the fact that, as we know, this is a tool that breaks borders and could deliver many benefits to those who are most vulnerable.",
    "First ResponseID": 19495,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CL",
    "HQ Country": "Chile",
    Original: "Creo que el mayor desafío es capacitar en habilidades digitales",
    English: "I think the biggest challenge is training in digital skills",
    "First ResponseID": 7346,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CL",
    "HQ Country": "Chile",
    Original: "Mayor  acceso a la formacion digital",
    English: "Increased access to digital training",
    "First ResponseID": 16675,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CL",
    "HQ Country": "Chile",
    Original:
      "Solo comentar los altos costos que uno debe pagar por los servicios de internet y lo que sería bueno es poder optar a capacitaciones a las herramientas digitales más actuales que van saliendo conforme avanza más la tecnología.",
    English:
      "Just commenting on the high costs that one must pay for internet services and what would be good is to be able to opt for training on the most current digital tools that are coming out as technology advances.",
    "First ResponseID": 7392,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CL",
    "HQ Country": "Chile",
    Original: "Mas alfabetización digital y acceso a TIC",
    English:
      "More digital literacy and access to information and comminucations technologies ICT",
    "First ResponseID": 7849,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CL",
    "HQ Country": "Chile",
    Original:
      "Uno de nuestros equipos está trabajando en temas de sesgo y equidad de género en relación con la inteligencia artificial. Otro equipo, en cómo democratizar la inteligencia artificial. Adicionalmente, estamos invirtiendo en hardware para poder crear contenidos de inteligencia artificial para la comunidad. Algunos de estos contenidos, ya están disponibles públicamente.",
    English:
      "One of our teams is working on issues of gender and equity in relation to artificial intelligence. Another team, on how to democratize artificial intelligence. Additionally, we are investing in hardware to be able to create artificial intelligence content for the community. Some of this content is already publicly available.",
    "First ResponseID": 7479,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CL",
    "HQ Country": "Chile",
    Original:
      "Que  nunca fue claro el proceso de elegibilidad para nuestra Fundación y lograr acceder finalmente a los beneficios gratuitos para acceder a  365..  Y aún no es claro explicarnos que pasó",
    English:
      "The eligibility process for our Foundation and finally being able to access the free benefits to access 365 was never clear. And it is still not clear to explain what happened",
    "First ResponseID": 1983,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CL",
    "HQ Country": "Chile",
    Original:
      "Tenemos un proyecto digital para los emprendedores rurales y no lo podemos iniciar, porque no todos los sectores cuentan con internet. :(",
    English:
      "We have a digital project for rural entrepreneurs and we cannot start it, because not all sectors have internet. :(",
    "First ResponseID": 18074,
  },
  {
    Language: "Korean",
    "Country Code": "CN",
    "HQ Country": "China",
    Original:
      "디지털 형평성은 공평하게 기술을 향유할 수 있고, 정보를 용이하게 접할 수 있도록 해주어, 이를 통해 비영리 기관의 사업개발, 집행 및 조직 구성원의 업무능력 제고에 매우 중요합니다. 그렇지만, 명품 페라리 차량을 지원해 주신다고 하셔도, 결국은 드라이버가 얼마나 잘 운전하는가 하는, '사람(조직원)'의 역량문제로 귀결됩니다. 코비드19 이후 디지털전환이 가속화 되는 상태에서 비영리 현장의 업무환경 역시 빠르게 변화하고 있습니다. 업무환경의 개선에 맞추어, 업무역량 제고를 위한 직무역량 강화교육, 인적 네트워크 등의 제반 프로그램이 있으면 좋을 것 같습니다.",
    English:
      'Digital equity allows you to utilize technology fairly and access information easily. This is critical to business development, execution, and empowerment of members of organizations.\n\nHowever, even if you supply luxury Ferrari vehicles, the result will eventually depend on the competence of the driver - the "persons" who make up the organization.\n\nWith digital transformation accelerating after Covid-19, the working environment of non-profit sites is also rapidly changing. It would be nice to have various programs in line with the improvement of the work environment, such as education programs for job competency enhancement, or network-building programs.',
    "First ResponseID": 7905,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "En la experiencia en nuestro trabajo lo más importante es el acceso a internet y la estabilidad del mismo en las zonas rurales para poder llegar con la oferta de programas de educación que brindamos de manera gratuita a la población vulnerable a nivel nacional.",
    English:
      "Considering the experience of our work, the most important thing is Internet access and its stability in rural areas to be able to reach the educational programs offer that we provide free of charge to the vulnerable population at a national level.",
    "First ResponseID": 1764,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "Fundamental el acceso digital a todos los niveles de edades, para los procesos de formación y cerrar la brecha",
    English:
      "Digital access is fundamental at all age levels, for training processes and to close the divide",
    "First ResponseID": 7909,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original: "Interesado en mejor el servicio de INTERNET, en la región.",
    English: "I am interested in better INTERNET service in the region.",
    "First ResponseID": 14445,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "Me gustaría que mi organización tenga herramientas digitales para poder enseñar y proporcionar  maquinaria para internet.",
    English:
      "I would like my organization to have digital tools to be able to teach and provide internet enginery.",
    "First ResponseID": 3459,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "Me gustaría fortalecer mi equipo de trabajo en las temáticas relacionadas para continuar desarrollando la Fundación",
    English:
      "I would like to strengthen my work team in related issues to continue developing our Foundation",
    "First ResponseID": 10408,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "En nuestra ciudad Cali, no todos los sectores tienen opciones de internet, por ejemplo en nuestra sede principal tuvimos que pagar un servicio carísimo para poder que nos instalaran fibra óptica y funcionar óptimamente.",
    English:
      "In our city Cali, not all sectors have internet options, for example in our main office we had to pay a very expensive service to be able to install optic -fiber network that works optimally.",
    "First ResponseID": 1793,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "En la región donde trabajamos los mayores desafíos que existen para la equidad digital son infraestructura tecnológica, accesibilidad a internet, equipos y accesorios para conectarse.",
    English:
      "In the region where we work, the biggest challenges that exist for digital equity are technological infrastructure, internet accessibility, equipment and accessories to connect.",
    "First ResponseID": 1758,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original: "Principal desafío: conectividad y falta de equipos",
    English: "Main challenge: connectivity and lack of equipment",
    "First ResponseID": 11373,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "La mayoría de las organizaciones sin ánimo de lucro presentan falencias o poca inversión en el aspecto técnológico porque el presupuesto se gasta en gran medida en la obras que realizan. Por esto agradecemos estas iniciativas.",
    English:
      "Most non-profit organizations have shortcomings or little investment in the technological aspect because the budget is largely spent on the operations work. For this we appreciate these initiatives.",
    "First ResponseID": 1778,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "La mayor barrera es tener los recursos económicos suficientes para llegar a más beneficiados, más regiones",
    English:
      "The biggest barrier is having sufficient financial resources to reach more beneficiaries, more regions",
    "First ResponseID": 9980,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "El mayor desafío que enfrentamos las IPS pequeñas como lo somos nosotros como fundación en donde aportamos al cierre de la brecha digital y el acercamiento de la tecnología en zonas rurales es el poco apoyo recibido por parte del estado y la poca existencia de insentivos que permitan que podamos desarrollar y avanzar en nuestra misión.",
    English:
      "The biggest challenge faced by small IPS (health services) like us as a foundation that contributes to closing the digital gap and bringing technology closer to rural areas is the little support received from the state and the scant existence of incentives that allow we can develop and advance our mission.",
    "First ResponseID": 15143,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "el mayor desafío en nuestra región es lograr acceso, calidad, continuidad y cobertura",
    English:
      "the biggest challenge in our region is to achieve access, quality, continuity and coverage",
    "First ResponseID": 1976,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "La distancia entre lo que reportan las entidades públicas encargadas de la equidad digital en mi país y la realidad de la infraestructura, accesibilidad y contenidos es ridícula. Nada de lo reportado como logro en zonas rurales y frecuentemente apartadas y frágiles. Que deberían ser la prioridad de cumple. En muchos programas se apoyan en los operadores que tienen antenas 2G en mal estado y no les prestan servicio pues las personas de esas comunidades tienen poco poder adquisitivo y no representan para los operadores un beneficio de mercado. Por lo tanto cumple. Con tener la antena, operando intermitentemente y en baja velocidad,  sin que ninguna autoridad verifique su óptimo funcionamiento. O mienten al reportar.",
    English:
      "The distance between what is reported by the public entities in charge of digital equity in my country and the reality of the infrastructure, accessibility and content is ridiculous. None of the reported as an achievement in rural and often remote and fragile areas, that should be priority, is achieved. In many programs, they rely on operators that have 2G antennas in poor condition and are not manteined because people of these communities have little economic power and don't represent a market benefit for the operators. Therefore it only accomplish having the antenna, operating intermittently and at a low speed, without any authority verifying its optimal operation. Or lie when reporting.",
    "First ResponseID": 1785,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "Toda la población  a la que ayudamos no tiene Internet, ni un celular o computador, hasta hora están aprendiendo  a manejar las redes sociales y todos desean validar la primaria, son adultos mayores con discapacidad. tenemos inscritos 80 personas",
    English:
      "The entire population we serve does not have Internet, or a cell phone or computer, until now they are learning to manage social networks and they all want to complete elementary school, they are older adults with disabilities. We have 80 people registered",
    "First ResponseID": 7886,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "El gremio caficultor del departamento del cauca al que atendemos, en su mayoría habita en el sector rural donde la accesibilidad a internet es muy bajo, y si lo hay, muy costoso.  Sería de gran ayuda poder beneficiar a estas comunidades.",
    English:
      "The majority of the coffee-growing union in the department of Cauca live in the rural sector where internet accessibility is very low, and if it exists, it is very expensive. It would be a great help to be able to benefit these communities.",
    "First ResponseID": 1774,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      'Actualmente nos encontramos realizando la iniciativa de fortalecimiento a organizaciones de la sociedad civil denominada "Sociedad Digital Incluyente" allí se promueve el buen uso de las comunicaciones para reducir la violencia en línea y la xenofobia, durante este proceso Cambio Sostenible ha identificado falencias en la sociedad civil sobre la comprensión de las comunicaciones digitales efectivas y los derechos digitales a nivel general.',
    English:
      'We are currently developing the initiative to strengthen civil society organizations called "Inclusive Digital Society", where the good use of communications is promoted to reduce online violence and xenophobia, during this process Sustainable Change has identified shortages in society on the understanding of effective digital communications and digital rights in general.',
    "First ResponseID": 16055,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "hemos prestado servicios de información   y conexión a internet a comunidades con equipos propios, para socialización de probelamas civiles y vulneración de derechos constitucionales,ante entidades gubernamentales  y gestión de soluciónes",
    English:
      "We have provided information and internet connection services to communities with their own equipment, in order to socialize civil problems and violation of constitutional rights, over government entities and management of solutions.",
    "First ResponseID": 8169,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "Esperamos superarnos y hacer lo mejor que podemos con lo que tengamos en nuestras manos para llevar la palabra de Dios y cambiar las vidas de cualquiera al que podamos llegar.",
    English:
      "We hope to improve ourselves and do the best we can with what we have in our hands to spread God's word and change the lives of anyone we can reach.",
    "First ResponseID": 9142,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CO",
    "HQ Country": "Colombia",
    Original:
      "Necesitamos que todos los niños cuenten con dispositivos tecnológicos y conectividad para que puedan obtener programas educativos, formativos, herramientas sustanciales para obtener conocimientos y experiencias que los impulsen al éxito.",
    English:
      "We need all children to have technological devices and connectivity so that they can obtain educational programs, training, substantial tools to obtain knowledge and experiences that drive them to success.",
    "First ResponseID": 2344,
  },
  {
    Language: "French",
    "Country Code": "CG",
    "HQ Country": "Congo (Congo-Brazzaville)",
    Original:
      "Le plus grand défi auquel nous nous sommes confronté est celui de n'est pas avoir les moyens nécessaires pour l'acquisition de matériels digital afin de révéler le défi d'équité digitale dont notre ONG/Association est confronté à se jour.",
    English:
      "The biggest challenge we face is that of not having the necessary means for the acquisition of digital materials in order to reveal the digital equity challenge that our NGO/Association is facing to date.",
    "First ResponseID": 8025,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CR",
    "HQ Country": "Costa Rica",
    Original:
      "Como organización, nuestro mayor desafío es el acceso a internet en algunos lugares debido a su situación socio política como en Cuba y Venezuela",
    English:
      "As an organization, our biggest challenge is internet access in some places due to their socio-political situation, such as Cuba and Venezuela.",
    "First ResponseID": 1866,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "CR",
    "HQ Country": "Costa Rica",
    Original:
      "El mayor desafío que tenemos es la equidad de género (igualdad entre hombres y mujeres en el trabajo cotidiano) Costa Rica goza de buen internet aunque en las zonas muy alejadas tienen problema todavía y hubo desigualdad en la educación con la pandemia pues, los muchachos (as), niños y (as) de las zonas rurales se quedaron atrás, por falta de infraestructura y equipos tecnológicos",
    English:
      "The biggest challenge we have is gender equity (equity between men and women in daily work) Costa Rica enjoys good internet, although in very remote areas they still have a problem and there was inequality in education with the pandemic, well, boys and children from rural areas were left behind, due to lack of infrastructure and technological equipment",
    "First ResponseID": 10424,
  },
  {
    Language: "Czech",
    "Country Code": "CZ",
    "HQ Country": "Czechia",
    Original:
      'Dostupné vzdělávání v rámci digitální gramotnosti v nestátním neziskovém sektoru (poskytování sociálních služeb pro osoby se zdravotním postižením) včetně uznatelnosti vynaložených nákladů v rámci rozpočtových položek "povinné vzdělávání" pro dotace z veřejných zdrojů.',
    English:
      "Available digital literacy education in the NGO sector (social services for people with disabbilities) including acknowledgment of conected costs as budget items (public/governmental grants)  compulsory education for public resources/grants",
    "First ResponseID": 12149,
  },
  {
    Language: "Czech",
    "Country Code": "CZ",
    "HQ Country": "Czechia",
    Original:
      "Ze zkušeností z jiných zemí mám názor, že se úroveň přístupu k digitálním nástrojům v Evropě snižuje ze severozápadu na jihovýchod, jako že na Balkáně je to nejhorší a ve Skandinávii nejlepší.",
    English:
      "From the experience from other countries I gather that the level of access to digital tools in Europe is decreasing from north-west to south-east meaning Balkan is the worst and Scandinavia the best.",
    "First ResponseID": 10825,
  },
  {
    Language: "Czech",
    "Country Code": "CZ",
    "HQ Country": "Czechia",
    Original:
      "Zajímám se o současný stav digitalizace v neziskových organizacích v Evropě. Realizujeme projekt orientovaný na digitální kompetence koordinátorů dobrovolníků a zajímáme se o všechna relevantní data.",
    English:
      "I am interested in the current state of digitalization in the European nonprofit sector. We are running a project focused on digital competences for volunteer coordinators and we are interested in all the relevant data.",
    "First ResponseID": 10042,
  },
  {
    Language: "Czech",
    "Country Code": "CZ",
    "HQ Country": "Czechia",
    Original:
      'Naše organizace se zabývá otázkou digitálního vyloučení, nemyslíme si však, že cestou je "zdigitalizování" všech. Myslíme si spíše, že cestou je uchovávání analogových cest pro lidi, kteří nechtějí digitální technologie využívat.  Ať už je to zachování hotovosti, či kontakt s úřady tváří v tvář, máme za to, že vždy bude existovat část lidí, kteří zkrátka digitální technologie z různých důvodů používat nechtějí a nebudou.  Více viz naše kampaň Právo na analog: https://digitalnisvobody.cz/kampane/pravo-na-analog/.',
    English:
      "Our organization is working in the field of digital exclusion, but we do not think that digitazing everyone is the way to go. We rather think that keeping analog options for people who do not want to use digital technology is the proper course of action. Be it keeping cash transactions in-person meetings with governmental officers we think there will always be people who will just not want to use digital technologies for different reasons. More about our campaign here: https://digitalnisvobody.cz/kampane/pravo-na-analog/.",
    "First ResponseID": 12753,
  },
  {
    Language: "Czech",
    "Country Code": "CZ",
    "HQ Country": "Czechia",
    Original:
      "Fenomén sociálních sítí a cílové skupiny, které na nich nejsou :-)",
    English:
      "Phenomenon of social networks and target groups which are on them :-)",
    "First ResponseID": 10097,
  },
  {
    Language: "Czech",
    "Country Code": "CZ",
    "HQ Country": "Czechia",
    Original: "Služby jsou drahé!",
    English: "Services are expensive!",
    "First ResponseID": 18241,
  },
  {
    Language: "Czech",
    "Country Code": "CZ",
    "HQ Country": "Czechia",
    Original:
      "největším problémem,  se kterými se potýkáme, je najít cenově dostupného správce našich počítačů - tzv. ajťáka, potřebujeme ho téměř neustále.  A další problém - neustálý vývoj internetových nástrojů - potřeba financovat nové nástroje nebo jen jejich aktualizaci (databázové systemy, HR systémy, e-shop, web a další), přičemž tomuto vývoji běžný člověk v organizaci nerozumí a pak je těžké si objednat a nedesignovat odpovídající nástroj. Ceny všeh těchto služeb jsou astronomické.",
    English:
      "the biggest problem we are facing is finding affordable IT admin - we need him constantly. Another problem - the ever evolving internet tools - the need to finance new tools or their update (database systems HR systems e-shop web etc.) while at the same time an ordinary person in the organization does not understand it and thus it is hard to request and design a proper tool/system. The price of such services is astronomical.",
    "First ResponseID": 10337,
  },
  {
    Language: "Czech",
    "Country Code": "CZ",
    "HQ Country": "Czechia",
    Original:
      "Zcela ignorované téma je kyberbezpečnostI ve školství, resp. jakákoliv snaha o nastavení jakýchkoliv procesů pro ICT ve školství ze strany MŠMT a MV, ať už jde o podporu financování správy ICT, nastavení jednotných metodik, uzákonění bezpečnostních pravidel pro školské systémy (viz 20 let zastaralý software Bakaláři, psaný na FoxPro, který je EOL od roku 2009), minimum security baseline, atp. Vládu téma bezpečných škol nezajímá, školy nemají finance a znalosti na nakupování softwaru, hardwaru nebo i té správy, což vede k brutálním únikům financí ze systému.",
    English:
      "The cybersecurity is a completely ignored topic in the education sector for example lack of efforts to set up  proper processes for ICT by the Ministry of Education and Ministry of Interior lack of financial support for ICT insufficient methodologies lack of proper laws guiding cybersecurity for educational systems (as evident by the 20 year old software called Bakaláři designed for FoxPro and still being used) minimum security baseline etc. The government does not care abotu the (cyber) security of our schools and schools do not have finances and knowledge for acquiring proper software hardware or IT services and tech support which leads to grossly inneficient use of available finances.",
    "First ResponseID": 10006,
  },
  {
    Language: "Czech",
    "Country Code": "CZ",
    "HQ Country": "Czechia",
    Original:
      "V Česku jsou velmi vysoké ceny internetových a telefonických služeb. A nedostatečné vědomí vlastních digitálních schopností.",
    English:
      "The price of internet connection and phone fees are very high in the Czech Republic. And insufficient awareness of one's own digital competencies.",
    "First ResponseID": 12570,
  },
  {
    Language: "Indonesian",
    "Country Code": "CZ",
    "HQ Country": "Czechia (Czech Republic)",
    Original:
      "Semoga semakin berkembang didalam semua mengakses semua pekerjaan di era digital saat ini",
    English:
      "Hopefully it will grow in all accessing all jobs in the current digital era",
    "First ResponseID": 12000,
  },
  {
    Language: "Indonesian",
    "Country Code": "CZ",
    "HQ Country": "Czechia (Czech Republic)",
    Original:
      "Tantangan terbesar file ku yg penting SK teriset dan terhapus oleh google,dan rawan hacker dan jaringan internet yg TDK stabil dan kurang cepat dan tidak cepat.",
    English:
      "The biggest challenge, my important files are often reset and deleted by google, and vulnerable to hackers and unstable internet networks and not fast and not fast.",
    "First ResponseID": 12730,
  },
  {
    Language: "English",
    "Country Code": "CD",
    "HQ Country": "Democratic Republic of the Congo",
    Original:
      "The Goverment of the Democratic Republic of Congo established a new ministry which deal with digital equity",
    English:
      "The Goverment of the Democratic Republic of Congo established a new ministry which deal with digital equity",
    "First ResponseID": 3431,
  },
  {
    Language: "English",
    "Country Code": "CD",
    "HQ Country": "Democratic Republic of the Congo",
    Original:
      "Nowadays, internet is the main concern so that a job moving forward.",
    English:
      "Nowadays, internet is the main concern so that a job moving forward.",
    "First ResponseID": 8872,
  },
  {
    Language: "French",
    "Country Code": "CD",
    "HQ Country": "Democratic Republic of the Congo",
    Original:
      "Dans notre travail au quotidien, nous avons besoins de l'outil informatique et particulièrement de l'internet. Les difficultés que nous éprouvons surtout en milieux ruraux et périurbains, celui de l'accès à l'électricité, l'accès aux outils informatiques comme les ordinateurs et autres suite à la cherté du prix, pas à l'auteur d'un jeune paysan soucieux de outils digitaux. On s'adapte selon les moyens à notre disposition et selon les milieux (agglomérations, cités actifs, etc.). Nous sollicitons un accompagnement ou soutien pour aider tant soit peu notre travail avec les bénéficiaires surtout jeunes en milieux ruraux.",
    English:
      "In our daily work, we need the computer tool and particularly the Internet. The difficulties that we experience especially in rural and peri-urban areas, that of access to electricity, access to computer tools such as computers and others due to the high price, not to the author of a young farmer concerned about digital tools. We adapt ourselves according to the means at our disposal and according to the environment (agglomerations, active cities, etc.). We ask for an accompaniment or support to help our work with the beneficiaries, especially young people in rural areas.",
    "First ResponseID": 6751,
  },
  {
    Language: "French",
    "Country Code": "CD",
    "HQ Country": "Democratic Republic of the Congo",
    Original:
      "Les différents défis : Outils ou matériels informatiques adéquat, logiciels, l'internet,... Les formations,..",
    English:
      "The different challenges: adequate computer tools or materials, software, the Internet... Training…",
    "First ResponseID": 18355,
  },
  {
    Language: "French",
    "Country Code": "CD",
    "HQ Country": "Democratic Republic of the Congo",
    Original:
      "L'équité digitale reste à ce jour un besoin fondamental dans ma communauté du fait tout se fait que l'internet est devenu un moyen puissant et inévitable de communication. Or, chez moi plusieurs personnes n'ont pas accès à l'internet et ont du mal à suivre tout ce qui se passe ailleurs faut de compétence et matériels nécessaires. Notre plus grand défi reste celui de rendre très facile l'accès aux outils digitaux malgré que nous ne disposons pas des moyens financiers pour cette fin utile.",
    English:
      "Digital equity is still a fundamental need in my community due to the fact that the internet has become a powerful and inevitable means of communication. However, in my community, many people do not have access to the internet and have difficulty keeping up with what is happening elsewhere due to a lack of skills and materials. Our biggest challenge is to make the access to digital tools very easy despite the fact that we do not have the financial means for this purpose.",
    "First ResponseID": 7656,
  },
  {
    Language: "French",
    "Country Code": "CD",
    "HQ Country": "Democratic Republic of the Congo",
    Original:
      "Le plus grand défi auquel nous faisons face ce sont les moyens pour nous permettre d'avoir des outils informatiques,de nous abonner aux maison fournissant la connexion et la formation des beneficiaires",
    English:
      "The biggest challenge we face is the means to enable us to have computer tools, to subscribe to houses providing connection and training of beneficiaries",
    "First ResponseID": 8370,
  },
  {
    Language: "French",
    "Country Code": "CD",
    "HQ Country": "Democratic Republic of the Congo",
    Original:
      "Les appareils que nous utilisons sont vétustes, la qualité de la connexion que nous fournissent les maisons de communication n'est pas du tout de bonne qualité. La plupart des bénéficiaires de nos actions sont vulnérables. Ils ne sont pas en mesure de se procurer du matériel pouvant leur permettre d'accéder à l'internet car ces matériels sont vraiment couteux. Néanmoins, nous reconnaissons que l'internet nous facilite la communication surtout à travers les réseaux sociaux comme whatsap, facebook, twitter, ... Nous avons des sérieux problèmes lorsque nous avons besoin de communiquer avec nos bénéficiaires étant donné que ces derniers n'ont pas accès à l'internet. Nous vous remercions",
    English:
      "The devices we use are old, the quality of the connection provided by the communication houses is not good at all. Most of the beneficiaries of our actions are vulnerable. They are not able to get equipment that would allow them to access the internet because these materials are very expensive. Nevertheless, we recognize that the internet facilitates communication especially through social networks such as whatsap, facebook, twitter, ... We have serious problems when we need to communicate with our beneficiaries as they do not have access to the internet. We thank you for your help",
    "First ResponseID": 5235,
  },
  {
    Language: "French",
    "Country Code": "CD",
    "HQ Country": "Democratic Republic of the Congo",
    Original:
      "Il y a un sérieux problème d'accès et de la qualité de services d'internet fournis et cela impacte négativement sur la qualité du travail et la redevabilité entre nous et nos bénéficiaires. Les services d'internet sont encore rudimentaires et moins performants.",
    English:
      "There is a serious problem of access and quality of internet services provided and this negatively impacts the quality of work and accountability between us and our beneficiaries. Internet services are still rudimentary and not very efficient.",
    "First ResponseID": 3091,
  },
  {
    Language: "French",
    "Country Code": "CD",
    "HQ Country": "Democratic Republic of the Congo",
    Original:
      "Nous avons beaucoup besoin de renforcement des capacités en vue de pouvoir accéder aux outils digitaux nécessaires pour notre fonctionnement normal sans pour autant oublier la gouvernance d'internet.",
    English:
      "We are in great need of capacity building in order to be able to access the digital tools necessary for our normal functioning without forgetting internet governance.",
    "First ResponseID": 7660,
  },
  {
    Language: "French",
    "Country Code": "CD",
    "HQ Country": "Democratic Republic of the Congo",
    Original:
      "Nous avons plusieurs défis pour accéder à l'internet. Pourtant il est indispensable actuellement pour notre travail. Il y a problème de réseau, des coûts, de matériels pour accès, de formation pour nos agents et bénéficiaires mais aussi problème de sécurité digitale. Dans notre région des gens sont kidnappés moyennant la téléphonie digitale, le détournement d'argent...",
    English:
      "We have many challenges to access the internet. However, it is currently indispensable for our work. There are problems of network, costs, materials for access, training for our agents and beneficiaries but also problem of digital security. In our region people are kidnapped through digital telephony, embezzlement...",
    "First ResponseID": 2989,
  },
  {
    Language: "French",
    "Country Code": "CD",
    "HQ Country": "Democratic Republic of the Congo",
    Original:
      "Nous vivons aujourd'hui les conséquences occasionnées par la pandémie du covid-19 qui nous oblige l'utilisation des outils numériques dans notre quotidien et nos milieux professionnels. Une technologie que nous avons pris de cours sans une préparation préalable, des formations ou des outils (soft ou hard) adéquats. Le véritable défis se trouve dans l'accès aux matériels adéquats, l'accès à l'internet haut débit et aux formations pertinentes sur le numérique. Merci",
    English:
      "We live today the consequences caused by the pandemic of the covid-19 that forces us the use of the digital tools in our daily life and our professional environments. A technology that we have taken without prior preparation, training or tools (soft or hard) adequate. The real challenge lies in the access to adequate materials, access to broadband internet and relevant training on digital. Thank you",
    "First ResponseID": 2690,
  },
  {
    Language: "French",
    "Country Code": "DJ",
    "HQ Country": "Djibouti",
    Original:
      "Nous faisons la sensibilisation du numérique au près des jeunes djiboutiens et djiboutiennes ainsi que autres intéressés dans les centres communautaires et chefs-lieux. Nous rencontrons que la majorité des jeunes travails en groupe par manque de moyen matériels et manque de forfait internet.",
    English:
      "We are raising awareness of digital technology among young Djiboutians and other interested parties in community centers and towns. We meet that the majority of the young people work in group for lack of material means and lack of Internet package.",
    "First ResponseID": 9555,
  },
  {
    Language: "English",
    "Country Code": "DO",
    "HQ Country": "Dominican Republic",
    Original:
      "Working in the slums of the Dominican Rep. is a constant challenge because of lack of access.",
    English:
      "Working in the slums of the Dominican Rep. is a constant challenge because of lack of access.",
    "First ResponseID": 6876,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "DO",
    "HQ Country": "Dominican Republic",
    Original: "Dar mayor accesibilidad a internet a nuestr@s usuari@s",
    English: "To give better internet accessibility to our users",
    "First ResponseID": 19354,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "EC",
    "HQ Country": "Ecuador",
    Original:
      "Como hacer para lograr mejor acceso a internet por parte de la comunidad, pensando en capacitación y educación",
    English:
      "How to achieve better internet access by the community, thinking about training and education",
    "First ResponseID": 10490,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "EC",
    "HQ Country": "Ecuador",
    Original:
      "En zona rural donde trabajamos no llega internet ni señal telefónica. Apoyamos a las comunidades rurales a desarrollar actividades de turismo comunitario como actividad complementaria a la actividad agricola y permitiendo la conservación del medio ambiente y de la cultura. La falta de comunicación y los altos costos de internet satelital limita el desarrollo de las actividades económicas y educativas.",
    English:
      "In the rural area where we work, there is no internet or telephone signal. We support rural communities to develop community tourism activities as a complementary activity to farming activities and allowing the conservation of the environment and culture. The lack of communication and the high costs of satellite internet limit the development of economic and educational activities.",
    "First ResponseID": 7785,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "EC",
    "HQ Country": "Ecuador",
    Original:
      "La infraestructura de redes en la Unidad Educativa atendida esta cayendo en obsolescencia y la señal de internet no cubre, no llega a las aulas o departamentos, el obtener espacio para los correos también es oneroso para el presupuesto entre otros software",
    English:
      "The network infrastructure in the Educational Spot we serve is becoming obsolete and Internet wire does not cover, it does not reach the classrooms or departments, obtaining space for emails is also expensive for the budget among other software",
    "First ResponseID": 19581,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "EC",
    "HQ Country": "Ecuador",
    Original:
      "Somos una organizacion de base sin fines de lucro que trabajamos programas y proyectos para beneficiar a las mujeres de los sectores, consideramos que el internet es fundamental para comunicarnos con las beneficiarias y posibles donantes. El aprendizaje de herramientas tecnologicas nos permitira crecer y ser mas eficientes en la busqueda de nuestros objetivos.",
    English:
      "We are a local based nonprofit organization that works with programs and projects to benefit women in the sectors, we believe that internet is essential to communicate with beneficiaries and potential grantmakers. Learning technological tools will allow us to grow and be more efficient in the pursuit of our goals.",
    "First ResponseID": 14332,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "SV",
    "HQ Country": "El Salvador",
    Original:
      "Trabajar en desarrollar capacidades digitales en nuestros públicos de interés",
    English: "Work to develop digital capacities in our stakeholders",
    "First ResponseID": 10522,
  },
  {
    Language: "Spanish_S",
    "Country Code": "SV",
    "HQ Country": "El Salvador",
    Original:
      "Como organización, el reto más importante es la compra de equipos (ordenadores, tablets, servidores, switches, etc.) y su reposición al momento de obsolescencia. Si existieran formas de tener acceso a estos equipos de forma más asequible se facilitaría muchísimo nuestro trabajo.",
    English:
      "As an organization, the most important challenge is the purchase of equipment (computers, tablets, servers, switches, etc.) and its replacement at the time of obsolescence. If there were ways to access this equipment more affordably, it would make our work much easier.",
    "First ResponseID": 19370,
  },
  {
    Language: "English",
    "Country Code": "FI",
    "HQ Country": "Finland",
    Original:
      "Typically we don't need to concern ourselves with the infrastructure or computers of our volunteers - they have them by their employees. We only are concerned with getting the software (on the internet) that enables us to work for our mission.",
    English:
      "Typically we don't need to concern ourselves with the infrastructure or computers of our volunteers - they have them by their employees. We only are concerned with getting the software (on the internet) that enables us to work for our mission.",
    "First ResponseID": 2795,
  },
  {
    Language: "French",
    "Country Code": "FR",
    "HQ Country": "France",
    Original:
      "Nos bénéficiaires ont tellement de réalités sociales différentes qu'il était difficile de mettre en commun ces réalités dans cette enquête. Mais nous croyons fortement à l'équité digitale et vous remercions pour votre initiative !",
    English:
      "Our grantees have so many different social realities that it was difficult to put these realities together in this survey. But we strongly believe in digital equity and thank you for your initiative!",
    "First ResponseID": 5245,
  },
  {
    Language: "French",
    "Country Code": "FR",
    "HQ Country": "France",
    Original:
      "Nous rencontrons majoritairement 2 types de problèmes :  1) Les problèmes d'ordres techniques -> la plus difficile accessibilité à des solutions techniques Libres et locales, donc qui ne sont pas des GAFAM ou équivalent. 2) Les problèmes d'ordres sociétaux -> la dématérialisation des services administratifs et dispositifs permettant aux citoyens et citoyennes d'exercer leurs droits. La question de l'équité \"digitale\" est une affaire en premier de l'Etat, des différentes entreprises qui composent le pays, mais aussi sur une logique mondiale. Nous, en local, on essaye surtout de limiter la casse...",
    English:
      'We encounter mainly 2 types of problems: 1) Technical problems -> the more difficult accessibility to free and local technical solutions, therefore not GAFAM or equivalent. 2) Societal problems -> the dematerialization of administrative services and devices allowing citizens to exercise their rights. The question of "digital" equity is primarily a matter for the State, the various companies that make up the country, but also on a global logic. We, locally, try to limit the damage...',
    "First ResponseID": 5251,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original:
      "Barrierefreiheit kaum vorhanden, Digitale Souveränität und Schutz von Kindern und Jugendlichen ist eine besondere Stellschraube",
    English:
      "Accessibility hardly exists, Digital sovereignty and protection of children and young people is a special set screw.",
    "First ResponseID": 9331,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original:
      "Als Non-Profit-Organisation fällt es uns schwer, Kosten für digitale Infrastruktur und Unterhaltung selbiger adäquat in unsere gesetzlichen Refinanzierungsstrukturen einzubringen!",
    English:
      "As a non-profit organization, it is difficult for us to adequately incorporate costs for digital infrastructure and maintenance into our statutory refinancing structures!",
    "First ResponseID": 19207,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original:
      "Die Verfügbarkeit überhaupt, mit ausreichender Bandbreite und zu akzeptablen Kosten stellt an vielen (Veranstaltungs-)Orten nach wie vor eine Einschränkung dar",
    English:
      "Availability at all, with sufficient bandwidth and at acceptable cost is still a limitation at many (event) venues",
    "First ResponseID": 10585,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original:
      "wegen der unterschiedlichen Fähigkeiten unserer verschiedenen Zielgruppen ist es schwierig, diese medial ideal zu adressieren. Auch wissen wir zu wenig darüber, wer was nutzt, z. B. Browser vs. Smartphone.",
    English:
      "because of the different capabilities of our various target groups, it is difficult to address them ideally in terms of media. We also know too little about who uses what, e.g. browser vs. smartphone.",
    "First ResponseID": 9591,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original:
      "Sowohl Geschäftsführung als auch der Vorstand sind im Altersdurchschnitt Ü 60. Wir suchen nach Möglichkeiten für eine dauerhafte Implementierung einer Fachkraft für digitale Kommunikation, um noch besser und schneller über digitale Kanäle unsere Zielgruppen erreichen zu können.",
    English:
      "Both management and board of directors have an average age of over 60. We are looking for opportunities to implement a digital communications specialist on a permanent basis so that we can communicate even better and faster via digital channels.",
    "First ResponseID": 10224,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original:
      "Breite Digitale Kompetenz ist einer der wichtigsten Schlüssel zu digitaler Gerechtigkeit Diskrimierungsfreie Algorithmen zu entwickeln  ist wichtig, um Zugang für möglichst viele zu gewährleisten",
    English:
      "Broad Digital literacy is one of the most important keys to digital justice. Developing non-discriminatory algorithms is important to ensure access for as many as possible.",
    "First ResponseID": 2847,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original:
      "Datenschutzthemen zwischen EU und USA erschweren die betriebliche Internetnutzung",
    English:
      "Data protection issues between the EU and the U.S. complicate corporate Internet use.",
    "First ResponseID": 9350,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original:
      "Den Umgang mit den digitalen Möglichkeiten versuche ich für meine Teilnehmenden stetig zu verbessern. Manchmal ist díe Infrastruktur des Unternehmens nicht ausreichend dafür ausgelegt, das behindert den Fortschritt.",
    English:
      "I am constantly trying to improve the way my participants use the digital possibilities. Sometimes the company's infrastructure is not sufficiently designed for this, which hinders progress.",
    "First ResponseID": 9319,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original:
      "Ich sehe mehrere Herausforderungen: 1) sicheres Internet (Schutz vor Kriminelle im Netz) , 2) Problem der Überwachung durch den Staat/ durch Unternehmen gegenüber ihren Angestellten 3) Auswirkungen auf das Zusammleben, wenn alles nur noch digital stattfindet, 4) Digitale Teilhabe für alle - wie kann das tatsächlich erreicht werden?",
    English:
      "I see several challenges: 1) secure internet (protection against criminals on the net) , 2) problem of surveillance by the state/ by companies against their employees 3) impact on living together when everything is digital only, 4) digital participation for all - how can this actually be achieved?",
    "First ResponseID": 9868,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original:
      "Der Internetzugang sollte in allen Senioren Einrichtungen vorhanden sein",
    English: "Internet access should be available in all senior facilities",
    "First ResponseID": 9237,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original:
      "Es ist in Deutschland nach wie vor ein großes Problem überall ein flächendeckendes schnelles Internet zu finden",
    English:
      "It is still a major problem in Germany to find nationwide fast Internet everywhere.",
    "First ResponseID": 9634,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original: "Mobiles Internet muss schneller und Leistungsfähiger werden",
    English: "Mobile Internet must become faster and more powerful",
    "First ResponseID": 14607,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original: "Mein Dorf hat einen sehr schlechten Internetzugang",
    English: "My village has very poor internet access",
    "First ResponseID": 10040,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original:
      "Unsere größte Herausforderung ist, dass die meisten Behörden, mit denen wir arbeiten, nur noch digital erreichbar sind. Die Kontaktformulare der Behörden sind so kompliziert, dass unsere ausländischen Klienten schon an der elektronischen Terminvereinbarung scheitern. Für uns bedeutet dies sehr viel Mehrarbeit.",
    English:
      "Our biggest challenge is that most of the authorities we work with can now only be reached digitally. The contact forms of the authorities are so complicated that our foreign clients fail even to make an appointment electronically. For us, this means a lot of extra work.",
    "First ResponseID": 9417,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original:
      'Unser Clubhaus verfügt über keinen Internetanschluss was aktuell unsere größte Herausforderung ist, da wir so viele Angebote nicht anbieten können. Die notwendigen Tiefbauarbeiten können wir uns als kleiner gemeinnütziger Verein nicht leisten. Der Hochtaunuskreis hatte eine europaweite Ausschreibung um diese "weißen Flecken" zu schließen, aber im Vorfeld nicht mit den beteiligten Kommunen gesprochen. So kam es, dass unser Clubhaus im Wald von Weilrod-Hasselbach schlicht vergessen, was uns maßlos ergärt.',
    English:
      'Our clubhouse does not have an internet connection which is currently our biggest challenge as we are unable to offer so many services. The necessary civil engineering work we can not afford as a small non-profit association. The Hochtaunuskreis had a Europe-wide tender to close these "white spots", but did not talk to the involved municipalities in advance. So it came that our clubhouse in the forest of Weilrod-Hasselbach simply forgotten, which annoys us beyond measure.',
    "First ResponseID": 9582,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original:
      "Umfrage ist mir zu a behoben und geht an der Realität in industrialisierten Ländern vorbei. Man Gewinnerzielungsabsicht den Eindruck, die Ersteller hatten beim Verfassen der Umfrage bestimmte Ergebnisse bereits im Kopf.",
    English:
      "The survey is too detached for me and does not reflect the reality in industrialized countries. One gets the impression that the authors already had certain results in mind when they wrote the survey.",
    "First ResponseID": 9275,
  },
  {
    Language: "German",
    "Country Code": "DE",
    "HQ Country": "Germany",
    Original:
      "Es gibt Gruppen, die nicht netzaffin sind oder keinen Zugang zu digitalen Informationen haben - diese dürfen nicht vom Zugang zu Information ausgeschlossen werden. Analoge Zugänge müssen möglich bleiben.",
    English:
      "There are groups that are not net-savvy or have no access to digital information - these must not be excluded from access to information. Analog access must remain possible.",
    "First ResponseID": 10948,
  },
  {
    Language: "Arabic",
    "Country Code": "GH",
    "HQ Country": "Ghana",
    Original:
      "There should be more focus for gender justice in access to the internet",
    English:
      "There should be more focus for gender justice in access to the internet",
    "First ResponseID": 7878,
  },
  {
    Language: "English",
    "Country Code": "GH",
    "HQ Country": "Ghana",
    Original: "Affordability devices and digital training.",
    English: "Affordability devices and digital training.",
    "First ResponseID": 11779,
  },
  {
    Language: "English",
    "Country Code": "GH",
    "HQ Country": "Ghana",
    Original:
      "Digital literacy is one of the biggest barriers to women's access and use of the internet, so is affordability and online safety. Online harassment for instance has been cited by some women as reason for not being online, exiting online platforms or not actively engaging in online spaces. Mobile money fraud is another big issue. These pieces of information were picked up from our engagement on the ground.",
    English:
      "Digital literacy is one of the biggest barriers to women's access and use of the internet, so is affordability and online safety. Online harassment for instance has been cited by some women as reason for not being online, exiting online platforms or not actively engaging in online spaces. Mobile money fraud is another big issue. These pieces of information were picked up from our engagement on the ground.",
    "First ResponseID": 8838,
  },
  {
    Language: "English",
    "Country Code": "GH",
    "HQ Country": "Ghana",
    Original:
      "Getting enough computers to do our training is really a challenge, We are forced to use theoretical approach.  Sometimes 5 persons will have to make use of 1 computer, Internet connectivity is really a challenge to us particularly in the Kwahu West .",
    English:
      "Getting enough computers to do our training is really a challenge, We are forced to use theoretical approach.  Sometimes 5 persons will have to make use of 1 computer, Internet connectivity is really a challenge to us particularly in the Kwahu West .",
    "First ResponseID": 8425,
  },
  {
    Language: "English",
    "Country Code": "GH",
    "HQ Country": "Ghana",
    Original:
      "Issues around accessibility and affordability  as well high quality and affordable computers.",
    English:
      "Issues around accessibility and affordability  as well high quality and affordable computers.",
    "First ResponseID": 8924,
  },
  {
    Language: "English",
    "Country Code": "GH",
    "HQ Country": "Ghana",
    Original:
      "One of my organisation's goal is to create accessibility and affordability of digital skills and training including the internet in rural communities.",
    English:
      "One of my organisation's goal is to create accessibility and affordability of digital skills and training including the internet in rural communities.",
    "First ResponseID": 9807,
  },
  {
    Language: "English",
    "Country Code": "GH",
    "HQ Country": "Ghana",
    Original:
      "The greatest challenge we now face as an organization is the affordability of internet data and digital tools like Microsoft 360 and website development. As an organization, we have been able to develop our web and social media policy that guide our work with women and children in rural Ghana.",
    English:
      "The greatest challenge we now face as an organization is the affordability of internet data and digital tools like Microsoft 360 and website development. As an organization, we have been able to develop our web and social media policy that guide our work with women and children in rural Ghana.",
    "First ResponseID": 10381,
  },
  {
    Language: "English",
    "Country Code": "GH",
    "HQ Country": "Ghana",
    Original:
      "Yes. Digital skill is vital in our daily lives but we lack the fund to acquire good tools to better our work, as a result, we lag in achieving our goal",
    English:
      "Yes. Digital skill is vital in our daily lives but we lack the fund to acquire good tools to better our work, as a result, we lag in achieving our goal",
    "First ResponseID": 9941,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "GT",
    "HQ Country": "Guatemala",
    Original:
      "En Guatemala enfrentamos mucha brecha digital en las áreas rurales, tanto en el acceso a servicio de internet como acceso a dispositivos y habilidades digitales.  Gracias por la encuesta, nos ayuda a saber que éste tema es importante para apoyarlo.",
    English:
      "In Guatemala we face a big digital divide in rural areas, both in access to internet service and access to digital devices and skills. Thank you for the survey, it helps us to know that this topic is important to support.",
    "First ResponseID": 19357,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "GT",
    "HQ Country": "Guatemala",
    Original:
      "Nuestro mayor desafío es lograr que la información y beneficios lleguen a mas personas y de forma mas rápida.",
    English:
      "Our greatest challenge is to ensure that information and benefits reach more people and more quickly.",
    "First ResponseID": 16717,
  },
  {
    Language: "French",
    "Country Code": "GN",
    "HQ Country": "Guinea",
    Original:
      "Manque de site web de notre organisation; Le coût des appareils et de l'internet  La non maîtrise de l'utilisation de certains logiciels   La faible mobilisation des fonds à cause des critères très contraignants imposés par les bailleurs",
    English:
      "Lack of a website for our organization; The cost of equipment and internet. The lack of mastery of the use of certain software. Low mobilization of funds because of the very restrictive criteria imposed by the donors",
    "First ResponseID": 19273,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "HN",
    "HQ Country": "Honduras",
    Original:
      "Acceso a internet en comunidades rurales - Capacitación en e-comercio y banca en linea",
    English:
      "Internet access in rural communities - Training in e-commerce and online banking",
    "First ResponseID": 8182,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "HN",
    "HQ Country": "Honduras",
    Original:
      "Paises pequeños y pobres requieren mayor inversión en infraestructura que otros paises...",
    English:
      "Small and poor countries require greater investment in infrastructure than other countries…",
    "First ResponseID": 7874,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "HN",
    "HQ Country": "Honduras",
    Original:
      "Nesecitamos obtener internet y aparatos digitales para cumplimieto de las metas de mi organizacion asi como de los municipios actores del desarrollo propuesto",
    English:
      "We need to obtain internet and digital devices to meet the goals of my organization as well as those of the municipalities involved in the proposed development",
    "First ResponseID": 10470,
  },
  {
    Language: "Spanish_S",
    "Country Code": "HN",
    "HQ Country": "Honduras",
    Original:
      "si que exista facilidad de acceso para las organizaciones ,escuelas, estudiantes.",
    English:
      "It would be good if there could be easy Internet access for organizations, schools, students.",
    "First ResponseID": 3692,
  },
  {
    Language: "Hungarian",
    "Country Code": "HU",
    "HQ Country": "Hungary",
    Original:
      "Az idősek körében (65+ év) nagy a digitális analfabétizmus. A vidék infrastruktúrában, képzésben le van maradva a nagyobb telelpülésekhez képest.",
    English:
      "Digital illiteracy is high among the elderly (65+ years). Rural areas are lagging behind in terms of infrastructure and training compared to urban areas.",
    "First ResponseID": 6731,
  },
  {
    Language: "Hungarian",
    "Country Code": "HU",
    "HQ Country": "Hungary",
    Original:
      'Nagyon akadályoz a munkában a saját és társaim digitális alulképzettsége. Mivel különböző szintű a tudatlanságunk, "korrepetítorra", "személyi segítőre" volna szüksége a szervezetünknek, aki néhány hét alatt felzárkóztat bennünket, egyéni igényeink szerint.',
    English:
      "I am very hampered in my work by my own and my co-workers' digital illiteracy. Because of our varying levels of digital illiteracy, our organisation needs a tutor",
    "First ResponseID": 3728,
  },
  {
    Language: "Hungarian",
    "Country Code": "HU",
    "HQ Country": "Hungary",
    Original:
      "A régiónkban az internet elérés inkább adott bárki számára, mint nem. Nagyon hianyoznak azok az ismeretek, képzések, információk, melyek segitik az internetezőt a netes csalások, álhírek kiszűrésében.",
    English:
      "In our region, internet access is rather more a given opportunity for everyone than not. Though there is a serious lack of knowledge, training and information to help internet users detect online scams and fake news.",
    "First ResponseID": 2746,
  },
  {
    Language: "Hungarian",
    "Country Code": "HU",
    "HQ Country": "Hungary",
    Original:
      "Fantasztikus hogy a civil szervezeteket sokan támogatják a digitális hozzáférésben pl. olcsóbb vagy ingyenes szoftverekkel, de a szociális szolgáltatók egy jó része nem civil, és nagyon alacsony büdzséből gazdálkodik, így szintén nem fér hozzá egy csomó esetben mindahhoz ami a munkája vagy az általuk ellátott emberek számára alapvetően szükséges lenne. A támogatott körből pedig kiszorul, mert nem civil. Ha esetleg nem a jogi személyiség, hanem az ellátott feladatok + egy-egy ellátottra jutó költségvetési keretek, lehetőségek mentén lenne a támogatotti kör definiálva, akkor beférnénk pl. mi is.",
    English:
      "It's fantastic that many NGOs are supported in digital access with, for example, cheaper or free software, but a good number of social service providers are not NGOs and run on very low budgets, so they also don't have access in a lot of cases to everything that is essential for their work or for the people they serve. And they are excluded from being funded because they are not NGOs. If the scope of support would be defined not by legal personality, but by the tasks performed + the budget per person served, then we would fit in.",
    "First ResponseID": 10503,
  },
  {
    Language: "Hungarian",
    "Country Code": "HU",
    "HQ Country": "Hungary",
    Original:
      "Alapvető szoftverekhez ( Word,Excel, Powerpoint) valamint az internetszolgáltatók szolgáltatásaikhoz (pl.: streaming, internetvédelembiztonság) való olcsóbb megfizethetőbbé tétele. Olyan pályázati rendszer, ami megfizethetőbbé tenné a digitális élet vonzóbbá tételét.",
    English:
      "Making basic software (Word, Excel, Powerpoint) and internet service providers' services (e.g. streaming, internet security) more affordable. A tendering scheme to make digital life more affordable and attractive.",
    "First ResponseID": 10651,
  },
  {
    Language: "Hungarian",
    "Country Code": "HU",
    "HQ Country": "Hungary",
    Original:
      "Fő probléma, hogy az Alapítványnak nincs pénze arra, hogy irodát béreljen, oktatási eszközöket (számítógép, okostelefon) vegyen és így oktassa az idős személyeket.",
    English:
      "The main problem is that the Foundation does not have the money to rent an office, buy educational equipment (computer, smartphone) and thus educate the elderly.",
    "First ResponseID": 10712,
  },
  {
    Language: "Hungarian",
    "Country Code": "HU",
    "HQ Country": "Hungary",
    Original:
      "Jelenleg is sok olyan ember van akinek nincs eszköze internet használatra,vagy ha igen alig tudja megfelelően használni tudás hiányában.",
    English:
      "There are still many people who do not have the means to use the internet, or if they do, they can barely use it properly due to lack of knowledge.",
    "First ResponseID": 3972,
  },
  {
    Language: "Hungarian",
    "Country Code": "HU",
    "HQ Country": "Hungary",
    Original:
      "Az elterjedt mobiltelefonos internethasználattal növekedett a számítógéphez nem értők száma és csökkent a motiváció is. Ezzel párhuzamosan az álláskeresők körében növekedett az igény a számítógépes ismeretek elsajátítása iránt, viszont pénzük nincs rá.",
    English:
      "With widespread mobile internet use, the number of computer illiterates has increased and motivation has decreased. At the same time, there is a growing demand among jobseekers to learn computer skills, but they do not have the money.",
    "First ResponseID": 1533,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original: "Availability of Devices is challenge",
    English: "Availability of Devices is challenge",
    "First ResponseID": 4450,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "Covid has shown us how important digital connectivity was. we were not able to connect with over half our community or team because of a lack of internet. We cold not reach the children with educational services because of lack of connectivity. Disasters in the mountains increase this divide.",
    English:
      "Covid has shown us how important digital connectivity was. we were not able to connect with over half our community or team because of a lack of internet. We cold not reach the children with educational services because of lack of connectivity. Disasters in the mountains increase this divide.",
    "First ResponseID": 3984,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "Internet connectivity is not good in many areas and most of Village and poor areas so I request you improve the same.",
    English:
      "Internet connectivity is not good in many areas and most of Village and poor areas so I request you improve the same.",
    "First ResponseID": 8721,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "Our organization is fairly equipped handling the digital journey. Since ours is non profit organization software and digital upgradation is expensive.",
    English:
      "Our organization is fairly equipped handling the digital journey. Since ours is non profit organization software and digital upgradation is expensive.",
    "First ResponseID": 4117,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "The internet has literally opened up possibilities to reach out to people without being limited by geographical boundaries. It was unthinkable even 6 or 7 years back. So much so that my primary mode of contact has become online enhancing the physical mode of communication used for millennia. Online will never replace in person communication in my line of work but enable reaching out at least at the introductory levels. And that is tremendously advantageous. But a word of caution - one has to be judicious in the use of technology and not go overboard. Then it even becomes detrimental and harms the seriousness by bringing a casual attitude.",
    English:
      "The internet has literally opened up possibilities to reach out to people without being limited by geographical boundaries. It was unthinkable even 6 or 7 years back. So much so that my primary mode of contact has become online enhancing the physical mode of communication used for millennia. Online will never replace in person communication in my line of work but enable reaching out at least at the introductory levels. And that is tremendously advantageous. But a word of caution - one has to be judicious in the use of technology and not go overboard. Then it even becomes detrimental and harms the seriousness by bringing a casual attitude.",
    "First ResponseID": 3673,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "Can we get some financial and technical support for creating the adequate and appropriate facilities with organization for using the digital technology effectively and which can also help us to reach out more people of marginalized communities living in difficult socio-geographies?",
    English:
      "Can we get some financial and technical support for creating the adequate and appropriate facilities with organization for using the digital technology effectively and which can also help us to reach out more people of marginalized communities living in difficult socio-geographies?",
    "First ResponseID": 10946,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "CSC Digital services demanded at present in our all day to day behavioural life journey.",
    English:
      "CSC Digital services demanded at present in our all day to day behavioural life journey.",
    "First ResponseID": 13728,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original: "Data Protection Policy / Privacy Policy formulation",
    English: "Data Protection Policy / Privacy Policy formulation",
    "First ResponseID": 3871,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "Digital equity is a big challenge in India. Communities in rural areas are left behind and are denied several benefits and opportunities. Strong policies to reach the last mile is required.",
    English:
      "Digital equity is a big challenge in India. Communities in rural areas are left behind and are denied several benefits and opportunities. Strong policies to reach the last mile is required.",
    "First ResponseID": 7689,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "digital journey will get completed if we get infrastructural facilities.",
    English:
      "digital journey will get completed if we get infrastructural facilities.",
    "First ResponseID": 10586,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "Generally, the digitalisation and internet changed the way the world functions. It has many positive points but has many negative sides due to misuse. The current system is not sufficient to address that.  No barrier / screening for the content that is shared; and a child below 10 years has equal access to the content which was known only to adults in the pre-digital and pre-internet era.  This is not a good trend.",
    English:
      "Generally, the digitalisation and internet changed the way the world functions. It has many positive points but has many negative sides due to misuse. The current system is not sufficient to address that.  No barrier / screening for the content that is shared; and a child below 10 years has equal access to the content which was known only to adults in the pre-digital and pre-internet era.  This is not a good trend.",
    "First ResponseID": 8681,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "I am conducting  PCRA Training  programs with Sankalan App in some places the Internet access  is very poor App does not open  my day is being wasted  I need to send Live Photos which are next to impossible. Internet  has to be there all over with in the reach of people.",
    English:
      "I am conducting  PCRA Training  programs with Sankalan App in some places the Internet access  is very poor App does not open  my day is being wasted  I need to send Live Photos which are next to impossible. Internet  has to be there all over with in the reach of people.",
    "First ResponseID": 16019,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "In fact I did not know much about the digital equity journey before your initiative. We have dearth of many items as we work with the computers which are not branded so we could not use different softwares for our work, lack of Competent staff and over all our organisation is not resourceful enough to run everything smoothly.",
    English:
      "In fact I did not know much about the digital equity journey before your initiative. We have dearth of many items as we work with the computers which are not branded so we could not use different softwares for our work, lack of Competent staff and over all our organisation is not resourceful enough to run everything smoothly.",
    "First ResponseID": 4461,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "In our rural working area no broadband services we rely on mobile phones",
    English:
      "In our rural working area no broadband services we rely on mobile phones",
    "First ResponseID": 3584,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "Internet speed must be monitored by government and a policy should be there to regulate and ensure reliable internet availability.",
    English:
      "Internet speed must be monitored by government and a policy should be there to regulate and ensure reliable internet availability.",
    "First ResponseID": 8858,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "Most of the Internet Service Providers are focusing their business area only in the urban communities where as the rural population is totally ignored for a respectable Internet Connection (not a supersonic) due to commercial reasons.",
    English:
      "Most of the Internet Service Providers are focusing their business area only in the urban communities where as the rural population is totally ignored for a respectable Internet Connection (not a supersonic) due to commercial reasons.",
    "First ResponseID": 9696,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original: "Online fundraising is biggest challenge we are facing.",
    English: "Online fundraising is biggest challenge we are facing.",
    "First ResponseID": 8623,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "Software companies should help civil society without any hurdles of paperwork or certification",
    English:
      "Software companies should help civil society without any hurdles of paperwork or certification",
    "First ResponseID": 4176,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "The staff we have and the rural people we serve do not have personal computers or laptop and use only mobile phones for accessing internet. Only the youngsters are adept with the techniques, whereas the elders do not know much about the nuances. The net connectivity is generally poor in the area and the tools are costly and unaffordable to them",
    English:
      "The staff we have and the rural people we serve do not have personal computers or laptop and use only mobile phones for accessing internet. Only the youngsters are adept with the techniques, whereas the elders do not know much about the nuances. The net connectivity is generally poor in the area and the tools are costly and unaffordable to them",
    "First ResponseID": 8916,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "We need support to impart the digital literacy (both skills and knowledge) to the people of most marginalized communities in rural and tribal regions so that they could access the correct information about their legal entitlements / various public welfare schemes and related benefits for their development.",
    English:
      "We need support to impart the digital literacy (both skills and knowledge) to the people of most marginalized communities in rural and tribal regions so that they could access the correct information about their legal entitlements / various public welfare schemes and related benefits for their development.",
    "First ResponseID": 4159,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "We plan to take the internet to the last people of the nation. We need support and handholding to take the cause forward",
    English:
      "We plan to take the internet to the last people of the nation. We need support and handholding to take the cause forward",
    "First ResponseID": 3964,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "We work for and with people with deafblindness (having combination of visual as well as hearing impairment). The main issue is availability of affordable devices to access digital information/ content.",
    English:
      "We work for and with people with deafblindness (having combination of visual as well as hearing impairment). The main issue is availability of affordable devices to access digital information/ content.",
    "First ResponseID": 8673,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original:
      "We work in the sector of Disability and the issue of Inclusion is very crucial and critical for Persons with Disabilities when we talk about Access to Digital Communications. Digital Equity can only be reached in totality if we look into the requirements and access to the Digital World and Services for the Disabled.",
    English:
      "We work in the sector of Disability and the issue of Inclusion is very crucial and critical for Persons with Disabilities when we talk about Access to Digital Communications. Digital Equity can only be reached in totality if we look into the requirements and access to the Digital World and Services for the Disabled.",
    "First ResponseID": 10025,
  },
  {
    Language: "English",
    "Country Code": "IN",
    "HQ Country": "India",
    Original: "Wi-fi should we provided free of cost to public.",
    English: "Wi-fi should we provided free of cost to public.",
    "First ResponseID": 8879,
  },
  {
    Language: "English",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "In our east Bali mountain village, where our 19 individual communities are scattered over the northeastern slopes of 2 mountains, most people have to walk various distances from their home to find even a basic internet signal. We urgently need digital infrastructure and internet access for all 4,000 families",
    English:
      "In our east Bali mountain village, where our 19 individual communities are scattered over the northeastern slopes of 2 mountains, most people have to walk various distances from their home to find even a basic internet signal. We urgently need digital infrastructure and internet access for all 4,000 families",
    "First ResponseID": 16266,
  },
  {
    Language: "English",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Mobile internet relatively cheap but unreliable Fiber expensive and not up to international standards",
    English:
      "Mobile internet relatively cheap but unreliable Fiber expensive and not up to international standards",
    "First ResponseID": 4728,
  },
  {
    Language: "English",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Through the process of digital transformation, we have become acutely aware of the need for certain policies such as privacy, accessibility, copyright, and a digital code of ethics.",
    English:
      "Through the process of digital transformation, we have become acutely aware of the need for certain policies such as privacy, accessibility, copyright, and a digital code of ethics.",
    "First ResponseID": 19529,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Akses alat digital yang kami gunakan terkadang memiliki kendala karena banyaknya pengguna di satu gedung yang kami tempati. Kami membutuhkan alat pelengkap untuk mempermudah akses internet kami. Dan untuk kebijakan internet untuk selalu menjaga Data Pengguna agar tetap aman.",
    English:
      "Access to digital tools that we use sometimes has problems due to the large number of users in one building that we occupy. We need complementary tools to facilitate our internet access. And for internet policy to always keep User Data safe.",
    "First ResponseID": 11282,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Perjalanan equitas digital masih panjang... perlu kerjasama dari berbagai pihak untuk membuat semua manusia diberbagai negara dapat menggunakan interner dengan maksimal.",
    English:
      "Digital equity is still a long way to go... it needs cooperation from various parties to make all people in various countries able to use the internet to the fullest.",
    "First ResponseID": 16929,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Literasi digital dan perubahan budaya transisi dari offline ke online",
    English:
      "Digital literacy and cultural change transition from offline to online",
    "First ResponseID": 2568,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Peningkatan kecepatan internet",
    English: "Increase internet speed",
    "First ResponseID": 12433,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Kendala yang dihadapi adalah akses internet yang tidak stabil, software yang terbatas khusus untuk pembuatan kontent video dan photo product, software untuk edukasi terbatas, software untuk meeting terbatas, kemudian perangkat hardware yang tterbatas dalam melakukan pelatihan literasi digital, kemudian untuk pembuatan photo product dan video product serta pembuatan materi ajar terkadang kami memiliki kendala hardware. Internet adalah hal yang kini menjadi kebutuhan hak mendasar bagi setiap manusia, karena dengan Internet segala informasi dapat diterima oleh semua orang.",
    English:
      "The constraints faced are unstable internet access, limited software specifically for the creation of video content and photo products, limited software for education, limited software for meetings, then limited hardware devices in conducting digital literacy training, then for making product photos and product videos as well as making teaching materials sometimes we have hardware problems. The internet is something that has now become a basic right for every human being, because with the internet all information can be received by everyone.",
    "First ResponseID": 2661,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan terbesar ialah kecepatan internet yang tidak stabil terutama saat berada di perdesaan sangat sulit menemukan tempat yang memiliki akses internet stabil dan merata",
    English:
      "The biggest challenge is the unstable internet speed, especially when in rural areas. It is very difficult to find a place that has a stable and even internet access.",
    "First ResponseID": 11144,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Internet yg sangat luas dan mudah terjanglau ke pelosok desa",
    English: "Very wide internet and easily accessible to remote villages",
    "First ResponseID": 11889,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Saat mengakses internet jaringan terkadang troble",
    English: "Sometimes it is problematic when accessing the internet network.",
    "First ResponseID": 11533,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "1. Akes internet yang sering bermasalah 2.semua daerah  belum tersedia akses internet 3. Kuoata yang cukup mahal",
    English:
      "1. Internet access is often problematic 2. All areas have no internet access 3. Quota is quite expensive",
    "First ResponseID": 17297,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Akses ke Google for Non-profit, platform Project Management, dan informasi Grant sangat diperlukan untuk mendukung keberlanjutan organisasi saya.",
    English:
      "Access to Google for Non-profits, the Project Management platform, and Grant information is essential to support the sustainability of my organization.",
    "First ResponseID": 9150,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Akses ketersediaan layanan internet kabel adalah hal yang sangat kami butuhkan, di satu sisi memang operator seluler lokal menyediakan produk internet yang variatif tetapi belum bisa meringankan penggunaan biaya untuk akses internet baik untuk organisasi maupun publik yang kami layani. Akses jaringan fiber optic terhambat Infrastruktur",
    English:
      "Access to the availability of cable internet services is something that we really need, on the one hand, local cellular operators provide a variety of internet products but they have not been able to reduce the cost for internet access for both organizations and the public we serve. Access to fiber optic network is hampered Infrastructure",
    "First ResponseID": 3866,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Sebenarnya saya adalah seorang yang awalnya gaptek alias gagap teknologi. Namun karena pekerjaan dan situasi disekeliling saya yang menuntut untuk bisa dan menggunakan internet maka saya mempelajarinya. Dan ternyta bisa mengakses internet dan menggunakannya adalah hal sangat bermanfaat untuk saya.",
    English:
      "Actually, I was a person who was initially clueless or technologically stuttering. But because of work and the situation around me that demands to be able to use the internet, I learn it. And it turns out that being able to access the internet and use it is very useful for me.",
    "First ResponseID": 11934,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Mengadvokasi masyarakat pinggiran agar melek informasi dan lebih peduli dengan masalah yang ada disekitar nya sehingga informasi cepat ke pembimbing",
    English:
      "Advocating suburban communities to be information literate and more concerned with the problems around them so that information is quickly sent to supervisors",
    "First ResponseID": 11975,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Akses internet yang terjangkau hingga pedalaman Papua, Indonesia.",
    English: "Affordable internet access to the interior of Papua, Indonesia.",
    "First ResponseID": 2897,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Disini akses internet hampir semua pakai handphone dan kalau listrik padam jaringan internetpun ikut hilanh",
    English:
      "Almost all internet access here uses cellphones and if the electricity goes out the internet network also disappears",
    "First ResponseID": 11243,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Apakah ada ide selain penggunaan internet untuk edukasi online yang lebih mudah digunakan sampai ke daerah terpencil ?",
    English:
      "Are there any ideas other than using the internet for online education that is easier to use even in remote areas?",
    "First ResponseID": 3203,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Apakah ada hak atau aturan atas akses digital,apalagi ada di sosial media",
    English:
      "Are there rights or rules for digital access, especially on social media?",
    "First ResponseID": 11244,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Ketersediaan infrastruktur yang handal serta kecepatan akses internet yang sesuai",
    English:
      "Availability of reliable infrastructure and appropriate internet access speed",
    "First ResponseID": 2733,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Karena saya tinggal di pedesaan yang dlu ny susah sekali mengakses internet dari yg tadi ny lewat warnet sekarang sudah punya sendiri",
    English:
      "Because I live in a rural area, it used to be very difficult to access the internet from the previous one via an internet cafe, now I have my own",
    "First ResponseID": 13044,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan yang komunitas kami hadapi saat adanya bencana alam di daerah terpencel yang msh jauh dri internet. Dan juga isi konten komunitas kami agar bisa menarik simpati orang banyak",
    English:
      "Challenges faced by our society when natural disasters occur in remote areas that are not covered by the internet. And also our community content that can attract sympthy from many people",
    "First ResponseID": 11214,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Saat ini komunitas kami kekurangan alat pendukung seperti PC EDITING alat recorder dll",
    English:
      "Currently our community lacks supporting tools such as PC EDITING tools, recorders etc",
    "First ResponseID": 2726,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Menghadapi masyarakat yang belum terbuka dengan teknologi",
    English: "Dealing with a society that is not yet open to technology",
    "First ResponseID": 11376,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Akses digital kini banyak digunakan di era sekarang. Tantangannya adalah bagaimana kita dapat menseleksi konten yang dibuat agar tetap menarik perhatian masyarakat namun tetap dalam aturan yang berlaku, menghindari kejahatan cryber crime. Akses digital yang dibutuhkan seperti media sosial,email dan lainnya.",
    English:
      "Digital access is now widely used. The challenge is how we can select the content that is created so that it still attracts the attention of the public but still within the applicable rules, avoiding the cyber crime. Required digital access such as social media, email, and others",
    "First ResponseID": 11146,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Konten digital dalam skala dasar mengenai penipuan digital, informasi internet dasar sangat dibutuhkan di kalangan pra lansia dan lansia sebagai modal pendidikan usia lanjut.",
    English:
      "Digital content on a basic scale regarding digital fraud, basic internet information is very much needed among pre-elderly and elderly people as capital for advanced education.",
    "First ResponseID": 3649,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Litetasi digital bagi komunitas perlubdidukung dgn kebijakan dan anggaran.",
    English:
      "Digital literacy for the community needs to be supported by policies and budgets.",
    "First ResponseID": 2736,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Literasi digital di Indonesia masih belum cukup baik karena ada lompatan beberapa tahap. Sehingga sering kali warga masih gagap ketika berinteraksi di komunitas digital",
    English:
      "Digital literacy in Indonesia is still not good enough because there are several stages of leaps and bounds. So often people still stutter when interacting in the digital community",
    "First ResponseID": 8230,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Alat digital yang tidak terjangkau (tidak dapat dibeli) membuat kami terus untuk berinovasi dalam mengedukasi literasi digital dan implementasi inovasi digital menjadi sebuah prestasi",
    English:
      "Digital tools that are not affordable (cannot be purchased) make us continue to innovate in educating digital literacy and the implementation of digital innovation is an achievement",
    "First ResponseID": 2565,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Pemerataan cakupan wilayah untuk 5g, konsep smart city",
    English: "Evenly distribution of area coverage for 5g, smart city concept",
    "First ResponseID": 11793,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Layanan cepat dari internet di daerah pedesaan masih tergolong lambat, belum memadai, serta memiliki banyak kendala, seperti iklim dan mati lampu",
    English:
      "Fast internet service in rural areas is still relatively slow, inadequate, and has many obstacles, such as climate and blackouts",
    "First ResponseID": 12426,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Akses Fiber Optik Internet di pinggiran kota (atau kota kecil) masih terbatas dan mahal dibanding di kota besar",
    English:
      "Fiber Optic Internet access in suburbs (or small towns) is still limited and expensive compared to big cities",
    "First ResponseID": 2589,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Untuk saat ini jangkauan internet kadang bagus kadang tidak.  Kalau bisa di perluas laginjangakauannintermey agar semua wilayah dan organisasi bisa terhmjangkau",
    English:
      "For now, internet coverage is sometimes good, sometimes not. If possible, expand the coverage of the internet so that all regions and organizations can be reached",
    "First ResponseID": 11133,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Kebijakan dan regulasi pemerintah agar lebih mensejaterahkan lapisan masyarakat",
    English:
      "Government policies and regulations to improve the welfare of the community",
    "First ResponseID": 11204,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Semoga jaringan akses internet menjangkau seluruh pelosok negri",
    English:
      "Hopefully the internet access network reaches all corners of the country",
    "First ResponseID": 11152,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Semoga internet semakin berkembang luas agar anak bangsa kita bisa paham dengan dunia teknologi internet",
    English:
      "Hopefully the internet will grow wider so that our nation's children can understand the world of internet technology",
    "First ResponseID": 12263,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Saya yakin, karena saat ini lembaga kami sedang memperkuat CSO ditingkat lokal agar mampu beradaptasi dan melakukan transformasi secara digital, sehingga dapat menjalankan program organisasi dengan memperkuat kapasitas dan pengetahuan dibidang teknologi dan digital.",
    English:
      "I am sure, because currently our institution is strengthening CSOs at the local level to be able to adapt and transform digitally, so that they can carry out organizational programs by strengthening capacity and knowledge in technology and digital.",
    "First ResponseID": 2638,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Saya menjadi salah satu narasumber untuk literasi digital di kabupaten saya. Tantangan saat ini adalah masalah akses internet. Kami memerlukan akses internet melalui fiber optik (yang lebih murah dibandingkan dari satelit).",
    English:
      "I became one of the resource persons for digital literacy in my district. The current challenge is the problem of internet access. We need internet access via fiber optic (which is cheaper than from satellite).",
    "First ResponseID": 3126,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Saya berharap semoga layanan internet semakin baik sehingga tidak ada kendala di kemudian hari",
    English:
      "I hope all internet services will be better so that there will be no problem in the future",
    "First ResponseID": 11250,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Berharap kedepannya pemerintah lebih peduli dengan rakyat kelas menengah kebawah dengan cara memberikan akse internet secara gratis",
    English:
      "I hope that in the future the government will be more concerned with the people of the lower middle class by providing free internet access",
    "First ResponseID": 11179,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "saya mengharapkan agar kedepan internet bisa dipergunakan secara optimal, jika memang regulasinya harus berkaitan dengan pajak untuk meningkatkan layanan tidak masalah, namun jika tidak mending bagaimana caranya internet murah dan bisa diakses semua khalayak",
    English:
      "I hope that in the future the internet can be used optimally, if indeed the regulations must be related to taxes to improve services, there is no problem, but if not, how can the internet be cheap and accessible to all audiences",
    "First ResponseID": 2728,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Saya berharap kemajuan teknologi  juga di iringi dengan kemjuan sumber daya manusianya",
    English:
      "I hope that technological progress is also accompanied by the advancement of human resources",
    "First ResponseID": 11339,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "saya tinggal di pedesaan yang terletak di lereng gunung dengan jaringan provider yang tidak semua berfungsi. tantangan terbesar adalah menemukan provider yang cocok untuk daerah kami dengan koneksi internet yang baik. kami butuh lebih banyak smartphon",
    English:
      "I live in a rural area on the slopes of a mountain with a network where not all providers work. the biggest challenge is finding a suitable provider for our area with a good internet connection. we need more smartphones",
    "First ResponseID": 11775,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "menurutku akses internet didaerah perdesaan masih sangat terbatas. diharapkan para BUMN khususnya pada bidang telekomunikasi memperluas jangkauan internet sampai pelosok desa",
    English:
      "I think internet access in rural areas is still very limited. It is hoped that SOEs, especially in the telecommunications sector, will expend the reach of the internet to remote villages",
    "First ResponseID": 10319,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Saya kira untuk akses internet masih terbatas dengan kecepatan yg ada saat ini masih belum merata terutama di bagian Indonesia Timur sehingga konektivitas jaringan terutama di wilayah terpencil harus lebih diprioritaskan",
    English:
      "I think that internet access is still limited with the current speed that is still not evenly distributed, especially in the eastern part of Indonesia so that network connectivity, especially in remote areas, must be prioritized.",
    "First ResponseID": 2642,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Saya ingin media sosial depenuhi dengan kontwn positif yg bermanfaat",
    English: "I want social media to be filled with useful positive content",
    "First ResponseID": 16563,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "saya bekerja untuk membantu pendidikan anak marjinal di area slum perkotaan Indonesia. Banyak area sangat susah akses internet, tidak tau apakah karena infrastruktur tidak memadai atau karena terhalang, gedung-gedung tinggi.",
    English:
      "I work to help the education of marginalized children in Indonesia's urban slum areas. Many areas are very difficult to access the internet, don't know if it is because of the inadequate infrastructure or because it blocked by the tall buildings",
    "First ResponseID": 11034,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Lebih ditingkatkan layanan internet dengan kecepatan yang standard dan harga yang terjangkau",
    English:
      "Improved internet service with standard speed and affordable price",
    "First ResponseID": 11371,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Selain akses layanan dan ketrampilan digital yang masih dasar, peralatan yang kurang mumpuni juga menjadi kendala dalam mengembangkan aktivitas digital",
    English:
      "In addition to access to services and basic digital skills, inadequate equipment is also an obstacle in developing digital activities",
    "First ResponseID": 14562,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Di dunia yang berkembang pada saat ini akses digital sangat kami butuhkan untuk memberikan literasi kepada masyarakat agar dunia digital pada era ini menjadi bermanfaat. Oleh karena itu penting nya bagi kami support dalam hal apapun untuk mementori masyarakat agar sadar akan dunia digital.",
    English:
      "In today's developing world, we really need digital access to provide literacy to the community so that the digital world in this era can be useful. Therefore, it is important for us to support in any case to mentor the public to be aware of the digital world.",
    "First ResponseID": 2602,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Biaya internet yang semakin mahal ,perangkat mahal",
    English: "Increasingly expensive internet costs, expensive devices",
    "First ResponseID": 11771,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "keterbukaan informasi dan akses internet dengan melindungi privasi setiap warga negara adalah kewajiban di negara demokrasi",
    English:
      "information disclosure and internet access by protecting the privacy of every citizen is an obligation in a democratic country",
    "First ResponseID": 13842,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Kecepatan internet yg kurang, dan mahal nya biaya internet di indonesian",
    English:
      "Insufficient internet speed, and the high cost of internet in Indonesia",
    "First ResponseID": 11380,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Akses dan keterjangkauan internet, ketrampilan dan keamanan digital. Biaya internet dan akses digital mahal",
    English:
      "Internet access and affordability, digital skills and security. Expensive internet and digital access fees",
    "First ResponseID": 2633,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "akses internet mudah dan merata menjangkau daerag2 terpencil",
    English: "Internet access is easy and evenly distributed to remote areas",
    "First ResponseID": 9167,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Akses internet sangat penting dalam mendukung kinerja kita. Kecepatan internet sangat berpengaruh terhadap kinerja kita.",
    English:
      "Internet access is very important in supporting our performance. The internet speed is very influential on our performance",
    "First ResponseID": 11101,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Akses internet harus semakin di perluas untuk akses segala informas",
    English: "Internet access must be expanded to access all information",
    "First ResponseID": 12501,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Jangkauan dan layanan internet dipelosok2 desa perku ditingkatkan lagi, karen sinyal susah dan sulit.",
    English:
      "Internet coverage and service in remote villages need to be improved",
    "First ResponseID": 11672,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Jangkauan internet lebih bisq masuk ke pedalaman desa agar merata",
    English:
      "Internet coverage is more accessible to the interior of the village so that it is evenly distributed",
    "First ResponseID": 14879,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Internet adalah kebutuhan dasar setiap manusia kedepannya",
    English: "Internet is a basic needs of every human being in the future",
    "First ResponseID": 11227,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "internet untuk mendapatkan informasi tentang pekerjaan kantor khusus dalam bidang pengelolaan hutan lestari berbasis masyarakat, penggalangan Dana untuk pengelolaan hutan lestari",
    English:
      "internet to get information about the work of specialized offices in the field of community-based sustainable forest management, fundraising for sustainable forest management",
    "First ResponseID": 8204,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Apakah jaringan untuk pelosok tersedia?",
    English: "Is the network for remote areas available?",
    "First ResponseID": 2744,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Diharapkan dapat mempermudah masyarakat dalam menjangkau informasi apapun da dibutuhkan sosialisasi kepada masyarakat tentang pera penting teknologi dalam kehidupan sehari hari.",
    English:
      "It is hoped that it will make it easier for the public to reach any information and socialization is needed to the public about the important role of technology in everyday life.",
    "First ResponseID": 2566,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Dibutuhkan layanan internet yang handal tanpa terbatas jam dan harga terjangkau seluruh lapisan masyarakat",
    English:
      "It takes reliable internet service without limited hours and affordable prices for all levels of society",
    "First ResponseID": 12213,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Keterbatasan fasilitas dalam mengerjakan kegiatan sosial",
    English: "Limited facilities in carrying out social activities",
    "First ResponseID": 11843,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Mungkin lebih perbanyak pelatihan soal alat digital dan cara penggunaannya",
    English: "Maybe more training about digital tools and how to use them",
    "First ResponseID": 11304,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Kemungkinan beberapa orang belum banyak yang menggerti tentang Digital",
    English: "Maybe some people don't understand much about Digital",
    "First ResponseID": 12565,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Meminimalisir akses internet terlarang dengan konten yang tidak layak untuk anak",
    English:
      "Minimizing prohibited internet access with content that is inappropriate for children",
    "First ResponseID": 12127,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Keterbatasan jaringan seluler dan perangkat yang tidak mendukung di daerah kami",
    English: "Mobile network limitations and unsupported devices in our area",
    "First ResponseID": 11981,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "tantangan ternesar saya ketika Pekerjaan Blm selesai tapi signalnya Kadang suka hilang membuat pekerjaan jadi terhambat",
    English:
      "My biggest challenge is when my work is not finished yet but the signal is sometimes lost. It hinders my productivity",
    "First ResponseID": 11240,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan terbesar organisasi saya dalam ekuitas digital adalah ketersediaan jaringan setiap saat dan kecepatan akses internet serta ketersediaan alat dan kecepatan akses internet bagi orang yang kami layani",
    English:
      "My organization's biggest challenge in digital equity is network availability around the clock and speed of internet access and availability of tools and speed of internet access for the people we serve",
    "First ResponseID": 11924,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan terbesar kami terutama di negara mami yaitu pemerintah tidak melaksanakan kewajibannya dalam melindungi data privasi warga negaranya.",
    English:
      "Our biggest challenge is that the government does not carry out its obligations in protecting the privacy data of its citizens.",
    "First ResponseID": 11327,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan terbesar kami adalah ketika tidak adanya dukungan dari sebagian masyarakat yg memandang komunitas kami tidak begitu penting",
    English:
      "Our biggest challenge is when there is no support from some people who see our community as not so important community",
    "First ResponseID": 11529,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Masalah terbesar kami dengan internet adalah karena wilayah kerja organisasi kadang jauh dan akses internet tidak tersedia secara stabil dan memadai.",
    English:
      "Our biggest problem with the internet is that the organization's work area is sometimes remote and internet access is not available in a stable and adequate manner.",
    "First ResponseID": 2610,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Organisasi kami mebutuhkan alat dan sarana untuk menjalankan ekuitas digital kami",
    English:
      "Our organization needs the tools and means to run our digital equity",
    "First ResponseID": 16732,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "pengetahuan masyarakat terhadap teknologi dan etika dalam kegiatan online masih sangat rendah sangat dipengaruhi oleh peran pemerintah yang tidak berkualitas",
    English:
      "Public knowledge of technology and ethics in online activities is still very low, strongly influenced by the role of the government which is not qualified",
    "First ResponseID": 11302,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Layanan internet kepelosok masih terkendala sinyal di handphone kalau pake WiFi juga terkadang ada gangguan",
    English:
      "Remote internet service is still constrained by the cellphone signal. if you use WiFi, sometimes there are disturbances",
    "First ResponseID": 11350,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Memohon dukungan dan menjalin kerjasama untuk meningkatkan inovasi teknologi informasi dan Digitalisasi untuk komunitas",
    English:
      "Request support and establish cooperation to improve information technology innovation and digitization for the community",
    "First ResponseID": 15234,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Keamanan di internet itu amatlah penting, tapi sampai sekarang kita masih merasa aman saat berada di internet",
    English:
      "Security on the internet is very important, but until now we still feel safe when on the internet",
    "First ResponseID": 2776,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Layanan untuk mengakses data, mengolalah jaringan internet",
    English: "Services for accessing data, managing the internet network",
    "First ResponseID": 11705,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Sejauh ini memang banyak masalah sektor digital dalam organisasi kami. Tapi kami berusaha untuk menyelesaikannya sendiri. Kecepatan internet salah satu masalah utama tersebut.",
    English:
      "So far there have been many digital sector problems in our organization. But we are trying to solve it ourselves. Internet speed is one such major problem.",
    "First ResponseID": 8617,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Sejauh ini penggunaan dan layanan internet utk daerah pinggiran kota dan pedesaan sangat minim dan jarang karena memang kurang tersedianya fasilitas tsb..kurangnya sinyal sehingga ketika terjadi proses belanja mengajar ataupun bekerja yg menggunakan teknologi internet sangat susah dan amat tidak maksimal, yg pastinya hal tsb krg menguntungkan dan menggangu kegiatan2 tsb.",
    English:
      "So far, internet usage and services for suburban and rural areas are very minimal and rare due to the lack of availability of these facilities. the lack of signal makes the work process that uses internet technology, such as teaching, working, or shopping, very difficult and not optimal, which is certainly not profitable and interferes with the activities.",
    "First ResponseID": 11785,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Sejauh ini sangat aman, mudah dan kondusif dalam mendukung kinerja komunitas Jenis akses digital pun tepantau lancar dan aman . Semoga seluruh orang bijak dalam menggunakan internet karna sangat berpengaruh besar dalam kehidupan kita",
    English:
      "So far, it has been very safe, easy and conducive to supporting community performance. The type of digital access is monitored smoothly and safely. Hopefully all people are wise in using the internet because it is very influential in our lives",
    "First ResponseID": 11180,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Terkadang layanan internet disini sangat mahal untuk orang yang tidak mampu. Sebaiknya turunkan harga untuk orang yang tidak mampu",
    English:
      "Sometimes the internet service here is very expensive for people who can't afford it. Better lower the price for people who can't afford it",
    "First ResponseID": 12020,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Belum adanya dana hibah dalam pengembangan pendidikan dasar IT tanpa biaya",
    English:
      "The absence of grant funds in the development of basic IT education without cost",
    "First ResponseID": 8573,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Ketersediaan perangkat keras dan lunak yang terjangkau oleh masyarakat masih tinggi",
    English:
      "The availability of hardware and software that is affordable by the community is still high",
    "First ResponseID": 12463,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan yang besar yang saya hadapi adalah kemampuan orang-orang dalam hal digital internet masih kurang",
    English:
      "The big challenge that I face is the peope's ability in terms of internet digital is still lacking",
    "First ResponseID": 11062,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan terbesar bagi daerah terpencil seperti daerah saya adalah kurangnya sosialisasi dan ketersediaan akses internet yang masih bisa dikatakan mahal bagi sebagian orang..",
    English:
      "The biggest challenge for remote areas like mine is the lack of socialization and the availability of internet access which can still be said to be expensive for some people.",
    "First ResponseID": 14740,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan terbesar yang saya hadapi adalah agak sulit untuk mengajak masyarakat berpindah ke era digital.",
    English:
      "The biggest challenge I face is that it is a little bit difficult to get people to move to the digital era.",
    "First ResponseID": 11359,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan terbesar adalah bagaimana memastikan komunitas mendapatkan akses pelatihan dan literasi digital guna perimbangan ditengah pesatnya perkembangan dunia digital",
    English:
      "The biggest challenge is how to ensure that the community has access to training and digital literacy for balance in the midst of the rapid development of the digital world",
    "First ResponseID": 4821,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Tantangan terbesar adalah akses internet di pedesaan",
    English: "The biggest challenge is internet access in rural areas",
    "First ResponseID": 14860,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Tantangan terbesar memenuhi kebutuhan internet yg meningkat",
    English: "The biggest challenge is meeting the growing internet demand",
    "First ResponseID": 11770,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "tantangan terbesar adalah tidak adanya signal internet yang mumpuni di pulau-pulau kecil di wilayah kerja kami, sehingga laporan dan update kegiatan di wilayah kerja tidak dapat dilakukan secara realtime. Dampak lain juga terhambatnya pelaporan administrasi karena untuk mendapatkan signal yang baik untuk pengiriman email rekan-rekan kami dilapangan perlu pindah pulau ataupun pergi ke kota terdekat yang jaraknya pun lumayan agak jauh.",
    English:
      "The biggest challenge is the absence of a good internet signal on the small islands in our working area, so that reports and updates on activities in the work area cannot be done in real time. Another impact is the delay in administrative reporting because to get a good signal for sending email, our colleagues in the field need to move islands or go to the nearest city which is quite far away.",
    "First ResponseID": 5154,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan terbesar adalah kurang nya dukungan bagi organisasi kami dari pemerintah sehingga banyak keterbatasan yang kami alami",
    English:
      "The biggest challenge is the lack of support for our organization from the government so that we experience many limitations",
    "First ResponseID": 11019,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan terbesar adalah terjangkaunya sinyal terbatas di daerah yang terpencil untuk akses internet.",
    English:
      "The biggest challenge is the limited coverage of the signal in remote areas for internet access",
    "First ResponseID": 11008,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan terbesar adalah layanan internet yang tidak stabil dan sering kali memburuk. Terkadang disaat hujan deras, internet tidak dapat digunakan hingga beberapa hari. Hal ini menyebabkan komunikasi terputus baik dengan para donatur maupun penerima donasi",
    English:
      "The biggest challenge is the unstable and often deteriorating internet service. Sometimes when it rains heavily, the internet cannot be used for several days. This causes communication to be lost both with donors and recipients of donations",
    "First ResponseID": 13002,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan terbesar saat ini adalah bagaimana perangkat internet baik software maupun hardware dapat terjangkau untuk seluruh lapisan masyarakat.",
    English:
      "The biggest challenge today is how internet devices, both software and hardware, can be affordable for all levels of society.",
    "First ResponseID": 8576,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Tantangan terbesar ketika para peneliti terkendala oleh modal",
    English:
      "The biggest challenge when researchers are constrained by capital",
    "First ResponseID": 11994,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Kendala terbesar dalam dunia digital di pedesaan adalah lemahnya jaringan, apa bila jaringan lebih baik maka penduduk desa juga bisa kita perkenalkan dunia digital yang bisa menguntungkan secara ekonomi dan pendidikan untuk mereka",
    English:
      "The biggest obstacle in the digital world in rural areas is the weakness of the network, if the network is better then we can also introduce the villagers to the digital world that can be economically and educationally beneficial for them",
    "First ResponseID": 2630,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan dalam meng sosialisasikan internet di pedesaan terkadang terhambat oleh kurangnya ilmu pengetahuan dari warga yang masuh tidak paham internet dan menggunakan internet sesuka hati tanpa mengetahui bahaya dan tidaknya sehingga serig terjadi scam yang merugikan mereka terutama orang orang yang sudah berumur 40tahun keatas yang rata rata tidak perpendidikan tinggi",
    English:
      "The challenge in socializing on the internet in rural areas is sometimes hampered by the lack of knowledge from the people who do not understand the internet and use the internet at will without knowing the dangers so that scams often occur and harm them, especially people who are 40 years old and above who are on average not highly educated",
    "First ResponseID": 11460,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Tantangan yaitu jaringan yang masih belum lancar dan optimal",
    English:
      "The challenge is that the network is still not smooth and optimal",
    "First ResponseID": 11209,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan ketika kita buat tugas dan tiba tiba sinyal internet jelek itu sangat tidak baik",
    English:
      "The challenge is when we do our assignments and suddenly the internet signal is bad. That is so not good",
    "First ResponseID": 11249,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Tantangan yang kami hadapi adalah akses pada berbagai layanan teknologi untuk manajemen organisasi dengan harga terjangkau",
    English:
      "The challenge we face is access to various technology services for organizational management at affordable prices",
    "First ResponseID": 7042,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Pemerintah negara kami sebagai regulator, sangat tidak paham mengenai pentingnya keterbukaan informasi publik di internet, dan kami sangat di batasi terkait dengan kebebasan berpendapat di dunia digital. Regulator kami malah memberikan akses luas kepada judi online dan admin slot.",
    English:
      "The government of our country, as a regulator, does not understand the importance of public information disclosure on the internet, and we are severely restricted in terms of freedom of expression in the digital world.",
    "First ResponseID": 2748,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Pentingnya Literasi Digital di zaman sekarang untuk menangkal Hoax yang berkembang",
    English:
      "The importance of digital literacy in today's era to ward off growing hoaxes",
    "First ResponseID": 4797,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Keterjangkauan internet di daerah pelosok",
    English: "The internet affordability in remote areas",
    "First ResponseID": 10993,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Internet diera sekarang menjadi kebutuhan pokok dalam bidang pendidikan, riset, kebutuhan rumah tangga, pekerjaan, dll",
    English:
      "The internet in today's era has become a basic need in the fields of education, research, household needs, work, etc",
    "First ResponseID": 2616,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Jaringan internet ke pelosok sangat minim sehingga sering mengalami kendala sulit sinyal",
    English:
      "The internet network to remote areas is very minimal so they often experience difficult signal problems",
    "First ResponseID": 11314,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Jaringan sinyal internet yang harusnya merata diseluruh daerah",
    English:
      "The internet signal network that should be evenly distributed throughout the area",
    "First ResponseID": 11525,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Jaringan yang kadang tidak stabil n nirkabel yang kadang suka bermasalah",
    English:
      "The network sometimes unstable and the wireless is sometimes problematic",
    "First ResponseID": 11202,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Kemajuan internet luarbiasa. Ketersediaan alat2 utk akses internet sudah bagus dan canggih namun jangkauan, kecepatan akses dan pemahaman internet masih kurang memadai. Kadang pula kebijakan pemerintah belum bisa maksimal mendukung dunia digital. Harapan akses digital menjangkau semua orang masih memerlukan usaha dan fokus lebih ekatra lagi dari semua pihak baik pengguna, produsen, dan pemerintah.",
    English:
      "The progress of the internet is amazing. The availability of tools for internet access is good and sophisticated, but the range, speed of access and understanding of the internet are still inadequate. Sometimes government policies have not been able to fully support the digital world. The hope of digital access to reach everyone still requires more effort and focus from all parties, including users, producers, and the government.",
    "First ResponseID": 16504,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Belum meratanya kades internet terutama di kawasan timur Indonesia, serta masih kurangnya literasi",
    English:
      "The uneven distribution of internet village heads, especially in eastern Indonesia, and the lack of literacy",
    "First ResponseID": 2769,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Kendala karena terdapat beberapa wilayah yang tidak tercover internet yang saya gunakan",
    English:
      "There are some areas that are not covered by the internet provider that I use",
    "First ResponseID": 11290,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original: "Masih ada keterbatasan internet di desa",
    English: "There are still internet limitations in the village",
    "First ResponseID": 11081,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Banyak nya konten yang kurang mendidik dan bebasnya sosial media",
    English:
      "There is a lot of content that is less educative and the freedom of social media",
    "First ResponseID": 11193,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Perlu ada nya konten yang objektif dan menarik tentang layanan kesehatan, karena saat ini masyarakat masih kurang tertarik dalam konten dan layanan kesehatan.",
    English:
      "There needs an objective and interesting content about health services because currently, people are still less interested in health content and services.",
    "First ResponseID": 11277,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Untuk melayani mqsyarakat kelompok terpinggirkan maka solusi yg harus dilakukan bersifat mendesak adalah pembelajaran internet / digital secara terus menerus dengan dukungan masyarakat internasional.",
    English:
      "To serve marginalized groups, the solution that must be done urgently is continuous internet/digital learning with the support of the international community.",
    "First ResponseID": 12044,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Pemahaman akan pentingnya memasuki era digital, kemampuan pengadaan serta keterampilan dalam menggunakan perangkat digital dan internet. Pembuatan konten, maintanance web dan medos official serta bagaimana cara meningkatkan SEO",
    English:
      "Understanding of the importance of entering the digital era, procurement capabilities and skills in using digital devices and the internet. Content creation, web maintenance and official media and how to improve SEO",
    "First ResponseID": 2747,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Koneksi internet yang belum stabil mempengaruhi kinerja organisasi kami, kemudian skill penggunaan teknologi informasi pekerja di organisasi kami masih terbatas",
    English:
      "Unstable internet connection affects the performance of our organization, then the skills of using information technology of workers in our organization are still limited",
    "First ResponseID": 2605,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Berbagai tantangan dunia digital dan kamu dapat belajar dari berbagai diskusi publik",
    English:
      "Various challenges of the digital world and you can learn from various public discussions",
    "First ResponseID": 14961,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Kami bersedia bekerjasama mengimplementasikan penyelesaian masalah yang banyak diangkat dalam survey ini",
    English:
      "We are willing to work together to implement the resolution of the many problems raised in this survey",
    "First ResponseID": 2788,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "Kekurangan yang kita alami adalah kurangnya pendidikan digital di area yang kita alami, sumber daya manusianya masih gaptek",
    English:
      "We experience the lack of digital education in the area, the human resources are still clueless",
    "First ResponseID": 12063,
  },
  {
    Language: "Indonesian",
    "Country Code": "ID",
    "HQ Country": "Indonesia",
    Original:
      "kami masih sangat berharap ada donor yang bisa membiayai program organisasi kami",
    English:
      "We still really hope that there are donors who can finance our organization's programs",
    "First ResponseID": 4718,
  },
  {
    Language: "English",
    "Country Code": "IE",
    "HQ Country": "Ireland",
    Original:
      "More affordable internet service for older people, and good training on how to use the internet for those over 70 years of age",
    English:
      "More affordable internet service for older people, and good training on how to use the internet for those over 70 years of age",
    "First ResponseID": 1020,
  },
  {
    Language: "English",
    "Country Code": "IE",
    "HQ Country": "Ireland",
    Original:
      "Yes. We are a non for profit organization and its so expensive to keep upgrading our workstations. We are also bringing digital devices to the older persons in the community to stop them from feeling isolated. A lot of people have a family to help with the skills but most are very isolated.  We provide some visits out to help with the training but have not the affordable resources to serve all. The cheapest broadband for senors is 20 euro a month. They find it difficult to spend this out of their pensions.",
    English:
      "Yes. We are a non for profit organization and its so expensive to keep upgrading our workstations. We are also bringing digital devices to the older persons in the community to stop them from feeling isolated. A lot of people have a family to help with the skills but most are very isolated.  We provide some visits out to help with the training but have not the affordable resources to serve all. The cheapest broadband for senors is 20 euro a month. They find it difficult to spend this out of their pensions.",
    "First ResponseID": 965,
  },
  {
    Language: "English",
    "Country Code": "IE",
    "HQ Country": "Ireland",
    Original:
      "Availability of broadband varies across Ireland. Our head office is in the country so has poor broadband service. A national scheme by Government to provide equal broadband service across Ireland will take years to deliver.",
    English:
      "Availability of broadband varies across Ireland. Our head office is in the country so has poor broadband service. A national scheme by Government to provide equal broadband service across Ireland will take years to deliver.",
    "First ResponseID": 1849,
  },
  {
    Language: "English",
    "Country Code": "IE",
    "HQ Country": "Ireland",
    Original:
      "Increasing awareness of digital ethics and strong moral and ethical behaviour as we embrace born-digital",
    English:
      "Increasing awareness of digital ethics and strong moral and ethical behaviour as we embrace born-digital",
    "First ResponseID": 5990,
  },
  {
    Language: "English",
    "Country Code": "IE",
    "HQ Country": "Ireland",
    Original:
      "Once online, open data is equally important. Despite legislation in many jurisdictions, access to data is still an issue in many cases.",
    English:
      "Once online, open data is equally important. Despite legislation in many jurisdictions, access to data is still an issue in many cases.",
    "First ResponseID": 1102,
  },
  {
    Language: "English",
    "Country Code": "IE",
    "HQ Country": "Ireland",
    Original:
      "Our users would mostly use mobile rather than computers but I would say our biggest issues are cost and ability to use technology.",
    English:
      "Our users would mostly use mobile rather than computers but I would say our biggest issues are cost and ability to use technology.",
    "First ResponseID": 2818,
  },
  {
    Language: "English",
    "Country Code": "IE",
    "HQ Country": "Ireland",
    Original:
      "There are lots of resources available but access is very severely limited by language, culture and income level.",
    English:
      "There are lots of resources available but access is very severely limited by language, culture and income level.",
    "First ResponseID": 2557,
  },
  {
    Language: "English",
    "Country Code": "IE",
    "HQ Country": "Ireland",
    Original:
      "We are an Irish language organization and online resources are not always available in our language. Technology and software being available in our minority language is very important to us.",
    English:
      "We are an Irish language organization and online resources are not always available in our language. Technology and software being available in our minority language is very important to us.",
    "First ResponseID": 974,
  },
  {
    Language: "Italian",
    "Country Code": "IT",
    "HQ Country": "Italy",
    Original:
      "Avere dei formatori e motivatori in grado si abbattere le barriere e le paure sugli strumenti digitali",
    English:
      "Having trainers and motivators who can break down barriers and fears about digital tools",
    "First ResponseID": 16827,
  },
  {
    Language: "Italian",
    "Country Code": "IT",
    "HQ Country": "Italy",
    Original:
      "Le politiche sociali nazionali (accesso al servizio sanitario gestione delle pratiche assistenziali informazioni pensionistiche) sono sempre più digitalizzate quando invece i soggetti target di queste politiche sono per loro natura affetti da una cronica carenza di strumenti o di capacità informatiche",
    English:
      "National social policies (health service access welfare file management pension information) are increasingly digitized when in contrast the target actors of these policies are inherently suffering from a chronic lack of IT tools or capabilities  the target actors of these policies are inherently suffering from a chronic lack of IT tools or capabilities",
    "First ResponseID": 17285,
  },
  {
    Language: "Italian",
    "Country Code": "IT",
    "HQ Country": "Italy",
    Original:
      "I soci della mia organizzazione non udenti oralisti hanno necessità di avere sottotitoli in italiano. Sono stati fatti pochi passi in questo senso👀",
    English:
      "The members of my organization who are hearing-impaired oralists have a need for Italian subtitles. Few steps have been taken in this direction",
    "First ResponseID": 16883,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "前略、 世界中で新型コロナが蔓延し、今まで、インターネットなどのデジタルツールから無縁の人たちも、それを使わなければ行けない状況になったことは、ある意味、進歩だと思っています。 しかしながら、個々人のデジタルスキルの格差がより広まったと思います。 NPO [redacted] では、その格差を埋めるため、個々人の家庭に無償訪問し、教育も行っています。しかし、私達の団体の資金規模は小さく、それを行うための十分な人材や機材の確保に苦労しています。 日本国政府は、やっとデジタル！と言い出しましたが、主導する方々のデジタル知識の不備、不足があると感じています。 行政機関でもやっと、昨年末（2021年末）くらいから、Web会議が可能な行政機関が出てきました。このスピード感は、私たち国民のほうが早かったと言えます。 私達、国民は、新型コロナ蔓延当初から、Zoom、Webexなどの手段を学び、実践していました。 調査・アンケート　お疲れ様でした。",
    English:
      "I think it is in a sense progress that COVID spreading all over the world and that people who have never had access to digital tools such as the Internet are now forced to use them. However I believe that the gap in individual digital skills has widened. [Organization redacted] is trying to bridge this gap by visiting individual families free of charge and also providing education. However the size of our organization's funding is small and we are struggling to secure sufficient human resources and equipment to do so. The Japanese government has finally started to take Digital seriously but I feel that there is a lack of digital knowledge on the part of those who are taking the lead. Finally some government institutions began to offer web conferencing at the end of last year (2021). We can say that we citizens were faster at this speed than they were. We learned and practiced means such as Zoom and Webex from the beginning of the COVID pandemic. Thank you for your efforts in the survey and questionnaires.",
    "First ResponseID": 2366,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "機器及びソフトやインフラが高額なため、活動に制限がある。地方では総務省の目指すものとの乖離を感じてます。公平ではありません。どこでも誰でも、もっと安価で気軽にできる環境になることを願っています。",
    English:
      "Activities are limited due to the high cost of devices software and infrastructure. In rural areas we feel there is a disconnect with what the Ministry of Internal Affairs and Communications is trying to achieve and it is not fair. I hope that the environment will become more affordable and easy for anyone anywhere.",
    "First ResponseID": 6921,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "スタッフの高齢化に伴い、現在のネット環境に関するスキルが相対的に低下している。スタッフの年齢多様性を確保する必要があるが、ボランティア団体なのでどうしても退職者中心になっている。",
    English:
      "As the staff ages there is a relative decline in skills related to the current internet environment. There is a need to ensure diversity in the age of the staff but since we are a volunteer organization we inevitably have mainly retirees.",
    "First ResponseID": 1555,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "・利用者他関係者への情報提供、関係者との情報共有、購買など一貫かつ簡便で分かりやすいプロセスと仕組みづくり ・SNSを使った効果的な告知 ・動画配信を使った効果的な説明ツールの提供 ・インターネット決済手数料が高いこと",
    English:
      "Creation of consistent easy-to-understand processes and mechanisms for providing information to users and other stakeholders sharing information with stakeholders and making purchases etc./Effective advertising using social networking services/Providing effective explanation tools using video distribution/Having high Internet payment fees",
    "First ResponseID": 15762,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original: "ＨＰの安全性確保や保証が難しい",
    English: "Difficult to secure and guarantee the safety of the website",
    "First ResponseID": 1272,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "デジタル技術により情報のやり取りは非常に便利になっているが、それを活用する側のスキルやリテラシーにばらつきが大きくなっている。",
    English:
      "Digital technology has made the exchange of information extremely convenient but the skills and literacy of those who use it are becoming more uneven.",
    "First ResponseID": 3826,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "業務に当たっては一人一台パソコンが必要ですが、一度購入すれば永遠に使用できるものではなく定期的に買い替えが必要になる。ソフトに関してもサブスクが増え、金銭面での負担が大きい。",
    English:
      "Each staff member needs one computer for work but once purchased it cannot be used forever and must be replaced periodically. As the number of subscriptions for software also increases which places a heavy financial burden on the organization.",
    "First ResponseID": 15720,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "認知症高齢者や障がい者はデジタルを活用したコミュニケーション、情報へのアクセスなどは十分にできないし、できるようになるための保障が十分にされているとは言えません。",
    English:
      "Elderly people with dementia and people with disabilities are not fully capable of communicating and accessing information digitally nor are they adequately guaranteed to be able to do so.",
    "First ResponseID": 15909,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original: "一定のデジタルスキルを取得できる公的な教育プログラムの充実。",
    English:
      "Enhancement of public educational programs to acquire certain digital skills",
    "First ResponseID": 9152,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "子供達に向けての映像編集機器やソフトの無償提供。映像の非営利的な学校を作りたい。",
    English:
      "Free video editing equipment and software geared toward children. We want to create a non-profit school for the visual arts.",
    "First ResponseID": 3840,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "シニアがデジタルデバイドから脱却し、インターネットを活用するための方策",
    English:
      "How seniors can break out of the digital divide and take advantage of the Internet",
    "First ResponseID": 8622,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "手話者です。 デジタルインフラやスキルがまだまだ足りなく、電話リレーサービス普及がなかなか進まない問題を解決していきたいです。",
    English:
      "I am a signer. We would like to solve the problem of the lack of digital infrastructure and skills and the slow progress in the spread of telephone relay services.",
    "First ResponseID": 16417,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original: "自国は世界的に恵まれている国だと感じている。",
    English:
      "I feel that my country is one of the most fortunate countries in the world.",
    "First ResponseID": 3812,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "どこでもWifiが無料で使えたり、インターネットが利用できる環境を整えてほしい",
    English: "I would like to have free wifi and internet access everywhere.",
    "First ResponseID": 3489,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original: "デジタルツールのスキルアップを目指したいです。",
    English: "I would like to improve my skills in digital tools.",
    "First ResponseID": 4650,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "日本では学校のIT化が進まず、何よりも閉鎖的な学校のあり方が障壁となり、せっかく配備された子ども用の機器が、子どもの学校外や地域での活動に活用されていません。そのための効果的なアクションを立ち上げたり、活用のためのネットワークを構築することを中間団体であるテックスープ等に期待しています。",
    English:
      "In Japan the lack of IT in schools and above all the closed nature of schools are barriers and the equipment for children that has been deployed is not being utilized outside of school and in the community. We expect TechSoup and others as an intermediary to launch effective actions for this purpose and build a network for utilization.",
    "First ResponseID": 2064,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original: "日本の場合は、地方自治体のデジタルツールが遅れている。",
    English:
      "In the case of Japan local governments lag behind in digital tools.",
    "First ResponseID": 2790,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "近い将来、小中高生の為にオンライン形式の授業を行いたいと考えております。ますますインターネットとデジタルツールを利用する機会が増えてくると思っております。",
    English:
      "In the near future we would like to offer online classes for elementary middle and high school students. We believe that more and more opportunities will arise to utilize the Internet and digital tools.",
    "First ResponseID": 15557,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "中小企業の担当へのITスキル教育、環境整備は当事者任せでは予算的にも難しいことが多いです。",
    English:
      "IT skills training and environmental improvement for SMEs in charge is often difficult to accomplish on a budget if left to the parties involved.",
    "First ResponseID": 3900,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "当法人の利用者が主として肢体不住者が多く高ぴゅーやーを使えるスキルを身に着けられない。",
    English:
      "Many of our users are mainly physically disabled and cannot acquire the skills to use computers.",
    "First ResponseID": 4796,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "Many people with disabilities and their supporters have low digital skills and low incomes. Therefore, the device and communication environment are low compared to the general Japanese.",
    English:
      "Many people with disabilities and their supporters have low digital skills and low incomes. Therefore the device and communication environment are low compared to the general Japanese.",
    "First ResponseID": 1228,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "会員夫々ネット環境ともっているデバイスの性能が違うことと、個人情報の保護、リテラシー、特に今、デジタルスキル習得の方法と場所（どこで効率よく習得できるか）について困っています。",
    English:
      "Members have different internet environments and devices and are having trouble with personal information protection literacy and especially how and where to learn digital skills efficiently.",
    "First ResponseID": 4633,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "当センターの活動の対象は個人と団体だが、個人への支援の場合、ITを使用することが難しい。機器も環境もない高齢者や女性が多い。団体においては機器が不足あるいは古い、ソフトや通信環境にお金を掛けられない、特にサブスクの現実があり、系統的に学ぶ機会や費用、時間がない。自団体でも常に議題になるが、費用がネックになって実現できない。",
    English:
      "Our center's activities target individuals and organizations but in the case of assistance to individuals it is difficult to use IT because many elderly people and women have neither the equipment nor the environment to use IT because many elderly people and women have neither the equipment nor the environment to use IT. For organizations there is a lack of devices or having older ones and the reality of not being able to spend money on software and communication environment especially subscriptions. They lack the opportunity cost and time to learn these things in a systematic manner. It is always on the agenda of our own organization but the cost is a bottleneck that prevents us from realizing it.",
    "First ResponseID": 4659,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "団体で契約している携帯がなく個人に頼っている。業務関連の連絡もするので、どのように切り分けるべきか考えている",
    English:
      "Our organization does not have a contracted cell phone and relies on individuals. We are considering how we should do this since we also make work-related contacts.",
    "First ResponseID": 3842,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "幣団体は非営利組織向けに提供されているいくつかの無料のサービス（teams,officeなど）を活用しているが、中部地域での普及はまだまだと感じる。 クラウド会計やオンライン会議などは有料での活用である。オンライン会議は助成金や委託事業の経費に盛り込むことができるが、クラウド会計は非営利組織向けの無料サービスがなく自己資金から何とかねん出している。 オンライン会議が主流になり、首都圏（関東）の非営利組織が主催する様々なプログラムに参加しやすくなったことから、地域の非営利組織支援団体の役割を再検討しなくてはならないと感じている。 首都圏の非営利組織とのデジタル技術の格差を感じる。",
    English:
      "Our organization utilizes several free services (such as Teams and Office) that are available for nonprofits but we felt that these services are not yet widely available in the Chubu region. Cloud accounting and online conferencing services are available with fees. While online meetings can be included in the cost of grants and outsourced projects cloud accounting is not available as a free service for NPOs so we somehow manage to fund it out of our own resources. As online conferencing has become mainstream and it has become easier to participate in various programs organized by NPOs in the Tokyo metropolitan area (Kanto) we feel that the role of local NPO support centers needs to be reconsidered. We feel that there is a gap in digital technology with nonprofits in the Tokyo metropolitan area.",
    "First ResponseID": 1675,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "当団体は、2011年の東北大震災における児童養育支援を目的として設立。当初より、デジタルによるクライアントとの関係性を重視して来た。問題は、インターネットインフラが高価である事、特にクライアントの環境が十分でない事にある。日本のインターネット環境が通信料、その他ツールの価格が高く、通信環境が稚拙にならざるを得ないのが実情だ。",
    English:
      "Our organization was established to support child care in the aftermath of the Great East Japan Earthquake and Tsunami in 2011. From the beginning we have focused on digital relationships with our clients. The problem is that internet infrastructure is expensive especially for clients. The reality of the Japanese Internet environment is that communication fees and tools are expensive forcing the communication environment to be poor.",
    "First ResponseID": 3913,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "当団体の利用者は高齢者となるが、現在の利用者はデジタルスキルが乏しい方が多く、送り手の情報、手段が制限されている。昨年より、利用者の層を広げるべく新たな広報活動を開始している。 職員のスキルもレベルアップを目指したい。 そのためのコンテンツがあれば利用したい。",
    English:
      "Our organization's users are elderly and many of them have poor digital skills so the sender (user) side is limited in information and means. Last year we started a new publicity campaign to broaden our user base. We would also like to improve the skill level of our staff. If there are contents for this purpose we would like to use them.",
    "First ResponseID": 16155,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original: "Reskilling for retired peple",
    English: "Reskilling for retired peple",
    "First ResponseID": 3879,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "指導できる人員の確保。その後のトレーニング。SNSや動画編集などにたけている人へのレクチャーにネット環境を使って教育を無償放映してほしい。",
    English:
      "Securing training personnel with subsequent training. I would like to see lectures by people skilled in social networking and video editing broadcast free of charge on the Internet.",
    "First ResponseID": 16127,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "小規模組織ではスタッフのデジタルスキルの共有化が大きな課題。研修は個人の向上心に頼るしかない。スキルが無くてもサポート可能なソフトの普及を希望。",
    English:
      "Sharing staff digital skills is a major challenge for small organizations. Training can only rely on individual aspirations. Wish for widespread use of software that can support even those without skills.",
    "First ResponseID": 4544,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "当団体は高齢者と深く関係するため、スマートフォンの普及が今後どの程度まで進むかが鍵となっています。老眼になり手元の文字が見えにくくなることによって、スマートフォンを持つメリットが少なくなるという問題があります。情報の多いものは自宅のパソコンで閲覧するという方法を取る方が一般的ですが、スマートフォンしか所有していない方も増えており、大量の情報をインターネットから受けることのメリットを享受できない高齢者が増えていることが懸念されます。スマートフォンの大型化やタブレット端末がより高性能化することを期待するとともに、プロバイダー等にはセキュリティ対策を標準装備として契約に盛り込んで頂くなど、高齢者やシステムを理解しづらい一般市民にも使いやすい環境整備を望んでいます。",
    English:
      "Since our organization is deeply involved with the elderly the extent to which the use of smartphones will continue to grow is key. The problem is that as people become presbyopic and have difficulty seeing the text at hand the benefits of owning a smartphone diminish. While it is more common for the elderly to use their home computers to view large amounts of information an increasing number of people own only smartphones and there is a concern that more elderly people will not be able to enjoy the benefits of receiving large amounts of information from the Internet. We hope to see larger smartphones and tablet devices with higher performance and we also hope that providers will include security measures as standard equipment in their contracts to create an environment that is easy to use for the elderly and the general public who have difficulty understanding the system.",
    "First ResponseID": 4648,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "千葉県内の公民館はWiFi通信設備が無いところがあり、団体活動が阻害されている。",
    English:
      "Some community centers in Chiba Prefecture do not have WiFi communication facilities which inhibits group activities.",
    "First ResponseID": 1218,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "デジタル化に抵抗のある会員がいる。切り捨てができないので、デジタルでの対応同等のことをアナログで行っている。  当団体はデジタル化を推進したい。",
    English:
      "Some members are resistant to digitalization. Since we cannot cut off such members we are doing the same things in analog as we do in digital. Our organization wants to promote digitalization.",
    "First ResponseID": 15580,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "高齢化している日本の視覚障害者が、デジタル社会に取り残されれうことのない諸政策が必要",
    English:
      "The aging visually impaired in Japan needs policies to ensure that they are not left behind in the digital world.",
    "First ResponseID": 1296,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "質の高い音声データのやりとりが、大きな課題である。 マイクなどの機器、音声認識のより高いレベルの認識など、多方面の発展を待っている",
    English:
      "The exchange of high-quality voice data is a major challenge. We are waiting for developments in many areas such as microphones and other equipment and higher levels of speech recognition.",
    "First ResponseID": 1592,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "年齢の壁、収入格差の壁によるインターネットの不公平があり、また団体の運営者による理解不足がさらに不公平格差を助長しています。政策でどの程度の解消できるかはわかりませんが、地域行政の末端まで、問題点をはやく落とし込んで欲しいです。",
    English:
      "There are inequities in the Internet due to age barriers and income disparity barriers. In addition the lack of understanding by group executives further contributes to the inequity gap. I am not sure how much can be resolved through policy but I would like to see the problems quickly addressed to the end of local governments.",
    "First ResponseID": 15605,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "当団体のサービスを一番必要とし受ける人々の日常を一番身近にサポートする親の高齢化が、デジタル格差を生じさせている状況がみられること。また家庭環境の所得格差も公平性への道のりを遠ざけているのではないかと懸念される。",
    English:
      "There is a digital divide among the aging parents who are most in need of our organization's services and who provide the primary support for the daily lives of those who receive our services. We are also concerned that income disparities in family circumstances may also be a roadblock on the path to equity.",
    "First ResponseID": 15715,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "情報公開や事業活動報告等の入力?制作作業が多いが、外部委託やボランティアに頼れる内容で無い為にスタッフの負担が大きい。",
    English:
      "There is a lot of typing and production work for information disclosure and business activity reports but the staff's burden is heavy because the content cannot be outsourced or depend on volunteers.",
    "First ResponseID": 16244,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "ICTを利用する側のリテラシー不足の問題がある。安全にICTを活用したいが、小規模な事業所がVPNを構築したり、職員全員が職場のデバイスを持つようなことは経済的に難しい。よって助成金を利用しこれを果たせるよう検討している。",
    English:
      "There is an issue of lack of literacy on the part of ICT users. We want to use ICT safely but it is financially difficult for a small business facility like us to construct a VPN and make all staff members have their own devices at the workplace. Therefore we are considering using a grant to help accomplish this.",
    "First ResponseID": 1231,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "全国組織で、会員が5歳から100歳を超えます。地域によって、年代によって、インターネット使用状況が違い、全会員へのインターネットでの情報共有がまだまだ、難しく感じています。すべての人がインターネットにアクセスできる環境が政府によって整えられると良いと思っています。",
    English:
      "We are a national organization with members ranging in age from 5 to over 100 years old. We think that it is still difficult to share information via the Internet to all members as Internet usage differs by region and age group. We hope that the government will create an environment where all people can access the Internet.",
    "First ResponseID": 1427,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "視覚障害者の情報提供している団体です。視覚障害者専用のアプリなども近年増えていますが、健常者と同じアプリを使う際には、まだまだ利便性が悪いと感じることが多く、操作方法の相談などがあると苦慮しています。",
    English:
      "We are an organization that provides information for the visually impaired. Although there has been an increase in the number of applications specifically for the visually impaired in recent years when using general applications we often still find them less convenient and we struggle when asked for advice on how to operate them.",
    "First ResponseID": 1237,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "子どもの支援の活動をしています。子どもにとって、インターネットへのアクセスの権利は晃平に保障されているとはいいがたく。家庭の経済状況及び保護者の教育方針に大きく左右されています。 インターネット及びソーシャルメディアに関するリテラシー教育の機会の不足は、世代を問わない課題と考えています。",
    English:
      "We are engaged in activities to support children. For children the right to access the Internet is not always guaranteed. It is largely dependent on the economic situation of the family and the educational policy of the parents. We believe that the lack of literacy education opportunities regarding the Internet and social media is a challenge for all generations.",
    "First ResponseID": 15858,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original: "セキュリティ機器導入など安全性確保のための経費捻出が課題。",
    English:
      "We are facing the challenge of generating expenses to ensure safety such as the installation of security equipment.",
    "First ResponseID": 16164,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "クラウド電話の導入を進めているが難航している。 事務所の電話番号を継続して使える事業者が少なく、料金も高い。 電話工事を事業者がしてくれず、自分たちでする必要がある。",
    English:
      "We are in the process of implementing cloud telephony but are having difficulty. There are few venders that can continue to use the office phone number and the fees are high. Our vender does not do phone installation so we have to do it ourselves.",
    "First ResponseID": 15619,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "災害時にも（災害時こそ）被災した人々が安全に高速なインターネットが使える環境が必要と考えます。",
    English:
      "We believe that even during (or because of) a disaster people affected need an environment where they can use high-speed Internet safely.",
    "First ResponseID": 4149,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "私も含め視覚障害者が多いのですが、アクセスするのに困ることが多いので、音声操作とマウスを使わずキーボードのみで操作が可能となることを国際基準としていただきたいです。",
    English:
      "We have many visually impaired people including myself who often have trouble accessing the system so I would like to see an international standard for voice control and the ability to use only the keyboard without a mouse.",
    "First ResponseID": 16531,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "限りある資金で何に使うのか。また有効な使い方は何なのかをより戦略的に検討をする必要がある。",
    English:
      "We need to be more strategic about what we spend our limited funds on and how we use them effectively.",
    "First ResponseID": 8342,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "高校生・大学生・大学院生に奨学金を給付していますが、金銭供与以外にもサポートを広げていきたいです。",
    English:
      "We provide scholarships to high school college and graduate students but would like to expand our support beyond monetary grants.",
    "First ResponseID": 15701,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "特定の製品(パソコンやソフトウェア)を大量購入し、非営利法人向けに正規価格より安価に販売するなどの支援策があるとよいと思った。",
    English:
      "We thought it would be good to have support measures such as purchasing certain products (computers and software) in large quantities and selling them to NPOs at a lower price than the regular price.",
    "First ResponseID": 18780,
  },
  {
    Language: "Japanese",
    "Country Code": "JP",
    "HQ Country": "Japan",
    Original:
      "おもな連絡方法がLINEやペーパーレスなどに向かう中で、今もなおガラケーなどの携帯をご使用されているので、舵を切ることができない。(ご利用者さまの経済なとの理由により) 一定の基準をもうけ、端末などの変更ができる政策があればよいと考えています。",
    English:
      "While the main method of communication is moving toward LINE and paperless methods we are still using feature phones so we are unable to change the direction of communication. We would like to see a policy that allows users to change their devices based on their financial situation.",
    "First ResponseID": 4560,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "All these need resources infrastructure,digital skills and affordable devices to enable us do our advocacy work especially when we have to do cyber feminism",
    English:
      "All these need resources infrastructure,digital skills and affordable devices to enable us do our advocacy work especially when we have to do cyber feminism",
    "First ResponseID": 9908,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "Another major challenge is the shortage of devices which has hindered our performance as a virtual office which has her work centered on online advocacy. Due to lack of resources, we have been unable to purchase devices as well as hold digital safety and security trainings for the organization (Staff, Board  and the community we serve) limiting us on digital literacy.",
    English:
      "Another major challenge is the shortage of devices which has hindered our performance as a virtual office which has her work centered on online advocacy. Due to lack of resources, we have been unable to purchase devices as well as hold digital safety and security trainings for the organization (Staff, Board  and the community we serve) limiting us on digital literacy.",
    "First ResponseID": 8839,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "In rural Kenya & many other parts of Africa, digital equity is affected by access to electricity. Many communities are literally still cut off completely.  Secondly, in Kenya digital literacy is not possible without without normal literacy- what we have seen is older people remembering shapes that they relate to names for phones and they use vice, and mobile money is the driver of digital literacy fir most people.  In addition, most adults are learning digital literacy from their children and young people - and young people are learning direct from the internet - google & YouTube.",
    English:
      "In rural Kenya & many other parts of Africa, digital equity is affected by access to electricity. Many communities are literally still cut off completely.  Secondly, in Kenya digital literacy is not possible without without normal literacy- what we have seen is older people remembering shapes that they relate to names for phones and they use vice, and mobile money is the driver of digital literacy fir most people.  In addition, most adults are learning digital literacy from their children and young people - and young people are learning direct from the internet - google & YouTube.",
    "First ResponseID": 3922,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "Thank you for shedding more light to my understanding on internet access to a digital world.  My challenge is always the reliability to internet access, sometimes it is very poor such that you can not do anything meaningful on your work. If there is a way to improve the speed and how easy my people can asses internet then it would be an advantage to teach them more about it. The ones who are using cellular data also find it hard since its too expensive to pay for it.",
    English:
      "Thank you for shedding more light to my understanding on internet access to a digital world.  My challenge is always the reliability to internet access, sometimes it is very poor such that you can not do anything meaningful on your work. If there is a way to improve the speed and how easy my people can asses internet then it would be an advantage to teach them more about it. The ones who are using cellular data also find it hard since its too expensive to pay for it.",
    "First ResponseID": 4830,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "The cost of digital tools like anti virus and other important applications and programs are our biggest challenges so we opt to using what is free which makes us uncertain about quality.",
    English:
      "The cost of digital tools like anti virus and other important applications and programs are our biggest challenges so we opt to using what is free which makes us uncertain about quality.",
    "First ResponseID": 4430,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original: "Advocate for internet as a basic right",
    English: "Advocate for internet as a basic right",
    "First ResponseID": 3532,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "How to low the cost of digital tools and increasing youth and young adults accessibility of internet services in rural areas as way of reducing youth migration to urban centers.",
    English:
      "How to low the cost of digital tools and increasing youth and young adults accessibility of internet services in rural areas as way of reducing youth migration to urban centers.",
    "First ResponseID": 4522,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "I wish there could be a pace setting organization that could support an easier reach of information. I wish the would be more affordable options for internet packages.",
    English:
      "I wish there could be a pace setting organization that could support an easier reach of information. I wish the would be more affordable options for internet packages.",
    "First ResponseID": 3322,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "I would require the full support in how to support in free internet coverage to the margialized and poor community which I serve in slum and rural villages",
    English:
      "I would require the full support in how to support in free internet coverage to the margialized and poor community which I serve in slum and rural villages",
    "First ResponseID": 18095,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original: "Internet access should be enhanced especially in rural areas.",
    English: "Internet access should be enhanced especially in rural areas.",
    "First ResponseID": 4022,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "Internet accessibility is the biggest issue in Tharaka where we work followed closely by the aspect of affordability",
    English:
      "Internet accessibility is the biggest issue in Tharaka where we work followed closely by the aspect of affordability",
    "First ResponseID": 12638,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "Our organization would really benefit form digital skillls through training to help in our outreach programs and fundraising efforts.",
    English:
      "Our organization would really benefit form digital skillls through training to help in our outreach programs and fundraising efforts.",
    "First ResponseID": 4104,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "The greatest challenge is with the vulnerable community that I serve concerning affordability to smart phones and skills to use to access virtual trainings and group discussions. Very few have capabilities",
    English:
      "The greatest challenge is with the vulnerable community that I serve concerning affordability to smart phones and skills to use to access virtual trainings and group discussions. Very few have capabilities",
    "First ResponseID": 5180,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "The greatest challenge we have is sometimes it is hard getting to do online counselling services to people because they do not have stable internet or afford internet for tha matter which makes our work hard",
    English:
      "The greatest challenge we have is sometimes it is hard getting to do online counselling services to people because they do not have stable internet or afford internet for tha matter which makes our work hard",
    "First ResponseID": 8208,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "The high illiteracy among the people my organization serves is a big deterrent to digital equity in this part of the world",
    English:
      "The high illiteracy among the people my organization serves is a big deterrent to digital equity in this part of the world",
    "First ResponseID": 10714,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "We are currently implimenting a Digital Skills program that seeks to narrow the gender gap in digital skills between girls and boys in the remotest part of Kenya. We use one laptop to visit schools and teach basic computer knowledge to our ado,escent girls. We shall really appreciate any kind of partnership and linage to relevant and potential organizations. To learn more about our work, please check our Facebook page at (https://web.facebook.com/TotoCentreInitiative). Twitter (https://twitter.com/totocentre)",
    English:
      "We are currently implimenting a Digital Skills program that seeks to narrow the gender gap in digital skills between girls and boys in the remotest part of Kenya. We use one laptop to visit schools and teach basic computer knowledge to our ado,escent girls. We shall really appreciate any kind of partnership and linage to relevant and potential organizations. To learn more about our work, please check our Facebook page at (https://web.facebook.com/TotoCentreInitiative). Twitter (https://twitter.com/totocentre)",
    "First ResponseID": 2987,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "We need grants in order to access computers and softwares to enable us achieve more, in terms of enhancing digital equity",
    English:
      "We need grants in order to access computers and softwares to enable us achieve more, in terms of enhancing digital equity",
    "First ResponseID": 8344,
  },
  {
    Language: "English",
    "Country Code": "KE",
    "HQ Country": "Kenya",
    Original:
      "We work in the most rural part of Kenya where access to internet is below 5%.  This is due to high cost of Android phones and internet bundles.",
    English:
      "We work in the most rural part of Kenya where access to internet is below 5%.  This is due to high cost of Android phones and internet bundles.",
    "First ResponseID": 17714,
  },
  {
    Language: "English",
    "Country Code": "LR",
    "HQ Country": "Liberia",
    Original:
      "Liberia west Africa internet poverty population does not have  cheap and affordable technology for the the betterment foe all.",
    English:
      "Liberia west Africa internet poverty population does not have  cheap and affordable technology for the the betterment foe all.",
    "First ResponseID": 6358,
  },
  {
    Language: "English",
    "Country Code": "LR",
    "HQ Country": "Liberia",
    Original:
      "Our youth development NGO is requesting for support to set up an Enterpreneurship Lab (eLab) in Liberia to increase young people skills through digital training for innovation and enterprise development.",
    English:
      "Our youth development NGO is requesting for support to set up an Enterpreneurship Lab (eLab) in Liberia to increase young people skills through digital training for innovation and enterprise development.",
    "First ResponseID": 8535,
  },
  {
    Language: "English",
    "Country Code": "LR",
    "HQ Country": "Liberia",
    Original:
      "Training in the use of software tools is essential to efficient and effective project delivery. I will appreciate benefiting from such training as well as acquiring hardware support and software tailor to project implementations.",
    English:
      "Training in the use of software tools is essential to efficient and effective project delivery. I will appreciate benefiting from such training as well as acquiring hardware support and software tailor to project implementations.",
    "First ResponseID": 9734,
  },
  {
    Language: "English",
    "Country Code": "MW",
    "HQ Country": "Malawi",
    Original:
      "We lack good internet service for our smooth running of data accessibility.  Secondly, we lack computers with good performance.",
    English:
      "We lack good internet service for our smooth running of data accessibility.  Secondly, we lack computers with good performance.",
    "First ResponseID": 8453,
  },
  {
    Language: "English",
    "Country Code": "MW",
    "HQ Country": "Malawi",
    Original:
      "Main challenge is access to infrastructure and affordability of internet as well as equipment. Our organization provides digital skills and the demand and opportunity is high but our infrastructure is inadequate to support this.",
    English:
      "Main challenge is access to infrastructure and affordability of internet as well as equipment. Our organization provides digital skills and the demand and opportunity is high but our infrastructure is inadequate to support this.",
    "First ResponseID": 7963,
  },
  {
    Language: "French",
    "Country Code": "ML",
    "HQ Country": "Mali",
    Original:
      "C'est un de mes souhaits de partager notre expérience en matière digitale pour les prochains jours. Si vous désirez bien",
    English:
      "It is one of my wishes to share our experience in digital matters for the next few days. If you wish to",
    "First ResponseID": 3019,
  },
  {
    Language: "English",
    "Country Code": "MU",
    "HQ Country": "Mauritius",
    Original:
      "One thing that may skew your data is that as a registered nonprofit, we have extensive access to Microsoft Office and have volunteers who are training up our organization and our staff in this regard.  So I have ticked that we have access to digital programs and it is not expensive, but it is expensive if you have to pay for it.",
    English:
      "One thing that may skew your data is that as a registered nonprofit, we have extensive access to Microsoft Office and have volunteers who are training up our organization and our staff in this regard.  So I have ticked that we have access to digital programs and it is not expensive, but it is expensive if you have to pay for it.",
    "First ResponseID": 10171,
  },
  {
    Language: "English",
    "Country Code": "MU",
    "HQ Country": "Mauritius",
    Original:
      "We're growing increasingly worried with the increasing Cold War between India & China on our part of the world. Internet spying seems to be the initial battleground unfortunately.",
    English:
      "We're growing increasingly worried with the increasing Cold War between India & China on our part of the world. Internet spying seems to be the initial battleground unfortunately.",
    "First ResponseID": 10594,
  },
  {
    Language: "English",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "We constantly face the violation of the human right to privacy, for the moment we try our best to adapt and continue our work but we lack of  tools, software and skills to overcome this.  Thanks for this initiative",
    English:
      "We constantly face the violation of the human right to privacy, for the moment we try our best to adapt and continue our work but we lack of  tools, software and skills to overcome this.",
    "First ResponseID": 5843,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Una cosa es si la información está accesible en el idioma que hablan los beneficiarios (niños y adolescentes), pero a muchos y sus familiares les cuesta casi no saben leer, y muchos de sus padres/tutores tiene español como segunda idioma, hablan algún dialecto.",
    English:
      "First thing is if the information is accessible in the language spoken by the beneficiaries (children and teenagers), but many other children and teenagers and their relatives can barely read, and many of their parents/tutors have Spanish as a second language, they speak native languages.",
    "First ResponseID": 14677,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Ha sido difícil para las personas indígenas que viven en las localidades marginadas tener acceso al servicio de internet",
    English:
      "It has been difficult for indigenous people living in marginalized communities to have access to internet service",
    "First ResponseID": 9110,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      'Muchas gracias, trabajamos apoyando escuelas comunitarias en localidades indígenas de Oaxaca. Lamentablemente el acceso a dispositivos (computadoras) e internet es una necesidad imperante pero es muy compleja. Actualmente hay una "moda" de no apoyar iniciativas asistenciales de donar equipo de computo. En su momento, era un hit pero en estas comunidades no era un tema prioritario aunque ahora sí lo es. Ojalá pudieran vincularnos con alguna empresa o Fundación que pueda apoyarnos para equipar estas escuelas con computadoras que sirvan para nuestros niños, niñas y jóvenes. Cabe señalar que hace un par de años una empresa nos donó cientos de equipos de cómputo que ¡no sirvieron! No es ético, no se vale. Era equipo que sirve únicamente para call centers y ahora tenemos un problema tan grande porque quisiéramos deshacernos de manera responsable de esa basura electrónica pero nos cuesta mucho dinero.',
    English:
      'Thank you very much, we work supporting community schools in indigenous communities in Oaxaca. Unfortunately, access to devices (computers) and internet is a prevailing need, but it is very complex. Currently there is a "tendency" of not supporting assistance initiatives to donate computer equipment. At a time, it was successful but in these communities it was not a priority issue, although now it is. I wish you could link us with a company or Foundation that can support us to equip these schools with computers that are useful for our children and young people. It should be noted that a couple of years ago a company donated hundreds of computers that were useless! It is not ethical, it is not worth it. It was equipment used only for call centers and now we have such a big problem because we would like to discard these electronic waste responsibly but it costs us a lot of money.',
    "First ResponseID": 14637,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "La pandemia evidencio más la inequidad en el acceso a las tecnologías y servicios digitales. Aún así en mi localidad en general las asociaciones civiles medianas o pequeñas no tienen un presupuesto asignado a este rubro pues los recursos se dirigen más a la operación de programas y servicios no en materia de tecnología y equipos.",
    English:
      "The pandemic evidenced inequity in access to digital technologies and services. Even so, in my community, in general, medium or small civil associations do not have a budget assigned to this item, since the resources are directed more to the programs operations and services, not in terms of technology and equipment.",
    "First ResponseID": 16035,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Nos interesa datos abiertos, transparencia, monitoreo ciudadano, y educación acompañada de espacios para acceder, discutir y explicar la información de calidad de agua en el país.",
    English:
      "We are interested in open data, transparency, citizen monitoring, and education accompanied by spaces to access, discuss, and explain information on water quality in the country.",
    "First ResponseID": 13483,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "EXPERIMENTAMOS COMO A.C. DESFASADOS PERO EN EL INTENTO ESTAMOS , HASTA PLANTEARNOS DE CONTRATAR UN EXPERTO EN CUESTION DIGITAL",
    English:
      "AS A NONPROFIT ORGANIZATION WE EXPERIENCE CHALLENGES BUT WE ARE IN THE ATTEMPT UNTIL WE CAN AFFORD TO HIRE A DIGITAL EXPERT",
    "First ResponseID": 14708,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "La equidad digital en muchos paises de latinoamerica no existe debido a la corrupción del gobierno",
    English:
      "Digital equity in many Latin American countries does not exist due to government corruption",
    "First ResponseID": 4676,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Durante la pandemia, tuvimos fuertes inconvenientes para lograr continuar las clases vía digital.",
    English:
      "During the pandemic, we had serious problems being able to continue classes digitally.",
    "First ResponseID": 2980,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Para personas con recursos limitados es importante contar con una herramienta tan efectiva y puntual como un dispositivo electrónico con capacidad para actividades en linea.",
    English:
      "For people with limited resources, it is important to have a tool as effective and punctual as an electronic device capable of online activities.",
    "First ResponseID": 7611,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Creo que debemos buscar las formas para disminuir esta brecha digital para lograr la equidad y el desarrollo para todos.",
    English:
      "I believe that we must seek ways to reduce this digital divide to achieve equity and development for all.",
    "First ResponseID": 16526,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "En México no existe actualmente un compromiso al más alto nivel por abatir la brecha digital y garantizar la equidad digital, a pesar de ser un derecho humano reconocido constitucionalmente desde hace 9 años",
    English:
      "In Mexico there is currently no commitment at the highest level to reduce the digital divide and guarantee digital equity, despite being a constitutionally recognized human right for 9 years",
    "First ResponseID": 2355,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "En México el derecho al acceso a la información es debil, así como poder contar con la infraestructura necesario para hacer un efectivo derecho por parte de la sociedad civil y la comunidad. Gracias",
    English:
      "In Mexico, the right to access to information is weak, as well as being able to count on the necessary infrastructure to exercise an effective right by civil society and the community. Thank you",
    "First ResponseID": 6989,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "En nuestras comunidades no tienen dispositivos electrónicos porque antes deben resolver otros temas y si llegan a tenerlos no tienen acceso gratuito a Internet.",
    English:
      "In our communities they do not have electronic devices because they must first solve other issues and if they do have them they do not have free access to Internet connection.",
    "First ResponseID": 2959,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "En zonas rurales, aunque se ubican empresas que proveen los servicios de conexión a internet, estos fallan constantemente, se pierde la comunicación, es lento, en tiempo de lluvias el servicio deja de funcionar por varios días.",
    English:
      "In rural areas, although there are companies that provide Internet connection services, they constantly fail, communication is lost, it is slow, in rainy weather the service stops working for several days.",
    "First ResponseID": 4379,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "En las comunidades Tseltales con las que trabajamos su acceso a internet es comprando tarjetas para conectarse con su celular",
    English:
      "In the Tseltal communities where we work, their access to the internet is by buying cards to connect with their cell phones.",
    "First ResponseID": 1553,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Interesante conocer el resultado de cómo nos encontramos hoy en día, en un mundo que fue un antes y un después de la pandemia del COVID-19 de cómo fuimos y somos dependientes cada día de las tecnologías y el acceso al internet y de su impacto en las comunidades más remotas de mi país.",
    English:
      "Interesting to know the result of how we find ourselves today, in a world that now is understood as a before and after the COVID-19 pandemic, how we were and are dependent every day on technologies and internet access and their impact on the most remote communities in my country.",
    "First ResponseID": 9963,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "El internet es una poderosa herramienta de comunicación e información que debería servir para todas las personas.",
    English:
      "Internet is a powerful communication and information tool that should serve everyone.",
    "First ResponseID": 2955,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Cada vez es mas obsoleta la velocidad de internet a un pago accesible, si se requiere, que de hecho sí se requiere, aumentar la velocidad para que de abasto a la comunidad el costo no es accesible para la Asociación. e igual manera el poder acceder a herramientas necesarias para la creación y desarrollo de contenidos digitales no es práctica ya que los recursos que lo facilitarían son impagables para la asociación.",
    English:
      "Internet speed is increasingly obsolete at an accessible payment, if it is required to increase the speed so that it can supply the community, which in fact is required, the cost is not accessible for the Association. and in the same way, being able to access the necessary tools for the creation and development of digital content is not practical since the resources that would facilitate it are unaffordable for the organization.",
    "First ResponseID": 3740,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Definitivamente las organizaciones pequeñas es muy dificil que tengamos recursos y personal, muchas no somos donatarias autorizadas y eso hace más difícil lograrlo.",
    English:
      "It is definitely very difficult for small organizations to have resources and personal, many of us are not authorized donees (tax expempt) and that makes it more difficult to achieve.",
    "First ResponseID": 9161,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Es difícil para nosotros aterrizar las ventajas del internet en nuestros servicios directos con los beneficiarios, ya que lo principal y de mayor impacto que generamos es un servicio persona-persona en el acompañamiento de salud y muchas veces el internet es de ayuda indirecta para la rendición de cuentas y transparencia de los datos.",
    English:
      'It is difficult for us to "land" the advantages of the Internet in our direct services with the beneficiaries, since the main thing and the biggest impact we generate is a person-to-person service in health monitoring and many times the Internet is of indirect help for surrender of accounts and data transparency.',
    "First ResponseID": 3026,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "es importante profesionalizar a las organizaciones sociales y conectar recursos desde el mercado económico que tiene que ser más responsable de su comunidad y entorno y desarrollar el mercado ambiental y el mercado social",
    English:
      "it is important to professionalize social organizations and link resources from the economic market which has to be more responsible for its community and environment, and develop the environmental market and the social market",
    "First ResponseID": 2972,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Es necesario acercar a las comunidades y personas vulnerables dispositivos para qie exista una equidad digital.",
    English:
      "It is necessary to bring devices closer to communities and vulnerable people so that there is digital equity.",
    "First ResponseID": 14739,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Me parece que el tema de la pandemia aceleró la importancia de considerar estas herramientas tecnológicos tras el confinamiento. lo que se tradujo en un crecimiento dispar o visualizó más las brechas de desigualdad tecnológica. No identificamos ninguna pregunta relacionada. Si no hubiera habido pandemia me parece que no se hubiera visibilizado la problemática.",
    English:
      "It seems to me that the issue of the pandemic accelerated the importance of considering these technological tools after confinement, which translated into uneven growth and further visualized the gaps in technological inequality. We did not identify any related questions. If there had not been a pandemic, it seems to me that the problem would not have been made visible.",
    "First ResponseID": 3308,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Siempre servirá el conocer los avances del uso de la tecnología en los grupos en estado de vulnerabilidad",
    English:
      "It will always be useful to know the advances in the use of technology in vulnerable groups",
    "First ResponseID": 14699,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Principalmente se necesita mayor infraestructura y menores costos en servicios digitales, para que un mayor número de personas tengan la posibilidad de acceder a las distintas herramientas y tecnologías hoy en día.",
    English:
      "Mainly, greater infrastructure and lower costs in digital services are needed, so that a larger number of people have the possibility to access the different tools and technologies today.",
    "First ResponseID": 3010,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "mayor accesibilidad a los grupos indígenas en lugares inaccesibles",
    English: "More accessibility to indigenous groups in inaccessible places",
    "First ResponseID": 10378,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "La mayoría de las personas a las que sirve nuestra organización (jóvenes estudiantes de 11 a 22 años de edad) cuentan con habilidades digitales básicas, pero para su mejor desarrollo académico, personal y futuro laboral requerimos fortalecer habilidades y conocimientos avanzados y especializados, para lo cual el software especializado puede ser caro y la capacitación también, así como dispositivos con suficiente capacidad de procesamiento y velocidad para no hacer de esos aprendizajes un proceso frustrante y lento para los jóvenes. La mayor parte de las computadoras y laptops que disponemos para nuestros beneficiarios provienen de donaciones empresariales de equipos usados en buen o excelente estado de funcionamiento, pero de una, dos o tres generaciones tecnológicas anteriores y los dispositivos nuevos son muy caros en el mercado nacional y local.   Los dispositivos que usan nuestros beneficiarios son para su formación educativa, los utilizan en las instalaciones de la organización, tienen acceso a los equipos durante el periodo lectivo según lo requieran los docentes y programas educativos y en el transcurso del día en las horas libres y/o de realización de tareas escolares e investigación de los estudiantes. Al inicio y término del ciclo escolar los equipos de cómputo se revisan y optimizan por la empresa donante del equipo.",
    English:
      "Most of the people our organization serve (young students from 11 to 22 years old) have basic digital skills, but for their academic, personal and future professional development sake we need to strengthen advanced and specialized skills and knowledge, for which specialized software can be expensive and training too, as well as devices with sufficient processing capacity and speed so as not to make learning a frustrating and slow process for young people. Most of the computers and laptops that we have for our beneficiaries come from business donations of used equipment in good or excellent state, but, are from one, two or three previous tech generations and new devices are very expensive in the national and local market. The devices used by our beneficiaries are for educational training, they use them in the organization's labs, they have access to the equipment during the school period as required by teachers and educational programs and during during free hours day and/or during extra school assignments and research time. At the beginning and end of the school year, the computer equipment is reviewed and optimized by the company who donated the equipment.",
    "First ResponseID": 3294,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Uno de los desafíos más significativos es la consideración de que la equidad digital debe estar basada en una educación integral, que muestre que la equidad digital es solo una herramienta, y que debe ajustarse bajo los contextos de su mejor utilización posible.",
    English:
      "One of the most significant challenges is the consideration that digital equity must be based on comprehensive education, showing that digital equity is only a tool, and that it must be adjusted in the context of its best possible use.",
    "First ResponseID": 14646,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Accesibilidad a internet deficiente y costos muy  elevados. Tenemos confianza en muy pronto todas las comunidades tendrán acceso a internet gratis o a muy bajo costo y esperamos que se instaure un programa para que las OSC cuenten con acceso a internet gratis.",
    English:
      "Poor internet accessibility and very high costs. We are confident that very soon all communities will have access to the internet for free or at a very low cost and we hope that a program will be established so that organizations have free internet access.",
    "First ResponseID": 6974,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Las licencias de Software, que no sea negocio cuando cada día se hace un medio necesario para sobrevivir en un medio digitalizado.",
    English:
      "Software licenses, not just seen as a business when actually they are a every day necessary mean to survive in a digitized environment.",
    "First ResponseID": 2740,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "El mayor desafió al que nos enfrentamos con los beneficiarios, es que tengan acceso económico al servicio de Internet.",
    English:
      "The biggest challenge that we face with our beneficiaries is they having affordable access to Internet service.",
    "First ResponseID": 14670,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "El mayor reto que enfrentamos es la falta de acceso a internet a comunidades rurales y/o indígenas del país.",
    English:
      "The biggest challenge we face is the lack of internet access in rural and/or indigenous communities in the country.",
    "First ResponseID": 4984,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "EL DESAFIO QUE ENGRENTAMOS ES LA ASEQUIBILIDAD DE EQUIPOS PARA EL ACCESO A INTERNET.",
    English:
      "THE CHALLENGE WE FACE IS THE AFFORDABLE EQUIPMENT FOR INTERNET ACCESS.",
    "First ResponseID": 9134,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Las comunidades donde trabajamos no hay servicio de telefonía ni acceso a internet.",
    English:
      "The communities where we work do not have telephone service or internet access.",
    "First ResponseID": 16089,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "La brecha es muy grande especialmente con mujeres y en zonas indígenas, los productos no sólo deberían de ser para todxs también incluyentes.",
    English:
      "The divide is very large, especially with women and in indigenous areas, the products should not only be for everyone, but also inclusive.",
    "First ResponseID": 3051,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Lo laborioso de hacer la paginas que nos representen ante las organizaciones las paginas web de las sociedades civiles",
    English:
      "The laboriousness of making websites that represent us as civil society organizations",
    "First ResponseID": 10518,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Estamos desarrollando una plataforma digital para  la gestion en prevencion y tratamiento de adicciones en México. Nos gustaria poder compartirla con uds. Una esta en la etapa de desarrollo y estara disponible para quien quiera usarla. Saludos y muchas gracias",
    English:
      "We are developing a digital platform for the management of addiction prevention and treatment in Mexico. We would like to be able to share it with you. One platform is in development phase and will be available to anyone who wants to use it. Greetings and thank you very much",
    "First ResponseID": 15182,
  },
  {
    Language: "Spanish_S",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Me parece muy importante que investiguen sobre el tema ya que se habla del acceso a las herramientas digitales como si todo se redujera a querer utilizarlas.",
    English:
      "I think it is very important that they investigate the subject since access to digital tools is something widely discussed about, but the issue is wider and more complex; it is not only encouraging people to use them.",
    "First ResponseID": 17807,
  },
  {
    Language: "Spanish_S",
    "Country Code": "MX",
    "HQ Country": "Mexico",
    Original:
      "Estamos en Oaxaca y las y los jóvenes que atendemos debido a la pandemia tuvieron que utilizar el internet para recibir nuestros servicios, la mayoría no tenía acceso, ni dispositivos y mucho menos podía costear el pago del servicio. Afortunadamente ya estamos nuevamente regresando a la nueva normalidad y aún así el acceso es precario y de alto costo para las familias de escasos recursos.",
    English:
      'We are in Oaxaca and the young people we serve due to the pandemic had to use the Internet to receive our services. But mostof them had no access to the Internet, no devices, and more importantly, they could not afford to pay for the service. Luckily, we are once again returning to the "new normal" situation',
    "First ResponseID": 18672,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "MZ",
    "HQ Country": "Mozambique",
    Original:
      "O maior desafio é a qualidade de internet que existe em meu país. A velocidade é fraca e muitas vezes há cortes no fornecimento",
    English:
      "The biggest challenge is the quality of the internet that exists in my country. Speed is low and there are often power cuts",
    "First ResponseID": 17349,
  },
  {
    Language: "English",
    "Country Code": "MM",
    "HQ Country": "Myanmar (formerly Burma)",
    Original:
      "Think some question need to understand we are still working remotely, both in staffing and beneficiaries. The desire for some projects when safe is to have in person training. Secondly Myanmar is under a coup - so that radically changes the responses given.",
    English:
      "Think some question need to understand we are still working remotely, both in staffing and beneficiaries. The desire for some projects when safe is to have in person training. Secondly Myanmar is under a coup - so that radically changes the responses given.",
    "First ResponseID": 7057,
  },
  {
    Language: "Dutch",
    "Country Code": "NL",
    "HQ Country": "Netherlands",
    Original:
      "Onze grootste uitdaging momenteel is het kunnen geven van remote support (support in Brazilië vanuit Nederland) voor de mensen die we via pc-cursussen helpen. Uitdaging: kwaliteit van de verbinding en (voordelige) beschikbaarheid van tooling zoals TeamViewer.",
    English:
      "Our biggest challenge currently is being able to provide remote support (support in Brazil from the Netherlands) for the people we help through PC courses. Challenge: quality of connection and (inexpensive) availability of tooling such as TeamViewer.",
    "First ResponseID": 15455,
  },
  {
    Language: "Dutch",
    "Country Code": "NL",
    "HQ Country": "Netherlands",
    Original:
      "Zelden zo'n zelfbevredigende enquete gezien. Ik hoop dat u blij bent met de antwoorden en u daarmee weer fondsen kunt werven.",
    English:
      "Rarely seen such a self-congratulatory survey. I hope you are happy with the answers and can use them to raise funds again.",
    "First ResponseID": 12761,
  },
  {
    Language: "Dutch",
    "Country Code": "NL",
    "HQ Country": "Netherlands",
    Original:
      "Volkomen gebrek aan visie van de overheid op het  herstellen van de digitale ongelijkheid.",
    English:
      "Utter lack of government vision for redressing digital inequality.",
    "First ResponseID": 12693,
  },
  {
    Language: "English",
    "Country Code": "NZ",
    "HQ Country": "New Zealand",
    Original:
      "The [organization] has developed a kids gardening app and during its development and upon working with local schools, we ensured all the content in the app is made available offline. So the kids can download the app on their own devices at school and then be able to use the app at home.",
    English:
      "The [organization] has developed a kids gardening app and during its development and upon working with local schools, we ensured all the content in the app is made available offline. So the kids can download the app on their own devices at school and then be able to use the app at home.",
    "First ResponseID": 13680,
  },
  {
    Language: "English",
    "Country Code": "NZ",
    "HQ Country": "New Zealand",
    Original:
      "As an indigenous, heritage language community we are mindful that open access to information online poses issues for some of our people. We are developing a Learning Management System in our language, supported by learning resources. Our people are clear that some resources are not open to all learners and should remain the property of the tribes people that developed them.",
    English:
      "As an indigenous, heritage language community we are mindful that open access to information online poses issues for some of our people. We are developing a Learning Management System in our language, supported by learning resources. Our people are clear that some resources are not open to all learners and should remain the property of the tribes people that developed them.",
    "First ResponseID": 5082,
  },
  {
    Language: "English",
    "Country Code": "NZ",
    "HQ Country": "New Zealand",
    Original:
      "I think effort needs to be put into helping and reassuring older consumers around the access to and use of IT.",
    English:
      "I think effort needs to be put into helping and reassuring older consumers around the access to and use of IT.",
    "First ResponseID": 16721,
  },
  {
    Language: "English",
    "Country Code": "NZ",
    "HQ Country": "New Zealand",
    Original:
      "In rural NZ there are more than three main reasons for needing access to digital technology.  Health, Education, Banking, Govt services, social media for connection, etc.  It's a pity that wasn't an option",
    English:
      "In rural NZ there are more than three main reasons for needing access to digital technology.  Health, Education, Banking, Govt services, social media for connection, etc.  It's a pity that wasn't an option",
    "First ResponseID": 5178,
  },
  {
    Language: "English",
    "Country Code": "NZ",
    "HQ Country": "New Zealand",
    Original:
      "Internet/cell phone service is a real problem in my area. Many rural communities with no access to cell phone service and limited quality internet service. Many of us are not able to work or do school to our full capacity at home (for example, video calls over the internet aren't possible, and audio calls have a long lag). My community needs access to quality satellite internet, as many of us live nestled down winding gravel roads amongst many hills and mountains, and we are likely to never gain access to cell phone service at home.",
    English:
      "Internet/cell phone service is a real problem in my area. Many rural communities with no access to cell phone service and limited quality internet service. Many of us are not able to work or do school to our full capacity at home (for example, video calls over the internet aren't possible, and audio calls have a long lag). My community needs access to quality satellite internet, as many of us live nestled down winding gravel roads amongst many hills and mountains, and we are likely to never gain access to cell phone service at home.",
    "First ResponseID": 9991,
  },
  {
    Language: "English",
    "Country Code": "NZ",
    "HQ Country": "New Zealand",
    Original:
      "We are privileged in the area of NZ that we work, but we know that there are many who are less able to access what they need. Thanks for doing this work.",
    English:
      "We are privileged in the area of NZ that we work, but we know that there are many who are less able to access what they need. Thanks for doing this work.",
    "First ResponseID": 13815,
  },
  {
    Language: "English",
    "Country Code": "NZ",
    "HQ Country": "New Zealand",
    Original:
      "We find the number of people who can't or won't use digital technology to keep in touch frustrating. Trying to build confidence in technology with this group is time consuming.",
    English:
      "We find the number of people who can't or won't use digital technology to keep in touch frustrating. Trying to build confidence in technology with this group is time consuming.",
    "First ResponseID": 4971,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "NI",
    "HQ Country": "Nicaragua",
    Original:
      "El mayor desafío y que no presenta la opción es que no hay energía en todas las comunidades rurales, ni acceso a señal de internet y teléfonos inteligentes",
    English:
      "The biggest challenge, and is not mentiones in the survey, is that there is no energy in all rural communities, nor access to internet and smartphones",
    "First ResponseID": 3030,
  },
  {
    Language: "Arabic",
    "Country Code": "NG",
    "HQ Country": "Nigeria",
    Original:
      "I will continue to call key players in digital technology to formulate policies that emphasize inclusion and equality. Making digital technology as away to make profit is evil and promote inequality.",
    English:
      "I will continue to call key players in digital technology to formulate policies that emphasize inclusion and equality. Making digital technology as away to make profit is evil and promote inequality.",
    "First ResponseID": 7941,
  },
  {
    Language: "English",
    "Country Code": "NG",
    "HQ Country": "Nigeria",
    Original:
      "As an organization, we employ professionals on a part-time basis to assist our work. I have even learnt more on this survey and I hope to dwell more especially on some complex digital skills that I have never heard of.  Internet  policy is one of our challenges. Sometimes posts or content is blocked. Sometimes, the government and the internet service providers increase their tariffs unnecessarily without considering the growing organizations that may have affordability challenges. The people (rural Communities) we reach out to, basically because of illiteracy, lack of digital knowledge, socio- economic growth challenges too, do not have knowledge of digital, hence, most programmes are done physically, a mega challenge to connecting to the technology advancement in other developed nations. Affordability and inaccessibility challenges as an organization is another factor while the greatest challenge is non- accessible to extensive, engaging and comprehensive capacity building, networking, trainnings, workshops on digital journey.",
    English:
      "As an organization, we employ professionals on a part-time basis to assist our work. I have even learnt more on this survey and I hope to dwell more especially on some complex digital skills that I have never heard of.  Internet  policy is one of our challenges. Sometimes posts or content is blocked. Sometimes, the government and the internet service providers increase their tariffs unnecessarily without considering the growing organizations that may have affordability challenges. The people (rural Communities) we reach out to, basically because of illiteracy, lack of digital knowledge, socio- economic growth challenges too, do not have knowledge of digital, hence, most programmes are done physically, a mega challenge to connecting to the technology advancement in other developed nations. Affordability and inaccessibility challenges as an organization is another factor while the greatest challenge is non- accessible to extensive, engaging and comprehensive capacity building, networking, trainnings, workshops on digital journey.",
    "First ResponseID": 9428,
  },
  {
    Language: "English",
    "Country Code": "NG",
    "HQ Country": "Nigeria",
    Original:
      "The digital equity is already unavailable! In fact, we are working to improve the situation. The greatest challenge is that; our organization in general has no infrastructure to avail it's operations and those we work to serve find it difficult to have access to the internet and misconceived investing in technology as a tool for development. Finally, we may need if possible: computers good working system for trainings, workshops,seminars to advance to this digital world.",
    English:
      "The digital equity is already unavailable! In fact, we are working to improve the situation. The greatest challenge is that; our organization in general has no infrastructure to avail it's operations and those we work to serve find it difficult to have access to the internet and misconceived investing in technology as a tool for development. Finally, we may need if possible: computers good working system for trainings, workshops,seminars to advance to this digital world.",
    "First ResponseID": 10400,
  },
  {
    Language: "English",
    "Country Code": "NG",
    "HQ Country": "Nigeria",
    Original:
      "Digital inequity is even more pronounced among persons with disabilities, especially the Blind and the Visually Impaired. Constant training would expose this category of people to the much needed digital skills required to decrease the digital divide, improve on their online presence and enable them to independently participate in online activities, like jobs. Persons with disabilities remain the most marginalized in the use of internet, and finding a lasting solution to end this digital inequity cannot be overemphasized. Worst still is their inability to buy the necessary tools, computers and mobile phones are not within their reach, not to talk of having to buy data on regular basis. Our community definitely would appreciate any form of support in this direction.",
    English:
      "Digital inequity is even more pronounced among persons with disabilities, especially the Blind and the Visually Impaired. Constant training would expose this category of people to the much needed digital skills required to decrease the digital divide, improve on their online presence and enable them to independently participate in online activities, like jobs. Persons with disabilities remain the most marginalized in the use of internet, and finding a lasting solution to end this digital inequity cannot be overemphasized. Worst still is their inability to buy the necessary tools, computers and mobile phones are not within their reach, not to talk of having to buy data on regular basis. Our community definitely would appreciate any form of support in this direction.",
    "First ResponseID": 8099,
  },
  {
    Language: "English",
    "Country Code": "NG",
    "HQ Country": "Nigeria",
    Original:
      "Having access to funding will ease some of the challenges. Inadequate funding is the major constraint and government of Nigeria is not interested in supporting what will ease the pain.",
    English:
      "Having access to funding will ease some of the challenges. Inadequate funding is the major constraint and government of Nigeria is not interested in supporting what will ease the pain.",
    "First ResponseID": 3310,
  },
  {
    Language: "English",
    "Country Code": "NG",
    "HQ Country": "Nigeria",
    Original:
      "High cost of Computers, high cost of genuine software, stable internet connection, high cost of mobile phones and tablets",
    English:
      "High cost of Computers, high cost of genuine software, stable internet connection, high cost of mobile phones and tablets",
    "First ResponseID": 8180,
  },
  {
    Language: "English",
    "Country Code": "NG",
    "HQ Country": "Nigeria",
    Original: "High cost of devices and internet access.",
    English: "High cost of devices and internet access.",
    "First ResponseID": 8092,
  },
  {
    Language: "English",
    "Country Code": "NG",
    "HQ Country": "Nigeria",
    Original:
      "Provision of digital and remote learning network that will make schools available to pupils and students in crisis environment where violence, riot, insurgency are the businesses of the day, so as to reduce cost",
    English:
      "Provision of digital and remote learning network that will make schools available to pupils and students in crisis environment where violence, riot, insurgency are the businesses of the day, so as to reduce cost",
    "First ResponseID": 8226,
  },
  {
    Language: "English",
    "Country Code": "NG",
    "HQ Country": "Nigeria",
    Original:
      "The following digital tools are needed for effective performance of duty especially in the communities where we work: 1) Laptop Computers 2) Customized Mini Tablets 3) Internet Devices (Routers, Mifi, WiFi, etc.) 4) Digital Skills Training 5) Storage Devices 6) Software (Anti-Virus, Firewall) 7) Internet Access (VPN preferrably). If we can be provided with a Virtual Private Network (VPN) this will greatly reduce the cost of purchase of internet data and services.",
    English:
      "The following digital tools are needed for effective performance of duty especially in the communities where we work: 1) Laptop Computers 2) Customized Mini Tablets 3) Internet Devices (Routers, Mifi, WiFi, etc.) 4) Digital Skills Training 5) Storage Devices 6) Software (Anti-Virus, Firewall) 7) Internet Access (VPN preferrably). If we can be provided with a Virtual Private Network (VPN) this will greatly reduce the cost of purchase of internet data and services.",
    "First ResponseID": 10702,
  },
  {
    Language: "English",
    "Country Code": "NG",
    "HQ Country": "Nigeria",
    Original:
      "The greatest challenge for us is the lack of official digital tools to support the amazing work we do in hard to reach communities. Staff have to depend on personal devices to implement their work and this poses a major challenge to the implementation of activities.",
    English:
      "The greatest challenge for us is the lack of official digital tools to support the amazing work we do in hard to reach communities. Staff have to depend on personal devices to implement their work and this poses a major challenge to the implementation of activities.",
    "First ResponseID": 3035,
  },
  {
    Language: "English",
    "Country Code": "NG",
    "HQ Country": "Nigeria",
    Original:
      "The greatest challenge we've faced lies in the areas of access to affordable and reliable internet as well as electricity.  These two are not exclusive and provide a major challenge for us in delivering our digital skills program to African youths virtually",
    English:
      "The greatest challenge we've faced lies in the areas of access to affordable and reliable internet as well as electricity.  These two are not exclusive and provide a major challenge for us in delivering our digital skills program to African youths virtually",
    "First ResponseID": 8094,
  },
  {
    Language: "English",
    "Country Code": "NG",
    "HQ Country": "Nigeria",
    Original:
      "The variables of conspiracy theory and disinformation needed to be interrogated.",
    English:
      "The variables of conspiracy theory and disinformation needed to be interrogated.",
    "First ResponseID": 11758,
  },
  {
    Language: "English",
    "Country Code": "NG",
    "HQ Country": "Nigeria",
    Original:
      "There is a need to make internet access available for Persons With Disabilities as many of them cannot afford assistive devices to ease their journey.",
    English:
      "There is a need to make internet access available for Persons With Disabilities as many of them cannot afford assistive devices to ease their journey.",
    "First ResponseID": 19611,
  },
  {
    Language: "English",
    "Country Code": "NG",
    "HQ Country": "Nigeria",
    Original:
      "We currently seeking support in providing user based Internet access for multiple locations to enable us give quality Internet connection.",
    English:
      "We currently seeking support in providing user based Internet access for multiple locations to enable us give quality Internet connection.",
    "First ResponseID": 7748,
  },
  {
    Language: "English",
    "Country Code": "NG",
    "HQ Country": "Nigeria",
    Original:
      "We need funding help to buy internet accessible device and appliances.",
    English:
      "We need funding help to buy internet accessible device and appliances.",
    "First ResponseID": 9189,
  },
  {
    Language: "English",
    "Country Code": "PK",
    "HQ Country": "Pakistan",
    Original:
      "I can not afford internet to connect with my community and the entire world to communicate.",
    English:
      "I can not afford internet to connect with my community and the entire world to communicate.",
    "First ResponseID": 8349,
  },
  {
    Language: "English",
    "Country Code": "PK",
    "HQ Country": "Pakistan",
    Original:
      "Internet access is difficult in the rural areas we work in. We are providing free diploma courses (6 months) for computer skills in the area. This is helping students get jobs as computer operators. If we have better internet access we can train them on social medial skill to access online freelance jobs.",
    English:
      "Internet access is difficult in the rural areas we work in. We are providing free diploma courses (6 months) for computer skills in the area. This is helping students get jobs as computer operators. If we have better internet access we can train them on social medial skill to access online freelance jobs.",
    "First ResponseID": 16596,
  },
  {
    Language: "English",
    "Country Code": "PK",
    "HQ Country": "Pakistan",
    Original: "There is Gender Disparity in digital equity",
    English: "There is Gender Disparity in digital equity",
    "First ResponseID": 9651,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "PE",
    "HQ Country": "Peru",
    Original:
      "Mayormente para las autoridades del estado no es relevante hechos que haya o no internet no significa votos y la empresa privada que invierte quiere su pronto reembolso, por lo que la para las comunidades pobres es casi imposible. Deberían mejorar las políticas",
    English:
      "Mostly for state authorities it is not a relevant fact that there is or not internet as it does not mean votes and the private companies investing want prompt reimbursement, so that for poor communities it is almost impossible. Policies should be improved.",
    "First ResponseID": 19022,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "PE",
    "HQ Country": "Peru",
    Original:
      "Lamentablemente en las zonas rurales y la misma ciudad en zona suburbana no se cuenta con servicios básicos (como agua y desagüe) menos internet para el gobierno en mi país son zonas olvidadas y estos servicios no significan votos. Debería establecerse como política pero crear infraestructura y dar acceso a internet para todos para el gobierno resulta muy caro y a la empresa privada no le interesa si no hay pronto reembolso de su inversión. En zona andina se puede ver mucha pobreza",
    English:
      "Unfortunately, in rural areas and also some city areas there are no basic services (such as water and sewage), even less internet. In my country the government has forgotten these services as they do not mean votes. It should be established as a policy, but creating infrastructure and providing internet access for all is very expensive for the government and private companies are not interested if there is no prompt investment return. In the Andean zone there is a lot of poverty.",
    "First ResponseID": 19020,
  },
  {
    Language: "English",
    "Country Code": "PH",
    "HQ Country": "Philippines",
    Original:
      "Free or more affordable video conferencing tool without additional payments for services connected to it (such as in-built apps, recording, etc.)",
    English:
      "Free or more affordable video conferencing tool without additional payments for services connected to it (such as in-built apps, recording, etc.)",
    "First ResponseID": 19594,
  },
  {
    Language: "English",
    "Country Code": "PH",
    "HQ Country": "Philippines",
    Original:
      "The assurance of peace, equity and understanding to every corner of the world should have access to free and reliable WIFI.",
    English:
      "The assurance of peace, equity and understanding to every corner of the world should have access to free and reliable WIFI.",
    "First ResponseID": 16628,
  },
  {
    Language: "English",
    "Country Code": "PH",
    "HQ Country": "Philippines",
    Original:
      "Currently, we only have three access points. I'm praying that our organization or perhaps some donors will be able to donate or add additional access points.",
    English:
      "Currently, we only have three access points. I'm praying that our organization or perhaps some donors will be able to donate or add additional access points.",
    "First ResponseID": 16270,
  },
  {
    Language: "English",
    "Country Code": "PH",
    "HQ Country": "Philippines",
    Original:
      "Hoping there are considerations for non profits to have access to digital tools.",
    English:
      "Hoping there are considerations for non profits to have access to digital tools.",
    "First ResponseID": 16885,
  },
  {
    Language: "English",
    "Country Code": "PH",
    "HQ Country": "Philippines",
    Original:
      "How I wish most everyone who get in touch with us are equipped with digital skills and have easier and affordable access to internet wherever they are in the Philippines.",
    English:
      "How I wish most everyone who get in touch with us are equipped with digital skills and have easier and affordable access to internet wherever they are in the Philippines.",
    "First ResponseID": 19206,
  },
  {
    Language: "English",
    "Country Code": "PH",
    "HQ Country": "Philippines",
    Original:
      "I would like to receive some tips on how to conceptualize a roadmap towards digital upskilling of the members of our organization as well as access to affordable software for non-profits.",
    English:
      "I would like to receive some tips on how to conceptualize a roadmap towards digital upskilling of the members of our organization as well as access to affordable software for non-profits.",
    "First ResponseID": 17305,
  },
  {
    Language: "English",
    "Country Code": "PH",
    "HQ Country": "Philippines",
    Original:
      "The digital journey in my community is getting much better compared decades ago. I expect this improvement will to continue in the near future.",
    English:
      "The digital journey in my community is getting much better compared decades ago. I expect this improvement will to continue in the near future.",
    "First ResponseID": 4615,
  },
  {
    Language: "English",
    "Country Code": "PH",
    "HQ Country": "Philippines",
    Original:
      "Things are happening too fast in the digital world even with digital access and it only magnifies the divide with the communities we serve. They will always be continually playing catch up.",
    English:
      "Things are happening too fast in the digital world even with digital access and it only magnifies the divide with the communities we serve. They will always be continually playing catch up.",
    "First ResponseID": 11702,
  },
  {
    Language: "English",
    "Country Code": "PH",
    "HQ Country": "Philippines",
    Original:
      "We are a homeowners association and collecting monthly dues from our homeowners is a very challenging job, resulting to lack of fund and avail basic services including internet  subscription, software and digital tools even computer machines in order to serve our community. Our fund is enough to sustain basic services such as streetlights and security guards that will protect our community/village. We will welcome and sincerely appreciate if there are donors that could grant us to get basic internet services and computers for our operation.",
    English:
      "We are a homeowners association and collecting monthly dues from our homeowners is a very challenging job, resulting to lack of fund and avail basic services including internet  subscription, software and digital tools even computer machines in order to serve our community. Our fund is enough to sustain basic services such as streetlights and security guards that will protect our community/village. We will welcome and sincerely appreciate if there are donors that could grant us to get basic internet services and computers for our operation.",
    "First ResponseID": 17288,
  },
  {
    Language: "English",
    "Country Code": "PH",
    "HQ Country": "Philippines",
    Original:
      "We want the youth and low income family of our community to gain access to internet in our common facility building. But, due to lack of funds, we cannot properly setup a safe internet browsing because we cannot afford the right network device to setup in our multi-purpose hall. Aside from that, our internet is very unstable due to low cost plans subscribed in our ISP. I hope we can get support from other organization to setup a network and share the safe computing internet service for our community.",
    English:
      "We want the youth and low income family of our community to gain access to internet in our common facility building. But, due to lack of funds, we cannot properly setup a safe internet browsing because we cannot afford the right network device to setup in our multi-purpose hall. Aside from that, our internet is very unstable due to low cost plans subscribed in our ISP. I hope we can get support from other organization to setup a network and share the safe computing internet service for our community.",
    "First ResponseID": 4868,
  },
  {
    Language: "Indonesian",
    "Country Code": "PL",
    "HQ Country": "Poland",
    Original:
      "Teknologi internet memang penting tapi jangan terlalu terpaku pada internet dan data data penting/ pribadi juga harus di lindungi pada era modern seperti ini karena data data penting atau pribadi juga bisa di bobol. Dan tantangan berat nya itu adalah persaingan",
    English:
      "Internet technology is indeed important, but don't get too hung up on the internet and the important/personal data have to be protected in this modern era because the important/personal data also can be compromised. And the tough challenge is competition",
    "First ResponseID": 11119,
  },
  {
    Language: "Polish",
    "Country Code": "PL",
    "HQ Country": "Poland",
    Original:
      "Obecnie największym wyzwaniem w świecie cyfrowym jest dezinformacja, fałszywe konta społecznościowe z Fake Newsami, oszustwa internetowe.",
    English:
      "Currently, the biggest challenge in the digital world is disinformation, fake social accounts with Fake News,\n Internet scams",
    "First ResponseID": 14894,
  },
  {
    Language: "Polish",
    "Country Code": "PL",
    "HQ Country": "Poland",
    Original:
      "Dostępność cyfrowa. Wiedza na temat przygotowania materiałów, treści dostępności cyfrowej.",
    English:
      "Digital availability. Knowledge of the preparation of materials, and digital accessibility content.",
    "First ResponseID": 13945,
  },
  {
    Language: "Polish",
    "Country Code": "PL",
    "HQ Country": "Poland",
    Original: "Dziękuję za zainteresowanie ię tym problemem społecznym.",
    English: "I appreciate your interest in this social problem.",
    "First ResponseID": 12578,
  },
  {
    Language: "Polish",
    "Country Code": "PL",
    "HQ Country": "Poland",
    Original:
      "Nierówność dostępu do mediów społecznościowych - by w nich zaistnieć i dotrzeć z informacją trzeba inwestować w reklamę.",
    English:
      "Inequal access to social media - in order to exist in them and reach out with information, you need to invest in advertising",
    "First ResponseID": 9845,
  },
  {
    Language: "Polish",
    "Country Code": "PL",
    "HQ Country": "Poland",
    Original:
      "Często nowo powstałe organizacje pozarządowe nie mają środków na cyfrowy rozwój i dostęp w tym kierunku. Mogą liczyć tylko na prywatne możliwości sprzętu i dostępu do internetu",
    English:
      "Often, newly established NGOs do not have the resources and access to digital development. \nThey can only count on private equipment and Internet access",
    "First ResponseID": 15340,
  },
  {
    Language: "Polish",
    "Country Code": "PL",
    "HQ Country": "Poland",
    Original:
      "W naszej organizacji brakuje umiejętności do korzystania z wielu programów. Na codzień korzystamy z Google Drive, Slacka, Miro oraz Google Calendar i Google Meets. Wiele osób nie wie jak do końca wykorzystywać te aplikacje i czują się zagubieni w swoich zadaniach. Uważam że brakuje łatwo dostępnych i darmowych szkoleń dla organizacji związanych z umiejętnością porządkowania pracy w programach to zarządzania projektami.",
    English:
      "Our organization lacks the skills to use many programs. We use Google Drive, Slack, Miro, Google Calendar \nand Google Meets on a daily basis. Many people do not know how to use these applications and feel lost in their tasks. \nI believe that there is a lack of easily available and free training for organizations related to the ability to organize \nwork in project management programs.",
    "First ResponseID": 10499,
  },
  {
    Language: "Polish",
    "Country Code": "PL",
    "HQ Country": "Poland",
    Original:
      "Dla naszej organizacji największym problemem w dostępie do internetu jest cena urządzeń takich jak smartfony i laptopy. Pracujemy z seniorami, którzy często nie mogą sobie pozwolić na taki sprzęt.",
    English:
      "Our organization’s biggest problem with Internet access is the price of devices such as smartphones and laptops. We work with seniors who often cannot afford such equipment.",
    "First ResponseID": 10166,
  },
  {
    Language: "Polish",
    "Country Code": "PL",
    "HQ Country": "Poland",
    Original:
      "Największym problemem mojej organizacji jest brak komputera oraz środków na zakup usług internetowych",
    English:
      "The biggest problem of my CSO is a lack of computer and resources to buy internet services",
    "First ResponseID": 2890,
  },
  {
    Language: "Polish",
    "Country Code": "PL",
    "HQ Country": "Poland",
    Original:
      "Jak największa szybkość internetu powinna być standardem dla wszystkich. Znaczna obniżka cen za dostęp lub za darmo.",
    English:
      "The fastest possible internet speed should be the standard for everyone. \nSignificant price reduction for access or for free.",
    "First ResponseID": 9636,
  },
  {
    Language: "Polish",
    "Country Code": "PL",
    "HQ Country": "Poland",
    Original:
      "We wsi istnieje świetlica wiejska bez dostępu do internetu. W projekcie jest jej rozbudowa. mamy nadzieję, że modernizacja zapewni dostęp do internetu.",
    English:
      "There is a village club in the village without internet access. The project includes its expansion. We hope that the modernization will provide access to the internet",
    "First ResponseID": 12670,
  },
  {
    Language: "Polish",
    "Country Code": "PL",
    "HQ Country": "Poland",
    Original:
      "Bardzo niskie kompetencje osób starszych w korzystaniu z internetu. Brak szkoleń, sprzętu.",
    English:
      "Very low competencies of older people in using the Internet. No training, no equipment.",
    "First ResponseID": 15088,
  },
  {
    Language: "Polish",
    "Country Code": "PL",
    "HQ Country": "Poland",
    Original: "brak nam wsparcia technicznego",
    English: "we do not have a technical support",
    "First ResponseID": 10757,
  },
  {
    Language: "Polish",
    "Country Code": "PL",
    "HQ Country": "Poland",
    Original:
      "Potrzeba sieci światłowodowych na obszarach wiejskich aby zmniejszyć wykluczenie cyfrowe",
    English:
      "We need fiber internet network on rural areas to lessen social exclusion",
    "First ResponseID": 8021,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "O maior desafio para a [organização] é conseguir serviços de internet grátis financiados pelo Governo, para todas as pessoas que apoiamos, bem como o acesso a equipamentos para a utilização da internet.",
    English:
      "The biggest challenge for [organization redacted] is getting free government-funded internet services for all the people we support, as well as access to equipment for using the internet.",
    "First ResponseID": 2243,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "Preocupação com a segurança digital dos dados, por não podermos suportar os custos de bons programas de proteção. Custos muito elevados nos programas digitais de gestão (crm sócios, utentes,...)",
    English:
      "Concern about the digital data security, because we cannot afford the costs of good protection programs. Very high costs in digital management programs (crm partners, users,...)",
    "First ResponseID": 1877,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "A equidade digital e as ferramentas a utilizar nas populações mais velhas ainda está longe de ser uma realidade.",
    English:
      "Digital equity and the tools to be used in older populations is still far from a reality.",
    "First ResponseID": 2092,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "Na instituição onde trabalho, o facto de estar descentralizada, origina elevados custos para que todos os colegas tenham condições de trabalho idênticas. As políticas de apoio a ONGs deveriam ser melhoradas, no que se refere à aquisição de equipamentos e infrastruturas de comunicação (ou mesmo a melhoria das mesmas - pois a tecnologia regista uma constante evolução e a rapidez de acesso faz diferença quando é fluida ou não, seja por causa da máquina ou da infrastructura.",
    English:
      "In the Organization where I work, because it is decentralized, it requires high costs so that all colleagues have the same working conditions. Policies to support NGOs should be improved, with regard to the acquisition of equipment and communication infrastructures (or even their improvement - because we are in a time in constant technological evolution and the speed of access makes the difference, if it is fluid or not, either because of the machine or the infrastructure.",
    "First ResponseID": 12346,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "A luta pela equidade e informação e formação do mundo tecnologica, a nossa instituição vem lutando na criação de projecto de literacia digital na comunidade desfavorida onde se insere.",
    English:
      "In the struggle for equity and information and training in the technological world, our Organization has been struggling to create a digital literacy project in the disadvantaged community where it operates.",
    "First ResponseID": 15407,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "Conteúdos mais acessíveis para a população com deficiência intelectual. Preços mais acessíveis para as IPSS´S para aquisição de hardware e software.",
    English:
      "More accessible content for the population with intellectual disabilities. More affordable prices for nonprofit Organizations to get hardware and software.",
    "First ResponseID": 16684,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "A nossa instituição é composta por pessoas de idade avançada, por isso é difícil a aquisição de conhecimentos digitas",
    English:
      "Our Organization hasmainly senior people, so it is difficult to achieve digital knowledge",
    "First ResponseID": 15472,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "O publico alvo no nosso contexto de trabalho são pessoas portadores de deficiência, pelo que muitas das questões não se aplicam ou não existem no questionário opções ajustadas para resposta mais direcionada.",
    English:
      "Our target audience are people with disabilities, so many of the questions do not apply or there are no adjusted options in the questionnaire for a more targeted response.",
    "First ResponseID": 15308,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "Proteção dos mais frágeis - acesso e  riscos; proteção das Organizações - riscos e formação em competências digitais",
    English:
      "Protection of the most fragile people - access and risks; protection of Organizations - risks and training in digital skills",
    "First ResponseID": 12939,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "A capacidade de aquisição e o acesso à formação digital acessível a pessoas de baixos rendimentos (e/ou em situação de pobreza) e baixos níveis de formação é fundamental. A grande dificuldade em Portugal é não haver quem apoie estas pessoas no acesso a serviços básicos, como serviços da segurança social e prestadores de outros serviços como água e electricidade. Obrigado pelo vosso estudo, que consigam diminuir com ele a desigualdade no acesso à internet e às ferramentas digitais!",
    English:
      "The ability to obtain and access digital training accessible to people with low incomes (and/or in situations of poverty) and low levels of training is fundamental. The great difficulty in Portugal is that there is no one to support these persons in accessing basic services, such as social security services and providers of other services such as water and electricity. Thank you for your study, may you manage to reduce inequality in access to the internet and digital tools!",
    "First ResponseID": 16877,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "O maior desafio é que os dispositivos se tornam obsoletos rapidamente e não há capacidade de nos mantermos sempre atualizados.",
    English:
      "The biggest challenge is that devices become obsolete very quickly and there is no ability to always keep up to date.",
    "First ResponseID": 14164,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      'O maior desafio é conseguir combater a "infoexclusão da minha comunidade", ou seja, conseguir a equidade digital efetiva',
    English:
      'The biggest challenge is to fight the "infoexclusion of my community", or in other words, to achieve effective digital equity',
    "First ResponseID": 12569,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "O maior desafio é encontrar ferramentas que permitam a utilização por pessoas com deficiência mental",
    English:
      "The biggest challenge is to find tools that allow people with intellectual disabilities to use",
    "First ResponseID": 12586,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "O maior desafio consiste em tornar o acesso digital acessível a todos. Promover a informação e a comunicação de forma igualitária, independentemente das limitações individuais. E ainda, mas não menos importante, promover o hábito e a visão do digital como ferramenta facilitadora.",
    English:
      "The biggest challenge is to make digital access accessible to everyone. Promoting information and communication in an equitable way regardless of individual limitations. And also, but not less important, to promote the habit and vision of digital as a facilitating tool.",
    "First ResponseID": 14987,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "O maior desafio que temos no próximo ano é a disponibilização de recursos (tablets) com acesso à internet, nos serviços ao domicílio e que permitam o registo online dos serviços e apoio aos utentes",
    English:
      "The biggest challenge that we have in the coming year is the availability of resources (tablets) with internet access, in the home services and that allow the online registration of services and support to users.",
    "First ResponseID": 2116,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "O maior desafio são os dispositivos, os software e a iliteracia digital por parte dos colaboradores.",
    English:
      "The biggest challenge we face is devices, software and digital illiteracy of employees.",
    "First ResponseID": 7165,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "o maior desafio que enfrentamos são as redes sociais. nomeadamente o site da nossa Organização",
    English:
      "the biggest challenge we face is social media, namely the website of our Organization",
    "First ResponseID": 6988,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "As comunidades que apoiamos em África são rurais e com recursos extremamente limitados, pelo que todo o equipamento e planos de comunicações têm de ser fornecidos pela nossa organização e são fundamentais para o funcionamento da nossa atividade, pois sem eles não teríamos forma de comunicar com essas comunidades e não poderíamos desenvolver o nosso trabalho.",
    English:
      "The communities we support in Africa are rural and with extremely limited resources, so all equipment and communications plans have to be provided by our Organization and are fundamental to the functioning of our activity, as without them we would have no way of communicating with these communities. communities and we could not develop our work.",
    "First ResponseID": 2086,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "Verificamos graves entraves no acesso e usufruto dos equipamentos e serviços digitais pro parte dos colaboradores e principalmente de muitos dos clientes (pessoas com deficiência intelectual)",
    English:
      "We have seen serious obstacles in the access and use of digital equipment and services for employees and especially for many customers (people with intellectual disabilities)",
    "First ResponseID": 2260,
  },
  {
    Language: "Portuguese_P",
    "Country Code": "PT",
    "HQ Country": "Portugal",
    Original:
      "Ainda dispomos de um mercado muito competitivo e dispendioso para grande parte da população idosa com o qual trabalhamos. Mesmo para as IPSS's, os valores praticados são comummente elevados, não conferindo prioridade na inovação e praticidade das tarefas diárias dos profissionais, seja na área da formação, seja na manutenção de estruturas físicas digitais, nomeadamente servidores, software, hardware facilmente obsoleto e regimes de proteção de dados com elevado grau de exigência de recursos humanos. Tudo tem sido exigido, sem qualquer planeamento de  recursos humanos e financeiros de empresas e instituições.",
    English:
      "We still have a very competitive and expensive market for a large part of the elderly population we work with. Even for nonprofit Organizations, the costs are commonly high, not giving priority to the innovation and practicality of the daily tasks of professionals, whether in the area of training, or in the maintenance of physical digital structures, namely servers, software, easily obsolete hardware and data protection with a high degree of demand on human resources. Everything has been demanded, without any planning of human and financial resources of companies and Organizations.",
    "First ResponseID": 3620,
  },
  {
    Language: "English",
    "Country Code": "RO",
    "HQ Country": "Romania",
    Original:
      'Working with visually impaired people, the most glaring and easily fixable issue is that many websites lack accessibility not because of some impossible requirements, but because most so-called "web designers" or "web developers" can\'t be bothered to adhere to some basic common sense design principles. The outcome is often impossible to detect for an average consumer, but it produces devastating cascading effects for people relying on screen readers.',
    English:
      'Working with visually impaired people, the most glaring and easily fixable issue is that many websites lack accessibility not because of some impossible requirements, but because most so-called "web designers" or "web developers" can\'t be bothered to adhere to some basic common sense design principles. The outcome is often impossible to detect for an average consumer, but it produces devastating cascading effects for people relying on screen readers.',
    "First ResponseID": 1471,
  },
  {
    Language: "English",
    "Country Code": "RO",
    "HQ Country": "Romania",
    Original:
      "We work on increasing transparency in the nonprofit sector in Romania. Our biggest problems is public data availability. We don't know who is behind a nonprofit, what the salaries are, the databases to not have similar ids so we can automatically link information from different sources, no activity detail reporting. The annual balance sheet that is public is a bad joke in providing any relevant data about an organizations activity. No-one knows how many nonprofits we have, what they do, how much money is in the sector and who is behind them.",
    English:
      "We work on increasing transparency in the nonprofit sector in Romania. Our biggest problems is public data availability. We don't know who is behind a nonprofit, what the salaries are, the databases to not have similar ids so we can automatically link information from different sources, no activity detail reporting. The annual balance sheet that is public is a bad joke in providing any relevant data about an organizations activity. No-one knows how many nonprofits we have, what they do, how much money is in the sector and who is behind them.",
    "First ResponseID": 2312,
  },
  {
    Language: "Romainian",
    "Country Code": "RO",
    "HQ Country": "Romania",
    Original:
      "Educatia si informarea sunt drepturi fundamentale care trebuie respectate, cu ajutorul internetului, mai ales pentru copii din mediile defavorizate, ne asiguram ca au acces la ele chiar in conditii de pandemie.",
    English:
      "Education and access to information are fundamental rights which should be respected. With the help of the Internet, we make sure that even children in disadvantaged environment enjoy these rights, even under pandemic conditions",
    "First ResponseID": 14963,
  },
  {
    Language: "Romainian",
    "Country Code": "RO",
    "HQ Country": "Romania",
    Original:
      "Cea mai mare provocare este subutilizarea internetului de catre beneficiari.",
    English:
      "The biggest challenge comes from the fact that the people we serve underuse the Internet",
    "First ResponseID": 12904,
  },
  {
    Language: "Romainian",
    "Country Code": "RO",
    "HQ Country": "Romania",
    Original:
      "Cea mai mare provocare este accesul la un software de ultimă generație",
    English: "The biggest challenge is accessing latest-generation software.",
    "First ResponseID": 15792,
  },
  {
    Language: "French",
    "Country Code": "RW",
    "HQ Country": "Rwanda",
    Original:
      "Notre organisation travaille dans des conditions très difficiles  pour plusieurs raisons :  Nous n'avons pas de machines ordinateurs suffisantes pour notre personnel et nos bénéficiaires. Nous avons 2 machines portables alors que au minimum nous devrions avoir 32 machines ordinateurs. 7  Machines de bureau et 25 machines ( desktops) pour nos bénéficiaires. Nous n'avons pas accès à un réseau Internet à haut débit puis que nos infrastructures sont très veilles. Si on avait de moyens financiers suffisant, on devrait chercher un bureau près d'un endroit où l'accessibilité à internet à fibre optique est facile. Il y a aussi en terme de gouvernance internet un problème lié au strict contrôle ou restrictions du pouvoir public. Ex: interdiction d'utilisation du VPN",
    English:
      "Our organization works in very difficult conditions for several reasons: We do not have enough computer machines for our staff and beneficiaries. We have 2 laptops when at least we should have 32 computers. 7 office machines and 25 machines (desktops) for our beneficiaries. We do not have access to a high speed internet network since our infrastructure is very old. If we had sufficient financial means, we would have to look for an office near a place where the accessibility to fiber optic internet is easy. There is also a problem in terms of Internet governance related to the strict control or restrictions of public power. E.g.: banning the use of VPN",
    "First ResponseID": 6842,
  },
  {
    Language: "English",
    "Country Code": "SL",
    "HQ Country": "Sierra Leone",
    Original:
      "As an organization we're struggling to provide adequate services due to lack of these resources such as technological appliances (computers) and sustainable internet. We're hoping that should the opportunity arise, we will be considered for support. Thank you",
    English:
      "As an organization we're struggling to provide adequate services due to lack of these resources such as technological appliances (computers) and sustainable internet. We're hoping that should the opportunity arise, we will be considered for support. Thank you",
    "First ResponseID": 4143,
  },
  {
    Language: "English",
    "Country Code": "SL",
    "HQ Country": "Sierra Leone",
    Original:
      "Face affordability and reliability as our greatest challenge to accomplished estate goal to meet the mission and vision of the organisation.",
    English:
      "Face affordability and reliability as our greatest challenge to accomplished estate goal to meet the mission and vision of the organisation.",
    "First ResponseID": 11084,
  },
  {
    Language: "English",
    "Country Code": "SL",
    "HQ Country": "Sierra Leone",
    Original:
      "Internet is very key to administering our questionnaires, conducting online research and civic education, engaging in advocacies, and communicating with partners and potential donors. Having the right devices will aid our use of the internet infrastructure. We will continue to engage the people we serve and the government to improve the internet infrastructure as it is key for economic development.",
    English:
      "Internet is very key to administering our questionnaires, conducting online research and civic education, engaging in advocacies, and communicating with partners and potential donors. Having the right devices will aid our use of the internet infrastructure. We will continue to engage the people we serve and the government to improve the internet infrastructure as it is key for economic development.",
    "First ResponseID": 8111,
  },
  {
    Language: "English",
    "Country Code": "SL",
    "HQ Country": "Sierra Leone",
    Original:
      "Screen reading software to help visually impaired  persons independently use digital devices such as computers.",
    English:
      "Screen reading software to help visually impaired  persons independently use digital devices such as computers.",
    "First ResponseID": 8440,
  },
  {
    Language: "English",
    "Country Code": "SL",
    "HQ Country": "Sierra Leone",
    Original:
      "Software and digital equipment like computers, printers and a license anti virus",
    English:
      "Software and digital equipment like computers, printers and a license anti virus",
    "First ResponseID": 8402,
  },
  {
    Language: "English",
    "Country Code": "SI",
    "HQ Country": "Slovenia",
    Original:
      "The greatest problems in developed countries arise from accessibility (WACAG) as most sites are not compliant.  For people with access and with disabilites, internet is still mostly inaccessible.",
    English:
      "The greatest problems in developed countries arise from accessibility (WACAG) as most sites are not compliant.  For people with access and with disabilites, internet is still mostly inaccessible.",
    "First ResponseID": 2611,
  },
  {
    Language: "English",
    "Country Code": "SO",
    "HQ Country": "Somalia",
    Original: "Digital Awareness Raising through social media.",
    English: "Digital Awareness Raising through social media.",
    "First ResponseID": 9069,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "All the answers provided through your questions are our greatest challenges in digital sector in our operation as Not for  Profit, Public Benefit Organization.",
    English:
      "All the answers provided through your questions are our greatest challenges in digital sector in our operation as Not for  Profit, Public Benefit Organization.",
    "First ResponseID": 18436,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "We teach coding and graphic design to rural and township youths. We are way short of computers, amongst other challenges.  Over 75% of the people in our focus areas have no access to computers, the internet etc.",
    English:
      "We teach coding and graphic design to rural and township youths. We are way short of computers, amongst other challenges.  Over 75% of the people in our focus areas have no access to computers, the internet etc.",
    "First ResponseID": 4361,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "Access to fiber internet to be able to have access to online training and zoom meetings, e.g..",
    English:
      "Access to fiber internet to be able to have access to online training and zoom meetings, e.g..",
    "First ResponseID": 12953,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "Biggest challenge working with rural communities is access to telecommunications and data - beneficiaries (communities we serve) lack access to the internet, mobile/computer devices (either because of affordability or availability of internet services in their communities)",
    English:
      "Biggest challenge working with rural communities is access to telecommunications and data - beneficiaries (communities we serve) lack access to the internet, mobile/computer devices (either because of affordability or availability of internet services in their communities)",
    "First ResponseID": 2886,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "Communities on the outskirts of central town are too disadvantaged by lack of or availability of knowledge or resources and few digital training opportunities...local workshops are exclusive and far from the people that don't have transport to and from venues or places of learning .centres gets locked up at 4 and working people can not attend classes at night after work ...our people have challenges in understanding the digital field and more frequent training sessions should be given from a young age starting in schools on a equal level....",
    English:
      "Communities on the outskirts of central town are too disadvantaged by lack of or availability of knowledge or resources and few digital training opportunities...local workshops are exclusive and far from the people that don't have transport to and from venues or places of learning .centres gets locked up at 4 and working people can not attend classes at night after work ...our people have challenges in understanding the digital field and more frequent training sessions should be given from a young age starting in schools on a equal level....",
    "First ResponseID": 2382,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "I assist a retirement village with IT projects. 25 of the 130 members of staff have access to the Internet - the others do maintenance, caring for aged and infirm. We support around 400 residents, most of whom cannot afford Internet. We are working to provide free Internet for them, but are short of funds.",
    English:
      "I assist a retirement village with IT projects. 25 of the 130 members of staff have access to the Internet - the others do maintenance, caring for aged and infirm. We support around 400 residents, most of whom cannot afford Internet. We are working to provide free Internet for them, but are short of funds.",
    "First ResponseID": 2404,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "I believe that the internet is supposed to be a basic right like water. We live in a digital world and need information to make sure we live a well balanced life.",
    English:
      "I believe that the internet is supposed to be a basic right like water. We live in a digital world and need information to make sure we live a well balanced life.",
    "First ResponseID": 2701,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "I think my organisation and the community we serve believe in digital journey. It will be our pleasure to work with your company. It will add value and increase our change for funding and extend our servicearound the province s",
    English:
      "I think my organisation and the community we serve believe in digital journey. It will be our pleasure to work with your company. It will add value and increase our change for funding and extend our servicearound the province s",
    "First ResponseID": 9080,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "More competition in the area of global satellite services such as Elon Musk's Starlink services could tremendously accelerate matters of digital equity for the rural people of South Africa, and the continent as a whole.",
    English:
      "More competition in the area of global satellite services such as Elon Musk's Starlink services could tremendously accelerate matters of digital equity for the rural people of South Africa, and the continent as a whole.",
    "First ResponseID": 10164,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "My wish is for the disadvantaged communities that my organization serves to have access to the internet.  The beneficiaries in our organization need digital skills to help them get jobs or even work for themselves and generally just have access to the internet.  Their children need computers to help with homework. We need funding for computers, mentors and secure space for our projects",
    English:
      "My wish is for the disadvantaged communities that my organization serves to have access to the internet.  The beneficiaries in our organization need digital skills to help them get jobs or even work for themselves and generally just have access to the internet.  Their children need computers to help with homework. We need funding for computers, mentors and secure space for our projects",
    "First ResponseID": 18302,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "Our great challenge is access to devices for both organisation and the clients we serve and lack of internet access to deliver digital skills to our communities",
    English:
      "Our great challenge is access to devices for both organisation and the clients we serve and lack of internet access to deliver digital skills to our communities",
    "First ResponseID": 1736,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "Our primary issues are: 1) Affordability of Internet service 2) Skills in using available services (staff and beneficiaries) 3) Availability of suitable IT development services and Microsoft (and others) deployment and configuration services.  We have funding to reduce cost of Internet service and are doing that.",
    English:
      "Our primary issues are: 1) Affordability of Internet service 2) Skills in using available services (staff and beneficiaries) 3) Availability of suitable IT development services and Microsoft (and others) deployment and configuration services.  We have funding to reduce cost of Internet service and are doing that.",
    "First ResponseID": 15107,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "We are a non-profit providing palliative care to terminal patients; internet connectivity, devices and tech support is very costly and sometimes has to take a backseat to service provision.",
    English:
      "We are a non-profit providing palliative care to terminal patients; internet connectivity, devices and tech support is very costly and sometimes has to take a backseat to service provision.",
    "First ResponseID": 10085,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original: "We need funding for digital infrastructure and resources",
    English: "We need funding for digital infrastructure and resources",
    "First ResponseID": 18273,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "We serve the community on various aspects, parenting, marriage preparation and enrichment as well as victims.  The public we serve do not have the ways and means to afford laptops and internet.  We therefor need to supply the printed material and guidance.  It makes the costs for the organization to sky rocket, and we are not even sure if the public looks at the material thereafter.",
    English:
      "We serve the community on various aspects, parenting, marriage preparation and enrichment as well as victims.  The public we serve do not have the ways and means to afford laptops and internet.  We therefor need to supply the printed material and guidance.  It makes the costs for the organization to sky rocket, and we are not even sure if the public looks at the material thereafter.",
    "First ResponseID": 1712,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "We wish we had computers to provide our people access to the internet but there are so many gangsters and drug addicts that destroy any spaces and thieves that steal resources. Our neighborhood is not safe. Poverty makes people desperate that they will steal the very resources we accumulate to provide them access to the internet.",
    English:
      "We wish we had computers to provide our people access to the internet but there are so many gangsters and drug addicts that destroy any spaces and thieves that steal resources. Our neighborhood is not safe. Poverty makes people desperate that they will steal the very resources we accumulate to provide them access to the internet.",
    "First ResponseID": 19333,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "While taking this survey and being part of this project is exciting, unless we urgently get access to decent internet speeds at affordable costs, the children and the state of education in this country will continue to decline.",
    English:
      "While taking this survey and being part of this project is exciting, unless we urgently get access to decent internet speeds at affordable costs, the children and the state of education in this country will continue to decline.",
    "First ResponseID": 1689,
  },
  {
    Language: "English",
    "Country Code": "ZA",
    "HQ Country": "South Africa",
    Original:
      "Working with community where most of the youth lacks even basic computer skills, hurts my Organization which aimed on helping with the youth and high school students with these skills.  We'll pursue all possible ways to link them to reliable sources for them to gain access to those skills.",
    English:
      "Working with community where most of the youth lacks even basic computer skills, hurts my Organization which aimed on helping with the youth and high school students with these skills.  We'll pursue all possible ways to link them to reliable sources for them to gain access to those skills.",
    "First ResponseID": 18598,
  },
  {
    Language: "Korean",
    "Country Code": "KR",
    "HQ Country": "South Korea",
    Original:
      "저희 단체의 경우 디지털 형평성에 대해서는 인지하고 있으나, 비영리단체의 특성상 디지털 장비 및 인프라에 투자하기 어려워 단체의 구성원들이 테크.",
    English:
      "Our organization is aware of the issue of digital equity. But due to the nature of NPOs, it is difficult to invest in digital equipment and infrastructure.",
    "First ResponseID": 12633,
  },
  {
    Language: "Korean",
    "Country Code": "KR",
    "HQ Country": "South Korea",
    Original:
      "중증장애인거주시설 이용 장애인의 디지털 접근성 확대를 위해 기기 보급, 교육 등이 필요합니다.",
    English:
      "To expand digital accessibility for residents of 'residential facilities for the severely disabled', it is necessary to supply devices and education.",
    "First ResponseID": 12452,
  },
  {
    Language: "Korean",
    "Country Code": "KR",
    "HQ Country": "South Korea",
    Original:
      "1. Giving low-income youths a chance to use educational software 2. Providing career-interrupted women with opportunities to participate in SW education to provide employment opportunities",
    English:
      "1. Giving low-income youths a chance to use educational software 2. Providing career-interrupted women with opportunities to participate in SW education to provide employment opportunities",
    "First ResponseID": 18750,
  },
  {
    Language: "Korean",
    "Country Code": "KR",
    "HQ Country": "South Korea",
    Original:
      "코로나 이후 지역사회의 디지털 리터러시 역량강화를 위해 3가지 섹터로 노력중입니다. 1. 인프라 구축 (공공Wifi 확대 등) 2. 디지털 역량강화 교육 3. 디지털 문화 이용을 위한 컨텐츠 확보",
    English:
      "After COVID-19, we are working on three sectors to strengthen the digital literacy capabilities of the community. 1. Infrastructure construction (expansion of public Wi-Fi, etc.) 2. Digital capacity building education 3. Securing contents for digital culture use",
    "First ResponseID": 13814,
  },
  {
    Language: "Spanish_S",
    "Country Code": "ES",
    "HQ Country": "Spain",
    Original:
      "Creo que es muy importante incidir en la necesidad de que todo lo que rodea a Internet sea accesible (dispositivos, harware, software, plataformas digitales, etc). También creo que hay que hablar de alfabetización digital, especialmente para grupos sociales en riesgo de exclusión, y de ciberseguridad.",
    English:
      "I think it is very important to emphasize the need for everything that is related to the Internet to be accessible (devices, hardware, software, digital platforms, etc). I also think that we must talk more about digital literacy, especially for social groups at risk of exclusion, and cybersecurity.",
    "First ResponseID": 1131,
  },
  {
    Language: "Spanish_S",
    "Country Code": "ES",
    "HQ Country": "Spain",
    Original:
      "Información más clara para gente no experta en informática. Posibilidad de cursos dirigidos a población inmigrante con bajos contenidos digitales para evitar la brecha digital",
    English:
      "Clearer information for non-computer experts. Possibility of courses aimed at immigrant population with low digital content to avoid the digital divide",
    "First ResponseID": 1360,
  },
  {
    Language: "Spanish_S",
    "Country Code": "ES",
    "HQ Country": "Spain",
    Original:
      "Considero que hay aspectos normativos que nos afectan (utilización de herramientas digitales como utilización de whatsapp , messenger con asociados o ciudadanos a nivel general que contactan con una asociación)  y que no somos conocedores, sobre todo las entidades sin animo de lucro más pequeñas, y quizás sería de interés que se nos formara en políticas de internet , cumplimiento protección de datos , utilización de herramientas digitales para un mayor aprovechamiento.",
    English:
      "I consider that there are regulatory aspects that affect us (using digital tools such as the use of whatsapp or messenger with constituents or citizens at a general level who contact our association) and that we are not aware of, especially the smaller non-profit entities, and perhaps it would be of interest for us to be trained on internet policies, data protection compliance, or the use of digital tools for greater use.",
    "First ResponseID": 11052,
  },
  {
    Language: "Spanish_S",
    "Country Code": "ES",
    "HQ Country": "Spain",
    Original:
      "Es muy difícil intentar pensar en que personas que no tienen para comer y en niños que no tienen un lápiz para ir a la escuela, lleguemos a ponerles un ordenador con conexión. Veo importante dar un paso hacia atrás, y conseguir primero una base educativa, un acceso a la educación básica, colegios y profesores para todos los niños. Cuando esto esté conseguido, pasar a preocuparnos por que tengan wiffi.",
    English:
      "It is very difficult to try to think about people who do not have enough to eat and children who do not have a pencil to go to school, we get to put a computer with a connection. I think it is important to take a step back, and first achieve an educational base, access to basic education, schools and teachers for all children. When this is achieved, we move on to worrying about them having wiffi.",
    "First ResponseID": 4781,
  },
  {
    Language: "Spanish_S",
    "Country Code": "ES",
    "HQ Country": "Spain",
    Original:
      "Sería útil que se articulase una forma para que las organizaciones como la nuestra, que trabaja precisamente en ayudar a las personas mayores a superar los problemas que les supone la brecha digital, pudiésemos intercambiar métodos de trabajo y experiencias.",
    English:
      "It would be useful to articulate a way so that organizations like ours, which works precisely to help older people overcome the problems posed by the digital divide, could exchange working methods and experiences.",
    "First ResponseID": 1531,
  },
  {
    Language: "Spanish_S",
    "Country Code": "ES",
    "HQ Country": "Spain",
    Original:
      "Hacer cursos accesibles  tanto a nivel de precio cómo de facilidad de adherirse para mejorar las capacidades digitales",
    English:
      "Make courses accessible both at the level of price and ease of adhering to improve digital skills.",
    "First ResponseID": 927,
  },
  {
    Language: "Spanish_S",
    "Country Code": "ES",
    "HQ Country": "Spain",
    Original:
      "El mayor desafío que encuentra mi organización es la resistencia al cambio tanto por parte de las instituciones locales: Ayuntamiento, miembros del gobierno municipal, biblioteca municipal, incluso los dos colegios públicos, como por parte de las personas adultas: se conforman con lo poco que ya saben y ignoran totalmente el significado de tener competencias digitales",
    English:
      "The biggest challenge that my organization encounters is the resistance to change both on the part of local institutions: City Council, members of the municipal government, municipal library, even the two public schools, and on the part of adults: they settle for the little they already know and totally ignore the meaning of having digital skills.",
    "First ResponseID": 2419,
  },
  {
    Language: "Spanish_S",
    "Country Code": "ES",
    "HQ Country": "Spain",
    Original:
      "La mayor preocupación en nuestra organización es la brecha digital existente en la familias vulnerables y la falta de competencias digitales en las personas mayores",
    English:
      "The biggest concern in our organization is the digital divide in vulnerable families and the lack of digital skills in the elderly.",
    "First ResponseID": 18343,
  },
  {
    Language: "Spanish_S",
    "Country Code": "ES",
    "HQ Country": "Spain",
    Original:
      "Hay preguntas en las que hay que dar tres respuestas, pero únicamente tenía dos que pudieran corresponder con nuestro caso. Trabajamos en bastantes lugares donde no hay electricidad, por lo que llegar a internet... es un sueño!",
    English:
      "There are questions in which three answers must be provided, but I only had two that could correspond to our case. We work in quite a few places where there is no electricity, so getting to the internet... It's a dream",
    "First ResponseID": 18300,
  },
  {
    Language: "Spanish_S",
    "Country Code": "ES",
    "HQ Country": "Spain",
    Original:
      'Se habla mucho de "usabilidad universal" y "accesibilidad" pero a la hora de la verdad, cuando tienes una divergencia funcional "no estandar", estas completamente abobandonada.',
    English:
      'There is a lot of discussion about the advancement of universal usability" and "accessibility" but in reality',
    "First ResponseID": 17324,
  },
  {
    Language: "Spanish_S",
    "Country Code": "ES",
    "HQ Country": "Spain",
    Original:
      "Sí. A día de hoy somos la única organización sin ánimo de lucro en la región donde trabajamos que está preocupada en solventar los graves problemas de acceso a Internet en zonas rurales y en familia con recursos limitados, así que agradecemos toda la ayuda y apoyo que nos puedan brindar. Gracias",
    English:
      "Today we are the only non-profit organization in the region where we work that is concerned about solving the serious problems of Internet access in rural areas and in families with limited resources, so we appreciate all the help and support you can give us. Thank you.",
    "First ResponseID": 3317,
  },
  {
    Language: "Spanish_S",
    "Country Code": "ES",
    "HQ Country": "Spain",
    Original: "NECESITAMOS ORDENADORES Y SOFTWARES ASEQUIBLES",
    English: "We need affordable computers and software",
    "First ResponseID": 17692,
  },
  {
    Language: "Spanish_S",
    "Country Code": "ES",
    "HQ Country": "Spain",
    Original:
      'Trabajamos con personas con discapacidad, nos encontramos diariamente que la accesibilidad de los portales, app, etc es nula o escasa. También el equipamiento informático es caro, y eventualmente personas que necesitarían equipos adaptados por su discapacidad no pueden tenerlo por su precio. En zonas rurales estos problemas se ven incrementados con la falta de conectividad. Las competencias digitales en todos los ámbitos son limitadas. Los usuarios para buscar trabajo, ayudas, etc por Internet, necesitan ayuda porque existen limitaciones que creemos que se pueden solventar por las Administraciones Públicas, especialmente en la utilización de certificados digitales, fácil lectura, etc. Para los técnicos hace falta formación a nivel de seguridad. La seguridad es el vagón de cola ya que se priorizan otros temas en la entidad. Las ayudas públicas para temas de seguridad "Kit Digital" se han focalizado y no se han tomado como algo global.',
    English:
      'We work with people with disabilities, we find daily that the accessibility of websites, apps, etc. is non-existing or scarce. Computer equipment is also expensive, and eventually people who would need adapted equipment because of their disability cannot have it because of its price. In rural areas these problems are increased by the lack of connectivity. Digital skills across the globe are limited. Users that look for work, aid, etc. on the Internet, need help because there are limitations that we believe can be solved by Public Administrations, especially in the use of digital certificates, easy reading, etc. For technicians, cibersecurity training is needed. Safety (cybersecurity) is the tail wagon since other issues are prioritized in the organization. Public aid (subsidies or grants) for preventing cybersecurity issues in theDigital Kit" (Spanish Government program for encouraging digitalisation) has been limited and has not been taken as something global.',
    "First ResponseID": 18789,
  },
  {
    Language: "Spanish_S",
    "Country Code": "ES",
    "HQ Country": "Spain",
    Original:
      "Trabajamos con personas con discapacidad intelectual, y no hay contenidos accesibles para que ellos lo entiendan",
    English:
      "We work with people with intellectual disabilities, and there is no accessible content for them to understand.",
    "First ResponseID": 929,
  },
  {
    Language: "English",
    "Country Code": "SE",
    "HQ Country": "Sweden",
    Original:
      "Har som idellt arbetande svårt att styra vilka plattformar som ska användas i organisation. Har man dessutom flera organisationer som man arbetar med och alla använder olika blir det stora problem.",
    English:
      "As a non-profit worker, it is difficult to control which platforms should be used in the organization. In addition, if you have several organizations that you work with and all of them use different methods, it becomes a big problem.",
    "First ResponseID": 9576,
  },
  {
    Language: "English",
    "Country Code": "SE",
    "HQ Country": "Sweden",
    Original:
      "Information till äldre för ökad trygghet med att använda Internet, Ökad kunskap om hur man undviker bedrägerier och att det inte är farligt att använda Internet för t.ex. läsning av nyheter. Idag sprids felaktig info om att det är farligt att vara på nätet - av rädsla - men med genomslag. Dvs som äldre tror man ofta mer på andra jämnåriga skeptiker med talets gåva än på yngre vänner och släktingar med IT-kunskaper",
    English:
      "Information for the elderly for increased safety when using the Internet, Increased knowledge about how to avoid fraud and that it is not dangerous to use the Internet for e.g. reading news. Today, incorrect information is being spread that it is dangerous to be online - out of fear - but with impact. In other words, as older people, you often believe more in other skeptics of the same age with the gift of speech than in younger friends and relatives with IT skills",
    "First ResponseID": 5968,
  },
  {
    Language: "English",
    "Country Code": "SE",
    "HQ Country": "Sweden",
    Original:
      "A lot of the people we serve are poor, many are refugees. Language barrier is there, but the most problematic thing is the cost for internet access and devices.",
    English:
      "A lot of the people we serve are poor, many are refugees. Language barrier is there, but the most problematic thing is the cost for internet access and devices.",
    "First ResponseID": 4912,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original:
      "1網路運用技術進步太快，知識的取得太多太雜，不知如何跟上 2如何增加網路的安全，所需的設備太貴，技術太難，非營利組織很難負擔",
    English:
      "1. Internet change too fast, too complicated,and too much new knowlege for us to keep up. 2. How to increase the security of the Internet? Not only expensive, the technology is too heavy, non-profit organizations can not afford.",
    "First ResponseID": 11498,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original: "名詞定義與生活用語有差異",
    English:
      "Differences between some noun definitions in this survey and our daily live here.",
    "First ResponseID": 4816,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original:
      "數位平權也需建立在，當地政府對於資訊中立的支持。倘落政府利用國家力量，動用人員在社群媒體上發表偏頗言論，則對所有組織來說，都是一場災難。",
    English:
      "Digital equality also needs to be built on the support of local governments for information neutrality. It would be a disaster for all organizations if the government used the power of the state to mobilize people to produce biased comments on social media.",
    "First ResponseID": 14252,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original: "希望能有志工協助基金會網站建置",
    English:
      "Hope to have volunteers to help to build our foundation's website",
    "First ResponseID": 11642,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original: "組織網頁製作知能和儲存空間。",
    English:
      "How to build our organization's website and have good storage space.",
    "First ResponseID": 1633,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original: "如何有效分配資源縮短弱勢的數位落差才能達到數位平權",
    English:
      "How to effectively allocate resources to reduce digital divid of the disadvantaged, in order to achieve digital equality",
    "First ResponseID": 6890,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original:
      "因應年輕世代的使用習慣，希望能有機會發展用APP來進行自殺防治協談，來幫助青少年世代，畢竟他們較依賴使用手機的聊天軟體，而不是傳統電話的交談。只是對非營利組織而言，APP的費用太高，難以負擔。",
    English:
      "In response to the younger generation, we hope to have the opportunity to develop an APP for suicide prevention and counseling to help the younger. After all, they are more attached to the SNS, using mobile phones rather than traditional telephone. Yet for non-profit organizations, the cost of build and maintain an APP is too high.",
    "First ResponseID": 12574,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original:
      "在台灣，越小越沒錢的非營利社福團體，資源少又得不到認同和幫助，很多幫助非營利的組織也不願意幫忙，希望在數位平權投入下，能夠幫助更多小型的組織茁壯和成長。而不是因為他們沒能力、沒人力、沒有錢，就把他們推於圈圈外。",
    English:
      "In Taiwan, the smaller the social welfare organizations are, the less resources they can get. Organizations that help non-profits also unwilling to help.Hope under the digital equal rights, they are resources to help more small organizations grow. Not let them been pushed out of the circle because they have no ability, no manpower, or no money.",
    "First ResponseID": 6575,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original: "國家或地方政府應落實網路基礎建設，拉近都市與城鄉差距。",
    English:
      "National or local governments should build the infrastructure needed, to reduce the gap between urban and rural areas.",
    "First ResponseID": 1202,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original:
      "組織員工與主管有年齡差距，數位技能、重要性等，難以制度與組織化。",
    English:
      "Our employees and supervisors are in different age, need different learning support in skills and awareness of importance of digitalization.It is difficult to arrange and systemized.",
    "First ResponseID": 1191,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original:
      "服務對象家庭數位設備不足，有的是不會使用，有的不購買怕家庭經濟負擔",
    English:
      "Our user and their familes didn't have digital hardwares, some do not how to use them, and some do not buy them because cannot afford to.",
    "First ResponseID": 6519,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original: "軟體價格及使用安全性",
    English: "Software price and security protection",
    "First ResponseID": 16115,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original: "資訊數位化推動，是最大挑戰。 要馬兒好，又要兒不吃草。唉!",
    English:
      'The biggest challenge of digitalization is like an old saying in Chinese. " “to want one\'s horse to run fast without letting it graze”"  Ugh!',
    "First ResponseID": 1157,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original:
      "數位落差一直是社會貧富懸殊的副產品。我們服務社區弱勢族群，明白基礎建設與負擔對我們服務的對象而言，每天為三餐奮鬥之餘，幾乎是困難的選項。",
    English:
      "The digital gap has always been a by-product of the disparity between the rich and the poor in society. We serve disadvantaged groups in the community, and understand that infrastructure and general cost make it a difficult option for those we serve, struggling for three meals a day.",
    "First ResponseID": 11572,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original: "資訊安全的議題需要被重視，才能消除高齡者的使用抗拒。",
    English:
      "The issue of security needs to be taken seriously in order to eliminate the resistance of the elderly.",
    "First ResponseID": 1156,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original:
      "我們服務的對象跟不上資訊科技的變化，而工作人員也是疲於接受新知識，以及疲於協助服務對象跟上變化。",
    English:
      "The people we serve cannot keep up with the changes in information technology, and the staff are too tired to get new knowledge/skills to help them keep up with the changes.",
    "First ResponseID": 1409,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original:
      "網路上訊息太多，導至有價值知識內容被淹沒，對於工作者或是被服務者被誤導，是我們面臨的問題，我們希望能有工具可以協助做這些事情，幫我們排除太多無用的內容！",
    English:
      "There is too much information on the Internet,  not easy to find real valuable knowledge and content. How to prevent our staffs and clents been misled became an issue. We hope to have tools to support us in eliminating too many useless content!",
    "First ResponseID": 16946,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original:
      "本單位為服務長照2.0與獨居老人、弱勢家庭兒少族群，數位使用端接收服務存在載具(手機電腦平板)與網路(網路費用)困難，對於友善數位環境的需求較平常一般受眾用戶高出許多",
    English:
      "We are one of the long-term care 2.0,provide services to the elders who live alone, and children and teenagers in disadvantaged families. They have difficulties in using vehicles (mobile phones, computers, tablets) and internet (fees) to receive services, the demand for friendly digital environment is much more than others.",
    "First ResponseID": 15032,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "TW",
    "HQ Country": "Taiwan",
    Original:
      "We don't have enough funds to provide technical support for implementing the cloud service.",
    English:
      "We don't have enough funds to provide technical support for implementing the cloud service.",
    "First ResponseID": 892,
  },
  {
    Language: "English",
    "Country Code": "TJ",
    "HQ Country": "Tajikistan",
    Original:
      "unfortunately in the place where our organization is currently active the internet and the infrastructure for it is not developed and its availability is totally controlled by local authorities. it can be turned off for several months",
    English:
      "unfortunately in the place where our organization is currently active the internet and the infrastructure for it is not developed and its availability is totally controlled by local authorities. it can be turned off for several months",
    "First ResponseID": 7801,
  },
  {
    Language: "English",
    "Country Code": "TZ",
    "HQ Country": "Tanzania",
    Original:
      "I believe Internet is tool and resource that can eliminate family poverty if it is available to especially people in developing country like Tanzania. With just a digital device like a simple smartphone a young people can start a digital shop and start selling products online. There are zillions of opportunities online that youth can access and better their lives. But there is huge barrier that block you and people generally from accessing Internet. The barrier is lack of affordable and meaningful Internet. This is what motivated me to start a Community Broadband Internet. This is members driven outfit. What we have done is have fiber stations where fiber from national carrier terminate. Once we have fiber connectivity, we buy a wholesale package from the national carrier and divide it's exorbitant cost among the members. Each member pays very little money for unlimited data per month with 100Mbs speed. This has been a 1 year journey and the results are phenomenal. We have connected 10 schools, 100 households and 7 public institutions. The journey to continue  our work in trajectory continues.",
    English:
      "I believe Internet is tool and resource that can eliminate family poverty if it is available to especially people in developing country like Tanzania. With just a digital device like a simple smartphone a young people can start a digital shop and start selling products online. There are zillions of opportunities online that youth can access and better their lives. But there is huge barrier that block you and people generally from accessing Internet. The barrier is lack of affordable and meaningful Internet. This is what motivated me to start a Community Broadband Internet. This is members driven outfit. What we have done is have fiber stations where fiber from national carrier terminate. Once we have fiber connectivity, we buy a wholesale package from the national carrier and divide it's exorbitant cost among the members. Each member pays very little money for unlimited data per month with 100Mbs speed. This has been a 1 year journey and the results are phenomenal. We have connected 10 schools, 100 households and 7 public institutions. The journey to continue  our work in trajectory continues.",
    "First ResponseID": 2647,
  },
  {
    Language: "English",
    "Country Code": "TZ",
    "HQ Country": "Tanzania",
    Original:
      "The main challenge we are facing is, affordability of internet access tools like computers, and price of data.",
    English:
      "The main challenge we are facing is, affordability of internet access tools like computers, and price of data.",
    "First ResponseID": 4433,
  },
  {
    Language: "English",
    "Country Code": "TZ",
    "HQ Country": "Tanzania",
    Original:
      "1. It was a very useful exercise that made me understand my strengths and gaps in the internet attributes.  2. Affordability is a major challenge that hinders access of the people served by my organization to the internet. The larger population is unable to procure the applicable devices. In my office, we can not purchase the tools required to make the work easier.",
    English:
      "1. It was a very useful exercise that made me understand my strengths and gaps in the internet attributes.  2. Affordability is a major challenge that hinders access of the people served by my organization to the internet. The larger population is unable to procure the applicable devices. In my office, we can not purchase the tools required to make the work easier.",
    "First ResponseID": 5247,
  },
  {
    Language: "English",
    "Country Code": "TZ",
    "HQ Country": "Tanzania",
    Original:
      "I serve a community of People with disability, majority are unconnected and illiterate, we need more support",
    English:
      "I serve a community of People with disability, majority are unconnected and illiterate, we need more support",
    "First ResponseID": 17695,
  },
  {
    Language: "English",
    "Country Code": "TZ",
    "HQ Country": "Tanzania",
    Original:
      "Internet affordability is still an issue, that has a direct impact on access and is a policy issues that needs to be checked",
    English:
      "Internet affordability is still an issue, that has a direct impact on access and is a policy issues that needs to be checked",
    "First ResponseID": 8339,
  },
  {
    Language: "English",
    "Country Code": "TH",
    "HQ Country": "Thailand",
    Original:
      "Provide cheaper mobile and and faster net connection is how you can provide for the needy  a bottle lack on net speed is like a bottle neck for education",
    English:
      "Provide cheaper mobile and and faster net connection is how you can provide for the needy  a bottle lack on net speed is like a bottle neck for education",
    "First ResponseID": 19329,
  },
  {
    Language: "English",
    "Country Code": "TH",
    "HQ Country": "Thailand",
    Original:
      "When working on remote island, the access for internet is lacking.",
    English:
      "When working on remote island, the access for internet is lacking.",
    "First ResponseID": 17230,
  },
  {
    Language: "English",
    "Country Code": "TG",
    "HQ Country": "Togo",
    Original:
      "Internet access is frustrating especially with unpredictable weather changes and what you are offered is the best. It is also expensive",
    English:
      "Internet access is frustrating especially with unpredictable weather changes and what you are offered is the best. It is also expensive",
    "First ResponseID": 8504,
  },
  {
    Language: "English",
    "Country Code": "TG",
    "HQ Country": "Togo",
    Original: "Speed problems, digital tools and computers needed",
    English: "Speed problems, digital tools and computers needed",
    "First ResponseID": 10517,
  },
  {
    Language: "Turkish",
    "Country Code": "TR",
    "HQ Country": "Turkey",
    Original:
      "İnternet, elektrik su kadar gerekli \nolduğu halde hâlâ çok gündemde değil. \nOnline eğitim veren kuruluşlar katılımcılarının dijital \nerişilebilirliklerini düşünmeli ve fon veren \nkuruluşlar da buna dair kalem açmalı, \nbunu gelenek haline getirmeli diye düşünüyorum.",
    English:
      "Although the internet is as necessary as electricity and water, it is still not on the agenda. Organizations providing online education should consider the digital accessibility of their participants. I also think that funding organizations should prioritize this and make it a tradition.",
    "First ResponseID": 10572,
  },
  {
    Language: "Turkish",
    "Country Code": "TR",
    "HQ Country": "Turkey",
    Original: "Bireylerin teknik bilgi ve becerilerindeki eksiklikler",
    English:
      "Deficiencies in the technical knowledge and skills of individuals",
    "First ResponseID": 2137,
  },
  {
    Language: "Turkish",
    "Country Code": "TR",
    "HQ Country": "Turkey",
    Original:
      "Hızlı internet altyapısı büyük şehirler harici ya yok ya da çok pahalı.",
    English:
      "High-speed internet infrastructure is either non-existent or very expensive, except for big cities.",
    "First ResponseID": 18629,
  },
  {
    Language: "Turkish",
    "Country Code": "TR",
    "HQ Country": "Turkey",
    Original:
      "Aslında ulaşım ve eşitlik konusunda çok sorun yaşamıyoruz ancak. \nKullanım becerisi ve yeterli zaman ayıramama gibi kendimizce \nbahanelerimizden ötürü ihtiyaç duyulan kapasitede \nhizmet alımı veya hizmet verimi sağlayamadığımıza inanıyorum.",
    English:
      "In fact, we do not have many problems with transportation and equality. However, I believe that we are not able to provide service procurement or service efficiency at the required capacity due to reasons such as usage skills and not being able to allocate enough time.",
    "First ResponseID": 2118,
  },
  {
    Language: "Turkish",
    "Country Code": "TR",
    "HQ Country": "Turkey",
    Original:
      "İnternet erişim yasakları çok sık ve kolay mekanizmalarla konuyor. Buna karşı bir önlem olabilen VPN hizmetlerinin ücretsiz olanlarının ise riskli olduğu söyleniyor.",
    English:
      "Internet access bans are imposed very often and with easy mechanisms. Free VPN services, which can be a countermeasure, are said to be risky.",
    "First ResponseID": 18571,
  },
  {
    Language: "Turkish",
    "Country Code": "TR",
    "HQ Country": "Turkey",
    Original:
      "Internet altyapısı, sürekli mahkeme kararları ile yasaklamalar, \nifade özgürlüğü problemleri konuları yorucu ve üzücü olabiliyor.",
    English:
      "Internet infrastructure, constant court orders and bans, and problems in freedom of expression can be tiring and distressing.",
    "First ResponseID": 6801,
  },
  {
    Language: "Turkish",
    "Country Code": "TR",
    "HQ Country": "Turkey",
    Original:
      "microsoft yazılımları çok pahalı bireysel bütçemiz \nile karşılamak zorunda kalıyoruz. Arıca dijital içerik üretimi \niçin programların temini ve kullanım eğitimlerine \nücretsiz erişemiyoruz",
    English:
      "Microsoft software is very expensive and we have to meet it with our individual budget. In addition, we cannot access free trainings for the supply and use of programs for digital content production.",
    "First ResponseID": 15181,
  },
  {
    Language: "Turkish",
    "Country Code": "TR",
    "HQ Country": "Turkey",
    Original:
      "Vakfımızın dijital araçlara erişimi ve paylaşımı konusunda finansal sıkıntıları mevcut.",
    English:
      "Our foundation has financial difficulties in accessing and sharing digital tools.",
    "First ResponseID": 2334,
  },
  {
    Language: "Turkish",
    "Country Code": "TR",
    "HQ Country": "Turkey",
    Original:
      "Yazılımlar (adobe, office vb. ) çok pahalı ve sınırlı sayıda kişi erişebiliyor.",
    English:
      "Software (adobe, office, etc.) is very expensive and only a limited number of people can access it.",
    "First ResponseID": 6809,
  },
  {
    Language: "Turkish",
    "Country Code": "TR",
    "HQ Country": "Turkey",
    Original: "gönüllü üyelerin ekiğman erişimi ekonomik olarak imkansız",
    English:
      "Volunteer members' access to equipment is economically impossible.",
    "First ResponseID": 18581,
  },
  {
    Language: "English",
    "Country Code": "VI",
    "HQ Country": "U.S. Virgin Islands",
    Original: "Age of congregation lends to disinterest and distrust",
    English: "Age of congregation lends to disinterest and distrust",
    "First ResponseID": 6760,
  },
  {
    Language: "Arabic",
    "Country Code": "UG",
    "HQ Country": "Uganda",
    Original:
      "The lack of devices and access to   Internet are the two biggest barriers to digitalization justice that is currently imperative to empower people and strengthen their connectivity. Information and communication   technology literacy can not be possible without appropriate tools, training skills and abilities to afford Internet. The needs in tools especially software and hardware for scaling up ICT literacy is the pre-requisite. This is going to contribute to the resolution of the large gap within the refugee hosting districts, and school and ICT hubs encountered at many public schools.",
    English: "",
    "First ResponseID": 14710,
  },
  {
    Language: "Dutch",
    "Country Code": "UG",
    "HQ Country": "Uganda",
    Original:
      "In a rural area, there is no infrastructure for typical internet connections and cell phone reception is not reliable. We are waiting for the Starlink project in Uganda to have a reliable connection through satellite, but there is a price to pay and it is not available yet. If you know any other alternatives, I would love to hear about this.",
    English:
      "In a rural area, there is no infrastructure for typical internet connections and cell phone reception is not reliable. We are waiting for the Starlink project in Uganda to have a reliable connection through satellite, but there is a price to pay and it is not available yet. If you know any other alternatives, I would love to hear about this.",
    "First ResponseID": 8382,
  },
  {
    Language: "English",
    "Country Code": "UG",
    "HQ Country": "Uganda",
    Original:
      "High need for digital training in rural communities; Lack of digital equipment; Lack of free internet access in rural communities",
    English:
      "High need for digital training in rural communities; Lack of digital equipment; Lack of free internet access in rural communities",
    "First ResponseID": 14787,
  },
  {
    Language: "English",
    "Country Code": "UG",
    "HQ Country": "Uganda",
    Original:
      "My major focus is on ensuring access to digital technology and quality affordable education in rural communities of Uganda where digital access is very low. The main challenges in promoting access to education and digital technologies in rural Uganda are: Electricity, Classroom infrastructure and lack of digital devices.",
    English:
      "My major focus is on ensuring access to digital technology and quality affordable education in rural communities of Uganda where digital access is very low. The main challenges in promoting access to education and digital technologies in rural Uganda are: Electricity, Classroom infrastructure and lack of digital devices.",
    "First ResponseID": 1125,
  },
  {
    Language: "English",
    "Country Code": "UG",
    "HQ Country": "Uganda",
    Original:
      "Affordability is still a huge challenge for the communities we serve.",
    English:
      "Affordability is still a huge challenge for the communities we serve.",
    "First ResponseID": 3028,
  },
  {
    Language: "English",
    "Country Code": "UG",
    "HQ Country": "Uganda",
    Original:
      "As a non-profit trying to bridge the digital skills, we face challenges of access to equipment for the skilling sessions, and training for our staff.",
    English:
      "As a non-profit trying to bridge the digital skills, we face challenges of access to equipment for the skilling sessions, and training for our staff.",
    "First ResponseID": 11087,
  },
  {
    Language: "English",
    "Country Code": "UG",
    "HQ Country": "Uganda",
    Original:
      "In our organisation, we have two computers and one of them is not functioning well. Which means we have only one.We also don't have finance to help us buy one to make work easy. Also internate is very expensive for us. Our donor has also withdrawn from helping us due to the effet of COVID",
    English:
      "In our organisation, we have two computers and one of them is not functioning well. Which means we have only one.We also don't have finance to help us buy one to make work easy. Also internate is very expensive for us. Our donor has also withdrawn from helping us due to the effet of COVID",
    "First ResponseID": 6866,
  },
  {
    Language: "English",
    "Country Code": "UG",
    "HQ Country": "Uganda",
    Original:
      "Our Digital Equity Journey started with digital skills training, digital infrastructure and Fast internet access",
    English:
      "Our Digital Equity Journey started with digital skills training, digital infrastructure and Fast internet access",
    "First ResponseID": 4815,
  },
  {
    Language: "English",
    "Country Code": "UG",
    "HQ Country": "Uganda",
    Original:
      "Thank you very much for this wonderful survey. We are still facing a challenge of digital tools like laptops , computers which are very expensive. We tried our best to get these instruments but are very expensive to ship into our country.  I will be delighted to see that this idea doesn't stop on survey but let us be supported by a grant to support our organisations in digital inclusion for all. Thanks",
    English:
      "Thank you very much for this wonderful survey. We are still facing a challenge of digital tools like laptops , computers which are very expensive. We tried our best to get these instruments but are very expensive to ship into our country.  I will be delighted to see that this idea doesn't stop on survey but let us be supported by a grant to support our organisations in digital inclusion for all. Thanks",
    "First ResponseID": 3176,
  },
  {
    Language: "English",
    "Country Code": "UG",
    "HQ Country": "Uganda",
    Original:
      "We are facing the problem of internet affordability. lack of the software to use. We lack the gadgets(computers, mobile phones etc.) We also lack the digital skills.",
    English:
      "We are facing the problem of internet affordability. lack of the software to use. We lack the gadgets(computers, mobile phones etc.) We also lack the digital skills.",
    "First ResponseID": 8258,
  },
  {
    Language: "English",
    "Country Code": "UG",
    "HQ Country": "Uganda",
    Original:
      "We don't have access to reliable internet, can't afford it and we don't have the necessary digital tools. Any support in this area we have mentioned and how to secure funding will be  highly appreciated.",
    English:
      "We don't have access to reliable internet, can't afford it and we don't have the necessary digital tools. Any support in this area we have mentioned and how to secure funding will be  highly appreciated.",
    "First ResponseID": 4517,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Внаслідок війни, що розпочала Росія проти України наші громадяни, зокрема на окупованих та прифронтових територіях попросту втрачають право на інформацію та користування інтернето",
    English:
      "As a result of the war launched by Russia against Ukraine, our citizens, in particular in the occupied and near-front territories, simply lose the right to access the information and use the Internet",
    "First ResponseID": 8886,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Під час війни дуже тяжко це зробити основою життя, тому доступ до інтернету та доступність інформації в тяжкому стані і це дуже погано",
    English:
      "During the war it is very difficult to make it the basis of life, so access to the Internet and the availability of information is in a difficult condition and it is very bad",
    "First ResponseID": 3585,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      'В Україні найбільшим викликом цифровій рівності на сьогодні є війна росії проти України, яка спричиняє руйнування інфраструктури (в т.ч. провайдерів інтернет) і майна організацій і фізичних осіб на територіях, де ведуться інтенсивні бойові дії. Окупанти також викрадають обладнання (комп"ютери, мобільні телефони тощо) з будинків і у людей, які намагаються виїхати з небезпечних зон, відключають українських інтернет провайдерів, обмежують людям доступ до Інтернету та інформаційних джерел. Виклик для пост-воєнного відновлення України є створення умов для цифрової рівності із дотриманням еко-стандартів, а саме - не перетворитися на ринок збуту неліквідних, неякісних товарів цифрового призначення.',
    English:
      "In Ukraine, the biggest challenge to digital equality today is russia's war against Ukraine, which is causing the destruction of infrastructure (including Internet providers) and property of organizations and individuals in the territories where intense hostilities are taking place. The occupants also steal equipment (computers, mobile phones, etc.) from homes and from people trying to leave dangerous areas, disconnect Ukrainian Internet providers, restrict people's access to the Internet and information sources. The challenge for the post-war recovery of Ukraine is to create conditions for digital equality in compliance with eco-standards, namely, not to turn into a market for illiquid, low-quality digital goods.",
    "First ResponseID": 2892,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Дуже бракує сучасного цифрового обладнання, (сильного ноутбука чи системника, проектор). Місцева влада, відділ освіти не зацікавленний у придбанні нової техніки для закладу ( не перший рік). А хочеться  показати, висвітлити гарний матеріал на сайті,під час війни проводиться багато заходів для ВПО, щоб користувач з глибокими  знаннями між працювати в ІТ сфері, прийшовши до нас у бібліотеку, але не маємо вже сучасної техніки, застаріла 12 р. комп'ютерам.",
    English:
      "There is a great lack of modern digital equipment (strong laptop or system, projector). The local authorities, the department of education are not interested in purchasing new equipment for the institution (not for the first year). And we want to show, highlight good material on the site, during the war there are many events for IDPs, so that a user with deep knowledge can work in the IT field, coming to our library, but we do not have modern technology, computers are outdated 12 years.",
    "First ResponseID": 16297,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Дякуємо за порушення такої важливої теми, особливо для сільського населення. Маємо надію, що  в майбутньому для   бажаючих буде можливість покращувати  цифрову грамотність завдяки соціальним програмам, або хоч символічну плату.",
    English:
      "Thank you for raising such an important topic, especially for the rural population. We hope that in the future there will be an opportunity for those who wish to improve digital literacy through social programs, or at least for a symbolic fee.",
    "First ResponseID": 14856,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Цифрова доступність для людей з інвалідністю в нашій країні на низькому рівні",
    English:
      "Digital accessibility for people with disabilities in our country is at a low level",
    "First ResponseID": 8869,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Цифрова рівність наразі дуже потрібна в освітній сфері. Особливо враховуючи багаторічний онлайн-формат навчання. Утім, досить складно знайти донорів, що згодні ставати спонсорами проєктів, що спрямовані на підвищення кваліфікації вчителів та викладачі, щодо цифрових навичок.",
    English:
      "Digital equality is now very much needed in the educational sphere. Especially considering the long-term online learning format. However, it is quite difficult to find donors who agree to sponsor projects aimed at improving the qualifications of teachers and teachers in digital skills.",
    "First ResponseID": 7784,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "У зв'язку з військовими діями мешканці окупованої Херсонської області не мають доступу до інтернету.",
    English:
      "Due to military actions, residents of the occupied Kherson region do not have access to the Internet.",
    "First ResponseID": 16658,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "У період війни, багато бомбосховищ не мають обладнання для інтернету, та самого інтернету. Люди з інвалідністю не можуть самостійно дістатися до будь якого місця, де є інтернет.",
    English:
      "During the war, many bomb shelters do not have equipment for the Internet, and the Internet itself. People with disabilities cannot get to any place where there is internet on their own.",
    "First ResponseID": 8822,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "У всіх Громадських організацій чи Благодійних організацій або фондів, які зареєстровані в Україні - найбільший виклик і перешкода це ціна програмного забезпечення або відсутність перекладу чи навіть інформації про недороге або безкоштовне програмне забезпечення",
    English:
      "For all NGOs or Charities or foundations registered in Ukraine - the biggest challenge and obstacle is the price of software or lack of translation or even information about inexpensive or free software",
    "First ResponseID": 14452,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Для нас в Україні інтернет є однією з базових комунальних послуг (як водопостачання чи електроенергія).  Усім миру!",
    English:
      "For us in Ukraine the Internet is one of the basic utilities (like water supply or electricity).  Peace to all!",
    "First ResponseID": 2801,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Хаби з макетами ОТР центральних міжвідомчих захищених сховищ медичних, учбових матеріалів, макети ОТР, як для потреб служби 112 МНС України захищеного вузла доступу до захищеного сховища учбових, медичних так і матеріалів та передбачаємо розгорнути і низку діючих макетів ОТР мобільних захищених вузлів доступу до захищеного сховища, медичних  учбових матеріалів, що функціонують в високоінтегрованих широко/вузько смугових та супутникових мережах польового призначення, у т.ч. з забезпеченням вимог ТЗІ та КСЗІ і GDPR.  Однак, у зв'язку з поточною ситуацією також  існує невідкладна потреба розгортання обладнаних нами системою реанімаційної та евакуаційної медичної техніки.Дуже потребуємо донорської допомоги у придбанні обладнання для.цієї ІТ системи",
    English:
      "Hubs with OTP models of central interagency secure storages of medical, educational materials, OTP models, both for the needs of 112 Service of the State Emergency Service of Ukraine in a secure access node to the secure storage of educational, medical and materials and we envisage deploying a number of existing OTP mock-ups of mobile secure access nodes to secure storage, medical training materials operating in highly integrated wide/narrowband and satellite networks for field use, including ensuring the requirements of TSI, CSIS and GDPR.  However, due to the current situation, there is also an urgent need to deploy our resuscitation and evacuation medical equipment. We really need donor assistance in purchasing equipment for this IT system",
    "First ResponseID": 10859,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Я розумію, що доступна муніципальна інтернет мережа має бути обов'язковою складовою будь-якої організації, що працює для людей. Це і школи, лікарні, кабінети сімейних лікарів, муніципальні заклади тощо, але навіть у західних країнах Європи послуги інтернету не є такі вільно доступні і відкриті для користування будь-ким, з відкритою мережею, і ці процеси залежать від влади, яка має це фінансувати, або віднаходити інші джерела фінансування",
    English:
      "I understand that an accessible municipal Internet network should be a mandatory component of any organization that works for people. This includes schools, hospitals, family doctors' offices, municipal institutions, etc., but even in Western European countries, Internet services are not so freely available and open for use by anyone, with an open network, and these processes depend on the authorities to finance it or find other sources of funding",
    "First ResponseID": 16492,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original: "Впровадження технологій для 60+ за віком",
    English: "Implementation of technologies for people aged 60+",
    "First ResponseID": 8968,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "У селах немає доступу до інтернет-ресурсів, а якщо є то це мінімальна швидкість без вибору провайдерів, тому інформація там неповна, або спотворена. Мобільного зв'язку там немає теж.",
    English:
      "In villages there is no access to Internet resources, and if there is, it is a minimum speed without a choice of providers, so the information there is incomplete or distorted. There is no mobile communication there either.",
    "First ResponseID": 14247,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      'Бажано було б організувати БЕЗОПЛАТНО систему цифрового навчання для соціально вразливих і економічно ослаблених груп населення: самітних людей, пенсіонерів, маломобільних/ нетранспортабельних хворих, людей з інвалідністю, неповних сімей. Переважно з роботою на смартфоні. Професіональні ГО  працюють на ноутбуках, комп\'ютерах, планшетах. Але для них теж потрібні заняття не "бігом", а з більшою кількістю вправ  на заняттях в інтернеті.',
    English:
      'It would be desirable to organize a FREE digital learning system for socially vulnerable and economically weakened groups of the population: lonely people, pensioners, people with limited mobility / non-transportable patients, people with disabilities, single-parent families. Mostly with work on a smartphone. Professional NGOs work on laptops, computers, tablets. But they also need classes not "running", but with more exercises on the Internet.',
    "First ResponseID": 15840,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Керівники органів місцевого самоврядування та деякі представники депутатського корпусу сільських громад до цього часу не розуміють важливості  цифрової грамотності для жителів громад.",
    English:
      "Local self-government leaders and some representatives of the deputy corps of rural communities still do not understand the importance of digital literacy for community residents.",
    "First ResponseID": 7150,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "рівень доходу у людей настільки низкий, що не всі люди можуть собі дозволити придбати прилади для інтернету, ноутбуки, мобільні телефони.",
    English:
      "People's income level is so low that not all people can afford to buy Internet devices, laptops, mobile phones.",
    "First ResponseID": 10908,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Сільські люди просто не мають ні фінансових, ні інших можливостей для бажаного доступу у світ цифрових технологій.",
    English:
      "Rural people simply have neither financial nor other opportunities for the desired access to the world of digital technologies.",
    "First ResponseID": 8375,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "соціально незахищені верстви населення мають малий доступ до навчання та закупівлі планшетів й компьютерів",
    English:
      "Socially vulnerable groups of the population have limited access to training and purchase of tablets and computers",
    "First ResponseID": 14803,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original: "Найбільша проблема це низький рівень технічного обладнання",
    English: "The biggest problem is the low level of technical equipment",
    "First ResponseID": 8733,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Керівництво багатьох навчальних закладів вважає, що забезпечення Інтернетом та оплата вартості приладів і вартості самого Інтернету та програмного забезпечення - це справа працівників освітніх закладів та батьків здобувачів освіти, навіть і в умовах війни в Україні та ковіду. В загальноосвітніх закладах добре поставлена підготовка вчителів до опановування цифровими навичками та постійного підвищення кваліфікації. У вищих, на жаль, ні.",
    English:
      "The management of many educational institutions believes that the provision of the Internet and payment for the cost of devices and the cost of the Internet and software is the responsibility of employees of educational institutions and parents of students, even in the context of the war in Ukraine and the Covid. In secondary educational institutions teachers are well prepared to master digital skills and continuous professional development. In higher education, unfortunately, not.",
    "First ResponseID": 15248,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Є потреба у навчання цифровим технологіям та їх практичному застосунку.",
    English:
      "There is a need for training in digital technologies and their practical application.",
    "First ResponseID": 14866,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "В проект цифрового інтегрованого зростання рівності знань, а також започаткування цих знань для отимізації життя Українців. Потрібно підключати безпосередньо Українські компанії, а не лишень іноземних партнерів. Компанії, котрі попередні роки та теперішні,  отримували зиск і отримують, від провадженої своєї діяльності: мобільні оператори; інтернет-провайдери.",
    English:
      "Ukrainian companies should be involved in the project of digital integrated growth of knowledge equality, as well as the use of this knowledge to optimize the lives of Ukrainians, not only foreign partners. Companies that have benefited in previous years and are still benefiting from their activities: mobile operators; Internet providers.",
    "First ResponseID": 7624,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "На жаль, люди поважного віку дуже часто не мають смартфонів, а тим більше ноутбуків, для користування. Це обмежує спілкування, проведення заходів онлайн.",
    English:
      "Unfortunately, elderly people often do not have smartphones, let alone laptops, to use. This limits communication and online activities.",
    "First ResponseID": 15325,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Нажаль рівень користування цифровими технологіями для людей 50+ на низькому рівні",
    English:
      "Unfortunately, the level of digital technology use for people aged 50+ is low",
    "First ResponseID": 14100,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "наразі працюємо з цільовою ауиторією, яка проживає в сірій зоні та в зоні бойових дій на Сході Україні... люди сидять без світла, газу, води... але найгірше, що вони там без мобільного зв'язку та Інтернету = наразі дуже важливо забезпечити потужним (можливо, супутниковим) обладнанням ці території, щоб уберегти українців від біди... сподіваємося, що ви зможете нам в цьому допомогти!",
    English:
      "We are currently working with the target audience who live in the gray zone and in the war zone in eastern Ukraine... people are without electricity, gas, water... but the worst thing is that they are without mobile communication and the Internet = now it is very important to provide powerful (possibly satellite) equipment to these territories to save Ukrainians from trouble... we hope that you can help us with this!",
    "First ResponseID": 8234,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Ми зараз проживаємо в умовах війни і цей досвід спонукає думати та розвивати ресурси максимально дієві у критичних умовах (освільки війни нажаль триватимуть за перерозподіл енергетичних ресурсів). Ми роздумуємо над супутниковим Інтернетом та альтернативними джерелами енегрії для підтримки роботи гаджетів для підтримки звязку. Дякую за опитування та чекатиму результатів.",
    English:
      "We are now living in a war and this experience encourages us to think and develop resources as efficient as possible in critical conditions (as wars will unfortunately continue for the redistribution of energy resources). We are thinking about satellite internet and alternative energy sources to support the work of gadgets to maintain communication. Thank you for the survey and look forward to the results.",
    "First ResponseID": 4768,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Ми працюємо з осередками громадянської освіти у всіх куточках країні та з корисним дозвілям для молоді, ми б бажали аби кожен Молодіжний центр мав  потужнйи ноутбку, вай фай роутер та надійний доступ до інтернету",
    English:
      "We are working with civic education centers in all parts of the country and with useful leisure activities for young people, we would like each Youth Center to have a powerful laptop, wi-fi router and reliable Internet access",
    "First ResponseID": 8986,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Вважаємо, що поруч з існуючими перевагами Інтернет має і свої вади (вартість, неякісний або навіть шкідливий контект, можливість маніпуоювання  свідомістю (боти). Тому з нетерпіггям чекаємо нових ноу-хау у комунікаціях.",
    English:
      "We believe that along with the existing advantages, the Internet has its drawbacks (cost, low-quality or even harmful content, the possibility of manipulating consciousness (bots). Therefore, we are looking forward to new know-how in communications.",
    "First ResponseID": 14826,
  },
  {
    Language: "Ukrainian",
    "Country Code": "UA",
    "HQ Country": "Ukraine",
    Original:
      "Ми надаємо гуманітарну допомогу особам у СЖО. Оскільки працюємо у прифронтовому місті та зважаючи на велику кількість бенефіціарів, працюємо за попереднім записом- за телефоном, у месенджері, або заповнення гугл форми. Особи старшого віку, малозабезпечені особи, особи з браком знань часто не встигають записатися, бо не знають як, не можуть додзвонитися без функції автодозвону ( не мають смартфонів), або елементарно не мають знань як заповнити форму.",
    English:
      "We provide humanitarian assistance to people in difficult life circumstances. Since we work in a frontline city and due to the large number of beneficiaries, we work by appointment - by phone, messenger, or filling out a Google form. Elderly people, low-income people, people with a lack of knowledge often do not have time to sign up because they do not know how, cannot get through without the auto-dial function (do not have smartphones), or simply do not know how to fill out the form.",
    "First ResponseID": 7398,
  },
  {
    Language: "Bulgarian",
    "Country Code": "GB",
    "HQ Country": "United Kingdom",
    Original:
      "За рисковите групи, с които работим досъ=тъпът до Интернет трябва да бъде осигурен",
    English:
      "For the groups at risk we work with we need to provide access to Internet",
    "First ResponseID": 3259,
  },
  {
    Language: "English",
    "Country Code": "GB",
    "HQ Country": "United Kingdom",
    Original:
      "As a UK registered NGO working in remote parts of Sierra Leone, communication and Internet services are prohibitively expensive for the local communities with rare or hardly any infrastructure availability. The government has the ability to extend their reach to these remote areas but seems almost unwilling to do so! Lack of daily communication with our only free hospital in Sierra Leone makes giving our services to the local communities even more challenging. With better affordable communication we would be able to train and engage with our staff in a more efficient manner.",
    English:
      "As a UK registered NGO working in remote parts of Sierra Leone, communication and Internet services are prohibitively expensive for the local communities with rare or hardly any infrastructure availability. The government has the ability to extend their reach to these remote areas but seems almost unwilling to do so! Lack of daily communication with our only free hospital in Sierra Leone makes giving our services to the local communities even more challenging. With better affordable communication we would be able to train and engage with our staff in a more efficient manner.",
    "First ResponseID": 8719,
  },
  {
    Language: "English",
    "Country Code": "GB",
    "HQ Country": "United Kingdom",
    Original:
      "Digital divide between the UK, where our HQ is, and rural South Africa. The main problem is lack of connectivity.",
    English:
      "Digital divide between the UK, where our HQ is, and rural South Africa. The main problem is lack of connectivity.",
    "First ResponseID": 16997,
  },
  {
    Language: "English",
    "Country Code": "GB",
    "HQ Country": "United Kingdom",
    Original:
      "Our challenges are around reaching older members of society who do not have access to the internet when so much of information is only available via the internet.  The poorest in our society who cannot afford laptops and smart phones are also disenfranchised from a society that expects everyone to have a smart phone available to them.",
    English:
      "Our challenges are around reaching older members of society who do not have access to the internet when so much of information is only available via the internet.  The poorest in our society who cannot afford laptops and smart phones are also disenfranchised from a society that expects everyone to have a smart phone available to them.",
    "First ResponseID": 19468,
  },
  {
    Language: "English",
    "Country Code": "GB",
    "HQ Country": "United Kingdom",
    Original:
      "Small organisation which has to be across multiple platforms, which all have a financial, time and skills burden. All these platforms profess to improve efficiency, accessibility, knowledge etc. etc. but they also create more work, demand more resource and need more support to deliver them. They can also lead to fundamental org changes that weren't planned. Digital enhancements can feel like very mixed blessings!",
    English:
      "Small organisation which has to be across multiple platforms, which all have a financial, time and skills burden. All these platforms profess to improve efficiency, accessibility, knowledge etc. etc. but they also create more work, demand more resource and need more support to deliver them. They can also lead to fundamental org changes that weren't planned. Digital enhancements can feel like very mixed blessings!",
    "First ResponseID": 16308,
  },
  {
    Language: "English",
    "Country Code": "GB",
    "HQ Country": "United Kingdom",
    Original:
      "The biggest challenge we face is digital skills/readiness. We can implement technology but our staff and beneficiaries need to be able to use it, want to use it and need to be trained and supported in doing so. This is tricky with limited resources and capacity. Not being Digital significantly reduces our ability to innovate and remain sustainable and relevant.",
    English:
      "The biggest challenge we face is digital skills/readiness. We can implement technology but our staff and beneficiaries need to be able to use it, want to use it and need to be trained and supported in doing so. This is tricky with limited resources and capacity. Not being Digital significantly reduces our ability to innovate and remain sustainable and relevant.",
    "First ResponseID": 17028,
  },
  {
    Language: "English",
    "Country Code": "GB",
    "HQ Country": "United Kingdom",
    Original:
      "The charity sector is the backbone of community but face challenges like, lack of digital skills, no support financially from government funding and they lack education to put digital transformation a priority in their services.",
    English:
      "The charity sector is the backbone of community but face challenges like, lack of digital skills, no support financially from government funding and they lack education to put digital transformation a priority in their services.",
    "First ResponseID": 8360,
  },
  {
    Language: "English",
    "Country Code": "GB",
    "HQ Country": "United Kingdom",
    Original:
      "The people I serve need access to up to date equipment and software. Many are using out of date software in kit that cannot be upgraded. This exposes them to digital risk. It also denies them access to many online services. Lack of skill is also a major issue.",
    English:
      "The people I serve need access to up to date equipment and software. Many are using out of date software in kit that cannot be upgraded. This exposes them to digital risk. It also denies them access to many online services. Lack of skill is also a major issue.",
    "First ResponseID": 11836,
  },
  {
    Language: "English",
    "Country Code": "GB",
    "HQ Country": "United Kingdom",
    Original:
      "There is the need to expand to help people with no digital knowledge to up-skills to be able to get access to daily services, which is essential to their everyday living.",
    English:
      "There is the need to expand to help people with no digital knowledge to up-skills to be able to get access to daily services, which is essential to their everyday living.",
    "First ResponseID": 17181,
  },
  {
    Language: "English",
    "Country Code": "GB",
    "HQ Country": "United Kingdom",
    Original:
      "We support mainly people with learning disabilities who generally have low income.  They have multiple barriers to achieving digital equity, affordability, understanding, accessing benefits and financial information to help them keep living where they live and paying their bills, accessing employment applications etc.  Without being able to do all this they fall further and further back and away from digital equity. The world has gone digital and left these people behind.",
    English:
      "We support mainly people with learning disabilities who generally have low income.  They have multiple barriers to achieving digital equity, affordability, understanding, accessing benefits and financial information to help them keep living where they live and paying their bills, accessing employment applications etc.  Without being able to do all this they fall further and further back and away from digital equity. The world has gone digital and left these people behind.",
    "First ResponseID": 12780,
  },
  {
    Language: "Indonesian",
    "Country Code": "GB",
    "HQ Country": "United Kingdom",
    Original:
      "Akses internet dinegara saya sementara hanya dikuasai oleh golongan tertentu dan masih lebih banyak digunakan utk akses konten media sosial. Semoga lebih banyak lagi manfaat internet untuk perbaikan ekonomi kedepannya",
    English:
      "The internet access of my country is currently controlled by certain groups only and is mostly used to acess social media content. Hopefully there will be more benefits of the internet for future economic improvements",
    "First ResponseID": 11473,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We as a public library have free wifi to our card holders and have MiFi devices from mobile beacon / T-mobile for the patrons to check out for a week at a time.",
    English:
      "We as a public library have free wifi to our card holders and have MiFi devices from mobile beacon / T-mobile for the patrons to check out for a week at a time.",
    "First ResponseID": 5428,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "A number of our members and volunteers are older and not tech savvy. Our area, although on the surface, fairly wealthy, has limited options on internet.",
    English:
      "A number of our members and volunteers are older and not tech savvy. Our area, although on the surface, fairly wealthy, has limited options on internet.",
    "First ResponseID": 7864,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Accessible, usable, reliable and affordable internet access is paramount for all citizens",
    English:
      "Accessible, usable, reliable and affordable internet access is paramount for all citizens",
    "First ResponseID": 5544,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "All the talk about rural internet and cellular access is just that, talk! We do not have phone services without electricity and we need a back up generator. We need help.",
    English:
      "All the talk about rural internet and cellular access is just that, talk! We do not have phone services without electricity and we need a back up generator. We need help.",
    "First ResponseID": 17196,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "As a nonprofit, we meet daily challenges to provide connectivity and affordable devices to run our organization and communicate with our clients and visitors.  In addition, for grant funding it is imperative.",
    English:
      "As a nonprofit, we meet daily challenges to provide connectivity and affordable devices to run our organization and communicate with our clients and visitors.  In addition, for grant funding it is imperative.",
    "First ResponseID": 5651,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "As a senior center, the movement of everything into the digital world is a tough transition for many of our members.  We help to extent we can.",
    English:
      "As a senior center, the movement of everything into the digital world is a tough transition for many of our members.  We help to extent we can.",
    "First ResponseID": 13676,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Funding is the number one problem we face. We are an all volunteer community radio station  located in an economically depressed community. Covid led to loss of several small businesses and the cost of housing has led to more loss in our tiny, remote community. We do receive federal, tribal support for our services.",
    English:
      "Funding is the number one problem we face. We are an all volunteer community radio station  located in an economically depressed community. Covid led to loss of several small businesses and the cost of housing has led to more loss in our tiny, remote community. We do receive federal, tribal support for our services.",
    "First ResponseID": 6650,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "I live Upstate NY and there is only [company redacted] cable Internet. Nothing else. They have a monopoly and charge whatever they want. This is really hard on my budget, especially since I am over 65.",
    English:
      "I live Upstate NY and there is only [company redacted] cable Internet. Nothing else. They have a monopoly and charge whatever they want. This is really hard on my budget, especially since I am over 65.",
    "First ResponseID": 5360,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "I put out a monthly newsletter of 8-12  pages.  I rely on the internet to contact the people in the community to send in any news items, pictures, etc. that they would like in the paper.  I do all my work on the computer, and put the resulting paper on the city webpage. This is a free newsletter and at present I am basically paying for everything myself except for a small donation here and there from a couple of people.  The speed of the internet makes my task take at least twice as long as it should.  I can not upgrade my software because of the cost of the software.  The cost of ink and toner is another problem since my printers require name-brand ink and toner.  Our small community relies on this paper for local information and activities in the community.",
    English:
      "I put out a monthly newsletter of 8-12  pages.  I rely on the internet to contact the people in the community to send in any news items, pictures, etc. that they would like in the paper.  I do all my work on the computer, and put the resulting paper on the city webpage. This is a free newsletter and at present I am basically paying for everything myself except for a small donation here and there from a couple of people.  The speed of the internet makes my task take at least twice as long as it should.  I can not upgrade my software because of the cost of the software.  The cost of ink and toner is another problem since my printers require name-brand ink and toner.  Our small community relies on this paper for local information and activities in the community.",
    "First ResponseID": 4349,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "In Ohio, we have started [initiative name redacted] to look for federal funds to help improve internet infrusture in our very rual community. It is going slow but we are working with ISP's and local government to see how improvements can be made.",
    English:
      "In Ohio, we have started [initiative name redacted] to look for federal funds to help improve internet infrusture in our very rual community. It is going slow but we are working with ISP's and local government to see how improvements can be made.",
    "First ResponseID": 5430,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our church is in a downtown area of [town redacted] California. We have NO access to highspeed internet at all. None of the ISPs provide connectivity for our location. We did have DSL - but the speeds and reliability were so bad we ended up cancelling the so-called service.",
    English:
      "Our church is in a downtown area of [town redacted] California. We have NO access to highspeed internet at all. None of the ISPs provide connectivity for our location. We did have DSL - but the speeds and reliability were so bad we ended up cancelling the so-called service.",
    "First ResponseID": 5775,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our current biggest hurdle/barrier/blocker is our clients' lack of access to mobile device networks/cellular data coupled with our org's lack of reliable/formal access to messaging apps like whatsapp business (which our clients rely heavily on, but is not currently designed for our particular use cases).",
    English:
      "Our current biggest hurdle/barrier/blocker is our clients' lack of access to mobile device networks/cellular data coupled with our org's lack of reliable/formal access to messaging apps like whatsapp business (which our clients rely heavily on, but is not currently designed for our particular use cases).",
    "First ResponseID": 5946,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our satellite office and our consumers have only one option for internet service in [county redacted], NY. After taking this survey I realize there is more we can do to help our consumers with digital training and finding resources for digital equipment.",
    English:
      "Our satellite office and our consumers have only one option for internet service in [county redacted], NY. After taking this survey I realize there is more we can do to help our consumers with digital training and finding resources for digital equipment.",
    "First ResponseID": 5474,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original: "The people we serve have nothing.",
    English: "The people we serve have nothing.",
    "First ResponseID": 6525,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Ee have added livestreaming to our residency programs and have added in zoom collaborators from countries like India.  since we started doing livestream we are teaching young people to do the tech,  we want to get underwriting so we can give the livestream and video documentation away to underserved artists/communities.",
    English:
      "Ee have added livestreaming to our residency programs and have added in zoom collaborators from countries like India.  since we started doing livestream we are teaching young people to do the tech,  we want to get underwriting so we can give the livestream and video documentation away to underserved artists/communities.",
    "First ResponseID": 6691,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We need more affordable devices for all ages (children, seniors, developmentally disabled, all else) in order to service those we serve and their children, and if on credit, we need affordable interest rates",
    English:
      "We need more affordable devices for all ages (children, seniors, developmentally disabled, all else) in order to service those we serve and their children, and if on credit, we need affordable interest rates",
    "First ResponseID": 11399,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We provide donated computers, laptops and other related equipment to any school or nonprofit in Maine.  Our equipment is donated to us from local businesses so cost of many laptops are as low as $10.",
    English:
      "We provide donated computers, laptops and other related equipment to any school or nonprofit in Maine.  Our equipment is donated to us from local businesses so cost of many laptops are as low as $10.",
    "First ResponseID": 5962,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      'You asked about low cost internet - it has already been proven that people are not getting what they pay for (ie, internet speed, data rate, etc.), and these "discount" programs are definitely not providing what they say they do. we already know that having more than one device on these low cost internet services are an issue when multiple devices are in use (eg, schoolchildren at home during covid had to trade off class time because their low cost service couldn\'t handle all of the students being on zoom at the same time in the household. Some older teens ended up studying after hours and missing class, or other students have gone to fast-food restaurant parking lots for better service.) so some of the questions are reproducing digital inequity because they are not the right questions.',
    English:
      'You asked about low cost internet - it has already been proven that people are not getting what they pay for (ie, internet speed, data rate, etc.), and these "discount" programs are definitely not providing what they say they do. we already know that having more than one device on these low cost internet services are an issue when multiple devices are in use (eg, schoolchildren at home during covid had to trade off class time because their low cost service couldn\'t handle all of the students being on zoom at the same time in the household. Some older teens ended up studying after hours and missing class, or other students have gone to fast-food restaurant parking lots for better service.) so some of the questions are reproducing digital inequity because they are not the right questions.',
    "First ResponseID": 3444,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "A challenge within my own home is that my daughter constantly uses her phone for connection because at the end of the school year, her ipad had to be turned in- leaving her without a device.  I have come to find out that families of color are more likely to connect on a phone or tablet than their white counterparts who are using a laptop.",
    English:
      "A challenge within my own home is that my daughter constantly uses her phone for connection because at the end of the school year, her ipad had to be turned in- leaving her without a device.  I have come to find out that families of color are more likely to connect on a phone or tablet than their white counterparts who are using a laptop.",
    "First ResponseID": 10795,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Accessibility to high-speed internet access services throughout the United States of America in Rural communities And. Cities. Leagues and towns citizens that are low income families and citizens of disabilities have a right to have affordability reliability accessibility flexibility high-speed internet access service when they need it.",
    English:
      "Accessibility to high-speed internet access services throughout the United States of America in Rural communities And. Cities. Leagues and towns citizens that are low income families and citizens of disabilities have a right to have affordability reliability accessibility flexibility high-speed internet access service when they need it.",
    "First ResponseID": 10761,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "I think Spanish speaking people in our community have little access, because of infrastructure and cost. Also, I think there is a language barrier.",
    English:
      "I think Spanish speaking people in our community have little access, because of infrastructure and cost. Also, I think there is a language barrier.",
    "First ResponseID": 6060,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "I think the biggest challenge is sifting through all of the resources I've found for adult digital literacy training in order to find what will work for the specific community I serve. I serve a Native American community that has unique advantages, challenges, and needs.",
    English:
      "I think the biggest challenge is sifting through all of the resources I've found for adult digital literacy training in order to find what will work for the specific community I serve. I serve a Native American community that has unique advantages, challenges, and needs.",
    "First ResponseID": 16090,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "In my community foundation we focus on three priority areas: education, health, and community economic development. I would like to see us integrate technology (access, capacity building, etc.). I would like us to allocate specific funding for software and technology literacy.",
    English:
      "In my community foundation we focus on three priority areas: education, health, and community economic development. I would like to see us integrate technology (access, capacity building, etc.). I would like us to allocate specific funding for software and technology literacy.",
    "First ResponseID": 18092,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our very rural, poor, low population county has a hilly terrain making any line-of-sight towers of limited use, cable impractical, wifi/moble phone hotspots limited, and very expensive but slow, undependable satellite one of the few ways to access the Internet.  However, we have a Broadband committee that has been working for 3 years to bring broadband to our county.  US government funds are helping a little to attract companies to explore offering their services.  There is also a new US program (Affordable Connectivity Program aka ACP) to discount monthly access fees by $30 for low income families.  As nice as that is, when monthly internet is costing $120 or more, it's just a start.",
    English:
      "Our very rural, poor, low population county has a hilly terrain making any line-of-sight towers of limited use, cable impractical, wifi/moble phone hotspots limited, and very expensive but slow, undependable satellite one of the few ways to access the Internet.  However, we have a Broadband committee that has been working for 3 years to bring broadband to our county.  US government funds are helping a little to attract companies to explore offering their services.  There is also a new US program (Affordable Connectivity Program aka ACP) to discount monthly access fees by $30 for low income families.  As nice as that is, when monthly internet is costing $120 or more, it's just a start.",
    "First ResponseID": 6438,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "The community we serve is 20% Latinx and fairly recent immigrants. While we mostly focus on environmental education, we also provide some information about emergencies such as flooding, heatwaves or overuse of pesticides. Very little information on the local, state or federal level is available in Spanish. In addition, we have found that a reasonable percentage of our total population cannot read well, and this is impacting our ability to pass high quality information to them so they can make important decisions. This is a serious equity issue and puts our whole community at unnecessary risk.",
    English:
      "The community we serve is 20% Latinx and fairly recent immigrants. While we mostly focus on environmental education, we also provide some information about emergencies such as flooding, heatwaves or overuse of pesticides. Very little information on the local, state or federal level is available in Spanish. In addition, we have found that a reasonable percentage of our total population cannot read well, and this is impacting our ability to pass high quality information to them so they can make important decisions. This is a serious equity issue and puts our whole community at unnecessary risk.",
    "First ResponseID": 6831,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Two challenges: 1. Rural access in Appalachia or other remote areas has been challenging. 2. Accessibility for disabled internet users both from a standards point of view and the tools communities use online to share their content and interact with one another.",
    English:
      "Two challenges: 1. Rural access in Appalachia or other remote areas has been challenging. 2. Accessibility for disabled internet users both from a standards point of view and the tools communities use online to share their content and interact with one another.",
    "First ResponseID": 18497,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Access to afford computers, longevity of devices purchased, and change of mindset in using technology.",
    English:
      "Access to afford computers, longevity of devices purchased, and change of mindset in using technology.",
    "First ResponseID": 6210,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Access to digital tools is useless if the hardware and software are inaccessible. That is our current struggle.",
    English:
      "Access to digital tools is useless if the hardware and software are inaccessible. That is our current struggle.",
    "First ResponseID": 2771,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Adaptive technology for those with disabilities. and affordability.",
    English:
      "Adaptive technology for those with disabilities. and affordability.",
    "First ResponseID": 5974,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "As I work with many over the age of 60, I would say that our/their biggest issue is digital literacy. I often hear statements that are patently false or fearful from those I serve. They don't know where to begin learning, or actual locations in which to do it.",
    English:
      "As I work with many over the age of 60, I would say that our/their biggest issue is digital literacy. I often hear statements that are patently false or fearful from those I serve. They don't know where to begin learning, or actual locations in which to do it.",
    "First ResponseID": 14270,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Bridging the technology gap for a community of people who range in age with majority over the age of sixty that don't have internet and devices in their homes.",
    English:
      "Bridging the technology gap for a community of people who range in age with majority over the age of sixty that don't have internet and devices in their homes.",
    "First ResponseID": 4262,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Citizens with. Disabilities. Need. Accessibility. Digital Services and device coverages because they need accessibility services to get where they need",
    English:
      "Citizens with. Disabilities. Need. Accessibility. Digital Services and device coverages because they need accessibility services to get where they need",
    "First ResponseID": 4505,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Communicating the importance of internet service to the people we serve and this community in general.",
    English:
      "Communicating the importance of internet service to the people we serve and this community in general.",
    "First ResponseID": 19264,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Content and website accessibility remains the greatest challenge, alongside affordability of accessibility features. Digital literacy is our second greatest challenge.",
    English:
      "Content and website accessibility remains the greatest challenge, alongside affordability of accessibility features. Digital literacy is our second greatest challenge.",
    "First ResponseID": 3378,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Currently, the main use of the internet we use is to sign on to a regional food bank software to qualify clients followed by:  ordering food, banking,  and connecting to each other.  We hope to become a location to help those needing services to be able to access services through the internet with help as needed.",
    English:
      "Currently, the main use of the internet we use is to sign on to a regional food bank software to qualify clients followed by:  ordering food, banking,  and connecting to each other.  We hope to become a location to help those needing services to be able to access services through the internet with help as needed.",
    "First ResponseID": 6216,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Cybersecurity is becoming more of a concern that it ever has been.  We are investing a considerable amount of resources to minimize our vulnerability to a cyber attach or breach of our network.",
    English:
      "Cybersecurity is becoming more of a concern that it ever has been.  We are investing a considerable amount of resources to minimize our vulnerability to a cyber attach or breach of our network.",
    "First ResponseID": 13432,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "DC has a Language Access Law but we still find many services not translated sufficiently online.",
    English:
      "DC has a Language Access Law but we still find many services not translated sufficiently online.",
    "First ResponseID": 7857,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Digital access in rural and remote as well as intercity communities is a key issue. Access for people with disabilities, including the use of assistive technology (screen readers, magnification software, voice-text, text-voice) is a major need for the people we serve.",
    English:
      "Digital access in rural and remote as well as intercity communities is a key issue. Access for people with disabilities, including the use of assistive technology (screen readers, magnification software, voice-text, text-voice) is a major need for the people we serve.",
    "First ResponseID": 6178,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Digital Literacy and safe spaces to learn is key for low income families.  Being able to trust who is teaching you and learning technology that best suits their needs.",
    English:
      "Digital Literacy and safe spaces to learn is key for low income families.  Being able to trust who is teaching you and learning technology that best suits their needs.",
    "First ResponseID": 5792,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Digital tools are necessary and helpful. But they are not my primary goal. I can't get distracted. It is critical to remain focused on the goal.",
    English:
      "Digital tools are necessary and helpful. But they are not my primary goal. I can't get distracted. It is critical to remain focused on the goal.",
    "First ResponseID": 5994,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Due to the terrain and our location near foothills, our mobile network and WiFi access is sketchy.",
    English:
      "Due to the terrain and our location near foothills, our mobile network and WiFi access is sketchy.",
    "First ResponseID": 6065,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Educated staff is a challenge, and affording that staff, training for our current staff is underfunded and not as great a priority as it should be...think stagnate",
    English:
      "Educated staff is a challenge, and affording that staff, training for our current staff is underfunded and not as great a priority as it should be...think stagnate",
    "First ResponseID": 5344,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Fast, reliable, inexpensive Internet options have been extremely limited in the area our church is located.  Fiber is cost-prohibitive due to the absurd construction costs, and all other options are not reasonably-priced and / or not fast enough for us to be able to stream our worship services or effectively do our work in the community we serve.  It has been extremely frustrating to have to put up with the situation as it is, and it looks like T-Mobile may end up being our most reasonable option for Internet access for us in the near future…",
    English:
      "Fast, reliable, inexpensive Internet options have been extremely limited in the area our church is located.  Fiber is cost-prohibitive due to the absurd construction costs, and all other options are not reasonably-priced and / or not fast enough for us to be able to stream our worship services or effectively do our work in the community we serve.  It has been extremely frustrating to have to put up with the situation as it is, and it looks like T-Mobile may end up being our most reasonable option for Internet access for us in the near future…",
    "First ResponseID": 14257,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "For persons who live in rural and high poverty areas, infrastructure for internet access does not exist. Some attempts are being made to deliver internet access to these areas through partnerships with utility providers.",
    English:
      "For persons who live in rural and high poverty areas, infrastructure for internet access does not exist. Some attempts are being made to deliver internet access to these areas through partnerships with utility providers.",
    "First ResponseID": 6165,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "From a non-profit experience.  Digital equity is substantially hindered by the organizations operating on a principle of scarcity and scrimping on overhead.  By not investing in the tools to get the work done, it hurts the community being served and hamstrings the organizations effectiveness",
    English:
      "From a non-profit experience.  Digital equity is substantially hindered by the organizations operating on a principle of scarcity and scrimping on overhead.  By not investing in the tools to get the work done, it hurts the community being served and hamstrings the organizations effectiveness",
    "First ResponseID": 4238,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original: "Getting everyone on the same page would be nice.",
    English: "Getting everyone on the same page would be nice.",
    "First ResponseID": 14390,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Getting leadership to understand the importance of a reliable infrastructure.",
    English:
      "Getting leadership to understand the importance of a reliable infrastructure.",
    "First ResponseID": 13514,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "I am actively looking for more resources to help people build digital skills from scratch. Lots of my coworkers are pretty low-tech and it's hard to not be on the same page about basic aspects of working together.",
    English:
      "I am actively looking for more resources to help people build digital skills from scratch. Lots of my coworkers are pretty low-tech and it's hard to not be on the same page about basic aspects of working together.",
    "First ResponseID": 3345,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "I am presently a digital training specialist for the homeless with Americorps",
    English:
      "I am presently a digital training specialist for the homeless with Americorps",
    "First ResponseID": 2657,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "I didn't see anything about Intellectual Property and the Internet, but this is also an area of strong interest for me and my work in early childhood literacy.  Low income families can't access paid digital content, and digital rights management makes it hard for orgs to share content as open access.  Please also support Creative Commons license adoption.",
    English:
      "I didn't see anything about Intellectual Property and the Internet, but this is also an area of strong interest for me and my work in early childhood literacy.  Low income families can't access paid digital content, and digital rights management makes it hard for orgs to share content as open access.  Please also support Creative Commons license adoption.",
    "First ResponseID": 3401,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "I help extremely disenfranchised populations. Is there any way for someone who has no electricity at this point to have home internet?",
    English:
      "I help extremely disenfranchised populations. Is there any way for someone who has no electricity at this point to have home internet?",
    "First ResponseID": 13564,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "I hesitate to participate in your survey only because the communities we attempt to serve in Haiti are facing extraordinary sociopolitical hardships that cannot be easily solved with technology. At this time, our work is stopped. Thanks for all your care and for all you do.",
    English:
      "I hesitate to participate in your survey only because the communities we attempt to serve in Haiti are facing extraordinary sociopolitical hardships that cannot be easily solved with technology. At this time, our work is stopped. Thanks for all your care and for all you do.",
    "First ResponseID": 5934,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "I really like the work that you do. Please do keep us posted on your progress and events where we could participate :)",
    English:
      "I really like the work that you do. Please do keep us posted on your progress and events where we could participate :)",
    "First ResponseID": 3744,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "I work in building equitable economic ecosystems, so my partners are universities, economic development organizations, local entrepreneurial associations, so digital access per se is not the problem, but effective digital content is.",
    English:
      "I work in building equitable economic ecosystems, so my partners are universities, economic development organizations, local entrepreneurial associations, so digital access per se is not the problem, but effective digital content is.",
    "First ResponseID": 18176,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "I work with adults with developmental disabilities. Often and easily victims online. Protection comes from caregivers not the internet, caregivers aren't available at all times. We've had many victims. Plus our organization can't afford the level of internet we need without applying for grants. Our internet freezes throughout the day, and costs keep going up for basic speeds. Why can't we all have high speed functional internet like we have electricity? We can survive without both, but we we can't participate in society without either.",
    English:
      "I work with adults with developmental disabilities. Often and easily victims online. Protection comes from caregivers not the internet, caregivers aren't available at all times. We've had many victims. Plus our organization can't afford the level of internet we need without applying for grants. Our internet freezes throughout the day, and costs keep going up for basic speeds. Why can't we all have high speed functional internet like we have electricity? We can survive without both, but we we can't participate in society without either.",
    "First ResponseID": 5722,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "I would like to flag the content moderation/censorship in relation to Palestine as its own standalone internet governance issue.",
    English:
      "I would like to flag the content moderation/censorship in relation to Palestine as its own standalone internet governance issue.",
    "First ResponseID": 17931,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "I'm surprised that your early question about what kind of work we do didn't offer NEWS as a category of endeavor.",
    English:
      "I'm surprised that your early question about what kind of work we do didn't offer NEWS as a category of endeavor.",
    "First ResponseID": 6248,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "In my other job, I am currently working with people who are taking GED classes remotely. They are mostly accessing their classes by their mobile devices. This is all very relevant to them--cost, availability, affordability! I used to work for an organization who did a huge research project on digital literacy education. We really don't have much where we are, so they started giving free classes. Unfortunately when I left to go teach remote GED classes, that program died. There are so many people who need to learn digital literacy!",
    English:
      "In my other job, I am currently working with people who are taking GED classes remotely. They are mostly accessing their classes by their mobile devices. This is all very relevant to them--cost, availability, affordability! I used to work for an organization who did a huge research project on digital literacy education. We really don't have much where we are, so they started giving free classes. Unfortunately when I left to go teach remote GED classes, that program died. There are so many people who need to learn digital literacy!",
    "First ResponseID": 13594,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "In Ohio, our rural areas do not have broadband access or a strong signal in all areas. The library tries to do the best we can by loaning WiFi hotspots but the demand exceeds the amount of hotspot service we are able to pay for, even with the Emergency Connectivity Fund. Plus, the government is very slow in getting the ECF funds to us. It would be helpful if there was a provider that would do better in working with public libraries for our hotspots. We have Verizon because that is the best service in our area, but they are not the best for customer service. It is disappointing that we do not really have any other options.",
    English:
      "In Ohio, our rural areas do not have broadband access or a strong signal in all areas. The library tries to do the best we can by loaning WiFi hotspots but the demand exceeds the amount of hotspot service we are able to pay for, even with the Emergency Connectivity Fund. Plus, the government is very slow in getting the ECF funds to us. It would be helpful if there was a provider that would do better in working with public libraries for our hotspots. We have Verizon because that is the best service in our area, but they are not the best for customer service. It is disappointing that we do not really have any other options.",
    "First ResponseID": 5975,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "In our rural area [company redacte] is the only broadband provider, but there are many areas they do not serve due to lack of population density. Public internet is limited.  Some of our community members must come to our Community Center to use the internet because their service is not fast/affordable enough. Our Center is not open 24/7 so most people sit in their cars to use the wifi. We've had to purchase extra equipment to extend & secure our wifi to serve them.",
    English:
      "In our rural area [company redacte] is the only broadband provider, but there are many areas they do not serve due to lack of population density. Public internet is limited.  Some of our community members must come to our Community Center to use the internet because their service is not fast/affordable enough. Our Center is not open 24/7 so most people sit in their cars to use the wifi. We've had to purchase extra equipment to extend & secure our wifi to serve them.",
    "First ResponseID": 16482,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "In the rural and deeply rural ares of our state, there simply is no internet access.  Buildout of the web is the biggest barrier to digital access in our state. Thus, speed, cost, training and devices cannot be judged.",
    English:
      "In the rural and deeply rural ares of our state, there simply is no internet access.  Buildout of the web is the biggest barrier to digital access in our state. Thus, speed, cost, training and devices cannot be judged.",
    "First ResponseID": 6474,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "It is very rural here in Northern Wisconsin and most folks do not have access to the internet.",
    English:
      "It is very rural here in Northern Wisconsin and most folks do not have access to the internet.",
    "First ResponseID": 5438,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Lack of accessible software and content is a barrier to the communities we serve.",
    English:
      "Lack of accessible software and content is a barrier to the communities we serve.",
    "First ResponseID": 17925,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original: "Lack of local leadership support",
    English: "Lack of local leadership support",
    "First ResponseID": 16899,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Living in an area that is comprised of a lot of rural living, there is a real lack of availability of affordable internet access.",
    English:
      "Living in an area that is comprised of a lot of rural living, there is a real lack of availability of affordable internet access.",
    "First ResponseID": 5726,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Money is about to run out for underserved to continue access to internet. This is a critical issue for public school children in Hawaii.",
    English:
      "Money is about to run out for underserved to continue access to internet. This is a critical issue for public school children in Hawaii.",
    "First ResponseID": 6401,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "More and more companies and governments entities INSIST that the public use the internet to pay taxes, apply for benefits, apply for jobs, do their banking, etc. Many of our patrons have the knowledge and equipment to do those things. But a lot of them don't. They don't even have an e-mail address or any idea how to get one, never mind being able to do their banking or manage their Social Security benefits online. They have no clue about internet safety, either. They are really being left behind. Our staff spends a lot of time helping our patrons with these things and we do our best, but we can't do it all. EVERYONE should have home internet access, a device to use for that access, and a place to receive the technical help they need without cost. The library can help with the training, but not without additional funding for training staff and updated equipment and software.",
    English:
      "More and more companies and governments entities INSIST that the public use the internet to pay taxes, apply for benefits, apply for jobs, do their banking, etc. Many of our patrons have the knowledge and equipment to do those things. But a lot of them don't. They don't even have an e-mail address or any idea how to get one, never mind being able to do their banking or manage their Social Security benefits online. They have no clue about internet safety, either. They are really being left behind. Our staff spends a lot of time helping our patrons with these things and we do our best, but we can't do it all. EVERYONE should have home internet access, a device to use for that access, and a place to receive the technical help they need without cost. The library can help with the training, but not without additional funding for training staff and updated equipment and software.",
    "First ResponseID": 6494,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "More focus on the positive aspects of digital communication and learning.",
    English:
      "More focus on the positive aspects of digital communication and learning.",
    "First ResponseID": 5949,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "More policy for municipal internet access and affordable/ subsidized devices would be wonderful.",
    English:
      "More policy for municipal internet access and affordable/ subsidized devices would be wonderful.",
    "First ResponseID": 7682,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our adult students live in communities where internet access is available but too expensive for them to use. Reliable devices other than smart phones are too expensive. Their limited access to information keeps them from thriving.",
    English:
      "Our adult students live in communities where internet access is available but too expensive for them to use. Reliable devices other than smart phones are too expensive. Their limited access to information keeps them from thriving.",
    "First ResponseID": 16188,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our biggest challenge as an all-volunteer non-profit is affording the software platforms necessary to conduct our work in an increasingly virtual, socially-distanced world.",
    English:
      "Our biggest challenge as an all-volunteer non-profit is affording the software platforms necessary to conduct our work in an increasingly virtual, socially-distanced world.",
    "First ResponseID": 16677,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our biggest problem is age.  Our group struggles with learning how to use new technology and most of the work falls on a handful of us with different skill sets so we still could not manage if one of us were to come up missing. Thank You for caring! Techsoup has been a huge help in making sofware affordable too.",
    English:
      "Our biggest problem is age.  Our group struggles with learning how to use new technology and most of the work falls on a handful of us with different skill sets so we still could not manage if one of us were to come up missing. Thank You for caring! Techsoup has been a huge help in making sofware affordable too.",
    "First ResponseID": 6428,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original: "Our clients need cheap reliable cell phones.",
    English: "Our clients need cheap reliable cell phones.",
    "First ResponseID": 3612,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our greatest challenge is having an older congregation that does not use digital forms - they don't want email surveys or online surveys - they do not want to give their money online",
    English:
      "Our greatest challenge is having an older congregation that does not use digital forms - they don't want email surveys or online surveys - they do not want to give their money online",
    "First ResponseID": 6283,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our greatest issue is the speed. An hour ago I ran a test speed and it was .35 mbps download and .13 mbps upload and we have 10 staff that work in this office.",
    English:
      "Our greatest issue is the speed. An hour ago I ran a test speed and it was .35 mbps download and .13 mbps upload and we have 10 staff that work in this office.",
    "First ResponseID": 8473,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our largest issue is affording computers to use in our classes.  Our equipment is outdated.  We also need to update the computer programs and applications.",
    English:
      "Our largest issue is affording computers to use in our classes.  Our equipment is outdated.  We also need to update the computer programs and applications.",
    "First ResponseID": 13601,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our non-profit visual arts project is international and mostly in schools, so in the US and Europe [except Greece] internet is good. Asia, Africa - not good. Haiti - bad. We have not made connections with South America.  Schools do not have much. For us, we cannot afford an expensive website that protects students' identities and can hold all the data - especially trying to connect images with information. We are trying to build a searchable data-base/archive of over 16 years of over 12,000 images and it's terribly labor-intensive.  Connecting images with spread sheets.... My volunteers are older and not tech-savvy. We use Artsonia.com as our digital gallery.  They do a great job with security but the process for teachers to create an account is still difficult - both in the US and especially outside due to language. Thank you for doing this work.",
    English:
      "Our non-profit visual arts project is international and mostly in schools, so in the US and Europe [except Greece] internet is good. Asia, Africa - not good. Haiti - bad. We have not made connections with South America.  Schools do not have much. For us, we cannot afford an expensive website that protects students' identities and can hold all the data - especially trying to connect images with information. We are trying to build a searchable data-base/archive of over 16 years of over 12,000 images and it's terribly labor-intensive.  Connecting images with spread sheets.... My volunteers are older and not tech-savvy. We use Artsonia.com as our digital gallery.  They do a great job with security but the process for teachers to create an account is still difficult - both in the US and especially outside due to language. Thank you for doing this work.",
    "First ResponseID": 6670,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our organization has only one provider for internet access at our site. Therefore we have zero options to use another service and are locked into their rates.",
    English:
      "Our organization has only one provider for internet access at our site. Therefore we have zero options to use another service and are locked into their rates.",
    "First ResponseID": 6074,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our organization is in a rural part of sierra leone meaning there is no internet infrastructure in the area so we only use mobile data which is also very slow because of the none exiting infrastructure in the community",
    English:
      "Our organization is in a rural part of sierra leone meaning there is no internet infrastructure in the area so we only use mobile data which is also very slow because of the none exiting infrastructure in the community",
    "First ResponseID": 6442,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our organization provided computers and paid for internet access for ALL of our clients during the shut down of schools during COVID.   We switched ALL of our programing to virtual and digital.  We provided training and support and there were not gaps in our services.  If we had not done this our clients would not have had access not only to our programs but they would not have had access to public school!",
    English:
      "Our organization provided computers and paid for internet access for ALL of our clients during the shut down of schools during COVID.   We switched ALL of our programing to virtual and digital.  We provided training and support and there were not gaps in our services.  If we had not done this our clients would not have had access not only to our programs but they would not have had access to public school!",
    "First ResponseID": 6296,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      'Our organization serves poor families so their children have access to arts education.  However, seeing how the local MSOs/Internet operators talk a good game of providing services for "underserved communities", there is little action behind the statements other than PR opportunities.  I wish the industry, government (from a federal, state, and local level), and other community organizations would provide more incentives and subsidies to get access to the internet and provide digital literacy skills.',
    English:
      'Our organization serves poor families so their children have access to arts education.  However, seeing how the local MSOs/Internet operators talk a good game of providing services for "underserved communities", there is little action behind the statements other than PR opportunities.  I wish the industry, government (from a federal, state, and local level), and other community organizations would provide more incentives and subsidies to get access to the internet and provide digital literacy skills.',
    "First ResponseID": 13873,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our organization's members are generally able to afford the necessary devices and services for digital access. However, we would like to expand our programs into neighborhoods where people are less likely to afford what is needed (our area has many people who don't have much \"disposable\" income).",
    English:
      "Our organization's members are generally able to afford the necessary devices and services for digital access. However, we would like to expand our programs into neighborhoods where people are less likely to afford what is needed (our area has many people who don't have much \"disposable\" income).",
    "First ResponseID": 9136,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our organizational divide is generational. Some older board members (including the treasurer) have no computer and no internet interest, making online fundraising impossible without recruiting new volunteers to assist.",
    English:
      "Our organizational divide is generational. Some older board members (including the treasurer) have no computer and no internet interest, making online fundraising impossible without recruiting new volunteers to assist.",
    "First ResponseID": 3463,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our rural community is a digital desert...while we have internet we have no support businesses, website designers, technology supports which means we have to pay more for these services if we can get them at all.",
    English:
      "Our rural community is a digital desert...while we have internet we have no support businesses, website designers, technology supports which means we have to pay more for these services if we can get them at all.",
    "First ResponseID": 5545,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Our school district provides tablets and mobile hotspots to all students, so the only people in our community who are disenfranchised with regard to internet access are those without school age children.",
    English:
      "Our school district provides tablets and mobile hotspots to all students, so the only people in our community who are disenfranchised with regard to internet access are those without school age children.",
    "First ResponseID": 4246,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "People need basic computer skills. It's a barrier in urban and rural communities across mid and older generations",
    English:
      "People need basic computer skills. It's a barrier in urban and rural communities across mid and older generations",
    "First ResponseID": 4332,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "People with low literacy skills are in a very difficult position - they need to apply for internet services online, but do not read well, do not have access to devices or wi-fi and - it is almost as you cannot get wi-fi if you do not have wi-fi.........",
    English:
      "People with low literacy skills are in a very difficult position - they need to apply for internet services online, but do not read well, do not have access to devices or wi-fi and - it is almost as you cannot get wi-fi if you do not have wi-fi.........",
    "First ResponseID": 6392,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      'Please spread the word that digital tools are more than "overhead" and actually worthy of an investment from funders and supporters of nonprofits everywhere. Thank you!',
    English:
      'Please spread the word that digital tools are more than "overhead" and actually worthy of an investment from funders and supporters of nonprofits everywhere. Thank you!',
    "First ResponseID": 4302,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Poor communities must not be left out of access in home or schools.  Low income business startup should be provide opportunities to access the internet to become strong contributors to the economy.",
    English:
      "Poor communities must not be left out of access in home or schools.  Low income business startup should be provide opportunities to access the internet to become strong contributors to the economy.",
    "First ResponseID": 13738,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Probably the biggest issue we have is the people we serve and our staff not keeping their contact information updated. We can't help, if we can't connect.",
    English:
      "Probably the biggest issue we have is the people we serve and our staff not keeping their contact information updated. We can't help, if we can't connect.",
    "First ResponseID": 4273,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Rural areas have great difficulty in accessing reliably the internet and it takes a large amount of time to upload and download things putting a burden. It is difficult to participate in online forums, public meetings, etc. Isolated areas within suburban and cities also are internetless islands.",
    English:
      "Rural areas have great difficulty in accessing reliably the internet and it takes a large amount of time to upload and download things putting a burden. It is difficult to participate in online forums, public meetings, etc. Isolated areas within suburban and cities also are internetless islands.",
    "First ResponseID": 13476,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Rural communities need access to non-monopolized internet services.  High prices and unreliable service is a normal and weekly occurrence. It is very difficult for students to succeed, often assignments do not upload to school.  Our town burnt down in the Dixie Fire last year, this has compounded the issue.",
    English:
      "Rural communities need access to non-monopolized internet services.  High prices and unreliable service is a normal and weekly occurrence. It is very difficult for students to succeed, often assignments do not upload to school.  Our town burnt down in the Dixie Fire last year, this has compounded the issue.",
    "First ResponseID": 5857,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "San Francisco Japantown, one of just 3 remaining in the country, is a deadspot for high speed Internet due to redlining policies by the major carriers. Am very interested in funding resources for urban infill Internet infrastructure for marginalized communities of color.",
    English:
      "San Francisco Japantown, one of just 3 remaining in the country, is a deadspot for high speed Internet due to redlining policies by the major carriers. Am very interested in funding resources for urban infill Internet infrastructure for marginalized communities of color.",
    "First ResponseID": 8513,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original: "Security is a huge problem",
    English: "Security is a huge problem",
    "First ResponseID": 15285,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "So many of the people who could benefit from our service do not have Intenet access in their homes.  They also do not have the skills to use the Internet if they DID have access. I have heard talk of providing access to every household for SO MANY years--and NOTHING happens",
    English:
      "So many of the people who could benefit from our service do not have Intenet access in their homes.  They also do not have the skills to use the Internet if they DID have access. I have heard talk of providing access to every household for SO MANY years--and NOTHING happens",
    "First ResponseID": 3313,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Sometimes generation is an issue in digital learning. In the rural areas offices that use to assist are being closed and the people that can afford the higher priced items were the ones that work remotely and their companies pay them to have it. Not sure it's a right for everyone to have it, but those that need it. I see people that just live to say connected and they don't have a job.",
    English:
      "Sometimes generation is an issue in digital learning. In the rural areas offices that use to assist are being closed and the people that can afford the higher priced items were the ones that work remotely and their companies pay them to have it. Not sure it's a right for everyone to have it, but those that need it. I see people that just live to say connected and they don't have a job.",
    "First ResponseID": 6016,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Speed up the process of getting quality internet services to our rural area. Making a phone call with a cell phone is even difficult in our rural areas and is a concern.",
    English:
      "Speed up the process of getting quality internet services to our rural area. Making a phone call with a cell phone is even difficult in our rural areas and is a concern.",
    "First ResponseID": 5409,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Suburban communities in rural Oklahoma are often overlooked because even though we are in the midwestern United States we have limited access to broadband internet services. Access providers are limited and primarily limited to satellite or wireless services.",
    English:
      "Suburban communities in rural Oklahoma are often overlooked because even though we are in the midwestern United States we have limited access to broadband internet services. Access providers are limited and primarily limited to satellite or wireless services.",
    "First ResponseID": 5397,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "The digital equity journey is just that a journey. As resources become available, I hope to be able to help more people.",
    English:
      "The digital equity journey is just that a journey. As resources become available, I hope to be able to help more people.",
    "First ResponseID": 3482,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "The greatest challenge in our depressed rural area is affordability.",
    English:
      "The greatest challenge in our depressed rural area is affordability.",
    "First ResponseID": 6332,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "The greatest challenge we seem to face is not necessarily access to the internet, but varied levels of competence.",
    English:
      "The greatest challenge we seem to face is not necessarily access to the internet, but varied levels of competence.",
    "First ResponseID": 12178,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "The internet in the US is heavily reliant on large social media companies that can decide what content they will serve. That, combined with the US federal and state governments currently on a reactionary footing regarding media, do make me concerned for the future of small media organizations.",
    English:
      "The internet in the US is heavily reliant on large social media companies that can decide what content they will serve. That, combined with the US federal and state governments currently on a reactionary footing regarding media, do make me concerned for the future of small media organizations.",
    "First ResponseID": 13414,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "The internet infrastructure in our neighborhood is not fast enough to allow us to put on some of the community outreach programs we would like to. Research has revealed that upgrading the speed would require installing fiber optic cables which costs much more than we can afford.",
    English:
      "The internet infrastructure in our neighborhood is not fast enough to allow us to put on some of the community outreach programs we would like to. Research has revealed that upgrading the speed would require installing fiber optic cables which costs much more than we can afford.",
    "First ResponseID": 18715,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "The lack of quality internet services in rural areas definitely restricts the advancement of the area.  From civic needs to educational needs the lack of digital access denies rural areas to expand into the 21st century.",
    English:
      "The lack of quality internet services in rural areas definitely restricts the advancement of the area.  From civic needs to educational needs the lack of digital access denies rural areas to expand into the 21st century.",
    "First ResponseID": 6319,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "The main office for our organization is in a rural community with unreliable Internet via Broadband. Since we rely 100% on public generosity to fund our work, we use 85% of our income to providing animal care. We rely on our staff to provide their computers and Internet service. Unable to give hardware and internet connectivity is not fair, but it is a reality.",
    English:
      "The main office for our organization is in a rural community with unreliable Internet via Broadband. Since we rely 100% on public generosity to fund our work, we use 85% of our income to providing animal care. We rely on our staff to provide their computers and Internet service. Unable to give hardware and internet connectivity is not fair, but it is a reality.",
    "First ResponseID": 5705,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "The organization for which I work is quite small. We have no employees, simply volunteers and Board members, all of whom are quite old. We desperately need to add content, fundraising software, and linkages to other like organizations.",
    English:
      "The organization for which I work is quite small. We have no employees, simply volunteers and Board members, all of whom are quite old. We desperately need to add content, fundraising software, and linkages to other like organizations.",
    "First ResponseID": 13470,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "The people we serve live below the poverty line. They can't afford housing, so computers and internet service tend to be a luxury. Our programs would have more impact and a farther reach if those we served had access to computers and the internet.",
    English:
      "The people we serve live below the poverty line. They can't afford housing, so computers and internet service tend to be a luxury. Our programs would have more impact and a farther reach if those we served had access to computers and the internet.",
    "First ResponseID": 13436,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "The power building, civil rights advocacy based work we do, in the way we do it, has yet to fully harness the capability of the internet, digital technologies, etc. Being based in the United States, it is mostly a generational divide, and the consequence of particular local political/social/demographic/historical dynamics.",
    English:
      "The power building, civil rights advocacy based work we do, in the way we do it, has yet to fully harness the capability of the internet, digital technologies, etc. Being based in the United States, it is mostly a generational divide, and the consequence of particular local political/social/demographic/historical dynamics.",
    "First ResponseID": 7604,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "The programs to provide Internet access to rural areas in my opinion and from what I currently know seem to be more about companies and whether they can make a profit providing access to individuals in rural areas for the most part rather than looking at access as an individual human right no matter where an individual lives. Years ago, there were efforts to run Internet access through electrical lines and poles - to use that existing infrastructure to provide access and I'm not sure what happened to that initiative but it seems to make sense from a cost perspective. I think esp. during COVID times, well COVID and how we've all had to work from home, school from home etc. has exposed the need for all individuals to have equal access to Internet services. We also need additional provider choices - Europeans have a multitude of choices for Internet services, while we in the U.S. only have a few and those options diminish as one moves out of the cities. Thank you for doing this important work!",
    English:
      "The programs to provide Internet access to rural areas in my opinion and from what I currently know seem to be more about companies and whether they can make a profit providing access to individuals in rural areas for the most part rather than looking at access as an individual human right no matter where an individual lives. Years ago, there were efforts to run Internet access through electrical lines and poles - to use that existing infrastructure to provide access and I'm not sure what happened to that initiative but it seems to make sense from a cost perspective. I think esp. during COVID times, well COVID and how we've all had to work from home, school from home etc. has exposed the need for all individuals to have equal access to Internet services. We also need additional provider choices - Europeans have a multitude of choices for Internet services, while we in the U.S. only have a few and those options diminish as one moves out of the cities. Thank you for doing this important work!",
    "First ResponseID": 17927,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "This survey forces answers about the people we serve. We don't know as much as we would like to know about that. As a result, answers contain a lot of wild guesses.",
    English:
      "This survey forces answers about the people we serve. We don't know as much as we would like to know about that. As a result, answers contain a lot of wild guesses.",
    "First ResponseID": 13164,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Trying to update our current IT system from top to bottom. Cannot afford what we really need to become more efficient in service delivery.",
    English:
      "Trying to update our current IT system from top to bottom. Cannot afford what we really need to become more efficient in service delivery.",
    "First ResponseID": 5760,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We also need some grant options that would create an opportunity to cover the cost of WiFi internet access to our community.",
    English:
      "We also need some grant options that would create an opportunity to cover the cost of WiFi internet access to our community.",
    "First ResponseID": 6105,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We are a community school located in a public school building.  Our internet services are poor since FIOS and Xfinity is not an option for our program.  We currently use two modems to connect to the internet.  It would be great if we had alternative means.",
    English:
      "We are a community school located in a public school building.  Our internet services are poor since FIOS and Xfinity is not an option for our program.  We currently use two modems to connect to the internet.  It would be great if we had alternative means.",
    "First ResponseID": 6986,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We are a small nonprofit organization that teaches after school hands on STEM classes to elementary school girls. Some of the questions in the survey were challenging to answer because the students we serve do not access the internet in our classes.",
    English:
      "We are a small nonprofit organization that teaches after school hands on STEM classes to elementary school girls. Some of the questions in the survey were challenging to answer because the students we serve do not access the internet in our classes.",
    "First ResponseID": 13125,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We are a small, rural community with only one internet provider and many dead zones. The town is currently trying to expand its digital footprint, however, with limited funds, it will take years.  The COA has a great technology training project, working with many residents to increase their digital knowledge and skills.",
    English:
      "We are a small, rural community with only one internet provider and many dead zones. The town is currently trying to expand its digital footprint, however, with limited funds, it will take years.  The COA has a great technology training project, working with many residents to increase their digital knowledge and skills.",
    "First ResponseID": 5897,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We are blessed to have local high-speed internet provided by our city government as a public utility at a relatively affordable price.  We are aware that not everyone is as fortunate.  We think the City of Longmont Colorado could serve as a model for other communities.",
    English:
      "We are blessed to have local high-speed internet provided by our city government as a public utility at a relatively affordable price.  We are aware that not everyone is as fortunate.  We think the City of Longmont Colorado could serve as a model for other communities.",
    "First ResponseID": 13174,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We are doing our best to offer free programming to our community for digital literacy. We do not see enough programming to attract the seniors who are technologically deficient; it is usually geared toward younger people who have never lived without the internet.",
    English:
      "We are doing our best to offer free programming to our community for digital literacy. We do not see enough programming to attract the seniors who are technologically deficient; it is usually geared toward younger people who have never lived without the internet.",
    "First ResponseID": 6922,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We are in a rural and underserved location. Some of my jobs entailed being a VTC technician. When I arrived at this location, I told them that the infrastructure could not support doing VTC work and that if they were looking for people to relocate to this area, they needed to improve internet access speed and reliability. They responded, \"we're good; people can get on FB and email.\" When the pandemic hit, they discovered firsthand how much infrastructure was lacking as people couldn't do remote work or school. Our organization expanded our network into our parking area to allow people to get on a free, stable, and reliable network throughout the day and people took advantage of that. If we'd had the funds to implement a better network, we would have. Still, we took a hit of over 70% revenue loss for 2019 and are currently just trying to come up with funds to replace our outdated computer equipment within the organization. Getting people to understand their needs and the cost it takes to upgrade has been an enormous challenge for us. It is incredibly discouraging when the local and state level doesn't want to fund anything to fix the issue.",
    English:
      "We are in a rural and underserved location. Some of my jobs entailed being a VTC technician. When I arrived at this location, I told them that the infrastructure could not support doing VTC work and that if they were looking for people to relocate to this area, they needed to improve internet access speed and reliability. They responded, \"we're good; people can get on FB and email.\" When the pandemic hit, they discovered firsthand how much infrastructure was lacking as people couldn't do remote work or school. Our organization expanded our network into our parking area to allow people to get on a free, stable, and reliable network throughout the day and people took advantage of that. If we'd had the funds to implement a better network, we would have. Still, we took a hit of over 70% revenue loss for 2019 and are currently just trying to come up with funds to replace our outdated computer equipment within the organization. Getting people to understand their needs and the cost it takes to upgrade has been an enormous challenge for us. It is incredibly discouraging when the local and state level doesn't want to fund anything to fix the issue.",
    "First ResponseID": 13561,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We are in a rural area that has VERY limited broadband capabilities.  Our citizen think they have broadband with their phone.  We are supposed to have broadband after two FCC bid projects but it is 3-5 years down the road.  Our Library is the only public place outside our 4 schools in our county to have safe broadband.",
    English:
      "We are in a rural area that has VERY limited broadband capabilities.  Our citizen think they have broadband with their phone.  We are supposed to have broadband after two FCC bid projects but it is 3-5 years down the road.  Our Library is the only public place outside our 4 schools in our county to have safe broadband.",
    "First ResponseID": 5874,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We are in a very rural area and there is NOTHING here via infrastructure.",
    English:
      "We are in a very rural area and there is NOTHING here via infrastructure.",
    "First ResponseID": 8562,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We are in a very rural area with very limited cell service.  Internet is provided through our telephone cooperative, yet access to high speed internet is not available to most individuals.",
    English:
      "We are in a very rural area with very limited cell service.  Internet is provided through our telephone cooperative, yet access to high speed internet is not available to most individuals.",
    "First ResponseID": 13319,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We are just starting our initiative and so are using English, but in the future may expand to other languages, where content may not be readily available. Internet access is crucial, but also providing the training and tools to ensure their safety in a way that is sustainable and not overwhelming is a challenge.",
    English:
      "We are just starting our initiative and so are using English, but in the future may expand to other languages, where content may not be readily available. Internet access is crucial, but also providing the training and tools to ensure their safety in a way that is sustainable and not overwhelming is a challenge.",
    "First ResponseID": 18215,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We are working hard to convince ISPs that internet should be freely available through public libraries - the ones that our organization establishes and others.  It would be to their financial benefit in the long run to have the increased user base/market that would result.",
    English:
      "We are working hard to convince ISPs that internet should be freely available through public libraries - the ones that our organization establishes and others.  It would be to their financial benefit in the long run to have the increased user base/market that would result.",
    "First ResponseID": 6997,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We are working to build out infrastructure throughout our County, and we are a hard to reach rural area that has to rely on grants and tax levy, this is VERY challenging to get municipalities to engage in this massive spend and tax levy to ensure that we have sufficient infrastructure in place for our economic growth and prosperity in our community!  We are also working on a County DEI plan to help with device access, as well as education and skill building.  It's a long path but it's SO worth it.",
    English:
      "We are working to build out infrastructure throughout our County, and we are a hard to reach rural area that has to rely on grants and tax levy, this is VERY challenging to get municipalities to engage in this massive spend and tax levy to ensure that we have sufficient infrastructure in place for our economic growth and prosperity in our community!  We are also working on a County DEI plan to help with device access, as well as education and skill building.  It's a long path but it's SO worth it.",
    "First ResponseID": 6234,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We could not serve our constituents without online registration capabilities. As we wait for our rural communities to gain better internet access - and there is progress on this front - we are aware of their limitations.",
    English:
      "We could not serve our constituents without online registration capabilities. As we wait for our rural communities to gain better internet access - and there is progress on this front - we are aware of their limitations.",
    "First ResponseID": 5608,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We do a lot of work in the Central and Southern Appalachian region of the USA, including some of the poorest counties in the country in KY, WV, VA and TN, and there is a big concern of digital equity and access for those regions.",
    English:
      "We do a lot of work in the Central and Southern Appalachian region of the USA, including some of the poorest counties in the country in KY, WV, VA and TN, and there is a big concern of digital equity and access for those regions.",
    "First ResponseID": 18690,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We do have access to internet and content, but it could be better; Our access to digital tools could be better; Our greatest challenge may be that we don't really know much about 'internet policy' and the effects on our use of the internet.",
    English:
      "We do have access to internet and content, but it could be better; Our access to digital tools could be better; Our greatest challenge may be that we don't really know much about 'internet policy' and the effects on our use of the internet.",
    "First ResponseID": 13329,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We have a total lack of internet infrastructure in many areas of the county we live in.  Most low-income people do not have the internet at home and rely on track phones for the service when they have money to pay for time.",
    English:
      "We have a total lack of internet infrastructure in many areas of the county we live in.  Most low-income people do not have the internet at home and rely on track phones for the service when they have money to pay for time.",
    "First ResponseID": 5658,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We have had 20 year battles with the internet providers regarding poor infrastructure, lack of choices (there is only one provider that has claimed they will not spend any money on infrastructure in our area)  Our town has been to the BPU and anywhere else we can go to get relief,  there has been some but not for 20% of the residents even though we were promised broad band access)",
    English:
      "We have had 20 year battles with the internet providers regarding poor infrastructure, lack of choices (there is only one provider that has claimed they will not spend any money on infrastructure in our area)  Our town has been to the BPU and anywhere else we can go to get relief,  there has been some but not for 20% of the residents even though we were promised broad band access)",
    "First ResponseID": 5818,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      'We interact with many seniors, and their digital skills are lower, and as they age get even lower, This will need to be addressed, maybe via VR  TVs  they can talk to "digital friends" who help them navigate life.',
    English:
      'We interact with many seniors, and their digital skills are lower, and as they age get even lower, This will need to be addressed, maybe via VR  TVs  they can talk to "digital friends" who help them navigate life.',
    "First ResponseID": 4249,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We know that some of our veterans do not have computers, some do not have adequate training and some need assistance and access to contact us and/or to submit writing.",
    English:
      "We know that some of our veterans do not have computers, some do not have adequate training and some need assistance and access to contact us and/or to submit writing.",
    "First ResponseID": 13547,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      'We need access to teaching computer skills and providing computers and internet to Local Organizations that support the low income families and homeless population within "Our Community',
    English:
      'We need access to teaching computer skills and providing computers and internet to Local Organizations that support the low income families and homeless population within "Our Community',
    "First ResponseID": 6345,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We need to solve the critical internet challenges by makes the cities Wi-Fi where access is available for all.",
    English:
      "We need to solve the critical internet challenges by makes the cities Wi-Fi where access is available for all.",
    "First ResponseID": 6991,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We operate from a rural community in Nevada. We have horrible internet connectivity. No cable internet, just wifi that is slow and unreliable.",
    English:
      "We operate from a rural community in Nevada. We have horrible internet connectivity. No cable internet, just wifi that is slow and unreliable.",
    "First ResponseID": 5922,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We provide shelter for women and children.  We don't have sufficient internet service to run our business or allow them to use it.",
    English:
      "We provide shelter for women and children.  We don't have sufficient internet service to run our business or allow them to use it.",
    "First ResponseID": 5320,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We serve a community that has severe long-standing economic depression , and is largely rural. Accessibility, affordability, and skill development are great challenges here, but there is not a path forward for the most part. Would be very interested in knowing what is available or coming in the future.",
    English:
      "We serve a community that has severe long-standing economic depression , and is largely rural. Accessibility, affordability, and skill development are great challenges here, but there is not a path forward for the most part. Would be very interested in knowing what is available or coming in the future.",
    "First ResponseID": 6377,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We serve a worldwide, multi-language, community by supporting local groups who provide our program to their members. This makes it difficult to summarize our communities needs and situations. Further, see are actively working to reach the international community (language). We need to rely on local groups to serve those without internet access. And, we have limited knowledge of how those with accessibility issues use our services.",
    English:
      "We serve a worldwide, multi-language, community by supporting local groups who provide our program to their members. This makes it difficult to summarize our communities needs and situations. Further, see are actively working to reach the international community (language). We need to rely on local groups to serve those without internet access. And, we have limited knowledge of how those with accessibility issues use our services.",
    "First ResponseID": 2650,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We serve an under supported community of indigenous Maya with education and healthcare.  Internet access and technology is extremely expensive and out of reach for the local population.  Like many third world countries, the minority rules the majority and prevents them from getting ahead but keeping education, technology and the like expensive.  Even working in small communities, we strive to make a difference.",
    English:
      "We serve an under supported community of indigenous Maya with education and healthcare.  Internet access and technology is extremely expensive and out of reach for the local population.  Like many third world countries, the minority rules the majority and prevents them from getting ahead but keeping education, technology and the like expensive.  Even working in small communities, we strive to make a difference.",
    "First ResponseID": 6219,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We serve people with disabilities. Access is important for them to be able to take advantage of our services. We also rely on internet access to support our fundraising efforts. Rural Vermont still lacks adequate universal access to the internet.",
    English:
      "We serve people with disabilities. Access is important for them to be able to take advantage of our services. We also rely on internet access to support our fundraising efforts. Rural Vermont still lacks adequate universal access to the internet.",
    "First ResponseID": 5743,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We should not forget to make internet access more affordable for our seniors",
    English:
      "We should not forget to make internet access more affordable for our seniors",
    "First ResponseID": 6276,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We support Adults with Disabilities and need a bulk account to handle all the internet services but no one offers it at an affordable rate.  Our only option is to use a business level account that is 8 times the cost of residential internet.",
    English:
      "We support Adults with Disabilities and need a bulk account to handle all the internet services but no one offers it at an affordable rate.  Our only option is to use a business level account that is 8 times the cost of residential internet.",
    "First ResponseID": 5517,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We teach digital literacy skills in 8 languages, provide an internet-ready device and help a senior, person with disabilities, or a disparaged community access affordable internet.",
    English:
      "We teach digital literacy skills in 8 languages, provide an internet-ready device and help a senior, person with disabilities, or a disparaged community access affordable internet.",
    "First ResponseID": 13459,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "We work with schools and the rural students don't have access at most homes (our students regularly went to McDonalds to use the Wi-Fi to submit their work!) The city students don't have devices or internet nor the knowledge on how to use them.   The teachers are given new technology WITHOUT TRAINING...over and over again!!  Each year, we spend time teaching teachers how to  make an electronic bookmark of a browser page.  For some, copy/paste from a keyboard command is a new discovery.",
    English:
      "We work with schools and the rural students don't have access at most homes (our students regularly went to McDonalds to use the Wi-Fi to submit their work!) The city students don't have devices or internet nor the knowledge on how to use them.   The teachers are given new technology WITHOUT TRAINING...over and over again!!  Each year, we spend time teaching teachers how to  make an electronic bookmark of a browser page.  For some, copy/paste from a keyboard command is a new discovery.",
    "First ResponseID": 5940,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "What the people we serve have, know and want is completely speculative on my part, and I imagine others. This is not to deny that many non-profits are intimately familiar with these.",
    English:
      "What the people we serve have, know and want is completely speculative on my part, and I imagine others. This is not to deny that many non-profits are intimately familiar with these.",
    "First ResponseID": 6068,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Where two of our employees live there are NO internet service providers other than satellite. None. They both live in Suburban areas that are bordering on semi-rural and it seems providers just stopped expanding 20 years ago and forgot about these folks, when lockdown hit in 2020 it became very evident there was inequity among school aged children in these areas.  We teach field trips and did online field trips during that time and many children missed out on that due to no internet and/or dial up and/or tethering phones just to try and get schooling.",
    English:
      "Where two of our employees live there are NO internet service providers other than satellite. None. They both live in Suburban areas that are bordering on semi-rural and it seems providers just stopped expanding 20 years ago and forgot about these folks, when lockdown hit in 2020 it became very evident there was inequity among school aged children in these areas.  We teach field trips and did online field trips during that time and many children missed out on that due to no internet and/or dial up and/or tethering phones just to try and get schooling.",
    "First ResponseID": 5504,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Why is Amazon so easy to use but Government websites and banks are not?",
    English:
      "Why is Amazon so easy to use but Government websites and banks are not?",
    "First ResponseID": 5561,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Working in Haiti is very challenging and in the more remote areas with out power , internet, cell service makes it that much harder",
    English:
      "Working in Haiti is very challenging and in the more remote areas with out power , internet, cell service makes it that much harder",
    "First ResponseID": 16712,
  },
  {
    Language: "English",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Working primarily with active seniors and the elderly, using the internet has been difficult, but as the boomers are moving through, we are noticing that more and more seniors are digitally savvy.  However, for older seniors, the internet is not an effective tool for us.",
    English:
      "Working primarily with active seniors and the elderly, using the internet has been difficult, but as the boomers are moving through, we are noticing that more and more seniors are digitally savvy.  However, for older seniors, the internet is not an effective tool for us.",
    "First ResponseID": 6872,
  },
  {
    Language: "Indonesian",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Kebijakan terbaru kementrian komunikasi dan informatika Indonesia. Peraturan Menteri Nomor 5 tahun 2020. Menghambat tumbuhnya digitalisasi di Indonesia.",
    English:
      "The latest policy of the Indonesian Ministry of Communication and Information Technology. Ministrerial Regulation Number 5 of 2020. Inhibiting the growth of digitalization in Indonesia",
    "First ResponseID": 2881,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "Considero que la accesibilidad al Internet es importante pero el contenido debe ser algo que aporte a la sociedad y no una herramiento mal utilizada para la alienación de las comunidades.",
    English:
      "I believe that Internet accessibility is important, but the content must be something that contributes to society and not a tool for alienating and distracting communities.",
    "First ResponseID": 4896,
  },
  {
    Language: "Arabic",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original:
      "توفر الانترنت اكبر مشكلة نواجهها في عملنا سواء داخل سوريا او في مخيمات اللاجئين",
    English:
      "Internet availability is the biggest problem we face in our work, whether inside Syria or in refugee camps",
    "First ResponseID": 7875,
  },
  {
    Language: "Chinese_Taiwan",
    "Country Code": "US",
    "HQ Country": "United States of America",
    Original: "使用者沒有動力去學習或了解。",
    English: "Users are not motivated to learn or understand",
    "First ResponseID": 13340,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "VE",
    "HQ Country": "Venezuela",
    Original: "Mayor desafio en Venezuela conectividad",
    English: "The biggest challenge in Venezuela is connectivity",
    "First ResponseID": 19381,
  },
  {
    Language: "Spanish_LATAM",
    "Country Code": "VE",
    "HQ Country": "Venezuela",
    Original:
      "La primera problemática que aborda mis beneficiarios es la falta de recursos para adquirir equipos electrónicos, Internet y datos, para poder recibir la información y ayuda necesaria para la resolución de la mayoría de sus necesidades básicas.",
    English:
      "The first problem our beneficiaries address is the lack of resources to acquire electronic equipment, Internet and data, in order to receive the right  information and necessary  help to solve most of their basic needs.",
    "First ResponseID": 3605,
  },
  {
    Language: "Dutch",
    "Country Code": "ZM",
    "HQ Country": "Zambia",
    Original:
      "Ik hoop dat in de toekomst er en eind komt aan de macht van centrale banken en dat ook mensen in ontwikkelingslanden in een stabiele munt kunnen sparen en lenen.",
    English:
      "I hope that in the future there will be an end to the power of central banks and that people in developing countries will also be able to save and borrow in a stable currency.",
    "First ResponseID": 12711,
  },
  {
    Language: "English",
    "Country Code": "ZM",
    "HQ Country": "Zambia",
    Original:
      "Educate Nonprofits on Advocacy for Digital services and also link them to organisations that can fund their Digital requirements",
    English:
      "Educate Nonprofits on Advocacy for Digital services and also link them to organisations that can fund their Digital requirements",
    "First ResponseID": 10146,
  },
  {
    Language: "English",
    "Country Code": "ZW",
    "HQ Country": "Zimbabwe",
    Original:
      "Helping my organisation understand the value of the IT department and its needs is a challenge, especially regarding equipment and continuing education to maintain relevance with technology and security needs.",
    English:
      "Helping my organisation understand the value of the IT department and its needs is a challenge, especially regarding equipment and continuing education to maintain relevance with technology and security needs.",
    "First ResponseID": 4044,
  },
];

export { comments };
