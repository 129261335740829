import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import styles from './css-modules/drop_down.module.css';

function CustomDropDown({ title, stateVar, stateFunction, options, optionLabel, groupBy, invert }) {
    return (
        <div className={`${styles.container}`}>
            <p className={`${styles.title}`} style={invert ? {color: '#fff'} : {color: '#000'}}>{title}</p>
            <div className={styles.dropDown_container}>
                <Autocomplete
                    disablePortal
                    disableClearable
                    options={options}
                    className={!invert ? 'default' : 'overwrite_invert'}
                    getOptionLabel={(option) => option[optionLabel]}
                    groupBy={(option) => option[groupBy]}
                    onChange={(event: any, newValue: string | null) => {
                        stateFunction(newValue);
                    }}
                    value={stateVar}
                    renderInput={(params) => <TextField variant="standard" {...params} label="" />}
                />
            </div>
        </div>
    );
}

export default CustomDropDown;
