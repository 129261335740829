import styles from "./css-modules/comment_renderer.module.css";


function CommentRenderer({ comment }) {
  return (
    <div className={styles.container}>
        <div className={styles.comment_container}>
            <p className={`${styles.original} ${styles[comment.Language]}`}>{comment['Original']}</p>
            {comment.Language !== 'English' && <div className={styles.trans}>
                <p><span>Translation: </span>{comment['English']}</p>
            </div>}
        </div>
        <div className={styles.country_container}>
            <p className={styles.country}>{comment['HQ Country']}</p>
        </div>
    </div>
  );
}

export default CommentRenderer;
